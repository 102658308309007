/* import packages */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'bootstrap';
/* import components */
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';
import { PrimaryBtn, SecondaryBtn, PrimaryLink } from '../../components/cta';
import { EditSip } from '../../components/modal';
import { Accordion } from '../../components/dataFormatting';
import CancelSIPModal from '../../components/modal/cancelSIPConfirmation';
import { SuccessPayment, InvestNowModal } from '../../components/modal/index';
// import  from '../../components/modal';

import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';
import Nodata from '../../components/note/nodata-placeholder';



/* import assets */
import Arrow from '../../assets/images/profile/right.svg'
import Pending from '../../assets/images/profile/pending.svg'
import Success from '../../assets/images/profile/success.svg'
import Failed from '../../assets/images/profile/failed.svg'
import SIPIcon from '../../assets/images/new-design/sip.svg';

const SIP = (props) => {
    const [more, setMore] = useState(false);
    const wrapperRef = useRef(null);
    const [sipData, setSipData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [transactionLoader, setTransactionLoader] = useState(false);
    const [sipDetails, setSipDetails] = useState({});
    const [sipTransactionData, setSipTransactionData] = useState([]);
    const [cancelLoader, setCancelLoader] = useState(false);
    const [selectedSIP, setSelectedSIP] = useState(null);
    const [orderList, setOrderList] = useState([]);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openInvestModal, setOpenInvestModal] = useState(false);
    const [successContent, setSuccessContent] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(true);
    const [modalTitle, setModalTitle] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, behaviour: "smooth" });
    }, []);

    useEffect(() => {
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 5000);
        }
    }, [error]);

    useEffect(() => {
        getSipDetails();
        getSipTransactions();
        getOrderList();
    }, []);

    const closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setMore(false)
        }
    }

    useEffect(() => {
        if (props.userDetail) _analyticsTrackUserDetails();
    }, [props.userDetail])

    const _analyticsTrackUserDetails = () => {
        window.analytics.identify(props.userDetail?.user_id, {
            path: window.location.pathname
        });
    }

    /*-----sip details api----*/
    const getSipDetails = () => {
        setLoader(true);
        let url = devconfig.user + '/sip/get'
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                if (response.status === true) {
                    setLoader(false);
                    setSipData(response.data);
                } else {
                    setLoader(false);
                    // setError(response.message);
                    setSipData([]);
                }
            } else {
                setLoader(false);
                setSipData([]);
                //setError(response.message);
            }
        });
    }

    /*-----sip transactions api----*/
    const getSipTransactions = () => {
        setTransactionLoader(true)
        let url = devconfig.user + '/sip/list-sip'
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                if (response.status === true) {
                    setTransactionLoader(false);
                    setSipTransactionData(response.data);
                } else {
                    setTransactionLoader(false);
                    setSipTransactionData([]);
                    // setError(response.message);
                }
            } else {
                setTransactionLoader(false);
                //setError(response.message);
            }
        });
    }

    /*-----sip details api----*/
    const getOrderList = () => {

        setLoader(true);
        let url = devconfig.user + '/profile/order-list'
        let data = JSON.stringify({
            order_type: [2],
            status_type: []
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.status === true) {
                    setOrderList(response.data)
                } else {
                    setOrderList([]);
                    setError(response.message);
                }
            } else {
                setOrderList([]);
                setLoader(false);
                // setError(response.message);
            }
        });
    }

    useEffect(() => {
        if (selectedSIP !== null) {
            var modal = new Modal(document.getElementById("cancelConfirm", {}))
            modal.show()
        }
    }, [selectedSIP])

    useEffect(() => {
        if (openSuccessModal === true) {
            var modal = new Modal(document.getElementById("paymentSucess", {}))
            modal.show()
        }
    }, [openSuccessModal])

    useEffect(() => {
        if (openInvestModal === true) {
            var modal = new Modal(document.getElementById("invest", {}))
            modal.show()
        }
    }, [openInvestModal])



    /*-----cancel sip api----*/
    const cancelSip = (id) => {
        setCancelLoader(true)
        let url = devconfig.user + '/sip/cancel'
        let data = JSON.stringify({
            sip_id: id
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.status === true) {
                    setCancelLoader(false);
                    getSipDetails();
                } else {
                    setCancelLoader(false);
                    setError(response.message);
                }
            } else {
                setCancelLoader(false);
                setError(response.message);
            }
        });
    }
    return (
        <>
            {
                loader && transactionLoader ? (
                    <Loader height="200px" />
                ) : (
                    <div className="e-main-content e-dashboard e-sip-container px-lg-5 px-3 pb-5 mb-5">
                        <div className="row">

                            <div className="col-12 mt-lg-3 mt-md-3 mt-sm-0 mt-0 pt-0">
                                <h5 className="e-dashboard-greeting mt-3">
                                    Manage SIPs
                                </h5>
                                <p className="e-dashboard-sub-title">
                                    Manage your SIP here
                                </p>
                            </div>

                            {
                                sipData.length > 0 ?
                                    <div className='col-12 mt-3'>

                                        {
                                            // loader === true ?
                                            //   <Loader />
                                            //   :
                                            sipData.length > 0 ?
                                                sipData.map((data, key) => (
                                                    <div className='e-card e-sip-card mb-3 py-3 ps-3 pe-5'>
                                                        <div className='row ' key={key}>
                                                            <div className='col-lg-3 col-md-3 col-sm-6 col-12 d-flex'>
                                                                <img src={SIPIcon} />
                                                                <div className='ms-3'>
                                                                    <h6 className='pt-1'>Monthly SIP</h6>
                                                                    <p>
                                                                        {
                                                                            data.amount ?
                                                                                <>
                                                                                    <span className='e-rupee-symbol position-relative me-1'
                                                                                        style={{ fontWeight: '400', fontSize: '18px', top: '-1px' }}>₹
                                                                                    </span>
                                                                                    {data.amount.toLocaleString('en-IN')}
                                                                                </>
                                                                                :
                                                                                '-'
                                                                        }
                                                                    </p>
                                                                </div>

                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                <h6 className='pt-1'>SIP date</h6>
                                                                <p className='e-value'>{data.sip_date}</p>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt-3 mt-lg-0">
                                                                <h6 className='pt-1'>Created date</h6>
                                                                <p className='e-value'>{data.created_date}</p>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12 pe-4 text-lg-end text-md-end mt-3 mt-lg-0">
                                                                <h6 className='pt-1'>Next Due date</h6>
                                                                <p className='e-value'>{data.next_due}</p>
                                                            </div>
                                                            <div className="col-lg-12 text-end mt-3 pe-lg-4 pe-md-4 pe-0 gx-lg-5 gx-md-5 gx-0">

                                                                <PrimaryLink
                                                                    name="Cancel SIP"
                                                                    className="e-cancel me-3 mb-2 e-sip-cancel-btn"
                                                                    handleClick={() => {
                                                                        setSelectedSIP(data.sip_id);
                                                                        // cancelSip(data.sip_id); 
                                                                        setSipDetails(data)
                                                                    }}
                                                                />
                                                                <PrimaryBtn
                                                                    name="Edit SIP"
                                                                    toggle="modal"
                                                                    target="#editSip"
                                                                    className='e-sip-submit mb-2'
                                                                    handleClick={() => { setSipDetails(data); }}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <h6 className='text-center'>No SIPs found</h6>
                                        }
                                    </div>
                                    :
                                    null
                            }

                            {
                                orderList && orderList.length > 0 ? (
                                    <>
                                        <h5 className='e-sip-title mt-3'>SIP Transactions</h5>
                                        <Accordion
                                            data={orderList}
                                        // OrderId={orderId}
                                        />
                                    </>
                                )
                                    :
                                    null
                            }

                            {/* {
                                sipTransactionData && sipTransactionData.length > 0 ?
                                    <div className='e-side-wrap'>
                                        <div className="row mb-3">
                                            <div className="col-lg-12 col-12 mb-3 d-flex justify-content-between">
                                                <h5>SIP transactions</h5>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='e-detail-sec e-sip-table mb-3 p-0'>
                                                    {
                                                        // transactionLoader ?
                                                        //   <Loader />
                                                        //   :
                                                        sipTransactionData && sipTransactionData.length > 0 ?
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Date</th>
                                                                        <th>Amount</th>
                                                                        <th style={{ width: "30px" }}>Status</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        sipTransactionData.map((item, key) => (
                                                                            <tr key={key}>
                                                                                <td>{item.created}</td>
                                                                                <td>{item.amount_text}</td>
                                                                                <td>{item.status}</td>
                                                                                <td>{item.status === "PENDING" ? <img src={Pending} alt="" /> : item.status === "SUCCESS" ? <img src={Success} alt="" /> : <img src={Failed} alt="" />}</td>
                                                                                <td className='text-end'><Link to={'/orders?order_id=' + item.order_id}><img src={Arrow} alt="" /></Link></td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            :
                                                            <h6 className='text-center'>No Transactions found</h6>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            } */}
                            {
                                sipData && sipData.length === 0 && sipTransactionData && sipTransactionData.length === 0 ?
                                    <Nodata title="Please try later" note="There is no SIP found" />
                                    :
                                    null
                            }

                            {
                                openSuccessModal && (
                                    <SuccessPayment
                                        sip={successContent !== null ? false : true}
                                        editScore={false}
                                        heading={modalTitle ? modalTitle : "SIP Updated"}
                                        content={successContent ? successContent : "We have updated your SIP preferences"}
                                        status={successContent ? paymentStatus : true}//status
                                        from="portfolio"
                                        close={() => {
                                            // if (!status) {
                                            // 	window.location.href = "/orders"
                                            // }
                                        }}
                                    />
                                )
                            }


                            {
                                openInvestModal === true && (
                                    <InvestNowModal
                                        haveMandate={true}
                                        location='sip'
                                        details={{}}
                                        assetsDetail={{}}
                                        callBack={(data, amount) => {
                                            if (data === 'SUCCESS') {
                                                setSuccessContent(
                                                    `We have received your payment of ₹${amount}. 
                                                    The orders have been placed with each asset manager and you should 
                                                    receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.`
                                                );
                                                setModalTitle('Investment Successful');
                                                setPaymentStatus(true);
                                            }
                                            else {
                                                setModalTitle('Investment Failed');
                                                setSuccessContent(
                                                    `Investment failed please try again `
                                                );
                                                setPaymentStatus(false);
                                            }
                                            setOpenSuccessModal(true);
                                        }}
                                        unmountModal={() => setOpenInvestModal(false)}
                                    />
                                )
                            }


                            {
                                selectedSIP !== null && (
                                    <CancelSIPModal
                                        id={selectedSIP}
                                        success={() => {
                                            getSipDetails();
                                            setSelectedSIP(null);
                                            setOpenInvestModal(true);
                                        }}
                                        modalClose={(status) => { if (status === true) { getSipDetails(); } setSelectedSIP(null) }}
                                    />
                                )
                            }


                            <EditSip details={sipDetails} success={() => { setOpenSuccessModal(true); getSipDetails(); }} />
                            {
                                error !== null ?
                                    <Toast data={error} type="fail" />
                                    :
                                    null
                            }

                        </div>
                    </div>
                )

            }
        </>
    )
}

export default SIP