
/*import package*/
import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


/*import custom component*/

import Login from './containers/auth/login.js';
import Assets from './containers/assets/asset.js';
import ScriptDetail from './containers/script/index.js';
import Orders from './containers/orders/index.js'
import Profile from './containers/profile/index.js';
import SplashScreen from './components/loader/splashScreen.js';
import NotificationList from './containers/notifications/index.js';
import Reports from './containers/reports/index';
import OverviewTour from './containers/tour/index.js';
import TourAssets from './containers/tour/tour-assets';
import ScripDetailTour from './containers/tour/scrip-detail.js';
import ProfileTour from './containers/tour/tour-profile.js';

import PortfolioTracker from './containers/portfolioTracker/index.js';
import Allocation from './containers/allocations/index.js';
import Redirect from './containers/auth/redirect';
import Sidebar from './components/menu/sidebar.js';
import Portfolio from './containers/portfolio/portfolio.js';

import Dashboard from './containers/newDesign/dashboard';
import Onboard from './containers/onboard/onboard';
import Home from './containers/newDesign/home';
import SIP from './containers/sip/index.js';
import ProgressBar from './components/loader/progressLoader.js';

/*import context data provider*/
import { UserDataContext } from './context/userData';
import MintdLogo from './assets/images/menu/logo.svg';
import SideBarMenu from './assets/images/new-design/Menu.svg';


/*Service import */
import devconfig from './config/config.js';
import APIGet from './services/getApi.js';
import NotFound from './containers/notfound/index.js';
import Loader from './components/loader/loader.js';
import GetURLVar from './services/getUrl.js';
import Support from './components/support/support.js';
import LeadSquared from './services/leadSquredServices.js';


export default function App(props) {

	const [userData, setUserData, onboardFlag, setOnboardFlag] = useContext(UserDataContext);
	const [dataLoader, setDataLoader] = useState(false)
	const [splashScreen, setSplashScreen] = useState(false);
	const [showCommunicationWidget, setShowCommunicationWidget] = useState(false);
	const [profileData, setProfileData] = useState(null);
	const [unreadCount, setUnreadCount] = useState(0);
	const [openSideBar, setOpenSideBar] = useState(false);
	const [tour, setTour] = useState(false);




	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			console.log = () => { }
			console.error = () => { }
			console.debug = () => { }
		}
	}, []);



	useEffect(() => {
		console.log('inside');
		if (window.location.pathname == '/basic-detail') setShowCommunicationWidget(false);
		else if (window.location.pathname == '/public-overview') setShowCommunicationWidget(false);
		else if (window.location.pathname == '/public-asset') setShowCommunicationWidget(false);
		else if (window.location.pathname == '/portfolio-tracker' && onboardFlag === false) setShowCommunicationWidget(false);
		else setShowCommunicationWidget(true);

	}, [])




	useEffect(() => {
		GetURLVar().then((query) => {
			if (query.utm_medium) {
				sessionStorage.setItem('avail_utm', query.utm_medium);
			}
			if (query.welcome) {
				setTour(query.welcome)
			}
		})
	}, [])


	useEffect(() => {
		if (!sessionStorage.getItem('shown_splash')) {
			setSplashScreen(true);
			sessionStorage.setItem('shown_splash', true);
			setTimeout(() => {
				setSplashScreen(false)
			}, 2500)
		}
	}, [])

	useEffect(() => {
		GetURLVar().then((query) => {
			if (query.token && !localStorage.getItem('access_token')) {
				let url = window.location.href;
				let path = url.split('/');
				localStorage.setItem('access_token', query.token);
				window.location.href = `/${path[path.length - 1]}`;
			}
		});
	}, [])

	const getUnreadNotificationCount = () => {
		let url = devconfig.user + '/notifications/count';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setUnreadCount(response.data.notifications)
			}
		})
	}

	useEffect(() => {
		if (userData) {
			setOnboardFlag(
				userData.signup_status === "INVESTED" ||
					userData.signup_status === "EDIT_SCORE" ||
					userData.signup_status === "DELETE_SCORE" ||
					userData.signup_status === "QUESTIONNAIRE_RESUBMIT_STARTED" ||
					userData.signup_status === "QUESTIONNAIRE_RESUBMIT_COMPLETED" ||
					userData.signup_status === 'INACTIVE'
					?
					true
					:
					false
			)
			console.log('here in app');
		}
		else {
			if (localStorage.getItem('access_token')) {
				updateContext();
			}
		}
	}, [userData])

	const getProfileDetail = () => {
		let url = devconfig.user + '/profile/get';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setProfileData(response.data);
				if (!sessionStorage.getItem('leadSquaredId')) {
					let leadType = 'Leads.GetByEmailaddress';
					let param = 'emailaddress'
					LeadSquared._getLead(response.data.email, leadType, param);
				}
			}
		})
	}

	const _checkPortfolio = (id) => {
		let url = devconfig.portfolio + '/assets/assets/dashboard';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				if (response.data.allocations.asset_types.length) {
					_analyticsUpdate(true, id)
				}
				else {
					_analyticsUpdate(false, id)
				}
			}
		})
	}

	const _analyticsUpdate = (status, id) => {
		console.log('status');
		window.analytics.identify(id, {
			'Portfolio tracker': status,
		});
	}
	const updateContext = () => {
		setDataLoader(true);
		let url = devconfig.user + '/auth/signup-progress';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setUserData(response.data);
				getProfileDetail();
				if (response.data.auth_configured == true) {
					_checkPortfolio(response.data.user_id);
				}
				getUnreadNotificationCount();
				setTimeout(() => {
					setDataLoader(false)
				}, 1000)
			} else {
				setDataLoader(false);
			}
		})

	}

	return (
		<>
			{
				splashScreen ?
					<SplashScreen />
					:

					<>
						{
							localStorage.getItem("access_token") ?
								<>
									{
										dataLoader === true ? (
											<ProgressBar loader={dataLoader} />
										) : (

											<Router>
												<div className="container-fluid">
													{
														userData?.auth_configured === false ? (
															<Routes>
																<Route exact path="/" element={<Login />} />
															</Routes>

														)

															: (

																<div className="row ">
																	<div className={tour === "true" ? "d-none" : 'col-lg-2 pe-0'}>
																		<div className='e-side-bar-lg'>
																			<Sidebar activeUser={onboardFlag} profile={profileData} userDetail={userData} count={unreadCount} />
																		</div>
																	</div>
																	<div className={
																		tour === "true" ?
																			"col-lg-10 col-md-12 col-sm-12 px-0 e-main-content e-tour-container"
																			:
																			"col-lg-10 col-md-12 col-sm-12 px-0 e-main-content"
																	}
																	>
																		<div className={tour === "true" ? "d-none" : ''}>
																			<div className={
																				onboardFlag === true ?
																					'e-inner-small-screen-title d-flex justify-content-between px-lg-4 px-3 pb-3'
																					:
																					'e-inner-small-screen-title d-flex justify-content-between px-2 pb-3'
																			}>
																				<img src={MintdLogo} className="mt-4" />
																				<button
																					className='e-menu-btn mt-4'
																					onClick={() => {
																						setOpenSideBar(true);
																					}}
																				>
																					<img src={SideBarMenu} />
																				</button>
																			</div>
																			<div
																				className={
																					openSideBar === true ?
																						'e-small-screen-menu e-menu-over-lay'
																						:
																						'e-small-screen-menu'
																				}
																				style={{ display: openSideBar && 'block', right: openSideBar && '0' }}
																			>
																				<Sidebar activeUser={onboardFlag} profile={profileData} count={unreadCount} closeMenu={() => setOpenSideBar(false)} />
																			</div>
																		</div>

																		{
																			onboardFlag === true ? (
																				<Routes>
																					<Route exact path="/" element={<Home userDetail={userData} profileData={profileData} count={unreadCount} markedAsRead={() => getUnreadNotificationCount()} />} />
																					<Route exact path="/onboard" element={<Onboard userDetail={userData} />} />
																					<Route exact path="/home" element={<Home userDetail={userData} profileData={profileData} count={unreadCount} markedAsRead={() => getUnreadNotificationCount()} />} />
																					<Route exact path="/assets" element={<Assets markedAsRead={() => getUnreadNotificationCount()} userDetail={userData} />} />
																					<Route exact path="/portfolio-tracker" element={<PortfolioTracker userDetail={userData} profile={profileData} />} />
																					<Route exact path="/allocation" element={<Allocation userDetail={userData} />} />
																					<Route exact path="/orders" element={<Orders userDetail={userData} />} />
																					<Route exact path="/sip" element={<SIP userDetail={userData} />} />
																					<Route exact path="/notifications" element={<NotificationList count={unreadCount} userDetail={userData} markedAsRead={() => getUnreadNotificationCount()} />} />
																					<Route exact path="/reports" element={<Reports userDetail={userData} />} />
																					<Route exact path="/settings" element={<Profile userDetail={userData} />} />
																					<Route exact path="/scrip-detail" element={<ScriptDetail userDetail={userData} />} />
																					<Route exact path="/portfolio" element={<Portfolio profile={profileData} />} />
																					<Route exact path="/tour" element={<OverviewTour tour={tour} />} />
																					<Route exact path="/asset-tour" element={<TourAssets tour={tour} />} />
																					<Route exact path="/tour-scrip-detail" element={<ScripDetailTour tour={tour} />} />
																					<Route exact path="/tour-profile" element={<ProfileTour tour={tour} />} />
																					<Route exact path="/auth/kite/redirect" element={<Redirect />} />
																					<Route path="*" element={<NotFound />} />
																				</Routes>
																			) : (
																				<Routes>
																					<Route exact path="/" element={<Dashboard userDetail={userData} profile={profileData} />} />
																					<Route exact path="/home" element={<Dashboard userDetail={userData} profile={profileData} />} />
																					<Route exact path="/onboard" element={<Onboard userDetail={userData} profile={profileData} />} />
																					<Route exact path="/portfolio-tracker" element={<PortfolioTracker profile={profileData} />} />
																					<Route exact path="/allocation" element={<Allocation />} />
																					<Route exact path="/notifications" element={<NotificationList />} />
																					<Route exact path="/tour" element={<OverviewTour tour={tour} />} />
																					<Route exact path="/asset-tour" element={<TourAssets tour={tour} />} />
																					<Route exact path="/tour-scrip-detail" element={<ScripDetailTour tour={tour} />} />
																					<Route exact path="/tour-profile" element={<ProfileTour tour={tour} />} />
																					<Route exact path="/auth/kite/redirect" element={<Redirect tour={tour} />} />
																					<Route path="*" element={<NotFound />} />
																				</Routes>
																			)
																		}

																	</div>
																</div>
															)
													}

												</div>
											</Router>
										)
									}
								</>
								:
								<Router>
									<div className='container-fluid'>
										{
											sessionStorage.getItem('shown_splash') ?
												<Routes>
													<Route exact path="/tour" element={<OverviewTour tour={tour} />} />
													<Route exact path="/asset-tour" element={<TourAssets tour={tour} />} />
													<Route exact path="/tour-scrip-detail" element={<ScripDetailTour tour={tour} />} />
													<Route exact path="/tour-profile" element={<ProfileTour tour={tour} />} />
													<Route exact path="/" element={<Login />} />
													<Route exact path="/login" element={<Login />} />
													<Route exact path="/auth/kite/redirect" element={<Redirect />} />
													{/* <Route path="*" element={<NotFound />} /> */}
												</Routes>
												:
												null
										}
									</div>
								</Router>
						}
						<Support />
					</>
			}
		</>
	)
}

