/*
 *   File : primaryIcon.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Icon button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 06-12-2021
 */

/*import style*/
import styles from '../../styles/components/primaryIcon.module.scss';

const PrimaryIconBtn = (props) => {
	return (
		<button
			id={props.id}
			className={props.disabled ? `e-input-wrap ${styles.e_primary_icon} ${styles.e_disabled} ${props.className}` : `e-input-wrap ${styles.e_primary_icon} ${props.className}`}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
			type="button"
		>
			{
				props.left ?
					<img src={props.image} className={props.iconClass} />
				:
					null
			}
			<span>{props.name}</span>
			{
				props.right ?
					<img src={props.image} className={`${styles.e_icon}`}/>
				:
					null
			}

		</button>

	);
}


export default PrimaryIconBtn;
