
/*
 *   File : dateRangepicker.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description :  dateRangepicker.js
 *   Integrations : 
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */
/*import package*/
import React from 'react';
import 'react-dates/initialize';
import { SingleDatePicker, isInclusivelyBeforeDay, isInclusivelyAfterDay} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date : null
    }
  }
  componentDidMount() {
    if(this.props.value){
      var tempDate = moment(this.props.value, 'DD-MM-YYYY');
      this.setState({ date: moment(tempDate) })
    }
  }

  render() {
    return (
      <div>
        <SingleDatePicker
          date={this.props.selectedDateValue? moment(this.props.selectedDateValue): this.state.date} 
          placeholder={this.props.placeholder}
          onDateChange={date => {
            this.setState({ date })
            if(date){
              var tempDate = date.format("DD-MM-YYYY");
              this.props.onDatesChange(tempDate);
            }
            else{
              this.props.onDatesChange(null);
            }
          }} 
          focused={this.state.focused}
          onFocusChange={({ focused }) => {this.setState({ focused });
          if(this.props.focus){
            this.props.focusChange();
          }}} 
          
          small={true}
          displayFormat="DD-MM-'YY"
          showDefaultInputIcon
          inputIconPosition={this.props.inputIconPosition}
          showClearDates
          onClose={()=>{
            if(this.props.focus){
              this.props.onClose();
            }
          }}
          
          isOutsideRange={
            this.props.minDate && this.props.maxDate?
            (day=> !isInclusivelyAfterDay(day, moment(this.props.minDate, "DD-MM-YYYY")) || !isInclusivelyBeforeDay(day, moment(this.props.maxDate, "DD-MM-YYYY")))
            :this.props.minDate?
            (day=> !isInclusivelyAfterDay(day, moment()) || !isInclusivelyAfterDay(day, moment(this.props.minDate, "DD-MM-YYYY")))
            :this.props.maxDate?
            (day=> !isInclusivelyAfterDay(day, moment()) || !isInclusivelyBeforeDay(day, moment(this.props.maxDate, "DD-MM-YYYY")))
            :
            this.props.allDate ?
            (day=> false)
            :

            (day=> !isInclusivelyAfterDay(day, moment()))
          }
        />
      </div>
    );
  }
}