import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'bootstrap';

//Asset import
import Gain from '../../assets/images/new-design/gain.svg';
import Info from '../../assets/images/new-design/info-black.svg';
import Up from '../../assets/images/new-design/trending-up.svg';
import Arrow from '../../assets/images/new-design/Vector.svg';
import Edit from '../../assets/images/new-design/edit-notification.svg';
import Icon from '../../assets/images/new-design/whtsapp.svg';
import CalendlyICon from '../../assets/images/new-design/calendly.svg';
import BtnArrow from '../../assets/images/new-design/btn-arrow.svg';
import Lumpsum from '../../assets/images/new-design/lumpsum-notification.svg';
import SIP from '../../assets/images/new-design/sip-notification.svg';
import Down from '../../assets/images/new-design/arrow-down.svg';
import Withdrawals from '../../assets/images/new-design/withdraw.svg';
import ArrowBlue from '../../assets/images/new-design/right-arrow-blue.svg';


//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn } from '../../components/cta';
import { Loader, SkeletonLoader } from '../../components/loader';
import { LineChart } from '../../components/chart/index';
import Accordion from 'react-bootstrap/Accordion';
import VideoPlayer from '../../components/input/videoPlayer';
import { WatsappBtn, CalendlyBtn } from '../../components/cta';
import { InvestNowModal, RedeemModal, SuccessPayment, EditScoreConfirmation, EditScore } from '../../components/modal';
import { IconStyleToolTip } from '../../components/tooltip';
import { SuccessToast } from '../../components/note';
import RebalanceModal from '../../components/modal/rebalance';
import ProgressBar from '../../components/loader/progressLoader';

// import  from '../../components/modal';

/*import sevices*/
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import CustomToolTip from '../../components/tooltip/customeTooltip';
import RebalanceUpdate from '../../components/modal/rebalanceUpdate';


const faq = [
    {
        title: 'How do I know I can trust you with my wealth?',
        answer: "First, we use institutional grade security to ensure your data is always protected. Second, we never hold your money. It goes directly from your account to the mutual funds account. And third, our advisors have decades of experience managing billions of dollars."
    },
    {
        title: 'What if I want to pull my money out?',
        answer: "It’s your money. You can withdraw it any time. We make it easy."
    },
    {
        title: 'How long does it take to open an account?',
        answer: "The entire process from onboarding through investment takes less than 5 minutes. Just keep your documents handy."
    },
    {
        title: 'Why should I invest with Mintd?',
        answer: "There are thousands of mutual funds yet 90% of managers don’t beat the market on a consistent basis. Our managers take away the headache of choosing for you by curating diversified portfolios tailored to your needs that provide superior risk-adjusted returns."
    },
    {
        title: 'Is there a lock in period for my investment?',
        answer: "No. We invest in liquid securities so you can withdraw your funds with no penalty ay any time. The cash usually takes about three days to settle in your account once you redeem your mutual funds."
    },
    {
        title: 'What happens if Mintd shutdown?',
        answer: `We never hold your money or investments. The funds are always in your name with each asset manager, we merely facilitate the transaction. So IF anything were to happen to us you could always:

        a.  Go to CAMS portal online and withdraw the amount
        
        b. Go to the AMC website and login using your PAN and withdraw the amount
        
        c. Call your AMC customer care who will send you a link to withdraw the amount
        
        Either ways we will facilitate this process for each of our customers.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.`
    },
]

const Home = (props) => {

    let calendlyUrl = devconfig.calendarUrl

    const [click, setClick] = useState(1)
    const [graphData, setGraphData] = useState();
    const [graphLoader, setGraphLoader] = useState(true);
    const [graphFilter, setGraphFilter] = useState([
        { value: 1, label: '1W' },
        { value: 2, label: '1M' },
        { value: 3, label: '3M' },
        { value: 4, label: '6M' },
        { value: 5, label: '1Y' },
        { value: null, label: 'Since 1st deposit' },
    ])
    const [userName, setUserName] = useState('');
    const [overViewData, setOverviewData] = useState();
    const [loader, setLoader] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [haveMandate, setHaveMandate] = useState(null);
    const [assetDetail, setAssetDetail] = useState();
    const [riskPercentage, setRiskPercentage] = useState(null);
    const [riskScore, setRiskScore] = useState(0);
    const [notificationData, setNotificationData] = useState();
    const [unreadCount, setUnreadCount] = useState(0);
    const navigator = useNavigate();
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState(null);
    const [successPopup, setSuccessPopUp] = useState(false);
    const [notificationDetail, setNotificaitonDetail] = useState(null);
    const [successContent, setSuccessContent] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [openPopUp, setOpenPopUp] = useState(false);
    const [openEditScoreModal, setOpenEditScoreModal] = useState(false);
    const [toast, setToast] = useState(null);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [openAmountTooltip, setOpenAmountTooltip] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [openRebalanceModal, setRebalanceModal] = useState(false);
    const [rebalanceSuccessTitle, setRebalanceSuccessTitle] = useState(null);
    const [rebalanceSuccessContent, setRebalanceSuccessContent] = useState(null);

    useEffect(() => {
        console.log('props', props.profileData)
        if (props.profileData) {
            setProfileData(props.profileData);
            setUserName(props.profileData.first_name)
        }
    }, [props.profileData])

    useEffect(() => {
        if (props.count) {
            setUnreadCount(props.count)
        }
    }, [props.count])

    useEffect(() => {
        if (successPopup) {
            const modal = new Modal(document.getElementById("paymentSucess"), {})
            modal.show()
        }
        console.log('pyment sttatus', paymentStatus);
    }, [successPopup])

    useEffect(() => {
        if (openRebalanceModal) {
            const modal = new Modal(document.getElementById('rebalance'), {});
            modal.show()
        }
    }, [openRebalanceModal])

    useEffect(() => {
        if (openEditScoreModal) {
            const modal = new Modal(document.getElementById("approved-edit"), {})
            modal.show()
        }
    }, [openEditScoreModal])

    useEffect(() => {
        getGraph();
        getOverview();
        getNotifications();
    }, [])

    const getNotifications = () => {
        let url = devconfig.user + '/notifications/get-notifications';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                let data;
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    if (element.is_read === false) {
                        data = element;
                        break;
                    }
                }
                setNotificationData(data);
                // props?.notificationData(response.data)
            }
            else {
                setNotificationData(null);
            }
        })
    }



    const getGraph = () => {
        setGraphLoader(true)
        let url = devconfig.user + '/user/portfolio-performance';
        let data = JSON.stringify({
            filter_id: click,
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setGraphData(response.data);
                setTimeout(() => {
                    setGraphLoader(false);
                }, 1000)

            }
            else {
                setTimeout(() => {
                    setGraphLoader(false);
                }, 1000)
            }
        })
    }

    const getOverview = () => {
        setLoader(true);
        getGraph()
        let url = devconfig.user + '/portfolio/overview';
        let data = JSON.stringify({
            "filter_id": null
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setOverviewData(response.data);
                setLoader(false);
                let data = response.data.portfolio_summary.risk_score;
                let risk;
                if (data) {
                    risk = Number(data.split(' ')[0]);
                    setRiskScore(risk);
                    //  
                }
                if (risk === 0.5) {
                    risk = 50
                } else if (risk === 1 || risk === 1.5) {
                    risk = 55;
                } else if (risk === 2 || risk === 2.5) {
                    risk = 65;
                }
                else if (risk < 3) {
                    risk = risk * 30
                } else {
                    risk = risk * 22.3
                }

                setTimeout(() => {
                    setRiskPercentage(risk);
                }, 1000)
                // _analyticsTrackUserDetails();
            }
            else {
                setLoader(false);
            }
        })
    }

    const getPortfolioDetail = () => {
        let url = devconfig.user + '/mf/portfolio';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setAssetDetail(response.data);

            }
        })
        getMandate()
    }

    const getMandate = () => {
        let url = devconfig.user + '/mf/get-mandate';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setHaveMandate(response.data.bank_mandate);
                setOpenModal(true)
            }
        })
    }

    useEffect(() => {
        if (openModal) {
            const modal = new Modal(document.getElementById("invest"), {})
            modal.show()
        }
    }, [openModal])

    useEffect(() => {
        if (openWithdrawModal === true) {
            const modal = new Modal(document.getElementById("redeem"), {})
            modal.show();
        }
    }, [openWithdrawModal])


    const _generateGreeting = () => {

        var currentHour = moment().format("HH");

        if (currentHour >= 3 && currentHour < 12) {
            return "Good Morning!";
        } else if (currentHour >= 12 && currentHour < 15) {
            return "Good Afternoon!";
        } else if (currentHour >= 15 && currentHour < 20) {
            return "Good Evening!";
        } else if (currentHour >= 20 && currentHour < 3) {
            return "Good Night!";
        } else {
            return "Good Evening!"
        }
    }

    useEffect(() => {
        getGraph();
    }, [click])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null)
            }, 5000)
        }
    }, [toast])

    const markAsRead = (id) => {
        console.log(id)
        let url = devconfig.user + '/notifications/read';
        let data = JSON.stringify({
            notification_id: id
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                props.markedAsRead();
                getNotifications();
            }
        })
    }

    const _successCallback = () => {
        props.markedAsRead();
        getNotifications();
        setSuccessPopUp(true);

    }

    const _successCancelEdit = () => {
        markAsRead(notificationData.data_id);
        props.markedAsRead();
        getNotifications();
        setToast('Rejected successfully')
    }

    const getNotificationDetail = () => {
        let url = devconfig.user + '/notifications/order/detail';
        let data = JSON.stringify({
            order_id: notificationData.data_id
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setNotificaitonDetail(response.data);
            }
        })
    }

    const _getCurrentValue = (val) => {
        if (val >= 10000000) {
            val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(2) + ' Lac';
        } else if (val >= 10000 || val >= 1000) {
            val = (val / 1000).toFixed(2) + ' K';
        }
        return val
    }

    useEffect(() => {
        if (props.userDetail && props.profileData) _analyticsTrackUserDetails();
    }, [props.userDetail, props.profileData])

    const _analyticsTrackUserDetails = () => {
        if (sessionStorage.getItem('avail_utm')) {
            let utm = sessionStorage.getItem('avail_utm')
            window.analytics.identify(props.userDetail?.user_id, {
                path: window.location.pathname,
                name: `${props.profileData?.first_name} ${props.profileData?.last_name}`,
                first_touch_utm: sessionStorage.getItem('avail_utm')
            });
        } else {
            window.analytics.identify(props.userDetail?.user_id, {
                path: window.location.pathname,
                name: `${props.profileData?.first_name} ${props.profileData?.last_name}`,
                first_touch_utm: "None"
            });
        }

    }

    return (
        <>
            {
                loader ? (
                    // <Loader height="250px" removeTag={true} />
                    <SkeletonLoader />
                )
                    :
                    (
                        <div className="e-main-content e-dashboard px-lg-5 px-3 pb-5 mb-5" style={{ backgroundColor: '#F9F9F9' }}>
                            <div className="row">

                                {/* Heading */}
                                <div className="col-12  mt-lg-3 mt-sm-0 mt-0  mb-lg-3 mb-md-3 mb-sm-2 mb-2">
                                    <h5 className="e-dashboard-greeting mt-3">
                                        {`Hey ${userName}, ${_generateGreeting()}`}
                                    </h5>
                                    {/* <p className="e-dashboard-sub-title ">
                                        Here is your portfolio summary
                                    </p> */}
                                    <p className="e-dashboard-coming-soon">
                                        We regret to inform you that we have made the difficult decision to sunset Mintd and will no longer be accepting new investments after <strong>March 3rd, 2023.</strong>
                                    </p>
                                </div>

                                {/* Portfolio summary cards */}

                                <div className="col-lg-4 col-md-6 col-sm-12 ">
                                    <div className="e-dashboard-summary-card p-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <h6 className="e-card-title">
                                                    Portfolio value
                                                </h6>
                                            </div>
                                            <div className="col-7">
                                                <h2
                                                    className='e-portfolio-value position-relative'
                                                    onMouseEnter={() => {
                                                        setOpenTooltip(true)
                                                    }}
                                                    onMouseLeave={() => {
                                                        // $(`#e-portfolio-value`).removeClass('e-hover-popup');
                                                        setOpenTooltip(false)
                                                    }}
                                                >
                                                    <span className='e-dashboard-rupee'>₹ </span>
                                                    {_getCurrentValue(overViewData?.invested_data.current_value)}
                                                    {
                                                        openTooltip && (
                                                            <CustomToolTip data={overViewData?.invested_data.current_value_text} />
                                                        )
                                                    }
                                                </h2>
                                            </div>
                                            <div className='col-4 text-center mt-3 ps-0'>
                                                <span className='e-gain-percent'>
                                                    {
                                                        overViewData?.invested_data.returns_percentage < 0 ?
                                                            <div className='e-lose-arrow'></div>
                                                            :
                                                            overViewData?.invested_data.returns_percentage > 0 ?
                                                                <img src={Gain} className="me-2" />
                                                                :
                                                                null

                                                    }

                                                    {overViewData?.invested_data.returns_percentage_text}
                                                </span>
                                            </div>
                                            <div className='col-6 border-end mt-3 mb-2'>
                                                <label className='e-card-label'>
                                                    Amount invested
                                                </label>
                                                <h5
                                                    className='e-amount mt-2 position-relative'
                                                    onMouseEnter={() => {
                                                        setOpenAmountTooltip(true)
                                                    }}
                                                    onMouseLeave={() => {
                                                        // $(`#e-portfolio-value`).removeClass('e-hover-popup');
                                                        setOpenAmountTooltip(false)
                                                    }}
                                                >
                                                    <span className='e-dashboard-rupee-sub'>₹ </span>
                                                    {_getCurrentValue(overViewData?.invested_data.invested_amount)}
                                                    {
                                                        openAmountTooltip && (
                                                            <CustomToolTip data={overViewData?.invested_data.invested_amount_text} />
                                                        )
                                                    }
                                                </h5>
                                            </div>
                                            <div className='col-6 mt-3 mb-2 ps-md-4'>
                                                <label className='e-card-label ps-md-3'>
                                                    Total returns
                                                </label>
                                                <h5 className='e-amount mt-2 position-relative ps-md-3'>
                                                    <span className='e-dashboard-rupee-sub '>₹ </span>
                                                    {overViewData?.invested_data.total_return}
                                                </h5>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                                                <PrimaryBtn
                                                    name="Invest more"
                                                    className="e-invest-more e-disabled"
                                                    handleClick={() => {
                                                        getPortfolioDetail()
                                                    }}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                                                <SecondaryBtn
                                                    name="Withdraw"
                                                    className="e-withdraw-more"
                                                    handleClick={() => {
                                                        setOpenWithdrawModal(true)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-sm-12 mt-3 mt-lg-0 mt-md-0'>
                                    <div className='e-dashboard-summary-card e-half-pie-card p-4'>
                                        <div className='row'>
                                            <div className='col-lg-12 d-flex justify-content-between position-relative'>
                                                <h5 className='e-score-title'>
                                                    Your risk score
                                                </h5>
                                                <img src={Info}
                                                    onMouseEnter={() => setOpenPopUp(true)}
                                                    onMouseLeave={() => setOpenPopUp(false)}
                                                />
                                                {
                                                    openPopUp && (
                                                        <IconStyleToolTip
                                                            className="e-tooltip"
                                                            content="This is your risk score on a scale of 0.5 to 10. We understand that everyone’s needs are different, so our advisors have curated a range of portfolios using Nobel Prize-winning Modern Portfolio Theory to cover every scenario. We use this score to assign you the perfect portfolio suited to your specific needs"
                                                        />
                                                    )
                                                }

                                            </div>
                                            <div className='col-12 position-relative text-center pt-4'>
                                                <div className='e-pie-outer-wrapper'>
                                                    <div class="e-half-progress">
                                                        <div class="barOverflow">
                                                            <div
                                                                class="bar"
                                                                style={{ transform: `rotate(${riskPercentage}deg)` }}
                                                            >
                                                            </div>
                                                        </div>
                                                        <label className='e-progress-legend'>Risk Score</label>
                                                        <span className='e-progress-value'>{riskScore}</span>
                                                    </div>
                                                    <div className='e-radius-left' style={{ backgroundColor: riskPercentage && '#1329e6' }}></div>
                                                    <div className='e-radius-right' style={{ backgroundColor: riskPercentage && riskScore === 10 && '#1329e6' }}></div>
                                                </div>
                                            </div>
                                            <div className='col-12 text-center'>
                                                <h5 className='e-growth mt-2'>
                                                    Growth <img src={Up} className="ms-2" />
                                                </h5>
                                                <span className='e-edit-score-btn' data-bs-toggle="modal" data-bs-target="#editScore">
                                                    Request edit
                                                    <img src={Arrow} className="ms-2" />
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-sm-12 mt-4 mt-lg-0'>
                                    <div className='e-dashboard-summary-card p-4' style={{ minHeight: '275px' }}>
                                        <div className="row">
                                            <div className='col-12'>
                                                <h5 className='e-score-title'>
                                                    Portfolio summary
                                                </h5>
                                            </div>
                                            <div className='col-6 mt-lg-4 mt-md-4 mt-sm-2 mt-2'>
                                                <h5 className="e-sub">
                                                    SIP status
                                                </h5>
                                                <span className="e-date">
                                                    Upcoming SIP
                                                </span>
                                                {
                                                    overViewData?.active_sips.length > 0 ? (
                                                        <span className='e-edit-score-btn d-block mt-2'
                                                            onClick={() => navigator('/sip')}
                                                        >
                                                            Edit SIP
                                                            <img src={Arrow} className="ms-2" />
                                                        </span>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                            <div className='col-6 mt-lg-4 mt-md-4 mt-sm-2 mt-2'>
                                                {
                                                    overViewData?.active_sips.length > 0 ? (
                                                        <h5 className='e-sip-status'>Active</h5>
                                                    ) : (
                                                        <span>--</span>
                                                    )
                                                }

                                                <span className='e-date'>
                                                    {
                                                        overViewData?.portfolio_summary?.sip_date &&
                                                        overViewData.portfolio_summary?.sip_date.map((item, key) => {
                                                            return (
                                                                item
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </div>
                                            <div className="col-12 mt-lg-4 mt-md-4 mt-sm-2 mt-2">
                                                <h5 className="e-sub">
                                                    Last rebalance
                                                </h5>
                                                <span className='e-date'>
                                                    {
                                                        overViewData?.portfolio_summary.last_rebalance_on == null ?
                                                            '--'
                                                            :
                                                            overViewData?.portfolio_summary.last_rebalance_on
                                                    }
                                                </span>
                                            </div>
                                            <div className="col-12 mt-lg-4 mt-md-4 mt-sm-2 mt-2">
                                                <h5 className="e-sub">
                                                    {
                                                        profileData?.rebalance_available !== true ?
                                                            "Upcoming rebalance"
                                                            :
                                                            "Rebalance available"
                                                    }

                                                </h5>
                                                {
                                                    profileData?.rebalance_available !== true ?
                                                        <span className='e-date'>
                                                            {
                                                                overViewData?.portfolio_summary.next_rebalance_on == null ?
                                                                    '--'
                                                                    :
                                                                    overViewData?.portfolio_summary.next_rebalance_on
                                                            }
                                                        </span>
                                                        :
                                                        <PrimaryLink
                                                            right={true}
                                                            image={ArrowBlue}
                                                            iconClass="ms-1"
                                                            name="Click to proceed"
                                                            className="e-rebalance-btn"
                                                            // handleClick={() => setRebalanceModal(true)}
                                                            toggle="modal"
                                                            target="#rebalance-update"
                                                        />
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-12 e-small-device mt-lg-4 mt-md-4 mt-sm-2 mt-2">
                                    <div className="e-chart-wrapper e-area-chart">
                                        <h6>Portfolio performance</h6>
                                        <div className='e-mobile-scroll text-lg-end text-md-end text-sm-start text-start'>
                                            <div className="" style={{ minWidth: "350px" }}>
                                                <div className="e-span-wrap mb-4">
                                                    {
                                                        graphFilter.map((item, key) => {
                                                            return (
                                                                <span className={click === item.value ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick(item.value)}>
                                                                    {item.label}
                                                                </span>
                                                            )
                                                        })
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                        {
                                            graphLoader ?
                                                <Loader height="100px" removeTag={true} className="e-overview-graph-loader" />
                                                :
                                                <div className='e-area-chart-scroll'>
                                                    <div className="" style={{ minWidth: "600px" }}>
                                                        <LineChart colors={['#1329E6', '#FFFFFF']} currency="₹" data={graphData} />
                                                    </div>
                                                </div>

                                        }

                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mt-4'>
                                    {
                                        unreadCount > 0 && notificationData && (
                                            <div className='e-dashboard-summary-card e-notification-card p-4'>
                                                <div className='row'>

                                                    <div className='col-6 mb-3'>
                                                        <h5 className='e-score-title position-relative'>
                                                            <div className='position-absolute e-unread-dot'></div>
                                                            Notifications
                                                        </h5>
                                                    </div>
                                                    <div className='col-6 text-end'>
                                                        <span className="e-mark-all" onClick={() => markAsRead(null)}>Mark all as read</span>
                                                    </div>
                                                    <div className='col-2'>
                                                        <img
                                                            className='e-icon'
                                                            src={
                                                                notificationData.type === 1 || notificationData.type === 4 ?
                                                                    Edit
                                                                    : notificationData.type === 8 ?
                                                                        SIP
                                                                        : notificationData.type === 9 ?
                                                                            Withdrawals
                                                                            : notificationData.type === 7 ?
                                                                                Lumpsum
                                                                                :
                                                                                Edit
                                                            }
                                                        />
                                                    </div>
                                                    <div className='col-10 ps-0'>
                                                        <h6>
                                                            {notificationData.title}
                                                        </h6>
                                                        <p>
                                                            {notificationData.description}
                                                        </p>
                                                    </div>
                                                    {
                                                        notificationData.type === 1 ? (
                                                            <div className='col-12 text-end mt-3'>
                                                                <SecondaryBtn
                                                                    name="Do later"
                                                                    className="e-notification-secondary me-2"
                                                                    handleClick={() => {
                                                                        markAsRead(notificationData.notification_id);
                                                                    }}
                                                                />
                                                                <PrimaryBtn
                                                                    name="Edit"
                                                                    className="e-notification-primary"
                                                                    handleClick={() => setOpenEditScoreModal(true)}
                                                                />
                                                            </div>
                                                        ) : notificationData.type === 4 || notificationData.type === 6 || notificationData.type === 9 || notificationData.type === 10 ? (
                                                            <div className='col-12 text-end mt-3'>
                                                                <SecondaryBtn
                                                                    name="Dismiss"
                                                                    className="e-notification-secondary me-2"
                                                                    handleClick={() => {
                                                                        markAsRead(notificationData.notification_id);
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : notificationData.type === 5 || notificationData.type === 7 || notificationData.type === 8 ? (
                                                            <div className='col-12 text-end mt-3'>
                                                                <SecondaryBtn
                                                                    name="Dismiss"
                                                                    className="e-notification-secondary me-2"
                                                                    handleClick={() => {
                                                                        markAsRead(notificationData.notification_id);
                                                                    }}
                                                                />
                                                                <PrimaryBtn
                                                                    name="See details"
                                                                    className="e-notification-primary"
                                                                    right={true}
                                                                    iconClass="ms-2"
                                                                    image={Down}
                                                                    handleClick={() => {
                                                                        if (notificationDetail) setNotificaitonDetail(null);
                                                                        else getNotificationDetail();
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                            :
                                                            null
                                                    }
                                                    {
                                                        notificationDetail && (
                                                            <div className='col-12'>
                                                                <div className='e-notification-accordion p-4 mt-4'
                                                                    style={{ height: notificationDetail && '300px' }}
                                                                >
                                                                    <div className='row'>
                                                                        <div className='col-2'>
                                                                            <div className='e-notification-progress-wrapper'>
                                                                                <div className='e-inprogress-dashed' style={{ height: '195px' }}>
                                                                                    <div className='e-progress-indicator' style={{ height: notificationDetail.units_allocated_on !== null ? '100%' : '50%' }}></div>
                                                                                    <div className='e-step-count e-one' style={{ fontSize: '13px', paddingTop: '4.5px' }}>
                                                                                        1
                                                                                    </div>
                                                                                    <div className='e-step-count e-two' style={{ fontSize: '13px', paddingTop: '4.5px' }}>
                                                                                        2
                                                                                    </div>
                                                                                    {
                                                                                        notificationDetail.units_allocated_on !== null ? (
                                                                                            <div className='e-step-count e-three' style={{ fontSize: '13px', paddingTop: '4.5px' }}>
                                                                                                3
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className=' e-step-count e-three e-step-progress' style={{ fontSize: '13px', paddingTop: '4.5px' }}>
                                                                                                3
                                                                                            </div>
                                                                                        )
                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-10'>
                                                                            <div className='e-progress-label-wrapper' style={{ height: '195px' }}>
                                                                                <div className='e-progress-label'>
                                                                                    <p>Fund received</p>
                                                                                    <span className='e-success-badge'>COMPLETED</span>
                                                                                    <h5>{notificationDetail.order_date}</h5>
                                                                                </div>
                                                                                <div className='e-progress-label'>
                                                                                    <p>Order placed</p>
                                                                                    <span className='e-success-badge'>COMPLETED</span>
                                                                                    <h5>{notificationDetail.order_date}</h5>
                                                                                </div>
                                                                                <div className='e-progress-label'>
                                                                                    <p>MFs allocation</p>
                                                                                    <span
                                                                                        className={
                                                                                            notificationDetail.units_allocated_on === null ? 'e-inprogress-badge' : 'e-success-badge'
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            notificationDetail.units_allocated_on === null ?
                                                                                                'IN PROGRESS'
                                                                                                :
                                                                                                'COMPLETED'
                                                                                        }
                                                                                    </span>
                                                                                    <h5>{notificationDetail.units_allocated_on} </h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }


                                                    <div className='col-12 text-end mt-3'>
                                                        <span className='e-link' onClick={() => navigator('/notifications')}>
                                                            See all Notifications
                                                            <img src={Arrow} className="ms-2" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>

                                {/* Video section */}
                                <div className="col-12 mt-3 mt-lg-0">
                                    <div className="row">
                                        <div className="col-12 mt-2 mb-1">
                                            <h6 className="e-section-title mt-4 mb-4">
                                                Welcome to Mintd
                                            </h6>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12" >
                                            <div className="e-video-card" >
                                                {/* <VideoPlayer
                                                    url="https://mintd.s3.ap-south-1.amazonaws.com/video/methodology.mp4"
                                                    message="How we build your portfolio"
                                                    home={true}
                                                /> */}

                                                <iframe
                                                    id="e-build-video"
                                                    sandbox
                                                    height="100%" width="100%"
                                                    allow='autoplay'
                                                    allowFullScreen
                                                    src={"https://www.youtube.com/embed/ZHC1K1jZ8a8"}>
                                                </iframe>

                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 mt-4  mt-lg-0 mt-md-0">
                                            <div className="e-video-card">
                                                {/* <VideoPlayer
                                                    url="https://mintd.s3.ap-south-1.amazonaws.com/video/message.mp4"
                                                    message="A message from our founder"
                                                    home={true}
                                                /> */}
                                                <iframe
                                                    id="e-founder-video"
                                                    sandbox
                                                    height="100%" width="100%"
                                                    allow='autoplay'
                                                    allowFullScreen
                                                    onclick="javascript:alert('event has been triggered')"
                                                    src={"https://www.youtube.com/embed/qT7Hs3N_bnc"}>
                                                </iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* FAQ */}
                                <div className="col-12 mt-4">
                                    <div className="row">
                                        <h6 className="e-section-title mt-4 mb-2">
                                            Frequently asked questions
                                        </h6>


                                        <Accordion defaultActiveKey="0" flush className="e-home-faq">
                                            <div className="e-home-faq-wrapper e-col pe-lg-4 pe-md-4 pe-0">
                                                {
                                                    faq.map((item, key) => {
                                                        if (key < 3) {
                                                            return (
                                                                <Accordion.Item eventKey={key} className="mt-4">
                                                                    <Accordion.Header>{item.title}</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {item.answer}
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                            <div className="e-home-faq-wrapper e-col">
                                                {
                                                    faq.map((item, key) => {
                                                        if (key > 2) {
                                                            return (
                                                                // <div className="">
                                                                <Accordion.Item eventKey={key} className="mt-4">
                                                                    <Accordion.Header>{item.title}</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {
                                                                            key !== 5 ?
                                                                                item.answer
                                                                                :
                                                                                <div>
                                                                                    <p>
                                                                                        We never hold your money or investments. The funds are always in your name with each asset manager, we merely facilitate the transaction. So IF anything were to happen to us you could always:
                                                                                    </p>
                                                                                    <p>
                                                                                        a. Go to CAMS portal online and withdraw the amount
                                                                                    </p>
                                                                                    <p>
                                                                                        b. Go to the AMC website and login using your PAN and withdraw the amount
                                                                                    </p>
                                                                                    <p>
                                                                                        c. Call your AMC customer care who will send you a link to withdraw the amount
                                                                                    </p>
                                                                                    <p>
                                                                                        Either ways we will facilitate this process for each of our customers.
                                                                                    </p>
                                                                                </div>
                                                                        }
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                // </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>

                                        </Accordion>
                                    </div>
                                </div>

                                {/* Contact */}
                                <div className='col-12 mt-5 pt-3 mb-5 text-center'>
                                    <h6 className='e-help-title'>Need help?</h6>
                                    <a href={devconfig.whatsappUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                        <WatsappBtn left={true} image={Icon} className="me-3" />
                                    </a>
                                    <a href={calendlyUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                        <CalendlyBtn left={true} image={CalendlyICon} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
            }
            {
                openModal ?
                    <InvestNowModal
                        haveMandate={haveMandate}
                        location={props.type === 1 ? "overview" : "asset"}
                        details={{}}
                        assetsDetail={assetDetail}
                        callBack={(data, amount) => {
                            console.log('data', data);
                            console.log('stst', amount);
                            if (data === 'SUCCESS') {
                                setSuccessContent(
                                    `We have received your payment of ₹${amount}. 
                                    The orders have been placed with each asset manager and you should 
                                    receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.`
                                );
                                setPaymentStatus(true);
                            }
                            else {
                                setSuccessContent(
                                    `Investment failed please try again `
                                );
                                setPaymentStatus(false);
                            }

                            _successCallback();
                            getOverview();
                        }}
                        unmountModal={() => setOpenModal(false)}
                    />
                    :
                    null
            }

            {
                openWithdrawModal && (
                    <RedeemModal
                        modalClose={() => { setOpenWithdrawModal(false) }}
                        success={(data) => {
                            setWithdrawAmount(data);
                            getOverview();
                            _successCallback();
                        }}
                        heading={(data) => props.heading(data)}
                    />
                )
            }
            {
                successPopup && (
                    <SuccessPayment
                        editScore={false}
                        heading={
                            successContent ?
                                paymentStatus === true ?
                                    'Investment Successful'
                                    :
                                    'Investment Failed'
                                :
                                <>
                                    {
                                        rebalanceSuccessTitle ?
                                            rebalanceSuccessTitle
                                            :
                                            "Withdrawal Successful"
                                    }
                                </>

                        }//type
                        subTitle={!successContent && !rebalanceSuccessContent && `You have successfully withdrawn ₹${withdrawAmount}`}
                        content={
                            successContent ?
                                successContent
                                :
                                <>
                                    {
                                        rebalanceSuccessContent ?
                                            rebalanceSuccessContent
                                            :
                                            `We are processing your withdrawal of ₹${withdrawAmount}. The orders have
                                            been placed with each asset manager and you should
                                            receive your mutual fund allocations in 3-4 days. They will
                                            show on your dashboard then.`
                                    }
                                </>
                        }
                        status={successContent ? paymentStatus : true}//status
                        from="portfolio"
                        close={() => {
                            // if (!status) {
                            // 	window.location.href = "/orders"
                            // }
                        }}
                    />
                )
            }
            {
                openEditScoreModal && (
                    <EditScoreConfirmation
                        modalClose={() => setOpenEditScoreModal(false)}
                        succuss={() => _successCancelEdit()}
                    />
                )

            }
            {
                toast && (
                    <SuccessToast data={toast} />
                )
            }
            {
                openRebalanceModal && (
                    <RebalanceModal
                        editScore={false}
                        success={(msg, status) => {
                            if (status === 1) {
                                setRebalanceSuccessTitle(msg);
                                setRebalanceSuccessContent('Your rebalance sell orders have been initiated, and the purchase order will be auto-created within a couple of business days.');
                                setSuccessPopUp(true);
                            }
                            else {
                                setToast(msg)
                            }
                            let tmp = profileData;
                            tmp.rebalance_available = null;
                            setProfileData({ ...tmp });

                        }}
                        modalClose={() => setRebalanceModal(false)}
                    />
                )
            }
            <EditScore />
            <RebalanceUpdate success={() => {
                setRebalanceModal(true)
            }} />

        </>

    )
}

export default Home