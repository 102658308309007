import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Home from '../../assets/images/sidebar/home.svg';
import HomeActive from '../../assets/images/sidebar/home-active.svg';
import Asset from '../../assets/images/sidebar/asset.svg';
import AssetActive from '../../assets/images/sidebar/asset-active.svg'
import Portfolio from '../../assets/images/sidebar/portfolio.svg';
import PortfolioActive from '../../assets/images/sidebar/portfolio-active.svg';
import Orders from '../../assets/images/sidebar/orders.svg';
import OrdersActive from '../../assets/images/sidebar/orders-active.svg';
import Calender from '../../assets/images/sidebar/calender.svg';
import CalenderActive from '../../assets/images/sidebar/calender-active.svg';
import Bell from '../../assets/images/sidebar/bell.svg';
import BellActive from '../../assets/images/sidebar/bell-active.svg';
import Clipboard from '../../assets/images/sidebar/clipboard.svg';
import ClipboardActive from '../../assets/images/sidebar/clipboard-active.svg';
import Settings from '../../assets/images/sidebar/settings.svg';
import SettingsActive from '../../assets/images/sidebar/settings-active.svg';
import Book from '../../assets/images/sidebar/book.svg';
import Message from '../../assets/images/sidebar/message.svg';
import Users from '../../assets/images/sidebar/users.svg';
import Logout from '../../assets/images/sidebar/logout.svg';
import Close from '../../assets/images/new-design/modalClose.svg';
import Right from '../../assets/images/new-design/arrow-active.svg'

import MintdLogo from '../../assets/images/menu/logo.svg';

import styles from '../../styles/components/sidebar.module.scss';

import devconfig from '../../config/config';
import APIGet from '../../services/getApi';


const Sidebar = (props) => {

    const [menuIndex, setMenuIndex] = useState(null);



    const menu = [
        {
            title: 'MAIN MENU',
            data: [
                {
                    name: 'Home',
                    icon: Home,
                    iconActive: HomeActive,
                    link: '/home',
                    active: true
                },
                {
                    name: 'Assets',
                    icon: Asset,
                    iconActive: AssetActive,
                    link: '/assets',
                    active: props.activeUser,
                    id: props.id,
                    tourLink: props.link
                },
                {
                    name: 'Mintd 360',
                    icon: Portfolio,
                    link: '/portfolio-tracker',
                    iconActive: PortfolioActive,
                    active: true,
                },
                {
                    name: 'Orders',
                    icon: Orders,
                    link: '/orders',
                    iconActive: OrdersActive,
                    active: props.activeUser
                },
                {
                    name: 'Manage SIPs',
                    icon: Calender,
                    link: '/sip',
                    iconActive: CalenderActive,
                    active: props.activeUser
                },
                {
                    name: 'Notifications',
                    icon: Bell,
                    link: '/notifications',
                    iconActive: BellActive,
                    active: true,

                },
                {
                    name: 'Reports',
                    icon: Clipboard,
                    link: '/reports',
                    iconActive: ClipboardActive,
                    active: props.activeUser
                },
                {
                    name: 'Account settings',
                    icon: Settings,
                    link: '/settings',
                    iconActive: SettingsActive,
                    active: props.activeUser,
                    id: props.settingId,
                    tourLink: props.link
                },
            ]
        },
        {
            title: 'OTHERS',
            data: [
                {
                    name: 'FAQs',
                    icon: Book,
                    link: 'https://www.mintd.in/faqs',
                    iconActive: Book,
                    active: true
                },
                {
                    name: 'Contact us',
                    icon: Message,
                    link: 'https://www.mintd.in/contact-us',
                    iconActive: Message,
                    active: true
                },
                {
                    name: 'Community',
                    icon: Users,
                    link: 'https://www.mintd.in/community',
                    iconActive: Users,
                    active: true
                },
                {
                    name: 'Log Out',
                    icon: Logout,
                    link: null,
                    iconActive: Logout,
                    active: true

                }

            ]
        }


    ]

    // const detail = JSON.parse(localStorage.getItem("ajs_user_traits"))

    const _checkPortfolio = () => {
        let url = devconfig.portfolio + '/assets/assets/dashboard';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                if (response.data.allocations.asset_types.length) {
                    _analyticsUpdate(true)
                }
                else {
                    _analyticsUpdate(false)
                }
            }
        })
    }
    const _analyticsUpdate = (status, id) => {
        console.log('status');
        window.analytics.identify(props.userDetail?.user_id, {
            'Portfolio tracker': status,
        });
    }

    const _analyticsTrackUserDetails = (index) => {
        window.analytics.identify(props.userDetail?.user_id, {
            path: index === 0 ? 'faq' : index === 1 ? 'contact' : 'community'
        });
    }

    let navigator = useNavigate();

    return (
        <>
            <div className={`${styles.e_sidebar_wrapper} e-side-bar p-3 pb-5 ps-0 `} >
                <div id={props.settings} className="pb-5 ps-2">
                    <img src={MintdLogo} className="mt-2 e-sidebar-logo" />
                    <img src={Close} className="e-side-abr-close-btn" onClick={() => props.closeMenu()} />
                    {
                        menu.map((section, key) => {
                            return (
                                <div className={`${styles.e_section}`}>
                                    <h6 className={`${styles.e_title}`} >
                                        {section.title}
                                    </h6>
                                    {
                                        section.data.map((item, index) => {
                                            return (
                                                <div
                                                    id={item.id}
                                                    className={
                                                        window.location.pathname === '/scrip-detail' && index === 1 && key === 0 ?
                                                            `${styles.e_navbar} ${styles.e_active_nav} position-relative  d-block`
                                                            :
                                                            window.location.pathname === '/allocation' && index === 2 && key === 0 ?
                                                                `${styles.e_navbar} ${styles.e_active_nav} position-relative  d-block`
                                                                :
                                                                window.location.pathname === '/' && index === 0 && key === 0 ?
                                                                    `${styles.e_navbar} ${styles.e_active_nav} position-relative  d-block`
                                                                    :
                                                                    window.location.pathname === item.link ?
                                                                        `${styles.e_navbar} ${styles.e_active_nav} position-relative  d-block`
                                                                        :
                                                                        `${styles.e_navbar} position-relative  d-block`
                                                    }
                                                    onMouseEnter={() => { if (key === 0) setMenuIndex(index) }}
                                                    onMouseLeave={() => { setMenuIndex(null) }}
                                                    onClick={() => {
                                                        if (item.tourLink) {
                                                            props.callBack();
                                                            return
                                                        }
                                                        if (key === 0) {
                                                            if (item.active) {
                                                                navigator(item.link)
                                                            }
                                                            else {
                                                                navigator('/onboard')
                                                            }
                                                            if (window.innerWidth <= 992) {
                                                                props.closeMenu();
                                                            }
                                                        } else {
                                                            if (item.link === null) {
                                                                localStorage.clear();
                                                                window.location.href = "/login"
                                                            } else {
                                                                _analyticsTrackUserDetails(index);
                                                                window.open(item.link, '_blank');
                                                            }

                                                            if (window.innerWidth <= 992) {
                                                                props.closeMenu();
                                                            }
                                                        }

                                                        _checkPortfolio();
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            window.location.pathname === '/scrip-detail' && index === 1 && key === 0 ?
                                                                item.iconActive
                                                                :
                                                                window.location.pathname === '/allocation' && index === 2 && key === 0 ?
                                                                    item.iconActive
                                                                    :
                                                                    window.location.pathname === item.link ?
                                                                        item.iconActive
                                                                        :
                                                                        item.icon
                                                        }
                                                        className="me-2"
                                                    />
                                                    {item.name}
                                                    {
                                                        item.name === 'Notifications' && props.count > 0 && (
                                                            <span className={`${styles.e_count} ms-4`}>{props.count}</span>
                                                        )
                                                    }
                                                    {
                                                        item.active === false ? (
                                                            <>
                                                                {
                                                                    menuIndex === index && key !== 1 && (
                                                                        <span className={`${styles.e_badge}`}> KYC Required </span>
                                                                    )
                                                                }
                                                            </>

                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    {
                                                        item.name === 'Mintd 360' && (
                                                            <span className='position-absolute e-new-badge'>
                                                                New
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }



                </div>
                {
                    props.tour === "true" ?
                        null :
                        <div className={"row e-info " + styles.e_info} onClick={() => {
                            navigator("/settings")
                        }}>


                            {
                                props.profile?.profile_picture ?
                                    <div className={`col-3`}>
                                        <img src={props.profile?.profile_picture} alt="" width="32px" className={` ${styles.e_placeholder} me-2`}/>
                                    </div>
                                    :
                                    <div className={`col-3 `}>
                                        <div className={`${styles.e_placeholder} ps-0`}>
                                        {props.profile?.first_name[0]}
                                        </div>
                                    </div>
                            }


                            <div className={"col-7 px-0 " + styles.e_name}>
                                {props.profile?.first_name}
                                <p className={"mb-0 " + styles.e_email}>{props.profile?.email}</p>
                            </div>
                            <div className={"col-2 px-0 text-end"}>
                                <img src={Right} alt="" />
                            </div>
                        </div>
                }

            </div>


        </>
    )
}

export default Sidebar;