/*
 *   File : radio.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Radio Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

/*import package*/
import { useState, useEffect } from 'react';

/*import styles*/
import styles from '../../styles/components/radioButton.module.scss';

const RadioButton = (props) => {

  return (
    <div className={`${styles.e_radiobutton} ${props.wrapperClass} d-flex position-relative`}
      onClick={() => props.onClick()}
      id={props.id ? props.id : undefined} >
      <input style={{ verticalAlign: "middle" }}
        id={props.id ? props.id : props.radioId ? props.radioId : props.upiId}
        onClick={() => { props.onClick(); console.log(props.checked) }}
        type="radio" name={props.name}
        checked={props.checked}
        disabled={props.disabled}
      />
      <div className={`${styles.e_radio} e-radio`}></div>
      <label for={props.id ? props.id : props.radioId ? props.radioId : props.upiId}
         >{props.label}</label>
    </div>
  );
}

export default RadioButton;