/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { TextArea } from '../input/index';
import { SecondaryBtn, PrimaryBtn } from '../cta/index';
import { Filter } from '../card/index';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/* import assets */
import Delete from '../../assets/images/modal/delete.svg'

/*Service import*/
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { message } from 'antd';

export default function OrderFilter(props) {
    const [ filterData, setFilterData ] = useState(null);
	const [ typeFilter, setTypeFilter] = useState([]);
	const [ statusFilter, setStatusFilter] = useState([]);

	useEffect(() => {
		var myModalEl = document.getElementById('order-filter')
		myModalEl.addEventListener('hidden.bs.modal', function (event) {
			props.modalClose()
		})
        
	}, [])

    useEffect(()=>{
        if ( props.data) {
			setFilterData(props.data)
		}
            
    },[props.data])

	useEffect(()=>{
		setTypeFilter(props.typeFilter);
		setStatusFilter(props.statusFilter)
	},[props.typeFilter, props.statusFilter])

    const _filterSubmit = (tempType, tempStatus) => {
        props.filterSubmit(tempType, tempStatus);
        $('#order-filter').trigger('click')
    }

	return (
		<div className={`${styles.e_edit_modal} modal fade`} id="order-filter" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h6></h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					{/* <div className="container ">
						<div className="row pt-3 ">
                            <div className='col-12'> */}
                            <Filter
								typeFilter={typeFilter}
								statusFilter={statusFilter}
                                submit={(tempType, tempStatus) => {
                                    _filterSubmit(tempType, tempStatus)
                                }}
                                data={filterData}
                            />
                            {/* </div>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	)
}
