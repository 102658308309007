/*
 *   File : upload.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : upload.js
 *   Integrations : 
 *   Version : v1.0
 */

/*Package importing*/
import React from 'react';
import $ from 'jquery';

/*import Services */
import s3Upload from './awsHelper';
import { message } from 'antd';

export default class Uploader extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      file: "",
    }
  }

  async uploadDocument(e) {
    console.log('change or dragged')
    this.props.uploadStatus(true)
    if(e.target.files.length){
      var file = e.target.files[0]; 
      var tempFileName = file.name;
      var fileReader = new FileReader();
      tempFileName = tempFileName.replaceAll(' ', '_');
      var tempKey = this.props.tempKey
      tempKey =  tempKey + "/" +  new Date().getTime()
      if (e.target.files != null) {
        if(this.props.imageSelected){
          this.props.imageSelected();
        }
        
      if (file.size <= 5 * 1024 * 1024){
        var fileType = tempFileName.split("/").pop();
        var response = await s3Upload(tempFileName, tempKey, file, fileType);
        // console.log(response);
        var data = JSON.stringify({
          'file': file,
          'fileName': tempFileName,
          'key': tempKey
        });
        if(response.status){
          await this.setState({ file: response.cdn_url});
          if (this.props.excel) {
            console.log('fileee', file);
            this.props.uploadSucess(response.cdn_url, file);
          }else{
            this.props.uploadSucess(response.cdn_url);
          }
          
        }
        else{
          await this.setState({ apiLoading: false});
        }
        this.props.uploadStatus(false);
      }else{
        this.props.uploadStatus(false);
        message.warning('The uploaded file exceeds the maximum size of 5mb.')
      }
    }
  }
    else{
      this.props.uploadStatus(false);
    }
  }

  render() {
    return (
     <>
     {
       !this.state.reload?
        <input 
        id={this.props.id} 
        className={this.props.className} 
        type="file" 
        accept={this.props.accept ? this.props.accept : ".pdf, image/jpeg, image/png"}
        onChange={this.uploadDocument.bind(this)}
        {...this.props}
        onClick={(e)=>{e.target.value=""}}
      />
      :
      null
     }
     </>
    );
  }
}

