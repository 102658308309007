/*
 *   File : bank.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Bank details
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 05-01-2022
 */

/* import packages */
import React, { useState, useEffect, useRef } from 'react';

/* import assets */
import More from '../../assets/images/profile/more.svg';
import Check from '../../assets/images/profile/check.svg';

/* import components */
import { Toast } from '../../components/note';
import { Loader } from '../../components/loader';
import ProgressBar from '../../components/loader/progressLoader';

/*import services */
import APIGet from '../../services/getApi';
import devconfig from '../../config/config';
import { Modal } from 'bootstrap';
import { AddBank, SuccessPayment } from '../../components/modal';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta';

const BankDetails = () => {
    const [more, setMore] = useState(false);
    const wrapperRef = useRef(null);
    const [bankData, setBankData] = useState({});
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(true);
    const [bank, setBank] = useState(false)
    const [success, setSuccess] = useState(false);
    const [mandateLoader, setMandateLoader] = useState(false);
    const [popupBlockers, setPopupBlockers] = useState(false);

    const closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setMore(false)
        }
    }

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 5000)
        }
    }, [error])

    useEffect(() => {
        window.scrollTo({ top: 0, behaviour: "smooth" })
    }, [])

    useEffect(() => {
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);

    useEffect(() => {
        getBank();
    }, []);

    useEffect(() => {
        if (bank) {
            var modal = new Modal(document.getElementById("editBank"), {});
            modal.show()
        }
    }, [bank])

    /*------------get bank details api ---*/
    const getBank = () => {
        setLoader(true)
        let url = devconfig.user + '/profile/get-bankdetails';

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setBankData(response.data);
                setLoader(false);
            }
            else {
                setError(response.message);
                setLoader(false);
            }
        })
    }

    const createMandate = () => {
        setMandateLoader(true);
        let url = devconfig.user + '/mandate/create';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setMandateLoader(false);
                var new_window = window.open(response.data.mandate_url);

                if (new_window !== null) {
                    var timer = setInterval(function () {
                        if (new_window.closed) {
                            clearInterval(timer);
                            getBank();
                        }
                    }, 1000);
                }
                else {
                    setPopupBlockers(true);
                }

            }
            else {
                setMandateLoader(false);
            }
        })
    }

    return (
        <>
            {
                loader ? (
                    <ProgressBar />
                )
                    :
                    <div className="e-profile-detail pb-5 mb-5">
                        <div className='col-lg-8 col-md-10 col-sm-12'>
                            <div className='row '>
                                <div className='col-12'>
                                    <h5 className='e-title'>
                                        Bank details
                                    </h5>
                                </div>

                                <div className='col-12'>
                                    <label>Bank name</label>
                                    <div
                                        className={
                                            Object.keys(bankData).length > 0 && bankData.bank_details.is_verified === true ?
                                                'e-verified'
                                                :
                                                'e-credential-wrapper'
                                        }
                                    >
                                        {
                                            Object.keys(bankData).length > 0 ?
                                                bankData.bank_details.bank_name : null
                                        }
                                    </div>
                                </div>
                                <div className='col-12 mt-4'>
                                    <label>Account number</label>
                                    <div className='e-credential-wrapper'>
                                        {
                                            Object.keys(bankData).length > 0 ?
                                                bankData.bank_details.account_number : null
                                        }
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <SecondaryBtn
                                        name="Edit"
                                        className="e-nominee-edit mt-3"
                                        handleClick={() => setBank(true)}
                                    />
                                </div>
                                <div className='col-12 mt-4 mb-2'>
                                    <h5 className='e-title'>
                                        Autopay
                                    </h5>
                                </div>
                                {
                                    bankData?.mandate_status === "SUCCESS" ?
                                        <div className='row'>
                                            <div className='col-12 '>
                                                <label>Autopay ID</label>
                                                <div className='e-credential-wrapper'>
                                                    {
                                                        Object.keys(bankData).length > 0 ?
                                                            bankData.auto_pay_details.auto_pay_id : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-12 mt-4'>
                                                <label>Bank name</label>
                                                <div className='e-credential-wrapper'>
                                                    {
                                                        Object.keys(bankData).length > 0 ?
                                                            bankData.auto_pay_details.bank_name : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-12 mt-4'>
                                                <label>Account number</label>
                                                <div className='e-credential-wrapper'>
                                                    {
                                                        Object.keys(bankData).length > 0 ?
                                                            bankData.auto_pay_details.account_number : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row">
                                            <div className='col-lg-12 py-3'>
                                                <PrimaryBtn
                                                    name={mandateLoader ? 'Processing' : "Create mandate"}
                                                    className={mandateLoader && 'e-btn-loader'}
                                                    handleClick={() => createMandate()}

                                                />
                                                {
                                                    popupBlockers ?
                                                        <p style={{ color: "red", paddingTop: "30px" }}>Disable popup blocker to continue with Payment Gateway</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
            }
            {
                error !== null ?
                    <Toast data={error} type="fail" />
                    :
                    null
            }
            {
                bank ?
                    <AddBank
                        data={bankData}
                        Close={() => setBank(false)
                        }
                        Added={() => {
                            // setSuccess(true);
                            getBank()
                        }} />
                    :
                    null
            }
        </>

    );
}



export default BankDetails;