// import packages
import { useState, useEffect } from "react";
import moment from "moment";
import $ from 'jquery';

// import service
import Config from '../../config/config';
import APIPost from "../../services/postApi";
import COLOUR from '../../config/color';

// Asset import
import TooltipIcon from '../../assets/images/new-design/info.svg';
import UploadIcon from '../../assets/images/new-design/upload.svg';
import Arrow from '../../assets/images/onboard/submit.svg';
import License from '../../assets/images/onboard/license.svg';
import Aadhar from '../../assets/images/onboard/aadhar.svg';
import Voter from '../../assets/images/onboard/voter.svg';
import Passport from '../../assets/images/onboard/passport.svg';
import Arrows from '../../assets/images/onboard/Arrow-another.svg';

// import component
import { IconStyleToolTip } from '../../components/tooltip/index';
import { CheckBox, Select, SingleDate } from '../../components/input/index';
import Uploader from '../../components/fileUploader/upload';
import { Toast } from "../../components/note";
import { PrimaryBtn } from "../../components/cta";
import Input from '../../components/input/loginInput';


import { Proof } from "../../components/card";


const AddressDetail = (props) => {
    const [address1, setAddress1] = useState(null);
    const [address2, setAddress2] = useState(null);
    const [state, setSelectedState] = useState(null);
    const [city, setCity] = useState(null);
    const [cityOptions, setCityOptions] = useState(null);
    const [pinCode, setPincode] = useState(null);
    const [sameAddress, setSameAddress] = useState(true);
    const [address3, setAddress3] = useState(null);
    const [address4, setAddress4] = useState(null);
    const [city1, setCity1] = useState(null);
    const [pinCode1, setPincode1] = useState(null);
    const [state1, setSelectedState1] = useState(null);
    const [toggleProofInfo, setToggleProofInfo] = useState(false);
    const [addressProofType, setAddressProofType] = useState(null);
    const [proofNumber, setProofNumber] = useState(null);
    const [uploadingFront, setUploadingFront] = useState(false);
    const [addressProofFront, setAddressProofFront] = useState(null);
    const [addressProofFrontUrl, setAddressProofFrontUrl] = useState(null);
    const [uploadingBack, setUploadingBack] = useState(false);
    const [addressProofBack, setAddressProofBack] = useState(null);
    const [addressProofBackUrl, setAddressProofBackUrl] = useState(null);
    const [issueDate, setIssueDate] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [KYCverified, setKYCVerified] = useState(true);
    const [error, setError] = useState(null);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        if (props.progressData) {
            setKYCVerified(props.progressData.is_kyc_complaint);
            let address_data = props.progressData.address_data;
            if (address_data.permanent_address) {
                // address data
                setAddress1(address_data.permanent_address.address_line_1);
                setAddress2(address_data.permanent_address.address_line_2);
                setSelectedState({ value: address_data.permanent_address.state_id, label: address_data.permanent_address.state });
                setCity({ value: address_data.permanent_address.city_id, label: address_data.permanent_address.city });
                setPincode(address_data.permanent_address.pincode);
                setSameAddress(address_data.is_same_correspondence_address);
                setAddress3(address_data.correspondence_address.address_line_1);
                setAddress4(address_data.correspondence_address.address_line_2);
                setSelectedState1({ value: address_data.correspondence_address.state_id, label: address_data.correspondence_address.state });
                setCity1({ value: address_data.correspondence_address.city_id, label: address_data.correspondence_address.city });
                setPincode1(address_data.correspondence_address.pincode);
                setAddressProofType({ value: address_data.proof_type_id, label: address_data.proof_type_name });
                setProofNumber(address_data.proof_number);
                setIssueDate(address_data.proof_issue_date === "None" ? null : address_data.proof_issue_date);
                setExpiryDate(address_data.proof_expiry_date === "None" ? null : address_data.proof_expiry_date);

                if (address_data.proof !== null) {
                    var str = address_data.proof.split("/");
                    setAddressProofFront(str[str.length - 1]);
                    setAddressProofFrontUrl(address_data.proof);
                    var str1 = address_data.proof_back.split("/");
                    setAddressProofBack(str1[str1.length - 1]);
                    setAddressProofBackUrl(address_data.proof_back);
                }

            }
        }
    }, [props.progressData])


    // get city options 
    const _getCities = (city) => {

        let url = Config.user + '/auth/get-city';
        let data = JSON.stringify({
            'state_id': city
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                let tempArray = [];
                response.data && response.data.map((item, key) => {
                    return (
                        tempArray.push({ value: item.id, label: item.name })
                    )
                })
                setCityOptions(tempArray);
            }
        })
    }

    const _checkCorrespondAddress = (e) => {
        const status = e.target.checked
        setSameAddress(status)
        if (status === true) {
            setAddress3(address1)
            setAddress4(address2)
            setCity1(city)
            setPincode1(pinCode)
            setSelectedState1(state)
        }
        else {
            setAddress3("")
            setAddress4("")
            setCity1({ label: "City", value: null })
            setPincode1("")
            setSelectedState1({ label: "State", value: null })
        }
    }

    const _submitAddressData = () => {
        let status = _validationAddressForm();
        if (status) {
            setApiLoader(true);
            let url = Config.user + '/auth/add-address'
            let data = JSON.stringify({
                permanent_address: {
                    address_line_1: address1,
                    address_line_2: address2,
                    state_id: state.value,
                    city: city.value,
                    pincode: parseInt(pinCode),
                },
                is_same_correspondence_address: sameAddress,
                proof_number: proofNumber,
                // address_proof_url: addressProofFrontUrl,
                proof_type: addressProofType?.value,
                proof: addressProofFrontUrl,
                proof_back: addressProofBackUrl,
                issue_date: addressProofType?.label === "Voter ID" ? null : issueDate,
                expiry_date: addressProofType?.label === "Voter ID" ? null : expiryDate,
                correspondence_address: sameAddress === false ? {
                    address_line_1: address3,
                    address_line_2: address4,
                    state_id: sameAddress === false ? state1.value : undefined,
                    city: sameAddress === false ? city1.value : undefined,
                    pincode: parseInt(pinCode1)
                }
                    :
                    undefined
            })
            APIPost(url, data).then((response) => {
                if (response.status_code === 200) {
                    props.success(response.data.signup_status);
                    props.updateUserData('address_data', response.data.address_data);
                    setApiLoader(false);
                } else {
                    setError(response.message);
                    setApiLoader(false);
                }
            })
        }
    }

    /* -- validation for address data fields ---*/
    const _validationAddressForm = () => {
        setError(null);
        if (sameAddress === true) {
            if (address1 === null || address1.length < 5) {
                setError("Address line 1 cannot be left blank");
                showFormValidationError("address1 e-input-id")
                return false;
            } else if (address2 === null || address2.length < 5) {
                setError("Address line 2 cannot be left blank");
                showFormValidationError("address2 e-input-id")
                return false;
            } else if (state?.value === null) {
                setError("Please select state");
                showFormValidationSelect("stateValue1")
                return false;
            } else if (city?.value === null) {
                setError("Please select city");
                showFormValidationSelect("cityValue1")
                return false;
            } else if (pinCode.length < 6) {
                setError("Invalid pincode");
                showFormValidationError("pinCode")
                return false;
            } else if (addressProofType?.value === null && !KYCverified) {
                setError("Select an address proof");
                showFormValidationSelect("proof")
                return false;
            } else if (proofNumber === "" && !KYCverified) {
                setError("Enter address proof");
                showFormValidationError("addressProof")
                return false;
            } else if (addressProofFrontUrl === "" && !KYCverified) {
                setError("Please upload front side of address proof");
                showFormValidationError("addressProofFront")
                return false;
            } else if (addressProofBackUrl === "" && !KYCverified) {
                setError("Please upload back side of address proof");
                showFormValidationError("addressProofBack")
                return false;
            }
            if (addressProofType?.label != "Voter ID") {
                if (issueDate === null && !KYCverified) {
                    setError("Enter issue date");
                    showFormValidationError("issueDate")
                    return false;
                } else if (expiryDate === null && !KYCverified) {
                    setError("Enter expiry date");
                    showFormValidationError("expiryDate")
                    return false;
                }
                return true;
            }
        }
        else {
            if (address1 === null || address1.length < 5) {
                setError(" Address line 1 cannot be left blank");
                showFormValidationError("address1 e-input-id")
                return false;
            } else if (address2 === null || address2.length < 5) {
                setError("Address line 2 cannot be left blank");
                showFormValidationError("address2 e-input-id")
                return false;
            } else if (state?.value === null) {
                setError("Please select state");
                showFormValidationSelect("stateValue1")
                return false;
            } else if (city?.value === null) {
                setError("Please select city");
                showFormValidationSelect("cityValue1")
                return false;
            } else if (pinCode.length < 6) {
                setError("Invalid pincode");
                showFormValidationError("pinCode")
                return false;
            }
            else if (address3 === null || address3.length < 5) {
                setError("Correspondence Address line 1 cannot be left blank");
                showFormValidationError("address3 e-input-id")
                return false;
            } else if (address4 === null || address4.length < 5) {
                setError("Correspondence Address line 2 cannot be left blank");
                showFormValidationError("address4 e-input-id")
                return false;
            } else if (state1?.value === null) {
                setError("Please select correspondence state");
                showFormValidationSelect("stateValue2")
                return false;
            } else if (city1?.value === null) {
                setError("Please select correspondence city");
                showFormValidationSelect("cityValue2")
                return false;
            } else if (pinCode1.length < 6) {
                setError("Invalid pincode");
                showFormValidationError("pincode1")
                return false;
            } else if (addressProofType?.value === null && !KYCverified) {
                setError("Select an address proof");
                showFormValidationSelect("proof")
                return false;
            } else if (proofNumber === "" && !KYCverified) {
                setError("Enter address proof");
                showFormValidationError("addressProof")
                return false;
            }
            else if (addressProofBackUrl === "" && !KYCverified) {
                setError("Please upload back side of address proof");
                showFormValidationError("addressProofBack")
                return false;
            } else if (addressProofFrontUrl === "" && !KYCverified) {
                setError("Please upload front side of address proof");
                showFormValidationError("addressProofFront")
                return false;
            } else if (issueDate === null && !KYCverified) {
                setError("Enter issue date");
                showFormValidationError("issueDate")
                return false;
            } else if (expiryDate === null && !KYCverified) {
                setError("Enter expiry date");
                showFormValidationError("expiryDate")
                return false;
            }
        }
        return true
    }

    /* validation to highlight fields */
    const showFormValidationError = (field) => {
        var ele = document.getElementById(field);
        ele.style.border = `solid 1.5px ${COLOUR.red}`
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* validation for select */
    const showFormValidationSelect = (field) => {
        const ele = document.getElementById(field);
        ele.className = "e-select-error"
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* clearing validation for select */
    const clearFormValidationSelect = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.className = "e-select-wraper";
        }
    }

    /* clearing validation fields */
    const clearFormValidationError = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.style.border = null;
        }
    }

    const _validForm = () => {
        let valid = false;
        if (KYCverified === true) {
            if (sameAddress === true) {
                if (address1 && address2 && state && city && pinCode) {
                    valid = true
                } else {
                    valid = false
                }
            } else {
                if (address1 && address2 && state && city && pinCode
                    && address3 && address4 && state1 && city1 && pinCode1
                ) {
                    valid = true
                } else {
                    valid = false
                }
            }
        } else {
            if (sameAddress === true) {
                if (address1 && address2 && state && city && pinCode
                    && addressProofType && proofNumber && addressProofFront &&
                    addressProofBack && issueDate
                ) {
                    if (addressProofType?.label !== "Voter ID") {
                        if (expiryDate) {
                            valid = true
                        }
                    } else {
                        valid = true
                    }

                } else {
                    valid = false
                }
            } else {
                if (address1 && address2 && state && city && pinCode
                    && address3 && address4 && state1 && city1 && pinCode1
                    && addressProofType && proofNumber && addressProofFront &&
                    addressProofBack && issueDate
                ) {
                    if (addressProofType?.label !== "Voter ID") {
                        if (expiryDate) {
                            valid = true
                        }
                    } else {
                        valid = true
                    }
                } else {
                    valid = false
                }
            }
        }
        return valid;
    }

    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-12  e-mobile-confirm-wrap pb-5 mb-5">
            <div className="e-process-wrap position-relative">
                <h5 className="e-onboard-form-title">
                    Address Details
                </h5>
                {/* Form */}
                <div className="row ">
                    <div className="col-lg-12 mt-2">
                        <div className="e-wraper" >
                            <div className="row">
                                {
                                    KYCverified === false ?
                                        <>
                                            <div className="col-lg-6 col-md-6 col-12 pb-lg-2 pb-sm-0 pb-0 mb-3 ">
                                                <label className="">
                                                    Address Proof
                                                    <span className='star px-1'> * </span>
                                                    <img src={TooltipIcon}
                                                        className="e-tooltip-hover "
                                                        style={{ verticalAlign: 'text-top' }}
                                                        onMouseEnter={() => setToggleProofInfo(true)}
                                                        onMouseLeave={() => setToggleProofInfo(false)}
                                                    />
                                                </label>
                                                {toggleProofInfo && (
                                                    <IconStyleToolTip
                                                        className="e-tooltip-address"
                                                        content="
                                                            This can be a government-issued ID such as a Voter's id, 
                                                            Passport or Driving license, a bank statement, 
                                                            a salary slip, or any utility bill with your name and address on it."
                                                    />
                                                )}
                                                <Select
                                                    id="proof"
                                                    options={props.proofOptions}
                                                    placeholder="Select"
                                                    value={addressProofType}
                                                    className="e-select-sort"
                                                    selectchange={(value) => {
                                                        setAddressProofType(value);
                                                        clearFormValidationSelect("proof")
                                                        setProofNumber("");
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 pb-lg-2 pb-sm-0 pb-0 mb-3 ">
                                                <label
                                                    className=""
                                                    style={{ textTransform: 'capitalize' }}
                                                >
                                                    {
                                                        addressProofType ?
                                                            `Enter ${addressProofType.label}  Number `
                                                            :
                                                            'ID Proof Number '
                                                    }
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <Input
                                                    id="addressProof"
                                                    label={addressProofType ? ` ${addressProofType.label}  number ` : 'Proof number '}
                                                    value={proofNumber}
                                                    valueChange={(value) => {
                                                        setProofNumber(value);
                                                    }}
                                                    type="text"
                                                />
                                            </div>
                                        </>
                                        :
                                        null
                                }
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label className="mb-1">
                                        Address Line 1
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Input
                                        inputId="address1"
                                        label="Address line 1"
                                        value={address1}
                                        valueChange={(value) => {
                                            setAddress1(value);
                                        }}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mt-3 mt-lg-0 mt-md-0">
                                    <label className="mb-1">
                                        Address Line 2
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Input
                                        inputId="address2"
                                        label="Address line 2"
                                        value={address2}
                                        valueChange={(value) => {
                                            setAddress2(value);
                                        }}
                                    />
                                </div>

                                <div className="col-lg-11 col-md-11 col-sm-12  my-md-4 my-3">
                                    {/* <div className="e-onboard-sub-info p-3">
                                        <div className="row">
                                            <div className="col-11">
                                                <h5>
                                                    Please make sure to copy your address word-to-word from the ID proof you've uploaded
                                                </h5>
                                            </div>
                                            <div className="col-1">
                                                <img src={Flag} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <Proof
                                        addressProofType={addressProofType}
                                        arrow={addressProofType?.label === "Voter ID" ? Arrows : null}
                                        width={
                                            addressProofType?.label === "Voter ID" ?
                                                '240px'
                                                :
                                                addressProofType?.label === "Passport" ?
                                                    "213px"
                                                    :
                                                    '157px'

                                        }
                                        left={
                                            addressProofType?.label === "Voter ID" ?
                                                '130px'
                                                :
                                                '144px'
                                        }
                                        top={
                                            addressProofType?.label === "Voter ID" ?
                                                "163px"
                                                :
                                                addressProofType?.label === "Passport" ?
                                                    "185px"
                                                    :
                                                    null
                                        }
                                        proof={
                                            addressProofType?.label === "Driving licence" ?
                                                License
                                                :
                                                addressProofType?.label === "Voter ID" ?
                                                    Voter
                                                    :
                                                    addressProofType?.label === "Passport" ?
                                                        Passport
                                                        :
                                                        Aadhar
                                        } />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mb-3">
                                    <label className="mb-1">
                                        State
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Select
                                        id="stateValue1"
                                        options={props.stateOption}
                                        value={state}
                                        placeholder="Select"
                                        className="e-select-sort"
                                        selectchange={(temp) => {
                                            setSelectedState(temp);
                                            _getCities(temp.value);
                                            setCity({ label: "City", value: null })
                                            clearFormValidationSelect("stateValue1");
                                        }}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mb-3">
                                    <label className="mb-1">
                                        City
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Select
                                        id="cityValue1"
                                        options={cityOptions && cityOptions.length ? cityOptions : []}
                                        value={city}
                                        placeholder="Select"
                                        className="e-select-sort"
                                        selectchange={(value) => {
                                            setCity(value);
                                            clearFormValidationSelect("cityValue1")
                                        }}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 ">
                                    <label className="mb-1">
                                        Pin Code
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Input
                                        id="pinCode"
                                        label="Pincode"
                                        value={parseInt(pinCode)}
                                        valueChange={(value) => {
                                            setPincode(value);
                                        }}
                                        type="number"
                                    />
                                </div>
                                <div className="col-lg-12 mt-2 pt-2">
                                    <CheckBox
                                        className="e-onboard-check-box"
                                        checkboxText="This address is same as correspondence address"
                                        onChange={(e) => {
                                            _checkCorrespondAddress(e)
                                        }}
                                        check={sameAddress}
                                    />
                                </div>
                                {
                                    sameAddress === false && (
                                        <>
                                            <div className="col-lg-6 col-md-6 col-12 pt-3">
                                                <label className="">
                                                    Address line 1
                                                    <span className='star px-1'> * </span></label>
                                                <Input
                                                    inputId="address3"
                                                    label="Address line 1"
                                                    value={address3}
                                                    valueChange={(value) => {
                                                        setAddress3(value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 pt-3">
                                                <label className="">
                                                    Address line 2
                                                    <span className='star px-1'> * </span></label>
                                                <Input
                                                    inputId="address4"
                                                    label="Address line 2 "
                                                    value={address4}
                                                    valueChange={(value) => {
                                                        setAddress4(value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 pt-3 mb-3">
                                                <label className="">
                                                    State
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <Select
                                                    id="e-stateValue2"
                                                    options={props.stateOption}
                                                    defaultValue={state1}
                                                    placeholder="Select"
                                                    // defaultValue={selected1}
                                                    className="e-select-sort"
                                                    value={state1}
                                                    selectchange={(temp) => {
                                                        setSelectedState1(temp);
                                                        _getCities(temp.value);
                                                        setCity1({ label: "City", value: null })
                                                        clearFormValidationSelect("stateValue2");
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 pt-3 mb-3">
                                                <label className="">
                                                    City
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <Select
                                                    id="cityValue2"
                                                    options={cityOptions && cityOptions.length ? cityOptions : []}
                                                    value={city1}
                                                    placeholder="Select"
                                                    className="e-select-sort"
                                                    selectchange={(value) => {
                                                        setCity1(value);
                                                        clearFormValidationSelect("cityValue2");
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 ">
                                                <label className="">
                                                    Pincode
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <Input
                                                    label="Pincode"
                                                    id="pincode1"
                                                    value={parseInt(pinCode1)}
                                                    valueChange={(value) => {
                                                        setPincode1(value);
                                                    }}
                                                    type="number"
                                                />
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    KYCverified === false ?
                                        <>
                                            {/* <div className="col-lg-6 col-md-6 col-12 pt-2 mt-3 ">
                                                <label className="">
                                                    Address Proof
                                                    <span className='star px-1'> * </span>
                                                    <img src={TooltipIcon}
                                                        className="e-tooltip-hover "
                                                        style={{ verticalAlign: 'text-top' }}
                                                        onMouseEnter={() => setToggleProofInfo(true)}
                                                        onMouseLeave={() => setToggleProofInfo(false)}
                                                    />
                                                </label>
                                                {toggleProofInfo && (
                                                    <IconStyleToolTip
                                                        className="e-tooltip-address"
                                                        content="
                                                            This can be a government-issued ID such as a Voter's id, 
                                                            Passport or Driving license, a bank statement, 
                                                            a salary slip, or any utility bill with your name and address on it."
                                                    />
                                                )}
                                                <Select
                                                    id="proof"
                                                    options={props.proofOptions}
                                                    placeholder="Select"
                                                    value={addressProofType}
                                                    className="e-select-sort"
                                                    selectchange={(value) => {
                                                        setAddressProofType(value);
                                                        clearFormValidationSelect("proof")
                                                        setProofNumber("");
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 pt-2 mt-3 ">
                                                <label
                                                    className=""
                                                    style={{ textTransform: 'capitalize' }}
                                                >
                                                    {
                                                        addressProofType ?
                                                            `Enter ${addressProofType.label}  Number `
                                                            :
                                                            'ID Proof Number '
                                                    }
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <Input
                                                    id="addressProof"
                                                    label={addressProofType ? ` ${addressProofType.label}  number ` : 'Proof number '}
                                                    value={proofNumber}
                                                    valueChange={(value) => {
                                                        setProofNumber(value);
                                                    }}
                                                    type="text"
                                                />
                                            </div> */}
                                            <div className="col-g-6 col-md-6 col-12 mt-3 pt-lg-2 pt-sm-0 pt-0 ">
                                                <label
                                                    className=""
                                                    style={{ textTransform: 'capitalize' }}
                                                >
                                                    Upload Front Side of {addressProofType ? addressProofType.label : 'address proof'}
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <div id="addressProofFront" className="e-uploader text-center mt-2"
                                                    onClick={() => {
                                                        $('#e-upload-front').trigger('click');
                                                        clearFormValidationError("addressProofFront")
                                                    }}
                                                >

                                                    <span className="e-upload-span text-center" >
                                                        <h6>Choose file</h6>
                                                        <button>
                                                            <img src={UploadIcon}
                                                                className="me-2"
                                                            />
                                                            {
                                                                uploadingFront ?
                                                                    'Uploading'
                                                                    :
                                                                    'Click to upload'
                                                            }

                                                        </button>
                                                        <span className="e-note mt-2 px-4">(supported formats PDF, PNG, JPEG and max-size 5mb)</span>
                                                    </span>
                                                    <Uploader
                                                        tempKey="files"
                                                        className="hidden"
                                                        id="e-upload-front"
                                                        uploadSucess={(temp) => {
                                                            var str = temp.split("/");
                                                            setAddressProofFront(str[str.length - 1]);
                                                            setAddressProofFrontUrl(temp);
                                                            setUploadingFront(false);
                                                        }}
                                                        uploadStatus={(temp) => setUploadingFront(temp)}
                                                    />
                                                </div>
                                                {
                                                    addressProofFront ?
                                                        <div className='col-lg-12 mt-3 w-100'>
                                                            <h6 className='e-file-preview-title'>Uploaded successfully</h6>
                                                            <div className="e-boarding-file-preview">
                                                                <p className="p-2">{addressProofFront}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-g-6 col-md-6 col-12 mt-3 pt-lg-2 pt-sm-0 pt-0 ">
                                                <label
                                                    className=""
                                                    style={{ textTransform: 'capitalize' }}
                                                >
                                                    Upload Back Side of {addressProofType ? addressProofType.label : 'address proof'}
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <div
                                                    id="addressProofBack"
                                                    className="e-uploader text-center mt-2"
                                                    onClick={() => {
                                                        $('#e-upload-back').trigger('click');
                                                        clearFormValidationError("addressProofBack")
                                                    }}
                                                >

                                                    <span className="e-upload-span text-center" >
                                                        <h6>Choose file</h6>
                                                        <button>
                                                            <img src={UploadIcon}
                                                                className="me-2"
                                                            />
                                                            {
                                                                uploadingBack ?
                                                                    'Uploading'
                                                                    :
                                                                    'Click to upload'
                                                            }

                                                        </button>
                                                        <span className="e-note mt-2 px-4">(supported formats PDF, PNG, JPEG and max-size 5mb)</span>
                                                    </span>
                                                    <Uploader
                                                        tempKey="files"
                                                        className="hidden"
                                                        id="e-upload-back"
                                                        uploadSucess={(temp) => {
                                                            var str = temp.split("/");
                                                            setAddressProofBack(str[str.length - 1]);
                                                            setAddressProofBackUrl(temp);
                                                            setUploadingBack(false);
                                                        }}
                                                        uploadStatus={(temp) => setUploadingBack(temp)}
                                                    />
                                                </div>
                                                {
                                                    addressProofBack ?
                                                        <div className='col-lg-12 mt-3 w-100 '>
                                                            <h6 className='e-file-preview-title'>Uploaded successfully</h6>
                                                            <div className="e-boarding-file-preview">
                                                                <p className="p-2">{addressProofBack}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>

                                            <>
                                                <div className='col-lg-6 col-md-6 col-sm-12 mt-4 pt-lg-2 pt-sm-0 pt-0  '>
                                                    <label
                                                        className=""
                                                        style={{ textTransform: 'capitalize' }}
                                                    >
                                                        {addressProofType?.label ? addressProofType?.label : "ID"} Issue Date
                                                        <span className='star px-1'> * </span>
                                                    </label>
                                                    <SingleDate
                                                        id="issueDate"
                                                        value={issueDate}
                                                        placeholder="dd-mm-yyyy"
                                                        onChange={(dateString) => {
                                                            setIssueDate(dateString);

                                                        }}
                                                        disabledDate={(day => { if (day.isAfter(moment().subtract(1, 'days'))) return true; })}
                                                    />
                                                </div>
                                                {
                                                    addressProofType?.label === "Voter ID" ?
                                                        null
                                                        :
                                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-4 pt-2'>
                                                            <label
                                                                className=""
                                                                style={{ textTransform: 'capitalize' }}
                                                            >
                                                                {addressProofType?.label ? addressProofType?.label : "ID"} Expiry Date
                                                                <span className='star px-1'> * </span>
                                                            </label>
                                                            <SingleDate
                                                                id="expiryDate"
                                                                value={expiryDate}
                                                                placeholder="dd-mm-yyyy"
                                                                removeToday={true}
                                                                onChange={(dateString) => {
                                                                    setExpiryDate(dateString);

                                                                }}
                                                                disabledDate={(day => { if (day.isBefore(moment())) return true; })}
                                                            />
                                                        </div>
                                                }
                                            </>

                                        </>
                                        :
                                        null
                                }
                                <div className="col-lg-12  mb-3 mt-5 pt-lg-2 pt-sm-0 pt-0 ">
                                    {
                                        window.innerWidth > 575 ? (
                                            <>
                                                {
                                                    _validForm() ? (
                                                        <PrimaryBtn
                                                            name="Next"
                                                            image={Arrow}
                                                            right={!apiLoader && true}
                                                            iconClass='ms-2'
                                                            className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                            handleClick={() => {
                                                                _submitAddressData()
                                                            }}
                                                        />
                                                    ) : (
                                                        <PrimaryBtn
                                                            name="Next"
                                                            image={Arrow}
                                                            right={!apiLoader && true}
                                                            iconClass='ms-2'
                                                            className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                            disabled={true}
                                                        />
                                                    )
                                                }

                                            </>
                                        ) : (
                                            <>
                                                {
                                                    _validForm() ? (
                                                        <PrimaryBtn
                                                            name="Next"
                                                            image={Arrow}
                                                            right={!apiLoader && true}
                                                            iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                            className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                            handleClick={() => {
                                                                _submitAddressData()
                                                            }}
                                                        />
                                                    ) : (
                                                        <PrimaryBtn
                                                            name="Next"
                                                            image={Arrow}
                                                            right={!apiLoader && true}
                                                            iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                            className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                            disabled={true}
                                                        />
                                                    )
                                                }
                                            </>

                                        )
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error != null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

export default AddressDetail;