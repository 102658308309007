
// import packages

// import style
import style from '../../styles/components/nodata.module.scss';

// import assets
import Illustration from '../../assets/images/loader/nodata.png';
const Nodata = (props) =>{
    return(
        <div className={`${style.e_nodata_wrapper} col-lg-12 text-center mt-5  ` }>
            <img src={Illustration} className={props.className}/>
            <h5>{props.title ? props.title : 'Please try later'}</h5>
            <p>{props.note ? props.note : 'There is nothing found'}</p>
        </div>
    )
}

export default Nodata;