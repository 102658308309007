import $ from 'jquery';
var LeadSquared = {}
const leadSquared_secret = '63ef0bbecf51982c8d40bff7680ba2198ff132aa'; //'381e524f3becbedb5c169944553d46d7acf2b9db';
const leadSquared_access = 'u$red6cefc6afa89fabb5b541f3a9cbb28c'; //'u$rcf72238717a55b77f5d25d9a790f5a3e'; 

// Function for get lead square id
LeadSquared._getLead = async (userName, leadType, param) => {
    let Id;
    var setting = {
        'url': `https://api-in21.leadsquared.com/v2/LeadManagement.svc/${leadType}?accessKey=${leadSquared_access}&secretKey=${leadSquared_secret}&${param}=${userName}`,
        "method": "GET",
        "timeout": 0,
    };
    await $.ajax(setting).done(function (response) {
        if (response.length) {
            sessionStorage.setItem('leadSquaredId', response[0].ProspectID);
            Id = response[0].ProspectID
        } else {
            Id = null
        }
    });
    return Id
}

// Function for create lead using phone number
LeadSquared._createLead = (phone, mail, fname, lname) => {
    let data = [
        {
            Attribute: 'Phone',
            Value: phone
        },
        {
            Attribute: 'EmailAddress',
            Value: mail
        },
        {
            Attribute: 'FirstName',
            Value: fname
        },
        {
            Attribute: 'LastName',
            Value: lname
        },
        {
            Attribute :"mx_Gender",
            Value: "-"
        },
        {
            Attribute:"mx_DOB",
            Value: ""
        },
        {
            Attribute:"mx_Street1",
            Value: "-"
        },
        {
            Attribute:"mx_Annual_Income",
            Value: null
        },
        {
            Attribute:"mx_Occupation",
            Value: "-"
        },
        {
            Attribute:"mx_Risk_Score",
            Value: null
        },
        {
            Attribute:"Source",
            Value: sessionStorage.getItem('avail_utm') ? 'Inorganic' : 'Organic'
        },
        {
            Attribute:"OwnerId",
            Value: null
        },
        {
            Attribute:"Notes",
            Value: "-"
        } 
    ]



    var settings = {
        "url": `https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Create?accessKey=${leadSquared_access}&secretKey=${leadSquared_secret}`,
        "method": "POST",
        "timeout": 0,
        "headers": {
            "Content-Type": "application/json"
        },
        "data": JSON.stringify(data),
    };

    $.ajax(settings).done(function (response) {
        console.log(response.Message.Id);
        // let fields = [
        //     {
        //         SchemaName: "mx_Custom_1", // phone number
        //         Datatye: "Number",
        //         Value: phone
        //     },
        //     {
        //         SchemaName: "mx_Custom_2", // name
        //         Datatye: "String",
        //         Value: `${fname} ${lname}`
        //     },
        //     {
        //         SchemaName: "mx_Custom_3", //email
        //         Datatye: "String",
        //         Value: mail
        //     },
        // ]
        sessionStorage.setItem('leadSquaredId', response.Message.Id);
        signUpEvent(response.Message.Id, "OTP Drop-off mail", 207);
    });
}

function signUpEvent(id, activityName, eventNumber) {
    var raw = JSON.stringify({
        "RelatedProspectId": id,
        "ActivityEvent": eventNumber,
        "ActivityNote": activityName,
        // "Fields": fields
    });

    var requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: raw,
    };

    fetch(
        `https://api-in21.leadsquared.com/v2/ProspectActivity.svc/Create?accessKey=${leadSquared_access}&secretKey=${leadSquared_secret}`, requestOptions
    )
        .then(response => response.text())
        .then(result => console.log(result));
}

//Function for pass Sign up event 
LeadSquared._activityTracker = (activityName, eventNumber) => {
    var raw = JSON.stringify({
        "RelatedProspectId": sessionStorage.getItem('leadSquaredId'),
        "ActivityEvent": eventNumber,
        "ActivityNote": activityName,
        // "Fields": fields
    });

    var requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: raw,
    };

    fetch(
        `https://api-in21.leadsquared.com/v2/ProspectActivity.svc/Create?accessKey=${leadSquared_access}&secretKey=${leadSquared_secret}`, requestOptions
    )
        .then(response => response.text())
        .then(result => console.log(result));
}

//Function for update activity data
LeadSquared._updateTracker = (activity, fields, eventNumber, activityId) => {
    var raw = JSON.stringify({
        "ProspectActivityId": activityId,
        "ActivityEvent": eventNumber,
        "ActivityNote": activity,
        "Fields": fields
    });
    var requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: raw,
    };
    fetch(
        `https://api-in21.leadsquared.com/v2/ProspectActivity.svc/CustomActivity/Update?accessKey=${leadSquared_access}&secretKey=${leadSquared_secret}`, requestOptions
    )
        .then(response => response.text())
        .then(result => console.log(result));
}

//Function for get activity ID 
LeadSquared._retrieveActivity = async (eventNumber) => {
    console.log('inside retrieve ()');
    let Id;
    var raw = JSON.stringify({
        "Parameter": {
            "ActivityEvent": eventNumber
        },
        "Paging": {
            "Offset": "0",
            "RowCount": "100"
        }
    });
    await $.ajax({
        method: 'POST',
        url: `https://api-in21.leadsquared.com/v2/ProspectActivity.svc/Retrieve?accessKey=${leadSquared_access}&secretKey=${leadSquared_secret}&leadId=${sessionStorage.getItem('leadSquaredId')}`,
        headers: {
            "Content-Type": "application/json"
        },
        body: raw,
        complete: function (XHR, TextStatus) {
            if (TextStatus == 'timeout') {
                console.log("Timeout");
            }
        }
    })
        .then(function (response) {
            if (response.RecordCount !== 0) {
                Id = response.ProspectActivities[0].Id;
                console.log('id', Id)
            }
            else {
                console.log('else', response.RecordCount)
                Id = null
            }
        })
    return Id;
}




export default LeadSquared;
