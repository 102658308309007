// Package import 
import { useEffect, useState } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { toast } from "react-toastify";

// Asset import
import Tooltip from '../../assets/images/tooltip/icon-blue.svg';
import Uploader from '../../components/fileUploader/upload';
import UploadIcon from '../../assets/images/new-design/upload.svg';
import Arrow from '../../assets/images/onboard/submit.svg';
import MintdLock from '../../assets/images/new-design/lock.svg';


// Component import 
import { IconStyleToolTip } from '../../components/tooltip/index';
import { SingleDate, RadioButton, Select } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta';
import { Toast, SuccessToast } from '../../components/note/index';
import MintdSecurity from '../../components/modal/securityModal';
import Input from '../../components/input/loginInput';

// Service import 
import Config from '../../config/config';
import APIPost from '../../services/postApi';
import COLOUR from '../../config/color';
import LeadSquared from '../../services/leadSquredServices';
import { Modal } from 'bootstrap';
import { KycCheckList } from '../../components/modal';

const PersonalDetails = (props) => {
    const [toggleTooltip, setToggleTooltip] = useState(false);
    const [togglePanInfoPopup, setTogglePanInfoPopup] = useState(false);
    const [panNumber, setPanNumber] = useState(null);
    const [KYCverified, setKYCverified] = useState(true);
    const [error, setError] = useState(null);
    const [panVerified, setPanVerified] = useState(false);
    const [fname, setFname] = useState(null);
    const [lname, setLname] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null)
    const [country, setCountry] = useState("yes");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [aadhaar, setAadhaar] = useState(null);
    const [motherName, setMotherName] = useState(null);
    const [fatherName, setFatherName] = useState(null);
    const [uploadingPanCard, setUploadingPan] = useState(false);
    const [panCard, setPanCard] = useState(null);
    const [panCardFileUrl, setPancardFileUrl] = useState(null);
    const [uploadingPicture, setUploadingPicture] = useState(false);
    const [picture, setPicture] = useState(null);
    const [pictureFileUrl, setPictureFileUrl] = useState(null);
    const [signatureInfoPopup, setSignatureInfoPopup] = useState(false);
    const [signatureUploading, setSignatureUploading] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileUrl, setFIleUrl] = useState(null);
    const [apiLoader, setApiLoader] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [email, setEmail] = useState(null);
    const [emailOTP, setEmailOTP] = useState(null);
    const [verifyEmail, setVerifyEmail] = useState(true);
    const [oldMail, setOldMail] = useState(null);
    const [toast, setToast] = useState(null);
    const [userData, setUserData] = useState(null);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [timer, setTimer] = useState(null);


    useEffect(() => {
        if (props.progressData) {
            let userData = props.progressData.personal_data;
            setUserData(props.progressData);
            setEmail(props.progressData.personal_data?.email);
            console.log('len', Object.keys(userData).length)
            if (Object.keys(userData).length > 1) {
                console.log('ayu')
                setKYCverified(props.progressData.is_kyc_complaint);
                setPanNumber(userData.pan);
                setFname(userData.first_name);
                setLname(userData.last_name);
                setEmail(userData.email);
                if (userData.gender_id && userData.gender_name) {
                    setSelectedGender({ value: userData.gender_id, label: userData.gender_name })
                }
                if (userData.is_indian_citizen === true || userData.is_indian_citizen === null) {
                    setCountry('yes');
                }
                else {
                    setCountry('no');
                    setSelectedCountry({ value: userData.country_id, label: userData.country })
                }
                setAadhaar(userData.aadhar_number);
                setDateOfBirth(userData.dob);
                setMotherName(userData.mother_name);
                setFatherName(userData.father_name);
                if (userData.profile_image) {
                    var str = userData.profile_image.split("/"); setPicture(str[str.length - 1]);
                    setPictureFileUrl(userData.profile_image);
                }
                if (userData.sign_proof) {
                    var str = userData.sign_proof.split("/"); setFileName(str[str.length - 1]);
                    setFIleUrl(userData.sign_proof);
                }
                if (userData.pan_image) {
                    var str = userData.pan_image.split("/"); setPanCard(str[str.length - 1]);
                    setPancardFileUrl(userData.pan_image);
                }
            }
        }
    }, [props.progressData])

    useEffect(() => {
        if (timer !== null)
            setNewCount();
    }, [timer])

    const setNewCount = () => {
        setTimeout(() => {
            if (timer > 0) {
                setTimer(timer - 1)
            }
        }, 1000)
    }


    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 3000)
        }
    }, [toast])

    useEffect(() => {
        if (oldMail !== null) {
            if (oldMail !== email) {
                setVerifyEmail(false);
                setOldMail(null);
                setEmailOTP(null)
            }
        }
    }, [email])
    //  API - KYC verification for pan card
    const _verifyPan = () => {
        if (panNumber === null) {
            setError("Pan number required");
            showFormValidationError('pan')
            return null;
        }
        else if (panNumber.length < 10) {
            setError("Pan number must contain at least 10 characters");
            showFormValidationError('pan')
            return null;
        }
        else {
            setApiLoader(true);
            let url = Config.user + '/auth/verify-kyc';
            let data = JSON.stringify({
                "pan": panNumber
            });

            APIPost(url, data).then((response) => {
                if (response.status_code === 200) {
                    setApiLoader(false);
                    setKYCverified(response.data.is_already_verified);
                    props.KYCStatus(response.data.is_already_verified);
                    if (!response.data.is_already_verified) {
                        var modal = new Modal(document.getElementById("check-list"))
                        modal.show()

                    }
                    setPanVerified(true);
                    if (userData.email_verified === true) {
                        // setEmailVerified(true);
                        // setVerifyEmail(true);
                    }
                } else {
                    setKYCverified(true);
                    setApiLoader(false);
                    setError(response.message);
                }
            });
        }

    }
    /* -- validation for personal data fields ---*/
    const validation = () => {
        setError(null)
        if (country === "yes") {
            if (panNumber && panNumber.length < 10) {
                setError("Pan number must contain at least 10 characters");
                showFormValidationError('pan')
                return false;
            }
            else if (fname == null || fname.length < 2) {
                setError("First name cannot be left blank");
                showFormValidationError('fName')
                return false;
            } else if (lname == null || lname.length < 1) {
                setError("Please enter your Last name");
                showFormValidationError('lName')
                return false;
            }
            else if (dateOfBirth === null) {
                setError("DOB is required");
                showFormValidationError('dob')
                return false;
            } else if (selectedGender.value === null) {
                setError("Select gender");
                showFormValidationSelect("gender")
                return false;
            } else if (motherName && motherName.length < 2 && !KYCverified) {
                setError("Minimum 2 characters required for Mother's name");
                showFormValidationError('motherName')
                return false;
            } else if (fatherName && fatherName.length < 2 && !KYCverified) {
                setError("Minimum 2 characters required for Father's name");
                showFormValidationError('fatherName')
                return false;
            } else if (aadhaar?.length !== 4 && !KYCverified) {
                setError("Please enter last 4 digits of Aadhaar");
                showFormValidationError('aadhar')
                return false;
            } else if (panCardFileUrl === "" && !KYCverified) {
                setError("Please upload pan card");
                showFormValidationError('uploadPan')
                return false;
            } else if (pictureFileUrl === "" && !KYCverified) {
                setError("Please upload photo");
                showFormValidationError('uploadPhoto')
                return false;
            } else if (fileUrl === "" && !KYCverified) {
                setError("Please upload signature");
                showFormValidationError('uploadSignature')
                return false;
            }
        }
        else {
            if (panNumber.length < 10) {
                setError("Pan number must contain at least 10 characters");
                showFormValidationError('pan')
                return false;
            }
            else if (fname === null || fname.length < 2) {
                setError("First name cannot be left blank");
                showFormValidationError('fName')
                return false;
            } else if (lname === null || lname.length < 1) {
                setError("Last name cannot be left blank");
                showFormValidationError('lName')
                return false;
            }
            else if (dateOfBirth === null) {
                setError("DOB is required");
                showFormValidationError('dob')
                return false;
            } else if (selectedGender?.value === null) {
                setError("Select gender");
                showFormValidationSelect("gender")
                return false;
            } else if (motherName && motherName.length < 2) {
                setError("Minimum 2 characters required for Mother's  name");
                showFormValidationError('motherName')
                return false;
            } else if (fatherName && fatherName.length < 2) {
                setError("Minimum 2 characters required for Father's  name");
                showFormValidationError('fatherName')
                return false;
            } else if (aadhaar?.length !== 4 && !KYCverified) {
                setError("Please enter last 4 digits of Aadhaar");
                showFormValidationError('aadhar')
                return false;
            } else if (selectedCountry?.value === null) {
                setError("Select country name");
                showFormValidationSelect("countryName")
                return false;
            } else if (selectedGender?.value === null) {
                var ele = document.getElementById("gender");
                ele.style.border = "solid 1px red"
                ele.style.borderRadius = "8px"
                ele.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                return false;
            } else if (panCardFileUrl === "" && !KYCverified) {
                setError("Please upload pan card");
                showFormValidationError('uploadPan')
                return false;
            } else if (pictureFileUrl === "" && !KYCverified) {
                setError("Please upload photo");
                showFormValidationError('uploadPhoto')
                return false;
            } else if (fileUrl === "" && !KYCverified) {
                setError("Please upload signature");
                showFormValidationError('uploadSignature')
                return false;
            }
        }
        return true
    }

    /* validation to highlight fields */
    const showFormValidationError = (field) => {
        var ele = document.getElementById(field);
        ele.style.border = `solid 1.5px ${COLOUR.red}`
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* validation for select */
    const showFormValidationSelect = (field) => {
        const ele = document.getElementById(field);
        ele.className = "e-select-error"
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* clearing validation for select */
    const clearFormValidationSelect = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.className = "e-select-wraper";
        }
    }

    /* clearing validation fields */
    const clearFormValidationError = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.style.border = null;
        }
    }

    // analytic tracker for user details
    const _analyticsTrackUserDetails = () => {
        window.analytics.identify(props.progressData.user_id, {
            name: `${fname} ${lname}`,
            birth_date: dateOfBirth,
            invested_status: false
        });
    }
    // submit personal data 
    const _submitPersonalData = () => {
        let status = validation();
        if (status) {
            _analyticsTrackUserDetails()
            setApiLoader(true);
            let url = Config.user + '/auth/add-personaldata';
            let data = JSON.stringify({
                pan: panNumber,
                first_name: fname,
                last_name: lname,
                is_indian_citizen: country === 'yes' ? true : false,
                gender_id: selectedGender?.value,
                dob: dateOfBirth,
                country_id: country === "no" ? selectedCountry?.value : undefined,
                aadhar_number: aadhaar,
                father_name: fatherName,
                mother_name: motherName,
                profile_image: pictureFileUrl,
                sign_proof: fileUrl,
                pan_image: panCardFileUrl
            })
            APIPost(url, data).then((response) => {
                if (response.status_code === 200) {
                    props.success(response.data.signup_status);
                    props.updateUserData('personal_data', response.data.personal_data);
                    // message.success('Details added successfully');
                    setApiLoader(false);
                } else {
                    setApiLoader(false);
                    setError(response.message)
                }
            });

        }
    }

    const _sendOTP = (type) => {
        let url = Config.user + '/auth/send-email-otp';
        let data = JSON.stringify({
            email: email,
            type: type
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setVerifyEmail(true);
                setToast(`OTP send to ${email}`)
                setTimer(20);
                setOldMail(email);
            }
            else {
                setError(response.message);
            }
        })
    }

    const _verifyEmail = () => {
        let url = Config.user + "/auth/verify-email";
        let data = JSON.stringify({
            email: email,
            otp: emailOTP
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setEmailVerified(true);
                let tmp = userData;
                tmp.email_verified = true;
                tmp.personal_data.email = email;
                setUserData({ ...tmp })
                window.analytics.identify("", {
                    email: email,
                });

            } else {
                setError(response.message);
            }
        })
    }



    //lead squared activity tracker for KYC
    const _kycTracker = (analysis, score) => {
        let trackerData = [
            {
                SchemaName: 'mx_Custom_3',
                Datatype: "SearchableDropdown",
                Value: 'Yes'
            },
        ]
        LeadSquared._activityTracker('KYC', trackerData, 203)
    }


    const _validForm = () => {
        let valid = false;
        if (props.progressData?.is_kyc_complaint === false && KYCverified === false) {
            if (
                fname && lname && dateOfBirth && selectedGender &&
                motherName && fatherName && aadhaar && panCard &&
                picture && fileName

            ) {
                valid = true
            }
            else {
                valid = false;
            }
        } else {
            if (
                fname && lname && dateOfBirth && selectedGender

            ) {
                valid = true
            }
            else {
                valid = false;
            }
        }
        return valid;
    }

    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-12 e-onboard-questions-section   e-mobile-confirm-wrap pb-5 mb-5">
            <div className="e-process-wrap position-relative">
                {
                    toggleTooltip ?
                        <IconStyleToolTip className="e-tooltip"
                            icon={Tooltip}
                            content="The Securities and Exchange Board of India (SEBI), which regulates mutual funds and other securities, requires us to collect this information as part of a mandatory Know Your Client (KYC) process. "
                        />
                        :
                        null
                }

                {/* Form */}
                <div className='row pt-1 mt-lg-3'>
                    <div className="col-lg-12">
                        <div className="e-wraper">
                            <div className="row">

                                {
                                    !panVerified && (
                                        <div className="col-lg-7 col-md-12 col-12 position-relative">
                                            {/* pan card introduction */}
                                            <label className=" d-flex">
                                                PAN Number <span className='star px-1'> * </span>
                                                {/* <img src={TooltipIcon}
                                                className="e-tooltip-hover ms-2"
                                                onMouseEnter={() => setTogglePanInfoPopup(true)}
                                                onMouseLeave={() => setTogglePanInfoPopup(false)} /> */}
                                            </label>

                                            {/* pan card tooltip */}
                                            {
                                                togglePanInfoPopup ?
                                                    <IconStyleToolTip
                                                        className="e-tooltip-pan"
                                                        icon={Tooltip}
                                                        content="We need your PAN number to check if you're already a registered investor. If you are, we can speed up your onboarding journey." />
                                                    :
                                                    null
                                            }

                                            {/* pan number */}
                                            <Input
                                                label="PAN number"
                                                id="pan"
                                                isCapital={true}
                                                value={panNumber}
                                                valueChange={(value) => {
                                                    clearFormValidationError('pan');
                                                    if (value.length <= 10) {
                                                        setPanNumber(value);
                                                    }
                                                }}
                                                type="text"
                                                maxlength="10"
                                                needBlurEvent={panVerified}
                                                focusOut={() => _verifyPan()}
                                            />
                                            <span className='e-mintd-security-btn' data-bs-toggle="modal" data-bs-target="#mintd-security">
                                                <img src={MintdLock} />
                                                Your data is always secure with us
                                            </span>

                                        </div>
                                    )
                                }


                                {/* {
                                      !KYCverified ?
                                        <CardIconStyleToolTip bgColor="#EDF3FF"
                                          className="mb-2"
                                          icon={Tooltip}
                                          content="Your KYC is not completed yet. You can complete KYC by submitting your photo, signature & Aadhaar number below. " />
                                        :
                                        null
                                    } */}
                                {
                                    !panVerified ?
                                        <div className='col-lg-12 text-start mt-5'>
                                            <PrimaryBtn
                                                name={apiLoader ? "Loading" : 'Next'}
                                                image={Arrow}
                                                right={!apiLoader && true}
                                                disabled={!panNumber ? true : false}
                                                iconClass="ms-2"
                                                className={apiLoader ? 'e-submit-btn e-btn-loader' : 'e-submit-btn'}
                                                handleClick={() => _verifyPan()}
                                            />
                                        </div>
                                        :

                                        <>
                                            <div className="col-lg-6 col-md-6 col-12 ">
                                                <label className="">First Name<span className='star px-1'> * </span></label>
                                                <Input label="First Name"
                                                    id="fName"
                                                    name="First Name"
                                                    value={fname}
                                                    type="text"
                                                    valueChange={(value) => {
                                                        setFname(value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-sm-3 mt-3 mt-lg-0 mt-md-0  e-last-name">
                                                <label className="">Last Name<span className='star px-1'> * </span></label>
                                                <Input label="Last Name"
                                                    id="lName"
                                                    name="Last Name"
                                                    value={lname}
                                                    type="text"
                                                    valueChange={(value) => {
                                                        setLname(value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-3 pt-lg-2 pt-md-2 pt-sm-0 pt-0">
                                                <label className="">Date of Birth<span className='star px-1'> * </span></label>
                                                <SingleDate
                                                    id="dob"
                                                    value={dateOfBirth}
                                                    placeholder="DD-MM-YYYY"
                                                    disabledDate={(day => {
                                                        if (day.isAfter(moment().subtract(18, "years"))) {
                                                            return true
                                                        }
                                                    })}
                                                    isDecadeBack={true}
                                                    onChange={(dateString) => {
                                                        setDateOfBirth(dateString);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-3 pt-lg-2 pt-md-2 pt-sm-0 pt-0">
                                                <label className="">Gender<span className='star px-1'> * </span></label>
                                                <Select id="gender"
                                                    options={props.genderOptions}
                                                    value={selectedGender}
                                                    isSearchable={false}
                                                    placeholder="Gender"
                                                    className="e-select-sort"
                                                    style="border:solid 10px #f0f0f0;"
                                                    selectchange={(temp) => {
                                                        setSelectedGender(temp);
                                                        // clearFormValidationSelect("gender")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-12 mt-4 pt-sm-0 pt-0">
                                                <label className="">Is India your country of citizenship?<span className='star px-1'> * </span></label>
                                                <div className="e-radio-buttons">
                                                    <RadioButton id={1}
                                                        label="Yes"
                                                        name="check"
                                                        wrapperClass=" me-5"
                                                        checked={country == "yes" ? true : false}
                                                        onClick={(e) => {
                                                            setCountry("yes")
                                                        }} />

                                                    <RadioButton id={2}
                                                        label="No"
                                                        name="check"
                                                        className="e-signup-radio me-3"
                                                        checked={country === "no" ? true : false}
                                                        onClick={(e) => {
                                                            setCountry("no")
                                                        }} />
                                                </div>
                                            </div>
                                            {
                                                country === "no" ?
                                                    <div className="col-lg-12 col-12 mt-2 pt-lg-2 pt-md-2 pt-sm-0 pt-0 ">
                                                        <label >Select your country<span className='star px-1'> * </span></label>
                                                        <Select
                                                            id="countryName"
                                                            options={props.countryOptions}
                                                            isSearchable={true}
                                                            value={selectedCountry}
                                                            placeholder="Country name"
                                                            className="e-country-search"
                                                            selectchange={(temp) => {
                                                                setSelectedCountry(temp);
                                                                // clearFormValidationSelect("countryName")
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                props.progressData?.is_kyc_complaint === false && KYCverified === false ?
                                                    <>
                                                        <div className='col-lg-6 col-md-6 col-12 mt-2 pt-lg-2 pt-md-2 pt-sm-0 pt-0'>
                                                            <label className="">Mother's Name<span className='star px-1'> * </span></label>
                                                            <Input
                                                                label="Mother's Name"
                                                                id="motherName"
                                                                value={motherName}
                                                                valueChange={(value) => {
                                                                    setMotherName(value);
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-12 mt-2 pt-lg-2 pt-md-2 pt-sm-1 pt-1'>
                                                            <label className="">Father's Name<span className='star px-1'> * </span></label>
                                                            <Input
                                                                label="Father's Name"
                                                                id="fatherName"
                                                                value={fatherName}
                                                                valueChange={(value) => {
                                                                    setFatherName(value);
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-12 mt-3 pt-lg-2 pt-md-2 pt-sm-0 pt-0">
                                                            <label className="">Last 4 digits of your Aadhaar number<span className='star px-1'> * </span></label>
                                                            <Input
                                                                label="Last 4  digits"
                                                                id="aadhar"
                                                                value={(aadhaar)}
                                                                valueChange={(value) => {
                                                                    if (value.length <= 4) {
                                                                        parseInt(setAadhaar(value))
                                                                    }
                                                                }}

                                                                type="number"
                                                            />
                                                        </div>
                                                        <div className='row pe-0'>
                                                            <div className="col-lg-6 col-md-6 col-12  mt-lg-4 mt-md-4 mt-sm-3 mt-3 pe-0">
                                                                <label className="m">Upload your PAN card<span className='star px-1'> * </span></label>
                                                                <div id="uploadPan" className="e-uploader text-center mt-2"
                                                                    onClick={() => {
                                                                        $('#e-upload-pancard').trigger('click');
                                                                        clearFormValidationError("uploadPan")
                                                                    }}>
                                                                    <span className="e-upload-span text-center" >
                                                                        <h6>Choose file</h6>
                                                                        <button>
                                                                            <img src={UploadIcon}
                                                                                className="me-2"
                                                                            />
                                                                            {
                                                                                uploadingPanCard ?
                                                                                    'Uploading'
                                                                                    :
                                                                                    'Click to upload'
                                                                            }

                                                                        </button>
                                                                        <span className="e-note mt-2 px-4">(supported formats PDF, PNG, JPEG and max-size 5mb)</span>
                                                                    </span>
                                                                    <Uploader
                                                                        tempKey="files"
                                                                        className="hidden"
                                                                        id="e-upload-pancard"
                                                                        uploadSucess={(temp) => {
                                                                            var str = temp.split("/");
                                                                            setPanCard(str[str.length - 1]);
                                                                            setPancardFileUrl(temp);
                                                                            setUploadingPan(false);
                                                                        }}
                                                                        uploadStatus={(temp) => setUploadingPan(temp)}
                                                                    />
                                                                </div>
                                                                {
                                                                    panCard ?
                                                                        <div className='col-lg-12 mt-3 w-100  '>
                                                                            <h6 className='e-file-preview-title'>Uploaded successfully</h6>
                                                                            <div className='e-boarding-file-preview'>
                                                                                <p className='p-2'>{panCard}</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-12 mt-lg-4 mt-md-4 mt-sm-3 mt-3 pe-0">
                                                                <label className="">Upload your Photo<span className='star px-1'> * </span></label>
                                                                <div id="uploadPhoto" className="e-uploader mt-2 text-center"
                                                                    onClick={() => {
                                                                        $('#e-upload-photo').trigger('click');
                                                                        clearFormValidationError("uploadPhoto")
                                                                    }}
                                                                >

                                                                    <span className="e-upload-span text-center" >
                                                                        <h6>Choose file</h6>
                                                                        <button>
                                                                            <img src={UploadIcon}
                                                                                className="me-2"
                                                                            />
                                                                            {
                                                                                uploadingPicture ?
                                                                                    'Uploading'
                                                                                    :
                                                                                    'Click to upload'
                                                                            }

                                                                        </button>
                                                                        <span className="e-note mt-2 px-4">(supported formats PDF, PNG, JPEG and max-size 5mb)</span>
                                                                    </span>
                                                                    <Uploader
                                                                        tempKey="files"
                                                                        className="hidden"
                                                                        id="e-upload-photo"
                                                                        uploadSucess={(temp) => {
                                                                            var str = temp.split("/");
                                                                            setPicture(str[str.length - 1]);
                                                                            setPictureFileUrl(temp);
                                                                            setUploadingPicture(false);
                                                                        }}
                                                                        uploadStatus={(temp) => setUploadingPicture(temp)}
                                                                    />
                                                                </div>
                                                                {
                                                                    picture ?
                                                                        <div className='col-lg-12 mt-3 w-100'>
                                                                            <h6 className='e-file-preview-title'>Uploaded successfully</h6>
                                                                            <div className='e-boarding-file-preview'>
                                                                                <p className='p-2'>{picture}</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-12  mt-lg-4 mt-md-4 mt-sm-3 mt-3 pt-lg-3 pt-md-3 pt-0 pe-0" >
                                                                <label className="">
                                                                    Upload your Signature
                                                                    <span className='star px-1'> * </span>
                                                                    {/* <img 
                                                                        src={TooltipIcon} 
                                                                        className="e-tooltip-hover ms-2" 
                                                                        onMouseEnter={() => setSignatureInfoPopup(true)}
                                                                        onMouseLeave={() => setSignatureInfoPopup(false)} 
                                                                    /> */}
                                                                </label>
                                                                {
                                                                    signatureInfoPopup ?
                                                                        <IconStyleToolTip
                                                                            className="e-tooltip"
                                                                            icon={Tooltip}
                                                                            content="Please upload a legible scanned copy of your signature on a clear white paper. " />
                                                                        :
                                                                        null
                                                                }
                                                                <div id="uploadSignature" className="e-uploader text-center mt-2" onClick={() => {
                                                                    $('#e-upload').trigger('click');
                                                                    clearFormValidationError("uploadSignature")
                                                                }}>
                                                                    <span className="e-upload-span text-center" >
                                                                        <h6>Choose file</h6>
                                                                        <button>
                                                                            <img src={UploadIcon}
                                                                                className="me-2"
                                                                            />
                                                                            {
                                                                                signatureUploading ?
                                                                                    'Uploading'
                                                                                    :
                                                                                    'Click to upload'
                                                                            }

                                                                        </button>
                                                                        <span className="e-note mt-2 px-4">(supported formats PDF, PNG, JPEG and max-size 5mb)</span>
                                                                    </span>
                                                                    <Uploader
                                                                        tempKey="files"
                                                                        className="hidden"
                                                                        id="e-upload"
                                                                        uploadSucess={(temp) => {
                                                                            var str = temp.split("/");
                                                                            setFileName(str[str.length - 1]);
                                                                            setFIleUrl(temp);
                                                                            setSignatureUploading(false);
                                                                        }}
                                                                        uploadStatus={(temp) => setSignatureUploading(temp)}
                                                                    />
                                                                </div>
                                                                {
                                                                    fileName ?
                                                                        <div className='col-lg-12 mt-3 w-100'>
                                                                            <h6 className='e-file-preview-title'>Uploaded successfully</h6>
                                                                            <div className='e-boarding-file-preview'>
                                                                                <p className='p-2'>{fileName}</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>

                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                            <div className="col-lg-12  mb-3  mt-lg-5 mt-md-5 mt-sm-4 mt-4">
                                                {
                                                    window.innerWidth > 575 ? (
                                                        <>
                                                            {
                                                                _validForm() ? (
                                                                    <PrimaryBtn
                                                                        name="Next"
                                                                        image={Arrow}
                                                                        right={!apiLoader && true}
                                                                        iconClass={'ms-2'}
                                                                        className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                                        handleClick={() => {
                                                                            _submitPersonalData();
                                                                            // _kycTracker();
                                                                            // _updateActivity()
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <PrimaryBtn
                                                                        name="Next"
                                                                        image={Arrow}
                                                                        right={!apiLoader && true}
                                                                        iconClass={'ms-2'}
                                                                        className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                                        disabled={true}
                                                                    />
                                                                )
                                                            }
                                                        </>

                                                    ) :
                                                        (
                                                            <>
                                                                {
                                                                    _validForm() ? (
                                                                        <PrimaryBtn
                                                                            name="Next"
                                                                            image={Arrow}
                                                                            right={!apiLoader && true}
                                                                            iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                                            className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                                            handleClick={() => {
                                                                                _submitPersonalData();
                                                                                // _updateActivity()
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <PrimaryBtn
                                                                            name="Next"
                                                                            image={Arrow}
                                                                            right={!apiLoader && true}
                                                                            iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                                            className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                                            disabled={true}
                                                                        />
                                                                    )
                                                                }

                                                            </>
                                                        )
                                                }


                                            </div>
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error != null ?
                    <Toast data={error} />
                    :
                    null
            }
            {
                toast != null ?
                    <SuccessToast data={toast} />
                    :
                    null
            }
            {/*------ kyc required modal -------*/}
            {
                fname !== null ?
                    <KycCheckList name={fname} />
                    :
                    null
            }
            <MintdSecurity />
        </div>
    )
}

export default PersonalDetails;
