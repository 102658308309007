/*
 *   File : radio.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Radio Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

/*import package*/
import { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';
import { Link, useNavigate } from "react-router-dom";


/*import styles*/
import styles from '../../styles/components/radiometrics.module.scss';

// import asset
import BtnArrow from '../../assets/images/new-design/btn-arrow.svg';

// import components
import { PrimaryBtn } from '../cta';

// import services
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import { Toast } from '../note';

const Radiometrics = (props) => {
  const [check, setCheck] = useState(false)
  const [score, setScore] = useState();
  const [risk, setRisk] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (error !== null) {
      setTimeout(() => {
        setError(null)
      }, 5000);
    }
  }, [error])

  useEffect(()=>{
    
    if( props.selected !== null && props.value) {
      console.log('prosp', props.selected)
      setScore([{ "question_id": props.question, answer: props.selected }])
      let selectedData = props.value.filter((e) => e.answer_id == props.selected);
      console.log('seleceted', selectedData);
      setCheck(selectedData[0]);
      props.checked(selectedData[0]);
    } 
  },[props.selected, props.value])
  
  const setSelectedAnswer = (item) => {
    console.log(item)
    let temp = [];
    temp.push({ "question_id": props.question, answer: item.answer_id });
    setScore(temp)
    setCheck(item)
    props.checked(item)
  }
  const openModal = (data) => {
    var myModal = new Modal(document.getElementById("success"), {});
    myModal.show();
    setTimeout(() => {
      myModal.hide()
      navigate('/portfolio')
    }, 2000)
  }

  const saveAnswer = (e) => {
    e.preventDefault();
    let url = devconfig.user + '/quiz/save-answer';
    let data = JSON.stringify({
      // topic_id: props?.details?.topic_id,
      question_id : score[0].question_id,
      answer_id : score[0].answer
    })
    APIPost(url, data).then((response) => {
      if (response.status_code === 200) {
        // openModal();
        calculateRisk();
      }
    })
    setTimeout(() => {
    }, 3000)
  }

  const calculateRisk = () => {
    let url = devconfig.user + '/quiz/calculate-risk'

    APIGet(url).then((response) => {
      if (response.status_code === 200) {
        if (response.status === true) {
          // navigate(`/portfolio`);
          props.success();
          setRisk(response.data);
        } else {
          setError(response.message);
        }
      }
      else {
        setError(response.message);
      }
    })
  }

  return (
    <>
      <div className='row '>
        <div className='col-lg-7 col-md-7 col-12 pe-0'>
          <div className="d-flex  mt-3  e-radio-metric-wrapper">
            {
              props.value.map((item) => (
                <div className={item === check ?

                  ` mb-1 ${styles.e_radiometrics_active} e-score-selector` : ` ${styles.e_radiometrics} mb-1 e-score-selector`
                }

                  onClick={() => setSelectedAnswer(item)}>
                  <span class={styles.e_item}>
                    {item.answer}
                  </span>
                </div>
              ))
            }
          </div>
          <div className="row  e-radiometric-sec">
            <div className="col-lg-4 col-4">
              <span class={styles.e_item_content}>Extremely conservative</span>
            </div>
            <div className="col-lg-4 col-4 text-center p-0">
              <span class={styles.e_item_content}>Moderate</span>
            </div>
            <div className="col-lg-3 col-4 text-end">
              <span class={styles.e_item_content_risky}>Risky</span>
            </div>
          </div>
          <div className="col-lg-7  mt-3">
            {
              score ?
                <PrimaryBtn 
                  name="See your Mintd Portfolio" 
                  className="e-questioner-submit" 
                  image={BtnArrow}
                  right={true}
                  iconClass="ms-2"
                  handleClick={(e) => { saveAnswer(e) }} 
                />
                :
                <PrimaryBtn 
                  name="See your Mintd Portfolio" 
                  className="e-questioner-submit" 
                  disabled={true} 
                  image={BtnArrow}
                  right={true}
                  iconClass="ms-2"
                />
            }
          </div>
          <div className="col-lg-12 e-radiometrics pt-2">
            <p className="pt-3 ">Having trouble figuring out your risk tolerance?<a className="ps-2 e-sample-modal-a" data-bs-target="#scoreExample" data-bs-toggle="modal">Read this</a></p>
          </div>
        </div>
      </div>
      {
        error !== null ?
          <Toast data={error} type="fail" />
          :
          null
      }
    </>
  );
}

export default Radiometrics;