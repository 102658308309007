/*
 *   File : support.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Footer
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-12-2021
 */

/*import packages*/
import React, { useState, useRef, useEffect } from 'react';
import $ from 'jquery'



/* import assets */
import SupportIcon from '../../assets/images/support/suppport.svg';
import Calendar from '../../assets/images/support/calendar.svg';
import Whatsapp from '../../assets/images/support/whatsapp.svg';
import Close from '../../assets/images/support/close.svg';
import Marker from '../../assets/images/support/marker.svg';
import devconfig from '../../config/config';



export default function Support(props) {
    const wrapperRef = useRef(null)
    const [contact, setContact] = useState(false)

    useEffect(() => {
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);


    const closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            let calendar = document.getElementById('e-calendar-widget');
            let marker = document.getElementById('e-marker-widget');
            let whatsapp = document.getElementById('e-whatsapp-widget');

            calendar.classList.add('e-remove-calendar');
            whatsapp.classList.add('e-remove-whatsapp');
            marker.classList.add('e-remove-marker');
            setTimeout(() => {
                setContact(false)
            }, 200)

        }
    }


    return (
        <>

            <div className="row e-support-sec" ref={wrapperRef}>
                <div id='support'>
                    {
                        contact === true ? (
                            <img
                                src={Close}
                                className="e-support-btn"
                                alt="footerLogo"
                                onClick={() => {
                                    let calendar = document.getElementById('e-calendar-widget');
                                    let marker = document.getElementById('e-marker-widget');
                                    let whatsapp = document.getElementById('e-whatsapp-widget');

                                    calendar.classList.add('e-remove-calendar');
                                    whatsapp.classList.add('e-remove-whatsapp');
                                    marker.classList.add('e-remove-marker');
                                    setTimeout(() => {
                                        setContact(false)
                                    }, 200)

                                }}
                            />
                        ) : (
                            <img src={SupportIcon} className="e-support-btn" alt="footerLogo" onClick={() => { console.log('open click'); setContact(true) }} />
                        )
                    }
                    <div className='e-contact-sec' >

                        <img src={Calendar} id="e-calendar-widget" className={contact ? "e-animate-calendar" : 'e-calendar'} onClick={() => { window.open(devconfig.calendarUrl, "_blank") }} />
                        <img src={Marker} id="e-marker-widget" className={contact ? "e-animate-marker" : 'e-marker'} onClick={() => { window.location.href = "/tour?welcome=" + true }} />
                        <img src={Whatsapp} id="e-whatsapp-widget" className={contact ? 'e-animate-whatsapp' : "e-whatsapp"} onClick={() => { window.open(devconfig.whatsappUrl, "_blank") }} />
                    </div>
                </div>

            </div>
        </>
    )
}

