/*
 *   File : rangeSlider.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Range Slider
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

/*import package*/
import React, { Component } from 'react'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

/*import styles*/ 
import styles from '../../styles/components/rangeSlider.module.scss';

class RangeSlider extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      volume: 5,
      defaultValue : 5
    }
  }

  handleOnChange = (value) => {

    if( value === this.state.defaultValue-1){
    
      this.setState({
        volume: value
      })
      this.props.valueChange(value-1);
    }
    if(value === this.state.defaultValue+1){
      this.setState({
        volume: value
      })
      this.props.valueChange(value+1);
    }
  


      
      

   
  }

  render() {
    let { volume } = this.state
    return (
      <Slider
        value={volume}
        // orientation="vertical"
        // onChange={this.handleOnChange}
        min={0}
        max={10}
        step={.5}
        // value={vl}
        orientation="horizontal"
        reverse={false}
        tooltip={true}
        labels={{ 0: '0', 1: '1',2: '2',3: '3',4:'4',5:'5',6:'6',7:'7',8:'8',9:'9',10:'10'}}
        // limits={{ enabled: true, minStart: 3, minEnd: 5, maxStart: 6, maxEnd: 9 }}
        // defaultValue={{
        //   start: 5,
        //   end: 7,
        // }}
        // disabled={true}
        // handleLabel="data"
        // format={Function}
        // onChangeStart={Function}
        onChange={this.handleOnChange}
        // onChangeComplete={Function}
      />
    )
  }
}

export default RangeSlider;