// Service import
import devconfig from "../config/config";
import APIGet from "./getApi";

export default async function RedirectUser() {
    let url = devconfig.user + '/auth/signup-progress';

    let status = APIGet(url).then(async (response) => {
        if (response.status_code === 200) {
            let tempStatus = response.data.signup_status;
            let status = response.data.is_kyc_complaint
            if (tempStatus === 'ACCOUNT_VERIFIED') return { redirect: true, link: '/login' };
            else if (tempStatus === 'PASSWORD_CONFIGURED') return { redirect: true, link: '/onboard-step-1' };
            else if (tempStatus === 'PERSONAL_DATA_ADDED') return { redirect: true, link: '/onboard-step-1?personal_data=' + true };
            else if (tempStatus === 'ADDRESS_DATA_ADDED') return { redirect: true, link: '/onboard-step-1?address_data=' + true };
            else if (tempStatus === 'INCOME_DATA_ADDED') return { redirect: true, link: '/onboard-step-2' };
            else if (tempStatus === 'BANKACCOUNT_ADDED') return { redirect: true, link: '/onboard-step-3' };
            //else if (tempStatus === 'NOMINEE_ADDED') return { redirect: true, link: '/onboard-step-3?nominee_data=' + true }
            else if (tempStatus === 'NOMINEE_ADDED') {
                if (status === true) {
                    return { redirect: true, link: '/onboard-step-4' }
                } else {
                    return { redirect: true, link: '/onboard-step-3' }
                };
            }
            else if (tempStatus === 'ESIGN_STARTED') return { redirect: true, link: '/onboard-step-3?nominee_data=' + true };
            else if (tempStatus === 'AMC_KYC_INPROGRESS') return { redirect: true, link: '/onboard-step-3?nominee_data=' + true };
            else if (tempStatus === 'ESIGN_COMPLETED' || tempStatus === 'AMC_KYC_COMPLETED') return { redirect: true, link: '/onboard-step-4' };
            else if (tempStatus === 'QUESTIONNAIRE_STARTED') return { redirect: true, link: '/onboard-step-4' };
            // else if (tempStatus === 'DELETE_SCORE') return { redirect: true, link: '/onboard-step-4?update=' + true };
            // else if (tempStatus === 'EDIT_SCORE') return { redirect: true, link: '/onboard-step-4?update=' + true };
            else if (tempStatus === 'QUESTIONNAIRE_COMPLETED') return { redirect: true, link: '/portfolio' };
            else if (tempStatus === 'RISK_SCORE_CALCULATED') return { redirect: true, link: '/portfolio' };
            else if (tempStatus === 'PORTFOLIO_CONFIGURED') return { redirect: true, link: '/portfolio' };
            else if (tempStatus === 'RISKSCORE_SKIPPED') return { redirect: true, link: '/portfolio?skipped=' + true }
            else return { redirect: false, link: null };

        }
    })
    return status;
}

