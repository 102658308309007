/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { Calendar } from '../input/index';
import Input from '../input/loginInput';

import { SecondaryBtn, PrimaryBtn } from '../cta/index';


/*import styles*/
import styles from '../../styles/components/edit.module.scss';

import Close from '../../assets/images/new-design/modalClose.svg';

/* import assets */

// Component import
import { Modal } from 'bootstrap';
import { CalendarModal } from '.';
import DateSelector from '../input/dateSelector';
import { Toast } from '../note';

//Service import
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';

export default function EditSip(props) {

  const [amount, setAmount] = useState();
  const [date, setDate] = useState([]);
  const [sipDate, setSipDate] = useState([]);
  const [id, setId] = useState();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [dates, setDates] = useState()

  useEffect(() => {
    // let arr = []
    // for (let i = 0; i < 29; i++) {
    //   arr.push(i);
    //   setDate(arr);
    // }
    getIvestmentData();
  }, []);

  useEffect(() => {
    setAmount(props?.details?.amount)
    setSipDate(props.details.sip_date)
    setId(props?.details?.sip_id)
    console.log('de', props.details)
  }, [props?.details])

  const updateSIP = () => {
    setLoader(true);
    let url = devconfig.user + '/sip/edit';
    let data = JSON.stringify({
      sip_id: id,
      sip_date: sipDate,
      amount: amount
    })
    APIPost(url, data).then((response) => {
      if (response.status_code === 200) {
        setLoader(false);
        props.success();
        $('#editSip').trigger('click');
      } else {
        setLoader(false);
        setError(response.message);
      }
    })
    setTimeout(() => {
      setError(null)
    }, 5000)
  }

  const getIvestmentData = () => {
    let url = devconfig.user + '/mf/minimum-investment';
    APIGet(url).then((response) => {
      if (response.status_code === 200) {
        console.log(response.data)
        setDates(response.data.sip_dates)
      }
    })
  }

  return (
    <>
      <div className={`${styles.e_edit_modal} modal fade`} id="editSip" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className={`${styles.e_modal_content} modal-content`}>
            <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
              <h5 className='mt-1'>Edit SIP</h5>
              <img
                src={Close}
                className={`${styles.e_modal_close}`}
                data-bs-dismiss="modal"
              />
            </div>
            <div className="container p-4">
              <div className="row ">
                <div className='col-12'>
                  <h5 className={`${styles.e_subtitle}`}>Update your SIP amount and date</h5>
                </div>
                <div className="col-lg-6 mt-3">
                  <label>SIP Amount</label>
                  <div className={`${styles.e_input_wrapper}`}>
                    <Input 
                      className={`${styles.e_input}`} 
                      value={amount} 
                      valueChange={(value) => setAmount(value)} 
                      type="number" 
                    />
                    <span className={`${styles.e_rupee}`}>₹</span>
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <label>Start date</label>
                  <DateSelector date={dates} defaultDate={sipDate} selectedDate={(temp) => setSipDate(temp)} />
                </div>
              </div>

              <div className="row pt-4 mt-2 pb-2">
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <SecondaryBtn
                    name="Cancel"
                    cancel="modal"
                    className="w-100"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    {
                      amount && sipDate ?
                        <PrimaryBtn 
                          name={loader ? "Updating" : "Update"} 
                          className={loader ? `w-100 e-btn-loader` : `w-100 `} 
                          handleClick={() => updateSIP()} 
                        />
                        :
                        <PrimaryBtn 
                          name="Update" 
                          className={`w-100`} 
                          disabled={true}
                        />
                    }

                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        error ?
          <Toast data={error} type="fail" />
          :
          null
      }
    </>
  )
}
