/*
 *   File : scrip-detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Scrip detail
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 21-07-2022
 */

/*import packages*/
import { useState, useEffect, React, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import ReactJoyride, { ACTIONS } from 'react-joyride';
import { Modal } from 'bootstrap';

/*import components*/
import { Footer } from '../../components/menu/index';
import { PrimaryIconBtn } from '../../components/cta/index';
import { IconStyleToolTip } from '../../components/tooltip/index';
import ProgressBar from '../../components/input/progress.js'
import { SkipTour } from '../../components/modal';

import { UserDataContext } from '../../context/userData';


/*import assets*/
import Playstore from '../../assets/images/menu/playstore.svg';
import Appstore from '../../assets/images/menu/appstore.svg';
import TooltipIcon from '../../assets/images/tooltip/icon.svg';
import Tooltip from '../../assets/images/tooltip/icon-blue.svg';
import Arrow from '../../assets/images/scrip/arrow.svg';
import BackArrow from '../../assets/images/new-design/arrow-back.svg';
import Hand from '../../assets/images/modal/hand.svg'
import Next from '../../assets/images/new-design/btn-arrow.svg';
import Previous from '../../assets/images/new-design/tour-back.svg'
import MintdLogo from '../../assets/images/menu/logo.svg';
import SideBarMenu from '../../assets/images//new-design/menu.png';
import LeadSquared from '../../services/leadSquredServices';
import Sidebar from '../../components/menu/sidebar';
import SelectBox from '../../components/input/selectBox';
const mf_trailing = [
  {
    "1D": -0.07
  },
  {
    "1M": -9.58
  },
  {
    "3M": -6.58
  },

]

const Options = [
  { label: "1D", value: -0.07 },
  { label: "1M", value: -9.58 },
  { label: "3M", value: -6.58 }

]
const ScripDetail = (props) => {
  const [isShown, setIsShown] = useState(false);
  const [click, setClick] = useState(0);
  let navigator = useNavigate();
  let percentValue = 100 / 7;
  const [run, setRun] = useState(false);
  const [next, setNext] = useState(false);
  const [tourStep, setTourStep] = useState(null);
  const [userData, setUserData] = useContext(UserDataContext);
  const [openSideBar, setOpenSideBar] = useState(false)
  const [returns, setReturns] = useState(Options[0]);
  var count = -1;

  let colorIndex = -1;
  /* window scroll */
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])


  /* dummy data */

  const asset_details = {
    "nav": "₹35.01",
    "nav_text": "₹35.01",
    "current_value_text": "₹3,178.65",
    "current_value": "₹3178.65",
    "target_weight": "45.0%",
    "current_weight": "45.0%",
    "units": 90.7968
  }

  const graph_data = {
    "colors": [
      "#1329E6",
      "#6A79FF",
      "#2F3DB9",
      "#644DEC",
      "#AF93F6",
      "#C6D9FF",
      "#72BBFF",
      "#127CC9"
    ],
    "sector": [
      "Personal Products",
      "Power",
      "Cement",
      "Finance",
      "Chemicals",
      "Retailing",
      "Gas",

    ],
    "allocation": [
      22,
      18.32,
      15.56,
      14.36,
      13.92,
      11.62,
      4.94
    ]
  }

  const backgroundColor = [
    '#1329E6',
    '#644DEC',
    '#8C65EC',
    '#9A87F1',
    '#AF93F6',
    '#BDACF6',
    '#DED0FB',
    '#E3D6FF',
    '#EDE8FA'
  ]


  /* joyride start */
  useEffect(async () => {
    setRun(true)
  }, [])

  const handleClickStart = () => {
    setRun(true)
  };

  const _segmentTourTracker = (step) => {
    window.analytics.track('Product tour', {
      user_id: userData?.user_id,
      producttour_status: 'viewed',
      step: step
    });
  }




  /* joyride steps */
  const steps = [

    {
      content: (
        <div className='e-tour'>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      target: '#e-sec-0',

    },
    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <span className='e-completed-item'>5</span>
            <h6 className='ps-3 text-start pt-0 pe-3'>Fund details and returns</h6>
          </div>
          <p className='text-start ms-4 ps-3'>See details and holdings of each fund we’ve used to represent a respective asset.</p>
          <ProgressBar percent={percentValue * 5} />
        </div>
      ),
      placement: 'top-end',
      styles: {
        options: {
          borderRadius: 16,
          width: 500,
          height: 180,
        }
      },
      locale: {
        skip: <p>5 to 7</p>,
        next: <span className="">
          <img src={Next} className="e-tour-button" onClick={() => {
            setNext(true); _segmentTourTracker(5);
            LeadSquared._activityTracker('Product Tour Drop-off mail', 208);
            setTourStep(5);
            if (window.innerWidth <= 992) {
              setOpenSideBar(true)
            }
          }} />
        </span>,
        back: <span className="e-tour-back"><img src={Previous} alt="" className='' onClick={() => window.history.back()} /></span>

      },
      disableBeacon: true,
      disableOverlayClose: true,
      target: '#e-sec-5',
      spotlightClicks: true,

    },

    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <span className='e-completed-item' style={{ padding: '2px 5px' }}>6</span>
            <h6 className='ps-3 text-start pt-0 pe-3'>Manage your account and investments and more</h6>
          </div>
          <p className='text-start ms-4 ps-3'>This is home base: view all orders, make changes to your account, manage your
            SIPS, and see your capital gains and holdings reports.</p>
          <ProgressBar percent={percentValue * 6} />
        </div>
      ),
      placement: openSideBar ? 'top-start' : 'right',
      styles: {
        options: {
          borderRadius: 16,
          width: 480,
          height: 180,
        }
      },
      locale: {
        skip: <p>6 to 7</p>,
        next: <span className="e-tour-button"
          onClick={() => {
            setTourStep(6);
            _segmentTourTracker(6);
            LeadSquared._activityTracker('Product Tour Drop-off mail', 208);
          }}>
          <img src={Next} className="e-form-submit" />
        </span>,
        back: <span className="e-tour-back"><img src={Previous} alt="" onClick={() => { setNext(false) }} /></span>

      },
      disableBeacon: true,
      disableOverlayClose: true,
      target: '#e-sec-6',

    },
    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <img src={Hand} alt="" />
            <h5 className='ps-3 pt-0 pt-lg-2 pt-md-2 text-start pt-0 pe-3'>Click on<a href='/tour-profile?welcome=true'
              onClick={() => {
                _segmentTourTracker(6);
                LeadSquared._activityTracker('Product Tour Drop-off mail', 208);
              }}>  "Account settings"</a> to see how to edit your portfolio.</h5>
          </div>
        </div>
      ),
      placement: 'left',
      styles: {
        options: {
          borderRadius: 16,
          width: 580,

        }
      },
      locale: {
        last: <span className="e-next-span d-none">
          <img src={Arrow} className="e-form-submit  d-none" />
        </span>,
        back: <img src={Previous} alt="" className='d-none' />


      },
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      target: '#e-sec-8',

    },


  ]

  const handleJoyrideCallback = data => {
    const { action, index, type, size } = data;

    if (action === ACTIONS.CLOSE) {
      var modal = new Modal(document.getElementById("skip"), {});
      modal.show()
      return setRun(false)

    }
  }

  const getWidth = (item) => {
    let width;
    let base;
    let length = graph_data.allocation.length;
    if (length < 10) base = 50;
    else if (length > 10 && length < 20) base = 48;
    else base = 50;
    width = base * item / 100;
    return `${item}%`
  }

  return (

    <>

      <div className="container-fluid e-asset-detail-wrapper" >

        {/* <HeaderScripTour logo={InnerLogo} id={"e-sec-6"} next={next} settings={"e-sec-8"} /> */}
        <div className='row'>
          <div className='col-lg-2'>
            <div className='e-side-bar-lg' >
              <Sidebar
                tour={props.tour}
                activeUser={true}
                count={1}
                settings={window.innerWidth > 992 ? "e-sec-6" : null}
                settingId={window.innerWidth > 992 ? "e-sec-8" : null}
                link="/tour-profile?welcome=true"
                callBack={() => {
                  navigator('/tour-profile?welcome=true');
                  console.log('inside callback');
                  _segmentTourTracker(6);
                  LeadSquared._activityTracker('Product Tour Drop-off mail', 208);
                }}
              />
            </div>
          </div>
          <div className='col-lg-10'>
            <div className={

              'e-inner-small-screen-title d-flex justify-content-between px-2 pb-4'
            }>
              <img src={MintdLogo} className="mt-4" />
              <button
                className='e-menu-btn mt-4'
                onClick={() => {
                  setOpenSideBar(true);
                }}
              >
                <img src={SideBarMenu} />
              </button>
            </div>
            <div
              className={
                openSideBar === true ?
                  'e-small-screen-menu e-menu-over-lay'
                  :
                  'e-small-screen-menu'
              }
              style={{ display: openSideBar && 'block', right: openSideBar && '0' }}
            >
              <Sidebar tour={props.tour} activeUser={true} count={1} settings={window.innerWidth <= 992 ? "e-sec-6" : null} settingId={window.innerWidth <= 992 ? "e-sec-8" : null} closeMenu={() => setOpenSideBar(false)} link="/tour-profile?welcome=true" />
            </div>
            <div id="e-sec-5">
              <div className="row  e-asset-details mt-3" >
                <div className="col-lg-12">
                  <Link to="/assets">
                    <span className="e-back" >
                      <img src={BackArrow} className="e-back-arrow me-3" alt="" />
                      Back
                    </span>
                  </Link>
                  <h4 className='e-fund-name mt-2'>India Large Cap Equities</h4>
                  <p className='e-amc-name'>HDFC Large Cap Fund</p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 mt-5">

                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="e-fund-wrapper">
                        <h5>About this fund</h5>
                        <p className="pt-3">The objective of the fund is to invest in companies whose securities are included in nifty junior index and to endeavor to achieve the returns of the above index as closely as possible, though subject to tracking error. The fund intends to track only 90-95% of the index i.e. It will always keep cash balance between 5-10% of the net assets to meet the redemptions and other liquidity requirements. However, as and when the liquidity in the index improves the fund intends to track upto 100% of the index.</p>
                        <PrimaryIconBtn name="Know more" right={true} image={Arrow} className="pt-3" handleClick={() => { }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-6">
                      <div className="e-fund-wrapper">
                        <div className="row">
                          <div className="col-lg-12 mb-4">
                            <h5> Asset details</h5>
                          </div>

                          <div className="col-lg-4 col-md-4 col-sm-4 mb-3">
                            <label className='e-label'>
                              NAV
                            </label>
                            <p className="e-value ">{asset_details.nav_text}</p>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 mb-3">
                            <label className='e-label'>
                              Current value
                            </label>
                            <p className="e-value ">{asset_details.current_value_text}</p>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 mb-3">
                            <label className='e-label'>
                              Returns
                            </label>
                            <p className="e-value ">{returns.value}</p>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 mb-3">
                            <label className='e-label'>
                              Target weight
                            </label>
                            <p className="e-value ">{asset_details.target_weight}</p>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 mb-3 position-relative">
                            <label className='e-label'>
                              Current weight
                              <img src={TooltipIcon} className="e-tooltip-hover ms-2" alt="" onMouseEnter={() => setIsShown(true)}
                                onMouseLeave={() => setIsShown(false)} />
                              {
                                isShown ?
                                  <IconStyleToolTip className="e-tooltip" icon={Tooltip} content="The Securities and Exchange Board of India (SEBI), which regulates mutual funds and other securities, requires us to collect this information as part of a mandatory Know Your Client (KYC) process. " />
                                  :
                                  null
                              }
                            </label>
                            {
                              isShown ?
                                <IconStyleToolTip className="e-tooltip" icon={Tooltip} content="The Securities and Exchange Board of India (SEBI), which regulates mutual funds and other securities, requires us to collect this information as part of a mandatory Know Your Client (KYC) process. " />
                                :
                                null
                            }
                            <p className="e-value ">{asset_details.current_weight}</p>
                          </div>
                          <div className='col-4 mt-3'>
                            <div className='e-asset-detail-select'>
                              <SelectBox
                                options={Options}
                                value={returns}
                                selectchange={(temp) => {
                                  setReturns(temp)
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label className='e-label'>
                              Units
                            </label>
                            <p className="e-value  mb-0">{asset_details.units}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 mt-4">
                      <div className="e-pie-container position-relative row">
                        <div className="e-fund-wrapper mb-1">
                          <h5>Sector allocation</h5>
                        </div>
                        <div className='col-lg-8 col-md-8 col-12 ps-3'>
                          <div className='e-chart-wrapper'>
                            {
                              graph_data.allocation.map((item, key) => {
                                if (count >= 8) count = 0;
                                else count = count + 1;
                                return (
                                  <div className='d-inline-block e-chart-bar'
                                    style={{
                                      backgroundColor: backgroundColor[count],
                                      width: getWidth(item),
                                      height: '100%',
                                      borderRadius:
                                        key === 0 ? '8px 0px 0px 8px'
                                          :
                                          key === graph_data.allocation.length - 1 && getWidth(item) == '0%' ?
                                            key === graph_data.allocation.length - 2 ?
                                              '0px 8px 8px 0px'
                                              :
                                              '0px 8px 8px 0px'
                                            :
                                            null
                                    }}
                                  >
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                        <div className='row mt-4'>
                          <div className='col-lg-8'>
                            <div className='row'>
                              {
                                graph_data && graph_data.allocation.map((item, key) => {
                                  if (colorIndex >= 8) colorIndex = 0;
                                  else colorIndex = colorIndex + 1;
                                  return (
                                    <div className='col-lg-6 col-md-6 col-12 mb-3'>
                                      <div className='d-flex'>
                                        <div className='e-indicator mt-1'
                                          style={{ backgroundColor: backgroundColor[colorIndex] }}
                                        >
                                        </div>
                                        <div className='e-legend-label'>
                                          <div className='e-name' style={{ width: 'max-content' }}>{graph_data.sector[key]}</div>
                                          <div className='e-percentage'> : {item}%</div>
                                        </div>
                                      </div>


                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactJoyride
        continuous
        showSkipButton
        scrollTo
        scrollToFirstStep
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        styles={{
          options: {
            zIndex: 1000,
            color: "#1329e6",
            arrowColor: 'transparent',
            height: 300,
          },
          buttonNext: {

            background: "#1329e6",
            // borderRadius: "50%",
          },
        }}
      />
      <SkipTour step={tourStep} active={props.active} continue={() => handleClickStart()} />
    </>
  )
}

export default ScripDetail;

