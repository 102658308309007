/*
 *   File : invest.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Invest Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*import packages*/
import React, { useEffect, useState } from 'react';

/*import componets*/
import { PrimaryBtn } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/scoreExample.module.scss';

export default function ScoreExampleModal(props) {

  return (
    <div className={`${styles.e_score_example_modal} modal fade e-score-modal `} id="scoreExample" tabIndex="-1" aria-labelledby="scoreExample" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className={`${styles.e_border} p-2`}>
            <div className="modal-header mb-0 pb-1">
              <h3>Understanding risk</h3>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="container px-3">
              <div className={`${styles.e_scroll} row mb-4 pb-2`}>
                <div className="col-lg-12">
                  <p className={`${styles.e_note}`}>
                    Here is a short note on risk along with a practical example to help you determine your risk tolerance.
                  </p>
                </div>
                <div className="col-lg-12">
                  <p className={`${styles.e_content}`}>
                    "Risk" gets a bad rep when it comes to investing. It has many definitions, one of which is “the likelihood of partial or complete loss of investment." However, we have specifically chosen assets where the likelihood of loss of investment over the long term is very low. All the funds we have picked are diversified, and track broad markets.
                  </p>
                  <p className={`${styles.e_content} pt-2`}>
                    A better way to think about risk, in this context, is how much short term pain you're willing and able to swallow for a better chance at long term gains. All things equal, over a long enough timeline, the higher the risk you’re able to take, the better your expected returns.
                  </p>
                  <p className={`${styles.e_content} pt-2`}>
                    <b>Let’s consider an example</b>
                  </p>
                  <p className={`${styles.e_content} pt-2`}>

                    The worst day to invest in the equity market in the last decade was probably 9th Feb 2020. The markets crashed because of the COVID-19 pandemic, and people temporarily lost a lot of wealth. Here's what an investment of Rs. 10k in a ‘risky’ diversified index fund that tracks the Indian stock market looked like vs one in a ‘safe’ Fixed Deposit (FD):
                    </p>
                    <p className={`${styles.e_content} `}>
                    Feb 9, 2020: <br/>
                    Index fund = Rs. 10k<br/>
                    FD = Rs. 10k<br/>
                    </p>
                    <p className={`${styles.e_content} `}>
                    Mar 29, 2020:<br/>
                    Index fund = Rs. 6673 (down 33%, ouch)<br/>
                    FD = Rs. 10078 (+0.8%)<br/>
                    </p>
                    <p className={`${styles.e_content} `}>
                    Today:<br/>
                    Index fund = Rs. 13144 (+31%)<br/>
                    FD = 10862 (+8.62%)<br/>
                    </p>
                    <p className={`${styles.e_content} `}>
                    Here, the riskier investment moved a lot more. At its worst it was down 33%, and right now near its best it's up 31%. Meanwhile, the less exciting FD didn't lose you money in March of 2020, but it also returned much less than the equity fund did over time.

                    Risk is the price you pay in the short run for better returns in the long run, as long as you are willing and able to swallow the pain in the short run. Holding the riskier index fund probably didn’t feel great in March, but it surely does today.

                    The index fund corresponds to a risk score of 7.5 and above on our scale, while a Fixed Deposit represent a score of 0.5-1.
                    </p>
                </div>
              </div>
              <div className="row pt-4 mt-2 pb- pe-0">
                <div className="col-lg-6 pe-0">
                  <PrimaryBtn name="Close " className={`${styles.e_modal_submit} e-modal-submit`} cancel="modal" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

