
/*
 *   File : newslider.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 06-01-2022
 */

/*import style*/
import styles from '../../styles/components/newSlider.module.scss';

/*import package*/
import { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import $ from 'jquery';
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';
import { message } from 'antd';

const RSlider = (props) => {
	const [value, setValue] = useState();
	const [score, setScore] = useState();
	const [labels, setLabels] = useState();
	const [baseArray, setBaseArray] = useState();

	const marks = [
		{
			value: 0,
			label: '0',
		},
		{
			value: 1,
			label: '1',
		},
		{
			value: 2,
			label: '2',
		},
		{
			value: 3,
			label: '3',
		},
		{
			value: 4,
			label: '4',
		},
		{
			value: 5,
			label: '5',
		},
		{
			value: 6,
			label: '6',
		},
		{
			value: 7,
			label: '7',
		},
		{
			value: 8,
			label: '8',
		},
		{
			value: 9,
			label: '9',
		},
		{
			value: 10,
			label: '10',
		},
	];

	const minDistance = 0.5;
	useEffect(() => {
		var temp = [{ value: 0, label: '0' }];
		var tempBase = [];
		if (props.values) {
			for (let index = 0; index < props.values.length; index++) {
				if (index % 2 !== 0) {
					temp.push({ value: props.values[index].label, label: `${props.values[index].label}`, id: props.values[index].value })
				}

			}
			for (let index = 0; index < props.values.length; index++) {
				tempBase.push({ value: props.values[index].label, label: `${props.values[index].label}`, id: props.values[index].value })
			}
			setLabels(temp);
			setBaseArray(tempBase);
			console.log('temp-->', temp)
			console.log('props.values-->', props.values);
		}
	}, [props.values])

	useEffect(() => {
		if (props.defaultValue) {
			if (!props.skipped) {
				var leftLimit = (props.defaultValue - 0.5) * 10;
				console.log('left limit', leftLimit);
				// var rightLimit = (props.defaultValue - 0.8) * 10;
				$('.MuiSlider-track').css('left', leftLimit + '%');
				if (props.defaultValue >= 10) {
					$('.MuiSlider-track').css('width', '5%');
				} else {
					$('.MuiSlider-track').css('width', '10%');
				}
			}

		}
	}, [props.defaultValue])

	const valuetext = (value) => {
		return value;
	}
	const handleChange2 = (event, newValue, activeThumb) => {
		console.log(newValue, activeThumb)
		if (newValue[1] <= 10) {
			// console.log(event, newValue)
			var rangeLimit = props.defaultValue;
			var leftLimit = (props.defaultValue - 0.5) * 10;
			var rightLimit = (props.defaultValue + 0.5) * 10;
			let value = '';
			if (!props.skipped) {
				//  set track width
				$('.MuiSlider-track').css('left', leftLimit + '%');
				if (props.defaultValue >= 10) {
					$('.MuiSlider-track').css('width', '5%');
				} else {
					$('.MuiSlider-track').css('width', '10%');
				}

				// rescrict thumb moving from <-5>
				// if (newValue[1] <= props.defaultValue - .5) {
				// 	$('.MuiSlider-thumb').css('left', leftLimit + '%');
				// 	$('.MuiSlider-valueLabelLabel').html(props.defaultValue - .5);
				// 	setScore(props.defaultValue - .5);
				// }
				// if (newValue[1] >= props.defaultValue + .5) {
				// 	$('.MuiSlider-thumb').css('left', rightLimit + '%');
				// 	$('.MuiSlider-valueLabelLabel').html(props.defaultValue + .5);
				// 	setScore(props.defaultValue + .5);
				// }

				// if (newValue[1] >= props.defaultValue - .5 && newValue[1] <= props.defaultValue + .5) {
				for (let index = 0; index < baseArray.length; index++) {
					if (baseArray[index].value === newValue[1]) {
						value = baseArray[index].id;
						console.log('value', value, newValue[1]);

					}
					setScore(value)
					if (activeThumb === 0) {
						$('.MuiSlider-thumb').css('left', 5 + '%');
						$('.MuiSlider-valueLabelLabel').html(.5);
						setScore(1)
					}
				}

				// console.log('element', value)
				// showData()
				// }	
				if (newValue[1] > props.defaultValue + 0.5 || newValue[1] < props.defaultValue - 0.5) {
					props.invade_boundary(true)
				} else {
					props.invade_boundary(false)
				}
			} else {
				for (let index = 0; index < baseArray.length; index++) {
					if (baseArray[index].value === newValue[1]) {
						value = baseArray[index].id;
						console.log('value', value);
					}
					setScore(value);
					if (activeThumb === 0) {
						$('.MuiSlider-thumb').css('left', 5 + '%');
						$('.MuiSlider-valueLabelLabel').html(.5);
						setScore(1)
					}
				}
			}
			console.log('score-->', score)

		}
	};

	useEffect(() => {
		if (score) {
			props.Score(score)
		}
	}, [score])



	useEffect(() => {
		getValue();
		// console.log('skipped', props.skipped);
		// console.log('default', props.defaultValue)
	}, [props.defaultValue])

	const getValue = () => {
		if (!props.skipped) {
			return [0, props.defaultValue, 10];
			// return [props.defaultValue - .5, props.defaultValue, props.defaultValue + .5]
		}
		else {
			return [0, 10, 10];
		}

	}


	return (
		<Slider
			getAriaLabel={() => 'Minimum distance'}
			track={true}
			min={0}
			max={10}
			step={0.5}
			aria-labelledby="track-false-range-slider"
			getAriaValueText={valuetext}
			defaultValue={getValue}
			marks={labels}
			disableSwap={false}
			value={value}
			valueLabelDisplay="on"
			onChange={handleChange2}

		/>
	)
}

export default RSlider;