import { useState, useEffect, useContext } from "react";
import $ from 'jquery';
import { useNavigate, useLocation } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion';

import VideoPlayer from "../../components/input/videoPlayer";
import Slider from '../../components/input/newSlider';
import { ToggleButton, AllocationInput } from '../../components/input/index';
import { InvestNowModal, MethodologyModal } from '../../components/modal/index';


import { SuccessPayment, YoutubePlayer } from '../../components/modal';
import RebalanceModal from '../../components/modal/rebalance';
import OpenSIPConfirmation from '../../components/modal/openSIPModal';
import WelcomeModal from "../../components/modal/welcomeModal";

//Asset import
import TooltipIcon from '../../assets/images/new-design/info.svg';
import Tooltip from '../../assets/images/tooltip/icon-blue.svg';
import Arrow from '../../assets/images/onboard/openLink.svg';
import BtnArrow from '../../assets/images/new-design/arrow-right.svg';
import WhiteArrow from '../../assets/images/new-design/white-arrow-right.svg';
import ArrowUp from '../../assets/images/new-design/arrow-up-right.svg';
import ArrowBlue from '../../assets/images/new-design/right-arrow-blue.svg';
import ArrowInvestBtn from '../../assets/images/new-design/btn-arrow.svg';
import Flag from '../../assets/images/new-design/flagYellow.svg';


//Component import
import { PrimaryBtn, PrimaryIconBtn } from "../../components/cta";
import { PieChart } from '../../components/chart/index';
import { Loader } from "../../components/loader";
import { IconStyleToolTip, CardIconStyleToolTip } from '../../components/tooltip/index';

import { Modal } from 'bootstrap';
import { Toast } from '../../components/note';
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';


/*import context*/
import { UserDataContext } from '../../context/userData';
import LeadSquared from '../../services/leadSquredServices';
import AllocationModal from "../../components/modal/allocationModal";



const returns = {};

returns[1] = {
    mintd: [3.74, 5.00, 6.77],
}
returns[2] = {
    mintd: [3.53, 5.07, 6.85],
}
returns[3] = {
    mintd: [5.47, 5.63, 7.40],
}
returns[4] = {
    mintd: [5.50, 5.60, 7.48],
}
returns[5] = {
    mintd: [7.15, 5.94, 8.14],
}
returns[6] = {
    mintd: [8.12, 6.22, 8.41],
}
returns[7] = {
    mintd: [9.19, 6.49, 8.62],
}
returns[8] = {
    mintd: [11.85, 7.98, 10.25],
}
returns[9] = {
    mintd: [13.48, 8.36, 10.48],
}
returns[10] = {
    mintd: [14.55, 9.16, 11.64],
}
returns[11] = {
    mintd: [15.57, 9.28, 12.06],
}
returns[12] = {
    mintd: [16.53, 9.58, 12.37],
}
returns[13] = {
    mintd: [16.81, 10.35, 13.06],
}
returns[14] = {
    mintd: [18.54, 10.69, 13.55],
}
returns[15] = {
    mintd: [20.57, 10.78, 13.87],
}
returns[16] = {
    mintd: [21.63, 11.04, 14.07],
}
returns[17] = {
    mintd: [23.16, 11.45, 14.64],
}
returns[18] = {
    mintd: [23.22, 12.29, 15.40],
}
returns[19] = {
    mintd: [24.08, 12.61, 15.68],
}
returns[20] = {
    mintd: [25.89, 12.76, 16.08],
}
returns['fd'] = {
    fd: [5.10, 6.25, 8.74]
}



const faq = [
    {
        title: 'How do I know I can trust you with my wealth?',
        answer: "First, we use institutional grade security to ensure your data is always protected. Second, we never hold your money. It goes directly from your account to the mutual funds account. And third, our advisors have decades of experience managing billions of dollars."
    },
    {
        title: 'What if I want to pull my money out?',
        answer: "It’s your money. You can withdraw it any time. We make it easy."
    },
    {
        title: 'How long does it take to open an account?',
        answer: "The entire process from onboarding through investment takes less than 5 minutes. Just keep your documents handy."
    },
    {
        title: 'Why should I invest with Mintd?',
        answer: "There are thousands of mutual funds yet 90% of managers don’t beat the market on a consistent basis. Our managers take away the headache of choosing for you by curating diversified portfolios tailored to your needs that provide superior risk-adjusted returns."
    },
    {
        title: 'Is there a lock in period for my investment?',
        answer: "No. We invest in liquid securities so you can withdraw your funds with no penalty ay any time. The cash usually takes about three days to settle in your account once you redeem your mutual funds."
    },
    {
        title: 'What happens if Mintd shutdown?',
        answer: null
    },
]


const Portfolio = (props) => {
    const [returnsFilter, setReturnsFilter] = useState(2);
    const [userData, setUserData] = useContext(UserDataContext);
    const [riskId, setRiskId] = useState(20);
    const [isShown, setIsShown] = useState(false);
    const [check, setCheck] = useState(false);
    const [loader, setLoader] = useState(true);
    const [allocation, setAllocation] = useState();
    const [riskData, setRiskData] = useState({});
    const [colors, setcolors] = useState([]);
    const [error, setError] = useState(null);
    const [index, setIndex] = useState();
    const [allocat, setAllocat] = useState([]);
    const [totalRisk, setTotalRisk] = useState(null);
    const [haveMandate, setHaveMandate] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [minAmount, setMinAmount] = useState();
    const [riskScores, setRiskScores] = useState();
    const [userRiskScore, setUserRiskScore] = useState();
    const [riskLoader, setRiskLoader] = useState(false);
    const [editableData, setEditableData] = useState([]);
    const [fundClass, setFundClass] = useState([]);
    const [valueEdited, setValueEdited] = useState(false);
    const [isSkipped, setIsSkipped] = useState(false);
    const [url, setUrl] = useState(null);
    const [progressLoader, setProgressLoader] = useState(false)
    const [status, setStatus] = useState(true)
    const [kycStatus, setKYCStatus] = useState(null);
    const [type, setType] = useState(null);
    const [minInvestDetails, setMinInvestDetails] = useState({});
    const [mountInvestModal, setMountIvestModal] = useState(false);
    let graphData = '';
    const [assetDetail, setAssetDetail] = useState();
    const [invadeBoundary, setInvadeBoundary] = useState(false);
    const [originalAllocationData, setOriginalAllocationData] = useState();
    const [userRiskData, setUserRiskData] = useState([]);
    let totalSum = 0;
    let navigator = useNavigate();
    const [editScore, setEditScore] = useState(false);
    const [mountRebalanceModal, setMountRebalanceModal] = useState(false);
    const [playerModal, setPlayerModal] = useState(false);
    const [signupStatus, setSignupStatus] = useState(null);
    const [openSIPConfirmation, setOpenSIPConfirmation] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [immediateLow, setImmediateLow] = useState({});
    const [immediateHigh, setImmediateHigh] = useState({});
    const [currentScore, setCurrentScore] = useState({});
    const [selected, setSelected] = useState(null);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [allocationModalButton, setAllocationModalButton] = useState(0);
    const [modalContent, setModalContent] = useState(null);
    const [graphAllocation, setGraphAllocation] = useState([]);
    const [updatingGraph, setUpdatingGraph] = useState(false);
    const [isPage, setIsPage] = useState(false);
    const [openWelcomeModal, setOpenWelcomeModal] = useState(false)

    const location = useLocation();

    useEffect(() => {
        checkInvested();

    }, [])



    useEffect(() => {
        if (location.pathname === '/portfolio') {
            setIsPage(true);
        }
    }, [location.pathname])

    const checkInvested = (status) => {
        let url = devconfig.user + '/auth/signup-progress';

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setUserData(response.data);
                setSignupStatus(response.data?.signup_status);
                // if (response.data.signup_status !== 'QUESTIONNAIRE_COMPLETED' ||
                //     response.data.signup_status !== 'PORTFOLIO_CONFIGURED' ||
                //     response.data.signup_status !== 'RISKSCORE_SKIPPED' ||
                //     response.data.signup_status !== 'RISK_SCORE_CALCULATED') getProfileDetail()
                // if ( response.data.active ) {

                // }
                if (response.data?.signup_status === "INVESTED") {
                    navigator('/home');
                } else if (response.data?.signup_status === "EDIT_SCORE") {
                    setEditScore(true);
                }
            }
        });
    }

    // calling profile api if user active  for collecting data for pass to segment analytics 
    const getProfileDetail = () => {
        let url = devconfig.user + '/profile/get';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setProfileData(response.data)
            }
        })
    }

    useEffect(() => {
        if (userData && profileData) {
            _identifierTrack();
        }
    }, [userData, profileData])

    // track rik score event 
    const segmentTrackScore = (analysis, score) => {
        window.analytics.track('Risk score created', {
            user_id: userData?.user_id,
            risk_analysis: !analysis,
            risk_score: score,
        });
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', () => { //closing youtube modal back btn click
            $('#video-player').trigger('click')
        });
        return () => {
            window.removeEventListener('popstate', () => { return null });
        };
    }, []);


    useEffect(() => {
        // getIvestmentData();
        getRiskData();
        getKYCStatus();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        let host = window.location.href.split("/");
        setUrl(host[3])
    }, [])


    useEffect(() => {
        if (check === false && riskId) {
            // setRiskData({ ...originalAllocationData });
            setTotalRisk(null)
        }
    }, [check, riskId]);

    useEffect(() => {
        setEditableData([]);
        setFundClass([]);
        setcolors([]);
        if (riskId) {
            showData(riskId);
            setRiskId(riskId)
        }
        //eslint-disable-next-line
    }, [riskId]);

    useEffect(() => {
        setTimeout(() => {
            setError(null)
        }, 3000)
    }, [error]);

    useEffect(() => {
        let arr = [];
        for (let i = 0; i < riskData?.graph_data?.allocation.length; i++) {
            arr.push(i)
        }
        setIndex(arr)
    }, [riskData?.graph_data]);

    // get kyc  status
    const getKYCStatus = () => {
        let url = devconfig.user + '/auth/get-kyc';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setKYCStatus(response.data);
            }
        })
    }

    // get risk data
    const getRiskData = () => {
        setRiskLoader(true);
        let url = devconfig.user + '/auth/get-risks';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                let temp = ''

                if (response.data.is_riskscore_skipped) {
                    setUserRiskScore(10);

                    temp = response.data.risk_score[response.data.risk_score.length - 1].value;
                    console.log('temp', temp);
                    setRiskScores(response.data.risk_score);
                    setRiskId(temp)
                } else {
                    setUserRiskScore(response.data.calculated_risk_score);
                    setRiskScores(response.data.risk_score);
                }
                if (response.data.signup_status === 'QUESTIONNAIRE_COMPLETED' ||
                    response.data.signup_status === 'PORTFOLIO_CONFIGURED' ||
                    response.data.signup_status === 'RISKSCORE_SKIPPED' ||
                    response.data.signup_status === 'RISK_SCORE_CALCULATED') {
                    setAllocationModalButton(1);
                    // _activityTracker(response.data.is_riskscore_skipped, response.data.calculated_risk_score);
                    LeadSquared._activityTracker('Portfolio Drop-off mail', 213);
                    segmentTrackScore(response.data.is_riskscore_skipped, response.data.calculated_risk_score); // segment analytic tracker
                }
                // if

                setIsSkipped(response.data.is_riskscore_skipped);
                // if(response.data.is_riskscore_skipped)
                //eslint-disable-next-line
                if (response.data.is_riskscore_skipped === true) {
                    setCurrentScore(response.data.risk_score && response.data.risk_score[response.data.risk_score.length - 1])
                    setImmediateLow(response.data.risk_score && response.data.risk_score[response.data.risk_score.length - 3])
                    setImmediateHigh(response.data.risk_score && response.data.risk_score[response.data.risk_score.length - 2])
                    setRiskLoader(false);
                } else {
                    response.data.risk_score && response.data.risk_score.map((item, key) => {


                        if (item.value === response.data.calculated_risk_score_id - 1) {
                            console.log('low', item);
                            setImmediateLow(item);
                        }
                        if (item.value === response.data.calculated_risk_score_id + 1) {
                            console.log('higth', item);
                            setImmediateHigh(item)
                        }
                        if (response.data.calculated_risk_score === item.label) {
                            temp = item.value;
                            setRiskId(temp)
                            setCurrentScore(item);
                            // showData(item.value);
                            setRiskLoader(false);
                        }
                        else {
                            setRiskLoader(false);
                        }
                    })
                }

                // console.log('returns', returns[13].mintd[returnsFilter])
            }
        })
    }

    /* get mutual funds for risk */
    const showData = (temp) => {
        setEditableData([]);
        setFundClass([]);
        setcolors([]);
        setUserRiskData([]);
        graphData = '';
        setLoader(true)
        let userAllocation = [];
        let actualAllocation = [];
        let url = devconfig.user + '/portfolio/get'
        let data = JSON.stringify({
            risk_score_id: temp
        });
        console.log('API data :: ', data)
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {


                let newArr = [];
                for (let index = 0; index < response.data.allocation.length; index++) {
                    const element = response.data.allocation[index].allocation;
                    newArr.push(element)
                }
                setRiskData(response.data);
                setGraphAllocation(response.data.graph_data.allocation);

                setTotalRisk(response.data?.allocation?.reduce((a, b) => a = a + b.allocation, 0));

                setOriginalAllocationData(newArr);
                setUserRiskData(userAllocation);
                // if ( editScore === false  ) {
                // 	setCheck(response.data.user_configured);
                // }

                setEditableData(response.data.graph_data.allocation);
                setFundClass(response.data.graph_data.fund_class)
                setcolors(response.data.graph_data.colors)
                // graphData = response.data
                setLoader(false)

            }
            else {
                setError(response.message);
                setLoader(false)
            }
        })
    }

    /* submit invest data api */
    const submitRiskData = (riskData = riskData, score) => {
        console.log(riskData);
        let tmp = [];
        for (let index = 0; index < riskData.allocation.length; index++) {
            const element = riskData.allocation[index];
            if (riskData.user_configured && check === true) {
                element.allocation = element.user_allocation;
                // element.user_allocation = 0;			
            }
            if (check === false) {
                element.allocation = originalAllocationData[index];
            }
            tmp.push(element);
        }
        if (tmp) {
            let total = tmp?.reduce((a, b) => a = a + b.allocation, 0);
            if (total !== 100) {
                setError('Allocation must be 100')
                return null
            }
        }
        if (riskId) {

            setSubmitLoader(true);
            let url = devconfig.user + '/portfolio/set'
            let data = JSON.stringify({
                risk_score_id: score,
                allocation: tmp
            });
            APIPost(url, data).then((response) => {
                console.log('data ')
                if (response.status_code === 200) {
                    if (signupStatus === 'EDIT_SCORE') {
                        getInvestmentStatus();
                    }
                    else {
                        checkCanInvest()
                    }
                    setSubmitLoader(false)
                    setLoader(false)
                } else {
                    setError(response.message);
                    setSubmitLoader(false);
                    setLoader(false)
                }
            })
        }
    }

    // () being called after submit portfolio ( in  submitRiskData()) if edit score true.
    //  If api give status true the go to re-balance flow else invest flow
    const getInvestmentStatus = () => {
        let url = devconfig.user + '/investment/status';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                if (response.data.status === true) {
                    checkUserMandate(response.data.is_open_order);
                }
                else {
                    getMandate();
                }
            }
        })
    }

    const checkUserMandate = (open_sip) => {
        let url = devconfig.user + '/mf/get-mandate';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setHaveMandate(response.data.bank_mandate);
                if (open_sip === true) {
                    setOpenSIPConfirmation(true);
                } else {
                    setMountRebalanceModal(true);
                }
                setSubmitLoader(false);
            } else {
                setSubmitLoader(false);
            }
        })
    }

    useEffect(() => {
        if (openSIPConfirmation === true) {
            var modal = new Modal(document.getElementById("openSIP"), {})
            modal.show()
        }
    }, [openSIPConfirmation])

    // check user status after submit portfolio whether on boarding or invest
    const checkCanInvest = () => {
        let url = devconfig.user + '/auth/signup-progress';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setUserData(response.data);
                setSignupStatus(response.data?.signup_status);
                if (response.data?.signup_status === "READY_TO_INVEST") {
                    getMandate();
                    LeadSquared._activityTracker('Payment Drop-off', 212);
                } else {
                    // navigator('/onboard');
                    props.success();
                }
            }
        });
    }
    // open rebalance modal if edit score true after portfolio set
    useEffect(() => {
        if (mountRebalanceModal) {
            var modal = new Modal(document.getElementById("rebalance"), {})
            modal.show()
        }
    }, [mountRebalanceModal])

    // check whether user have active mandate or not
    const getMandate = () => {
        // _subscriptionEventTrack(); // call investment tracker
        let url = devconfig.user + '/mf/get-mandate';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                // getIvestmentData();
                getPortfolioDetail();
                setHaveMandate(response.data.bank_mandate);
            } else {
                // hide loader and show error in toast here
            }
        })
    }

    // get allocation detail
    const getPortfolioDetail = () => {
        let url = devconfig.user + '/mf/portfolio';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setAssetDetail(response.data);
                setMountIvestModal(true);
                setSubmitLoader(false);
            }
        })
    }
    //get min-investment
    const getIvestmentData = () => {
        let url = devconfig.user + '/mf/minimum-investment';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setMinInvestDetails(response.data);
                setMountIvestModal(true);
                setSubmitLoader(false);
            } else {
                // hide loader and show error in toast here
            }
        })
    }

    // Listener for request for show  invest modal 
    useEffect(() => {
        if (mountInvestModal) {
            var modal = new Modal(document.getElementById("invest"), {})
            modal.show()
        }
    }, [mountInvestModal])

    const getGraphData = (name, value) => {
        let idx = riskData.graph_data.fund_class.findIndex((item) => item === name)   //getting index of same asset class name
        if (value >= 0) {
            let grapgAllocation = [...editableData] //allocation of graph
            let allocation = [...riskData.allocation] //investments data
            const result = allocation.filter(item => item.global_class === name); //getting investments of particular asset class
            const sum = result.reduce((initial, final) => initial + final.allocation, 0); //calculating sum of allocation from resulted array
            grapgAllocation[idx] = Number(sum) //assigning sum to index value present in graph allocation
            setEditableData(grapgAllocation)
            setValueEdited(false)
        }
    }

    //  check decide success modal content based user status
    const updateContext = (payment) => {
        console.log('update context')
        setProgressLoader(true);
        // setStatus(true)
        let url = devconfig.user + '/auth/signup-progress';

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setUserData(response.data);
                if (response.data.signup_status === "INVESTED" || payment === "SUCCESS") {
                    // setStatus(true)
                    if (editScore === true) {
                        setType("Risk Score Updated ");
                    } else {
                        setType("Investment Successful");
                        console.log('Invest success');
                    }
                    const modal = new Modal(document.getElementById("paymentSucess"), {})
                    modal.show()
                } else {
                    // setStatus(false)
                    setType("Investment Failed")
                    const modal = new Modal(document.getElementById("paymentSucess"), {})
                    modal.show()
                }

            }
        });
    }

    useEffect(() => {
        if (openWelcomeModal) {
            const modal = new Modal(document.getElementById("welcome"), {})
            modal.show()
        }
    }, [openWelcomeModal])



    //  deny edit risk score
    const denyEditScore = () => {
        let url = devconfig.user + "/profile/reject-edit-riskscore";
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setStatus(false);
                setType('Risk Score Update Failed');
                const modal = new Modal(document.getElementById("paymentSucess"), {})
                modal.show()

            }
            else {
                $('#rebalance').trigger('click');
                setError(response.message);
            }
        })
        setTimeout(() => {
            setError(null);
        }, 3000)
    }


    // Listener for request for show  video modal 
    useEffect(() => {
        if (playerModal === true) {
            var modal = new Modal(document.getElementById("video-player"), {})
            modal.show()
        }
    }, [playerModal])

    // return chart RETURNS chart label value
    const _getReturns = (percentage, type = null) => {
        let base = 1000000;
        let year;
        if (returnsFilter == 0) year = 2;
        else if (returnsFilter == 1) year = 5;
        else if (returnsFilter === 2) year = 10;

        let added = base * (Math.pow((1 + (percentage / 100) / 1), (1 * year)));
        if (type !== null) {
            return added;
        }
        // set orientation for value 
        var val = Math.abs(added)
        if (val >= 10000000) {
            val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(2) + ' Lac';
        }
        return val
    }

    const getHeight = (percentage) => {
        let base = 1000000;
        let height = 200;
        let year;
        if (returnsFilter === 0) year = 2;
        else if (returnsFilter === 1) year = 5;
        else if (returnsFilter === 2) year = 10;
        let added = base * (Math.pow((1 + (percentage / 100) / 1), (1 * year)));
        return `${added / 200 / 100}px`;
    }

    const _subscriptionEventTrack = () => {
        window.analytics.track("Subscription started", {
            user_id: userData.user_id,
            investmentamount: '',
            payment_type: '',
            investment_activation: false
        });
    }

    const _identifierTrack = () => {
        window.analytics.identify(userData?.user_id, {
            created_at: profileData?.created,
            email: profileData?.email,
            name: `${profileData?.first_name} ${profileData?.last_name}`,
            phone_number: profileData?.mobile,
            birth_date: profileData?.dob,
            invested_status: userData?.active,
            amount_invested: profileData?.invested_amount,
            last_visited: Date.now(),
            last_investeddate: profileData?.last_invested,
            risk_score: profileData?.risk_sore,
            // investment_plan: profileData?.risk_sore,
            sip_date: profileData?.sip_date,
            path: window.location.pathname
        });
    }

    const _updateScores = (score) => {
        let index = score - 1;
        console.log('score', score);
        console.log('index', index);
        if (index === 0) {
            setImmediateLow(riskScores[1]);
            setImmediateHigh(riskScores[2]);
        } else if (index === 19) {
            setImmediateLow(riskScores[17]);
            setImmediateHigh(riskScores[18]);
        } else {
            setImmediateLow(riskScores[index - 1]);
            setImmediateHigh(riskScores[index + 1]);
        }

        setCurrentScore(riskScores[index]);

    }

    const updateRiskAllocation = (score) => {
        setSubmitLoader(true);
        setEditableData([]);
        setFundClass([]);
        setcolors([]);
        setUserRiskData([]);
        graphData = '';
        setLoader(true)
        let userAllocation = [];
        let actualAllocation = [];
        let url = devconfig.user + '/portfolio/get'
        let data = JSON.stringify({
            risk_score_id: score
        });
        console.log('API data :: ', data)
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {


                let newArr = [];
                for (let index = 0; index < response.data.allocation.length; index++) {
                    const element = response.data.allocation[index].allocation;
                    newArr.push(element)
                }
                submitRiskData(response.data, score)
                // setRiskData(response.data);


                // setTotalRisk(response.data?.allocation?.reduce((a, b) => a = a + b.allocation, 0));

                // setOriginalAllocationData(newArr);
                // setUserRiskData(userAllocation);
                // // if ( editScore === false  ) {
                // // 	setCheck(response.data.user_configured);
                // // }

                // setEditableData(response.data.graph_data.allocation);
                // setFundClass(response.data.graph_data.fund_class)
                // setcolors(response.data.graph_data.colors)
                // // graphData = response.data
                // setLoader(false)

            }
            else {
                setSubmitLoader(false);
                // setError(response.message);
                // setLoader(false)
            }
        })
    }

    useEffect(() => {
        if (selectedPortfolio) {
            var modal = new Modal(document.getElementById("allocation"), {})
            modal.show()
        }
    }, [selectedPortfolio])

    return (
        <div className="e-onboard-step-section e-portfolio-section ">
            <div className="e-step-progress" style={{ width: `${100}%`, position: 'fixed' }}></div>
            <div className="e-inner-section">
                {/* Progress bar */}
                {/* <div className="e-step-progress"></div> */}

                {/* Title section */}
                <div className="row px-lg-5 px-3 pt-lg-5 pt-0 pb-3">
                    <div className={isPage === true ? "col-8" : "col-10"}>
                        <h4 className="e-step-title">
                            Your personalized portfolio
                        </h4>
                        <p className="e-desc">
                            Based on the answers you provided in the risk analysis questionnaire,<br />
                            we recommend the following portfolio
                        </p>
                    </div>
                    <div className="col-2 text-end">
                        {/* <span className="e-step-indicator">STEP 1/4</span> */}
                    </div>
                </div>

                {/* Video  player */}
                <div className="row px-lg-5 px-3">
                    <div
                        className={
                            isPage === true ?
                                "col-lg-7 col-md-12 col-sm-12 pe-lg-4 pe-md-4 pe-2"
                                :
                                "col-lg-9 col-md-12 col-sm-12  pe-md-4 pe-2"
                        }
                    >
                        <div className="e-portfolio-video-wrapper">
                            <VideoPlayer />
                        </div>
                    </div>
                    <div
                        className={
                            isPage === true ?
                                "col-lg-7 col-12 mt-4 pe-lg-4 pe-md-4 pe-2"
                                :
                                "col-lg-9 col-12 mt-4  pe-md-4 pe-2"
                        }
                    >
                        <div className='row mt-2 mb-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 position-relative'>
                                <h6 className='e-video-sec-title'>Your risk score
                                    <img alt=""
                                        src={TooltipIcon}
                                        className="e-tooltip-hover ms-2"
                                        onMouseEnter={() => setIsShown(true)}
                                        onMouseLeave={() => {
                                            setTimeout(() => {
                                                setIsShown(false)
                                            }, 100)
                                        }}
                                    />
                                    {
                                        isShown ?
                                            <IconStyleToolTip className="e-tooltip"
                                                icon={Tooltip}
                                                content="We've determined that your risk tolerance lies in the dark blue region on the sliding bar below. We recommend that you select a portfolio within that region. However, you are free to use the sliding scale to view and invest in portfolios outside of it. You can even use the 'Edit investments' toggle further below to customize your portfolio."
                                                more=" Want to learn more about how we calculate risk? "
                                                hover={() => setIsShown(true)}
                                                leave={() => setIsShown(false)} />
                                            :
                                            null
                                    }
                                </h6>
                            </div>
                            <div className='col-md-6 col-sm-12 text-lg-end text-md-end'>
                                <h5
                                    className='e-methodology-link'
                                    data-bs-target="#methodology"
                                    data-bs-toggle="modal"
                                >
                                    How we invest your money
                                    <img src={Arrow} className="ps-1" />
                                </h5>
                            </div>
                        </div>

                        {/* Risk score picker */}
                        <div className={check === false ? 'pt-5 mt-4' : ''}>
                            {
                                riskLoader ?
                                    <Loader className="e-range-slider-loader"
                                        removeTag={true} />
                                    :
                                    <>
                                        {
                                            check ?
                                                // <CardIconStyleToolTip
                                                //     bgColor="#EDF3FF"
                                                //     icon={Tooltip}
                                                //     className="mb-3"
                                                //     content="You do not have a risk score since you edited the allocation."
                                                // />
                                                <div className="e-onboard-sub-info p-3 mb-5">
                                                    <div className="row">
                                                        <div className="col-11 mt-1">
                                                            <h5>
                                                                You do not have a risk score since you edited the allocation.
                                                            </h5>
                                                        </div>
                                                        <div className="col-1">
                                                            <img src={Flag} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <Slider values={riskScores}
                                                    defaultValue={userRiskScore}
                                                    invade_boundary={(temp) => {
                                                        console.log('temp', temp)
                                                        setInvadeBoundary(temp)
                                                    }}
                                                    skipped={isSkipped}
                                                    Score={(score) => {
                                                        _updateScores(score)
                                                        setRiskId(score);
                                                        setCheck(false)
                                                    }} />
                                        }
                                    </>

                            }
                        </div>

                        {/* Risk score preview */}

                        <div className="col-12 mt-3">
                            <div className="e-portfolio-riskscore-preview position-relative p-4">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <h5 className="e-card-title">Your risk score</h5>
                                        <p className="e-card-link-btn"
                                            onClick={() => {
                                                setSelectedPortfolio({ score: currentScore?.label, min: currentScore?.min_sip, risk_id: currentScore?.value });
                                                setSelected(1);
                                            }}
                                        >
                                            See asset allocations <img src={WhiteArrow} className="ms-1" style={{ height: '6.5px' }} />
                                        </p>
                                        <span className="e-label">Minimum Investment Amount</span>
                                        <h4 className="e-amount">
                                            <span className="e-rupee-symbol position-relative me-1" style={{ fontSize: '14px', fontWeight: '200', top: '-3px' }}>₹</span>
                                            {currentScore?.min_sip?.toLocaleString('en-IN')}
                                        </h4>
                                        <span className="e-card-badge">Recommended</span>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 text-end">
                                        <h4 className="e-score mb-4">
                                            {
                                                currentScore.value && currentScore.value % 2 == 0 && currentScore.value !== 20 ?
                                                    currentScore?.label + '.0'
                                                    :
                                                    currentScore?.label
                                            }
                                        </h4>
                                        <button
                                            className={
                                                submitLoader && selected == 1 ?
                                                    " e-btn-loader e-invest-btn mt-5"
                                                    :
                                                    "e-invest-btn mt-5"
                                            }
                                            onClick={() => {
                                                setSelected(1);
                                                if (check === true) {
                                                    submitRiskData(riskData, currentScore?.value)
                                                } else {
                                                    updateRiskAllocation(currentScore?.value);
                                                }


                                            }}
                                        >
                                            <span>
                                                {
                                                    submitLoader && selected == 1 ?
                                                        'Loading'
                                                        :
                                                        'Invest in this portfolio'
                                                }

                                                {
                                                    submitLoader && selected == 1 ? (
                                                        null
                                                    ) : (
                                                        <img src={BtnArrow} className="ms-2" />
                                                    )
                                                }
                                            </span>

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="e-subsequent-score-card p-4"
                                    style={{ borderColor: selected === 2 && '#D2D2D2' }}
                                    onClick={() => setSelected(2)}
                                >
                                    <div className="row">
                                        <div className="col-8 mt-2">
                                            <h6 className="e-title">
                                                Similar to your profile
                                            </h6>
                                        </div>
                                        <div className="col-4 text-end">
                                            <span className="e-score">
                                                {
                                                    immediateLow.value && immediateLow.value % 2 == 0 && immediateLow.value !== 20 ?
                                                        immediateLow?.label + '.0'
                                                        :
                                                        immediateLow?.label
                                                }
                                            </span>
                                        </div>
                                        <p className="e-card-link-btn"
                                            onClick={() => {
                                                setSelectedPortfolio({ score: immediateLow?.label, min: immediateLow?.min_sip, risk_id: immediateLow?.value });
                                                setSelected(2);
                                            }}
                                        >
                                            See asset allocations <img src={ArrowBlue} style={{ height: '6.5px' }} className="ms-1" />
                                        </p>
                                        <p className="e-label">
                                            Minimum Investment Amount
                                        </p>
                                        <h6 className="e-amount">
                                            <span className="e-rupee-symbol position-relative me-1" style={{ fontSize: '14px', fontWeight: '600', top: '-3px' }}>₹</span>
                                            {immediateLow?.min_sip?.toLocaleString('en-IN')}
                                        </h6>
                                        <div className="col-9 mt-2">
                                            <button className={
                                                submitLoader && selected == 2 ?
                                                    "e-continue-btn e-btn-loader"
                                                    :
                                                    'e-continue-btn'
                                            }
                                                onClick={() => {
                                                    // updateRiskAllocation(immediateLow?.value); setSelected(2); 
                                                    setSelectedPortfolio({ score: immediateLow?.label, min: immediateLow?.min_sip, risk_id: immediateLow?.value });
                                                    setSelected(2);
                                                }}

                                            >
                                                <span>
                                                    {
                                                        submitLoader && selected == 2 ?
                                                            'Loading'
                                                            :
                                                            'Continue with this'
                                                    }
                                                    {
                                                        submitLoader && selected == 2 ? (
                                                            null
                                                        ) : (
                                                            <img src={BtnArrow} className="ms-2" />
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-lg-0 mt-md-0">
                                <div className="e-subsequent-score-card p-4"
                                    style={{ borderColor: selected === 3 && '#D2D2D2' }}
                                    onClick={() => setSelected(3)}
                                >
                                    <div className="row">
                                        <div className="col-8 mt-2">
                                            <h6 className="e-title">
                                                Similar to your profile
                                            </h6>
                                        </div>
                                        <div className="col-4 text-end">
                                            <span className="e-score">
                                                {
                                                    immediateHigh.value && immediateHigh.value % 2 == 0 && immediateHigh.value !== 20 ?
                                                        immediateHigh?.label + '.0'
                                                        :
                                                        immediateHigh?.label
                                                }
                                            </span>
                                        </div>
                                        <p className="e-card-link-btn"
                                            onClick={() => {
                                                setSelectedPortfolio({ score: immediateHigh?.label, min: immediateHigh?.min_sip, risk_id: immediateHigh?.value });
                                                setSelected(3);
                                            }}
                                        >
                                            See asset allocations <img src={ArrowBlue} className="ms-2" />
                                        </p>
                                        <p className="e-label">
                                            Minimum Investment Amount
                                        </p>
                                        <h6 className="e-amount">
                                            <span className="e-rupee-symbol position-relative me-1" style={{ fontSize: '14px', fontWeight: '600', top: '-3px' }}>₹</span>
                                            {immediateHigh?.min_sip?.toLocaleString('en-IN')}
                                        </h6>
                                        <div className="col-9 mt-2">
                                            <button
                                                className={
                                                    submitLoader && selected == 3 ?
                                                        "e-continue-btn e-btn-loader"
                                                        :
                                                        'e-continue-btn'
                                                }
                                                onClick={() => {
                                                    // updateRiskAllocation(immediateHigh?.value); setSelected(3); 
                                                    setSelectedPortfolio({ score: immediateHigh?.label, min: immediateHigh?.min_sip, risk_id: immediateHigh?.value });
                                                    setSelected(3);
                                                }}
                                            >
                                                <span>
                                                    {
                                                        submitLoader && selected == 3 ?
                                                            'Loading'
                                                            :
                                                            'Continue with this'
                                                    }
                                                    {
                                                        submitLoader && selected == 3 ? (
                                                            null
                                                        ) : (
                                                            <img src={BtnArrow} className="ms-2" />
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 text-center mt-3 ">
                                <PrimaryIconBtn
                                    name="Help me choose"
                                    right={true}
                                    image={ArrowUp}
                                    className="e-btn-link"
                                    handleClick={() => setOpenWelcomeModal(true)}
                                />
                            </div>
                            {
                                loader ?
                                    <div className="row  mt-4 pt-2">
                                        <Loader />
                                    </div>
                                    :
                                    <>

                                        {/* Chart sections */}
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3 ">
                                            <h5 className="e-chart-title">
                                                Asset allocations for this score
                                            </h5>
                                            <div className="e-chart-card p-4 ps-5">
                                                {
                                                    graphAllocation ?
                                                        <>
                                                            {

                                                                <PieChart
                                                                    data={graphAllocation}
                                                                    colors={riskData?.graph_data.colors}
                                                                    labels={riskData?.graph_data.fund_class}
                                                                    width="250"
                                                                    height="220"
                                                                />
                                                            }
                                                        </>

                                                        :
                                                        null
                                                }
                                                <div className="e-asset-chart-label-wrapper ">
                                                    {
                                                        riskData.graph_data.allocation.map((item, key) => {
                                                            return (
                                                                <div className=' e-chart-label mt-2' key={key}>
                                                                    <p className="d-inline e-chart-label-text">
                                                                        <div className="rounded-circle me-2 pt-1"
                                                                            style={{ backgroundColor: `${riskData.graph_data.colors[key]}`, width: '16px', height: '16px', display: 'inline-block', position: 'relative', top: '3px' }}
                                                                        >
                                                                        </div>
                                                                        {riskData.graph_data.fund_class[key]}:
                                                                    </p>
                                                                    <span className='pt-2 ps-2 d-inline'>{item}%</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-lg-3 mt-md-3 mt-4">
                                            <h5 className="e-chart-title">
                                                Probable returns
                                            </h5>
                                            <div className="e-chart-card p-4">
                                                <p className="e-desc">
                                                    Invest a lumpsum amount of <span><span className="e-rupee-symbol">₹</span>1,00,000</span> for {returnsFilter === 0 ? 2 : returnsFilter === 1 ? 5 : 10} years
                                                </p>
                                                <h4 className="e-returns mb-3">
                                                    <span>
                                                        {
                                                            (_getReturns(returns[riskId]?.mintd[returnsFilter], 1) / _getReturns(returns['fd']?.fd[returnsFilter], 1)).toFixed(2)}X</span> returns with Mintd </h4>

                                                {/* Graph */}
                                                <div className='col-lg-12 pb-4 mb-5'>
                                                    <div className='e-bar-wrapper p-3'>
                                                        <div className='row  e-graph-bottom'>
                                                            {/* 
                                                        ---------Graph bar height calculation---------
                                                                Base height = 150px
                                                                formula = returns%*2+100 * Base height / 100
                                                                eg : 16.81*2+100 * 150 / 100
                                            */}
                                                            {/* First bar */}
                                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6 text-end position-relative'>
                                                                <h6 // graph label

                                                                    style={{
                                                                        bottom: getHeight(returns[riskId]?.mintd[returnsFilter]), left: '42px' // setting chart label position
                                                                    }}
                                                                >
                                                                    <span className="e-rupee-symbol">₹</span>{_getReturns(returns[riskId]?.mintd[returnsFilter])}
                                                                </h6>

                                                                <div  // graph bar
                                                                    className='e-mintd-bar'
                                                                    style={{
                                                                        height: getHeight(returns[riskId]?.mintd[returnsFilter])//setting height of bar  
                                                                    }}>
                                                                </div>
                                                            </div>
                                                            {/* Second bar */}
                                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6 text-start position-relative'>
                                                                <h6
                                                                    style={{
                                                                        bottom: getHeight(returns['fd']?.fd[returnsFilter])// setting chart label position
                                                                    }}
                                                                >
                                                                    <span className="e-rupee-symbol">₹</span>{_getReturns(returns['fd']?.fd[returnsFilter])}
                                                                </h6>
                                                                <div
                                                                    className='e-gold-bar '
                                                                    style={{
                                                                        height: getHeight(returns['fd']?.fd[returnsFilter])
                                                                    }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Graph legend */}
                                                        <div className='row mt-2'>
                                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6 text-center text-lg-end'>
                                                                <p className='e-graph-legend pe-3'>Mintd</p>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-sm-6 col-6  text-start ps-0'>
                                                                <p className='e-graph-legend '>Fixed deposit</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Graph end */}

                                                {/* Filter for graph */}
                                                <div className='col-lg-8 offset-2 mt-5 mt-lg-4 mt-md-4'>
                                                    <div className='d-flex justify-content-around '>
                                                        <div
                                                            className={
                                                                returnsFilter === 0 ?
                                                                    'e-switch e-active-switch'
                                                                    :
                                                                    'e-switch'
                                                            }
                                                            onClick={() => setReturnsFilter(0)}
                                                        >
                                                            2Y
                                                        </div>
                                                        <div
                                                            className={
                                                                returnsFilter === 1 ?
                                                                    'e-switch e-active-switch mx-3'
                                                                    :
                                                                    'e-switch mx-3'
                                                            }
                                                            onClick={() => setReturnsFilter(1)}
                                                        >
                                                            5Y
                                                        </div>
                                                        <div
                                                            className={
                                                                returnsFilter === 2 ?
                                                                    'e-switch e-active-switch'
                                                                    :
                                                                    'e-switch '
                                                            }
                                                            onClick={() => setReturnsFilter(2)}
                                                        >
                                                            10Y
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }

                            {/* FAQ section */}

                            <div className="col-12 mt-4">
                                <h5 className="e-chart-title">
                                    Frequently asked questions
                                </h5>

                                <Accordion defaultActiveKey="0" flush className="e-portfolio-faq">
                                    {
                                        faq.map((item, key) => {
                                            return (
                                                <Accordion.Item eventKey={key} className="mt-3">
                                                    <Accordion.Header>{item.title}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {
                                                            key !== 5 ?
                                                                item.answer
                                                                :
                                                                <div>
                                                                    <p>
                                                                        We never hold your money or investments. The funds are always in your name with each asset manager, we merely facilitate the transaction. So IF anything were to happen to us you could always:
                                                                    </p>
                                                                    <p>
                                                                        a. Go to CAMS portal online and withdraw the amount
                                                                    </p>
                                                                    <p>
                                                                        b. Go to the AMC website and login using your PAN and withdraw the amount
                                                                    </p>
                                                                    <p>
                                                                        c. Call your AMC customer care who will send you a link to withdraw the amount
                                                                    </p>
                                                                    <p>
                                                                        Either ways we will facilitate this process for each of our customers.
                                                                    </p>
                                                                </div>
                                                        }

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }

                                </Accordion>
                            </div>

                            {/* Edit allocation */}
                            {
                                !editScore ?
                                    <div className="col-12">
                                        <div className='row my-3 p-3'>
                                            <div className='e-allocation-edit-card pb-2 pt-1'>
                                                <div className='d-flex justify-content-between ps-1'>
                                                    <h6 className='e-edit-allocation-title mt-3'>Edit allocation</h6>
                                                    <div className='me-4 pe-3'>
                                                        <ToggleButton
                                                            check={check}
                                                            onChange={(e) =>
                                                                setCheck(e.target.checked)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    check ?
                                                        riskData && riskData.allocation && riskData.allocation.map((item, key) => {
                                                            return (
                                                                <div className='row  ps-1 pe-1' key={key}>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 order-1 " >
                                                                        <div className="e-investment-title mt-3" key={key}>
                                                                            <p className="mb-1 e-fund-name">{item.display_name}</p>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 pt-2 col-4 d-flex order-lg-2 order-md-2 order-sm-2 order-3  mb-3 mb-lg-0 mb-md-0 mb-sm-0" style={{ alignItems: 'center' }}>
                                                                        <div className='e-progress-track position-relative'>
                                                                            <div
                                                                                className='e-progress-bar' id={"progress-bar-" + key}
                                                                                style={{
                                                                                    width:
                                                                                        //  item.allocation <= 100 ? `${item.allocation}px`: `${100}px`,
                                                                                        check ?
                                                                                            riskData.user_configured ?
                                                                                                item.user_allocation <= 100 ?
                                                                                                    `${item.user_allocation}px` : `${100}px`
                                                                                                : item.allocation <= 100 ?
                                                                                                    `${item.allocation}px` : `${100}px`
                                                                                            : `${originalAllocationData[key]}px`,
                                                                                    backgroundColor: `${riskData.graph_data.colors[key]}`, height: "4px"
                                                                                }}
                                                                            ></div>
                                                                        </div>
                                                                        <AllocationInput value={allocation}
                                                                            type="number"
                                                                            id="allocationChange"
                                                                            valueChange={
                                                                                (value) => {
                                                                                    setUpdatingGraph(true);
                                                                                    let temp = riskData;
                                                                                    if (value === "") {
                                                                                        riskData.allocation.includes((data) => data.portfolio_id === item.portfolio_id ? setAllocation(0) : item.allocation);
                                                                                        temp.allocation[key]['allocation'] = 0;
                                                                                        temp.graph_data.allocation[key] = 0
                                                                                        temp.allocation[key]['user_allocation'] = 0;
                                                                                    } else {
                                                                                        riskData.allocation.includes((data) => data.portfolio_id === item.portfolio_id ? setAllocation(Number(value)) : item.allocation);
                                                                                        temp.allocation[key]['allocation'] = Number(value);
                                                                                        temp.graph_data.allocation[key] = Number(value)
                                                                                        temp.allocation[key]['user_allocation'] = Number(value);
                                                                                        temp.allocation[key]['edit'] = true;
                                                                                    }

                                                                                    // For update pie chart change
                                                                                    setGraphAllocation([...temp.graph_data.allocation]);
                                                                                    setRiskData({ ...temp })
                                                                                    setUpdatingGraph(false);
                                                                                    setTotalRisk(temp.allocation?.reduce((a, b) => a = a + b.allocation, 0));
                                                                                    // setTotalRisk(riskData?.allocation?.reduce((a, b) => a = a + b.allocation, 0));
                                                                                    // }

                                                                                }}
                                                                            onBlur={() => {
                                                                                //     // setTotalRisk(riskData?.allocation?.reduce((a, b) => a = a + b.allocation, 0));
                                                                            }}
                                                                            defaultValue={riskData.user_configured ? item.user_allocation : item.allocation}
                                                                        // defaultValue={item.allocation}
                                                                        />
                                                                    </div>
                                                                    {/* <div className="col-lg-2 col-2 text-end ps-0 position-relative">
																										
																									</div> */}
                                                                    <div className='col-lg-6 col-md-6 col-sm-6 col-12 order-lg-3 order-md-3 order-sm-3 order-2'>
                                                                        <div className='e-investment-title'>
                                                                            <span className='e-display-name'>{item.scheme_name}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }
                                            </div>
                                            {
                                                check ?
                                                    <div className='col-lg-12 mt-3'>
                                                        <span className="e-total">Total:	{
                                                            riskData.user_configured ?
                                                                riskData?.allocation?.reduce((a, b) => a = a + b.user_allocation, 0)
                                                                :
                                                                riskData?.allocation?.reduce((a, b) => a = a + b.allocation, 0)
                                                        }/100
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                            }


                        </div>

                    </div>
                </div>
                {
                    mountInvestModal ?
                        <InvestNowModal
                            haveMandate={haveMandate}
                            minAmount={minAmount}
                            allocation_data={riskData && riskData.allocation}
                            location="portfolio"
                            risk_id={riskId}
                            success={() => { }}
                            reload={() => { }}
                            unmountModal={() => { console.log('invest modal unmount'); setMountIvestModal(false) }}
                            updateContext={(data, amount) => {

                                if (data === 'SUCCESS') {
                                    setStatus(true);
                                    setModalContent(
                                        `We have received your payment of ₹${amount}. 
                                        The orders have been placed with each asset manager and you should 
                                        receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.`
                                    )
                                }
                                else {
                                    setStatus(false);
                                    setModalContent(
                                        `Investment failed please try again `
                                    );
                                }
                                updateContext(data);
                            }}
                            details={minInvestDetails}
                            assetsDetail={assetDetail}
                            editScore={editScore}

                        />
                        :
                        null
                }
                {
                    mountRebalanceModal ?
                        <RebalanceModal
                            editScore={true}
                            rejectEdit={() => {
                                denyEditScore();
                                setMountRebalanceModal(false);
                            }}
                            haveMandate={haveMandate}
                            modalClose={() => {
                                setMountRebalanceModal(false);

                            }}
                            success={(temp) => {
                                updateContext(temp)
                            }}
                        />
                        :
                        null
                }
            </div>
            <div class='e-fixed-btn-wrapper'>
                <PrimaryBtn
                    image={ArrowInvestBtn}
                    iconClass="ms-2"
                    right={true}
                    name={
                        allocationModalButton === 1 ?
                            "Do KYC and Invest"
                            :
                            "Invest Now"
                    }
                    className="e-fixed-btn ms-2"
                    handleClick={() => {
                        submitRiskData(riskData, currentScore?.value)
                    }}
                />
            </div>
            <MethodologyModal />
            {
                type !== null ?
                    <SuccessPayment
                        editScore={editScore}
                        heading={type}//type
                        status={status}//status
                        content={modalContent}
                        from="portfolio"
                        close={() => {
                            setType(null);
                            // if (!status) {
                            // 	window.location.href = "/orders"
                            // }
                        }} />
                    :
                    null
            }

            {
                openSIPConfirmation === true && (
                    <OpenSIPConfirmation
                        modalClose={() => setOpenSIPConfirmation(false)}
                        continue={() => setMountRebalanceModal(true)}
                    />
                )
            }

            {
                error !== null ?
                    <Toast data={error} type="fail" />
                    :
                    null
            }
            {
                selectedPortfolio && (
                    <AllocationModal
                        buttonType={allocationModalButton}
                        selectedPortfolio={selectedPortfolio}
                        riskData={riskData}
                        modalClose={() => setSelectedPortfolio(null)}
                        callBack={() => {
                            updateRiskAllocation(selectedPortfolio.risk_id)
                        }}
                    />
                )
            }

            {
                openWelcomeModal && (
                    <WelcomeModal
                        modalClose={() => setOpenWelcomeModal(false)}
                        name={props.profile.first_name}
                        removeTour={true}
                    />
                )
            }
        </div>
    )
}

export default Portfolio;