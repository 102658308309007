/*
 *   File : portfolioSummary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Portfolio smmary Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-12-2021
*/

/*import packages */
import React from "react";

/* import assests */
import Flag from '../../assets/images/new-design/flagYellow.svg';
import Down from '../../assets/images/onboard/down.svg'
import { Input } from "../input";

export default function Proof(props) {
    return (
        <div className="e-onboard-sub-info p-3 position-relative">
            <div className="row">
                <div className="col-11">
                    <h5>
                        Please make sure to copy your address word-to-word from the ID proof you've uploaded
                    </h5>
                    {
                        props.addressProofType ?
                            <img src={props.proof} alt="" className="mt-3" />
                            :
                            null
                    }
                </div>
                <div className="col-1">
                    <img src={Flag} />
                </div>

                {
                    props.addressProofType ?
                        <>
                            <img src={props.arrow ? props.arrow : Down} alt="" className="e-arrow-img" style={{ width: props.width, left: props.left, top: props.top }} />
                            <div className="col-lg-12 mt-3 px-0">
                                <div className="row e-onboard-info-fields mx-2 p-2">
                                    <div className="col-lg-6 col-md-6 col-12 ">
                                        <label className="mb-1">
                                            Address Line 1
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            inputId="address1"
                                            label="Address line 1"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 mt-3 mt-lg-0 mt-md-0">
                                        <label className="mb-1">
                                            Address Line 2
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            inputId="address2"
                                            label="Address line 2"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null
                }
            </div>
        </div >
    )
}