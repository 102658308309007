/*
 *   File : redeem.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Invest Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*import packages*/
import React, { useEffect, useState, useContext } from 'react';
import $, { data } from 'jquery';

/*import componets*/
import { CheckBox } from '../../components/input/index';
import Input from '../../components/input/loginInput';
import { PrimaryBtn, PrimaryLink, SecondaryBtn } from '../../components/cta/index';
import Close from '../../assets/images/new-design/modalClose.svg';
import DownArrow from '../../assets/images/new-design/down-arrow.png';


/*import styles*/
import styles from '../../styles/components/redeem.module.scss';

// import services
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import { Loader } from '../loader';
import { Toast } from '../note';
import RedirectUser from '../../services/redirectUser';
import { UserDataContext } from '../../context/userData';
import { style } from '@mui/system';

export default function RedeemModal(props) {

	const [amount, setAmount] = useState(null);
	const [checked, setChecked] = useState(true);
	const [loader, setLoader] = useState(false);
	const [details, setDetails] = useState({});
	const [apiLoader, setAPILoader] = useState(false);
	const [error, setError] = useState(null);
	const [userData, setUserData, onboardFlag, setOnboardFlag] = useContext(UserDataContext);
	const [confirmation, setConfirmation] = useState(false);
	const [opened, setOpened] = useState(false);

	useEffect(async () => {
		getWithdrawalData();
	}, [])



	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 4000)
		}
	}, [error])

	const getWithdrawalData = () => {
		setAPILoader(true);
		let url = devconfig.user + '/investment/withdraw-amount';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				let arr = [...response.data.allocation]
				for (var i = 0; i < arr.length; i++) {

					if ((arr[i]['allocation'] === "0.0%") || (arr[i]['allocation'] === '0.0%')) {
						arr.splice(i, 1);
						i--;
					}
				}
				setDetails({
					allocation: arr,
					withdrawAmount: response.data.max_amount_to_withdraw_text,
					amount: response.data.max_amount_to_withdraw,
					minAmount: response.data.min_withdrawal_amount,
					minText: response.data.min_withdrawal_amount_text
				});
				setAmount(response.data.max_amount_to_withdraw)
				setChecked(true)
				setAPILoader(false);
			} else {
				setAPILoader(false);

			}
		})
	}

	//   analytics tracker for withdraw event
	const _withdrawingTracker = (status) => {
		window.analytics.track('Withdrawal', {
			user_id: userData?.user_id,
			withdrawal_amount: amount,
			withdraw_status: status
		});
	}

	const withdrawAmount = () => {
		setLoader(true);
		let url = devconfig.user + '/investment/withdraw';
		let data = JSON.stringify({
			amount: amount,
			redeem_all: checked,
		})

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				$('#redeem').trigger('click');
				if (checked) {
					window.location.href = "/home"
				} else {
					props.success(amount);
				}
				_withdrawingTracker(true);
				setLoader(false);

			}
			else {
				setError(response.message);
				_withdrawingTracker(false);
				setLoader(false);

			}
		})
	}

	useEffect(() => {
		let modal = document.getElementById('redeem');
		modal.addEventListener('hidden.bs.modal', () => {
			props.modalClose();
		})
	}, [])
	return (
		<div className={`${styles.e_redeem_modal} modal fade`} id="redeem" tabIndex="-1" aria-labelledby="invest" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				{
					confirmation ?
						<div className={`modal-content ${styles.e_redeem_content}`}>
							<div className={`${styles.e_modal_header} mb-2`}>
								<h5>Withdraw now</h5>
								<img
									src={Close}
									data-bs-dismiss="modal"
								/>
							</div>
							<div className='container px-3'>
								<div className='row py-3 mb-2 px-2'>
									<div className='col-lg-12 text-center'>
										<p className={`${styles.e_confirmation_message}`}>
											Are you sure you wish to withdraw ₹{amount} ?
										</p>
									</div>
									<div className='col-6'>
										<SecondaryBtn
											name="Cancel"
											className="me-3 w-100"
											handleClick={() =>
												setConfirmation(false)
											}
										/>
									</div>
									<div className='col-6'>
										<PrimaryBtn
											name={loader ? "Withdrawing" : "Yes, withdraw now"}
											className={loader ? 'e-btn-loader w-100' : 'w-100'}
											handleClick={() =>
												withdrawAmount()
											}
										/>

									</div>
								</div>
							</div>
						</div>
						:
						<div className={`modal-content ${styles.e_redeem_content}`}>
							{
								apiLoader ?
									<Loader height="250px" className="e-modal-loader" removeTag={true} />
									: !apiLoader && !details ?
										null
										:
										<>
											<div className={`${styles.e_modal_header} mb-2`}>
												<h5>Withdraw now</h5>
												<img
													src={Close}
													data-bs-dismiss="modal"
												/>
											</div>
											<div className="container py-3 px-4">
												<div className="row mb-3 px-2">
													<div className="col-lg-6">
														<label className='mb-1'>Enter withdraw amount</label>
														<div className={`${styles.e_input_wrapper}`}>
															<Input
																value={amount} type={'number'}
																className={`${styles.e_input}`}
																valueChange={(value) => { setAmount(value); setChecked(false) }}
															/>
															<span className={`${styles.e_rupee}`}>₹</span>
														</div>
														<p className={`${styles.e_to_withdraw} mt-1 mb-2`}>
															{`Min withdrawal amount ${details.minText}`}
														</p>

													</div>
													<div className="col-lg-12 mt-3">
														<CheckBox
															checkboxText="Withdraw maximum amount "
															className={`${styles.e_check_box}`}
															onChange={(e) => {
																setChecked(!checked);
																if (!checked) setAmount(details.amount);
																else setAmount('')
															}}
															check={checked}
														/>
													</div>
												</div>
												<div className="row px-2">
													<div className="col-lg-12">
														<div
															className={`${styles.e_amount_wrpper}`}
															style={{
																cursor: 'pointer',
																height: opened === true && '350px',
																overflowY: opened === true && 'auto'
															}}
															onClick={() => setOpened(!opened)}
														>
															<div className="row pb-2">
																<div className="col-lg-11">
																	<h5 className={`${styles.e_title}`}>Withdrawing money by selling these funds</h5>
																</div>
																<div className='col-lg-1'>
																	<img
																		style={{ transform: opened === true && 'rotate(180deg)' }}
																		src={DownArrow}
																	/>
																</div>
															</div>
															<div className="row pb-2">
																<div className="col-lg-8 col-8">
																	<h6 className='text-start ps-0'>Fund name</h6>
																</div>
																<div className="col-lg-2 col-2 text-center ">
																	<h6 className='text-center ps-0'>Quantity</h6>
																</div>
																<div className="col-lg-2 col-2 text-end">
																	<h6>Amount</h6>
																</div>
															</div>
															{/* <div className="row pb-2">
																<div className="col-lg-12">
																	<div className={`${styles.e_border_bottom}`}></div>
																</div>
															</div> */}
															{
																details.allocation && details.allocation.map((item, key) => {
																	return (
																		<div className='row' key={key}>
																			<div className={`col-lg-8 ${styles.e_content} col-8`}>
																				<p>{item.scheme_name}</p>
																			</div>
																			<div className={`col-lg-2 ${styles.e_content_qty} text-center col-2`}>
																				<p>{item.allocation}</p>
																			</div>
																			<div className={`col-lg-2 ${styles.e_content_qty} text-end col-2`}>
																				<p>{item.amount_text}</p>
																			</div>
																		</div>
																	)
																})
															}
														</div>
													</div>
												</div>
												<div className="row mt-3 mb-2 pt-3 px-2">
													{/* <div className="col-lg-6 pe-0 mt-2">
														<p className={`${styles.e_to_withdraw} mb-2`}>Amount to withdraw</p>
														<span className={`${styles.e_amount}`}> ₹{amount ? amount : 0}</span>


													</div> */}
													<div className="col-lg-12 ">
														{
															Number(amount) > 0 ?
																<PrimaryBtn name="Withdraw" className={`${styles.e_modal_submit}  e-modal-submit`}
																	handleClick={() => {
																		let valid = false;
																		if (amount <= details.amount) valid = true;
																		// else {
																		// 	if (amount < details.minAmount) {
																		// 		setError('Minimum withdraw amount is ' + details.minText)
																		// 	} else {
																		// 		setError('Maximum withdraw amount is ' + details.withdrawAmount)
																		// 	}
																		// }

																		//withdrawAmount()
																		setConfirmation(valid);
																	}} />
																:
																<PrimaryBtn name="Withdraw" className={`${styles.e_modal_submit} e-modal-submit`} disabled={true} />
														}

													</div>
												</div>



											</div>

										</>
							}

						</div>
				}

			</div>
			{
				error ?
					<Toast data={error} type="fail" />
					:
					null
			}
		</div>
	)
}


