// Package import
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
// Components import 
import { Loader, SkeletonLoader } from '../../components/loader';
import Nodata from '../../components/note/nodata-placeholder';
import { PrimaryIconBtn, PrimaryLink } from '../../components/cta';
import { NoAssets, SuccessToast } from '../../components/note';
import CustomToolTip from '../../components/tooltip/customeTooltip';
import { AssetList, SuccessPayment, UpdateAssets } from '../../components/modal';
import AssetDeleteConfirmation from '../../components/modal/assetRemoveConfirmation';

//Assets import
import Breadcrumb from '../../assets/images/new-design/divider.svg';
import TM from '../../assets/images/Tech-mahindra.svg';
import Tata from '../../assets/images/Tata.svg';
import HDFC from '../../assets/images/hdfc.svg';
import Infosys from '../../assets/images/Infosys.svg';
import Wipro from '../../assets/images/Wipro.svg';
import HCL from '../../assets/images/HCL.svg';
import Gain from '../../assets/images/new-design/gainTracker.svg';
import Loser from '../../assets/images/new-design/loser.svg';
import AddIcon from '../../assets/images/new-design/plus-circle-blue.svg';
import EditIcon from '../../assets/images/new-design/pen.svg';
import CardEdit from '../../assets/images/new-design/more-vertical.svg';
import List from '../../assets/images/new-design/list.svg';
import Grid from '../../assets/images/new-design/grid.svg';
import ListActive from '../../assets/images/new-design/list-active.svg';
import GridActive from '../../assets/images/new-design/grid-active.svg';
import Delete from '../../assets/images/new-design/delete-red.svg';
//service import
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';
import GetURLVar from '../../services/getUrl';

//Config import
import Config from '../../config/config';
import _getCurrentValue from '../../services/getCurrentValue';

const filters = [
    'Stocks', 'Mutual funds', 'Real Estate', 'Equity', 'Gold', 'Crypto'
]

const data = [
    {
        name: 'Tech Mahindra',
        icon: TM,
        price: '₹40, 000',
        percentage: '10%',
        qty: '30',
        current_value: '₹ 50, 000',
        performance: 'Gain'
    },
    {
        name: 'TATA Motors',
        icon: Tata,
        price: '₹40, 000',
        percentage: '10%',
        qty: '30',
        current_value: '₹ 50, 000',
        performance: 'Gain'
    },
    {
        name: 'HDFC Bank',
        icon: HDFC,
        price: '₹40, 000',
        percentage: '10%',
        qty: '30',
        current_value: '₹ 50, 000',
        performance: 'Gain'
    },
    {
        name: 'Infosys',
        icon: Infosys,
        price: '₹40, 000',
        percentage: '10%',
        qty: '30',
        current_value: '₹ 50, 000',
        performance: 'Gain'
    },
    {
        name: 'Wipro',
        icon: Wipro,
        price: '₹40, 000',
        percentage: '10%',
        qty: '30',
        current_value: '₹ 50, 000',
        performance: 'Gain'
    },
    {
        name: 'HCL',
        icon: HCL,
        price: '₹40, 000',
        percentage: '10%',
        qty: '30',
        current_value: '₹ 50, 000',
        performance: 'Gain'
    },
]
const Allocation = (props) => {
    const [tabIndex, setTabIndex] = useState(1);
    const [apiLoader, setApiLoader] = useState(false);
    const [overlayLoader, setOverlayLoader] = useState(true);
    const [assetData, setAssetData] = useState([]);
    const [filters, setFilters] = useState([]);
    const [activeUser, setActiveUser] = useState(true);
    const [openAssetList, setOpenAssetList] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState({});
    const [investedAsset, setInvestedAsset] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [editId, setEditId] = useState(null);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [toast, setToast] = useState(null);
    const [openDeleteModal, setOpDeleteModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [grid, setGrid] = useState(true);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [openInvestedTooltip, setInvestedOpenTooltip] = useState(false);
    const [deleteAll, setDeleteAll] = useState(false)
    const moreMenuRef = useRef(null);
    const scollToRef = useRef();
    const navigator = useNavigate();

    useEffect(() => {
        if (props.userDetail) _analyticsTrackUserDetails();
    }, [props.userDetail])

    const _analyticsTrackUserDetails = () => {
        window.analytics.identify(props.userDetail?.user_id, {
            path: window.location.pathname
        });
    }

    useEffect(() => {
        setActiveUser(props.active)
    }, [props.active])

    const closeMenu = (event) => {
        if (moreMenuRef.current && !moreMenuRef.current.contains(event.target)) {
            setEditIndex(null);
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 5000)
        }
    }, [toast])

    // listener for toggle asset list modal
    useEffect(() => {
        if (openAssetList) {
            const modal = new Modal(document.getElementById("asset-list"), {})
            modal.show()
        }
    }, [openAssetList])

    // listener for toggle asset update modal
    useEffect(() => {
        if (openUpdateModal) {
            const modal = new Modal(document.getElementById("update-asset"), {})
            modal.show()
        }
    }, [openUpdateModal])

    // listener for toggle asset update modal
    useEffect(() => {
        if (openDeleteModal) {
            const modal = new Modal(document.getElementById("remove-asset"), {})
            modal.show()
        }
    }, [openDeleteModal])


    useEffect(() => {
        GetURLVar().then((query) => {
            if (query) {
                if (query.edit) {
                    setEditMode(true);
                }
                _getFilters(parseInt(query.id));
                setTabIndex(parseInt(query.id));
                _getAssets(parseInt(query.id));
            } else {
                _getFilters(1);
                _getAssets(1);

            }
        })
    }, [])




    const _getAssets = (tabIndex = 1) => {
        if (tabIndex === 20 || tabIndex === 21) {
            setAssetData(null);
            return
        }
        setApiLoader(true)
        let url = Config.portfolio + '/assets/assets/list';
        let payload = JSON.stringify({
            asset_type_id: tabIndex
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setTimeout(() => { setApiLoader(false); }, 1000)

                setAssetData(response.data);
            }
            else {
                setAssetData({ data: [], total: null });
                setApiLoader(false);

            }
        })
    }

    const _getFilters = (id) => {
        let url = Config.portfolio + '/assets/general/list';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                let active;
                response.data.asset_type.map((item) => {
                    if (item.id === id) {
                        active = { value: item.id, label: item.label }
                    }
                })
                console.log('ac', active);
                setSelectedAsset(active);
                setFilters(response.data.asset_type);
                setOverlayLoader(false);
            } else {
                setFilters([]);
                setOverlayLoader(false);
            }
        })
    }

    useEffect(() => {
        if (successModal) {
            const modal = new Modal(document.getElementById("paymentSucess"), {})
            modal.show()
        }
    }, [successModal])

    return (
        <>
            {
                overlayLoader ? (
                    <SkeletonLoader />
                ) :

                    (
                        <>

                            <div className='e-portfolio-detail px-lg-5 px-3 pb-5 mb-5'>
                                <div className='row '>
                                    {/* Heading */}
                                    <div className="col-12  mt-lg-3 mt-md-3 mt-sm-0 mt-0  pt-0">
                                        <h5 className="e-dashboard-greeting mt-3">
                                            Portfolio Tracker
                                        </h5>
                                        <p className="e-dashboard-sub-title">
                                            A bird’s eye view of all your assets
                                        </p>
                                    </div>
                                    <div className='co-lg-9 col-md-12 col-sm-12 '>

                                        <span className='e-active-node' onClick={() => navigator(-1)}>Portfolio tracker</span>
                                        <img src={Breadcrumb} className='mx-2' />
                                        <span className='e-current-node'>Allocations</span>
                                        <div className='e-tab-wrapper mt-3 py-2'>
                                            {
                                                filters.map((item, key) => {
                                                    if (item.label != 'Equity') {
                                                        return (
                                                            <span

                                                                id={`e-tab-${key}`}
                                                                key={key}
                                                                className={item.id === tabIndex ? 'e-active-tab e-tab me-2' : 'e-tab me-2'}
                                                                onClick={() => {
                                                                    setSelectedAsset({ value: item.id, label: item.label });
                                                                    setTabIndex(item.id);
                                                                    _getAssets(item.id);
                                                                    setEditMode(false);
                                                                    setGrid(true)
                                                                }}
                                                            >
                                                                {
                                                                    item.id === 1 || item.id === 2 || item.id === 5 ?
                                                                        `${item.label}s`
                                                                        :
                                                                        item.label
                                                                }
                                                            </span>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>

                                    {/* <div className='co-lg-3 col-md-3 col-sm-8 mt-4 mt-lg-0 mt-md-0 ps-lg-5 ps-md-5'>
                                    {
                                        tabIndex  < 20 && (
                                            <div className='e-total-amount px-4 pt-4 pb-2'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <h6>Total amount invested</h6>
                                                        <h5 className='mt-2'>
                                                            {
                                                                assetData && assetData.total !== null ?
                                                                    `₹ ${assetData.total}`
                                                                :
                                                                '--'

                                                            }
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ) 
                                    }
                                    
                                </div> */}

                                </div>
                                <>
                                    {
                                        apiLoader ? (
                                            <Loader height="200px" />
                                        )
                                            : !apiLoader && assetData && !assetData.data.length ? (
                                                <NoAssets className="mt-3" addAsset={() => {
                                                    setOpenAssetList(true)
                                                }} />
                                            )
                                                :
                                                <>
                                                    {
                                                        tabIndex === 2 && (
                                                            <div className='row mt-lg-3 mt-md-3 mt-sm-3 mt-3'>
                                                                <div className='col-lg-5 col-md-9 col-sm-12'>
                                                                    <div
                                                                        className='e-detail-card-wrapper position-relative'

                                                                    >
                                                                        <div
                                                                            className='e-allocation-detail-card p-4'
                                                                        >
                                                                            <div className='row'>
                                                                                <div className='col-lg-8 col-md-8 col-sm-6 col-12'>
                                                                                    <label>
                                                                                        Current Value
                                                                                    </label>
                                                                                    <h5 className='e-current-value '
                                                                                        onMouseEnter={() => {
                                                                                            setOpenTooltip(true)
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            // $(`#e-portfolio-value`).removeClass('e-hover-popup');
                                                                                            setOpenTooltip(false)
                                                                                        }}>
                                                                                        <span className='e-rupee'>₹ </span>
                                                                                        <span className='position-relative'>
                                                                                            {_getCurrentValue(assetData.totals.current_value)}
                                                                                            {
                                                                                                openTooltip && (
                                                                                                    <CustomToolTip data={Number((assetData.totals.current_value).toFixed(2)).toLocaleString('en-IN')} top="0px" symbol={true} />
                                                                                                )
                                                                                            }
                                                                                        </span>

                                                                                    </h5>
                                                                                    <p className='e-last-updated'>
                                                                                        {assetData.totals.last_updated_date}
                                                                                        {/* <img src={Refresh} className="ms-2" /> */}
                                                                                    </p>
                                                                                    <PrimaryIconBtn
                                                                                        name="Add more"
                                                                                        className="ps-0"
                                                                                        left={true}
                                                                                        image={AddIcon}
                                                                                        iconClass="me-2 e-link-icon"
                                                                                        handleClick={() => setOpenAssetList(true)}
                                                                                    />
                                                                                    <PrimaryIconBtn
                                                                                        name="Edit"
                                                                                        left={true}
                                                                                        image={EditIcon}
                                                                                        iconClass="me-2"
                                                                                        className='ms-2'
                                                                                        handleClick={() => {
                                                                                            scollToRef.current.scrollIntoView({ behavior: "smooth" })
                                                                                            setEditMode(true)

                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 text-end'>
                                                                                    <div className='e-portfolio-indicator'>
                                                                                        <span
                                                                                            className='e-gainer'
                                                                                            style={{
                                                                                                color: assetData.totals.returns > 0 ?
                                                                                                    '#3CBC81'
                                                                                                    : assetData.totals.returns < 0 ?
                                                                                                        '#E74A3B'
                                                                                                        : '#010101'
                                                                                            }}
                                                                                        >
                                                                                            {assetData.totals.returns_percentage}
                                                                                        </span>
                                                                                        {
                                                                                            assetData.totals.returns !== 0 && (
                                                                                                <img
                                                                                                    src={
                                                                                                        assetData.totals.returns > 0 ?
                                                                                                            Gain
                                                                                                            :
                                                                                                            Loser
                                                                                                    }
                                                                                                    className="e-indicator ms-2"
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Invested
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0 mb-0' >
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        {/* <span className='position-relative'> */}
                                                                                        {_getCurrentValue(assetData.totals.invested_amount)}
                                                                                        {/* {
                                                                                                openInvestedTooltip && (
                                                                                                    <CustomToolTip data={Number((assetData.totals.invested_amount).toFixed(2)).toLocaleString('en-IN')} top="0px" symbol={true} />
                                                                                                )
                                                                                            } */}
                                                                                        {/* </span> */}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-3 pe-0'>
                                                                                    <label className='mb-1'>
                                                                                        Current Return
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0 mb-0'>
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        {(assetData.totals.returns)}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Gain/Loss
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0 mb-0'>
                                                                                        {
                                                                                            assetData.totals.returns > 0 ?
                                                                                                "Gain"
                                                                                                :
                                                                                                assetData.totals.returns < 0 ?
                                                                                                    "Loss"
                                                                                                    :
                                                                                                    '-'
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row pe-lg-3 pe-md-3 pe-0' ref={scollToRef} >
                                                                    <div className='col-lg-6 col-4 my-4'>
                                                                        {
                                                                            assetData && (
                                                                                <h6 className='e-subtitle'>{assetData.data.length > 1 ? `${assetData.data.length} Stocks` : `${assetData.data.length} Stock`}</h6>
                                                                            )
                                                                        }

                                                                    </div>
                                                                    <div className='col-lg-6 col-8 text-end my-4'>

                                                                        {
                                                                            !editMode ?
                                                                                assetData && (
                                                                                    <span>
                                                                                        View options
                                                                                        <img
                                                                                            src={grid ? GridActive : Grid}
                                                                                            alt=""
                                                                                            className={grid ? 'e-active px-3' : 'e-inactive px-3'}
                                                                                            onClick={() => {
                                                                                                setGrid(true)
                                                                                            }} />
                                                                                        <img
                                                                                            src={grid ? ListActive : List}
                                                                                            className={!grid ? 'e-active' : 'e-inactive'}
                                                                                            alt=""
                                                                                            onClick={() => {
                                                                                                setGrid(false)
                                                                                            }} />
                                                                                    </span>
                                                                                )
                                                                                :
                                                                                <PrimaryLink
                                                                                    name={`Delete all ${selectedAsset.value === 1 || selectedAsset.value === 2 || selectedAsset.value === 5 ? `${selectedAsset.label}s` : selectedAsset.label}`}
                                                                                    left={true}
                                                                                    image={Delete}
                                                                                    className="e-delete"
                                                                                    iconClass="me-2"
                                                                                    handleClick={() => {
                                                                                        setDeleteAll(true);
                                                                                        setOpDeleteModal(true)
                                                                                    }} />
                                                                        }

                                                                    </div>

                                                                    {
                                                                        grid ?
                                                                            assetData.data.map((i, j) => {
                                                                                return (
                                                                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mb-4 pe-lg-3 pe-md-3 pe-0' >
                                                                                        <div className='e-allocation-card p-4 position-relative' >
                                                                                            {
                                                                                                j === editIndex && (
                                                                                                    <div className='e-more-menu' ref={moreMenuRef}>
                                                                                                        <div
                                                                                                            className='e-menu-item'
                                                                                                            onClick={() => {
                                                                                                                setEditId(i.id);
                                                                                                                setOpenUpdateModal(true);
                                                                                                                setEditIndex(null);
                                                                                                            }}
                                                                                                        >
                                                                                                            Edit
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className='e-menu-item'
                                                                                                            onClick={() => {
                                                                                                                setEditId(i.id);
                                                                                                                setOpDeleteModal(true);
                                                                                                                setEditIndex(null);
                                                                                                                console.log('click');
                                                                                                            }}
                                                                                                        >
                                                                                                            Delete
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            <div className='row'>
                                                                                                <div className='col-12 '>
                                                                                                    <div className='row'>
                                                                                                        <div className='col-1'>
                                                                                                            <div className='col-2'>
                                                                                                                <div className='e-icon-wrap'>
                                                                                                                    {
                                                                                                                        i.ticker ?
                                                                                                                            i.ticker.charAt(0)
                                                                                                                            :
                                                                                                                            'M'
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='col-6 pt-1 mb-2'>
                                                                                                            <h6 className='mb-1 ms-4'>
                                                                                                                {i.ticker}
                                                                                                            </h6>
                                                                                                            <span className='e-price ms-4'>Price </span>
                                                                                                            <span className='e-price-amount ps-1'>{i.invested_value.toLocaleString('en-IN')}</span>
                                                                                                        </div>
                                                                                                        <div className='col-5 ps-0 text-end'>
                                                                                                            {
                                                                                                                editMode === false ? (
                                                                                                                    <>
                                                                                                                        <span
                                                                                                                            className='e-gainer'
                                                                                                                            style={{
                                                                                                                                color: i.return_percentage > 0 ?
                                                                                                                                    '#3CBC81'
                                                                                                                                    : i.return_percentage < 0 ?
                                                                                                                                        '#E74A3B'
                                                                                                                                        : '#010101'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {`${i.return_percentage}%`}
                                                                                                                        </span>
                                                                                                                        {
                                                                                                                            i.return_percentage !== 0 && (
                                                                                                                                <img
                                                                                                                                    src={
                                                                                                                                        i.return_percentage > 0 ?
                                                                                                                                            Gain
                                                                                                                                            :
                                                                                                                                            Loser
                                                                                                                                    }
                                                                                                                                    className="e-indicator ms-2"
                                                                                                                                />
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <img
                                                                                                                        src={CardEdit}
                                                                                                                        className="e-card-edit"
                                                                                                                        onClick={() => setEditIndex(j)}
                                                                                                                    />
                                                                                                                )
                                                                                                            }

                                                                                                        </div>
                                                                                                        <div className='col-lg-2 col-md-2 col-sm-6 col-12 mt-3 e-assets-card'>
                                                                                                            <label className='d-inline me-2'>
                                                                                                                Quantity
                                                                                                            </label>
                                                                                                            <span className='e-amount d-block'>
                                                                                                                {i.quantity ? i.quantity : '-'}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-3 e-assets-card ps-4'>
                                                                                                            <label className='d-inline me-2'>
                                                                                                                Avg. price
                                                                                                            </label>
                                                                                                            <span className='e-amount d-block'>
                                                                                                                <span className='e-value-rupee'>₹ </span> {i.purchase_ltp ? i.purchase_ltp : '-'}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-3 e-assets-card'>
                                                                                                            <label className='d-inline me-2'>
                                                                                                                Current Value
                                                                                                            </label>
                                                                                                            <span className='e-amount d-block'>
                                                                                                                <span className='e-value-rupee'>₹ </span>{i.current_value.toLocaleString('en-IN')}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-3 e-assets-card'>
                                                                                                            <label className=' me-2'>
                                                                                                                Gain/Lose
                                                                                                            </label>
                                                                                                            <span className='e-amount d-block e-capitalized'>
                                                                                                                {i.return_percentage > 0 ? 'Gain' : 'Loss'}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            <div className='col-lg-12 col-md-12 col-12 e-allocation-detail-card pt-4 mt-2'>
                                                                                <div className="e-table-wrapper">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3 col-3 pt-1 px-0">
                                                                                            <h6 className='ms-4 ps-2'>Instrument</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-1 col-1 pt-1 text-center">
                                                                                            <h6 className="ps-3">
                                                                                                Quantity
                                                                                            </h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 text-center">
                                                                                            <h6 className="ps-3">
                                                                                                Avg. price
                                                                                            </h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>Price</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>Current value</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>P&L</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="e-table-content p-0">
                                                                                    {
                                                                                        assetData.data.map(function (item) {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="row mx-1 e-table-row px-3 my-2" >
                                                                                                        <div className="col-lg-3 col-3 d-flex align-items-center pt-2 mt-2 px-0 ">
                                                                                                            <p>{item.ticker}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-1 col-1 d-flex align-items-center pt-2 mt-2 px-3 justify-content-center">
                                                                                                            <p>{item.quantity}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 d-flex align-items-center pt-2 mt-2 px-3 justify-content-center">
                                                                                                            <p><span className='e-value-rupee'>₹ </span>{item.purchase_ltp}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 d-flex align-items-center pt-2 mt-2 e-amount">
                                                                                                            <p>  <span className='e-value-rupee'>₹ </span>{Number((item.invested_value).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 d-flex align-items-center pt-2 mt-2 pe-0 ps-4 e-amount">
                                                                                                            <p><span className='e-value-rupee'>₹ </span>{Number((item.current_value).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 d-flex align-items-center pt-2 mt-2 pe-0 ps-4 e-amount">
                                                                                                            <p> <span className='e-value-rupee'>₹ </span>{Number((item.returns).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })

                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        tabIndex === 1 && (
                                                            <div className='row mt-lg-3 mt-md-3 mt-sm-3 mt-3'>
                                                                <div className='col-lg-7 col-md-7 col-sm-12'>
                                                                    <div
                                                                        className='e-detail-card-wrapper'
                                                                    // style={{}}

                                                                    >
                                                                        <div className='e-allocation-detail-card position-relative p-4'>
                                                                            <div className='row'>
                                                                                <div className='col-lg-8 col-md-10 col-sm-12'>
                                                                                    <label>
                                                                                        Current Value
                                                                                    </label>
                                                                                    <h5 className='e-current-value '
                                                                                        onMouseEnter={() => {
                                                                                            setOpenTooltip(true)
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            // $(`#e-portfolio-value`).removeClass('e-hover-popup');
                                                                                            setOpenTooltip(false)
                                                                                        }}>
                                                                                        <span className='e-rupee'>₹ </span>
                                                                                        <span className='position-relative'>
                                                                                            {_getCurrentValue(assetData.totals.current_value)}
                                                                                            {
                                                                                                openTooltip && (
                                                                                                    <CustomToolTip data={Number((assetData.totals.current_value).toFixed(2)).toLocaleString('en-IN')} top="0px" symbol={true} />
                                                                                                )
                                                                                            }
                                                                                        </span>

                                                                                    </h5>
                                                                                    <p className='e-last-updated'>
                                                                                        {assetData.totals.last_updated_date}
                                                                                        {/* <img src={Refresh} className="ms-2" /> */}
                                                                                    </p>
                                                                                    <PrimaryIconBtn
                                                                                        name="Add more"
                                                                                        className="ps-0"
                                                                                        left={true}
                                                                                        image={AddIcon}
                                                                                        iconClass="me-2 e-link-icon"
                                                                                        handleClick={() => setOpenAssetList(true)}
                                                                                    />
                                                                                    <PrimaryIconBtn
                                                                                        name="Edit"
                                                                                        left={true}
                                                                                        image={EditIcon}
                                                                                        iconClass="me-2"
                                                                                        className='ms-2'
                                                                                        handleClick={() => {
                                                                                            scollToRef.current.scrollIntoView({ behavior: "smooth" })
                                                                                            setEditMode(true)

                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4  text-end'>
                                                                                    <div className='e-portfolio-indicator'>
                                                                                        <span
                                                                                            className='e-gainer'
                                                                                            style={{
                                                                                                color: assetData.totals.returns > 0 ?
                                                                                                    '#3CBC81'
                                                                                                    : assetData.totals.returns < 0 ?
                                                                                                        '#E74A3B'
                                                                                                        : '#010101'
                                                                                            }}
                                                                                        >
                                                                                            {assetData.totals.returns_percentage}
                                                                                        </span>
                                                                                        {
                                                                                            assetData.totals.returns !== 0 && (
                                                                                                <img
                                                                                                    src={
                                                                                                        assetData.totals.returns > 0 ?
                                                                                                            Gain
                                                                                                            :
                                                                                                            Loser
                                                                                                    }
                                                                                                    className="e-indicator ms-2"
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-lg-3 col-md-3 col-sm-6 col-6 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Invested
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'
                                                                                    >
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        <span className='position-relative e-card-value mb-0'>
                                                                                            {_getCurrentValue(assetData.totals.invested_amount)}

                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-3 col-md-3 col-sm-6 col-6 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Invested Since
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        {assetData.totals.first_investment}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-3 col-md-3 col-sm-6 col-6 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Returns
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        {assetData.totals.returns}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-3 col-md-3 col-sm-6 col-6 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Returns %
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        {assetData.totals.returns_percentage}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='row pe-lg-3 pe-md-3 pe-0' ref={scollToRef} style={{ scrollBehavior: "smooth" }}>

                                                                    <div className='col-lg-6 col-4 my-4'>
                                                                        {
                                                                            assetData && (
                                                                                <h6 className='e-subtitle'>{assetData.data.length > 1 ? `${assetData.data.length} Mutual Funds` : `${assetData.data.length} Mutual Fund`}</h6>
                                                                            )
                                                                        }

                                                                    </div>

                                                                    <div className='col-lg-6 col-8 text-end my-4'>

                                                                        {
                                                                            !editMode ?
                                                                                assetData && (
                                                                                    <span>
                                                                                        View options
                                                                                        <img
                                                                                            src={grid ? GridActive : Grid}
                                                                                            alt=""
                                                                                            className={grid ? 'e-active px-3' : 'e-inactive px-3'}
                                                                                            onClick={() => {
                                                                                                setGrid(true)
                                                                                            }} />
                                                                                        <img
                                                                                            src={grid ? ListActive : List}
                                                                                            className={!grid ? 'e-active' : 'e-inactive'}
                                                                                            alt=""
                                                                                            onClick={() => {
                                                                                                setGrid(false)
                                                                                            }} />
                                                                                    </span>
                                                                                )
                                                                                :
                                                                                <PrimaryLink
                                                                                    name={`Delete all ${selectedAsset.value === 1 || selectedAsset.value === 2 || selectedAsset.value === 5 ? `${selectedAsset.label}s` : selectedAsset.label}`}
                                                                                    left={true}
                                                                                    image={Delete}
                                                                                    className="e-delete"
                                                                                    iconClass="me-2"
                                                                                    handleClick={() => {
                                                                                        setDeleteAll(true);
                                                                                        setOpDeleteModal(true)
                                                                                    }} />
                                                                        }

                                                                    </div>

                                                                    {
                                                                        grid ?
                                                                            assetData.data.map((i, j) => {
                                                                                return (
                                                                                    <div className='col-lg-8 col-md-8 col-sm-12 mb-4 pe-lg-3 pe-md-3 pe-0' id={`fund-${j}`}>

                                                                                        <div className='e-allocation-card p-4 position-relative'>
                                                                                            {
                                                                                                j === editIndex && (
                                                                                                    <div className='e-more-menu' ref={moreMenuRef}>
                                                                                                        <div
                                                                                                            className='e-menu-item'
                                                                                                            onClick={() => {
                                                                                                                setEditId(i.id);
                                                                                                                setOpenUpdateModal(true);
                                                                                                                setEditIndex(null);
                                                                                                            }}
                                                                                                        >
                                                                                                            Edit
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className='e-menu-item'
                                                                                                            onClick={() => {
                                                                                                                setEditId(i.id);
                                                                                                                setOpDeleteModal(true);
                                                                                                                setEditIndex(null);
                                                                                                                console.log('click');
                                                                                                            }}
                                                                                                        >
                                                                                                            Delete
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            <div className='row'>
                                                                                                <div className='col-1'>
                                                                                                    <div className='e-icon-wrap'>
                                                                                                        {
                                                                                                            i.mf_name ?
                                                                                                                i.mf_name.charAt(0)
                                                                                                                :
                                                                                                                'S'
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-9 mb-2'>
                                                                                                    <h6 className='ms-lg-2 ms-md-2 ms-4 pt-1'>
                                                                                                        {i.mf_name}
                                                                                                    </h6>
                                                                                                </div>
                                                                                                <div className='col-2 ps-0 text-end'>
                                                                                                    {
                                                                                                        editMode === false ? (
                                                                                                            <>
                                                                                                                <span
                                                                                                                    className='e-gainer'
                                                                                                                    style={{
                                                                                                                        color: i.return_percentage > 0 ?
                                                                                                                            '#3CBC81'
                                                                                                                            : i.return_percentage < 0 ?
                                                                                                                                '#E74A3B'
                                                                                                                                : '#010101'
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {`${i.return_percentage}% `}
                                                                                                                </span>
                                                                                                                {
                                                                                                                    i.return_percentage !== 0 && (
                                                                                                                        <img
                                                                                                                            src={
                                                                                                                                i.return_percentage > 0 ?
                                                                                                                                    Gain
                                                                                                                                    :
                                                                                                                                    Loser
                                                                                                                            }
                                                                                                                            className="e-indicator ms-2"
                                                                                                                        />
                                                                                                                    )
                                                                                                                }
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <img
                                                                                                                src={CardEdit}
                                                                                                                // style={{ cursor: 'pointer' }}
                                                                                                                className="e-card-edit"
                                                                                                                onClick={() => {
                                                                                                                    setEditIndex(j)
                                                                                                                }}
                                                                                                            />
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                                <div className='row mt-2 e-assets-card'>
                                                                                                    <div className='col-lg-4 col-md-4 col-sm-12 '>
                                                                                                        <label className='mb-1'>
                                                                                                            Current Value
                                                                                                        </label>
                                                                                                        <span className='e-amount d-block'>
                                                                                                            <span className='e-value-rupee' >₹ </span>
                                                                                                            {Number((i.current_value).toFixed(2)).toLocaleString('en-IN')}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4 col-md-4 col-sm-12 my-lg-0 my-md-0 my-3'>
                                                                                                        <label className='mb-1'>
                                                                                                            Returns
                                                                                                        </label>
                                                                                                        <span className='e-amount d-block'>
                                                                                                            <span className='e-value-rupee' >₹ </span>
                                                                                                            {Number((i.returns).toFixed(2)).toLocaleString('en-IN')}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                                                                                        <label className='mb-1'>
                                                                                                            Invested Amount
                                                                                                        </label>
                                                                                                        <span className='e-amount d-block'>
                                                                                                            <span className='e-value-rupee' >₹ </span>
                                                                                                            {Number((i.invested_value).toFixed(2)).toLocaleString('en-IN')}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            <div className='col-lg-12 col-md-12 col-12 e-allocation-detail-card pt-4 mt-2'>
                                                                                <div className="e-table-wrapper">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3 col-3 pt-1 px-0">
                                                                                            <h6 className='ms-4 ps-2'>Instrument</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-3 col-3 pt-1 text-center">
                                                                                            <h6 className="ps-3">
                                                                                                Quantity
                                                                                            </h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>Price</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>Current value</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>P&L</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="e-table-content p-0">
                                                                                    {
                                                                                        assetData.data.map(function (item) {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="row mx-1 e-table-row px-3 my-2" >
                                                                                                        <div className="col-lg-3 col-3 pt-2 mt-2 px-0 ">
                                                                                                            <p>{item.mf_name}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-3 col-3 pt-2 mt-2 px-3 justify-content-center d-flex align-items-center">
                                                                                                            <p>{item.quantity.toFixed(2)}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 pt-2 mt-2  e-amount d-flex align-items-center">
                                                                                                            <p>  <span className='e-value-rupee'>₹ </span>{Number((item.invested_value).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 pt-2 mt-2 pe-0 ps-4  e-amount d-flex align-items-center">
                                                                                                            <p><span className='e-value-rupee'>₹ </span>{Number((item.current_value).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 pt-2 mt-2 pe-0 ps-4  e-amount d-flex align-items-center">
                                                                                                            <p> <span className='e-value-rupee'>₹ </span>{Number((item.returns).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })

                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        )
                                                    }


                                                    {
                                                        tabIndex === 3 && (
                                                            <div className='row mt-lg-3 mt-md-3 mt-sm-3 mt-3'>
                                                                <div className='col-lg-5 col-md-7 col-sm-12'>
                                                                    <div
                                                                        className='e-detail-card-wrapper'
                                                                    // style={{ height: editMode === true ? '0' : window.innerWidth > 765 ? '240px' : 'max-content', overflow: editMode === true && 'hidden' }}
                                                                    >
                                                                        <div className='e-allocation-detail-card position-relative p-4'>
                                                                            <div className='row'>
                                                                                <div className='col-lg-8 col-md-8 col-sm-12'>
                                                                                    <label>
                                                                                        Current Value
                                                                                    </label>
                                                                                    <h5 className='e-current-value '
                                                                                        onMouseEnter={() => {
                                                                                            setOpenTooltip(true)
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            // $(`#e - portfolio - value`).removeClass('e-hover-popup');
                                                                                            setOpenTooltip(false)
                                                                                        }}>
                                                                                        <span className='e-rupee'>₹ </span>
                                                                                        <span className='position-relative'>
                                                                                            {_getCurrentValue(assetData.totals.current_value)}
                                                                                            {
                                                                                                openTooltip && (
                                                                                                    <CustomToolTip data={Number((assetData.totals.current_value).toFixed(2)).toLocaleString('en-IN')} top="0px" symbol={true} />
                                                                                                )
                                                                                            }
                                                                                        </span>

                                                                                    </h5>
                                                                                    <p className='e-last-updated'>
                                                                                        {assetData.totals.last_updated_date}
                                                                                    </p>
                                                                                    <PrimaryIconBtn
                                                                                        name="Add more"
                                                                                        className="ps-0"
                                                                                        left={true}
                                                                                        image={AddIcon}
                                                                                        iconClass="me-2 e-link-icon"
                                                                                        handleClick={() => setOpenAssetList(true)}
                                                                                    />
                                                                                    <PrimaryIconBtn
                                                                                        name="Edit"
                                                                                        left={true}
                                                                                        image={EditIcon}
                                                                                        iconClass="me-2"
                                                                                        className='ms-2'
                                                                                        handleClick={() => {
                                                                                            scollToRef.current.scrollIntoView({ behavior: "smooth" })
                                                                                            setEditMode(true)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-12 text-end'>
                                                                                    <div className='e-portfolio-indicator'>
                                                                                        <span className='e-gainer'
                                                                                            style={{
                                                                                                color: assetData.totals.returns > 0 ?
                                                                                                    '#3CBC81'
                                                                                                    : assetData.totals.returns < 0 ?
                                                                                                        '#E74A3B'
                                                                                                        : '#010101'
                                                                                            }}
                                                                                        >
                                                                                            {assetData.totals.returns_percentage}
                                                                                        </span>
                                                                                        {
                                                                                            assetData.totals.returns !== 0 && (
                                                                                                <img
                                                                                                    src={
                                                                                                        assetData.totals.returns > 0 ?
                                                                                                            Gain
                                                                                                            :
                                                                                                            Loser
                                                                                                    }
                                                                                                    className="e-indicator ms-2"
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Invested
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        {_getCurrentValue(assetData.totals.invested_amount)}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Invested Since
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>

                                                                                        {assetData.totals.first_investment}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Returns
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        {assetData.totals.returns}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row' ref={scollToRef}>
                                                                    <div className='col-lg-6 col-4 my-4'>
                                                                        {
                                                                            assetData && (
                                                                                <h6 className='e-subtitle'>{`${assetData.data.length} Real Estate`}</h6>
                                                                            )
                                                                        }

                                                                    </div>
                                                                    <div className='col-lg-6 col-8 text-end my-4'>

                                                                        {
                                                                            !editMode ?
                                                                                assetData && (
                                                                                    <span>
                                                                                        View options
                                                                                        <img
                                                                                            src={grid ? GridActive : Grid}
                                                                                            alt=""
                                                                                            className={grid ? 'e-active px-3' : 'e-inactive px-3'}
                                                                                            onClick={() => {
                                                                                                setGrid(true)
                                                                                            }} />
                                                                                        <img
                                                                                            src={grid ? ListActive : List}
                                                                                            className={!grid ? 'e-active' : 'e-inactive'}
                                                                                            alt=""
                                                                                            onClick={() => {
                                                                                                setGrid(false)
                                                                                            }} />
                                                                                    </span>
                                                                                )
                                                                                :
                                                                                <PrimaryLink
                                                                                    name={`Delete all ${selectedAsset.value === 1 || selectedAsset.value === 2 || selectedAsset.value === 5 ? `${selectedAsset.label}s` : selectedAsset.label}`}
                                                                                    left={true}
                                                                                    image={Delete}
                                                                                    className="e-delete"
                                                                                    iconClass="me-2"
                                                                                    handleClick={() => {
                                                                                        setDeleteAll(true);
                                                                                        setOpDeleteModal(true)
                                                                                    }} />
                                                                        }

                                                                    </div>

                                                                </div>
                                                                {
                                                                    grid ?
                                                                        assetData.data.map((i, j) => {
                                                                            return (
                                                                                <div className='col-lg-8 col-md-8 col-sm-12 mb-4 pe-lg-3 pe-md-3 pe-0'>
                                                                                    <div className='e-allocation-card p-4 position-relative'>
                                                                                        {
                                                                                            j === editIndex && (
                                                                                                <div className='e-more-menu' ref={moreMenuRef}>
                                                                                                    <div
                                                                                                        className='e-menu-item'
                                                                                                        onClick={() => {
                                                                                                            setEditId(i.id);
                                                                                                            setOpenUpdateModal(true);
                                                                                                            setEditIndex(null);
                                                                                                        }}
                                                                                                    >
                                                                                                        Edit
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className='e-menu-item'
                                                                                                        onClick={() => {
                                                                                                            setEditId(i.id);
                                                                                                            setOpDeleteModal(true);
                                                                                                            setEditIndex(null);
                                                                                                            console.log('click');
                                                                                                        }}
                                                                                                    >
                                                                                                        Delete
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <div className='row e-assets-cards'>
                                                                                            <div className='col-8 mb-2'>
                                                                                                <h6>
                                                                                                    {i.property_type_id == 13 ? 'Residential Property' : 'Commercial Property'}
                                                                                                </h6>
                                                                                                {/* <span className='e-type-badge'> 
                                                                            {i.property_type_id == 13 ? 'Residential Property' : 'Commercial Property'}
                                                                        </span> */}
                                                                                            </div>
                                                                                            <div className='col-4 ps-0 text-end'>
                                                                                                {
                                                                                                    editMode === false ? (
                                                                                                        <>
                                                                                                            <span
                                                                                                                className='e-gainer'
                                                                                                                style={{
                                                                                                                    color: i.return_percentage > 0 ?
                                                                                                                        '#3CBC81'
                                                                                                                        : i.return_percentage < 0 ?
                                                                                                                            '#E74A3B'
                                                                                                                            : '#010101'
                                                                                                                }}
                                                                                                            >
                                                                                                                {`${i.return_percentage}% `}
                                                                                                            </span>
                                                                                                            {
                                                                                                                i.return_percentage !== 0 && (
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            i.return_percentage > 0 ?
                                                                                                                                Gain
                                                                                                                                :
                                                                                                                                Loser
                                                                                                                        }
                                                                                                                        className="e-indicator ms-2"
                                                                                                                    />
                                                                                                                )
                                                                                                            }
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <img
                                                                                                            src={CardEdit}
                                                                                                            // style={{ cursor: 'pointer' }}
                                                                                                            className="e-card-edit"
                                                                                                            onClick={() => setEditIndex(j)}
                                                                                                        />
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                            <div className='col-lg-3 col-md-4 col-sm-12 mt-3 e-assets-card'>
                                                                                                <label className=' me-2'>
                                                                                                    Invested
                                                                                                </label>
                                                                                                <span className='e-amount d-block mt-2 '>
                                                                                                    <span className='e-rupee'>₹ </span>{i.invested_value ? Number((i.invested_value).toFixed(2)).toLocaleString('en-IN') : '-'}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='col-lg-3 col-md-4 col-sm-12 mt-3 e-assets-card'>
                                                                                                <label>Current Value</label>
                                                                                                <span className="d-block e-amount mt-2"> <span className='e-rupee'>₹ </span>{i.current_value ? Number((i.current_value).toFixed(2)).toLocaleString('en-IN') : '-'}</span>
                                                                                            </div>
                                                                                            {/* <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                                                        <label>Last evaluated date</label>
                                                                        <span className="d-block mt-2">{i.last_evaluation_date}</span>
                                                                    </div> */}
                                                                                            <div className='col-lg-3 col-md-4 col-sm-12 mt-3 e-assets-card'>
                                                                                                <label>Invested Since</label>
                                                                                                <span className='d-block mt-2 e-amount'>{i.purchase_date}</span>
                                                                                            </div>

                                                                                            {/* <div className='col-lg-3 col-md-3 col-sm-12 mt-3'>
                                                                        <label>Location</label>
                                                                        <span className="d-block mt-2">{'-'}</span>
                                                                    </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <div className='col-lg-12 col-md-12 col-12 e-allocation-detail-card pt-4 mt-2'>
                                                                            <div className="e-table-wrapper">
                                                                                <div className="row">
                                                                                    <div className="col-lg-3 col-3 pt-1 px-0">
                                                                                        <h6 className='ms-4 ps-2'>Instrument</h6>
                                                                                    </div>
                                                                                    {/* <div className="col-lg-3 col-3 pt-1 text-center">
                                                                                        <h6 className="ps-3">
                                                                                            Quantity
                                                                                        </h6>
                                                                                    </div> */}
                                                                                    <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                        <h6 className='px-0'>Price</h6>
                                                                                    </div>
                                                                                    <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                        <h6 className='px-0'>Current value</h6>
                                                                                    </div>
                                                                                    <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                        <h6 className='px-0'>P&L</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="e-table-content p-0">
                                                                                {
                                                                                    assetData.data.map(function (item) {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="row mx-1 e-table-row px-3 my-2" >
                                                                                                    <div className="col-lg-3 col-3 pt-2 d-flex align-items-center mt-2 px-0 ">
                                                                                                        <p>{item.property_type_id == 13 ? 'Residential Property' : 'Commercial Property'}</p>
                                                                                                    </div>
                                                                                                    {/* <div className="col-lg-3 col-3 pt-2 d-flex align-items-center mt-2 px-3 text-center">
                                                                                                        <p>{item.quantity}</p>
                                                                                                    </div> */}
                                                                                                    <div className="col-lg-2 col-2 pt-2 d-flex align-items-center mt-2 e-amount">
                                                                                                        <p>  <span className='e-value-rupee'>₹ </span>{Number((item.invested_value).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                    </div>
                                                                                                    <div className="col-lg-2 col-2 pt-2 d-flex align-items-center mt-2 pe-0 ps-4 e-amount">
                                                                                                        <p><span className='e-value-rupee'>₹ </span>{Number((item.current_value).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                    </div>
                                                                                                    <div className="col-lg-2 col-2 pt-2 d-flex align-items-center mt-2 pe-0 ps-3 e-amount">
                                                                                                        <p> <span className='e-value-rupee'>₹ </span>{Number((item.returns).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })

                                                                                }


                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        tabIndex === 4 && (
                                                            <div className='row mt-lg-3 mt-md-3 mt-sm-3 mt-3'>
                                                                <div className='col-lg-6 col-md-7 col-sm-12'>
                                                                    <div
                                                                        className='e-detail-card-wrapper'
                                                                    // style={{ height: editMode === true ? '0' : window.innerWidth > 992 ? '240px' : 'max-content', overflow: editMode === true && 'hidden' }}
                                                                    >
                                                                        <div className='e-allocation-detail-card position-relative p-4'>
                                                                            <div className='row'>
                                                                                <div className='col-lg-8 col-md-8 col-sm-12'>
                                                                                    <label>
                                                                                        Current Value
                                                                                    </label>
                                                                                    <h5 className='e-current-value '
                                                                                        onMouseEnter={() => {
                                                                                            setOpenTooltip(true)
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            // $(`#e - portfolio - value`).removeClass('e-hover-popup');
                                                                                            setOpenTooltip(false)
                                                                                        }}>
                                                                                        <span className='e-rupee'>₹ </span>
                                                                                        <span className='position-relative'>
                                                                                            {_getCurrentValue(assetData.totals.current_value)}
                                                                                            {
                                                                                                openTooltip && (
                                                                                                    <CustomToolTip data={Number((assetData.totals.current_value).toFixed(2)).toLocaleString('en-IN')} top="0px" symbol={true} />
                                                                                                )
                                                                                            }
                                                                                        </span>

                                                                                    </h5>
                                                                                    <p className='e-last-updated'>
                                                                                        {assetData.totals.last_updated_date}
                                                                                        {/* <img src={Refresh} className="ms-2" /> */}
                                                                                    </p>
                                                                                    <PrimaryIconBtn
                                                                                        name="Add more"
                                                                                        className="ps-0"
                                                                                        left={true}
                                                                                        image={AddIcon}
                                                                                        iconClass="me-2 e-link-icon"
                                                                                        handleClick={() => setOpenAssetList(true)}
                                                                                    />
                                                                                    <PrimaryIconBtn
                                                                                        name="Edit"
                                                                                        left={true}
                                                                                        image={EditIcon}
                                                                                        iconClass="me-2"
                                                                                        className='ms-2'
                                                                                        handleClick={() => {
                                                                                            scollToRef.current.scrollIntoView({ behavior: "smooth" })
                                                                                            setEditMode(true)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-12 text-end'>
                                                                                    <div className='e-portfolio-indicator'>
                                                                                        <span
                                                                                            className='e-gainer'
                                                                                            style={{
                                                                                                color: assetData.totals.returns > 0 ?
                                                                                                    '#3CBC81'
                                                                                                    : assetData.totals.returns < 0 ?
                                                                                                        '#E74A3B'
                                                                                                        : '#010101'
                                                                                            }}
                                                                                        >
                                                                                            {assetData.totals.returns_percentage}
                                                                                        </span>
                                                                                        {
                                                                                            assetData.totals.returns !== 0 && (
                                                                                                <img
                                                                                                    src={
                                                                                                        assetData.totals.returns > 0 ?
                                                                                                            Gain
                                                                                                            :
                                                                                                            Loser
                                                                                                    }
                                                                                                    className="e-indicator ms-2"
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Invested
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        {_getCurrentValue(assetData.totals.invested_amount)}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Current Return
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        {(assetData.totals.returns)}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Gain/Loss
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        {
                                                                                            assetData.totals.returns > 0 ?
                                                                                                "Gain"

                                                                                                : assetData.totals.returns < 0 ?
                                                                                                    "Lose"
                                                                                                    :
                                                                                                    "-"
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row pe-lg-3 pe-md-3 pe-0' ref={scollToRef}>
                                                                    <div className='col-lg-6 col-4 my-4'>
                                                                        {
                                                                            assetData && (
                                                                                <h6 className='e-subtitle'>{assetData.data.length > 1 ? `${assetData.data.length} Golds` : `${assetData.data.length} Gold`}</h6>
                                                                            )
                                                                        }

                                                                    </div>
                                                                    <div className='col-lg-6 col-8 text-end my-4'>

                                                                        {
                                                                            !editMode ?
                                                                                assetData && (
                                                                                    <span>
                                                                                        View options
                                                                                        <img
                                                                                            src={grid ? GridActive : Grid}
                                                                                            alt=""
                                                                                            className={grid ? 'e-active px-3' : 'e-inactive px-3'}
                                                                                            onClick={() => {
                                                                                                setGrid(true)
                                                                                            }} />
                                                                                        <img
                                                                                            src={grid ? ListActive : List}
                                                                                            className={!grid ? 'e-active' : 'e-inactive'}
                                                                                            alt=""
                                                                                            onClick={() => {
                                                                                                setGrid(false)
                                                                                            }} />
                                                                                    </span>
                                                                                )
                                                                                :
                                                                                <PrimaryLink
                                                                                    name={`Delete all ${selectedAsset.value === 1 || selectedAsset.value === 2 || selectedAsset.value === 5 ? `${selectedAsset.label}s` : selectedAsset.label}`}
                                                                                    left={true}
                                                                                    image={Delete}
                                                                                    className="e-delete"
                                                                                    iconClass="me-2"
                                                                                    handleClick={() => {
                                                                                        setDeleteAll(true);
                                                                                        setOpDeleteModal(true)
                                                                                    }} />
                                                                        }

                                                                    </div>

                                                                    {
                                                                        grid ?
                                                                            assetData.data.map((i, j) => {
                                                                                return (
                                                                                    <div className='col-lg-8 col-md-8 col-sm-12 mb-4 pe-lg-3 pe-md-3 pe-0'>
                                                                                        <div className='e-allocation-card p-4 position-relative'>
                                                                                            {
                                                                                                j === editIndex && (
                                                                                                    <div className='e-more-menu' ref={moreMenuRef}>
                                                                                                        <div
                                                                                                            className='e-menu-item'
                                                                                                            onClick={() => {
                                                                                                                setEditId(i.id);
                                                                                                                setOpenUpdateModal(true);
                                                                                                                setEditIndex(null);
                                                                                                            }}
                                                                                                        >
                                                                                                            Edit
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className='e-menu-item'
                                                                                                            onClick={() => {
                                                                                                                setEditId(i.id);
                                                                                                                setOpDeleteModal(true);
                                                                                                                setEditIndex(null);
                                                                                                                console.log('click');
                                                                                                            }}
                                                                                                        >
                                                                                                            Delete
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            <div className='row'>
                                                                                                <div className='col-1'>
                                                                                                    <div className='e-icon-wrap'>
                                                                                                        G
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-9 ps-3 pt-1 e-assets-card'>
                                                                                                    <h6 className='d-inline me-2 ps-1'>
                                                                                                        Gold in grams
                                                                                                    </h6>
                                                                                                    <span className='e-amount d-block mt-1 ps-1'>
                                                                                                        {i.gold_in_grams ? i.gold_in_grams : '-'}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className='col-2 ps-0 text-end'>
                                                                                                    {
                                                                                                        editMode === false ? (
                                                                                                            <>
                                                                                                                <span
                                                                                                                    className='e-gainer'
                                                                                                                    style={{
                                                                                                                        color: i.return_percentage > 0 ?
                                                                                                                            '#3CBC81'
                                                                                                                            : i.return_percentage < 0 ?
                                                                                                                                '#E74A3B'
                                                                                                                                : '#010101'
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {`${i.return_percentage}% `}
                                                                                                                </span>
                                                                                                                {
                                                                                                                    i.return_percentage !== 0 && (
                                                                                                                        <img
                                                                                                                            src={
                                                                                                                                i.return_percentage > 0 ?
                                                                                                                                    Gain
                                                                                                                                    :
                                                                                                                                    Loser
                                                                                                                            }
                                                                                                                            className="e-indicator ms-2"
                                                                                                                        />
                                                                                                                    )
                                                                                                                }
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <img
                                                                                                                src={CardEdit}
                                                                                                                className="e-card-edit"
                                                                                                                onClick={() => setEditIndex(j)}
                                                                                                            />
                                                                                                        )
                                                                                                    }
                                                                                                </div>

                                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-3 e-assets-card'>
                                                                                                    <label className='d-inline me-2'>
                                                                                                        Gold price
                                                                                                    </label>
                                                                                                    <span className='e-amount d-block mt-1'>
                                                                                                        {i.gold_price ? i.gold_price : '-'}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-3 e-assets-card'>
                                                                                                    <label className='d-inline me-2'>
                                                                                                        Invested value
                                                                                                    </label>
                                                                                                    <span className='e-amount d-block mt-1'>
                                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                                        {i.invested_value ? i.invested_value.toLocaleString('en-IN') : '-'}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-3 e-assets-card'>
                                                                                                    <label>Current value</label>
                                                                                                    <span className="d-block e-amount mt-1">
                                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                                        {i.current_value.toLocaleString('en-IN')}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-2 e-assets-card mt-3'>
                                                                                                    <label>Purchase date</label>
                                                                                                    <span className="e-amount d-block mt-1">{i.purchase_date}</span>
                                                                                                </div>
                                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-2 e-assets-card mt-3'>
                                                                                                    <label>Returns</label>
                                                                                                    <span className="e-amount d-block mt-1"> <span className='e-value-rupee'>₹ </span>  {i.returns.toLocaleString('en-IN')}</span>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            <div className='col-lg-12 col-md-12 col-12 e-allocation-detail-card pt-4 mt-2'>
                                                                                <div className="e-table-wrapper">
                                                                                    <div className="row">
                                                                                        {/* <div className="col-lg-3 col-3 pt-1 px-0">
                                                                                            <h6 className='ms-4 ps-2'>Instrument</h6>
                                                                                        </div> */}
                                                                                        <div className="col-lg-3 col-3 pt-1">
                                                                                            <h6 className="ps-3">
                                                                                                Gold in grams
                                                                                            </h6>
                                                                                        </div>
                                                                                        <div className="col-lg-3 col-3 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>Gold price</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>Invested value</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>Current value</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>P&L</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="e-table-content p-0">
                                                                                    {
                                                                                        assetData.data.map(function (item) {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="row mx-1 e-table-row px-3 my-2" >
                                                                                                        <div className="col-lg-3 col-3 pt-3 d-flex align-items-center mt-2 px-0 ">
                                                                                                            <p>{item.gold_in_grams}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-3 col-3 pt-3 d-flex align-items-center mt-2 px-3 text-center e-amount">
                                                                                                            <p> <span className='e-value-rupee'>₹ </span>{Number((item.gold_price).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 pt-3 d-flex align-items-center mt-2 e-amount">
                                                                                                            <p>  <span className='e-value-rupee'>₹ </span>{Number((item.invested_value).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 pt-3 d-flex align-items-center mt-2 pe-0 ps-4  e-amount">
                                                                                                            <p><span className='e-value-rupee'>₹ </span>{Number((item.current_value).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 pt-3 d-flex align-items-center mt-2 pe-0 ps-4  e-amount">
                                                                                                            <p> <span className='e-value-rupee'>₹ </span>{Number((item.returns).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })

                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        tabIndex === 5 && (
                                                            <div className='row mt-lg-3 mt-md-3 mt-sm-3 mt-3'>
                                                                <div className='col-lg-5 col-md-6 col-sm-12'>
                                                                    <div
                                                                        className='e-detail-card-wrapper'
                                                                    // style={{ height: editMode === true ? '0' : window.innerWidth > 992 ? '240px' : 'max-content', overflow: editMode === true && 'hidden' }}
                                                                    >
                                                                        <div className='e-allocation-detail-card position-relative p-4'>
                                                                            <div className='row'>
                                                                                <div className='col-lg-8 col-md-8 col-sm-12'>
                                                                                    <label>
                                                                                        Current Value
                                                                                    </label>
                                                                                    <h5 className='e-current-value '
                                                                                        onMouseEnter={() => {
                                                                                            setOpenTooltip(true)
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            // $(`#e - portfolio - value`).removeClass('e-hover-popup');
                                                                                            setOpenTooltip(false)
                                                                                        }}>
                                                                                        <span className='e-rupee'>₹ </span>
                                                                                        <span className='position-relative'>
                                                                                            {_getCurrentValue(assetData.totals.current_value)}
                                                                                            {
                                                                                                openTooltip && (
                                                                                                    <CustomToolTip data={Number((assetData.totals.current_value).toFixed(2)).toLocaleString('en-IN')} top="0px" symbol={true} />
                                                                                                )
                                                                                            }
                                                                                        </span>

                                                                                    </h5>
                                                                                    <p className='e-last-updated'>
                                                                                        {assetData.totals.last_updated_date}
                                                                                        {/* <img src={Refresh} className="ms-2" /> */}
                                                                                    </p>
                                                                                    <PrimaryIconBtn
                                                                                        name="Add more"
                                                                                        className="ps-0"
                                                                                        left={true}
                                                                                        image={AddIcon}
                                                                                        iconClass="me-2 e-link-icon"
                                                                                        handleClick={() => setOpenAssetList(true)}
                                                                                    />
                                                                                    <PrimaryIconBtn
                                                                                        name="Edit"
                                                                                        left={true}
                                                                                        image={EditIcon}
                                                                                        iconClass="me-2"
                                                                                        className='ms-2'
                                                                                        handleClick={() => {
                                                                                            scollToRef.current.scrollIntoView({ behavior: "smooth" })
                                                                                            setEditMode(true)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-4 col-sm-12 text-end'>
                                                                                    <div className='e-portfolio-indicator'>
                                                                                        <span
                                                                                            className='e-gainer'
                                                                                            style={{
                                                                                                color: assetData.totals.returns > 0 ?
                                                                                                    '#3CBC81'
                                                                                                    : assetData.totals.returns < 0 ?
                                                                                                        '#E74A3B'
                                                                                                        : '#010101'
                                                                                            }}
                                                                                        >
                                                                                            {assetData.totals.returns_percentage}
                                                                                        </span>
                                                                                        {
                                                                                            assetData.totals.returns !== 0 && (
                                                                                                <img
                                                                                                    src={
                                                                                                        assetData.totals.returns > 0 ?
                                                                                                            Gain
                                                                                                            :
                                                                                                            Loser
                                                                                                    }
                                                                                                    className="e-indicator ms-2"

                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Invested
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        {_getCurrentValue(assetData.totals.invested_amount)}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Current Return
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                        {assetData.totals.returns}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-3'>
                                                                                    <label className='mb-1'>
                                                                                        Gain/Loss
                                                                                    </label>
                                                                                    <p className='e-card-value mb-0'>
                                                                                        {
                                                                                            assetData.totals.returns > 0 ?
                                                                                                "Gain"
                                                                                                : assetData.totals.returns < 0 ?
                                                                                                    "Lose"
                                                                                                    :
                                                                                                    '-'
                                                                                        }

                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row pe-lg-3 pe-md-3 pe-0' ref={scollToRef}>
                                                                    <div className='col-lg-6 col-4 my-4'>
                                                                        {
                                                                            assetData && (
                                                                                <h6 className='e-subtitle'>{assetData.data.length > 1 ? `${assetData.data.length} Fixed Deposits` : `${assetData.data.length} Fixed Deposit`}</h6>
                                                                            )
                                                                        }

                                                                    </div>
                                                                    <div className='col-lg-6 col-8 text-end my-4'>

                                                                        {
                                                                            !editMode ?
                                                                                assetData && (
                                                                                    <span>
                                                                                        View options
                                                                                        <img
                                                                                            src={grid ? GridActive : Grid}
                                                                                            alt=""
                                                                                            className={grid ? 'e-active px-3' : 'e-inactive px-3'}
                                                                                            onClick={() => {
                                                                                                setGrid(true)
                                                                                            }} />
                                                                                        <img
                                                                                            src={grid ? ListActive : List}
                                                                                            className={!grid ? 'e-active' : 'e-inactive'}
                                                                                            alt=""
                                                                                            onClick={() => {
                                                                                                setGrid(false)
                                                                                            }} />
                                                                                    </span>
                                                                                )
                                                                                :
                                                                                <PrimaryLink
                                                                                    name={`Delete all ${selectedAsset.value === 1 || selectedAsset.value === 2 || selectedAsset.value === 5 ? `${selectedAsset.label}s` : selectedAsset.label}`}
                                                                                    left={true}
                                                                                    image={Delete}
                                                                                    className="e-delete"
                                                                                    iconClass="me-2"
                                                                                    handleClick={() => {
                                                                                        setDeleteAll(true);
                                                                                        setOpDeleteModal(true)
                                                                                    }} />
                                                                        }

                                                                    </div>

                                                                    {
                                                                        grid ?
                                                                            assetData.data.map((i, j) => {
                                                                                return (
                                                                                    <div className='col-lg-6 col-md-6 col-sm-12 mb-4 pe-lg-3 pe-md-3 pe-0'>
                                                                                        <div className='e-allocation-card p-4 position-relative'>
                                                                                            {
                                                                                                j === editIndex && (
                                                                                                    <div className='e-more-menu' ref={moreMenuRef}>
                                                                                                        <div
                                                                                                            className='e-menu-item'
                                                                                                            onClick={() => {
                                                                                                                setEditId(i.id);
                                                                                                                setOpenUpdateModal(true);
                                                                                                                setEditIndex(null);
                                                                                                            }}
                                                                                                        >
                                                                                                            Edit
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className='e-menu-item'
                                                                                                            onClick={() => {
                                                                                                                setEditId(i.id);
                                                                                                                setOpDeleteModal(true);
                                                                                                                setEditIndex(null);
                                                                                                                console.log('click');
                                                                                                            }}
                                                                                                        >
                                                                                                            Delete
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            <div className='row'>
                                                                                                <div className='col-1'>
                                                                                                    <div className='e-icon-wrap'>
                                                                                                        {
                                                                                                            i.organization_name ?
                                                                                                                i.organization_name.charAt(0)
                                                                                                                :
                                                                                                                'F'
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-11 pt-1 ps-4 mb-2 position-relative'>
                                                                                                    <h6 className='e-capatalize mb-1 ps-3'>
                                                                                                        {i.organization_name}
                                                                                                    </h6>
                                                                                                    <span className='e-amount mt-3 mt-lg-0 mt-md-0 mt-sm-0 ps-3'>
                                                                                                        <span className='e-price'>Type </span>
                                                                                                        <span className='e-price-amount e-capatalize' >{i.fd_type_name}</span>
                                                                                                    </span>
                                                                                                    <span className='d-lg-inline d-md-inline d-sm-inline d-block mt-2 mt-lg-0 mt-md-0 mt-sm-0'>
                                                                                                        <span className='e-price ms-lg-5 ms-md-5 ms-sm-3 ms-0'>Type </span>
                                                                                                        <span className='e-price-amount e-capatalize' >{i.organization_type_name}</span>
                                                                                                    </span>
                                                                                                    {
                                                                                                        editMode === true && (
                                                                                                            <img
                                                                                                                src={CardEdit}
                                                                                                                className="e-deposit-edit"
                                                                                                                onClick={() => setEditIndex(j)}
                                                                                                            />
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                <div className='col-lg-4 col-md-8 col-sm-6 col-12 mt-3'>
                                                                                                    <label className='d-inline me-2'>
                                                                                                        Invested Amount
                                                                                                    </label>
                                                                                                    <span className='e-amount d-block mt-1'>
                                                                                                        <span className='e-value-rupee'>₹ </span>
                                                                                                        {i.invested_amount ? Number((i.invested_amount).toFixed(2)).toLocaleString('en-IN') : '-'}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-3'>
                                                                                                    <label>Tenure</label>
                                                                                                    <span className="d-block mt-1">{i.tenure}</span>
                                                                                                </div>
                                                                                                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-3'>
                                                                                                    <label>Rate of interest</label>
                                                                                                    <span className='d-block mt-1'>{i.rate_of_interest}%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            <div className='col-lg-12 col-md-12 col-12 e-allocation-detail-card pt-4 mt-2'>
                                                                                <div className="e-table-wrapper">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3 col-3 pt-1 px-0">
                                                                                            <h6 className='ms-4 ps-2'>Organization name</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-3 col-3 pt-1 text-center">
                                                                                            <h6 className="ps-3">
                                                                                                FD type
                                                                                            </h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>Invested amount</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>Tenure</h6>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                                            <h6 className='px-0'>ROI</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="e-table-content p-0">
                                                                                    {
                                                                                        assetData.data.map(function (item) {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="row mx-1 e-table-row px-3 my-2" >
                                                                                                        <div className="col-lg-3 col-3 pt-4 mt-2 px-0 ">
                                                                                                            <p>{item.organization_name}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-3 col-3 pt-4 mt-2 px-3 text-center">
                                                                                                            <p>{item.fd_type_name}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 pt-4 mt-2 e-amount">
                                                                                                            <p>  <span className='e-value-rupee'>₹ </span>{Number((item.invested_amount).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 pt-4 mt-2 pe-0 ps-4  e-amount">
                                                                                                            <p>{item.tenure}</p>
                                                                                                        </div>
                                                                                                        <div className="col-lg-2 col-2 pt-4 mt-2 pe-0 ps-4 e-amount">
                                                                                                            <p> {item.rate_of_interest}%</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })

                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        tabIndex === 20 && (
                                                            <div className='row'>
                                                                <div className='co-12  text-center'>
                                                                    <Nodata
                                                                        title="Coming soon"
                                                                        note="  "
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        tabIndex === 21 && (
                                                            <div className='row'>
                                                                <div className='co-12  text-center'>
                                                                    <Nodata
                                                                        title="Coming soon"
                                                                        note="  "
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>

                                    }

                                </>
                            </div>
                        </>
                    )
            }
            {
                openAssetList && (
                    <AssetList
                        modalClose={() => {
                            setOpenAssetList(false);
                            // setSuccessModal(true)
                        }}
                        smallCase={() => {

                        }}
                        selected={selectedAsset}
                        success={(asset) => { setSelectedAsset(asset); setSuccessModal(true); _getAssets(asset.value); }}
                    />
                )
            }
            {
                openUpdateModal && (
                    <UpdateAssets
                        type={2}
                        modalClose={() => {
                            setOpenUpdateModal(false)
                        }}
                        selected={selectedAsset}
                        dataId={editId}
                        success={(asset) => {
                            setEditId(null);
                            _getAssets(asset.value);
                            setSelectedAsset(asset);
                            setToast(`Successfully updated`);
                            setEditMode(false)
                        }}
                    />
                )
            }

            {
                openDeleteModal && (
                    <AssetDeleteConfirmation
                        modalClose={() => setOpDeleteModal(false)}
                        selected={selectedAsset}
                        id={editId}
                        delete={deleteAll}
                        success={(asset) => {
                            setEditId(null);
                            _getAssets(asset.value);
                            setSelectedAsset(asset);
                            setToast('Successfully Deleted')
                        }}
                    />
                )
            }

            {
                successModal && (
                    <SuccessPayment
                        editScore={false}
                        heading={"Add assets"}//type
                        status={true}//status
                        subTitle={`Your ${selectedAsset.label} had been added to Portfolio Tracker`}
                        content={`You have added your ${selectedAsset.label} investment.We will track your investment
                    from now on.`}
                        from="portfolio-tracker"
                        close={() => {
                            setSuccessModal(false);
                            setEditMode(false)
                            // if (!status) {
                            // 	window.location.href = "/orders"
                            // }
                        }}
                    />
                )

            }

            {
                toast && (
                    <SuccessToast data={toast} />
                )
            }
        </>
    )
}

export default Allocation;