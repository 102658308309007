import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export default function SkeletonLoader() {
    return (
        <div className="row">
            <div className="col-lg-12 px-5 mt-4">
                <div className='item-info'>
                    <Skeleton width={380} height={15} />
                    <Skeleton width={380} height={8} className="mt-2" />
                </div>
                <div className="row  mt-4">
                    <div className="col-lg-3 ">
                        <div className="d-flex " style={{ justifyContent: "space-between" }}>
                            <Skeleton width={22} height={22} circle={true} />
                            <Skeleton width={120} height={8} />
                        </div>
                        <Skeleton width={120} height={20} className="mt-2" />
                        <Skeleton width={250} height={8} className="mt-3" />
                        <Skeleton width="100%" height={8} />

                    </div>
                    <div className="col-lg-3 ">
                        <div className="d-flex " style={{ justifyContent: "space-between" }}>
                            <Skeleton width={22} height={22} circle={true} />
                            <Skeleton width={120} height={8} />
                        </div>
                        <Skeleton width={120} height={20} className="mt-2" />
                        <Skeleton width={250} height={8} className="mt-3" />
                        <Skeleton width="100%" height={8} />

                    </div>
                    <div className="col-lg-3 ">
                        <div className="d-flex " style={{ justifyContent: "space-between" }}>
                            <Skeleton width={22} height={22} circle={true} />
                            <Skeleton width={120} height={8} />
                        </div>
                        <Skeleton width={120} height={20} className="mt-2" />
                        <Skeleton width={250} height={8} className="mt-3" />
                        <Skeleton width="100%" height={8} />

                    </div>
                    <div className="col-lg-3 ">
                        <div className="d-flex " style={{ justifyContent: "space-between" }}>
                            <Skeleton width={22} height={22} circle={true} />
                            <Skeleton width={120} height={8} />
                        </div>
                        <Skeleton width={120} height={20} className="mt-2" />
                        <Skeleton width={250} height={8} className="mt-3" />
                        <Skeleton width="100%" height={8} />

                    </div>
                </div>
                <Skeleton width={160} height={18} className="mt-5" />
                <div className="row">
                    <div className="col-lg-4">
                        <div className='item-img '>
                            <Skeleton width="100%" height={250} />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='item-img '>
                            <Skeleton width="100%" height={250} />
                        </div>
                    </div>
                </div>
                <Skeleton width={160} height={18} className="mt-3" />
                <div className="row  mt-4">
                    <div className="col-lg-6 ">
                        <div className="row">
                            <div className="col-lg-10">
                                <Skeleton width="100%" height={12} className="mt-3" />
                            </div>
                            <div className="col-lg-2">
                                <Skeleton width={12} height={12} circle={true} className="mt-3" />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 ">
                        <div className="row">
                            <div className="col-lg-10">
                                <Skeleton width="100%" height={12} className="mt-3" />
                            </div>
                            <div className="col-lg-2">
                                <Skeleton width={12} height={12} circle={true} className="mt-3" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="row">
                            <div className="col-lg-10">
                                <Skeleton width="100%" height={12} className="mt-3" />
                            </div>
                            <div className="col-lg-2">
                                <Skeleton width={12} height={12} circle={true} className="mt-3" />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 ">
                        <div className="row">
                            <div className="col-lg-10">
                                <Skeleton width="100%" height={12} className="mt-3" />
                            </div>
                            <div className="col-lg-2">
                                <Skeleton width={12} height={12} circle={true} className="mt-3" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="row">
                            <div className="col-lg-10">
                                <Skeleton width="100%" height={12} className="mt-3" />
                            </div>
                            <div className="col-lg-2">
                                <Skeleton width={12} height={12} circle={true} className="mt-3" />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 ">
                        <div className="row">
                            <div className="col-lg-10">
                                <Skeleton width="100%" height={12} className="mt-3" />
                            </div>
                            <div className="col-lg-2">
                                <Skeleton width={12} height={12} circle={true} className="mt-3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}