
//Package import 
import { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import $ from 'jquery';

//Asset import
import Zerodha from '../../assets/images/new-design/kite.svg';
import Tradebook from '../../assets/images/tradebook.svg';
import UploadIcon from '../../assets/images/new-design/upload.svg';
import FileIcon from '../../assets/images/fileIcon.svg';
import Smallcase from '../../assets/images/new-design/smallcase.svg';
import Upstocks from '../../assets/images/new-design/broker.svg';
import Arrow from '../../assets/images/PrimaryArrow.svg';
import Close from '../../assets/images/modal/close.svg';
import Trash from '../../assets/images/onboard/trash-can.png';
import SpinnerMinus from '../../assets/images/new-design/spinner-minus.svg';
import SpinnerPlus from '../../assets/images/new-design/spinner-plus.svg';

//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput';
import Uploader from '../../components/fileUploader/upload';
import Toast from '../../components/note/toast';
import s3Upload from '../../components/fileUploader/awsHelper';

import { UserDataContext } from '../../context/userData';

// import Config
import Config from '../../config/config';

//service import 
import APIPost from '../../services/postApi';

const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]


const StockDetail = (props) => {
    const [accordionOpened, setAccordionOpened] = useState(false);
    const [showOtherOptions, setShowOtherOptions] = useState(false);
    const [manual, setManual] = useState(false);
    const [loader, setLoader] = useState(false);
    const [ticker, setTicker] = useState(null);
    const [date, setDate] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [type, setType] = useState(null);
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [toast, setToast] = useState(null);
    const [openSearchPanel, setOpenSearchPanel] = useState(null);
    const [searchDetail, setSearchDetail] = useState([]);
    const wrapperRef = useRef(null);
    const [syncing, setSyncing] = useState(false);
    const [viewMore, setViewMore] = useState(false);

    const [stockList, setStockList] = useState([
        {
            ticker: null,
            purchase_date: null,
            quantity: 0,
            stock_type: 11,
            ltp: null,

        }
    ]);
    const [userData, setUserData] = useContext(UserDataContext);

    useEffect(() => {
        if (sessionStorage.getItem('kite_access_token')) {
            setShowOtherOptions(true)
        }
    }, [])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 5000)
        }
    }, [toast])

    const _kiteLogin = () => {
        window.location.href = Config.KiteLoginUrl;
    }

    const _addStock = () => {
        setLoader(true);
        let url = Config.portfolio + '/assets/stocks/add';
        let payload = JSON.stringify({
            data: stockList
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                _eventTracker();
                setLoader(false)
                props.success();
            }
            else {
                setLoader(false)
                setToast(response.message);
            }
        })
    }

    const _importFile = () => {
        let url = Config.portfolio + '/assets/stocks/import-from-file';
        let payload = JSON.stringify({
            url: files[0]
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                _eventTracker();
                console.log('response', response.data);
                props.success(response.data)
            } else {
                setToast(response.message);
            }
        })
    }

    const _formValidation = () => {
        let valid = true;
        if (!stockList.length) {
            valid = false
        } else {
            stockList.forEach(function (v, i) {
                if (
                    Object.keys(v).some(function (k) {
                        return (v[k] == null || v[k] == '');

                    })
                ) {
                    console.log('null value present', i);
                    valid = false;
                }
                else {
                    console.log('data right', i);
                    valid = true;
                }
            });
        }
        return valid;
    }

    const _uploadFile = async (file) => {
        var file = file[0];
        var tempFileName = file.name;
        var fileReader = new FileReader();
        tempFileName = tempFileName.replaceAll(' ', '_');
        var tempKey = 'stocks-file';
        tempKey = tempKey + "/" + new Date().getTime()
        var fileType = tempFileName.split("/").pop();
        if (fileType.split('.')[1] == 'xlsx') {
            var response = await s3Upload(tempFileName, tempKey, file, fileType);
            let fileArray = files;
            let names = fileNames;
            fileArray.push(response.cdn_url);
            names.push(tempFileName);
            setFiles([...fileArray]);
            setFileNames([...names]);
        }
        else {
            setToast('Unsupported file type')
        }

    }

    const _fileDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let fileInput = document.getElementById('e-excel-upload');
        const temp = e.target.files || e.dataTransfer.files;
        // const fileList = Object.keys(files).map(file => files[file]);
        _uploadFile(temp)
    }

    const _stockSearch = (text) => {
        let url = Config.portfolio + '/assets/general/stock-search';
        let payload = JSON.stringify({
            search_text: text
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                if (openSearchPanel !== null) {
                    setSearchDetail([...response.data]);
                } else {
                    console.log('inside else');
                    setSearchDetail(response.data);
                }
            }
        })
    }

    const hideDropDown = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpenSearchPanel(null)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', hideDropDown);
        return () => {
            document.removeEventListener('mouseup', hideDropDown);
        };
    }, []);

    const _importData = () => {
        setSyncing(true);
        let url = Config.portfolio + '/assets/stocks/import-from-api';
        let payload = JSON.stringify({
            access_token: sessionStorage.getItem('kite_access_token'),
            api: 'ZERODHA'
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setSyncing(false);
                props.success(response.data)
            }
            else {
                setSyncing(false);
                setToast(response.message);
            }
        })
    }

    const _eventTracker = () => {
        window.analytics.track('Portfolio tracker configured', {
            user_id: userData?.user_id,
            stocks: true,
            MF: false,
            Gold: false,
            RealEstate: false,
            FD: false,
            Bond: false,
            crypto: false
        });
    }


    return (
        <div className={`row mt-4 ${styles.e_stocks} e_stocks`}>
            {
                manual === true ? ( // Form for add asset details manually
                    <>
                        <div className='col-12 '>
                            <h5 className={`${styles.e_form_name}`}>
                                /Adding manually
                            </h5>
                        </div>
                        {
                            stockList.map((item, key) => {
                                return (
                                    <div className='col-12 mt-3 position-relative' key={key}>
                                        <div className={key === 0 ? 'row ' : 'row mt-3'}>
                                            <div className='col-8'>
                                                <h6 className={styles.e_asset_number}>Stock - {key + 1}</h6>
                                            </div>
                                            <div className='col-4 text-end'>
                                                {
                                                    key !== 0 && (
                                                        <img
                                                            src={Trash}
                                                            className={`${styles.e_form_trash}`}
                                                            onClick={() => {
                                                                let array = stockList;
                                                                array.splice(key, 1);
                                                                setStockList([...array]);
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className={key === 0 ? 'col-12 ' : 'col-12 '}>
                                                <label>
                                                    Stock Name
                                                    <span className='star px-1'> * </span>
                                                </label>

                                                <Input
                                                    id={`e-stock-${key}`}
                                                    placeholder="Search stock"
                                                    className={`${styles.e_search}`}
                                                    value={item.ticker}
                                                    needFocusEvent={true}
                                                    focusIn={() => {
                                                        let array = stockList;
                                                        array[key].ticker = '';
                                                        setStockList([...array]);
                                                        setOpenSearchPanel(key);
                                                    }}
                                                    // needBlurEvent={true}
                                                    // focusOut={()=>{ setOpenSearchPanel(false)}}
                                                    valueChange={(e) => {
                                                        let array = stockList;
                                                        array[key].ticker = e;
                                                        setStockList([...array]);
                                                        if (e) { _stockSearch(e) }
                                                    }}
                                                />
                                                {
                                                    openSearchPanel === key && searchDetail.length ? (
                                                        <div className='e-mf-search-panel' ref={wrapperRef} >
                                                            {
                                                                searchDetail && searchDetail.map((stock, i) => {
                                                                    return (
                                                                        <div className='row border-bottom py-2' key={key}>
                                                                            <div className={i === 0 ? 'col-lg-9 col-md-9 col-sm-8 col-8' : 'col-lg-9 col-md-9 col-sm-8 col-8 mt-2'}>
                                                                                {stock.symbol}
                                                                            </div>
                                                                            <div className='col-lg-3 col-md-3 col-sm-4 col-4 '>
                                                                                <SecondaryBtn
                                                                                    name="Add"
                                                                                    className="e-mf-add-btn d-flex justify-content-center"
                                                                                    handleClick={() => {
                                                                                        let array = stockList;
                                                                                        array[key].ticker = stock.symbol;
                                                                                        setStockList([...array]);
                                                                                        setOpenSearchPanel(null);
                                                                                        setSearchDetail([]);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                        : null
                                                }
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4 pt-2'>
                                                <label>
                                                    Purchase date
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <SingleDate
                                                    placeholder="DD-MM-YYYY"
                                                    removeToday={true}
                                                    disabledDate={(day => {
                                                        if (day.isAfter(moment())) {
                                                            return true
                                                        }
                                                    })}
                                                    onChange={(e) => {
                                                        var tempDate = moment(e, 'YYYY-MM-DD')
                                                        let array = stockList;
                                                        array[key].purchase_date = tempDate.format('DD-MM-YYYY');
                                                        setStockList([...array]);
                                                    }}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4 pt-2'>
                                                <label>
                                                    Quantity
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <div className='w-50 position-relative'>
                                                    <Input
                                                        // needOnInput={true}
                                                        // onInput={(e) => { e.target.value = Math.abs(e.target.value) }}
                                                        value={item.quantity}
                                                        type="number"
                                                        label="Quantity"
                                                        className={` ${styles.e_spinner_input}`}
                                                        valueChange={(e) => {
                                                            let array = stockList;
                                                            array[key].quantity = e;
                                                            setStockList([...array]);
                                                        }}
                                                    />
                                                    <img src={SpinnerMinus}
                                                        className={`${styles.e_spinner_minus}`}
                                                        onClick={() => {
                                                            let array = stockList;
                                                            if (array[key].quantity > 0) {
                                                                array[key].quantity = array[key].quantity - 1;
                                                                setStockList([...array]);
                                                            }
                                                        }}
                                                    />
                                                    <img
                                                        src={SpinnerPlus}
                                                        className={`${styles.e_spinner_plus}`}
                                                        onClick={() => {
                                                            let array = stockList;
                                                            array[key].quantity = array[key].quantity + 1;
                                                            setStockList([...array]);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                                                <label>
                                                    Avg cost
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <Input
                                                    label="Avg cost"
                                                    value={item.ltp}
                                                    type="number"
                                                    valueChange={(e) => {
                                                        let array = stockList;
                                                        array[key].ltp = e;
                                                        setStockList([...array]);
                                                    }}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                                <label>
                                                    Net amount
                                                </label>
                                                <div className='position-relative'>
                                                    <Input
                                                        className={styles.e_price_input + " e-portfolio-disable-input position-relative"}
                                                        value={item.ltp * item.quantity}
                                                        valueChange={(e) => { }}
                                                    />
                                                    <span className={`${styles.e_rupee} e-rupee`}>₹</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }


                        <div className='col-12 mt-4'>
                            <PrimaryLink
                                name="Add more"
                                className={` ${styles.e_link_btn}`}
                                handleClick={() => {
                                    let array = stockList;
                                    array.push({
                                        ticker: null,
                                        purchase_date: null,
                                        quantity: null,
                                        stock_type: 11,
                                        ltp: null,
                                    })
                                    setStockList([...array])
                                }}
                            />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                            <SecondaryBtn
                                name="Cancel"
                                className="me-3 w-100"
                                cancel="modal"
                            />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                            {
                                _formValidation() ? (
                                    <PrimaryBtn
                                        name="Add"
                                        className={loader ? 'e-btn-loader w-100' : 'w-100'}
                                        handleClick={() => _addStock()}
                                    />
                                ) :
                                    (
                                        <PrimaryBtn
                                            name="Add"
                                            disabled={true}
                                            className={loader ? 'e-btn-loader w-100' : 'w-100'}
                                            handleClick={() => _addStock()}
                                        />
                                    )

                            }

                        </div>
                    </>
                )

                    :

                    ( // Upload asset details 
                        <>

                            {/* <div className='col-12'>
                                <div className='col-lg-12 col-md-12 col-sm-12 position-relative'>
                                    <h6 className={styles.e_card_title + ' mb-2'}>
                                        Import your holdings below from 9+ Brokers

                                        
                                        <span
                                            id="e-upstocks"
                                            className={`${styles.e_upcoming_broker}`}
                                        >
                                            <img
                                                src={Smallcase}
                                                className={`mx-3`}
                                                style={{ cursor: 'pointer', width: "24px" }}

                                                onClick={() => props.getSmallCaseData()}
                                            />
                                        </span>
                                    </h6>



                                </div>
                                <div className='text-center mt-3'>
                                    <SecondaryBtn
                                        name="Import holdings"
                                        className="e-add-more"
                                        left={true}
                                        // image={Smallcase}
                                        iconClass="me-2"
                                        handleClick={() => {
                                            props.getSmallCaseData()
                                        }} />
                                </div>

                            </div> */}
                            <div className='col-lg-12'>
                                <h6 className={`${styles.e_card_title} mb-2 `}>Upload asset document</h6>
                                <div
                                    className={`${styles.e_file_uploader} text-center p-3`}
                                    onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
                                    onDragLeave={(e) => { e.stopPropagation(); e.preventDefault(); }}
                                    onDrop={(e) => _fileDrop(e)}
                                >
                                    <p className='mt-2'>Drag and drop the files <br />or</p>

                                    <button
                                        onClick={() => {
                                            $('#e-stock-upload').trigger('click');
                                        }}
                                        className="mb-1"
                                    >
                                        <img src={UploadIcon} className="me-2" />
                                        Click to upload
                                    </button>
                                    <span className='mb-2'>(supported formats PDF, csv, sls max-size <br />5mb)</span>
                                </div>
                                <Uploader
                                    tempKey="files"
                                    className="hidden"
                                    id="e-stock-upload"
                                    uploadSucess={(temp, file) => {
                                        let fileArray = files;
                                        let names = fileNames;
                                        fileArray.push(temp);
                                        names.push(file.name);
                                        setFiles([...fileArray]);
                                        setFileNames([...names]);
                                    }}
                                    excel={true}
                                    accept=".xlsx"
                                    uploadStatus={(temp) => { }}
                                />
                            </div>
                            {
                                fileNames.length > 0 && (
                                    <div className='col-12 mt-3'>
                                        {
                                            fileNames.map((item, key) => {
                                                return (
                                                    <div>
                                                        <h6 className='e-file-preview-title '>Uploaded successfully</h6>
                                                        <div className="e-boarding-file-preview">
                                                            <p className="p-2">{item}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            <div className='col-12 mt-3'>
                                <div className='accordion e-portfolio-accordion mb-3' id="parent">
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header  px-2 pb-3 pt-1 e_accordion_header"
                                            id="headingOne"
                                            style={{ marginBlockEnd: accordionOpened && '-17px' }}
                                        >
                                            <button
                                                className={accordionOpened ? "e-accordion-btn text-start mt-2" : "e-accordion-btn text-start mt-2"}
                                                type="button"
                                                aria-expanded={accordionOpened === true ? 'true' : "false"}
                                                onClick={() =>
                                                    setAccordionOpened(!accordionOpened)
                                                }
                                            >
                                                {/* <img src={Tradebook} className="me-2 "/> */}
                                                How to get my report?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseOne"
                                            className={accordionOpened === true ? "accordion-collapse collapse  show" : "accordion-collapse collapse"}
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#parent"
                                        >
                                            <div className='accordion-body pt-2'>
                                                <div className='row'>
                                                    <div className='col-lg-12 '>
                                                        <ul className={`${styles.e_instruction_ul}`}>
                                                            <li>
                                                                Login to <a href="https://console.zerodha.com/" target="_blank">console.zerodha.com</a>
                                                            </li>
                                                            <li>
                                                                Download your holdings statement in .xslx format
                                                            </li>
                                                            <li>
                                                                Upload below
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Accordion */}
                            {/* <div className='col-12 mt-3'>
                                <div className='accordion e-portfolio-accordion mb-3' id="parent">
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header  px-2 pb-3 pt-1 e_accordion_header"
                                            id="headingOne"
                                            style={{ marginBlockEnd: accordionOpened && '-17px' }}
                                        >
                                            <button
                                                className={accordionOpened ? "e-accordion-btn text-start mt-2" : "e-accordion-btn text-start mt-2"}
                                                type="button"
                                                aria-expanded={accordionOpened === true ? 'true' : "false"}
                                                onClick={() =>
                                                    setAccordionOpened(!accordionOpened)
                                                }
                                            >
                                                <img src={Tradebook} className="me-2 "/>
                                                How to get my report?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseOne"
                                            className={accordionOpened === true ? "accordion-collapse collapse  show" : "accordion-collapse collapse"}
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#parent"
                                        >
                                            <div className='accordion-body pt-2'>
                                                <div className='row'>
                                                    <div className='col-lg-12 '>
                                                        <ul className={`${styles.e_instruction_ul}`}>
                                                            <li className='e-break'>
                                                                Login to <a href="https://console.zerodha.com/" target="_blank">console.zerodha.com</a>
                                                            </li>
                                                            <li>
                                                                Download your holdings statement in .xslx format
                                                            </li>
                                                            <li>
                                                                Upload below
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className='col-12 text-center mt-3'>
                                <PrimaryLink
                                    name={!viewMore ? "View other options" : 'Hide other options'}
                                    className={`${styles.e_more_btn}`}
                                    handleClick={() => setViewMore(!viewMore)}
                                />
                            </div>

                            <div className='col-12 mt-3'>
                                <div className='row'>
                                    <div className='col-12 '>
                                        <div className={`row ${styles.e_other_card} `} style={{ height: viewMore ? window.innerWidth > 765 ? 'auto' : '175px' : '0px' }}>
                                            {
                                                sessionStorage.getItem('kite_access_token') ? (
                                                    <div className='col-lg-6 col-md-6 col-sm-12 position-relative'>
                                                        <h6 className='mb-4'>
                                                            Sync your Zerodha holdings
                                                        </h6>
                                                        <PrimaryBtn
                                                            name={syncing ? "Syncing" : 'Sync'}
                                                            className={syncing ? "e-btn-loader w-100 e-add-asset-modal-btn" : 'w-100 e-add-asset-modal-btn'}
                                                            handleClick={() => _importData()}
                                                        />
                                                        <div className={`${styles.e_divider}`}></div>
                                                    </div>
                                                )
                                                    : (
                                                        <div className='col-lg-6 col-md-6 col-sm-12 position-relative'>
                                                            <h6 className='mb-3'>
                                                                Connect with your broker
                                                            </h6>
                                                            <img src={Zerodha} className={`me-3 ${styles.e_login_btn}`} style={{ cursor: 'pointer' }} onClick={() => _kiteLogin()} />
                                                            <div
                                                                id="e-upcoming"
                                                                className={`${styles.e_upcoming_broker}`}
                                                            >
                                                                <img src={Upstocks}
                                                                    onMouseEnter={() => {
                                                                        $(`#e-upcoming`).addClass('e-hover-popup');
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        $(`#e-upcoming`).removeClass('e-hover-popup');
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className={`${styles.e_divider}`}></div>
                                                        </div>
                                                    )
                                            }

                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3 ps-3' >
                                                <h6 className='mb-4'>
                                                    Add stocks details
                                                </h6>
                                                <PrimaryLink
                                                    name="Add manually"
                                                    className={`${styles.e_link_btn}`}
                                                    handleClick={() => { setManual(true) }}//_kiteLogin();
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                        <SecondaryBtn
                                            name="Cancel"
                                            className="w-100"
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                        <PrimaryBtn
                                            name="Upload"
                                            className="w-100"
                                            disabled={files.length ? false : true}
                                            handleClick={() => _importFile()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
            }

            {
                toast && (
                    <Toast data={toast} />
                )
            }

        </div >
    )
}

export default StockDetail;