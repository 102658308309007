/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-11-2021
 */

/*import package*/
import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';




/*import style*/
import styles from '../../styles/components/accordion.module.scss';


/*---import assets --*/
import Calendar from '../../assets/images/dataformatting/calendar.svg'
import Right from '../../assets/images/new-design/check-circle.svg'
import Money from '../../assets/images/dataformatting/money.svg'
import Faile from '../../assets/images/new-design/x-circle.svg'
import Process from '../../assets/images/new-design/clock.svg';
import SIP from '../../assets/images/new-design/sip.svg';
import Lumpsum from '../../assets/images/new-design/lumpsum.svg';
import Withdrawals from '../../assets/images/new-design/withdraw.svg';

export default function Accordion(props) {

  const [edit, setEdit] = useState();
  const [id, setId] = useState();

  useEffect(() => {
    setId(props.OrderId);
    if (props.OrderId) {
      document.getElementById(`accordion-${props.OrderId}`).scrollIntoView();
    }

  }, [])
  return (
    <div className={`${styles.e_order_accordion} e-order-accordion`}>
      <div className="accordion" id="accordionDescription">
        <div className="row mx-0">
          {
            props?.data?.map((item, key) => {
              return (
                <div className={`${styles.e_accordion_sec} col-lg-12 p-3 pb-1 mt-3 `} id={`accordion-${item.order_id} `}>
                  <div className={item.order_id !== id ? `${styles.e_accordion_item} accordion-item ` : `${styles.e_accordion_item} accordion-item pb-2 `}>
                    <h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription" + key} >
                      <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-parent="#accordionDescription" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`}>
                        <div onClick={() => setId(item.order_id)}>
                          {
                            item.order_id !== id ?
                              <div className="row" >
                                <div className="col-lg-12">
                                  <div className='d-flex  flex-wrap align-items-start'>
                                    <img
                                      src={
                                        item.type === 'Withdrawals' ?
                                          Withdrawals
                                          : item.type === 'SIP' ?
                                            SIP
                                            :
                                            Lumpsum
                                      }
                                      className="me-3"
                                    />
                                    <div className={`mb-3 ${styles.e_column} e-accordion-column`}>
                                      <div className={`${styles.e_accordion_title}`}>{item.type}</div>
                                      <div className={` ${styles.e_amount}`}>
                                        {
                                          item.invested_amount_text ?
                                            <>
                                              <span className='e-rupee-symbol position-relative me-1'
                                                style={{ fontWeight: '400', fontSize: '18px', top: '-1px' }}>₹
                                              </span>
                                              {item.invested_amount.toLocaleString('en-IN')}
                                            </>
                                            :
                                            '-'
                                        }

                                      </div>
                                    </div>

                                    <div className={`mb-3 ${styles.e_column} e-accordion-column`}>
                                      <div className={`${styles.e_accordion_title}`}>Date</div>
                                      <div className={`${styles.e_accordion_sub_title} `}>{item.order_date}</div>
                                    </div>

                                    <div className={`mb-3 ${styles.e_column} e-accordion-column`}>
                                      <div className={`${styles.e_accordion_title}`}>{item.type == 'Withdrawals' ? 'Withdrawal status' : 'Payment status'}</div>
                                      <div className={`${styles.e_accordion_sub_title} `}>{item.payment_status}<span className='ps-2'><img src={item.payment_status === "Success" ? Right : item.payment_status === "Failed" ? Faile : Process} alt="" /></span></div>
                                    </div>
                                    <div className={`mb-3 ${styles.e_column} e-accordion-column`}>
                                      <div className={`${styles.e_accordion_title}`}>Order status</div>
                                      <div className={`${styles.e_accordion_sub_title} `}>{item.status}
                                        {
                                          item.status ?
                                            <span className='ps-2'><img src={item.status === "Success" ? Right : item.status === "Failed" ? Faile : Process} alt="" /></span>
                                            :
                                            "--"
                                        }
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-2 col-md-2 col-sm-2 col-2"> */}
                                    <span className={`${styles.e_accordion_icon} e-order-accordion-icon`}></span>
                                    {/* </div> */}
                                  </div>

                                </div>


                              </div>
                              :
                              null
                          }
                        </div>
                        <div onClick={() => setId(null)}>
                          {
                            item.order_id === id ?
                              <div className="row" >
                                <div className="col-lg-12">
                                  <div className='d-flex flex-wrap align-items-start'>
                                    <img
                                      src={
                                        item.type === 'Withdrawals' ?
                                          Withdrawals
                                          : item.type === 'SIP' ?
                                            SIP
                                            :
                                            Lumpsum
                                      }
                                      className="me-3"
                                    />
                                    <div className={`mb-3 ${styles.e_column} e-accordion-column`}>
                                      <div className={`${styles.e_accordion_title}`}>{item.type}</div>
                                      <div className={`${styles.e_amount} `}>
                                        {
                                          item.invested_amount_text ?
                                            <>
                                              <span className='e-rupee-symbol position-relative me-1'
                                                style={{ fontWeight: '400', fontSize: '18px', top: '-1px' }}>₹
                                              </span>
                                              {item.invested_amount.toLocaleString('en-IN')}
                                            </>
                                            :
                                            '-'
                                        }

                                      </div>
                                    </div>

                                    <div className={`mb-3 ${styles.e_column} e-accordion-column`}>
                                      <div className={`${styles.e_accordion_title}`}>Date</div>
                                      <div className={`${styles.e_accordion_sub_title} `}>{item.order_date}</div>
                                    </div>

                                    <div className={`mb-3 ${styles.e_column} e-accordion-column`}>
                                      <div className={`${styles.e_accordion_title}`}>{item.type == 'Withdrawals' ? 'Withdrawal status' : 'Payment status'}</div>
                                      <div className={`${styles.e_accordion_sub_title} `}>{item.payment_status}<span className='ps-2'><img src={item.payment_status === "Success" ? Right : item.payment_status === "Failed" ? Faile : Process} alt="" /></span></div>
                                    </div>
                                    <div className={`mb-3 ${styles.e_column} e-accordion-column`}>
                                      <div className={`${styles.e_accordion_title}`}>Order status</div>
                                      <div className={`${styles.e_accordion_sub_title} `}>{item.status}
                                        {
                                          item.status ?
                                            <span className='ps-2'><img src={item.status === "Success" ? Right : item.status === "Failed" ? Faile : Process} alt="" /></span>
                                            :
                                            "--"
                                        }
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      <span className={`${styles.e_accordion_icon_minus} e-order-accordion-icon`}></span>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              :
                              null
                          }
                        </div>
                      </button>
                    </h2>
                    {
                      item.order_id == id ?
                        <div>
                          <div className={`${styles.e_accordion_body} accordion-body e-order-accordion-body mt-3`}>
                            <div className={`${styles.e_accordion_wrapper} row e-order-accordion-body-wrapper ps-2`}>
                              <p className='ps-2'>Portfolio score: <span> {item.portfolio_score} </span></p>
                              <div className={`${styles.e_accordion_sub_wrapper} row  px-0 mx-2`}>
                                <div className='col-lg-5 col-md-5 col-5'>
                                  <div className='row'>
                                    <div className='col-lg-8 col-md-7 col-7 px-0'>
                                      <p>Fund name</p>
                                    </div>
                                    <div className='col-lg-4 text-center col-md-5 col-5'>
                                      <p>Amount</p>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-lg-7 col-md-7 col-7'>
                                  <div className='row'>
                                    <div className='col-lg-3 col-md-3 col-3'>
                                      <p>Units</p>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-3'>
                                      <p>{item.type_id === 3 ? "Units redeemed on" : "Units allocated on"}</p>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-3'>
                                      <p>Transaction ID</p>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-3 gx-0'>
                                      <p>Order status</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {
                                item.portfolio?.map((data, key) => (
                                  <div className={key === item.portfolio.length - 1 ? `${styles.e_accordion_table_wrapper} row px-0 mx-2` : `${styles.e_accordion_table_wrapper} row  px-0 mx-2`} key={key}>
                                    <div className='col-lg-5 col-md-5 col-5'>
                                      <div className='row'>
                                        <div className='col-lg-8 col-md-7 col-7  px-0'>
                                          <p>{data.fund}</p>
                                        </div>
                                        <div className='col-lg-4 text-center col-md-5 col-5'>
                                          <p>
                                            {
                                              data.amount ?
                                                <>
                                                  <span className='e-rupee-symbol position-relative me-1'
                                                    style={{ fontWeight: '200', fontSize: '10px', top: '-1px' }}>₹
                                                  </span>
                                                  {data.amount.toLocaleString('en-IN')}
                                                </>
                                                :
                                                '-'
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-7'>
                                      <div className='row'>
                                        <div className='col-lg-3 col-md-3 col-3'>
                                          <p>{data.units !== null ? data.units : "--"}</p>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-3'>
                                          <p className='mb-0'>{data.units_allocated_on !== null ? data.units_allocated_on : "--"}</p>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-3'>
                                          <p>{data.mintd_order_id}</p>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-3 gx-0'>
                                          <p>{data.order_status}<span className='mt-2 ms-2 py-2'><img src={data.order_status === "Success" ? Right : data.order_status === "Failed" ? Faile : Process} alt="" /></span></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                        :
                        null
                    }
                  </div>
                </div>
              )
            })
          }

        </div>
      </div>
      {/* <div className="row mx-0">
        <div className={`${styles.e_add_sec} col-lg-12`} onClick={() => addFund()}>
          <h6><span className="pe-2"><img src={AddNew} /></span>Add New</h6>
        </div>
      </div> */}
      {/* <ChangeAllocation data={edit}/>
      <FundClass />
      <EditFundClass editData={edit} />
      <AddFund /> */}
    </div>
  );
}


