import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Component
import { Loader } from "../../components/loader"
import GetURLVar from "../../services/getUrl";

import APIPost from "../../services/postApi";
import Config from '../../config/config';

const Redirect = () => {
    const navigator = useNavigate();

    useEffect(()=>{
        GetURLVar().then((query)=>{
            _getToken(query.request_token);
        })
        
    },[])

    const _getToken = (token) => {
        let url = Config.portfolio+'/auth/kite/generate-token';
        let payload = JSON.stringify({
            request_token: token
        })
        APIPost(url, payload).then((response) => {
            if ( response.status_code === 200 ) {
                sessionStorage.setItem('kite_access_token', response.data.access_token);
                navigator('/portfolio-tracker?asset_type='+sessionStorage.getItem('asset_type'))
            }
        })
    }
    
    return(
        <Loader height="300px" removeTag={true}/>
    )
}

export default Redirect;