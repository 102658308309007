
//Package import
import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput';

import { Toast } from '../../components/note';

import Trash from '../../assets/images/onboard/trash-can.png';

import APIPost from '../../services/postApi';
import Config from '../../config/config';

import { UserDataContext } from '../../context/userData';
const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]

const frequencyOption = [
    { value: 12, label: 'Monthly' },
    { value: 4, label: 'Quarterly' },
    { value: 2, label: 'Half-yearly' },
    { value: 1, label: 'Yearly' }
]

const FixedDepositDetail = (props) => {
    const [loader, setLoader] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [name, setName] = useState(null);
    const [FDType, setFDType] = useState(null);
    const [amount, setAmount] = useState(null);
    const [interest, setInterest] = useState(null);
    const [date, setDate] = useState(null);
    const [frequency, setFrequency] = useState(null);
    const [tenure, setTenure] = useState(null);
    const [toast, setToast] = useState(null);
    const [userData, setUserData] = useContext(UserDataContext);

    const [depositList, setDepositList] = useState([
        {
            organization_type_id: null,
            organization_name: null,
            fd_type: null,
            invested_amount: null,
            rate_of_interest: null,
            start_date: null,
            interest_compounding_frequency: null,
            tenure: null
        }
    ]);

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 3000)
        }
    }, [toast])

    const _addFixed = () => {
        let url = Config.portfolio + '/assets/fd/add';
        let payload = JSON.stringify({
            data: depositList
            // organization_type_id: organization.value,
            // organization_name: name,
            // fd_type: FDType.value,
            // invested_amount: amount,
            // rate_of_interest: interest,
            // start_date: date,
            // interest_compounding_frequency: frequency,
            // tenure: tenure
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                _eventTracker();
                setLoader(false);
                props.success();
            }
            else {
                setLoader(false);
                setToast(response.message);
            }
        })
    }

    const _formValidation = () => {
        let valid = true;
        depositList.forEach(function (v, i) {
            if (
                Object.keys(v).some(function (k) {
                    return (v[k] == null || v[k] == '');
                })
            ) {
                console.log('null value present', i);
                valid = false;
            }
            else {
                console.log('data right', i);
                valid = true;
            }
        });
        return valid;
    }

    const _eventTracker = () => {
        window.analytics.track('Portfolio tracker configured', {
            user_id: userData?.user_id,
            stocks: false,
            MF: true,
            Gold: false,
            RealEstate: false,
            FD: true,
            Bond: false,
            crypto: false
        });
    }

    return (
        <div className={`row mt-4 ${styles.e_stocks} e_stocks`}>
            <div className='col-12'>
                {
                    depositList.map((item, key) => {
                        return (
                            <div key={key} className='col-12 mt-3 position-relative'>
                                <div className={key === 0 ? 'row ' : 'row mt-4'}>
                                    <div className='col-8'>
                                        <h6 className={styles.e_asset_number}>Fixed Deposit - {key + 1}</h6>
                                    </div>
                                    <div className='col-4 text-end'>
                                        {
                                            key !== 0 && (
                                                <img
                                                    src={Trash}
                                                    className={`${styles.e_form_trash}`}
                                                    onClick={() => {
                                                        let array = depositList;
                                                        array.splice(key, 1);
                                                        setDepositList([...array]);
                                                    }}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className={key === 0 ? 'col-12 ' : 'col-12'}>
                                        <label>
                                            Organization type
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Select
                                            isSearchable={true}
                                            options={props.organization}
                                            selectchange={(e) => {
                                                let array = depositList;
                                                array[key].organization_type_id = e.value
                                                setDepositList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 pt-2'>
                                        <label>
                                            Organization name
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            value={item.organization_name}
                                            label="Organization name"
                                            valueChange={(e) => {
                                                let array = depositList;
                                                array[key].organization_name = e
                                                setDepositList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 pt-2'>
                                        <label>
                                            FD Type
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Select
                                            placeholder="Select FD type"
                                            options={props.fixedOptions}
                                            // menuIsOpen={true}
                                            selectchange={(e) => {
                                                let array = depositList;
                                                array[key].fd_type = e.value
                                                setDepositList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                        <label>
                                            Investment amount
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            value={item.invested_amount}
                                            label="&#8377;0"
                                            type="number"
                                            valueChange={(e) => {
                                                let array = depositList;
                                                array[key].invested_amount = e
                                                setDepositList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                        <label>
                                            Rate of interest
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            value={item.rate_of_interest}
                                            label="e.g 6.75%"
                                            type="number"
                                            valueChange={(e) => {
                                                let array = depositList;
                                                array[key].rate_of_interest = e
                                                setDepositList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 pt-1'>
                                        <label>
                                            Start date
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <SingleDate
                                            placeholder="Choose a day"
                                            removeToday={true}
                                            disabledDate={(day => {
                                                if (day.isAfter(moment())) {
                                                    return true
                                                }
                                            })}
                                            onChange={(e) => {
                                                var tempDate = moment(e, 'YYYY-MM-DD')
                                                let array = depositList;
                                                array[key].start_date = tempDate.format('DD-MM-YYYY')
                                                setDepositList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-3 '>
                                        <label>
                                            Interest compounding frequency
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Select
                                            placeholder="Choose frequency"
                                            options={frequencyOption}
                                            selectchange={(e) => {
                                                let array = depositList;
                                                array[key].interest_compounding_frequency = e.value;
                                                setDepositList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                        <label>
                                            Tenure (In year)
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            value={item.tenure}
                                            label="Enter tenure"
                                            type="number"
                                            valueChange={(e) => {
                                                let array = depositList;
                                                array[key].tenure = e
                                                setDepositList([...array]);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className='col-12 mt-4'>
                <PrimaryLink
                    name="Add more"
                    className={` ${styles.e_link_btn}`}
                    handleClick={() => {
                        let array = depositList;
                        array.push(
                            {
                                organization_type_id: null,
                                organization_name: null,
                                fd_type: null,
                                invested_amount: null,
                                rate_of_interest: null,
                                start_date: null,
                                interest_compounding_frequency: null,
                                tenure: null
                            }
                        )
                        setDepositList([...array]);
                    }}
                />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                <SecondaryBtn
                    name="Cancel"
                    className="w-100"
                    cancel="modal"
                />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                {
                    _formValidation() ? (
                        <PrimaryBtn
                            name="Add"
                            className={loader ? 'e-btn-loader w-100' : 'w-100'}
                            handleClick={() => _addFixed()}
                        />
                    ) : (
                        <PrimaryBtn
                            name="Add"
                            disabled={true}
                            className={'w-100'}
                        />
                    )

                }

            </div>
            {
                toast && (
                    <Toast data={toast} />
                )
            }
        </div>
    )
}

export default FixedDepositDetail;