/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Dummy page for tour
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-07-2022
 */

/*import packages*/
import { useState, useEffect, React, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal } from 'bootstrap';
import ReactJoyride, { ACTIONS, } from 'react-joyride';

/*import components*/
import { Footer } from '../../components/menu/index';
import { PieChart } from '../../components/chart/index';
import { IconStyleToolTip } from '../../components/tooltip/index';
import ProgressBar from '../../components/input/progress.js'
import { SkipTour } from '../../components/modal';
import LeadSquared from '../../services/leadSquredServices';

/*import assets*/
import Playstore from '../../assets/images/menu/playstore.svg';
import Appstore from '../../assets/images/menu/appstore.svg';
import TooltipIcon from '../../assets/images/tooltip/icon.svg';
import Tooltip from '../../assets/images/tooltip/icon-blue.svg';
import Hand from '../../assets/images/modal/hand.svg'
import Arrow from '../../assets/images/new-design/btn-arrow.svg';
import Previous from '../../assets/images/new-design/tour-back.svg'
import MintdLogo from '../../assets/images/menu/logo.svg';
import SideBarMenu from '../../assets/images//new-design/menu.png';

/*import context*/
import { UserDataContext } from '../../context/userData';
import Sidebar from '../../components/menu/sidebar';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta';

const TourAssets = (props) => {

  let navigator = useNavigate();
  let percentValue = 100 / 7;
  const [run, setRun] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isShownCurrent, setIsShownCurrent] = useState(false);
  const [assetName, setAssetName] = useState(false);
  const [tourStep, setTourStep] = useState(null);
  const [userData, setUserData] = useContext(UserDataContext);
  const [openSideBar, setOpenSideBar] = useState(false)


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])

  useEffect(() => {
    window.addEventListener("mousewheel DOMMouseScroll", disableScroll);
    disableScroll();
    return () => {
      window.removeEventListener("scroll", disableScroll);
    };
  }, [])

  const disableScroll = () => {
    if (assetName === true) {
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft
      window.scrollTo(scrollLeft, scrollTop);
    }
  }


  useEffect(async () => {
    setRun(true)
  }, [])

  const handleClickStart = () => {
    // e.preventDefault();
    setRun(true)
  };

  const backgroundColor = [
    "#1329E6",
    "#6A79FF",
    "#2F3DB9",
    "#644DEC",
    "#AF93F6",
    "#C6D9FF",
    "#72BBFF",
    "#127CC9",
  ]

  const invested_data = {
    "allocation": [
      {
        "portfolio_id": 436,
        "display_name": "Indian Large Cap",
        "scheme_name": "ICICI Prudential NIFTY Next 50 Index Fund - Growth",
        "allocation": 45.25,
        "performance": [
          {
            "1D": -1.28
          },
          {
            "1M": 9.25
          },
          {
            "3M": -10.26
          },
          {
            "6M": -10.63
          }
        ]
      },
      {
        "portfolio_id": 437,
        "display_name": "Indian Mid Cap",
        "scheme_name": "Motilal Oswal NIFTY Midcap 150 Index Fund - Regular Plan",
        "allocation": 20.02,
        "performance": [
          {
            "1D": -0.69
          },
          {
            "1M": 8.52
          },
          {
            "3M": -7.93
          },
          {
            "6M": -12.13
          }
        ]
      },
      {
        "portfolio_id": 433,
        "display_name": "Government Bonds",
        "scheme_name": "UTI - Gilt Fund - Regular Plan - Growth Option",
        "allocation": 19.77,
        "performance": [
          {
            "1D": 0.01
          },
          {
            "1M": 0.63
          },
          {
            "3M": 0.29
          },
          {
            "6M": 0.11
          }
        ]
      },
      {
        "portfolio_id": 438,
        "display_name": "Indian Small Cap",
        "scheme_name": "Motilal Oswal NIFTY Smallcap 250 Index - Regular Plan",
        "allocation": 14.94,
        "performance": [
          {
            "1D": -1.06
          },
          {
            "1M": 6.09
          },
          {
            "3M": -14.99
          },
          {
            "6M": -20.07
          }
        ]
      },
      {
        "portfolio_id": 435,
        "display_name": "Debt Like",
        "scheme_name": "Aditya Birla Sun Life Arbitrage Fund - Regular Plan - Growth",
        "allocation": 0.0,
        "performance": [
          {
            "1D": 0.05
          },
          {
            "1M": 0.25
          },
          {
            "3M": 0.77
          },
          {
            "6M": 1.62
          }
        ]
      },
      {
        "portfolio_id": 439,
        "display_name": "Global",
        "scheme_name": "Aditya Birla Sun Life Nasdaq 100 FOF-Regular Growth",
        "allocation": 0.0,
        "performance": [
          {
            "1D": -0.07
          },
          {
            "1M": 6.1
          },
          {
            "3M": -13.71
          },
          {
            "6M": -19.03
          }
        ]
      },
      {
        "portfolio_id": 440,
        "display_name": "Indian Bonds",
        "scheme_name": "UTI - Short Term Income Fund - Regular Plan - Growth Option",
        "allocation": 0.0,
        "performance": [
          {
            "1D": 0.01
          },
          {
            "1M": 0.7
          },
          {
            "3M": 0.59
          },
          {
            "6M": 1.05
          }
        ]
      },
      {
        "portfolio_id": 496,
        "display_name": "Liquid Funds",
        "scheme_name": "SBI Liquid Fund - Regular Plan -Growth",
        "allocation": 0.0,
        "performance": [
          {
            "1D": 0
          },
          {
            "1M": 0.38
          },
          {
            "3M": 1.01
          },
          {
            "6M": 1.87
          }
        ]
      }
    ],
    "graph_data": {
      "allocation": [
        25,
        15,
        5,
        40,
        25,
        15,
      ],
      "colors": [
        "#1329E6",
        "#6A79FF",
        "#2F3DB9",
        "#644DEC",
        "#AF93F6",
        "#C6D9FF",
        "#72BBFF",
        "#127CC9",
      ],
      "fund_class": [
        "Indian Large Cap",
        "Indian Mid Cap",
        "Government Bond",
        "Debt Like",
        "Indian Bonds",
        "Liquid Funds",
      ]
    }
  }

  const investment_summary = {
    "current_value": 7078.01,
    "invested_value": 6800.0
  }

  const portfolio_by_class = {
    "Indian Equities": [
      {
        "portfolio_id": 436,
        "mutualfund_id": 56,
        "display_name": "Indian Large Cap",
        "scheme_name": "ICICI Prudential NIFTY Next 50 Index Fund - Growth",
        "scheme_code": "112957",
        "allocation": 45.351
      },
      {
        "portfolio_id": 437,
        "mutualfund_id": 57,
        "display_name": "Indian Mid Cap",
        "scheme_name": "Motilal Oswal NIFTY Midcap 150 Index Fund - Regular Plan",
        "scheme_code": "147621",
        "allocation": 20.131
      },
      {
        "portfolio_id": 438,
        "mutualfund_id": 67,
        "display_name": "Indian Small Cap",
        "scheme_name": "Motilal Oswal NIFTY Smallcap 250 Index - Regular Plan",
        "scheme_code": "147624",
        "allocation": 15.048
      },
      {
        "portfolio_id": 439,
        "mutualfund_id": 69,
        "display_name": "Global",
        "scheme_name": "Aditya Birla Sun Life Nasdaq 100 FOF-Regular Growth",
        "scheme_code": "149290",
        "allocation": 0.0
      }
    ],
    "Indian Debt": [
      {
        "portfolio_id": 433,
        "mutualfund_id": 65,
        "display_name": "Government Bonds",
        "scheme_name": "UTI - Gilt Fund - Regular Plan - Growth Option",
        "scheme_code": "102510",
        "allocation": 19.468
      },
      {
        "portfolio_id": 440,
        "mutualfund_id": 68,
        "display_name": "Indian Bonds",
        "scheme_name": "UTI - Short Term Income Fund - Regular Plan - Growth Option",
        "scheme_code": "106624",
        "allocation": 0.0
      },
      {
        "portfolio_id": 496,
        "mutualfund_id": 80,
        "display_name": "Liquid Funds",
        "scheme_name": "SBI Liquid Fund - Regular Plan -Growth",
        "scheme_code": "105280",
        "allocation": 0.0
      }
    ],
    "Hybrid": [
      {
        "portfolio_id": 435,
        "mutualfund_id": 66,
        "display_name": "Debt Like",
        "scheme_name": "Aditya Birla Sun Life Arbitrage Fund - Regular Plan - Growth",
        "scheme_code": "112088",
        "allocation": 0.0
      }
    ]
  }

  /* chart data */
  const portfolio_data = {
    "investment_summary": {
      "current_value": 7078.01,
      "invested_value": 6800.0
    },
    "allocation": [
      {
        "portfolio_id": 436,
        "display_name": "Indian Large Cap",
        "scheme_name": "ICICI Prudential NIFTY Next 50 Index Fund - Growth",
        "allocation": 45.0
      },
      {
        "portfolio_id": 437,
        "display_name": "Indian Mid Cap",
        "scheme_name": "Motilal Oswal NIFTY Midcap 150 Index Fund - Regular Plan",
        "allocation": 20.0
      },
      {
        "portfolio_id": 433,
        "display_name": "Government Bonds",
        "scheme_name": "UTI - Gilt Fund - Regular Plan - Growth Option",
        "allocation": 20.0
      },
      {
        "portfolio_id": 438,
        "display_name": "Indian Small Cap",
        "scheme_name": "Motilal Oswal NIFTY Smallcap 250 Index - Regular Plan",
        "allocation": 15.0
      },
      {
        "portfolio_id": 440,
        "display_name": "Indian Bonds",
        "scheme_name": "UTI - Short Term Income Fund - Regular Plan - Growth Option",
        "allocation": 0.0
      },
      {
        "portfolio_id": 435,
        "display_name": "Debt Like",
        "scheme_name": "Aditya Birla Sun Life Arbitrage Fund - Regular Plan - Growth",
        "allocation": 0.0
      },
      {
        "portfolio_id": 439,
        "display_name": "Global",
        "scheme_name": "Aditya Birla Sun Life Nasdaq 100 FOF-Regular Growth",
        "allocation": 0.0
      },
      {
        "portfolio_id": 496,
        "display_name": "Liquid Funds",
        "scheme_name": "SBI Liquid Fund - Regular Plan -Growth",
        "allocation": 0.0
      },

    ],
    "graph_data": {
      "allocation": [
        15,
        5,
        25,
        25,
        25,
        5,
      ],
      "colors": [
        "#1329E6",
        "#644DEC",
        "#8C65EC",
        "#9A87F1",
        "#AF93F6",
        "#BDACF6",
        "#DED0FB",
        "#E3D6FF",
        "#EDE8FA"
      ],
      "fund_class": [
        "Indian Large Cap",
        "Indian Mid Cap",
        "Government Bond",
        "Debt Like",
        "Indian Bonds",
        "Liquid Funds",
      ]
    }
  }

  const _segmentTourTracker = (step) => {
    window.analytics.track('Product tour', {
      user_id: userData?.user_id,
      producttour_status: 'viewed',
      step: step
    });
  }




  /* joy ride steps */
  const steps = [

    {
      content: (
        <div className='e-tour'>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      target: '#e-sec-0',
      stepIndex: 0,
    },
    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <span className='e-completed-item'>3</span>
            <h6 className='ps-3 text-start '>View your asset allocations</h6>
          </div>
          <p className='text-start ms-4 ps-3'>See a quick breakdown of your current holdings vs. what your ideal allocation
            should look like.</p>
          <ProgressBar percent={percentValue * 3} />
        </div>
      ),
      placement: window.innerWidth > 992 ? 'bottom-center' : 'top-center',
      styles: {
        options: {
          borderRadius: 16,
          width: 500,
          height: 180,
        }
      },
      locale: {
        skip: <p>3 to 7</p>,
        next: <span className="e-tour-button"
          onClick={() => {
            _segmentTourTracker(3);
            LeadSquared._activityTracker('Product Tour Drop-off mail', 208);
            setTourStep(3)
          }}
        >
          <img src={Arrow} className="e-form-submit" />
        </span>,

        back: <span className="e-tour-back">
          <img src={Previous} alt="" className='' onClick={() => window.history.back()} />
        </span>

      },
      disableBeacon: true,
      disableOverlayClose: true,
      disableScrolling: true,
      disableScrollParentFix: true,
      target: '#e-sec-4',
    },
    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <span className='e-completed-item'>4</span>
            <h6 className='ps-3 text-start  pe-3'>Asset class breakdown</h6>
          </div>
          <p className='text-start  ms-4 ps-3'>View your returns by asset class, so you know how each piece of your portfolio is
            performing over time.</p>
          <ProgressBar percent={percentValue * 4} />
        </div>
      ),
      placement: 'top-center',
      styles: {
        options: {
          borderRadius: 16,
          width: 500,
          height: 180,
        }
      },
      locale: {
        skip: <p>4 to 7</p>,
        next: <span className="e-tour-button"
          onClick={() => {
            setAssetName(true);
            _segmentTourTracker(4);
            LeadSquared._activityTracker('Product Tour Drop-off mail', 208);
            setTourStep(4)
          }}
        >
          <img src={Arrow} className="e-form-submit" />

        </span>,
        back: <span className='e-tour-back'>
          <img src={Previous} alt="" />
        </span>
      },

      disableBeacon: true,
      disableOverlayClose: true,
      target: '#e-sec-5',
    },

    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <img src={Hand} alt="" />
            <h5 className='ps-3 text-start pt-0 pt-md-2 pt-lg-2 pe-3'>Click on the<a href='/tour-scrip-detail?welcome=true'> asset’s name</a> to view more details.</h5>
          </div>
        </div>
      ),
      placement: 'top-center',
      styles: {

        options: {
          borderRadius: 16,
          width: 500,
          height: 180,
        },
      },
      locale: {
        last: <span className="e-next-span d-none">
          <img src={Arrow} className="e-form-submit  d-none" />
        </span>,
        back: <img src={Previous} alt="" className='d-none' />

      },
      disableBeacon: true,
      disableOverlayClose: true,
      target: '#e-sec',
    }

  ]

  const handleJoyrideCallback = data => {
    console.log(data);
    const { action, index, type, size } = data;
    if (action === ACTIONS.CLOSE) {
      var modal = new Modal(document.getElementById("skip"), {});
      modal.show()
      return setRun(false)

    }
  }


  return (
    <>
      {/* <HeaderTour logo={InnerLogo} /> */}
      <div className="container-fluid e-asset-container pb-0 mb-0 e-tour-asset-container " >
        <div className='row'>
          <div className='col-lg-2'>
            <div className='e-side-bar-lg' >
              <Sidebar tour={props.tour} activeUser={true} count={1} />
            </div>
          </div>
          <div className='col-lg-10 ps-4'>
            <div className={

              'e-inner-small-screen-title d-flex justify-content-between px-2 pb-4'
            }>
              <img src={MintdLogo} className="mt-4" />
              <button
                className='e-menu-btn mt-4'
                onClick={() => {
                  setOpenSideBar(true);
                }}
              >
                <img src={SideBarMenu} />
              </button>
            </div>
            <div
              className={
                openSideBar === true ?
                  'e-small-screen-menu e-menu-over-lay'
                  :
                  'e-small-screen-menu'
              }
              style={{ display: openSideBar && 'block', right: openSideBar && '0' }}
            >
              <Sidebar tour={props.tour} activeUser={true} count={1} id={window.innerWidth <= 992 ? "e-sec-3" : null} closeMenu={() => setOpenSideBar(false)} />
            </div>
            <div className='row'>
              <div className="col-12  mt-0 pt-0">
                <h5 className="e-dashboard-greeting mt-3">
                  Your Assets
                </h5>
                <p className="e-dashboard-sub-title">
                  Some text for asset goes here I'm dummy
                </p>
              </div>
              <div className=' e-card e-asset-graph-card position-relative p-4' id="e-sec-4">
                <div className="row" >

                  {/* target allocation */}
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3 mt-lg-0  mt-md-0">

                    <h5 className="mb-4 ps-0">Target allocation<img src={TooltipIcon} className="e-tooltip-hover ms-2" onMouseEnter={() => setIsShown(true)}
                      onMouseLeave={() => setIsShown(false)} /></h5>
                    {
                      isShown ?
                        <IconStyleToolTip
                          className="e-tooltip"
                          icon={Tooltip}
                          content="The Securities and Exchange Board of India (SEBI), which regulates mutual funds and other securities, requires us to collect this information as part of a mandatory Know Your Client (KYC) process. "
                        />
                        :
                        null
                    }
                    <div className='row mt-3'>
                      <div className='col-lg-5 col-5 e-asset-graph-chart pe-0 ps-2'>
                        <PieChart
                          data={portfolio_data && portfolio_data.graph_data.allocation}
                          colors={backgroundColor}
                          labels={portfolio_data && portfolio_data.graph_data.fund_class}
                          width="310"
                          height="190"
                        />
                      </div>
                      <div className='col-lg-7 col-7 pe-2'>
                        <div className=" e-piechart-label ms-2" >
                          {
                            portfolio_data.graph_data && portfolio_data.graph_data.allocation.map((item, key) => {
                              return (
                                <div className='d-block mb-2 ms-3'>
                                  <span className="mb-0 e-number"><div className="rounded-circle me-2" style={{ backgroundColor: backgroundColor[key], width: '8px', height: '8px', display: 'inline-block' }}></div>{`${portfolio_data.graph_data.allocation[key]}%`}</span>
                                  <span className='e-assets-chart-label' data-bs-toggle="tooltip " data-bs-placement="top" title={portfolio_data.graph_data.fund_class[key]} key={key}>{portfolio_data.graph_data.fund_class[key]}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                  {/* <div className='e-vertical-rule'></div> */}
                  {/* current allocation */}
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3 mt-lg-0  mt-md-0">

                    <h5 className="mb-4 ps-0" >Current allocation<img src={TooltipIcon} className="e-tooltip-hover ms-2" onMouseEnter={() => setIsShownCurrent(true)}
                      onMouseLeave={() => setIsShownCurrent(false)} /></h5>
                    {
                      isShownCurrent ?
                        <IconStyleToolTip
                          className="e-tooltip"
                          icon={Tooltip}
                          content="The Securities and Exchange Board of India (SEBI), which regulates mutual funds and other securities, requires us to collect this information as part of a mandatory Know Your Client (KYC) process. "
                        />
                        :
                        null
                    }
                    <div className='row mt-3'>
                      <div className='col-lg-5 col-5 e-asset-graph-chart pe-0 ps-2'>
                        <PieChart
                          data={invested_data && invested_data.graph_data.allocation}
                          colors={backgroundColor}
                          labels={invested_data && invested_data.graph_data.fund_class}
                          width="310"
                          height="190"
                        />
                      </div>

                      <div className=' col-lg-7 col-7 gx-0'>
                        <div className=" e-piechart-label">
                          {
                            invested_data && invested_data.graph_data.allocation && invested_data && invested_data.graph_data.allocation.map((item, key) => {
                              return (
                                <div className='d-block mb-2 ms-3'>
                                  <span className="mb-0 e-number"><div className="rounded-circle me-2" style={{ backgroundColor: backgroundColor[key], width: '18px', height: '18px', display: 'inline-block' }}></div>{`${invested_data.graph_data.allocation[key]}%`}</span>
                                  <span className='e-assets-chart-label' data-bs-toggle="tooltip " data-bs-placement="top" title={invested_data.graph_data.fund_class[key]} key={key}>{invested_data.graph_data.fund_class[key]}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                  {/* <div className="col-lg-4 col-md-8 e-small-device">
                  <PortfolioSummary
                    type={2}
                    data={investment_summary}

                  />
                </div> */}

                </div>
              </div>

            </div>

            {/* --------------------------- */}
            <span>

              {/* asset class table */}
              <div className="row mb-2">
                <div className='col-lg-9 col-12 mt-4' id="e-sec-5">
                  <div className='e-card p-4'>                   {/* filters */}
                    <div className='e-asset-table-title p-3'>
                      <div className='row'>
                        <div className='col-5'>
                          <h6>
                            Asset class
                          </h6>
                        </div>
                        <div className='col-3'>
                          <h6>
                            Current weight
                          </h6>
                        </div>
                        <div className='col-4'>
                          <h6>
                            Time weighted returns
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="row px-3">
                      <>
                        <div className='e-table-subtitle  text-center py-2 mt-3'>
                          <h6>
                            Indian Equities
                          </h6>
                        </div>


                        <div className="row ms-1 mt-3 e-table-body py-3" id="e-sec">
                          <div className="col-lg-5 col-5  pe-0 ps-1">
                            <p><a href="/tour-scrip-detail?welcome=true" style={{ color: ' #000000', textDecoration: 'none' }}>Indian Large Cap Equities</a></p>
                          </div>
                          <div className="col-lg-4 col-4 text-start e-scan-loader pe-0">

                            <span className="e-value pe-3">20.131%</span>

                          </div>
                          <div className="col-lg-3 col-3   pe-0 ps-5 e-filter-return">
                            <span>+20%</span>
                          </div>
                          {/* <div className="col-lg-1 col-1 pt-2 text-end pt-4 mt-1 ps-2">
                                <img src={Detail} alt="" className="e-detail-arrow" />
                              </div> */}
                        </div>

                        {
                          assetName === false &&
                          <>
                            <div className="row ms-1 mt-3 e-table-body py-3" onClick={() => { navigator('/tour-scrip-detail') }}>
                              <div className="col-lg-5 col-5  mt-2 pe-0 ps-1">
                                <p>Indian Mid Cap Equities</p>
                              </div>
                              <div className="col-lg-4 col-4 e-scan-loader pe-0">

                                <span className="e-value pe-3">20.131%</span>

                              </div>
                              <div className="col-lg-3 col-3  mt-2 pe-0 ps-5 e-filter-return">
                                <span>+20%</span>
                              </div>
                              {/* <div className="col-lg-1 col-1 pt-2 text-end pt-4 mt-1 ps-2">
                                <img src={Detail} alt="" className="e-detail-arrow" />
                              </div> */}
                            </div>

                            <div className="row ms-1 mt-3 e-table-body py-3" onClick={() => { navigator('/tour-scrip-detail') }}>
                              <div className="col-lg-5 col-5 mt-2 pe-0 ps-1">
                                <p>Indian Small Cap Equities</p>
                              </div>
                              <div className="col-lg-4 col-4 e-scan-loader pe-0">
                                <span className="e-value pe-3">15.048%</span>

                              </div>
                              <div className="col-lg-3 col-3 mt-2 pe-0 ps-5 e-filter-return">
                                <span>+20%</span>
                              </div>
                              {/* <div className="col-lg-1 col-1 pt-2 text-end pt-4 mt-1 ps-2">
                                <img src={Detail} alt="" className="e-detail-arrow" />
                              </div> */}
                            </div>
                          </>
                        }
                      </>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12  col-12 mt-4'>
                  <div className="e-card e-asset-portfolio-card p-4">
                    <div className='row'>
                      <div className='col-12'>
                        <h6 className='e-card-title'>
                          Portfolio Summary
                        </h6>
                      </div>
                      <div className='col-12 mt-3'>
                        <label>
                          Current Value
                        </label>
                        <div className='e-current-value'>
                          <span>₹</span> {portfolio_data.investment_summary.current_value}
                        </div>
                      </div>
                      <div className='col-12 mt-3'>
                        <label>
                          Amount Invested
                        </label>
                        <div className='e-value'>
                          <span>₹</span> {portfolio_data.investment_summary.invested_value}
                        </div>
                      </div>
                      <div className='col-12 mt-3'>
                        <PrimaryBtn
                          name="Invest more"

                        />
                      </div>
                      <div className='col-12 mt-3'>
                        <SecondaryBtn
                          name="Withdraw"

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </span>
          </div>
        </div>
        {/* --------------------------- */}

      </div>

      {/* <Footer logo={FooterLogo} playstore={Playstore} appstore={Appstore} /> */}

      <ReactJoyride
        continuous
        showSkipButton
        scrollTo
        callback={handleJoyrideCallback}
        scrollToSteps={true}
        run={run}
        steps={steps}

        styles={{
          options: {
            zIndex: 1000,
            color: "#1329e6",
            arrowColor: 'transparent',
            height: 300,
          },
          buttonNext: {

            background: "#1329e6",
            // borderRadius: "50%",
          },
        }}

      />
      <SkipTour step={tourStep} active={props.active} continue={() => handleClickStart()} />

    </>
  )
}

export default TourAssets;

