/*import packages*/
import React, { useState, useEffect } from 'react';

/*import assets*/
import Overlay from '../../assets/images/loader/overlay.png';

// import style
import style from '../../styles/components/splashScreen.module.scss';


const SplashScreen = () => {
	const [ clicked, setClicked ] = useState(false);

	useEffect(() =>{
			setTimeout(()=>{
				setClicked(true);
			},500)
	 },[])

	return (
		<div className={style.e_animate_wrapper}>
			{
				// clicked === false  ?
				// 	<div className={`container-fluid ${style.e_content_wrapper}`}>
				// 		<div className="row">
				// 			<div className="col-lg-12 text-center ">
				// 			<img src={Overlay} className={style.e_splash_image}/>
				// 			</div>
				// 		</div>
				// 	</div>
				// :
				<div className={`container-fluid ${style.e_content_wrapper}`}>
					<div className="row">
						<div className="col-lg-12 text-center ">
							<img src={Overlay} className={style.e_splash_image}/>
							{
								clicked ?
									<>
										<div className={style.e_sixth_outer}> </div>
										<div className={style.e_fifth_outer}> </div>
										<div className={style.e_fourth_outer}> </div>
										<div className={style.e_third_outer}> </div>
										<div className={style.e_second_outer}> </div>
										<div className={style.e_first_outer}></div>
										<div className={style.e_dots}></div>
									</>
								:
									null
							}
							
						</div>
					</div>
				</div>
			}
	</div>
	)
}


export default SplashScreen;