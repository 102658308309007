/*
 *   File : detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Details
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 04-01-2022
 */

/* import packages */
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import $ from 'jquery';

/* import assets */
import Arrow from '../../assets/images/dataformatting/arrow.svg'
import Remove from '../../assets/images/dataformatting/remove.svg'
import Icon from '../../assets/images/dataformatting/icon.svg'
import Edit from '../../assets/images/overview/edit.svg'
import IndicatorImage from '../../assets/images/profile/carbon_delete.svg';
import ArrowBtn from '../../assets/images/onboard/submit.svg';
import ProfileUploadBtn from '../../assets/images/new-design/edit-2.svg';
import ProfileEdit from '../../assets/images/new-design/edit-profie-data.svg';


/* import components */
import Tooltip from '../../assets/images/tooltip/tooltip.svg';
import { IconStyleToolTipSecond } from '../../components/tooltip';
import { Modal } from 'bootstrap';
import { DeleteScore, EditScore, Email, Phone, EditScoreConfirmation, EditProfile } from '../../components/modal';
import { Toast } from '../../components/note';
import Uploader from '../../components/fileUploader/upload';

import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';

const Details = (props) => {
	const [tooltip, setTooltip] = useState(false)
	const [tooltip1, setTooltip1] = useState(false)
	const wrapperRef = useRef(null);
	const wrapRef = useRef(null);
	const [editScoreModal, setEditScoreModal] = useState(false);
	const [toast, setToast] = useState(null);
	const [profile, setProfile] = useState();
	const [editPhoneModal, setEditPhoneModal] = useState(false);
	const [editEmailModal, setEditEmailModal] = useState(false);
	const [editProfileModal, setEditProfileModal] = useState(false);
	const [profileImage, setProfileImage] = useState(null);
	const [uploading, setUploading ] = useState(false);
	
	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setTooltip(false)
		}
	}
	const closeNotification = (event) => {
		if (wrapRef.current && !wrapRef.current.contains(event.target)) {
			setTooltip1(false)
		}
	}

	useEffect(() => {
		setProfile(props.profile);
		setProfileImage(props.profile.profile_picture);
		window.scrollTo({ top: 0, behaviour: "smooth" })
	}, [props.profile])

	useEffect(() => {
		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	useEffect(() => {
		document.addEventListener('mouseup', closeNotification);
		return () => {
			document.removeEventListener('mouseup', closeNotification);
		};
	}, []);

	const openModal = () => {
		var modal = new Modal(document.getElementById("editScore", {}))
		modal.show()
	}
	const openModal1 = () => {
		var modal = new Modal(document.getElementById("deleteScore", {}))
		modal.show()
	}
	const openPhone = () => {
		var modal = new Modal(document.getElementById("phone", {}))
		modal.show()
	}
	const openEmail = () => {
		var modal = new Modal(document.getElementById("email", {}))
		modal.show()
	}

	const denyDeleteScore = () => {
		let url = devconfig.user + "/profile/reject-delete-riskscore";
		APIGet(url).then((response) => {
			if (response.status_code === 200) {

				$('#approved-edit').trigger('click');
				setToast({ data: 'Edit score  rejected', type: 'success' });
				setEditScoreModal(false);
			} else {
				setToast({ data: response.message, type: 'fail' });
				setEditScoreModal(false);
			}
		})
		setTimeout(() => {
			setToast(null);
		}, 3000)
	}


	const denyEditScore = () => {
		let url = devconfig.user + "/profile/reject-edit-riskscore";
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				let tmp = setProfile;
				tmp.active_editscore_request = false;
				console.log('tmp', tmp);
				setProfile({ ...tmp });
				$('#approved-edit').trigger('click');
				setToast({ data: 'Edit score  rejected', type: 'success' })
				setEditScoreModal(false);
			} else {
				setToast({ data: response.message, type: 'fail' });
				setEditScoreModal(false);
			}
		})
		setTimeout(() => {
			setToast(null);
		}, 3000)
	}

	useEffect(() => {
		if (editScoreModal === true) {
			var modal = new Modal(document.getElementById("approved-edit", {}))
			modal.show()
		}
	}, [editScoreModal])

	useEffect(() => {
		if (editPhoneModal === true) {
			openPhone();
		}
	}, [editPhoneModal])

	useEffect(() => {
		if (editEmailModal === true) {
			openEmail();
		}
	}, [editEmailModal])

	useEffect(() => {
		if (editProfileModal === true) {
			var modal = new Modal(document.getElementById("profile-edit", {}))
			modal.show()
		}
	}, [editProfileModal])

	const _uploadImage = (img) => {
		console.log('img', img);
		let url = devconfig.user+'/profile/update-image';
		let data = JSON.stringify({
			image : img
		})
		APIPost(url,data).then((response) => {
			if ( response.status_code !== 200 ) {
				setToast(response.message);
			}
		})
	}

	return (
		<>
			<div className='e-profile-detail pb-5 mb-5'>
				<div className='col-lg-8 col-md-10 col-sm-12'>
				<div className='row mt-5'>

					{/* Profile Image */}
					<div className='col-12'>
						<div className='e-image-preview text-center'>
							<img src={ProfileUploadBtn}
								className="e-profile-edit-btn"
								onClick={()=> {
									$('#e-upload-profile').trigger('click');
								}}
							/>
							{
								profileImage ? (
									<img src={profileImage} className="e-img"/>
								) :
								(
									<div className='e-placeholder'>
										{
											profile?.first_name && profile?.first_name.charAt(0)

										}
									</div>
								)
							}
							
						</div>
						<Uploader
							tempKey="profile_upload"
							className="hidden"
							id="e-upload-profile"
							uploadSucess={(temp) => {
								_uploadImage(temp);
								setProfileImage(temp)
								setUploading(false);
							}}
							uploadStatus={(temp) =>{  setUploading(temp)}}
						/>
					</div>
					
					<div className='col-12 my-4'>
						<h5 className='e-title'>
							Personal details
						</h5>
					</div>

					<div className='col-lg-6 col-md-6 col-sm-12'>
						<label>First name</label>
						<div className='e-data-container'>
							{profile?.first_name}
						</div>
					</div>	

					<div className='col-lg-6 col-md-6 col-sm-12'>
						<label>Last name</label>
						<div className='e-data-container'>
							{profile?.last_name}
						</div>
					</div>

					<div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
						<label>Marital status</label>
						<div className='e-data-container'>
							{profile?.marital_status}
						</div>
					</div>	

					<div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
						<label>Gender</label>
						<div className='e-data-container'>
							{profile?.gender ? profile.gender : '--'}
						</div>
					</div>	

					<div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
						<label>Date of birth</label>
						<div className='e-data-container'>
							{profile?.dob ? profile.dob_text : '--'}
						</div>
					</div>	

					<div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
						<label>Risk score</label>
						<div className='e-data-container d-flex justify-content-between'>
							{`${profile?.risk_sore}/10`}
							<img 
								src={ProfileEdit}
								style={{cursor: 'pointer'}}
								data-bs-toggle="modal"
								data-bs-target="#editScore"
							/>
						</div>
					</div>	

					<div className='col-lg-10 col-md-10 col-sm-12 mt-2'>
						<label>Mobile number</label>
						<div className='e-credential-wrapper d-flex justify-content-between'>
							{profile?.country_code} {profile?.mobile ? profile?.mobile : profile?.phone_number}
							<img 
								src={ProfileEdit}
								style={{cursor: 'pointer'}}
								onClick={()=> setEditPhoneModal(true)}
							/>
						</div>
					</div>

					<div className='col-lg-10 col-md-10 col-sm-12 mt-2'>
						<label>Email address</label>
						<div className='e-credential-wrapper d-flex justify-content-between'>
							<span data-bs-toggle="tool-tip" title={profile?.email}>{profile?.email}</span>
							<img 
								src={ProfileEdit}
								style={{cursor: 'pointer'}}
								onClick={()=> setEditEmailModal(true)}
							/>
						</div>
					</div>		

				</div>
				</div>
			</div>
			{
				editScoreModal ?
					<EditScoreConfirmation
						modalClose={() => setEditScoreModal(false)}
						type={
							props.profile.active_deletescore_request ?
								'delete'
								:
								'edit'
						}
						rejectEdit={() => {
							if (props.profile.active_deletescore_request === true) denyDeleteScore();
							else denyEditScore()
						}}
					/>
					:
					null
			}
			{
				toast ?
					<Toast data={toast.data} type={toast.type} />
					:
					null
			}
			<EditScore />
			<DeleteScore />
			{
				editPhoneModal ?
					<Phone
						phone={props.profile}
						modalClose={() => setEditPhoneModal(false)}
						success={(temp) => {
							let tmp = profile;
							tmp.mobile = temp;
							console.log('tmp', tmp);
							setProfile({ ...tmp });
						}}
					/>
					:
					null
			}
			{
				editEmailModal ?
					<Email
						email={props.profile.email}
						modalClose={() => setEditEmailModal(false)}
						success={(temp) => {
							let tmp = profile;
							tmp.email = temp;
							console.log('tmp', tmp);
							setProfile({ ...tmp });
						}}
					/>
					:
					null
			}
			{
				editProfileModal ?
					<EditProfile
						data={profile}
						modalClose={() => setEditProfileModal(false)}
						success={(tmp) => {
							setProfile({ ...tmp })
							console.log(tmp)
						}}
					/>
					:
					null
			}


		</>
	);
}


export default Details;