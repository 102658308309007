import { useState, useEffect } from 'react';

//Asset import
import Arrow from '../../assets/images/new-design/divider.svg';
import Inprogress from '../../assets/images/new-design/in-progress.svg';
import Completed from '../../assets/images/new-design/correct.svg';
import PortfolioIcon from '../../assets/images/new-design/portfolio.svg';
import KYCIcon from '../../assets/images/new-design/kyc.svg';
import Invest from '../../assets/images/new-design/invest.svg';


// Import containers
import Questionnaire from './questionnaire';
import Portfolio from '../portfolio/portfolio';
import KYCandDetails from './kycAndDetails.js'
import GetURLVar from '../../services/getUrl';
import LeadSquared from '../../services/leadSquredServices';

const SIGNUP_STATUS = [
    {
        "status": "PERSONAL_DATA_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "ADDRESS_DATA_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "INCOME_DATA_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "AMC_KYC_REJECTED",
        "step": 3,
        "completed": false
    }, {
        "status": "BANKACCOUNT_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "NOMINEE_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "AMC_KYC_INPROGRESS",
        "step": 3,
        "completed": false
    }, {
        "status": "ESIGN_STARTED",
        "step": 3,
        "completed": false
    }, {
        "status": "ESIGN_COMPLETED",
        "step": 4,
        "completed": true
    }, {
        "status": "AMC_KYC_COMPLETED",
        "step": 4,
        "completed": true
    },
    {
        "status": "AMC_KYC_VERIFIED",
        "step": 3,
        "completed": true
    }, {
        "status": "READY_TO_INVEST",
        "step": 4,
        "completed": true
    }, {
        "status": "QUESTIONNAIRE_STARTED",
        "step": 1,
        "completed": false
    }, {
        "status": "QUESTIONNAIRE_COMPLETED",
        "step": 2,
        "completed": false
    }, {
        "status": "RISK_SCORE_CALCULATED",
        "step": 2,
        "completed": false
    }, {
        "status": "RISKSCORE_SKIPPED",
        "step": 2,
        "completed": false
    }, {
        "status": "QUESTIONNAIRE_RESUBMIT_COMPLETED",
        "step": 2,
        "completed": false
    }, {
        "status": "QUESTIONNAIRE_RESUBMIT_STARTED",
        "step": 1,
        "completed": false
    },
    {
        "status": 'PORTFOLIO_CONFIGURED',
        "step": 3,
        "completed": true
    },
    {
        "status": "PASSWORD_CONFIGURED",
        "step": 1,
        "completed": false
    },
    {
        "status": "AUTH_CONFIGURED",
        "step": 1,
        "completed": false
    },
    {
        "status": "OTP_REQUESTED",
        "step": 1,
        "completed": false
    },
    {
        "status": "INVESTED",
        "step": 4,
        "completed": true
    }

]

const Onboard = (props) => {
    const [currentProgress, setCurrentProgress] = useState(1);
    const [onboardProgress, setOnboardProgress] = useState(0);
    const [editFlow, setEditFlow] = useState(false);
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        GetURLVar().then((query) => {
            if (query.edit) {
                if (query.edit === 'risk-analysis') {
                    setCurrentProgress(1)
                } else {
                    setCurrentProgress(3)
                }
                setEditFlow(true);
            }
        })
    }, [])

    useEffect(() => {
        if (props.userDetail) {
            let status_index = SIGNUP_STATUS.findIndex((item) => item.status === props.userDetail.signup_status);
            let progress = 40;

            switch (SIGNUP_STATUS[status_index].step) {
                case 1: progress = 0;
                    break;
                case 2: progress = 40;
                    break;
                case 3: progress = 60;
                    break;
                case 4: if (window.innerWidth > 992) progress = 80; else progress = 100;
                    break;
                default: progress = 0;
            }
            let step = SIGNUP_STATUS[status_index].step;
            if (step === 3 && props.userDetail?.is_kyc_complaint === true && props.userDetail?.signup_status === 'NOMINEE_ADDED') {
                step = 4;
                if (window.innerWidth > 992) progress = 80; else progress = 100;
            }
            console.log('step', step)
            if (editFlow === false) {
                setCurrentProgress(step);
            }

            setOnboardProgress(progress);
        }
    }, [props.userDetail])


    useEffect(() => {
        if (props.profile) {
            setProfileData(props.profile);
        }
    }, [props.profile])

    //update screen after edit quiz or personal details
    const _updateScreen = () => {

        if (props.userDetail) {
            let status_index = SIGNUP_STATUS.findIndex((item) => item.status === props.userDetail.signup_status);
            let progress = 40;

            switch (SIGNUP_STATUS[status_index].step) {
                case 1: progress = 0;
                    break;
                case 2: progress = 40;
                    break;
                case 3: progress = 60;
                    break;
                case 4: if (window.innerWidth > 992) progress = 80; else progress = 100;
                    break;
                default: progress = 0;
            }
            let step = SIGNUP_STATUS[status_index].step;
            if (step === 3 && props.userDetail?.is_kyc_complaint === true && props.userDetail?.signup_status === 'NOMINEE_ADDED') {
                step = 4;
                if (window.innerWidth > 992) progress = 80; else progress = 100;
            }
            console.log('step', step)
            setCurrentProgress(step);
            setOnboardProgress(progress);
        }
    }

    return (
        <div className="e-onboard">
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12 e-onboard-progress-section p-4 pt-2">
                    <div className="e-breadcrumb-wrapper mt-4">
                        <span className="e-previous">Dashboard</span>
                        <span className="e-divider mx-2"><img src={Arrow} /></span>
                        <span className="e-current">
                            {
                                currentProgress === 1 ?
                                    'Risk Assessment'
                                    :
                                    'See your portfolio'
                            }
                        </span>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-2'>
                            <div className='e-onboard-progress-wrapper'>
                                <div className='e-inprogress-dashed'>
                                    <div className='e-progress-indicator' style={{ height: `${onboardProgress}%` }}></div>
                                    <div className='e-img-wrapper'>
                                        <img
                                            src={
                                                currentProgress > 1 ?
                                                    Completed
                                                    :
                                                    Inprogress
                                            }
                                        />
                                    </div>
                                    <div className='e-img-wrapper'>
                                        <img
                                            src={
                                                currentProgress < 2 ?
                                                    PortfolioIcon
                                                    :
                                                    currentProgress === 2 ?
                                                        Inprogress
                                                        :
                                                        Completed
                                            }
                                        />
                                    </div>
                                    <div className='e-img-wrapper'>
                                        <img
                                            src={
                                                currentProgress < 3 ?
                                                    KYCIcon
                                                    :
                                                    currentProgress === 3 ?
                                                        Inprogress
                                                        :
                                                        Completed
                                            }

                                        />
                                    </div>
                                    <div className='e-img-wrapper'>
                                        <img
                                            src={
                                                currentProgress < 4 ?
                                                    Invest
                                                    :
                                                    currentProgress === 4 ?
                                                        Inprogress
                                                        :
                                                        Completed
                                            }

                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-10'>
                            <div className='e-progress-label-wrapper'>
                                <div className='e-progress-label mt-2'>
                                    <p>STEP 01</p>
                                    <h5>Risk Assessment</h5>
                                </div>
                                <div className='e-progress-label'>
                                    <p>STEP 02</p>
                                    <h5>See your portfolio</h5>
                                </div>
                                <div className='e-progress-label'>
                                    <p>STEP 03</p>
                                    <h5>Personal details and KYC</h5>
                                </div>
                                <div className='e-progress-label'>
                                    <p>STEP 04</p>
                                    <h5>Invest</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* For small screen */}

                <div className="col-12 e-onboard-progress-section-small-screen p-4 pt-lg-4 pt-xs-0 pt-0">
                    <div className="e-breadcrumb-wrapper mt-lg-4 mt-xs-0 mt-0">
                        <span className="e-previous">Dashboard</span>
                        <span className="e-divider mx-2"><img src={Arrow} /></span>
                        <span className="e-current">
                            {
                                currentProgress === 1 ?
                                    'Risk Assessment'
                                    :
                                    'See your portfolio'
                            }
                        </span>
                    </div>
                    <div className='e-small-screen-progress-wrapper'>
                        <div className='e-onboard-progress-wrapper'>
                            <div className='e-inprogress-dashed'>
                                <div className='e-progress-indicator' style={{ width: `${onboardProgress}%` }}></div>
                                <div className='e-img-wrapper'>
                                    <img
                                        src={
                                            currentProgress > 1 ?
                                                Completed
                                                :
                                                Inprogress
                                        }
                                    />
                                </div>
                                <div className='e-img-wrapper'>
                                    <img
                                        src={
                                            currentProgress < 2 ?
                                                PortfolioIcon
                                                :
                                                currentProgress === 2 ?
                                                    Inprogress
                                                    :
                                                    Completed
                                        }
                                    />
                                </div>
                                <div className='e-img-wrapper'>
                                    <img
                                        src={
                                            currentProgress < 3 ?
                                                KYCIcon
                                                :
                                                currentProgress === 3 ?
                                                    Inprogress
                                                    :
                                                    Completed
                                        }

                                    />
                                </div>
                                <div className='e-img-wrapper'>
                                    <img
                                        src={
                                            currentProgress < 4 ?
                                                Invest
                                                :
                                                currentProgress === 4 ?
                                                    Inprogress
                                                    :
                                                    Completed
                                        }

                                    />
                                </div>

                            </div>
                        </div>
                        <div className='e-progress-label-wrapper '>
                            <div className='e-progress-label '>
                                <p>STEP 01</p>
                                <h5>Risk Assessment</h5>
                            </div>
                            <div className='e-progress-label '>
                                <p>STEP 02</p>
                                <h5>See your portfolio</h5>
                            </div>
                            <div className='e-progress-label '>
                                <p>STEP 03</p>
                                <h5>Personal details and KYC</h5>
                            </div>
                            <div className='e-progress-label '>
                                <p>STEP 04</p>
                                <h5>Invest</h5>
                            </div>
                        </div>
                    </div>

                </div>

                {/* Ends */}


                <div className="col-lg-9 col-md-12 col-sm-12 gx-lg-0">
                    {
                        currentProgress === 1 && (
                            <Questionnaire
                                success={() => {
                                    if (editFlow) {
                                        _updateScreen();
                                    } else {
                                        setCurrentProgress(2);
                                        setTimeout(() => {
                                            setOnboardProgress(40);
                                        }, 500)
                                        LeadSquared._activityTracker('Risk Score Drop-off mail', 209);
                                    }

                                }}
                            />
                        )
                    }
                    {
                        currentProgress === 2 && (
                            <Portfolio
                                profile={profileData}
                                success={() => {
                                    if (editFlow === true) {
                                        _updateScreen();
                                    } else {
                                        setCurrentProgress(3);
                                        setTimeout(() => {
                                            setOnboardProgress(60);
                                        }, 500)
                                    }

                                }}
                            />
                        )
                    }

                    {
                        currentProgress === 3 && (
                            <KYCandDetails
                                signupData={props.userDetail}
                                success={() => {
                                    if (editFlow === true) {
                                        _updateScreen()
                                    } else {
                                        setCurrentProgress(4);
                                        setTimeout(() => {
                                            if (window.innerWidth > 992) {
                                                setOnboardProgress(80);
                                            } else {
                                                setOnboardProgress(100);
                                            }

                                        }, 500)
                                    }
                                }}
                            />
                        )
                    }

                    {
                        currentProgress === 4 && (
                            <Portfolio
                                profile={profileData}
                                success={() => {

                                }}
                            />
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default Onboard;