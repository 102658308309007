/*
 *   File : singleDate.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 16-12-2021
 */

/*import package*/
import { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import $ from 'jquery';
/*import style*/
import styles from '../../styles/components/singleDate.module.scss';
import jquery from 'jquery';

// import Icon from '../../assets/images/input/calendar.svg';

const SingleDate = (props) => {
  var date = new Date("1992-06-24");
  const [selectedData, setSelectedDate] = useState(null);

  const handleChange = (date, dateString) => {
    if (date) {
      var tempDate = moment(date, 'DD-MM-YYYY');
      setSelectedDate(tempDate);
      props.onChange(tempDate.format('YYYY-MM-DD'));
    }
    else {
      setSelectedDate(null);
      props.onChange(null);
    }
  }

  useEffect(() => {
    if (props.id) {
      let input = document.getElementById(props.id);

      input.addEventListener('keyup', handleKeyDown);

      // cleanup this component
      return () => {
        input.removeEventListener('keyup', handleKeyDown);
      };
    }

  }, []);

  // append hyphen after day and month 
  const handleKeyDown = (e) => {
    if (e.keyCode !== 8) {
      let input = document.getElementById(props.id);
      // console.log('lng', input.value.length)
      if (input.value.length === 2 || input.value.length === 5) {
        let finalVal = `${input.value}-`;
        console.log(finalVal);
        input.value = finalVal;
      }
    }
  }

  useEffect(() => {
    if (props.value) {
      var tempDate;
      if (props.converted) { 
        tempDate = moment(props.value, 'DD-MM-YYYY');
      }else{
        tempDate = moment(props.value, 'YYYY-MM-DD');
      }
      
      console.log('props.value', props.value)
      console.log('temp date', tempDate)
      setSelectedDate(tempDate)
    } else {
      setSelectedDate(null)
    }
  }, [props.value])


  return (
    <div className={selectedData !== null ? "e-single-date-value" : "e-single-date"}>
      <DatePicker onChange={handleChange}
        value={selectedData}
        placeholder={props.placeholder}
        format={"DD-MM-YYYY"}
        name={props.name}
        id={props.id}
        disabledDate={props.disabledDate}
        defaultPickerValue={props.isDecadeBack && !selectedData ? moment().subtract(18, "years") : null}
        showToday={props.removeToday ? false : true}
      />
    </div>
  );
}

export default SingleDate;