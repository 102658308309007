
//Package import 
import { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import $ from 'jquery';

//Asset import 
import Tradebook from '../../assets/images/tradebook.svg';
import UploadIcon from '../../assets/images/new-design/upload.svg';
import FileIcon from '../../assets/images/fileIcon.svg';
import Smallcase from '../../assets/images/new-design/smallcase.svg';
import Upstocks from '../../assets/images/new-design/broker.svg';
import Arrow from '../../assets/images/PrimaryArrow.svg';
import Close from '../../assets/images/modal/close.svg';
import Trash from '../../assets/images/onboard/trash-can.png';
import s3Upload from '../../components/fileUploader/awsHelper';
import SpinnerMinus from '../../assets/images/new-design/spinner-minus.svg';
import SpinnerPlus from '../../assets/images/new-design/spinner-plus.svg';
import Zerodha from '../../assets/images/new-design/kite.svg';
//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { RadioButton, Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput';
import Uploader from '../../components/fileUploader/upload';
import { Toast } from '../../components/note';

//service import 
import APIPost from '../../services/postApi';
import Config from '../../config/config';

import { UserDataContext } from '../../context/userData';

const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]



const MutualFundsDetail = (props) => {
    const [accordionOpened, setAccordionOpened] = useState(false);
    const [showOtherOptions, setShowOtherOptions] = useState(false);
    const [manual, setManual] = useState(false);
    const [loader, setLoader] = useState(false);
    const [mfType, setMfType] = useState(null);
    const [name, setName] = useState(null);
    const [date, setDate] = useState(null);
    const [quantity, setQuanity] = useState(null);
    const [NAV, setNAV] = useState(null);
    const [amount, setAmount] = useState(null);
    const [password, setPassword] = useState(null);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [searchDetail, setSearchDetail] = useState([]);
    const [openSearchPanel, setOpenSearchPanel] = useState(null);
    const [selectedFund, setSelectedFund] = useState(null);
    const [toast, setToast] = useState(null)
    const wrapperRef = useRef(null);
    const [syncing, setSyncing] = useState(false);
    const [viewMore, setViewMore] = useState(false);
    const [apiLoader, setApiLoader] = useState(false)
    const [type, setType] = useState("CONSOLIDATED")

    const [mfList, setMfList] = useState([
        {
            // mf_id: null,
            mutual_fund_name: null,
            isin: null,
            type_id: 7,
            purchase_date: null,
            quantity: null,
            nav: null,
            // net_amount: null
        }
    ]);
    const [userData, setUserData] = useContext(UserDataContext);

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null)
            }, 3000)
        }
    }, [toast])

    useEffect(() => {
        if (sessionStorage.getItem('kite_access_token')) {
            setShowOtherOptions(true)
        }
    }, [])


    const _addMutualFund = () => {
        console.log('data', mfList);
        let url = Config.portfolio + '/assets/mf/add';
        let payload = JSON.stringify({
            data: mfList
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                _eventTracker();
                setLoader(false);
                props.success(null)

            }
            else {
                // setManual(false);
                setLoader(false);
                setToast(response.message);
            }
        })
    }

    const _importFile = () => {
        setApiLoader(true)
        let url = Config.portfolio + '/assets/mf/import-from-file';
        let payload = JSON.stringify({
            url: file,
            password: password,
            file_type: type
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                _eventTracker();
                setApiLoader(false)
                console.log('response', response.data);
                props.success(response.data, response.error)

            } else {
                setApiLoader(false)
                setToast(response.message);
            }
        })
    }

    useEffect(() => {
        if (searchDetail) {
            console.log('searchDetail', searchDetail);
        }
    }, [searchDetail])

    const _mfSearch = (text) => {
        let url = Config.portfolio + '/assets/general/mf-search';
        let payload = JSON.stringify({
            search_text: text
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {

                if (openSearchPanel !== null) {
                    // let array = searchDetail;
                    // for (let index = 0; index < response.data.length; index++) {
                    //     const element = response.data[index];
                    //     const found = array.some(el => el.scheme_code === element.scheme_code);
                    //     if ( !found) {
                    //         array.push(element)
                    //     }

                    // }
                    setSearchDetail([...response.data]);
                } else {
                    console.log('inside else');
                    setSearchDetail(response.data);
                }

            }
        })
    }

    const _kiteLogin = () => {
        window.location.href = Config.KiteLoginUrl;
    }

    const _formValidation = () => {
        let valid = true;
        if (!mfList.length) {
            valid = false;
            console.log('in side if')
        }
        else {
            console.log('in side else')
            mfList.forEach(function (v, i) {
                if (
                    Object.keys(v).some(function (k) {
                        console.log('each data', v[k])
                        return (v[k] == null || v[k] == '');
                    })
                ) {
                    console.log('null value present', i);
                    valid = false;
                }
                else {
                    console.log('data right', i);
                    valid = true;
                }
            });
        }
        return valid;
    }

    const _uploadFile = async (file) => {
        var file = file[0];
        var tempFileName = file.name;
        var fileReader = new FileReader();
        tempFileName = tempFileName.replaceAll(' ', '_');
        var tempKey = 'mutual-fund-file';
        tempKey = tempKey + "/" + new Date().getTime()
        var fileType = tempFileName.split("/").pop();
        if (fileType.split('.')[1] == 'pdf') {
            var response = await s3Upload(tempFileName, tempKey, file, fileType);
            setFile(response.cdn_url);
            setFileName(tempFileName)
        } else {
            setToast('Unsupported file type')
        }
    }

    const _fileDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let fileInput = document.getElementById('e-excel-upload');
        const temp = e.target.files || e.dataTransfer.files;
        // const fileList = Object.keys(files).map(file => files[file]);
        _uploadFile(temp)
    }

    const hideDropDown = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpenSearchPanel(null)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', hideDropDown);
        return () => {
            document.removeEventListener('mouseup', hideDropDown);
        };
    }, []);


    const _importData = () => {
        setSyncing(true);
        let url = Config.portfolio + '/assets/mf/import-from-api';
        let payload = JSON.stringify({
            access_token: sessionStorage.getItem('kite_access_token'),
            api: 'ZERODHA'
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setSyncing(false);
                props.success(response.data)
            }
            else {
                setSyncing(false);
                setToast(response.message);
            }
        })
    }

    const _eventTracker = () => {
        window.analytics.track('Portfolio tracker configured', {
            user_id: userData?.user_id,
            stocks: false,
            MF: true,
            Gold: false,
            RealEstate: false,
            FD: false,
            Bond: false,
            crypto: false
        });
    }



    return (
        <div className={`row mt-4 ${styles.e_stocks} e_stocks`}>

            {
                manual === true ? ( // Form for add asset details manually
                    <>
                        <div className='col-12 '>
                            <h5 className={`${styles.e_form_name}`}>
                                /Adding manually
                            </h5>
                        </div>
                        {
                            mfList.map((item, key) => {
                                return (
                                    <div className='col-12 mt-3 position-relative' key={key}>
                                        <div className={key === 0 ? 'row ' : 'row mt-3'}>
                                            <div className='col-8'>
                                                <h6 className={styles.e_asset_number}>Mutual Fund - {key + 1}</h6>
                                            </div>
                                            <div className='col-4 text-end'>
                                                {
                                                    key !== 0 && (
                                                        <img
                                                            src={Trash}
                                                            className={`${styles.e_form_trash}`}
                                                            onClick={() => {
                                                                let array = mfList;
                                                                array.splice(key, 1);
                                                                setMfList([...array]);
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className='row '>
                                            <div className={key === 0 ? 'col-12 ' : 'col-12'}>
                                                <label>
                                                    Mutual fund name
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <Input
                                                    value={item.mutual_fund_name}
                                                    placeholder="Search mutual fund"
                                                    needFocusEvent={true}
                                                    className={`${styles.e_search}`}
                                                    focusIn={() => {
                                                        setOpenSearchPanel(key);
                                                        let array = mfList;
                                                        array[key].mutual_fund_name = '';
                                                        setMfList([...array]);
                                                    }}
                                                    // needBlurEvent={true}
                                                    // focusOut={()=>{ setOpenSearchPanel(false)}}
                                                    valueChange={(e) => {
                                                        let array = mfList;
                                                        array[key].mutual_fund_name = e;
                                                        setMfList([...array]);
                                                        if (e) { _mfSearch(e) }
                                                    }}
                                                />
                                                {
                                                    openSearchPanel === key && searchDetail.length ? (
                                                        <div className='e-mf-search-panel' ref={wrapperRef} >
                                                            {
                                                                searchDetail && searchDetail.map((mf, i) => {
                                                                    return (
                                                                        <div className='row border-bottom py-2' key={key}>
                                                                            <div className={i === 0 ? 'col-lg-9 col-md-9 col-sm-8 col-8' : 'col-lg-9 col-md-9 col-sm-8 col-8 mt-2'}>
                                                                                {mf.scheme_name}
                                                                            </div>
                                                                            <div className='col-lg-3 col-md-3 col-sm-4 col-4 '>
                                                                                <SecondaryBtn
                                                                                    name="Add"
                                                                                    className="e-mf-add-btn d-flex justify-content-center"
                                                                                    handleClick={() => {
                                                                                        setSelectedFund(mf);
                                                                                        let array = mfList;
                                                                                        array[key].mutual_fund_name = mf.scheme_name;
                                                                                        array[key].scheme_code = mf.scheme_code;
                                                                                        array[key].isin = mf.isin_dividend_payout_or_growth;
                                                                                        setMfList([...array]);
                                                                                        setOpenSearchPanel(null);
                                                                                        setSearchDetail([])
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                        : null
                                                }
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4 pt-2'>
                                                <label>
                                                    Purchase date
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <SingleDate
                                                    placeholder="DD-MM-YYYY"
                                                    removeToday={true}
                                                    disabledDate={(day => {
                                                        if (day.isAfter(moment())) {
                                                            return true
                                                        }
                                                    })}
                                                    onChange={(e) => {
                                                        let array = mfList;
                                                        var tempDate = moment(e, 'YYYY-MM-DD');
                                                        array[key].purchase_date = tempDate.format('DD-MM-YYYY');
                                                        setMfList([...array]);

                                                    }}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4 pt-2'>
                                                <label className={`${styles.e_form_title}`}>
                                                    Quantity
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <div className='w-50 position-relative'>
                                                    <Input
                                                        needOnInput={true}
                                                        onInput={(e) => { e.target.value = Math.abs(e.target.value) }}
                                                        label="Quantity"
                                                        value={item.quantity}
                                                        className={` ${styles.e_spinner_input}`}
                                                        type="number"
                                                        valueChange={(e) => {
                                                            let array = mfList;
                                                            array[key].quantity = e;
                                                            setMfList([...array]);
                                                        }}
                                                    />
                                                    <img src={SpinnerMinus}
                                                        className={`${styles.e_spinner_minus}`}
                                                        onClick={() => {
                                                            let array = mfList;
                                                            if (array[key].quantity > 0) {
                                                                array[key].quantity = array[key].quantity - 1;
                                                                setMfList([...array]);
                                                            }
                                                        }}
                                                    />
                                                    <img
                                                        src={SpinnerPlus}
                                                        className={`${styles.e_spinner_plus}`}
                                                        onClick={() => {
                                                            let array = mfList;
                                                            array[key].quantity = array[key].quantity + 1;
                                                            setMfList([...array]);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                                <label>
                                                    Purchase NAV
                                                    <span className='star px-1'> * </span>
                                                </label>
                                                <Input
                                                    label="NAV"
                                                    value={item.nav}
                                                    type="number"
                                                    valueChange={(e) => {
                                                        let array = mfList;
                                                        array[key].nav = e;
                                                        setMfList([...array]);
                                                    }}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                                <label>
                                                    Net amount
                                                </label>
                                                <div className='position-relative'>
                                                    <Input
                                                        label="Net amount"
                                                        value={item.quantity * item.nav}
                                                        type="number"
                                                        className={styles.e_price_input + " e-portfolio-disable-input"}
                                                        valueChange={(e) => {
                                                            // setAmount(e)
                                                            let array = mfList;
                                                            array[key].net_amount = e;
                                                            setMfList([...array]);
                                                        }}
                                                    />
                                                    <span className={`${styles.e_rupee} e-rupee`}>₹</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }


                        <div className='col-12 mt-4'>
                            <PrimaryLink
                                name="Add more"
                                className={` ${styles.e_link_btn}`}
                                handleClick={() => {
                                    let array = mfList;
                                    array.push(
                                        {
                                            mutual_fund_name: null,
                                            isin: null,
                                            type_id: 7,
                                            purchase_date: null,
                                            quantity: null,
                                            nav: null,

                                        }
                                    )
                                    setMfList([...array])
                                }}
                            />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                            <SecondaryBtn
                                name="Cancel"
                                className="me-3 w-100 e-portfolio-btn-add"
                                cancel="modal"
                            />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                            {
                                _formValidation() ? (
                                    <PrimaryBtn
                                        name="Add"
                                        className={loader ? 'e-btn-loader w-100 e-portfolio-btn-add' : 'w-100 e-portfolio-btn-add'}
                                        handleClick={() =>
                                            _addMutualFund()
                                        }
                                    />
                                )
                                    : (
                                        <PrimaryBtn
                                            name="Add"
                                            disabled={true}
                                            className={loader ? 'w-100 e-btn-loader e-portfolio-btn-add' : 'w-100 e-portfolio-btn-add'}
                                            handleClick={() =>
                                                _addMutualFund()
                                            }
                                        />
                                    )

                            }

                        </div>
                    </>
                )

                    :

                    ( // Upload asset details 
                        <>

                            <div className='col-12'>

                                {/* <div className='col-lg-12 col-md-12 col-sm-12 position-relative'>
                                    <h6 className={styles.e_card_title + ' mb-2'}>
                                        Import your holdings below from 9+ Brokers
                                    </h6>
                                </div>
                                <div className='text-center mt-3'>
                                    <SecondaryBtn
                                        name="Import holdings"
                                        className="e-add-more"
                                        left={true}
                                        // image={Smallcase}
                                        iconClass="me-2"
                                        handleClick={() => {
                                            props.getSmallCaseData()
                                        }} />
                                </div> */}

                                <h6 className={`${styles.e_card_title} mb-2`}>Upload your CAS report</h6>
                                <div
                                    className={`${styles.e_file_uploader} text-center p-3`}
                                    onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
                                    onDragLeave={(e) => { e.stopPropagation(); e.preventDefault(); }}
                                    onDrop={(e) => _fileDrop(e)}
                                >
                                    <p className='mt-2'>Drag and drop the files <br />or</p>

                                    <button
                                        onClick={() => {
                                            $('#e-excel-upload').trigger('click');
                                        }}
                                        className="mb-1"
                                    >
                                        <img src={UploadIcon} className="me-2" />
                                        Click to upload
                                    </button>
                                    <span className='mb-2'>(supported formats PDF, csv, sls max-size <br />5mb)</span>
                                </div>
                                <Uploader
                                    tempKey="files"
                                    className="hidden"
                                    id="e-excel-upload"
                                    uploadSucess={(temp, file) => {
                                        console.log('temp', temp, 'file', file)
                                        setFile(temp);
                                        setFileName(file.name)
                                    }}
                                    isDragable={true}
                                    excel={true}
                                    accept=".pdf"
                                    uploadStatus={(temp) => { }}
                                />
                            </div>
                            {
                                file && (
                                    <div className='col-12 mt-3'>
                                        <div>
                                            <h6 className='e-file-preview-title '>Uploaded successfully</h6>
                                            <div className="e-boarding-file-preview">
                                                <p className="p-2">{fileName}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                file && (
                                    <div className='row'>

                                        <div className='col-lg-4 mt-2'>

                                            <RadioButton id={1}
                                                label="CONSOLIDATED"
                                                checked={type == "CONSOLIDATED" ? true : false}
                                                wrapperClass="e-edit-profile-radio "
                                                onClick={(e) => {
                                                    setType("CONSOLIDATED")
                                                }}
                                            />
                                        </div>
                                        <div className='col-lg-4 mt-2'>
                                            <RadioButton
                                                label="TRANSACTIONAL"
                                                checked={type == "TRANSACTIONAL" ? true : false}
                                                wrapperClass="e-edit-profile-radio "
                                                onClick={(e) => {
                                                    setType("TRANSACTIONAL")
                                                }}
                                            />
                                        </div>

                                        <div className='col-8 mt-3'>

                                            <label>Password</label>
                                            <Input
                                                value={password}
                                                type="password"
                                                valueChange={(e) => setPassword(e)}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div className='col-12 mt-3'>
                                <div className='accordion e-portfolio-accordion mb-3' id="parent">
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header  px-2 pb-3 pt-2 e_accordion_header"
                                            id="headingOne"
                                            style={{ marginBlockEnd: accordionOpened && '-17px' }}
                                        >
                                            <button
                                                className={accordionOpened ? "e-accordion-btn text-start mt-2" : "e-accordion-btn text-start mt-2"}
                                                type="button"
                                                aria-expanded={accordionOpened === true ? 'true' : "false"}
                                                onClick={() =>
                                                    setAccordionOpened(!accordionOpened)
                                                }
                                            >
                                                {/* <img src={Tradebook} className="me-2 "/> */}
                                                How to get my report?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseOne"
                                            className={accordionOpened === true ? "accordion-collapse collapse  show" : "accordion-collapse collapse"}
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#parent"
                                        >
                                            <div className='accordion-body pt-0'>
                                                <div className='row'>
                                                    <div className='col-lg-12 '>
                                                        <ul className={`${styles.e_instruction_ul} w-100`}>
                                                            <li className='e-break'>
                                                                Go to
                                                                <a
                                                                    style={{ overFlow: 'hidden', paddingLeft: '2px' }}
                                                                    href="https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement"
                                                                    target="_blank"
                                                                >
                                                                    https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement
                                                                </a>
                                                            </li>
                                                            <li>
                                                                Click on “Detailed” option and select "With zero balance folios".
                                                            </li>
                                                            <li>
                                                                Choose the dates from your first investment till current date. Incase you don’t
                                                                remember We advice to choose as early as 5 years for the from current date.
                                                            </li>
                                                            <li>Enter your email and select a password. Make sure to note the password down.</li>
                                                            <li>Click "Submit".</li>
                                                            <li className='e-break'>
                                                                You will receive an email from <a href="donotreply@camsonline.com" target="_blank">donotreply@camsonline.com</a> within a  <br /> few
                                                                minutes. Upload the same below with the password.
                                                            </li>
                                                            <li className='e-break'>
                                                                Alternatively You can view the instructions on scribe here :
                                                                <br />
                                                                <a href='https://scribehow.com/shared/Camsonline_Workflow__F6hz1P8sTDyAL5STKft3sg' target="_blank" style={{ paddingLeft: '5 px' }}>
                                                                    https://scribehow.com/shared/Camsonline_Workflow__F6hz1P8sTDyAL5STKft3sg
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 text-center mt-3'>
                                <PrimaryLink
                                    name={!viewMore ? "View other options" : 'Hide other options'}
                                    className={`${styles.e_more_btn}`}
                                    handleClick={() => setViewMore(!viewMore)}
                                />
                            </div>


                            <div className='col-12 mt-3'>
                                <div className='row'>

                                    <div className='col-12 '>
                                        <div className={`row ${styles.e_other_card} `} style={{ height: viewMore ? window.innerWidth > 765 ? 'auto' : 'auto' : '0px' }}>
                                            {
                                                sessionStorage.getItem('kite_access_token') ? (
                                                    <div className='col-lg-6 col-md-6 col-sm-12 position-relative'>
                                                        <h6 className='mb-4'>
                                                            Sync your Zerodha holdings
                                                        </h6>
                                                        <PrimaryBtn
                                                            name={syncing ? "Syncing" : 'Sync'}
                                                            className={syncing ? "e-btn-loader w-100 e-add-asset-modal-btn" : 'w-100 e-add-asset-modal-btn'}
                                                            handleClick={() => _importData()}
                                                        />
                                                        <div className={`${styles.e_divider}`}></div>
                                                    </div>
                                                ) : (
                                                    <div className='col-lg-6 col-md-6 col-sm-12 position-relative'>
                                                        <h6 className='mb-4'>
                                                            Connect with your broker
                                                        </h6>
                                                        <div
                                                            id="e-upstocks"
                                                            className={`${styles.e_upcoming_broker}`}
                                                        >
                                                            <img
                                                                src={Zerodha}
                                                                className={`me-3`}
                                                                style={{ cursor: 'pointer' }}
                                                                // onMouseEnter={() => {
                                                                //     $(`#e-upstocks`).addClass('e-hover-popup');
                                                                // }}
                                                                // onMouseLeave={() => {
                                                                //     $(`#e-upstocks`).removeClass('e-hover-popup');
                                                                // }}
                                                                onClick={() => _kiteLogin()}
                                                            />
                                                        </div>
                                                        <div
                                                            id="e-upcoming"
                                                            className={`${styles.e_upcoming_broker}`}
                                                        >
                                                            <img src={Upstocks}
                                                                onMouseEnter={() => {
                                                                    $(`#e-upcoming`).addClass('e-hover-popup');
                                                                }}
                                                                onMouseLeave={() => {
                                                                    $(`#e-upcoming`).removeClass('e-hover-popup');
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={`${styles.e_divider}`}></div>
                                                    </div>
                                                )

                                            }
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-3 ps-3 ps-0' >
                                                <h6 className='mb-4 ms-lg-3 ms-md-3 ms-0'>
                                                    Add mutual fund details
                                                </h6>
                                                <PrimaryLink
                                                    name="Add manually"
                                                    className={`${styles.e_link_btn} ms-lg-3 ms-md-3 ms-0`}
                                                    handleClick={() => setManual(true)}
                                                />
                                            </div>

                                            {/* {
                                                file && (
                                                    <div className='col-12 mt-3'>
                                                        <div>
                                                            <h6 className='e-file-preview-title '>Uploaded successfully</h6>
                                                            <div className="e-boarding-file-preview">
                                                                <p className="p-2">{fileName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } */}

                                            {/* {
                                                file && (
                                                    <div className='row'>

                                                        <div className='col-lg-4 mt-2'>

                                                            <RadioButton id={1}
                                                                label="CONSOLIDATED"
                                                                checked={type == "CONSOLIDATED" ? true : false}
                                                                wrapperClass="e-edit-profile-radio "
                                                                onClick={(e) => {
                                                                    setType("CONSOLIDATED")
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='col-lg-4 mt-2'>
                                                            <RadioButton
                                                                label="TRANSACTIONAL"
                                                                checked={type == "TRANSACTIONAL" ? true : false}
                                                                wrapperClass="e-edit-profile-radio "
                                                                onClick={(e) => {
                                                                    setType("TRANSACTIONAL")
                                                                }}
                                                            />
                                                        </div>

                                                        <div className='col-8 mt-3'>

                                                            <label>Password</label>
                                                            <Input
                                                                value={password}
                                                                type="password"
                                                                valueChange={(e) => setPassword(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } */}
                                            {/* <div className='col-12 mt-3'>
                                                <div className='accordion e-portfolio-accordion mb-3' id="parent">
                                                    <div className="accordion-item">
                                                        <h2
                                                            className="accordion-header  px-2 pb-3 pt-2 e_accordion_header"
                                                            id="headingOne"
                                                            style={{ marginBlockEnd: accordionOpened && '-17px' }}
                                                        >
                                                            <button
                                                                className={accordionOpened ? "e-accordion-btn text-start mt-2" : "e-accordion-btn text-start mt-2"}
                                                                type="button"
                                                                aria-expanded={accordionOpened === true ? 'true' : "false"}
                                                                onClick={() =>
                                                                    setAccordionOpened(!accordionOpened)
                                                                }
                                                            >
                                                                <img src={Tradebook} className="me-2 "/>
                                                                How to get my report?
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="collapseOne"
                                                            className={accordionOpened === true ? "accordion-collapse collapse  show" : "accordion-collapse collapse"}
                                                            aria-labelledby="headingOne"
                                                            data-bs-parent="#parent"
                                                        >
                                                            <div className='accordion-body pt-0'>
                                                                <div className='row'>
                                                                    <div className='col-lg-12 '>
                                                                        <ul className={`${styles.e_instruction_ul}`}>
                                                                            <li>
                                                                                Go to
                                                                                <a
                                                                                    style={{ overFlow: 'hidden' }}
                                                                                    href="https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement"
                                                                                    target="_blank"
                                                                                >
                                                                                    https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                Enter your email and create a password
                                                                            </li>
                                                                            <li>
                                                                                A report from donotreply@camsonline.com will be sent to your email
                                                                            </li>
                                                                            <li>
                                                                                Upload the report below along with the password you created
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className='col-lg-12 col-md-12 col-sm-12 mt-lg-0 mt-md-0 mt-3 ps-0 ps-0' >
                                                <h6 className='mb-4 ms-lg-3 ms-md-3 ms-0'>
                                                    Add mutual fund details
                                                </h6>
                                                <PrimaryLink
                                                    name="Add manually"
                                                    className={`${styles.e_link_btn} ms-lg-3 ms-md-3 ms-0`}
                                                    handleClick={() => setManual(true)}
                                                />
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                        <SecondaryBtn
                                            name="Cancel"
                                            className="w-100 e-portfolio-btn-add"
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                        <PrimaryBtn
                                            name="Upload"
                                            disabled={password && file ? false : true}
                                            className={apiLoader ? "w-100 e-portfolio-btn-add e-btn-loader" : "w-100 e-portfolio-btn-add"}
                                            handleClick={() => _importFile()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
            }


            {
                toast && (
                    <Toast data={toast} />
                )
            }
        </div>
    )
}

export default MutualFundsDetail;