/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Orders
 *   Integrations : 25-01-2022
 *   Version : 1.0.0
 *   Created : 04-01-2022
 */

/*import packages*/
import { useState, useEffect, React } from 'react';
import { useNavigate } from 'react-router-dom';

/*import components*/
import { HeaderInner, Footer } from '../../components/menu/index';
import { Side } from '../../components/dataFormatting';
import Details from './details';
import ChangePassword from './changepassword';
import BankDetails from './bank';
import { RedeemModal, SuccessPayment } from '../../components/modal';
import { Modal } from 'bootstrap';

import ManageSip from './sip';
import NomenineeDeatils from './nominee';
import Report from './report';
import RedirectUser from '../../services/redirectUser';

import Arrow from '../../assets/images/new-design/divider.svg';
import UserActive from '../../assets/images/new-design/user-navigation.svg';
import Message from '../../assets/images/new-design/message-profile.svg';
import Users from '../../assets/images/sidebar/users.svg';
import User from '../../assets/images/new-design/user-nav-inactive.svg';
import ActiveMessage from '../../assets/images/new-design/message-active.svg';
import UsersActive from '../../assets/images/new-design/users-active.svg';
import Active from '../../assets/images/new-design/profile-tab-arrow.svg';
import Mintd360 from '../../assets/images/new-design/mintd360.svg';
import Mintd360Active from '../../assets/images/new-design/mintd360-active.svg';
import Delete from '../../assets/images/new-design/delete.svg';


/*import services */
import GetURLVar from '../../services/getUrl';
import { Loader } from '../../components/loader';
import APIGet from '../../services/getApi';
import devconfig from '../../config/config';
import { PrimaryBtn } from '../../components/cta';
import AssetDeleteConfirmation from '../../components/modal/assetRemoveConfirmation';

const Profile = (props) => {
  const [tabIndex, setTabindex] = useState(1);
  const [profileData, setProfileData] = useState({});
  const [loader, setLoader] = useState(true);
  const [openWithdrawModal, setOpenModalWithdrawModal] = useState(false);
  const [status, setStatus] = useState(true);
  const [type, setType] = useState(null)
  let navigator = useNavigate();

  useEffect(() => {
    getProfileData();
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" })
  }, []);

  useEffect(() => {
    if (openWithdrawModal === true) {
      const modal = new Modal(document.getElementById("redeem"), {})
      modal.show();
    }
  }, [openWithdrawModal])

  useEffect(() => {
    if (props.userDetail) {
      window.analytics.identify(props.userDetail.user_id, {
        path: window.location.pathname,
      });
    }
  }, [props.userDetail])

  useEffect(async () => {
    let data = await RedirectUser();
    console.log(data)
    if (!data.redirect) setLoader(false);
    else navigator(data.link);
    // else  setLoader(false);
  }, [])

  const getProfileData = () => {
    let url = devconfig.user + '/profile/get';
    APIGet(url).then((response) => {
      if (response.status_code === 200) {
        setProfileData(response.data);
      }
    })
  }

  const getQueries = async () => {
    let queries = await GetURLVar();
    console.log("quer", queries)
    if (queries.tabIndex) {
      setTabindex(parseInt(queries.tabIndex))
    }
  }

  useEffect(() => {
    getQueries();
  }, [tabIndex]);

  const openModal = (data) => {
    setOpenModalWithdrawModal(false);
    if (data === "SUCCESS") {
      setStatus(false)
      const modal = new Modal(document.getElementById("paymentSucess"), {})
      modal.show()
      setType("Payment successful")
    } else {
      setStatus(true)
      const modal = new Modal(document.getElementById("paymentSucess"), {})
      modal.show()
      setType("Payment failed")
    }

  }

  return (
    <>
      {
        <>
          <div className="e-profile-container ps-lg-5 ps-md-5 ps-3  pe-lg-5 pe-md-4 pe-3 " style={{ backgroundColor: '#F9F9F9' }}>
            <div className="row pe-0">
              <div className="col-lg-3 col-md-3 col-sm-12 e-onboard-progress-section py-4 ps-4">
                <div className='row'>
                  <div className='col-12 gx-0 mt-0'>
                    <div className="e-breadcrumb-wrapper mt-lg-4 mt-md-4 mt-0">
                      <span className="e-previous">Account Settings</span>
                      <span className="e-divider mx-2"><img src={Arrow} /></span>
                      <span className="e-current">
                        {
                          tabIndex === 1 ?
                            'Personal Details'
                            : tabIndex === 2 ?
                              'Bank Details'
                              : 'Nominee Details'
                        }
                      </span>
                    </div>
                  </div>
                </div>
                <div className={window.innerWidth > 575 ? 'row mt-lg-4 mt-md-4 mt-2 e-profile-tab-wrapper' : 'mt-lg-4 mt-md-4 mt-2 e-profile-tab-wrapper'}>
                  <div className={window.innerWidth > 575 ? 'col-lg-12 col-md-12 col-sm-4 col-6 gx-0' : ''}>
                    <div className={tabIndex === 1 ? 'e-active-profile-tab mt-1' : 'e-profile-tab mt-1'} onClick={() => setTabindex(1)}>
                      <div className='d-flex'>
                        <img src={tabIndex === 1 ? UserActive : User} style={{ width: '16px' }} />
                        <span className='mx-2 '>Personal Details</span>
                        {
                          tabIndex === 1 && (
                            <img src={Active} className='' />
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className={window.innerWidth > 575 ? 'col-lg-12 col-md-12 col-sm-4 col-6 gx-0' : ''}>
                    <div className={tabIndex === 2 ? 'e-active-profile-tab mt-1' : 'e-profile-tab mt-1'} onClick={() => setTabindex(2)}>
                      <div className='d-flex'>
                        <img src={tabIndex === 2 ? ActiveMessage : Message} style={{ width: '16px' }} />
                        <span className='mx-2'>Bank Details</span>
                        {
                          tabIndex === 2 && (
                            <img src={Active} />
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className={window.innerWidth > 575 ? 'col-lg-12 col-md-12 col-sm-4 col-6 gx-0' : ''}>
                    <div className={tabIndex === 3 ? 'e-active-profile-tab mt-1' : 'e-profile-tab mt-1'} onClick={() => setTabindex(3)}>
                      <div className='d-flex'>
                        <img src={tabIndex === 3 ? Mintd360Active : Mintd360} style={{ width: '16px' }} />
                        <span className='mx-2'>Mintd 360</span>
                        {
                          tabIndex === 3 && (
                            <img src={Active} />
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className={window.innerWidth > 575 ? 'col-lg-12 col-md-12 col-sm-4 col-6 gx-0' : ''}>
                    <div className={tabIndex === 4 ? 'e-active-profile-tab mt-1' : 'e-profile-tab mt-1'} onClick={() => setTabindex(4)}>
                      <div className='d-flex'>
                        <img src={tabIndex === 4 ? UsersActive : Users} style={{ width: '16px' }} />
                        <span className='mx-2'>Nominee Details</span>
                        {
                          tabIndex === 4 && (
                            <img src={Active} />
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-9 col-md-9 col-sm-12  mt-lg-4 mt-md-4 mt-0 ps-lg-5 ps-md-5 ps-2 e-onboard-step-section pb-5 mb-5">
                <h5 className="e-dashboard-greeting mt-3">
                  {
                    tabIndex !== 3 ?
                      "My Profile"
                      :
                      "Mintd 360"
                  }
                </h5>
                <p className="e-dashboard-sub-title">
                  {
                    tabIndex !== 3 ?
                      "Tell us more about yourself"
                      :
                      "Manage your Mintd 360 portfolio"
                  }
                </p>
                <div className='col-12'>
                  {
                    tabIndex === 1 && (
                      <Details profile={profileData} />
                    )
                  }
                  {
                    tabIndex === 2 && (
                      <BankDetails />
                    )
                  }
                  {
                    tabIndex === 3 && (
                      <>
                        <PrimaryBtn
                          name="Delete all data from mintd 360"
                          image={Delete}
                          left={true}
                          iconClass="me-2"
                          className="e-delete-btn mt-3"
                          marginTop="-4px"
                          toggle="modal"
                          target="#remove-asset"
                        />
                      </>
                    )
                  }
                  {
                    tabIndex === 4 && (
                      <NomenineeDeatils />
                    )
                  }
                </div>
              </div>
              <AssetDeleteConfirmation
                selected={{ label: "data from Mintd360", value: null }}
                delete={true}
                modalClose={() => {

                }}
                success={() => {

                }}

              />

            </div>
          </div>
          <SuccessPayment from="/profile?tab=1" heading={type} status={status} close={() => { }} />


        </>
      }
    </>

  )
}

export default Profile;

