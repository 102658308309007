/*
 *   File : phoneWithFlag.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : phoneWithFlag.js
 *   Integrations : 
 *   Version : v1.0
 */

/*Package importing*/
import React from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'

/*import components */
import { Toast } from '../../components/note/index';

/*import style*/
import styles from '../../styles/components/phoneInput.module.scss';

export default class PhoneWithFlag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: null,
    }
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ value: this?.props?.defaultValue });

    }
  }
  validate() {
    if (this?.state?.value && this?.state?.value?.length < 9 || this?.state?.value?.length > 15 || this?.state?.value === "0000000000") {
      this.setState({ error: "Invalid phone" })
    }
  }
  valueChange(value) {
    this.setState({ value: value, error: null });
    this.props.valueChange(value);
    if (value !== undefined) {
      let val = parsePhoneNumber(value);
      if(this.props.country && this.props.number) {
        this.props.country(val?.countryCallingCode)
        this.props.number(val?.nationalNumber)
      }
      
    }
  }

  render() {

    return (
      <div className={this.props.isDisabled ? `${styles.e_input_wrap} ${styles.e_input_disabled}` : styles.e_input_wrap}>
        <PhoneInput
          id={this.props.id}
          placeholder="Enter phone number"
          value={this.props.value}
          onChange={(value) => { this.valueChange(value) }}
          className={this.state.error ?
            `${styles.e_input_error} ${styles.e_input_box}` + " " + this.props.className : `${styles.e_input_box}` + " " + this.props.className}
          // className={this.props.value? `${styles.e_input_label} ${styles.e_input_box }`+" "+this.props.className : `${styles.e_input_box}` +" "+this.props.className }
          defaultCountry="IN"
          onBlur={() => { this.validate() }}
          placeholder="Number"
          international
          countryCallingCodeEditable={false}
          disabled={this.props.disabled}
        // onCountryChange={(val) => console.log(val)}
        // focusInputOnCountrySelection={(val) => console.log("a",val)}
        // countrySelectProps={(val) => console.log("a",val)}

        />
        {/* { this.props.label? <label htmlFor={this.props.id} className="label">{this.props.label}</label>: null } */}
        {
          this.state.error ?
            // <p className={styles.e_error_msg+" mb-0"}>{this.state.error}</p>
            <Toast data={this.state.error} />
            :
            null
        }
      </div>
    );
  }
}

