
//Package import 
import { useState, useEffect } from 'react';
import moment from 'moment';
import $ from 'jquery';

//Asset import 

import Updown from '../../assets/images/new-design/up-down.png';

//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput';

import Uploader from '../../components/fileUploader/upload';
import { Toast, SuccessToast } from '../../components/note';

//service import 
import APIPost from '../../services/postApi';
import Config from '../../config/config';
import Nodata from '../../components/note/nodata-placeholder';

const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]

const MutualFundListError = (props) => {
    const [loader, setLoader] = useState(false);
    const [searchDetail, setSearchDetail] = useState([]);
    const [openSearchPanel, setOpenSearchPanel] = useState(false);
    const [selectedFund, setSelectedFund] = useState(null);
    const [toast, setToast] = useState(null)
    const [mfList, setMfList] = useState([]);
    const [error, setError] = useState(false)
    const [mfName, setMfName] = useState(null);
    const [removing, setRemoving] = useState(false);

    useEffect(() => {
        if (props.data) {
            setMfList(props.data)
        }
    }, [props.data])

    useEffect(() => {
        if (mfName) {
            _mfSearch(mfName)
        }
    }, [mfName])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null)
            }, 3000)
        }
    }, [toast])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])


    const _updateMfList = (list) => {
        console.log('data', list);
        for (let i = 0; i < list.length; i++) {
            const item = list[i]
            let url = Config.portfolio + '/assets/mf/update';
            let payload = JSON.stringify({
                mf_id: item.id,
                scheme_code: item.scheme_code,
                isin: item.isin,
                type_id: 7,
                mutual_fund_name: item.mf_name,
                purchase_date: item.purchase_date,
                quantity: item.unit_balance,
                nav: item.nav_value
            })
            APIPost(url, payload).then((response) => {
                if (response.status_code === 200) {
                    setLoader(false);
                    setToast('Successfully updated');
                    props.success();
                }
                else {
                    // setManual(false);
                    setLoader(false);
                    setError(response.message);
                }
            })
        }

        const _removeMf = (id, index) => {
            setRemoving(true);
            let url = Config.portfolio + '/assets/mf/delete';
            let payload = JSON.stringify({
                request_id: id
            })
            APIPost(url, payload).then((response) => {
                if (response.status_code === 200) {
                    setToast("Successfully removed");
                    setRemoving(false);
                    let array = mfList;
                    array.splice(index, 1);
                    setMfList([...array])
                }
                else {
                    setError(response.message);
                    setRemoving(false);
                }
            })
        }
    }

    const _mfSearch = (text) => {

        let url = Config.portfolio + '/assets/general/mf-search';
        let payload = JSON.stringify({
            search_text: text
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setSearchDetail(response.data);

            }
            else {
                setSearchDetail([])
            }
        })
    }

    const _kiteLogin = () => {
        window.location.href = Config.KiteLoginUrl;
    }

    return (
        <div className={`row mt-4 ${styles.e_stocks} e_stocks`}>
            <div className='col-12 '>

            </div>
            {
                mfList && (
                    mfList.map((item, index) => {
                        return (
                            <div className='col-12 mt-3'>
                                <div className='col-12'>
                                    <label>
                                        Mutual Fund Name
                                    </label>
                                    <Input
                                        defaultValue={props.data[index].mf_name}
                                        placeholder="Search stock"
                                        needFocusEvent={true}
                                        readOnly={true}
                                        className="mb-0"

                                    />
                                    <div className='col-12 text-center'>
                                        <img src={Updown} alt="" className='my-2 text-center' width="18px" />
                                    </div>
                                    <Input
                                        value={selectedFund === index ? item.mf_name : null}
                                        placeholder="Search stock"
                                        needFocusEvent={true}
                                        className={`${styles.e_search} mb-3`}
                                        focusIn={() => {
                                            let temp = mfList;
                                            temp[index].mf_name = '';
                                            setMfList(temp);
                                            setOpenSearchPanel(index);
                                        }}
                                        valueChange={(e) => {
                                            setMfName(e);

                                            let temp = mfList;
                                            temp[index].mf_name = e;
                                            setMfList(temp);
                                        }}
                                    />
                                    {
                                        openSearchPanel === index && searchDetail.length ? (
                                            <div className='e-mf-search-panel' >
                                                {
                                                    searchDetail && searchDetail.map((mf, i) => {
                                                        return (
                                                            <div className='row border-bottom py-2' key={i}>
                                                                <div className={i === 0 ? 'col-9' : 'col-9 mt-2'}>
                                                                    {mf.scheme_name}
                                                                </div>
                                                                <div className='col-3'>
                                                                    <SecondaryBtn
                                                                        name="Add"
                                                                        className="e-mf-add-btn"
                                                                        handleClick={() => {

                                                                            // let array = mfList;
                                                                            let temp = mfList;
                                                                            temp[index].mf_name = mf.scheme_name;
                                                                            temp[index].scheme_code = mf.scheme_code;
                                                                            temp[index].isin = mf.isin_dividend_payout_or_growth;
                                                                            console.log(temp)
                                                                            setSelectedFund(index)
                                                                            setMfList(temp);
                                                                            setOpenSearchPanel(false);
                                                                            setSearchDetail([])
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                            : null
                                    }

                                </div>

                                {/* <div className='col-6 mt-4 pt-2'>
                                <label>
                                    Purchase date
                                </label>
                                <SingleDate
                                    placeholder="DD-MM-YYYY"
                                    removeToday={true}
                                    converted={true}
                                    value={mfList.purchase_date}
                                    disabledDate={(day => {
                                        if (day.isAfter(moment())) {
                                            return true
                                        }
                                    })}
                                    onChange={(e) => {
                                        let temp = mfList;
                                        var tempDate = moment(e, 'YYYY-MM-DD');
                                        temp.purchase_date = tempDate.format('DD-MM-YYYY');
                                        setMfList({ ...temp });
    
                                    }}
                                />
                            </div>
                            <div className='col-6 mt-4 pt-2'>
                                <label>
                                    Quantity
                                </label>
                                <div className='w-50 position-relative'>
                                    <Input
                                        value={mfList.quantity === 0 ? '0' : mfList.quantity}
                                        className={` ${styles.e_spinner_input}`}
                                        type="number"
                                        valueChange={(e) => {
                                            let temp = mfList;
                                            let value = e === 0 ? '0' : e
                                            temp.quantity = value;
                                            setMfList({ ...temp });
                                        }}
                                    />
                                    <img src={SpinnerMinus}
                                        className={`${styles.e_spinner_minus}`}
                                        onClick={() => {
                                            let temp = mfList;
                                            temp.quantity = temp.quantity - 1;
                                            setMfList({ ...temp });
                                        }}
                                    />
                                    <img
                                        src={SpinnerPlus}
                                        className={`${styles.e_spinner_plus}`}
                                        onClick={() => {
                                            let temp = mfList;
                                            temp.quantity = temp.quantity + 1;
                                            setMfList({ ...temp });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-6 mt-4 '>
                                <label>
                                    NAV
                                </label>
                                <Input
                                    label="NAV"
                                    value={mfList.current_nav}
                                    type="number"
                                    valueChange={(e) => {
                                        let temp = mfList;
                                        temp.current_nav = e;
                                        setMfList({ ...temp });
                                    }}
                                />
                            </div>
                            <div className='col-6 mt-4 '>
                                <label>
                                    Net amount
                                </label>
                                <Input
                                    label="Net amount"
                                    value={mfList.quantity * mfList.current_nav === 0 ? '0' : mfList.quantity * mfList.current_nav}
                                    type="number"
                                    className="e-portfolio-disable-input"
                                    valueChange={(e) => {
                                    }}
                                />
                            </div> */}
                            </div>
                        )
                    })

                )
            }
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-12  mt-4'>
                    <SecondaryBtn
                        name="Cancel"
                        className="w-100"
                        cancel="modal"
                    />
                </div>
                <div className='col-lg-6 col-md-6 col-12  mt-4'>
                    {/* { */}
                    {/* // item.mf_type_id && */}

                    <PrimaryBtn
                        name="Update"
                        className={loader ? 'e-btn-loader w-100' : 'w-100'}
                        handleClick={() =>
                            _updateMfList(mfList)
                        }
                    />
                    {/* :
                            <PrimaryBtn
                                name="Update"
                                className={'w-100'}
                                disabled={true}
                            />
                    } */}


                </div>
            </div>


            {
                toast && (
                    <SuccessToast data={toast} />
                )
            }

            {
                error && (
                    <Toast data={error} />
                )
            }
        </div>
    )
}

export default MutualFundListError;