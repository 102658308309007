
//Package import
import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput';

import Arrow from '../../assets/images/PrimaryArrow.svg';
import Trash from '../../assets/images/onboard/trash-can.png';

// service 
import APIPost from '../../services/postApi';

//config
import Config from '../../config/config';
import { Toast } from '../../components/note';

import { UserDataContext } from '../../context/userData';

const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]


const RealEstateDetail = (props) => {
    const [loader, setLoader] = useState(false);
    const [propertyType, setPropertyType] = useState(null);
    const [date, setDate] = useState(null);
    const [price, setPrice] = useState(null);
    const [evaluationDay, setEvaluationDay] = useState(null);
    const [currentValue, setCurrentValue] = useState(null);
    const [unit, setUnit] = useState(null);
    const [size, setSize] = useState(null);
    const [toast, setToast] = useState(null);
    const [userData, setUserData] = useContext(UserDataContext);

    const [propertyList, setPropertyList] = useState([
        {
            type_id: null,
            purchase_date: null,
            buy_price: null,
            last_evaluation_date: null,
            current_value: null,
            size_unit: null,
            property_size: null
        }
    ]);

    useEffect(() => {
        if (toast) {
            setToast(null);
        }
    }, [toast])

    const _addProperty = () => {
        setLoader(true);
        let url = Config.portfolio + '/assets/realestate/add';
        let payload = JSON.stringify({
            data: propertyList
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                _eventTracker();
                setLoader(false);
                props.success();
            }
            else {
                setLoader(false);
                setToast(response.message);
            }
        })
    }

    const _formValidation = () => {
        let valid = true;
        propertyList.forEach(function (v, i) {
            if (
                Object.keys(v).some(function (k) {
                    return (v[k] == null || v[k] == '');
                })
            ) {
                console.log('null value present', i);
                valid = false;
            }
            else {
                console.log('data right', i);
                valid = true;
            }
        });
        return valid;
    }

    const _eventTracker = () => {
        window.analytics.track('Portfolio tracker configured', {
            user_id: userData?.user_id,
            stocks: false,
            MF: false,
            Gold: false,
            RealEstate: true,
            FD: false,
            Bond: false,
            crypto: false
        });
    }

    return (
        <div className={`row mt-4 ${styles.e_stocks} e_stocks`}>
            <div className='col-12 '>
                {
                    propertyList.map((item, key) => {
                        return (
                            <div key={key} className='col-12 mt-3 position-relative'>
                                <div className={key === 0 ? 'row ' : 'row mt-4'}>
                                    <div className='col-8'>
                                        <h6 className={styles.e_asset_number}>Real Estate - {key + 1}</h6>
                                    </div>
                                    <div className='col-4 text-end'>
                                        {
                                            key !== 0 && (
                                                <img
                                                    src={Trash}
                                                    className={`${styles.e_form_trash}`}
                                                    onClick={() => {
                                                        let array = propertyList;
                                                        array.splice(key, 1);
                                                        setPropertyList([...array]);
                                                    }}
                                                />
                                            )
                                        }
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className={key === 0 ? 'col-12 ' : 'col-12 '}>
                                        <label>
                                            Property type
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Select
                                            isSearchable={true}
                                            options={props.propertyOptions}
                                            selectchange={(e) => {
                                                let array = propertyList;
                                                array[key].type_id = e.value;
                                                setPropertyList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                        <label>
                                            Purchase date
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <SingleDate
                                            placeholder="DD-MM-YYYY"
                                            removeToday={true}
                                            disabledDate={(day => {
                                                if (day.isAfter(moment())) {
                                                    return true
                                                }
                                            })}
                                            onChange={(e) => {
                                                var tempDate = moment(e, 'YYYY-MM-DD')
                                                let array = propertyList;
                                                array[key].purchase_date = tempDate.format('DD-MM-YYYY');
                                                setPropertyList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                        <label>
                                            Buy price
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <div className='position-relative'>
                                            <Input
                                                value={item.buy_price}
                                                label="Buy price"
                                                type="number"
                                                className={`${styles.e_price_input}`}
                                                valueChange={(e) => {
                                                    let array = propertyList;
                                                    array[key].buy_price = e;
                                                    setPropertyList([...array]);
                                                }}
                                            />
                                            <span className={`${styles.e_rupee} e-rupee`}>₹</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                        <label>
                                            Last evaluation day
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <SingleDate
                                            placeholder="DD-MM-YYYY"
                                            removeToday={true}
                                            disabledDate={(day => {
                                                if (day.isAfter(moment())) {
                                                    return true
                                                }
                                            })}
                                            onChange={(e) => {
                                                var tempDate = moment(e, 'YYYY-MM-DD')
                                                let array = propertyList;
                                                array[key].last_evaluation_date = tempDate.format('DD-MM-YYYY');
                                                setPropertyList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                        <label>
                                            Current value
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <div className='position-relative'>
                                            <Input
                                                value={item.current_value}
                                                label="Current value"
                                                className={`${styles.e_price_input}`}
                                                type="number"
                                                valueChange={(e) => {
                                                    let array = propertyList;
                                                    array[key].current_value = e;
                                                    setPropertyList([...array]);
                                                }}
                                            />
                                            <span className={`${styles.e_rupee} e-rupee`}>₹</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                        <label>
                                            Size unit
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            value={item.size_unit}
                                            label="Sq.feet"
                                            type="number"
                                            valueChange={(e) => {
                                                let array = propertyList;
                                                array[key].size_unit = e;
                                                setPropertyList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4 '>
                                        <label>
                                            Property size
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            value={item.property_size}
                                            label="Property size"
                                            type="number"
                                            valueChange={(e) => {
                                                let array = propertyList;
                                                array[key].property_size = e;
                                                setPropertyList([...array]);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='col-12 mt-4'>
                <PrimaryLink
                    name="Add more"
                    className={` ${styles.e_link_btn}`}
                    handleClick={() => {
                        let array = propertyList;
                        array.push(
                            {
                                type_id: null,
                                purchase_date: null,
                                buy_price: null,
                                last_evaluation_date: null,
                                current_value: null,
                                size_unit: null,
                                property_size: null
                            }
                        )
                        setPropertyList([...array]);
                    }}
                />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                <SecondaryBtn
                    name="Cancel"
                    className="w-100"
                    cancel="modal"
                />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                {
                    _formValidation() ? (
                        <PrimaryBtn
                            name="Add"
                            className={loader ? 'e-btn-loader w-100' : 'w-100'}
                            handleClick={() => _addProperty()}
                        />
                    ) : (
                        <PrimaryBtn
                            name="Add"
                            disabled={true}
                            className='w-100'
                        />
                    )

                }

            </div>
            {
                toast && (
                    <Toast data={toast} />
                )
            }
        </div>
    )
}

export default RealEstateDetail;