
/*import package*/
import React, { Component, useEffect, useState } from 'react';
import Chart from "react-apexcharts";

/*import style*/
import styles from '../../styles/components/pie.module.scss';
import { Loader } from '../loader';

const _customTooltip = (props) => {
  return (
    "<div class='e-custom-tool-tip'>"+ 
      props.w.globals.labels[props.seriesIndex] +": "+props.series[props.seriesIndex]+'%'+
    "</div>"
  )
}

const PieChart = (props) => {
  const [ colors, setColors] = useState([]);
  const [ labels, setLabels] = useState([]);
  const [ values, setValues] = useState([]);
  const [ loader, setLoader] = useState(true);

  useEffect(()=>{
      console.log('data', props.data);
      setLoader(true);
      setColors(props.colors);
      setValues(props.data);
      setLabels(props.labels);
      setLoader(false);

  },[props.data])

  return (
    <>
    {
      !loader ? (
        <Child data={values} colors={colors} labels={labels} width={props.width} removeTooltip={props.removeTooltip ? true : false} height={props.height} />
      ) : (
        <Loader removeTag={true} />
      )
    }
    </>
  )
}
 class Child extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: this.props.data,
      options: {
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '52%',
            }
          }
        },
        dataLabels: {
          enabled: false,

        },
        legend: {
          fontSize: '14px',
          fontFamily: 'Inter',
          fontWeight: 600,
          show: false,
        },
        stroke: {
          show: false,
        },
        colors: this.props.colors,
        labels: this.props.labels,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom',
              colors: this.props.colors
            }
          }
        }],
        tooltip: {
          enabled:  this.props.removeTooltip ? false : true,
          fillSeriesColor: false,
          theme: false,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => { return _customTooltip({ series, seriesIndex, dataPointIndex, w })},
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return value + "%";
            }
          }
          // fillSeriesColor: false,
          // colors: ['#ffffff']



        },

      },
    };
  }

  render() {
    return (
      <div className="donut">
        <Chart options={this.state.options} series={this.props.data} type="donut" width={this.props.width ? this.props.width : "380"} height={this.props.height ? this.props.height : "250"} />
      </div>
    );
  }
}

export default PieChart;

