/*
 *   File : login.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import OneSignal from 'react-onesignal';
import $ from 'jquery';
import jwt_decode from 'jwt-decode'

/*import components*/
import Input from '../../components/input/loginInput';
import LoginPhone from '../../components/input/loginPhone';
import { PrimaryBtn, PrimaryIconBtn, SecondaryBtn, PrimaryLink } from '../../components/cta/index';
import { Toast, SuccessToast } from '../../components/note/index';
import VideoPlayer from '../../components/input/videoPlayer';
import { WatsappBtn, CalendlyBtn } from '../../components/cta';
import MintdSecurity from '../../components/modal/securityModal';
import BasicDetail from './signup';

/*import styles */

/*import assets*/
import LogoMobile from '../../assets/images/menu/logo.svg';
import BackArrow from '../../assets/images/new-design/arrow.svg';
import BtnArrow from '../../assets/images/new-design/btn-arrow.svg';
import Lock from '../../assets/images/new-design/lock.svg';
import Icon from '../../assets/images/new-design/whtsapp.svg';
import CalendlyICon from '../../assets/images/new-design/calendly.svg';
import Google from '../../assets/images/new-design/google.svg';
import Email from '../../assets/images/new-design/mail.svg';
import Mobile from '../../assets/images/new-design/smartphone.svg';

/*import context*/
import { UserDataContext } from '../../context/userData';


/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import LeadSquared from '../../services/leadSquredServices';
import APIGet from '../../services/getApi';

const Login = (props) => {
	let navigator = useNavigate();
	const [error, setError] = useState();
	const [phone, setPhone] = useState();
	const [userId, setUserId] = useState();
	const [userData, setUserData, onboardFlag, setOnboardFlag] = useContext(UserDataContext);
	const columnRef = useRef(null);
	const otpInput = useRef(null);
	const [number, setNumber] = useState(null);
	const [isValidate, setIsValidate] = useState(false);
	const [otp, setOTP] = useState(null);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [phoneResponseStatus, setPhoneResponseStatus] = useState(null);
	const [loader, setLoader] = useState(false);
	const [toast, setToast] = useState(null);
	const [redirecting, setRedirecting] = useState(false);
	const [phoneOTP, setPhoneOTP] = useState(null);
	const [timer, setTimer] = useState(null);
	const [phoneStatusClass, setPhoneStatusClass] = useState(null);
	const [OTPValidationFail, setOTPValidationFail] = useState(false);
	const [OTPValidationSuccess, setOTPValidationSuccess] = useState(false);
	const [resendLoader, setResendLoader] = useState(false);
	const [otpResponseStatus, setOTPResponseStatus] = useState(null);
	const [loginType, setLoginType] = useState(1);
	const [emailResponseStatus, setEmailResponseStatus] = useState(null);
	const [userName, setUserName] = useState(null);
	const [showBasicDetail, setShowBasicDetail] = useState(false);
	const [googleClient, setGoogleClient] = useState({});
	const [token, setToken] = useState(null)

	let calendlyUrl = devconfig.calendarUrl;

	// const CLIENT_ID = "551759561378-1705s2h1aii369o6cn32pca5cuv3fqao.apps.googleusercontent.com";
	const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
	let auth2;
	var googleUser = {};

	useEffect(() => {
		/* global google */

		let google = window.google;
		if (google) {
			console.log('google', google);

			// Initializing goole identify service
			google.accounts.id.initialize({
				client_id: CLIENT_ID,
				callback: handleCallbackResponse
			});

			// Rendering google Identify button 
			google.accounts.id.renderButton(
				document.getElementById('google-login'),
				{ theme: 'outline', width: '355' }
			);

			// Initiating google login prompt
			google.accounts.id.prompt();
		}

	}, [])

	// Google login callback, this will get JWT from google 
	// decoding the JWT and and pass user object to social_login API
	function handleCallbackResponse(response) {
		console.log("Encoded JWT ID token: ", response.credential);
		var userObject = jwt_decode(response.credential);
		console.log('userObject', jwt_decode(response.credential));
		if (userObject) {
			setUserName(userObject.email)
			_socialLogin(response.credential, userObject);
		}
	}

	const _socialLogin = (token, userData) => {
		let url = devconfig.user + '/auth/verify-social-login';
		let data = JSON.stringify({
			gmail_token: token,
			email: userData.email,
			first_name: null,
			second_name: null,
			phone_number: null
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {

				if (response.data.auth_configured === true) {
					localStorage.setItem("access_token", response.data.access_token)
					_getUserType(response.data.access_token, userData.email);


				} else {
					setShowBasicDetail(true)
					setToken(response.data.access_token);
				}

			} else {
				setError(response.message)
			}
		})
	}

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null)
			}, 10000)
		}
	}, [error])


	useEffect(() => {
		setNewCount();
	}, [timer])

	const setNewCount = () => {
		setTimeout(() => {
			if (timer > 0) {
				setTimer(timer - 1)
			}
		}, 1000)
	}



	useEffect(() => {
		OneSignal.getUserId(function (userId) {
			console.log("OneSignal User ID:", userId);
			setUserId(userId);
			console.log('user id', userId)
			//   updatePlayerId(userId);
		});

	}, [])



	// valdiation and otp api
	const _getOTP = (name = userName) => {
		if (loginType === 2 && number.length !== 10) {
			setPhoneResponseStatus({ type: 'fail', message: 'Invalid Number' });
			return
		}
		// setPhoneStatusClass('e-phone-success')
		setLoader(true);
		let url = devconfig.user + '/auth/validate-credential';
		let data = JSON.stringify({
			username: name,
			// country_code: "+" + country,
			// phone_number: number,
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				if (timer === null || timer === 0) {
					setTimer(20)
				}
				console.log(document.getElementById("otp-input"));
				// document.getElementById("otp-input").focus();
				if (loginType === 1) {
					setEmailResponseStatus({ type: 'success', message: '' })
				} else {
					setPhoneStatusClass('e-phone-success');
					setPhoneResponseStatus({ type: 'success', message: '' })
				}

				// navigator(`/confirm-otp?phone=${number}&country=+${country}`);
				setIsValidate(true);
			} else {
				if (loginType === 1) {
					setEmailResponseStatus({ type: 'fail', message: response.message })
				} else {
					setPhoneResponseStatus({ type: 'fail', message: response.message })
				}
				setError(response.message);

				setLoader(false);
			}
		})
		// }
		// else{
		// 	setPhoneStatusClass('e-phone-fail')
		// }
		setTimeout(() => {
			setError(null);
			setToast(null);
		}, 5000)

	}

	// useEffect(()=>{
	// 	if (phoneStatusClass === 'e-phone-success') {
	// 		_getOTP();
	// 	}
	// },[phoneStatusClass])



	const validateOtp = () => {
		var num = parseInt(otp);
		let error;
		if (loginType === 2 && number.length !== 10) {
			setPhoneResponseStatus({ type: 'fail', message: 'Invalid Number' });
			return
		}
		if (userName) {
			setLoader(true);
			let url = devconfig.user + '/auth/verify-otp';
			let data = JSON.stringify({
				username: userName,
				otp: phoneOTP
			})
			APIPost(url, data).then((response) => {
				if (response.status_code === 200) {
					setOTPValidationSuccess(true);
					// setOTPResponseStatus({type: 'success' , message:  loginType === 2 ? "Phone verified" : 'Email verified' })
					// localStorage.setItem('access_token', response.data.access_token);

					if (response.data.auth_configured === true) {
						localStorage.setItem("access_token", response.data.access_token)
						_getUserType(response.data.access_token);
					} else {
						setToken(response.data.access_token);
						setShowBasicDetail(true)
					}

				} else {
					setOTPResponseStatus({ type: 'fail', message: response.message });
					setOTPValidationFail(true);
					setError(response.message)
					setLoader(false);
				}
			})
		} else {
			let message;
			if (loginType === 1) message = 'OTP & Email required';
			else message = 'OTP & Phone number require'
			setError(message)
		}

		setTimeout(() => { setError(null) }, 5000)
	}

	const _getUserType = (token, name = userName) => {
		if (token) {
			let url = devconfig.user + '/auth/signup-progress';
			APIGet(url).then(async (response) => {
				if (response.status_code === 200) {
					if (response.data.auth_configured === true) {
						let leadType;
						let param
						if (loginType === 1) {
							leadType = 'Leads.GetByEmailaddress';
							param = 'emailaddress'
						}
						else {
							leadType = 'RetrieveLeadByPhoneNumber';
							param = 'phone'
						}
						console.log('userName', name);
						LeadSquared._getLead(name, leadType, param);
						routing(response.data.signup_status);
					}

					else {
						setShowBasicDetail(true);
					}
					setLoader(false);
				}
			})
		}

	}

	const _trackSignedUser = async () => {
		let timeStamp = Date.now();
		await window.analytics.identify("", {
			phone_number: phone,
		});

	}

	/*  update one signal notification player id */
	const updatePlayerId = () => {
		let url = devconfig.user + '/profile/save-notification';
		let data = JSON.stringify({
			"notification_id": userId
		})
		APIPost(url, data).then((response) => {
			if (response.status_code) {
				// console.log(response.message)
			}
		})
	}

	const resendOTP = (e) => {
		e.preventDefault();
		setResendLoader(true);
		let url = devconfig.user + '/auth/resend-otp';
		let data = JSON.stringify({
			//   country_code: `+${country}`,
			//   phone_number: number,
			username: userName,
			is_forgotpassword: false,
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setResendLoader(false);
				setTimer(20);
				// message.success(`OTP send to ${email}`);				
			}
			else {
				setResendLoader(false);
				setError(response.message);
			}
		})
		setTimeout(() => {
			setError(null)
		}, 5000)
	}

	/* redirect based on signup condition */
	const routing = (temp, status) => {
		switch (temp) {
			case 'ACCOUNT_VERIFIED': window.location.href = 'login';
				break;
			case "OTP_REQUESTED": window.location.href = "/home";
				break;
			case 'AUTH_CONFIGURED': window.location.href = '/home';
				break;
			case 'PASSWORD_CONFIGURED': window.location.href = '/home';
				break;
			case 'PERSONAL_DATA_ADDED': window.location.href = '/home';
				break;
			case 'ADDRESS_DATA_ADDED': window.location.href = '/home';
				break;
			case 'INCOME_DATA_ADDED': window.location.href = '/home';
				break;
			case 'BANKACCOUNT_ADDED': window.location.href = '/home';
				break;
			case 'NOMINEE_ADDED': window.location.href = '/home'
				break;
			case 'ESIGN_STARTED': window.location.href = '/home';
				break;
			case 'ESIGN_COMPLETED': window.location.href = '/home';
				break;
			case 'AMC_KYC_INPROGRESS': window.location.href = '/home';
				break;
			case 'AMC_KYC_REJECTED': window.location.href = '/home';
				break;
			case 'AMC_KYC_COMPLETED': window.location.href = '/home';
				break;
			case 'QUESTIONNAIRE_COMPLETED': window.location.href = '/home'
				break;
			case 'QUESTIONNAIRE_STARTED': window.location.href = '/home'
				break;
			case 'QUESTIONNAIRE_RESUBMIT_STARTED': window.location.href = "/home"
				break;
			case 'QUESTIONNAIRE_RESUBMIT_COMPLETED': window.location.href = "/home"
				break;
			case 'RISK_SCORE_CALCULATED': window.location.href = '/home';
				break;
			case 'PORTFOLIO_CONFIGURED': window.location.href = '/home';
				break;
			case 'EDIT_SCORE': window.location.href = '/home';
				break;
			case 'DELETE_SCORE': window.location.href = '/home';
				break;
			case 'RISKSCORE_SKIPPED': window.location.href = '/home';
				break;
			case 'READY_TO_INVEST': window.location.href = '/home';
				break;
			case 'INACTIVE': window.location.href = '/home';
				break;
			case 'INVESTED': window.location.href = '/home';
			default: window.location.href = '/home';
		}
	}


	const _validForm = () => {
		let valid = true;
		if (loginType === 1) {
			if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(userName)) {
				valid = false;
			}
		}
		if (loginType === 2) {
			if (!userName || number && number.length !== 10) {
				valid = false
			}
		}
		return valid;
	}




	return (
		<>
			{
				showBasicDetail === true && (
					<BasicDetail loginType={loginType} userData={userName} token={token} back={() => { setShowBasicDetail(false) }} />
				)
			}
			{
				showBasicDetail === false && (
					<div className='container e-new-onboard'>

						{/* Header logo */}

						<div className='row'>
							<div className='col-12 ps-0'>
								<img
									className='e-outer-logo mt-4'
									src={LogoMobile}
								/>
							</div>
						</div>

						{/* Body */}
						<div className='row mt-lg-5 mt-md-5 mt-xs-3 mt-3 pt-lg-5 pt-md-5 pb-lg-5 pb-md-5'>
							<div className='col-lg-10 col-md-12 col-sm-12  offset-lg-1'>
								<div className='row'>

									{/* Video section */}
									{
										window.innerWidth > 575 && (
											<div className='col-lg-6 col-md-6 col-sm-12 gx-0 order-lg-1 mt-lg-0 mt-md-0 mt-5 mt-sm-5  order-md-1 order-sm-2 order-2'>
												<div className='e-video-player-container'>
													<VideoPlayer />
												</div>
											</div>
										)
									}


									{/* Form */}
									<div className='col-lg-4 col-md-5 col-sm-12 offset-lg-1 offset-md-1 gx-0 order-lg-2 order-md-2 order-sm-1 order-1'>
										<a href="https://mintd.in" className='e-site-link'>
											<img src={BackArrow}
											/>
											Go back to mintd.in
										</a>
										<h4 className='e-form-title mt-4'>
											Welcome to Mintd
										</h4>
										<p className='e-form-caption'>
											{/* Please enter your phone number to continue */}
										</p>
										<form
											className='e-login-form'
											onSubmit={(e) => e.preventDefault()}
										>
											{
												loginType === 1 ? (
													<>
														<label>Email</label>
														<Input
															id="email-input"
															type="text"
															placeholder="abc@gmail.com"
															value={userName}
															needBlurEvent={true}
															needFocusEvent={true}
															focusIn={() => {
																setEmailResponseStatus(null)
															}}
															success={emailResponseStatus && emailResponseStatus.type == 'success' ? true : false}
															fail={emailResponseStatus && emailResponseStatus.type != 'success' ? true : false}
															message={
																emailResponseStatus && emailResponseStatus.message ?
																	emailResponseStatus.message
																	:
																	'You will receive an OTP on this email'
															}
															messageType={emailResponseStatus?.type}
															valueChange={(value) => { setUserName(value) }}
															focusOut={(value) => {
																if (value) {
																	if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value)) {
																		setEmailResponseStatus({ type: 'fail', message: 'Invalid email' })
																	} else {

																	}
																}
															}}
														/>
													</>
												) :
													(
														<>
															<label>Phone Number</label>
															<LoginPhone
																id="phone-input"
																className="e-login-phone-input"
																errorClass={phoneStatusClass}
																message={
																	phoneResponseStatus ?
																		phoneResponseStatus?.message
																		:
																		'You will receive an OTP on this number'
																}
																messageType={phoneResponseStatus && phoneResponseStatus?.type}
																value={phone}
																country={(val) => { }}
																number={(val) => setNumber(val)}
																valueChange={(value) => {
																	setUserName(value);
																	if (value && value.length === 13) {
																		// document.getElementById("phone-input").blur();
																		// console.log('if'); setPhoneStatusClass('e-phone-success');
																	}
																	else setPhoneStatusClass(null)
																}}
																needFocus={true}
																focus={() => {
																	setPhoneStatusClass(null);
																	setPhoneResponseStatus(null)
																}}
																callBack={(value) => {
																	if (number && number.length !== 10) {
																		console.log('inside', value)
																		setPhoneStatusClass('e-phone-fail');
																		setPhoneResponseStatus({ type: 'fail', message: 'Invalid Number' })
																	} else if (number && number.length == 10) {
																		setPhoneResponseStatus(null)
																	}
																}}
															/>
														</>
													)
											}

											{

												<div className='col-5 e-otp-wrapper'
													style={{ height: isValidate === false ? '0px' : '139px' }}
												>
													<label className='mt-4'>{loginType === 1 ? 'Email' : 'Mobile'} OTP</label>
													<Input
														ref={otpInput}
														id="otp-input"
														type="number"
														width="40%"
														value={parseInt(phoneOTP)}
														success={OTPValidationSuccess}
														fail={OTPValidationFail}
														message={otpResponseStatus?.message === "Invalid OTP request" ? null : otpResponseStatus?.message}
														messageType={otpResponseStatus?.type}
														valueChange={(value) => {
															if (value.length <= 6) {
																setPhoneOTP(value)
															}
														}}
														needFocusEvent={true}
														focusIn={() => {
															setOTPResponseStatus(null);
															setOTPValidationFail(false)
														}}
													/>
													<div className='d-flex justify-content-between e-timer-wrapper mt-1'>
														<>
															<span className='e-otp-timer'>00 : {timer < 10 ? "0" + timer : timer}</span>
															<PrimaryLink
																className={resendLoader ? "e-btn-loader e-otp-resend" : 'e-otp-resend'}
																name={resendLoader ? "Sending" : "Resend OTP"}
																disabled={timer > 0 ? true : false}
																type="button"
																handleClick={(e) => {
																	_getOTP();
																}}
															/>
														</>
													</div>

												</div>

											}

											{/* <div className={ isValidate === true ? 'd-flex mt-2': 'd-flex mt-4'}>
											<CheckBox
												onChange={()=> setAcceptTerms( !acceptTerms)}
											/>
											<span className='e-terms'>
												I have read and agree to the  
												<a href="https://www.mintd.in/terms-and-conditions" target="_blank" className='ms-1'>
													terms of service
												</a>
											</span>
										</div> */}
											{
												!_validForm() ? (
													<PrimaryBtn
														className="w-100 mt-3 e-auth-submit"
														name="Continue"
														disabled={true}
														right={true}
														iconClass="ms-2"
														image={BtnArrow}
													/>
												) : (

													<PrimaryBtn
														className="w-100 mt-3 e-auth-submit"
														name="Continue"
														right={true}
														image={BtnArrow}
														iconClass="ms-2"
														handleClick={() => {
															if (!isValidate) {
																_getOTP()
															} else {
																validateOtp();
															}


														}}
													/>
												)
											}


											<span className='d-block e-security-text mt-2' data-bs-toggle="modal" data-bs-target="#mintd-security">
												<img src={Lock} className="me-1" />
												Mintd is secure and private
											</span>
										</form>
										<div className='col-12 text-center'>
											<span className='e-sign-up-option'>OR</span>
										</div>
										<div className='col-12 '>
											<div className='position-relative'>
												<div id="google-login" className='e-google-login-btn'></div>
												{/* <div id="g_id_onload"
													data-client_id="675836577762-dc6leultnfe3dism6dp3ntng05112gug.apps.googleusercontent.com"
													data-context="signin"
													data-ux_mode="popup"
													data-login_uri="http://localhost:3000/"
													data-auto_prompt="false">
												</div>

												<div class="g_id_signin"
													data-type="standard"
													data-shape="rectangular"
													data-theme="outline"
													data-text="continue_with"
													data-size="large"
													data-logo_alignment="left"
													data-width="345">
												</div> */}

												<SecondaryBtn
													name="Continue with Google"
													image={Google}
													left={true}
													className="e-sign-up-btn mt-3 w-100"
													iconClass="me-3"
													// toggle="modal"
													// target="#loader-modal"
													handleClick={() => {
														// googleClient.requestAccessToken()
														$('#google-login').trigger('click');
													}}
												/>
											</div>
										</div>
										<div className='col-12'>
											{
												loginType === 1 ? (
													<SecondaryBtn
														name="Continue with Phone "
														image={Mobile}
														left={true}
														iconClass="me-2 pe-1"
														className="e-sign-up-btn w-100"
														handleClick={() => { setUserName(null); setLoginType(2) }}
													/>
												) : (
													<SecondaryBtn
														name="Continue with Email"
														image={Email}
														left={true}
														iconClass="me-2 pe-1"
														className="e-sign-up-btn w-100"
														handleClick={() => { setUserName(null); setLoginType(1) }}
													/>
												)
											}
										</div>
										<div className='col-12'>
											<p className='e-privacy'>
												By proceeding, I accept Mintd's <a href="https://www.mintd.in/terms-and-conditions" target="_blank">T&C</a> and <a href="https://www.mintd.in/privacy-policy" target="_blank">Privacy Policy</a>.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className='col-12 mt-5 mb-5 text-center'>
								<h6 className='e-help-title'>Still not sure?</h6>
								<a href={devconfig.whatsappUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
									<WatsappBtn left={true} image={Icon} className="me-lg-3 me-md-3 me-sm-3 e-login-whatsapp" />
								</a>
								<a href={calendlyUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
									<CalendlyBtn left={true} image={CalendlyICon} />
								</a>
							</div>
						</div>
						<MintdSecurity />
						{
							error && (
								<Toast data={error} />
							)
						}
					</div>
				)
			}

		</>
	)
}

export default Login;



// </div>
// <div className='col-12 mt-5 mb-5 text-center'>
// 	<h6 className='e-help-title'>Still not sure?</h6>
// 	<a href="https://wa.me/+918655872100" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
// 		<WatsappBtn left={true} image={Icon} className="me-lg-3 me-md-3 me-sm-3 e-login-whatsapp" />
// 	</a>
// 	<a href={calendlyUrl} target="_blank"  rel="noopener noreferrer" style={{ textDecoration: "none" }}>
// 		<CalendlyBtn left={true} image={CalendlyICon} />
// 	</a>
// </div>
// </div>
// <MintdSecurity/>
// </div>	