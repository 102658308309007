/*
 *   File : login.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import OneSignal from 'react-onesignal';
import $ from 'jquery';

/*import components*/
import { HeaderOuterFullWidth } from '../../components/menu/index';
import { HoverVideo, CheckBox } from '../../components/input/index';
import Input from '../../components/input/loginInput';
import LoginPhone from '../../components/input/loginPhone';
import { PrimaryBtn, PrimaryIconBtn, SecondaryBtn, PrimaryLink } from '../../components/cta/index';
import { Toast, SuccessToast } from '../../components/note/index';
import VideoPlayer from '../../components/input/videoPlayer';
import { WatsappBtn, CalendlyBtn } from '../../components/cta';
import MintdSecurity from '../../components/modal/securityModal';

/*import styles */
import styles from '../../styles/components/primary.module.scss';

/*import assets*/
import LogoMobile from '../../assets/images/menu/logo.svg';
import BackArrow from '../../assets/images/new-design/arrow.svg';
import BtnArrow from '../../assets/images/new-design/btn-arrow.svg';
import Lock from '../../assets/images/new-design/lock.svg';
import Icon from '../../assets/images/new-design/whtsapp.svg';
import CalendlyICon from '../../assets/images/new-design/calendly.svg';

// import BtnArrow from '../../assets/images/new-design/btn-arrow.svg';

/*import context*/
import { UserDataContext } from '../../context/userData';

/* import services */
import Config from '../../config/config';
import APIPost from '../../services/postApi';
import { switchClasses } from '@mui/material';
import LeadSquared from '../../services/leadSquredServices';
import GetURLVar from '../../services/getUrl';
import devconfig from '../../config/config';

const Login = (props) => {
	let navigator = useNavigate();
	const [isValidate, setIsValidate] = useState(false);
	const [emailOTP, setEmailOTP] = useState(null);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [loader, setLoader] = useState(false);
	const [toast, setToast] = useState(null);
	const [phoneOTP, setPhoneOTP] = useState(null);
	const [timer, setTimer] = useState(null);
	const [phoneStatusClass, setPhoneStatusClass] = useState(null);
	const [OTPValidationFail, setOTPValidationFail] = useState(false);
	const [OTPValidationSuccess, setOTPValidationSuccess] = useState(false);
	const [resendLoader, setResendLoader] = useState(false);
	const [otpResponseStatus, setOTPResponseStatus] = useState(null);
	const [emailResponseStatus, setEmailResponseStatus] = useState(null);
	const [email, setEmail] = useState(null);
	const [fname, setFname] = useState(null);
	const [lname, setLname] = useState(null);
	const [error, setError] = useState(null);
	const [oldMail, setOldMail] = useState(null);
	const [fnameResponse, setFnameResponse] = useState(null);
	const [lnameResponse, setLnameResponse] = useState(null);

	let calendlyUrl = devconfig.calendarUrl
	const [loginType, setLoginType] = useState(2);
	const [number, setNumber] = useState(null);
	const [phoneResponseStatus, setPhoneResponseStatus] = useState(null);
	const [phone, setPhone] = useState();
	const [userName, setUserName] = useState(null);

	// let calendlyUrl = 'https://calendly.com/jaisal/mintd-onboarding-1on1?month=2022-08' 

	useEffect(() => {
		setLoginType(props.loginType)
	}, [props.loginType])

	useEffect(() => {
		if (toast) {
			setTimeout(() => {
				setToast(null)
			}, 5000)
		}
	}, [toast])

	useEffect(() => {
		if (error) {
			setTimeout(() => {
				setError(null)
			}, 5000)
		}
	}, [error])

	useEffect(() => {
		setNewCount();
	}, [timer])

	const setNewCount = () => {
		setTimeout(() => {
			if (timer > 0) {
				setTimer(timer - 1)
			}
		}, 1000)
	}

	useEffect(() => {
		if (oldMail !== null) {
			if (oldMail !== email) {
				setIsValidate(false);
				setOldMail(null);
				setEmailOTP(null)
				setEmailResponseStatus(null);
			}
		}
	}, [email])

	//Track signed up user details

	const _trackUser = () => {

		window.analytics.track("Account created", {
			name: `${fname} ${lname}`,
			phonenumber: loginType === 1 ? userName : props.userData,
			Email: loginType === 2 ? userName : props.userData,
			Typeofuser: 'Newuser',
			UTMinput: sessionStorage.getItem('avail_utm') ? 'Inorganic' : 'Organic'
		});
	}

	// Retrieving user prospect id from lead squared, 
	// if id is null -> create lead : save id to session
	const _createLead = () => {
		let phone = loginType === 1 ? userName : props.userData;
		let mail = loginType === 2 ? userName : props.userData
		LeadSquared._createLead(phone, mail, fname, lname);
	}

	const _validateOtp = (type = null) => {
		setLoader(true);
		let url = Config.user + '/auth/validate-name';
		let payload = JSON.stringify({
			username: userName,
			first_name: fname,
			last_name: lname,
		})
		APIPost(url, payload, props.token).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				setIsValidate(true);
				// localStorage.setItem("access_token", response.data.token)
				if (timer === null || timer === 0) {
					setTimer(20)
				}
				if (loginType === 2) {
					setEmailResponseStatus({ type: 'success', message: '' })
				} else {
					setPhoneStatusClass('e-phone-success');
					setPhoneResponseStatus({ type: 'success', message: '' })
				}

				// Create lead in Lead square
				_createLead();

				// Call segment track function
				_trackUser();
			}
			else {
				setLoader(false);
				if (loginType === 2) {
					setEmailResponseStatus({ type: 'fail', message: response.message })
				} else {
					setPhoneResponseStatus({ type: 'fail', message: null })
				}

				setError(response.message);
			}

		})
	}

	const _verifyOTP = () => {
		setLoader(true);
		let url = Config.user + '/auth/verify-name';
		let payload = JSON.stringify({
			username: userName,
			otp: emailOTP
		})
		APIPost(url, payload, props.token).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				// if ( loginType === 1 )
				// setOTPResponseStatus({ type: 'success',  message: 'Email verified'});
				localStorage.setItem("access_token", response.data.access_token)
				setTimeout(() => {
					window.location.href = '/home'
				}, 500)
			}
			else {
				setLoader(false);
				setOTPResponseStatus({ type: 'fail', message: response.message })
				setError(response.message);
			}
		})
	}




	/* redirect based on signup condition */
	const routing = (temp, status) => {
		console.log('Signup Progress Status :: ', temp)
		switch (temp) {
			case 'ACCOUNT_VERIFIED': window.location.href = 'login';
				break;
			case 'AUTH_CONFIGURED': window.location.href = '/onboard';
				break;
			case 'PASSWORD_CONFIGURED': window.location.href = '/onboard';
				break;
			case 'PERSONAL_DATA_ADDED': window.location.href = '/onboard';
				break;
			case 'ADDRESS_DATA_ADDED': window.location.href = '/onboard';
				break;
			case 'INCOME_DATA_ADDED': window.location.href = '/onboard';
				break;
			case 'BANKACCOUNT_ADDED': window.location.href = '/onboard';
				break;
			case 'NOMINEE_ADDED': window.location.href = '/onboard'
				break;
			case 'ESIGN_STARTED': window.location.href = '/onboard';
				break;
			case 'ESIGN_COMPLETED': window.location.href = '/onboard';
				break;
			case 'AMC_KYC_INPROGRESS': window.location.href = '/onboard';
				break;
			case 'AMC_KYC_REJECTED': window.location.href = '/onboard';
				break;
			case 'AMC_KYC_COMPLETED': window.location.href = '/onboard';
				break;
			case 'QUESTIONNAIRE_COMPLETED': window.location.href = '/portfolio'
				break;
			case 'QUESTIONNAIRE_STARTED': window.location.href = '/onboard'
				break;
			case 'QUESTIONNAIRE_RESUBMIT_STARTED': window.location.href = "/overview"
				break;
			case 'QUESTIONNAIRE_RESUBMIT_COMPLETED': window.location.href = "/portfolio"
				break;
			case 'RISK_SCORE_CALCULATED': window.location.href = '/portfolio';
				break;
			case 'PORTFOLIO_CONFIGURED': window.location.href = '/onboard';
				break;
			case 'EDIT_SCORE': window.location.href = '/overview';
				break;
			case 'DELETE_SCORE': window.location.href = '/overview';
				break;
			case 'RISKSCORE_SKIPPED': window.location.href = '/portfolio?skipped=' + true;
				break;
			case 'READY_TO_INVEST': window.location.href = '/portfolio';
				break;
			case 'INACTIVE': window.location.href = '/home';
				break;
			case 'INVESTED': window.location.href = '/home';
			default: window.location.href = '/home';
		}
	}

	// useEffect(()=>{
	// 	if (phoneStatusClass === 'e-phone-success') {
	// 		_validateOtp();
	// 	}
	// },[phoneStatusClass])

	const _validForm = () => {
		let valid = true;
		if (loginType === 2) {
			if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(userName)) {
				valid = false;
			}
		}
		if (loginType === 1) {
			if (!userName || number && number.length !== 10) {
				valid = false
			}
		}
		return valid;
	}

	return (
		<div className='container e-new-onboard'>

			{/* Header logo */}

			<div className='row'>
				<div className='col-12 ps-4 ps-lg-0 ps-md-0'>
					<img
						className='e-outer-logo mt-4'
						src={LogoMobile}
					/>
				</div>
			</div>

			{/* Body */}
			<div className='row mt-5 pt-lg-5 pt-md-5 pb-lg-5 pb-md-5 e-sign-up'>
				<div className='col-lg-10 col-md-12 col-sm-12  offset-lg-1'>
					<div className='row'>

						{/* Video section */}
						{
							window.innerWidth > 575 && (
								<div className='col-lg-6 col-md-6 col-sm-12 gx-0 order-lg-1 mt-lg-0 mt-md-0 mt-5 mt-sm-5  order-md-1 order-sm-2 order-2'>
									<div className='e-video-player-container'>
										<VideoPlayer />
									</div>
								</div>
							)
						}


						{/* Form */}
						<div className='col-lg-4 col-md-5 col-sm-12 offset-lg-1 offset-md-1 gx-lg-0 gx-md-0 gx-5 order-lg-2 order-md-2 order-sm-1 order-1'>
							<span className='e-site-link' style={{ cursor: "pointer" }} onClick={() => { props.back(); }}>
								<img src={BackArrow}
								/>
								Go back
							</span>
							<h4 className='e-form-title mt-4'>
								Welcome to Mintd
							</h4>
							<form
								className='e-login-form'
								onSubmit={(e) => e.preventDefault()}
							>
								<div className='row py-3'>
									<div className="col-lg-6 col-md-6 col-sm-12">
										<label>First name</label>
										<Input
											type="text"
											placeholder="John"
											value={fname}
											needBlurEvent={true}
											focusOut={() => {
												var regExp = /[a-zA-Z]/g;
												if (fname && !regExp.test(fname)) {
													setFnameResponse({ type: 'fail', message: 'Invalid name' })
												}
											}}
											needFocusEvent={true}
											focusIn={() => {
												setFnameResponse(null)
											}}
											success={fnameResponse && fnameResponse.type == 'success' ? true : false}
											fail={fnameResponse && fnameResponse.type != 'success' ? true : false}
											message={fnameResponse?.message}
											messageType={fnameResponse?.type}
											valueChange={(value) => { setFname(value); }}
										/>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12 mt-3 mt-lg-0 mt-md-0">
										<label>Last name</label>
										<Input
											type="text"
											placeholder="Deo"
											value={lname}
											needBlurEvent={true}
											focusOut={() => {
												var regExp = /[a-zA-Z]/g;
												if (lname && !regExp.test(lname)) {
													setLnameResponse({ type: 'fail', message: 'Invalid name' })
												}
											}}
											needFocusEvent={true}
											focusIn={() => {
												setLnameResponse(null)
											}}
											success={lnameResponse && lnameResponse.type == 'success' ? true : false}
											fail={lnameResponse && lnameResponse.type != 'success' ? true : false}
											message={lnameResponse?.message}
											messageType={lnameResponse?.type}
											valueChange={(value) => { setLname(value) }}
										/>
									</div>
								</div>
								{
									loginType === 2 ? (
										<>
											<label>Email</label>
											<Input
												type="text"
												placeholder="abc@gmail.com"
												value={userName}
												needBlurEvent={true}
												needFocusEvent={true}
												focusIn={() => {
													setEmailResponseStatus(null)
												}}
												success={emailResponseStatus && emailResponseStatus.type == 'success' ? true : false}
												fail={emailResponseStatus && emailResponseStatus.type != 'success' ? true : false}
												message={
													emailResponseStatus && emailResponseStatus.message ?
														emailResponseStatus.message
														:
														'You will receive an OTP on this mail'
												}
												messageType={emailResponseStatus?.type}
												valueChange={(value) => { setUserName(value) }}
												focusOut={(value) => {
													if (value) {
														if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value)) {
															setEmailResponseStatus({ type: 'fail', message: 'Invalid email' })
														}
													}
												}}

											/>
										</>
									) : (
										<>
											<label>Phone Number</label>
											<LoginPhone
												id="phone-input"
												className="e-login-phone-input"
												errorClass={phoneStatusClass}
												message={
													isValidate === true ?
														phoneResponseStatus?.message
														:
														'You will receive an OTP on this number'
												}
												messageType={isValidate && phoneResponseStatus?.type}
												value={phone}
												country={(val) => { }}
												number={(val) => setNumber(val)}
												valueChange={(value) => {
													setUserName(value);
													if (value && value.length === 13) {
														// document.getElementById("phone-input").blur();
														// setPhoneStatusClass('e-phone-success');
													}
													else setPhoneStatusClass(null)
												}}
												callBack={() => {
													if (number && number.length !== 10) {
														setPhoneStatusClass('e-phone-fail');
														setPhoneResponseStatus(null)
													}
												}}
											/>
										</>
									)
								}

								{

									<div className='col-5 e-otp-wrapper'
										style={{ height: isValidate === false ? '130px' : '139px' }}
									>
										<label className='mt-4'>{loginType === 1 ? "Mobile OTP" : 'Email OTP'}</label>
										<Input
											type="number"
											width="40%"
											value={parseInt(emailOTP)}
											success={otpResponseStatus && otpResponseStatus.type == 'success' ? true : false}
											fail={otpResponseStatus && otpResponseStatus.type != 'success' ? true : false}
											message={otpResponseStatus?.message}
											messageType={otpResponseStatus?.type}
											valueChange={(value) => {
												if (value.length <= 3) {
													setEmailOTP(value)
												}
											}}
											focusIn={() => {
												setOTPResponseStatus(null);
											}}
										/>
										<div className='d-flex justify-content-between e-timer-wrapper'>
											<>
												<span className='e-otp-timer'>00 : {timer < 10 ? "0" + timer : timer}</span>
												<PrimaryLink
													className={resendLoader ? "e-btn-loader e-otp-resend" : 'e-otp-resend'}
													name={resendLoader ? "Sending" : "Resend OTP"}
													disabled={timer > 0 ? true : false}
													type="button"
													handleClick={(e) => {
														_validateOtp('RESEND');
													}}
												/>
											</>
										</div>

									</div>

								}

								{
									// isValidate === false ? (
									// 	<>
									// 		{
									// 			fname && lname && userName ?
									// 				<PrimaryBtn
									// 					className={loader ?  "e-btn-loader e-auth-submit mt-3 w-100" :  "e-auth-submit mt-3 w-100"}
									// 					name={ loader ? "Loading" : "Continue"}
									// 					image={!loader && BtnArrow}
									// 					iconClass="ms-2"
									// 					image={BtnArrow}
									// 					right={true}
									// 					handleClick={()=> {
									// 						// if (emailResponseStatus === null)
									// 						_validateOtp()
									// 					}}
									// 				/>
									// 			:
									// 				<PrimaryBtn
									// 					className="e-auth-submit mt-3 w-100"
									// 					name="Continue"
									// 					disabled={true}
									// 					image={BtnArrow}
									// 					right={true}
									// 					iconClass="ms-2"
									// 				/>
									// 		}
									// 	</>

									// ) : (
									<>
										{
											fname && lname && _validForm() ?
												<PrimaryBtn
													className={loader ? "e-btn-loader e-auth-submit mt-3 w-100" : 'e-auth-submit mt-3 w-100'}
													name={loader ? "Loading" : "Continue"}
													image={BtnArrow}
													iconClass="ms-2"
													handleClick={() => {
														if (isValidate) {
															_verifyOTP()
														} else {
															_validateOtp();
														}
													}}
												/>
												:
												<PrimaryBtn
													className="e-auth-submit mt-3 w-100"
													name="Continue"
													disabled={true}
													image={BtnArrow}
													iconClass="ms-2"
												/>
										}
									</>


								}


								<span className='d-block e-security-text mt-2' data-bs-toggle="modal" data-bs-target="#mintd-security">
									<img src={Lock} className="me-2" />
									Mintd is secure and private
								</span>
							</form>
						</div>
					</div>
				</div>
				<div className='col-12 mt-5 mb-5 text-center'>
					<h6 className='e-help-title'>Still not sure?</h6>
					<a href={devconfig.whatsappUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
						<WatsappBtn left={true} image={Icon} className="me-3" />
					</a>
					<a href={calendlyUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
						<CalendlyBtn left={true} image={CalendlyICon} />
					</a>
				</div>
			</div>
			<MintdSecurity />
			{
				toast && (
					<SuccessToast data={toast} />
				)
			}
			{
				error && (
					<Toast data={error} />
				)
			}
		</div>
	)
}

export default Login;

