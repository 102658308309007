import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import './styles/config.scss';
import './styles/style.scss';
import './styles/responsive.scss';

import './assets/fonts/Montserrat-woff/Montserrat-ExtraBold.woff';
import './assets/fonts/Montserrat-woff/Montserrat-Bold.woff';
import './assets/fonts/Montserrat-woff/Montserrat-SemiBold.woff';
import './assets/fonts/Montserrat-woff/Montserrat-Medium.woff';
import './assets/fonts/Montserrat-woff/Montserrat-Regular.woff';
import './assets/fonts/Montserrat-woff/Montserrat-Light.woff';
import { UserDataProvider } from './context/userData';

ReactDOM.render(

  <React.StrictMode>
    <UserDataProvider>
      <App />
    </UserDataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
