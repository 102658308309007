
//Package import 
import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import $, { data } from 'jquery';
import { useNavigate } from 'react-router-dom';

//Style import 
import styles from '../../styles/components/assetList.module.scss';

// Component import
import { Input, Select } from '../input';
import { PrimaryBtn, SecondaryBtn, PrimaryLink} from '../cta';
import { Toast } from '../note';

//Asset import
import Lens from '../../assets/images/lens.svg';

//Container import
import StockDetail from '../../containers/portfolioTracker/stockList';
import MutualFundsDetail from '../../containers/portfolioTracker/mfList';
import RealEstateDetail from '../../containers/portfolioTracker/propertyList';
import FixedDepositDetail from '../../containers/portfolioTracker/depositList';
import GoldDetail from '../../containers/portfolioTracker/goldList';
import Close from '../../assets/images/new-design/modalClose.svg';

import { Loader } from '../loader';
// config import 
import Config from '../../config/config';

//service import
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';
import GetURLVar from '../../services/getUrl';



const UpdateAssets = (props) => {
    const [ selectedAsset, setSelectedAsset ] = useState({});
    const [ assetTypes, setAssetsTypes ] = useState([]);
    const [ fixedType, setFixedType ] = useState([]);
    const [ mutualFunds, setMutualFunds ] = useState([]);
    const [ organization, setOrganization ] = useState([]);
    const [ property, setProperty ] = useState([]);
    const [ stocks, setStocks ] = useState([]);
    const [ toast, setToast ] = useState(null);
    const [ apiLoader, setApiLoader ] = useState(false); 
    const [ assetData, setAssetData ] = useState([]);
    const location = useParams();
    const [ showPreview, setShowPreview] = useState(false);
    const navigator = useNavigate();  

    useEffect(()=>{
        _getGeneralData()
    },[])

    useEffect(()=> {
        if ( toast ) {
            setTimeout(()=>{
                setToast(null);
            },5000)
        }
    },[toast])

    useEffect(()=>{
        if ( props.selected) {
            setSelectedAsset(props.selected);
            _getAssetData(props.selected.value, props.dataId);
            setShowPreview(true);
        }
    },[props.selected])

    const _getGeneralData = async () => {
        let url = Config.portfolio+'/assets/general/list';
        //checking url params if url param zerodha redirection;
        let selected = await GetURLVar().then((query)=>{ return query.asset_type });
        if ( selected ) selected = selected.replace("%20", " ");
        APIGet(url).then((response) => {
            if (response.status_code === 200 ) {
                let assets = []; 
                response.data.asset_type.map((item, key)=>{
                    if (selected == item.label) 
                        setSelectedAsset({value: item.id, label: item.label});
                        if ( item.label != 'Equity'){
                            return(
                                assets.push({value: item.id, label: item.label})
                            )
                        }
                    
                })
                console.log('assets', assets)
                setAssetsTypes(assets);  
                let fixed = response.data.fixed_deposit_type.map((item, key)=>{
                    return(
                        {value: item.id, label: item.label}
                    )
                })
                setFixedType(fixed)
                // let mutual = response.data.mutual_fund_type.map((item, key) => {
                //     return(
                //         {value: item.id, label: item.label}
                //     )
                // })
                // setMutualFunds(mutual)
                let organization = response.data.organization_type.map((item, key) => {
                    return(
                        {value:item.id, label: item.label}
                    )
                })
                setOrganization(organization);
                let properties = response.data.property_type.map((item, key) => {
                    return(
                        { value: item.id, label: item.label}
                    )
                })
                setProperty(properties)
                let stock = response.data.stock_type.map((item, key) => {
                    return(
                        { value: item.id, label: item.label}
                    )
                })
                setStocks(stock);
            }
        })
    }

    const _getAssetData = (id, dataId) => {
        if ( id === 20 || id === 21) return 
        setApiLoader(true)
        let url = Config.portfolio+'/assets/assets/list';
        let payload = JSON.stringify({
            asset_type_id : id
        })
        APIPost(url, payload).then((response) => {
            if ( response.status_code === 200 ) {
                setTimeout(() =>{ setApiLoader(false);},1000)
                let editData; 
                response.data.data.map((item)=>{
                    if ( item.id === dataId) {
                        editData = item
                    }
                })
                setAssetData(editData);
            }
            else{
                setApiLoader(false);
                setAssetData([]);
            }
        })
    }

    useEffect(() => {
        var myModalEl = document.getElementById('update-asset')
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            props.modalClose();
        })
    }, [])

    const _success = (type) => {
        props.success(selectedAsset); 
    }


    const RenderAssetDetail = () => {
        let body;
        switch(selectedAsset?.value) {
            case 1 : return ( 
                <MutualFundsDetail 
                    data= { assetData } 
                    mutualFundOptions = { mutualFunds } 
                    success={()=> {
                        $('#update-asset').trigger('click');
                        _success(selectedAsset);
                    }}
                    error={(e)=>setToast(e)}
                />
            );
            break;
            case 2 : return ( 
                <StockDetail 
                    data= { assetData } 
                    success={(file)=> {
                        $('#update-asset').trigger('click');
                        props.success(selectedAsset); 
                    }}
                    error={(e)=>setToast(e)}
                />
            );
            break;
            case 3 : return ( 
                <RealEstateDetail 
                    data = { assetData }
                    propertyOptions = { property } 
                    success={()=> {
                        $('#update-asset').trigger('click');
                        props.success(selectedAsset);
                    }}
                    error={(e)=>setToast(e)}
                /> 
            );
            break;
            case 4 : return ( 
                <GoldDetail 
                    data={ assetData }
                    success={()=>{
                        $('#update-asset').trigger('click');
                        props.success(selectedAsset);
                    }}
                    error={(e)=>setToast(e)}
                /> 
            );
            break;
            case 5 : return (  
                <FixedDepositDetail 
                    data={assetData}
                    organization = { organization } 
                    fixedOptions = {fixedType} 
                    success={()=>{
                        $('#update-asset').trigger('click');
                        props.success(selectedAsset);
                    }}
                    error={(e)=>setToast(e)}
                /> 
            );
            break;
            case 6 : return ( <div></div>);
            break; 
            case 20 : return ( <div className={`${styles.e_coming_soon} text-center mt-4 py-4`}>Coming soon</div> )
            break;
            case 21 : return ( <div className={`${styles.e_coming_soon} text-center mt-4 py-4`}>Coming soon</div> )
            break;
            default : return (<div></div>)
        }
    }

    

    return(
        <div className={`${styles.e_asset_modal} modal fade`} id="update-asset"    tabIndex="-1" aria-labelledby="successLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered ">
                <div className={`${styles.e_content} modal-content position-relative`}>
                <   div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                        <h5 className='mt-1'>Edit your assets</h5>
                        <img
                            src={Close}
                            className={`${styles.e_modal_close}`}
                            data-bs-dismiss="modal"
                        />
					</div>
                    <div className='modal-body'>
                        <div className="container  p-3">
                            <div className='row gx-0'>
                                <div className='col-12'> 
                                    {
                                        Object.keys(selectedAsset).length ? (
                                            <div className='col-12'>
                                                {/* <div className={`${styles.e_back_btn}`} onClick={()=>{ setSelectedAsset({})}} >
                                                    <img 
                                                        src={Arrow}
                                                    />
                                                    Back
                                                </div> */}
                                                <div 
                                                    className={`${styles.e_active} ${styles.e_tab} `} 
                                                    style={{width: 'max-content',left: '0', right:  '0', margin : 'auto', pointerEvents: props.type ===2 && 'none'}}
                                                    onClick={()=>{ if ( props.type === 1)setSelectedAsset({});}}
                                                >
                                                    {selectedAsset.label}
                                                </div>
                                            </div>
                                            
                                        ) : (
                                            <div className='d-flex flex-wrap justify-content-between'>
                                                {
                                                    assetTypes && assetTypes.length > 0 && assetTypes.map((item, key) =>{
                                                        return(
                                                            <div 
                                                                className={ 
                                                                    item.value === selectedAsset.value ? 
                                                                        `${styles.e_tab} ${styles.e_active} mb-3 `
                                                                    :
                                                                        `${styles.e_tab}  mb-3 `
                                                                }
                                                                onClick={()=>{
                                                                    if ( props.type === 1 ) {
                                                                        $('#update-asset').trigger('click');
                                                                        navigator(`/allocation?id=${item.value}&edit=${true}`)
                                                                    } else{
                                                                        setSelectedAsset(item);
                                                                        setShowPreview(false);
                                                                        sessionStorage.setItem('asset_type', item.label);
                                                                    }
                                                                }}

                                                            >
                                                                {item.label}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }  
                                </div>
                                {
                                    showPreview === true && (
                                        <div className='col-12'>
                                            {
                                                apiLoader ? (
                                                    <Loader height="250px"/>
                                                ) :
                                                (
                                                    <RenderAssetDetail/>
                                                )
                                            }
                                            
                                        </div>
                                    )
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                toast && (
                    <Toast data={toast}/>
                )
            }
        </div>
    )
}

export default UpdateAssets