/*
 *   File : skipTourConfirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Skip Tour  Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 22-07-2022
 */

/*import packages*/
import React, { useEffect, useContext } from 'react';
import $ from 'jquery'

/*import styles*/
import styles from '../../styles/components/rebalanceModal.module.scss';

/*import context*/
import { UserDataContext } from '../../context/userData';

/*import assets */
import Close from '../../assets/images/new-design/modalClose.svg';

/* import components */
import { PrimaryBtn, PrimaryLink, SecondaryBtn } from '../cta';

/*import services */


export default function RebalanceUpdate(props) {


    return (
        <div className={`${styles.e_rebalance_modal} modal fade`} id="rebalance-update" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="successLabel" aria-hidden="true" data-bs-backdrop="static" >
            <div className={`modal-dialog modal-dialog-centered ${styles.e_modal_dialog}`}>
                <div className={`modal-content ${styles.e_modal_content} `}>
                    <div className={`${styles.e_modal_title}  text-center pt-4 pb-3`}>
                        <h5> {`Rebalance Update`}</h5>
                        <img src={Close}
                            data-bs-dismiss="modal"
                            className={`${styles.e_modal_close}`}
                        />
                    </div>
                    <div className="container px-3">
                        <div className='row'>
                            <div className='col-lg-12 pt-4 px-4 '>
                                <h6 className={styles.e_sub_title}>
                                    Your portfolio will be rebalanced and will look fresher than ever.
                                </h6>
                                <p className={styles.e_sub_content}>
                                    As an investor, it is a good time to check and apply for rebalances
                                </p>
                            </div>
                        </div>
                        <div className="row pt-lg-4 pt-md-4 pt-sm-4 pb-4">
                            {/* cancel or close button */}

                            <div className="col-lg-6 col-6">
                                <SecondaryBtn name="Remind me later"
                                    className={` e-skip-tour-btn mt-4 mt-lg-0 mt-md-0 mt-sm-0`}
                                    cancel="modal"
                                />
                            </div>
                            <div className="col-lg-6 col-6">
                                <PrimaryBtn
                                    name="Next"
                                    className={`e-finish-tour-btn e-take-tour`}
                                    cancel="modal"
                                    handleClick={() => {
                                        props.success()
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    )
}



