/*
 *   File : calendly.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Calendly button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 16-06-2022
 */

/*import style*/
import styles from '../../styles/components/watsappBtn.module.scss';

const CalendlyBtn = (props) => {
  return (
    <button
      id={props.id}
      className={props.disabled ? `e-input-wrap e-calendly-btn ${styles.e_calendly} ${props.className}` : `e-input-wrap e-calendly-btn ${styles.e_calendly} ${props.className}`}
      data-bs-dismiss={props.cancel}
      data-bs-toggle={props.toggle}
      data-bs-target={props.target}
      onClick={props.handleClick}
      type="button"
    >
      {
        props.left ?
          <img src={props.image} className={`${styles.e_icon}`} />
          :
          null
      }

      <span>Book a free call</span>

    </button>

  );
}


export default CalendlyBtn;
