import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

const Progress = (props) => {

  return (
    <ProgressBar
      percent={props.percent}
      filledBackground= "#1329E6"
      height={2}
      unfilledBackground	="#EDF3FF"
    />
  );
}
export default Progress;