
/*Package import */
import { useState, useEffect } from 'react';
import $ from 'jquery';

/*Assets import*/
import PrimaryArrow from '../../assets/images/assets/primary_arrow.png';
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';

/* Style import*/
import styles from '../../styles/components/rebalanceModal.module.scss';
import Close from '../../assets/images/new-design/modalClose.svg';

/*Component import */
import { PrimaryBtn, SecondaryBtn } from '../cta';
import { Toast } from '../note';
import RebalanceCard from '../card/rebalance-card';

import Success from '../../assets/images/new-design/successGif.gif';
import Failed from '../../assets/images/new-design/failGif.gif';

const RebalanceModal = (props) => {

    const [detail, setDetail] = useState();
    const [loader, setLoader] = useState();
    const [reject, setReject] = useState();
    const [error, setError] = useState();
    const [popupBlockers, setPopupBlockers] = useState(false);
    const [hasMandate, setHasMandate] = useState(true);
    const [createMandateSuccess, setCreateMandateSuccess] = useState(false);
    const [checkingMandate, setCheckingMandate] = useState(false);
    useEffect(() => {
        if (!props.editScore) {
            getRebalanceDetaill()
        }

    }, [props.editScore])

    useEffect(() => {
        setHasMandate(props.haveMandate);
    }, [props.haveMandate])

    const getRebalanceDetaill = () => {
        let url = devconfig.user + '/investment/rebalance-details';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setDetail(response.data);
            } else {
            }
        })
    }

    const acceptRebalance = () => {
        setLoader(true)
        let url = devconfig.user + '/investment/rebalance';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                $('#rebalance').trigger('click');
                props.success("Changes applied ", 1)
            }
            else {
                setLoader(false);
                setError(response.message);
            }
        })
        setTimeout(() => {
            setError(null)
        }, 5000)
    }

    const rejectRebalance = () => {
        setReject(true);
        let url = devconfig.user + '/investment/reject-rebalance';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setReject(false)
                $('#rebalance').trigger('click');
                props.success('Rebalance rejected', 2);
            }
            else {
                setReject(false);
                setError(response.message);
            }
        })
        setTimeout(() => {
            setError(null);
        }, 5000)
    }

    const _updateInvestment = async () => {
        setLoader(true);
        let url = devconfig.user + '/mf/update-investment';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                props.success(response.data.status);
                $('#rebalance').trigger('click');
            }
            else {
                setLoader(false);
                setError(response.message);
                setTimeout(() => { setError(null) }, 5000)
            }
        })
    }

    const _createMandate = () => {
        setLoader(true);
        setCheckingMandate(true);
        let url = devconfig.user + '/mandate/create';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                // let newTab = window.open();
                //  newTab.location.href = url;
                var new_window = window.open();


                if (new_window !== null) {
                    new_window.location.href = response.data.mandate_url
                    new_window.opener = null;
                    new_window.blur();
                    window.focus();
                    var timer = setInterval(function () {
                        if (new_window.closed) {
                            clearInterval(timer);
                            checkMandate();

                        }
                    }, 1000);
                }

            }
            else {
                setError(response.message);
                setLoader(false);
            }
        })
        setTimeout(() => {
            setError(null);
        }, 4000)
    }

    //  CHECK MANDATE
    const checkMandate = () => {
        // setCheckingMandate(true);
        let url = devconfig.user + '/mf/get-mandate';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setCreateMandateSuccess(true);
                setHasMandate(response.data.bank_mandate);
                setCheckingMandate(false);
            } else {
            }
        })
    }

    useEffect(() => {
        var myModal = document.getElementById('rebalance')
        myModal.addEventListener('hidden.bs.modal', () => {
            props.modalClose();
        });
    }, []);
    function RebalanceItem(portfolio_data) {
        let data = portfolio_data.item;
        // Type 1 - New MF - https://www.figma.com/proto/HYSfhTwVgEeNJsN1iGo5b2/Mintd?node-id=4361%3A6321&scaling=min-zoom&page-id=338%3A3705&starting-point-node-id=4360%3A6093&show-proto-sidebar=1
        // Type 2 - Allocation got changed - https://www.figma.com/proto/HYSfhTwVgEeNJsN1iGo5b2/Mintd?node-id=4361%3A6321&scaling=min-zoom&page-id=338%3A3705&starting-point-node-id=4360%3A6093&show-proto-sidebar=1
        // Type 3 - No chnage in MF - https://www.figma.com/proto/HYSfhTwVgEeNJsN1iGo5b2/Mintd?node-id=4361%3A6321&scaling=min-zoom&page-id=338%3A3705&starting-point-node-id=4360%3A6093&show-proto-sidebar=1
        if (portfolio_data.type === 2) {
            return (
                <>
                    <div className={`${styles.e_content_card} p-3 mb-3`}
                        key={data.key}>
                        <div className='row'>
                            <div className="col-lg-5">
                                <h6 className={`${styles.e_equity_name}`}>{data.display_name}</h6>
                                <h6 className={`${styles.e_fund_name}`}>{data.scheme_name}</h6>
                            </div>
                            <div className='col-lg-6 mt-4'>
                                <span className={styles.e_weight}>Old weight: {data.invested_allocation}%</span>
                                <img src={PrimaryArrow} className={`${styles.e_arrow} mx-3 mt-1`} style={{ height: '13px' }} />
                                <span className={styles.e_weight}>New weight: {data.portfolio_allocation}%</span>
                            </div>


                        </div>
                    </div>
                </>
            )
        }
        else if (portfolio_data.type === 3) {
            return (
                <>
                    <div className={`${styles.e_content_card} p-3 mb-3`}
                        key={data.key}>
                        <div className='row'>
                            <div className="col-lg-5">
                                <h6 className={`${styles.e_equity_name}`}>{data.display_name}</h6>
                                <h6 className={`${styles.e_fund_name}`}>{data.scheme_name}</h6>
                                {
                                    portfolio_data.type !== 3 ?
                                        <span className={styles.e_weight}>Old weight: {data.invested_allocation}%</span>
                                        :
                                        null
                                }

                            </div>
                            {
                                portfolio_data.type !== 3 ?
                                    <div className='col-lg-1 mt-2'>
                                        <img src={PrimaryArrow} className={`${styles.e_arrow} mt-4 pt-1`} />
                                    </div>
                                    :
                                    <div className='col-lg-6 mt-4'>
                                        <span className={styles.e_weight}>Weight: {data.invested_allocation}%</span>
                                    </div>
                            }
                            {
                                portfolio_data.type !== 3 ?
                                    <div className='col-lg-6 '>
                                        {
                                            portfolio_data.type == 1 &&
                                            <>
                                                <h6 className={`${styles.e_equity_name}`}>{data.display_name}</h6>
                                                <h6 className={`${styles.e_fund_name}`}>{data.scheme_name}</h6>
                                            </>
                                        }

                                        {
                                            portfolio_data.type != 3 &&
                                            <span className={styles.e_weight}>New weight: {data.portfolio_allocation}%</span>
                                        }
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </div>
                </>
            )
        }
    }

    return (
        <div className={`${styles.e_rebalance_modal} modal fade`} id="rebalance" tabIndex="-1" aria-labelledby="rebalance " aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`modal-content ${styles.e_modal_content}`}>
                    <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                        <h5>{
                            props.editScore ?
                                'Change my score and portfolio'
                                :
                                " Rebalance Info"
                        }
                        </h5>
                        <img
                            src={Close}
                            className={`${styles.e_modal_close}`}
                            data-bs-dismiss="modal"
                        />
                    </div>
                    <div className="container p-4">
                        <div className="col-lg-12">
                            <p className={styles.e_info}>
                                {
                                    props.editScore ?
                                        <>
                                            {
                                                createMandateSuccess === false ?
                                                    "All existing SIPs will be cancelled and replaced by new ones with the latest allocation on the same schedule. Your existing portfolio will  undergo the changes below. "
                                                    :
                                                    null
                                            }
                                        </>
                                        :
                                        "Your portfolio have been rebalanced and are now fresher than ever. As an investor, it would be a good time to check and apply for rebalances."
                                }

                            </p>
                        </div>
                        <>
                            {
                                props.editScore === false ?
                                    <div className={` ${styles.e_rebalance_card} p-3 `}>
                                        {/* {console.log(detail)} */}
                                        {
                                            detail ?

                                                // detail.updated_mfs.map((item, key)=> {
                                                //     return(
                                                //         <>
                                                //             <RebalanceItem key= {key}
                                                //                 item= {item} />
                                                //         </>
                                                //     )
                                                // })
                                                <>
                                                    {
                                                        detail.updated_mfs.length != 0 ?
                                                            <div className='row'>
                                                                <div className='col-lg-12'>
                                                                    <h6 className={`${styles.e_title} mt-4`}>Weight allocation changes</h6>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-10'>
                                                                        <h6 className={`${styles.e_title} mt-2`}>
                                                                            Fund name
                                                                        </h6>
                                                                    </div>
                                                                    <div className='col-2'>
                                                                        <h6 className={`${styles.e_title} mt-2`}>
                                                                            Weight
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    detail.updated_mfs.map((item, key) => {
                                                                        return (
                                                                            <>
                                                                                <div className='col-12'>
                                                                                    <h6 className={`${styles.e_title} mt-2`}>{item.display_name}</h6>
                                                                                </div>
                                                                                <div className='col-10'>
                                                                                    <h6 className={`${styles.e_fund_name}  `}>{item.scheme_name}</h6>
                                                                                </div>
                                                                                <div className='col-2'>
                                                                                    <h6 className={styles.e_weight}>{item.invested_allocation}%</h6>
                                                                                </div>
                                                                                <div className='col-10'>
                                                                                    <h6 className={`${styles.e_fund_name}`}>{item.scheme_name}</h6>
                                                                                </div>
                                                                                <div className='col-2'>
                                                                                    <h6 className={styles.e_weight}>{item.portfolio_allocation}%</h6>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        detail.stable_mfs.length != 0 ?
                                                            <div className='row'>
                                                                <div className='col-lg-12'>
                                                                    {/* <h6 className={`${styles.e_title} mt-4`}>{ key=== 0 ? 'Fund changes' : key === 1 ? 'Weight allocation changes'  : 'No changes to' }</h6> */}
                                                                    <h6 className={`${styles.e_title} mt-4`}>No changes to</h6>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-10'>
                                                                        <h6 className={`${styles.e_title} mt-2`}>
                                                                            Fund name
                                                                        </h6>
                                                                    </div>
                                                                    <div className='col-2'>
                                                                        <h6 className={`${styles.e_title} mt-2`}>
                                                                            Weight
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                {

                                                                    detail.stable_mfs.map((item, key) => {
                                                                        return (
                                                                            <>
                                                                                <div className='col-12'>
                                                                                    <h6 className={`${styles.e_title} mt-2`}>{item.display_name}</h6>
                                                                                </div>
                                                                                <div className='col-10'>
                                                                                    <h6 className={`${styles.e_fund_name}  `}>{item.scheme_name}</h6>
                                                                                </div>
                                                                                <div className='col-2'>
                                                                                    <h6 className={styles.e_weight}>{item.invested_allocation}%</h6>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    <>
                                        {
                                            createMandateSuccess ?
                                                <div className='modal-body'>
                                                    <div className="container px-3">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                {
                                                                    hasMandate ?
                                                                        <img src={Success} alt="" className={`${styles.e_success_icon}`} />
                                                                        :
                                                                        <img src={Failed} alt="f" className={`${styles.e_success_icon}`} />
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                <p className={styles.e_success_text}>
                                                                    {
                                                                        hasMandate ?
                                                                            'Mandate created successfully'
                                                                            :
                                                                            'Mandate creation failed'
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row pt-2 pb-2">
                                                            <div className="col-lg-12 ">
                                                                {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >hi</button> */}
                                                                {
                                                                    hasMandate ?
                                                                        <div className='d-flex align-items-end justify-content-center'>
                                                                            <PrimaryBtn name="Invest now" className="w-100" handleClick={() => setCreateMandateSuccess(false)} />
                                                                        </div>
                                                                        :
                                                                        <div className='d-flex align-items-end justify-content-center'>
                                                                            <PrimaryBtn name="Create mandate" className="w-100" handleClick={() => setCreateMandateSuccess(false)} />
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                :
                                                <RebalanceCard />
                                        }
                                    </>

                            }
                        </>


                        {
                            props.editScore === false ?
                                <div className="row mt-5 mb-4">
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <SecondaryBtn name={reject ? "Rejecting" : "Reject"}
                                            className={reject ? "e-btn-loader me-3 w-100" : "me-3 w-100"}
                                            handleClick={() => rejectRebalance()}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-4'>
                                        <PrimaryBtn name={loader ? "Applying" : "Apply changes"}
                                            className={loader ? "e-btn-loader w-100" : 'w-100'}
                                            handleClick={() => acceptRebalance()}
                                        />
                                    </div>
                                </div>
                                :
                                <>
                                    {
                                        createMandateSuccess === false && (
                                            <div className='row mt-5'>
                                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                                    <SecondaryBtn name={reject ? "Rejecting" : "Reject"}
                                                        className={reject ? "e-btn-loader me-3 w-100" : "me-3 w-100"}
                                                        handleClick={() => props.rejectEdit()}
                                                    />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 mt-4 mt-lg-0 mt-md-0'>
                                                    {
                                                        hasMandate === false ?
                                                            <PrimaryBtn name={loader ? "Processing" : "Create Mandate"}
                                                                className={loader ? "e-btn-loader w-100" : 'w-100'}
                                                                disable={checkingMandate}
                                                                handleClick={() => _createMandate()}
                                                            />
                                                            :
                                                            <PrimaryBtn name={loader ? "Processing" : "Approve"}
                                                                className={loader ? "e-btn-loader w-100" : 'w-100'}
                                                                handleClick={() => _updateInvestment()}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }

                                </>
                        }

                        <div className='row'>
                            <div className='col-lg-12'>
                                {
                                    popupBlockers ?
                                        <p style={{ color: "red", paddingTop: "30px" }}>Disable popup blocker to continue with Payment Gateway</p>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error ?
                    <Toast data={error} type="fail" />
                    :
                    null
            }
        </div>
    )
}

export default RebalanceModal;