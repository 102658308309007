/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : OverviewTour
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-12-2021
 */

/*import packages*/
import { useState, React, useEffect, useContext } from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Modal } from 'bootstrap';
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery'

/*import components*/
import { Footer } from '../../components/menu/index';
import { LineChart } from '../../components/chart/index';
import { CurrentValue, Overviews, PortfolioSummary } from '../../components/card/index';
import ProgressBar from '../../components/input/progress.js';
import { Tour, SkipTour } from '../../components/modal';
import HeaderTour from '../../components/menu/headerTour';
import Accordion from 'react-bootstrap/Accordion';
import VideoPlayer from '../../components/input/videoPlayer';

/*import assets*/
import InnerLogo from '../../assets/images/menu/inner-logo.svg';
import CalendlyICon from '../../assets/images/new-design/calendly.svg';
import Edit from '../../assets/images/new-design/edit-notification.svg';
import Down from '../../assets/images/new-design/arrow-down.svg';
import MintdLogo from '../../assets/images/menu/logo.svg';
import SideBarMenu from '../../assets/images//new-design/menu.png';
import FooterLogo from '../../assets/images/menu/footerLogo.svg';
import Playstore from '../../assets/images/menu/playstore.svg';
import Appstore from '../../assets/images/menu/appstore.svg';

import Icon from '../../assets/images/new-design/whtsapp.svg';
import Arrow from '../../assets/images/new-design/btn-arrow.svg';
import ArrowCard from '../../assets/images/new-design/Vector.svg';
import Previous from '../../assets/images/new-design/tour-back.svg'
import Hand from '../../assets/images/modal/hand.svg'
import Gain from '../../assets/images/new-design/gain.svg';
import Info from '../../assets/images/new-design/info-black.svg';
import Up from '../../assets/images/new-design/trending-up.svg';

/*import context*/
import { UserDataContext } from '../../context/userData';
import LeadSquared from '../../services/leadSquredServices';
import { CalendlyBtn, PrimaryBtn, SecondaryBtn, WatsappBtn } from '../../components/cta';
import Sidebar from '../../components/menu/sidebar';

/* graph data */
const data = {
  data: [
    6800,
    6813.299999999999,
    6813.299999999999,
    6813.299999999999,
    6885.4400000000005,
    6865.3,
    6868.42,
    6859.7699999999995,
    6886.05,
    6886.05,
    6886.05,
    6961.5599999999995,
    6996.250000000001,
    7010.18,
    7084.040000000001,
    7078.01,
    7078.01,
    7078.01
  ],
  categories: [
    "08 Jul, 22",
    "09 Jul",
    "10 Jul",
    "11 Jul",
    "12 Jul",
    "13 Jul",
    "14 Jul",
    "15 Jul",
    "16 Jul",
    "17 Jul",
    "18 Jul",
    "19 Jul",
    "20 Jul",
    "21 Jul",
    "22 Jul",
    "23 Jul",
    "24 Jul",
    "25 Jul"
  ]
}

const OverviewTour = (props) => {

  const [click, setClick] = useState(1)
  const [run, setRun] = useState(false);
  let percentValue = 100 / 7;
  let navigator = useNavigate();
  const [next, setNext] = useState(false);
  const [tourStep, setTourStep] = useState(null);
  const [userData, setUserData] = useContext(UserDataContext);
  const [openSideBar, setOpenSideBar] = useState(false);


  const [graphFilter, setGraphFilter] = useState([
    { value: 1, label: '1M' },
    { value: 2, label: '3M' },
    { value: 3, label: '6M' },
    { value: 4, label: '1Y' },
    { value: null, label: 'Since 1st deposit' },
  ]);


  /* modal open */
  // useEffect(async () => {
  //   var getTour = localStorage.getItem("getTour")
  //   if (!getTour) {
  //     var modal = new Modal(document.getElementById("tour"), {});
  //     modal.show();
  //   }
  // }, []);

  useEffect(() => {
    document.addEventListener('wheel', function (e) {
      preventScroll(e);
    }, { passive: false })
    return () => {
      document.addEventListener('wheel', function (e) {
        preventScroll(e);
      }, { passive: false })
    };
  }, []);

  function preventScroll(e) {
    if (window.innerWidth > 575) {
      e.preventDefault();
      e.stopPropagation();
    }

    return false;
  }


  useEffect(async () => {
    setRun(true)
  }, [])

  const _segmentTourTracker = (step) => {
    window.analytics.track('Product tour', {
      user_id: userData?.user_id,
      producttour_status: 'viewed',
    });
  }

  const _activityTracker = (step) => {
    let trackerData = [
      {
        SchemaName: 'mx_Custom_1',
        DataType: 'SearchableDropdown',
        Value: 'False'
      }
    ]
    LeadSquared._activityTracker('Product tour', trackerData, 201);
  }




  /* joyride steps */
  const steps = [


    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <span className='e-completed-item'>1</span>
            <h6 className='ps-3 pt-0 text-start pe-3'>A simple dashboard without the noise</h6>
          </div>
          <p className='text-start ms-4 ps-3'>View your investments and returns immediately at any time. Focus on what’s
            important without any noise or complexity.</p>
          <ProgressBar percent={percentValue * 1} />
        </div>
      ),
      placement: "top-center",
      styles: {
        options: {
          borderRadius: "16px",
          width: 480,
          height: 300,
          // top: "180px !important",
          // top: "-500px"
        }
      },
      locale: {
        skip: <p>1 to 7</p>,
        next:
          <span className="e-tour-button"
            onClick={() => {
              _segmentTourTracker(1);
              setTourStep(1)
              LeadSquared._activityTracker('Product Tour Drop-off mail', 208);
            }}
          >
            <img src={Arrow} className="e-form-submit" />
          </span>
      },
      disableBeacon: true,
      target: '#e-sec-1',
      disableOverlayClose: true,
      spotlightClicks: true
    },
    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <span className='e-completed-item'>2</span>
            <h6 className='ps-3 text-start pt-0 pe-3'>Portfolio summary</h6>
          </div>
          <p className='text-start  ms-4 ps-3'>Everything you need to know about your portfolio in one place. Top up your
            investments seamlessly at any time.</p>
          <ProgressBar percent={percentValue * 2} />
        </div>
      ),
      placement: 'left',
      styles: {
        options: {
          top: "180px !important",
          width: 480,
        }
      },
      locale: {
        skip: <p>2 to 7</p>,
        next: <span className="e-tour-button" onClick={() => {
          _segmentTourTracker(2);
          LeadSquared._activityTracker('Product Tour Drop-off mail', 208);
          setTourStep(2);

          if (window.innerWidth <= 992) { setOpenSideBar(true) }
        }}>
          <img src={Arrow} className="e-form-submit" />
        </span>,
        back: <span className="e-tour-back">
          <img src={Previous} alt="" onClick={() => {
            var ele = document.getElementById("e-sec-1");
            ele.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            });
          }} />
        </span>

      },
      disableBeacon: true,
      disableOverlayClose: true,
      target: '#e-sec-2',
    },
    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <img src={Hand} alt="" />
            <h5 className='ps-3 text-start pt-0 pe-3 pt-md-2 pt-lg-2'>Click on <a href={`/asset-tour?welcome=true`}  >"Assets"</a> to explore further.</h5>
          </div>
        </div >
      ),
      placement: openSideBar ? "left" : 'right',
      styles: {
        options: {
          borderRadius: 16,
          width: 450
        }
      },
      locale: {
        last: <span className="e-next-span d-none">
          <img src={Arrow} className="e-form-submit  d-none" onClick={() => {

          }} />
          {/* last: <span className="e-next-span d-none">
          <img src={Arrow} className="e-form-submit  d-none" /> */}
        </span>,
        back: <img src={Previous} alt="" className='d-none' onClick={() => {

        }} />
      },
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      target: '#e-sec-3',
      // scrollToFirstStep: true,
    }
  ];


  /* handleJoyrideCallback */
  const handleJoyrideCallback = data => {
    const { action, index, type, size } = data;
    if (action === ACTIONS.CLOSE) {
      var modal = new Modal(document.getElementById("skip"), {});
      modal.show();
      return setRun(false);
    }
  }

  /* tour start */
  const handleClickStart = () => {
    // e.preventDefault();
    setRun(true)
  };

  const onClick = () => {
    window.location.href = '/login'
  };

  // $("body").css("overflow", "scroll")
  // $("svg").css("overflow", "")
  // $(".fade").remove()

  const faq = [
    {
      title: 'How do I know I can trust you with my wealth?',
      answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
    },
    {
      title: 'What if I want to pull my money out?',
      answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
    },
    {
      title: 'How long does it take to open an account?',
      answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
    },
    {
      title: 'Why should I invest with Mintd?',
      answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
    },
    {
      title: 'Is there a lock in period for my investment?',
      answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
    },
    {
      title: 'What happens id Mintd shutdown?',
      answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
    },
  ]


  return (
    <>
      {/* <HeaderInner id="e-sec-3" logo={InnerLogo} /> */}
      {/* <HeaderTour id={ window.innerWidth > 575 ?"e-sec-3" : null} logo={InnerLogo} next={next} onClick={() => window.location.href = "/assets"} /> */}
      <div className="container-fluid e-main-content e-dashboard e-overview-container e-tour-overview-container px-4 ">
        <div className='row'>
          <div className='col-lg-2'>
            <div className='e-side-bar-lg' >
              <Sidebar
                tour={props.tour}
                activeUser={true}
                count={1} id={!openSideBar ? "e-sec-3" : null}
                link="/asset-tour?welcome=true"
                callBack={() => {
                  navigator('/asset-tour?welcome=true');
                }}
              />
            </div>
          </div>
          <div className='col-lg-10 '>
            <div className={

              'e-inner-small-screen-title d-flex justify-content-between px-2 pb-4'
            }>
              <img src={MintdLogo} className="mt-4" />
              <button
                className='e-menu-btn mt-4'
                onClick={() => {
                  setOpenSideBar(true);
                }}
              >
                <img src={SideBarMenu} />
              </button>
            </div>
            <div
              className={
                openSideBar === true ?
                  'e-small-screen-menu e-menu-over-lay'
                  :
                  'e-small-screen-menu'
              }
              style={{ display: openSideBar && 'block', right: openSideBar && '0' }}
            >
              <Sidebar tour={props.tour} activeUser={true} count={1} id={openSideBar ? "e-sec-3" : null} closeMenu={() => setOpenSideBar(false)} link="/asset-tour?welcome=true" />
            </div>
            <div className="row pt-4">
              <h5 className="e-dashboard-greeting mt-3">
                {`Hey John, Good morning`}
              </h5>
              <p className="e-dashboard-sub-title">
                Here is your portfolio summary
              </p>
            </div>
            <div className=' '>
              <div className='row' id="e-sec-1">
                <div className="col-lg-4 col-md-6 col-sm-12 ">
                  <div className="e-dashboard-summary-card p-4">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="e-card-title">
                          Portfolio value
                        </h6>
                      </div>
                      <div className="col-8">
                        <h2 className='e-portfolio-value'>₹3,411.81
                        </h2>
                      </div>
                      <div className='col-4 text-end mt-3'>
                        <span className='e-gain-percent'>
                          <img src={Gain} className="me-2" />
                          0.35%
                        </span>
                      </div>
                      <div className='col-6 border-end mt-3 mb-2'>
                        <label className='e-card-label'>
                          Amount invested
                        </label>
                        <h5 className='e-amount mt-2'>
                          ₹3,400.00
                        </h5>
                      </div>
                      <div className='col-6 mt-3 mb-2'>
                        <label className='e-card-label'>
                          Total returns
                        </label>
                        <h5 className='e-amount mt-2'>
                          ₹11.81
                        </h5>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                        <PrimaryBtn
                          name="Invest more"
                          className="e-invest-more px-2"
                        // handleClick={() => {
                        //   getPortfolioDetail()
                        // }}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                        <SecondaryBtn
                          name="Withdraw"
                          className="e-withdraw-more"
                        // handleClick={() => {
                        //   setOpenWithdrawModal(true)
                        // }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12 mt-3 mt-lg-0 mt-md-0'>
                  <div className='e-dashboard-summary-card e-half-pie-card p-4'>
                    <div className='row'>
                      <div className='col-lg-12 d-flex justify-content-between position-relative'>
                        <h5 className='e-score-title'>
                          Your risk score
                        </h5>
                        <img src={Info}
                        // onMouseEnter={() => setOpenPopUp(true)}
                        // onMouseLeave={() => setOpenPopUp(false)}
                        />
                        {/* {
                      openPopUp && (
                        <IconStyleToolTip
                          className="e-tooltip"
                          content="Lorem ispum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        />
                      )
                    } */}

                      </div>
                      <div className='col-12 position-relative text-center'>
                        <div className='e-pie-outer-wrapper'>
                          <div class="e-half-progress">
                            <div class="barOverflow">
                              <div
                                class="bar"
                                style={{ transform: `rotate(${223}deg)` }}
                              >
                              </div>
                            </div>
                            <label className='e-progress-legend'>Risk Score</label>
                            <span className='e-progress-value'>10</span>
                          </div>
                          <div className='e-radius-left' style={{ backgroundColor: '#1329e6' }}></div>
                          <div className='e-radius-right' style={{ backgroundColor: '#1329e6' }}></div>
                        </div>
                      </div>
                      <div className='col-12 text-center'>
                        <h5 className='e-growth mt-2'>
                          Growth <img src={Up} className="ms-2" />
                        </h5>
                        <span className='e-edit-score-btn' >
                          Request edit
                          <img src={ArrowCard} className="ms-2" />
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12 mt-4 mt-lg-0' id="e-sec-2">
                  <div className='e-dashboard-summary-card p-4'>
                    <div className="row">
                      <div className='col-12'>
                        <h5 className='e-score-title'>
                          Portfolio summary
                        </h5>
                      </div>
                      <div className='col-6 mt-4'>
                        <h5 className="e-sub">
                          SIP STATUS
                        </h5>
                        <span className="e-date">
                          Upcoming SIP
                        </span>
                        <span className='e-edit-score-btn d-block mt-2'
                          onClick={() => navigator('/sip')}
                        >
                          Edit SIP
                          <img src={ArrowCard} className="ms-2" />
                        </span>
                      </div>
                      <div className='col-6 mt-4'>
                        <h5 className='e-sip-status'>Active</h5>
                        {/* <span className='e-date'>
                      {
                        overViewData?.portfolio_summary?.sip_date &&
                        overViewData.portfolio_summary?.sip_date.map((item, key) => {
                          return (
                            item
                          )
                        })
                      }
                    </span> */}
                      </div>
                      <div className="col-12 my-3">
                        <h5 className="e-sub">
                          Last rebalance
                        </h5>
                        <span className='e-date'>
                          JAN 23, 20222
                        </span>
                      </div>
                      <div className="col-12">
                        <h5 className="e-sub">
                          Upcoming rebalance
                        </h5>
                        <span className='e-date'>
                          Aug 1, 2022
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 e-small-device col-12 mt-4">
                  <div className="e-chart-wrapper e-area-chart">
                    <h6>Portfolio performance</h6>
                    <div className='e-mobile-scroll text-end'>
                      <div className="" style={{ minWidth: "345px" }}>
                        <div className="e-span-wrap mb-4">
                          {
                            graphFilter.map((item, key) => {
                              return (
                                <span className={click === item.value ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick(item.value)}>
                                  {item.label}
                                </span>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>

                    <div className='e-area-chart-scroll'>
                      <div className="" style={{ minWidth: "600px" }}>
                        <LineChart colors={['#1329E6', '#FFFFFF']} currency="₹" data={data} />
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-12 mt-4'>
                  <div className='e-dashboard-summary-card e-notification-card p-4'>
                    <div className='row'>

                      <div className='col-6 mb-3'>
                        <h5 className='e-score-title'>
                          Notifications
                        </h5>
                      </div>
                      <div className='col-6 text-end'>
                        <span className="e-mark-all" >Mark all as read</span>
                      </div>
                      <div className='col-2'>
                        <img
                          className='e-icon'
                          src={Edit}
                        />
                      </div>
                      <div className='col-10 ps-0'>
                        <h6>
                          Successfully invested
                        </h6>
                        <p>

                          Successfully invested
                          We are processing your LumpSum of ₹3400. The orders have been placed with each asset manager and you should receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.
                        </p>
                      </div>


                      <div className='col-12 text-end mt-3'>
                        <SecondaryBtn
                          name="Dismiss"
                          className="e-notification-secondary me-2"

                        />
                        <PrimaryBtn
                          name="See details"
                          className="e-notification-primary"
                          right={true}
                          iconClass="ms-2"
                          image={Down}

                        />
                      </div>

                      <div className='col-12 text-end mt-3'>
                        <span className='e-link'>
                          See all Notifications
                          <img src={ArrowCard} className="ms-2" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Video section */}
              <div className="col-12 mt-3 mt-lg-0">
                <div className="row">
                  <div className="col-12">
                    <h6 className="e-section-title mt-2 mb-3">
                      Welcome to Mintd
                    </h6>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="e-video-card">
                      <VideoPlayer url="https://outzet-resume-template.s3.ap-south-1.amazonaws.com/sample-video/pexels-artem-podrez-5726130.mp4" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-4  mt-lg-0 mt-md-0">
                    <div className="e-video-card">
                      <VideoPlayer />
                    </div>
                  </div>

                </div>

                {/* FAQ */}
                <div className="col-12 mt-4">
                  <div className="row">
                    <h6 className="e-section-title mt-2 mb-3">
                      Frequently asked questions
                    </h6>


                    <Accordion defaultActiveKey="0" flush className="e-home-faq">
                      <div className="e-home-faq-wrapper e-col pe-lg-4 pe-md-4 pe-0">
                        {
                          faq.map((item, key) => {
                            if (key < 3) {
                              return (
                                <Accordion.Item eventKey={key} className="mt-4">
                                  <Accordion.Header>{item.title}</Accordion.Header>
                                  <Accordion.Body>
                                    {item.answer}
                                  </Accordion.Body>
                                </Accordion.Item>
                              )
                            }
                          })
                        }
                      </div>
                      <div className="e-home-faq-wrapper e-col">
                        {
                          faq.map((item, key) => {
                            if (key > 2) {
                              return (
                                // <div className="">
                                <Accordion.Item eventKey={key} className="mt-4">
                                  <Accordion.Header>{item.title}</Accordion.Header>
                                  <Accordion.Body>
                                    {item.answer}
                                  </Accordion.Body>
                                </Accordion.Item>
                                // </div>
                              )
                            }
                          })
                        }
                      </div>

                    </Accordion>
                  </div>
                </div>

                {/* Contact */}
                <div className='col-12 mt-5 pt-3 mb-5 text-center'>
                  <h6 className='e-help-title'>Still not sure?</h6>
                  <a href="https://wa.me/9769444722" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                    <WatsappBtn left={true} image={Icon} className="me-3" />
                  </a>
                  <a target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                    <CalendlyBtn left={true} image={CalendlyICon} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactJoyride
        continuous
        showSkipButton
        scrollTo
        scrollToSteps={true}
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        // disableScrolling={true}
        // disableScrollParentFix={false}
        scrollToFirstStep

        styles={{
          options: {
            zIndex: 1000,
            color: "#1329e6",
            arrowColor: 'transparent',
            height: 300,
            // overlayColor: 'rgba(0, 0, 0, 0.5)',
            // spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
          },
          buttonNext: {
            width: 38,
            background: "#1329e6",
            borderRadius: "50%",
          },
        }}

      />
      {/* <Tour active={props.active} Start={() => { handleClickStart() }} /> */}
      <SkipTour step={tourStep} active={props.active} continue={() => handleClickStart()} />
    </>
  )
}

export default OverviewTour;

