/*
 *   File : invest.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Invest Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*import packages*/
import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

/*import componets*/
import { SingleDate, Calendar, RadioButton, CheckBox } from '../../components/input/index';
import Input from '../../components/input/loginInput';
import { Tab } from '../../components/dataFormatting/index';
import { IconStyleToolTip, CardIconStyleToolTip } from '../../components/tooltip/index';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';
import { CalendarModal } from '.';
import { Loader } from '../../components/loader/index';
import Toast from '../../components/note/toast';
import DateSelector from '../input/dateSelector';
import RebalanceCard from '../card/rebalance-card';

import LeadSquared from '../../services/leadSquredServices';


/*import styles*/
import styles from '../../styles/components/invest.module.scss';

/*import assets */
import Arrow from '../../assets/images/onboard/submit.svg';
import Tooltip from '../../assets/images/tooltip/tooltip.svg';
import TooltipIcon from '../../assets/images/new-design/info.svg';
import calendar from '../../assets/images/modal/calendar.svg';
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import Tick from '../../assets/images/assets/tick.svg';
import Success from '../../assets/images/profile/success.svg';
import Failed from '../../assets/images/profile/failed.svg';
import HDFC from '../../assets/images/new-design/hdfc.svg';
import Flag from '../../assets/images/new-design/flagYellow.svg';
import Close from '../../assets/images/new-design/modalClose.svg';
import DownArrow from '../../assets/images/new-design/down-arrow.png';

// import context
import { UserDataContext } from '../../context/userData';

export default function InvestNowModal(props) {

	let navigator = useNavigate();
	const [userData, setUserData, onboardFlag, setOnboardFlag] = useContext(UserDataContext);

	const [tabIndex, setTabindex] = useState(1);
	const [sipAmount, setSipAmount] = useState(null);
	const [lumpsumAmount, setLumpsumAmount] = useState(null);
	const [calender, setCalender] = useState();
	const [value, setValue] = useState([]);
	const [isShown, setIsShown] = useState(false);
	const [name, setName] = useState();
	const [date, setDate] = useState(1);
	const [toast, setToast] = useState(null);
	const [apiLoader, setApiLoader] = useState(false);

	const [checkingMandate, setCheckingMandate] = useState(false);
	const [hasMandate, setHasMandate] = useState(props.haveMandate);
	const [payment_window, setPayment_window] = useState();
	const [orderType, setOrderType] = useState(1);
	const [minSIP, setMinSip] = useState("");
	const [minLumpsum, setMinLumpsum] = useState("");
	const [selectedBank, setSelectedBank] = useState(true);
	const [sipDate, setSipDate] = useState(null);
	const [paymentWindowSip, setPaymentWindowSip] = useState(false)
	const [paymentWindowLumpsum, setPaymentWindowLumpsum] = useState(false)
	const [defaultState, setDefaultState] = useState(true);
	const [create_Mandate, setCreate_Mandate] = useState(false);
	const [completedSteps, setCompletedSteps] = useState([]);
	const [currentStep, setCurrentStep] = useState(0);
	const [currentStepCompleted, setCurrentStepStatus] = useState(false);
	const [createMandateCompleted, setCreateMandateCompleted] = useState(false);
	const [popupBlockers, setPopupBlockers] = useState(false);
	const [canInvest, setCanInvest] = useState(false);
	const [assetDetail, setAssetDetail] = useState();
	const [editPortfolio, setEditPortfolio] = useState(false);
	const [createMandateSuccess, setCreateMandateSuccess] = useState(false);
	const [accordionOpened, setAccordionOpened] = useState(true);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [is_HDFC_User, setIsHDFCUser] = useState(false);
	const [opened, setOpened] = useState(false);
	let maxAmount = 9999999;
	/* 
		------- Modal working flow -------
			1. Props getting from parent 
					mandate status (props.haveMandate)
					investment data (props.assetsDetail)
					edit score status (props.editScore)
			2. check mandate status (props.haveMandate)
				-> if ! sip tab btn name "Create Mandate" else "Invest"
			3. INVEST FLOW
				-> if invest in lumpsum 
					-> invest() -> success -> orders  "nb : to find button condition cntrl F  "LUMPSUM BUTTON"  " for invest function "INVEST()"
				else SIP 
					-> if (props.haveMandate) -> invest() ->success -> orders
						else -> create mandate () -> check  mandate status -> show success or fail-> if success invest() -> success -> orders
						to see sip btn condition find " SIP BUTTON " 
						to see check  mandate status find " CHECK MANDATE"
						to see success screen find " SUCCESS SCREEN"
						to see sip  screen find " SIP TAB"
						to see lumpsum screen find " LUMPSUM TAB"
						to see create mandate () " CREATE MANDATE () "
	*/

	/* This is a temp fix to get user bank 
		use -> hide net banking option for HDFC BANK users
	*/

	useEffect(() => {
		if (props.location === 'sip') {
			setTabindex(2);
			setOrderType(2);
		}
	}, [props.location])


	useEffect(() => {
		_getBankDetails();
	}, [])

	const _getBankDetails = () => {
		let url = devconfig.user + '/profile/get-bankdetails';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				if (response.data.bank_details.bank_name == 'HDFC BANK')
					setIsHDFCUser(true)
			}
			else {
			}
		})
	}

	useEffect(() => {
		setHasMandate(props.haveMandate);
	}, [props.haveMandate]);

	//  sign_up status = EDIT SCORE list mf portfolio -changes
	useEffect(() => {
		console.log('props.editscore', props.editScore);
		if (props.editScore === true) {
			setEditPortfolio(true);
		}
	}, [props.editScore])

	useEffect(() => {
		console.log('Mandate Status :: ', hasMandate)
		if (hasMandate == null) {
			setHasMandate(null);
			setCreateMandateCompleted(false);
		}
	}, [hasMandate]);

	useEffect(() => {
		if (toast !== null) {
			setTimeout(() => { setToast(null) }, 5000)
		}
	}, [toast])

	useEffect(() => {
		console.log('currentStepCompleted :: ', currentStepCompleted)
	}, [currentStepCompleted]);

	useEffect(() => {
		var myModal = document.getElementById('invest');
		myModal.addEventListener('shown.bs.modal', () => {
			// checkMandate();
			// getIvestmentData();
		})
	}, []);


	useEffect(() => {
		if (props.assetsDetail) {
			setAssetDetail(props.assetsDetail);
		}
	}, [props.assetsDetail])

	useEffect(() => {
		getIvestmentData();
	}, [])

	useEffect(() => {
		var myModal = document.getElementById('invest')
		myModal.addEventListener('shown.hidden.modal', () => {
			setApiLoader(false);
			setPopupBlockers(false);
		});
	}, []);

	useEffect(() => {
		var myModal = document.getElementById('invest')
		myModal.addEventListener('hidden.bs.modal', () => {
			setPopupBlockers(false);
			props.unmountModal();
		});
	}, []);



	const getIvestmentData = () => {
		let url = devconfig.user + '/mf/minimum-investment';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setSipAmount(response.data.minimum_investment_sip == 0 ? `${response.data.minimum_investment_sip}` : response.data.minimum_investment_sip);
				setLumpsumAmount(response.data.minimum_investment == 0 ? `${response.data.minimum_investment}` : response.data.minimum_investment);
				setMinSip(response.data.minimum_investment_sip == 0 ? `${response.data.minimum_investment_sip}` : response.data.minimum_investment_sip);
				setMinLumpsum(response.data.minimum_investment == 0 ? `${response.data.minimum_investment}` : response.data.minimum_investment);
				setSipDate(response.data.sip_dates);
			}
		})
	}

	//  CHECK MANDATE
	const checkMandate = () => {
		setCheckingMandate(true);
		let url = devconfig.user + '/mf/get-mandate';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setHasMandate(response.data.bank_mandate);
				setCheckingMandate(false);
				setCreateMandateSuccess(true);
			} else {
				setCheckingMandate(false);
			}
		})
	}

	const recheck = (e) => {
		e.preventDefault();
		setApiLoader(true);
		let url = devconfig.user + '/mandate/create';
		let data = JSON.stringify({
			amount: sipAmount
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.data.mandate_url !== null) {
					setApiLoader(false);
					// let newTab = window.open();
					//  newTab.location.href = url;
					var new_window = window.open();


					if (new_window !== null) {
						setPaymentWindowLumpsum(false);
						setPaymentWindowSip(false);
						new_window.location.href = response.data.mandate_url
						new_window.opener = null;
						new_window.blur();
						window.focus();
						var timer = setInterval(function () {
							if (new_window.closed) {
								clearInterval(timer);
								checkMandate();

							}
						}, 1000);
					}
					else {
						setPopupBlockers(true);
					}
				}
				else {
					invest(e);
					setApiLoader(false);

				}
			}
			else {
				setToast(response.message);
				setApiLoader(false);
			}
		})
	}

	// INVEST()
	const invest = async (e) => {
		e.preventDefault();
		let valid = await checkMInAmountValidation();
		if (valid) {
			setApiLoader(true);
			let url = devconfig.user + '/mf/invest';
			let data = JSON.stringify({
				"type": orderType,
				'amount': tabIndex === 1 ? parseInt(lumpsumAmount) : parseInt(sipAmount),
				"sip_date": orderType === 2 ? date : null,
				"payment_type": selectedBank === true ? 2 : 1

			})
			// if (tabIndex === 2) {

			// }
			APIPost(url, data).then((response) => {
				if (response.status_code === 200) {

					// setOnboardFlag(true);
					setApiLoader(false);

					/*
					if (orderType == 2) {
						$('#invest').trigger('click');
						if (props.location == 'portfolio') {
							window.location.href = '/overview';
						} else {
							props.callBack();
						}
						if (props.location === 'overview') {
							props.callBack();
						}
						return false
					}
					*/
					// setPayment_window(response.data.payment_url)
					// if (tabIndex === 2) {
					var payment_window = window.open(response.data.payment_url);
					if (payment_window !== null) {

						// payment_window.location = ;
						setPaymentWindowSip(false)
						setPaymentWindowLumpsum(false)
						var timer = setInterval(function () {
							if (payment_window.closed) {
								clearInterval(timer);
								paymentStatus();
							}
						}, 300);


					}
					else {
						setPopupBlockers(true);
					}
					// } else {
					//     $('#invest').trigger('click');
					//     paymentStatus()
					// }


				}
				else {
					setApiLoader(false);
					setToast(response.message);


				}
			})
		}
	}

	const _trackInvestmentDetails = (status) => {
		var today = new Date();
		var date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`
		window.analytics.identify(userData.user_id, {
			invested_status: status === "SUCCESS" ? true : false,
			investment_plan: tabIndex === 1 ? 'lumpsum' : 'sip',
			last_investeddate: date,
		});
	}


	// //lead squared activity tracker 
	// const _activityTracker = (analysis, score) => {
	// 	let trackerData = [
	// 		{
	// 		  SchemaName: 'mx_Custom_3',
	// 		  Datatype: "SearchableDropdown",
	// 		  Value: 'Yes'
	// 		},
	// 	]
	// 	LeadSquared._activityTracker('Invested', trackerData, 204)
	// }

	//This will have all the data of the user for his investment
	const _subscriptionEventTrack = (status) => {
		window.analytics.track("Subscription started", {
			user_id: userData.user_id,
			investmentamount: tabIndex === 1 ? parseInt(lumpsumAmount) : parseInt(sipAmount),
			payment_type: selectedBank ? 'netbanking' : 'upi',
			investment_activation: status === "SUCCESS" ? true : false
		});
	}

	// check minimum amount validation
	const checkMInAmountValidation = () => {
		if (tabIndex === 2) {
			if (sipAmount < minSIP) {
				setToast(`SIP amount should be greater than ${minSIP}`);
				return false;
			} else if (sipAmount > maxAmount) {
				setToast(`SIP amount should be lesser than ₹${maxAmount}`);
				return false;
			}
		}
		if (tabIndex === 1) {
			if (lumpsumAmount < minLumpsum) {
				setToast(`Lumpsum amount should be greater than ${minLumpsum}`);
				return false;
			} else if (lumpsumAmount > maxAmount) {
				setToast(`Lumpsum amount should be lesser than ₹${maxAmount}`);
				return false;
			}
		}
		return true;
	}

	const paymentStatus = () => {
		let url = devconfig.user + '/investment/payment-status';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				_subscriptionEventTrack(response.data.status);
				_trackInvestmentDetails(response.data.status);
				// _activityTracker();
				// window.location.href="/home"
				if (props.location === 'overview' || props.location === "asset" || props.location === 'sip') {

					props.callBack(response.data.status, tabIndex === 1 ? parseInt(lumpsumAmount) : parseInt(sipAmount));
					$('#invest').trigger('click');
				} else if (props.location === "portfolio") {

					props.updateContext(response.data.status, tabIndex === 1 ? parseInt(lumpsumAmount) : parseInt(sipAmount));
					$('#invest').trigger('click');
				}
				/*
				if (props.location == 'portfolio' && response.data.signup_status === "INVESTED") {
					window.location.href = '/overview';
				} else {
					props.callBack();
				}
				if (props.location === 'overview' || props.location === "asset") {
					props.callBack();
				}*/
			}
			else {
				// setCreate_Mandate(true);
			}
		});
	}



	// CREATE MANDATE ()
	// if user does not have an active mandate, then here create new mandate
	const createMandate = (e) => {
		e.preventDefault();
		setApiLoader(true);
		setCheckingMandate(true)
		let url = devconfig.user + '/mandate/create';
		let data = JSON.stringify({
			amount: sipAmount
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.data.mandate_url !== null) {
					setApiLoader(false);
					// let newTab = window.open();
					//  newTab.location.href = url;
					var new_window = window.open();


					if (new_window !== null) {
						setPaymentWindowLumpsum(false);
						setPaymentWindowSip(false);
						new_window.location.href = response.data.mandate_url
						new_window.opener = null;
						new_window.blur();
						window.focus();
						var timer = setInterval(function () {
							if (new_window.closed) {
								clearInterval(timer);
								checkMandate();

							}
						}, 1000);
					}
					else {
						setPopupBlockers(true);
					}
				}
				else {
					checkMandate();
					setApiLoader(false);

				}
			}
			else {
				setToast(response.message);
				setApiLoader(false);
			}
		})

	}

	const closeModal = () => {
		// var modal = new Modal(document.getElementById("invest"), {});
		// modal.show();
		$('#invest').trigger('click');
		// $('.modal-backdrop').remove();
	}

	function updateStatus() {
		setCanInvest(true);
	}

	// update investment if user edit | delete risk score 

	const _updateInvestment = async (e) => {
		e.preventDefault();
		let valid = await checkMInAmountValidation();
		if (valid) {
			setApiLoader(true);
			let url = devconfig.user + '/mf/update-investment';
			// let data = JSON.stringify({
			// 	"type": orderType,
			// 	'amount': tabIndex === 2 ? parseInt(sipAmount) : parseInt(lumpsumAmount),
			// 	"sip_date": orderType === 2 ? date : null,
			// 	"payment_type": selectedBank === true ? 2 : 1

			// })
			APIGet(url).then((response) => {
				if (response.status_code === 200) {
					setApiLoader(false);
					props.updateContext(response.data.status);
					$('#invest').trigger('click');
				}
				else {
					setApiLoader(false);
					setToast(response.message);
				}
			})
		}
	}
	return (
		<>
			<div className={`${styles.e_invest_modal} modal fade`} id="invest" tabIndex="-1" aria-labelledby="invest" aria-hidden="true">
				<div className={`modal-dialog modal-dialog-centered ${styles.e_dialog}`}>

					<div className={`${styles.e_modal_content} modal-content`}>
						<div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
							<h5 className=''>Invest Now</h5>
							<img
								src={Close}
								className={`${styles.e_modal_close}`}
								data-bs-dismiss="modal"
							/>
						</div>

						{/* SUCCESS SCREEN */}
						{
							createMandateSuccess ?
								<div className='modal-body  '>
									<div className="container px-3">
										<div className="row">
											<div className="col-lg-12 text-center">
												{
													hasMandate ?
														<img src={Success} alt="" className={`${styles.e_success_icon}`} style={{ marginTop: "60px" }} />
														:
														<img src={Failed} alt="f" className={`${styles.e_success_icon}`} style={{ marginTop: "60px" }} />
												}

											</div>
										</div>
										<div className="row">
											<div className="col-lg-12 text-center">
												<p className={styles.e_success_text}>
													{
														hasMandate ?
															'Mandate created successfully'
															:
															'Mandate creation failed'
													}
												</p>
											</div>
										</div>
										<div className="row  pt-5 mt-3 pb-2">
											<div className="col-lg-12 ">
												{/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >hi</button> */}
												{
													hasMandate ?
														<div className='d-flex align-items-end justify-content-center'>
															<PrimaryBtn name="Invest now" className="e-modal-submit" handleClick={(e) => { setCreateMandateSuccess(false); invest(e) }} />
														</div>
														:
														<div className='d-flex align-items-end justify-content-center'>
															<PrimaryBtn name="Create mandate" className="e-modal-submit" handleClick={() => setCreateMandateSuccess(false)} />
														</div>
												}
											</div>
										</div>

									</div>
								</div>
								:
								<div className={`${styles.e_invest_modal_body} container p-4 e-onboard-container`}>
									<div className="row mb-4 pb-2">
										<div className="col-lg-12 text-center">
											<Tab
												data={props.location === 'sip' ? ['Monthly SIP'] : [' Lumpsum', 'Monthly SIP']}
												tabIndex={tabIndex}
												center={true}
												setTab={(temp) => {
													if (props.location === 'sip') {
														return;
													} else {
														setTabindex(temp);
														setOrderType(temp);
														setAcceptTerms(false);
													}

												}}
											/>
										</div>
									</div>

									{
										tabIndex === 1 ?
											// LUMPSUM TAB
											<form>
												<div className="row">
													<div className="col-lg-12 mb-3">
														{
															editPortfolio ?
																<p className="">
																	All previous SIPs will be cancelled. The new allocation will applicable from next SIP onwards.
																</p>
																:
																<p className="">What's SIP and Lumpsum? <img src={TooltipIcon} className={`${styles.e_tooltip_hover} ms-1`} onMouseEnter={() => setIsShown(true)}
																	onMouseLeave={() => setIsShown(false)} />
																</p>
														}

														{
															isShown ?
																<IconStyleToolTip className={styles.e_tooltip} icon={Tooltip} content="A Systematic Investment Plan (SIP) allows an investor to invest a fixed amount of money at pre-defined intervals in a selected mutual fund. This works great if you want to invest a fixed amount of your savings every month.<br /><br /> A lumpsum is a single, larger, one-time investment. This makes sense if you've saved up some money that you've been meaning to invest in a while.<br /><br /> Need to do both? That's fine! Start with one and you can easily add the other from our dashboard later. " />
																:
																null
														}
													</div>
												</div>
												<div className="row">
													<div className="col-lg-6 position-relative ">
														<label className="mb-2">Lumpsum Amount<span className='star px-1'> * </span></label>
														<Input
															id="lumpsum"
															label="Enter amount"
															value={lumpsumAmount}
															className={`${styles.e_amount_input}`}
															valueChange={(value) => { setLumpsumAmount(value); }}
															type={lumpsumAmount == 0 ? "text" : "number"}
														/>
														<span className={`${styles.e_rupee} e-invest-rupee`}>₹</span>
														<p className={`${styles.e_to_withdraw} mt-2`}>Min amount <span className='e-rupee-symbol'>₹</span> {minLumpsum ? minLumpsum : 0}</p>
													</div>
													{
														!editPortfolio ?
															<div className='row'>
																<label className="mb-1">Select Payment Type<span className='star px-1'> * </span></label>
																<div className="e-radio-buttons">
																	{
																		is_HDFC_User === false && (
																			<RadioButton
																				upiId={1}
																				label="Net Banking"
																				wrapperClass="e-invest-modal-radio"
																				checked={selectedBank}
																				onClick={(e) => {
																					setSelectedBank(true);

																				}}
																				name="tax"
																			/>
																		)
																	}

																	<RadioButton
																		upiId={2}
																		label="UPI"
																		wrapperClass="e-invest-modal-radio"
																		checked={selectedBank === false}
																		disabled={parseInt(lumpsumAmount) >= 100000 ? true : false}
																		onClick={(e) => {
																			setSelectedBank(false);

																		}}
																		name="tax"
																	/>
																</div>
															</div>
															:
															null
													}
													{
														// !editPortfolio &&(
														<div className='col-12'>
															<div className="e-onboard-sub-info px-3 py-2">
																<div className="row">
																	<div className="col-11">
																		<h5>
																			<img src={HDFC} className="me-2" />
																			HDFC bank users only:
																		</h5>
																	</div>
																	<div className="col-1">
																		<img src={Flag} />
																	</div>
																	<div className='co-12'>
																		<p className={`${styles.e_info}`}>
																			<b>Please use the UPI method to invest</b>, as HDFC net banking is currently not supported by our payment partner. We will resolve this soon.
																		</p>
																	</div>
																</div>
															</div>
														</div>
														// )
													}
													{
														editPortfolio ?
															<RebalanceCard />
															:
															<div className="col-lg-12 mt-4">
																<div className={`${styles.e_amount_wrpper}`}
																	style={{ cursor: 'pointer', height: opened === true && '350px', overflow: opened === true && 'auto' }}
																	onClick={() => setOpened(!opened)}
																>
																	<div className="row">
																		<div className="col-lg-11 col-11">
																			<p className={`${styles.e_title}`}>Where we will invest your money?</p>
																		</div>
																		<div className='col-lg-1 col-1'>
																			<img
																				style={{ transform: opened === true && 'rotate(180deg)' }}
																				src={DownArrow}
																			/>
																		</div>
																	</div>
																	<div className="row ">
																		<div className="col-lg-6 col-4">
																			<h6 className='text-start ps-0'>Fund Name</h6>
																		</div>
																		<div className="col-lg-3 col-4 text-center ">
																			<h6 className='text-center ps-0'>Quantity</h6>
																		</div>
																		<div className="col-lg-3 col-4 text-end">
																			<h6>Amount</h6>
																		</div>
																	</div>
																	<div className="row pb-2">
																		<div className="col-lg-12">
																			{/* <div className={`${styles.e_border_bottom}`}></div> */}
																		</div>
																	</div>
																	{
																		assetDetail && assetDetail.mf.map((item, key) => {
																			return (
																				<div className='row' key={key}>
																					<div className={`col-lg-6 ${styles.e_content} col-4`}>
																						{/* <p className={`${styles.e_asset_class} mt-2`}>{item.display_name}</p> */}
																						<span className={styles.e_scheme}>{item.scheme_name}</span>
																					</div>
																					<div className={`col-lg-3 ${styles.e_content_qty} text-center col-4`}>
																						<p className='mt-3'>{item.allocation}%</p>
																					</div>
																					<div className={`col-lg-3 ${styles.e_content_qty} text-end col-4`}>
																						<p className='mt-3'><span className='e-rupee-symbol' style={{ fontWeight: '500', fontSize: '12px' }}>₹</span> {(item.allocation * lumpsumAmount) / 100}</p>
																					</div>
																				</div>
																			)
																		})
																	}
																</div>
															</div>
													}
													<div className="row my-3 pt-3 pe-0">
														{/* {
												props.location == 'portfolio' && !editPortfolio ?
													<div className='e-accept-terms d-flex mt-3'>
														<CheckBox 
															onChange={()=> setAcceptTerms( !acceptTerms)}
														/>
														<p>
															By verifying the payment verification through the bank OTP, I am giving consent for the creation of a folio (investor account).
														</p>
													</div>
												:
													null
											} */}

														<div className="col-lg-12  ">
															{/* LUMPSUM BUTTON */}
															{
																hasMandate ?
																	<>
																		{
																			lumpsumAmount ?
																				<PrimaryBtn name={apiLoader ? "Investing" : "Invest Now"}
																					image={Arrow}
																					right={!apiLoader && true}
																					className={apiLoader ? "e-btn-loader " : ""}
																					iconClass="ms-2"
																					// disabled={
																					// 	props.location == 'portfolio' && !editPortfolio ?
																					// 		!acceptTerms
																					// 	:
																					// 		false
																					// }
																					handleClick={(e) => {
																						// editPortfolio ? _updateInvestment(e) : invest(e)
																						invest(e)
																					}} />
																				:
																				<PrimaryBtn name={apiLoader ? "Investing" : "Invest Now"}
																					disabled={true}
																					image={Arrow}
																					iconClass="ms-2"
																					right={!apiLoader && true}
																					className={apiLoader ? "e-btn-loader e-modal-submit" : "e-modal-submit"}
																					handleClick={(e) => invest(e)} />
																		}
																	</>
																	:
																	// <PrimaryBtn name={apiLoader ? "Creating " : "Create Mandate"} className={apiLoader ? "e-btn-loader e-modal-submit" : 'e-modal-submit'} handleClick={(e) => createMandate(e)} />
																	<PrimaryBtn name={apiLoader ? "Investing" : "Invest Now"}
																		// disabled={
																		// 	props.location == 'portfolio' && !editPortfolio ?
																		// 		!acceptTerms
																		// 	:
																		// 		false
																		// }
																		iconClass="ms-2"
																		right={!apiLoader && true}
																		image={Arrow}
																		className={apiLoader ? "e-btn-loader e-modal-submit " : "e-modal-submit"}
																		handleClick={(e) => invest(e)} />
															}

														</div>

													</div>
												</div>
											</form>
											:
											null
									}

									{
										tabIndex === 2 ?
											// SIP TAB
											<form id="sipForm" >
												<div className="row">
													<div className="col-lg-12 mb-3">
														{
															editPortfolio ?
																<p className="">
																	All previous SIPs will be cancelled. The new allocation will applicable from next SIP onwards.
																</p>
																:
																<p className="">What's SIP and Lumpsum? <img src={TooltipIcon} onMouseEnter={() => setIsShown(true)}
																	onMouseLeave={() => setIsShown(false)} className='ms-1' />
																</p>
														}

														{
															isShown == true &&
															<IconStyleToolTip className={`${styles.e_tooltip} e-invest-tooltip`}
																icon={Tooltip}
																content="A Systematic Investment Plan (SIP) allows an investor to invest a fixed amount of money at pre-defined intervals in a selected mutual fund. This works great if you want to invest a fixed amount of your savings every month.<br /><br /> A lumpsum is a single, larger, one-time investment. This makes sense if you've saved up some money that you've been meaning to invest in a while.<br /><br /> Need to do both? That's fine! Start with one and you can easily add the other from our dashboard later. " />
														}
													</div>
												</div>

												<div className="row ">
													<div className="col-lg-6 position-relative">
														<label className="mb-2">SIP Amount<span className='star px-1'> * </span></label>
														<Input id="sipInput"
															autocomplete="off"
															label="Enter amount"
															value={sipAmount}
															className={`${styles.e_amount_input}`}
															valueChange={(value) => {
																setSipAmount(value);
															}}
															type={sipAmount == 0 ? 'text' : "number"} />
														<span className={`${styles.e_rupee} e-invest-rupee`}>₹</span>
														<p className={`${styles.e_to_withdraw} mt-2`}>Min amount <span className='e-rupee-symbol'>₹</span> {minSIP ? minSIP : 0}</p>
													</div>

													<div className="col-lg-6">
														<label className="mb-2">Monthly SIP Date<span className='star px-1'> * </span></label>
														<DateSelector date={sipDate}
															selectedDate={(temp) => setDate(temp)}
															disableSelect={false} />
													</div>
												</div>
												{
													!editPortfolio ?
														<div className='row'>
															<label className="mb-1">Select Payment Type<span className='star px-1'> * </span></label>
															<div className="e-radio-buttons">
																{
																	is_HDFC_User === false && (
																		<RadioButton upiId={1}
																			label="Net Banking"
																			name="tax"
																			wrapperClass="e-invest-modal-radio"
																			checked={selectedBank}
																			onClick={(e) => {
																				setSelectedBank(true);
																			}}
																		/>
																	)
																}

																<RadioButton upiId={2}
																	label="UPI     "
																	name="tax"
																	wrapperClass="e-invest-modal-radio"
																	checked={selectedBank === false}
																	disabled={parseInt(sipAmount) >= 100000 ? true : false}
																	onClick={(e) => {
																		setSelectedBank(false);
																	}} />
															</div>
														</div>
														:
														null
												}
												{
													// !editPortfolio &&(
													<div className='col-12'>
														<div className="e-onboard-sub-info px-3 py-2">
															<div className="row">
																<div className="col-11">
																	<h5>
																		<img src={HDFC} className="me-2" />
																		HDFC bank users only:
																	</h5>
																</div>
																<div className="col-1">
																	<img src={Flag} />
																</div>
																<div className='co-12'>
																	<p>
																		<b>Please use the UPI method to invest</b>, as HDFC net banking is currently not supported by our payment partner. We will resolve this soon.
																	</p>
																</div>
															</div>
														</div>
													</div>
													// )
												}
												{
													editPortfolio ?
														<RebalanceCard />
														:
														<>
															{
																props.location === 'sip' ?
																	null
																	:
																	<div className="row mt-3">
																		<div className="col-lg-12">
																			<div className={`${styles.e_amount_wrpper}`}
																				style={{ cursor: 'pointer', height: opened === true && '350px', overflow: opened === true && 'auto' }}
																				onClick={() => setOpened(!opened)}
																			>
																				<div className="row ">
																					<div className="col-lg-11 col-11">
																						<p className={`${styles.e_title}`}>Where we will invest your money?</p>
																					</div>
																					<div className='col-lg-1 col-1'>
																						<img
																							style={{ transform: opened === true && 'rotate(180deg)' }}
																							src={DownArrow}
																						/>
																					</div>
																				</div>
																				<div className="row ">
																					<div className="col-lg-6 col-4">
																						<h6 className='text-start ps-0'>Fund Name</h6>
																					</div>
																					<div className="col-lg-3 col-4 text-center ">
																						<h6 className='text-center ps-0'>Quantity</h6>
																					</div>
																					<div className="col-lg-3 col-4 text-end">
																						<h6>Amount</h6>
																					</div>
																				</div>
																				<div className="row pb-2">
																					<div className="col-lg-12">
																						{/* <div className={`${styles.e_border_bottom}`}></div> */}
																					</div>
																				</div>
																				{
																					assetDetail && assetDetail.mf.map((item, key) => {
																						return (
																							<div className='row' key={key}>
																								<div className={`col-lg-6 ${styles.e_content} col-4`}>
																									{/* <p className={styles.e_asset_class}>{item.display_name}</p> */}
																									<span className={styles.e_scheme}>{item.scheme_name}</span>
																								</div>
																								<div className={`col-lg-3 ${styles.e_content_qty} text-center col-4`}>
																									<p className='mt-3'>{item.allocation}%</p>
																								</div>
																								<div className={`col-lg-3 ${styles.e_content_qty} text-end col-4`}>
																									<p className='mt-3'><span className="e-rupee-symbol" style={{ fontWeight: '500', fontSize: '12px' }}>₹</span> {(item.allocation * sipAmount) / 100}</p>
																								</div>
																							</div>
																						)
																					})
																				}
																			</div>
																		</div>
																	</div>
															}
														</>

												}

												<div className="row my-3 pt-3 pe-0">
													{
														hasMandate === false ?
															<div className='col-lg-12 mb-4'>
																<div className="e-onboard-sub-info p-3 	">
																	<div className="row">
																		<div className="col-11 mt-1">
																			<p>
																				You will be redirected to your bank's page to create an e-mandate.
																				We require this to rebalance your portfolio periodically.
																				Rebalancing means selling some funds to buy others.
																				The mandate allows us to draw funds from your account to make the required purchases from the sales proceeds.
																				The reason the mandate is for 1L is to account for portfolios of all sizes. It does not have any relation to the amount you're investing.
																				We can't draw any funds from your account without your explicit approval.
																			</p>
																		</div>
																		<div className="col-1">
																			<img src={Flag} />
																		</div>
																	</div>
																</div>
																{/* <CardIconStyleToolTip 
														bgColor="#EDF3FF" 
														icon={Tooltip} 
														content="You will be redirected to your bank's page to create an e-mandate. 
															We require this to rebalance your portfolio periodically. 
															Rebalancing means selling some funds to buy others. 
															The mandate allows us to draw funds from your account to make the required purchases from the sales proceeds.
															The reason the mandate is for 1L is to account for portfolios of all sizes. It does not have any relation to the amount you're investing. 
															We can't draw any funds from your account without your explicit approval." 
													/> */}
															</div>
															:
															null
													}

													{/* {
											props.location == 'portfolio' && !editPortfolio ?
												<div className='e-accept-terms d-flex mt-3'>
													<CheckBox 
														onChange={()=> setAcceptTerms( !acceptTerms)}
													/>
													<p>
														By verifying the payment verification through the bank OTP, I am giving consent for the creation of a folio (investor account).
													</p>
												</div>
											:
												null
										}
										
									 */}
													<div className="col-lg-12 text-end e-invest-btn">
														{/* SIP BUTTON */}
														{
															hasMandate === true ?
																<>
																	{
																		sipAmount ?
																			<>
																				<PrimaryBtn
																					name={apiLoader ? "Investing" : "Invest"}
																					className={apiLoader ? "e-btn-loader e-modal-submit" : "e-modal-submit"}
																					image={!apiLoader && Arrow}
																					iconClass="ms-2"
																					right={true}
																					// disabled={
																					// 	props.location == 'portfolio' && !editPortfolio ?
																					// 		!acceptTerms
																					// 	:
																					// 		false
																					// }
																					handleClick={(e) => {
																						// editPortfolio ? _updateInvestment(e) : invest(e)
																						invest(e);
																						//commented dynamic mandate creation, invest if bank mandate enable 22/09/2002 
																						// recheck(e);
																					}}
																				/>

																			</>
																			:
																			<PrimaryBtn
																				name={apiLoader ? "Investing" : "Invest"}
																				// disabled={true} 
																				image={!apiLoader && Arrow}
																				iconClass="ms-2"
																				right={true}
																				className={apiLoader ? "e-btn-loader e-modal-submit" : "e-modal-submit"}
																				handleClick={(e) => {
																					invest(e)
																					// editPortfolio ? _updateInvestment(e) : invest(e)
																					// recheck(e);

																				}}
																			/>
																	}
																</>
																:

																<PrimaryBtn
																	name={apiLoader ? "Creating " : "Create Mandate"}
																	className={apiLoader ? "e-btn-loader e-modal-submit" : 'e-modal-submit'}
																	image={!apiLoader && Arrow}
																	iconClass="ms-2"
																	right={true}
																	// disabled={
																	// 	props.location == 'portfolio' && !editPortfolio ?
																	// 		!acceptTerms
																	// 	:
																	// 		false
																	// }
																	handleClick={(e) => { createMandate(e) }}
																	disable={checkingMandate}
																/>
														}

													</div>
													<div className='col-lg-12 text-end'>

													</div>
												</div>
											</form>
											:
											null

									}
									{
										popupBlockers ?
											<div className='row'>
												<div className='col-12'>
													<p style={{ color: "red", paddingTop: "30px" }}>Disable popup blocker to continue with Payment Gateway</p>
												</div>
											</div>
											:
											null
									}
								</div>
						}



					</div>
				</div>
			</div>
			{
				toast ?
					<Toast data={toast} type="fail" />
					:
					null
			}
		</>
	)
}

{/* <Input placeholder="Question" value={props.edit && props.data?props.data.title : question} valueChange={props.edit? (value)=>props.setData("title",value):(value) => { setQuestion(value) }} type="text"/> */ }
