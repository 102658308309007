import { useState, useEffect } from 'react';
import $ from 'jquery';

import styles from '../../styles/components/loaderModal.module.scss';

import Loader from '../../assets/images/new-design/portfolioLoader.gif';
import TrendingUp from '../../assets/images/new-design/returns-up.svg';
import Text from '../../assets/images/new-design/file-text-loader.svg';
import DB from '../../assets/images/new-design/database-loader.svg';


const LoaderModal = (props) => {

    const [loader_content, setLoaderContent] = useState([
        {
            text: 'Calculating your returns with Mintd',
            icon : TrendingUp,
        },
        {
            text: 'Analyzing your answers',
            icon : Text,
        },
        {
            text: 'Building a  cutomized portfolio for you',
            icon : DB,
        },
    ]);

    let timer;


    useEffect(()=>{
        setTimeout(()=>{
            $('#loader-modal').trigger('click');
        },5000)
        
    },[])

    useEffect(()=>{
        timer = setInterval(()=>{ 
            let temp = loader_content;
            let shifted = temp.shift();
            temp.push(shifted);
            setLoaderContent([...temp]);
        },1000) // update index of loading screen content by 1
    },[])

    useEffect(()=>{
        let modal = document.getElementById('loader-modal');
            modal.addEventListener('hidden.bs.modal',() =>{
                // timer.clearInterval();
                props.modalClose();
                
            })
    },[])

    return (
        <div 
            className={`${styles.e_loader_modal} modal fade`} 
            id="loader-modal"    
            tabIndex="-1" 
            aria-labelledby="LoaderModal" 
            aria-hidden="true" 
        >
            <div className={`modal-dialog modal-dialog-centered ${styles.e_dialog} `}>
                <div className={`${styles.e_content} modal-content position-relative p-4`}>
                    <div className='row mt-4'>
                        <div className='col-12 text-center'>
                            <h5>Please sit back and relax</h5>
                            <p>We are building your portfolio</p>
                            <img 
                                src={Loader}
                                style={{width: '300px'}}
                            />
                        </div>
                        <div className='col-12'>
                        <div className=''>
                                {
                                    loader_content.map((item, key)=> {
                                        return(
                                            <div className={ key ===1 ? `${styles.e_loader_content} mb-3 ps-5` : `mb-3 ps-5` }
                                                style={{ opacity: key === 1 ? '1' : "0.5"}}
                                            >
                                                <img src={  item.icon} className="me-2" />
                                                <span style={{ color: '#000000'  }}>{item.text}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoaderModal;