/*
 *   File : dateRangepicker.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description :  dateRangepicker.js
 *   Integrations : 
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

/*import package*/
import React from 'react';
import 'react-dates/initialize';
import { DateRangePicker, DayPickerRangeController, isInclusivelyAfterDay } from 'react-dates';
import styles from '../../styles/components/rangePicker.module.scss'
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

export default class DateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initalDate: moment().format(),
      startDate: null,
      endDate: null,
    }
  }

  clearDateSelection = () => {
    this.setState({
      startDate: null,
      endDate: null
    });
  }

  onDatesChange = async ({ startDate, endDate }) => {
    this.setState({ startDate: startDate, endDate: endDate })
    if (startDate && endDate) {
      var tempStart = startDate.format("DD-MM-YYYY");
      var tempEnd = endDate.format("DD-MM-YYYY");
      this.props.dateChange(tempStart, tempEnd)
    } else {
      this.props.dateChange(null, null)
    }
  }

  render() {
    return (
      <div className="e-ant-range-picker">
        <DateRangePicker
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onDatesChange={this.onDatesChange.bind(this)}
          focusedInput={this.state.focused}
          onFocusChange={focusedInput => this.setState({ focused: focusedInput })}
          isOutsideRange={(day => isInclusivelyAfterDay(day, moment().add(2, 'days')))}
          small={true}
          displayFormat='DD-MM-YYY'
          showDefaultInputIcon
          inputIconPosition="after"
          showClearDates={true}
        // startDatePlaceholderText="dd/mm/yyy"
        // endDatePlaceholderText="dd/mm/yyy"
        />
      </div>
    );
  }
}

