
/*
 *   File : tab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
*/

/*import package*/
import { useState, useEffect } from 'react';

/*import styles*/
import styles from '../../styles/components/tab.module.scss';

const Tab = (props) => {
	const [index, setIndex] = useState(1);

	useEffect(() => {
		setIndex(props.tabIndex)
	}, [props.tabIndex]);

	return (
		<div className={props.center ? "d-flex justify-content-center e-faq-tab-sec" : "d-flex"}>
			{
				props.data.map((item, key) => {
					return (
						<div
							className={index === (key + 1) ? `${styles.e_active_tab} me-4 ` : `${styles.e_tab} me-4`}
							onClick={() => { setIndex((key + 1)); props.setTab((key + 1)) }} key={key}
						>
							{item}
						</div>
					)
				})
			}
		</div>
	)
}

export default Tab;

