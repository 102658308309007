// import packages
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import service
import APIGet from '../../services/getApi';
import Config from '../../config/config';

// import component
import { PrimaryBtn } from '../../components/cta';
import { Toast } from '../../components/note';
import Arrow from '../../assets/images/onboard/submit.svg';

const ESign = (props) => {
    const [KYCStatus, setKYCStatus] = useState(false);
	const [kycStatusLoader, setKYCStatusLoader] = useState(false);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    let navigate = useNavigate();
    
    useEffect(() =>{
        _getKYCStatus()
    },[])
    
    useEffect(()=>{
        setTimeout(()=>{
            setError(null);
        })
    },[error])
    // get kyc  status
    const _getKYCStatus = () => {
        setKYCStatusLoader(true);
        let url = Config.user + '/auth/get-kyc';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                if (response.data.status === 'REJECTED') {
                    navigate('/onboard');
                } else {
                    setKYCStatus(response.data);
                    setKYCStatusLoader(false);
                }
            } else {
                setKYCStatusLoader(false);
            }
        })
    }

    const createEsign = () => {
		let url = Config.user + '/auth/create-esign';
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				let e_sign_window = window.open();
				e_sign_window.location.href = response.data.redirect_url;
				var timer = setInterval(function () {
					if (e_sign_window.closed) {
						clearInterval(timer);
						props.success();
					}
				}, 1000);
			}
			else {
				setError(response.message);
			}
		})
	}

    return(
        <div className="col-lg-9 col-md-9 col-sm-12 col-12 e-mobile-confirm-wrap pb-5 mb-5">
            <div className="e-process-wrap position-relative">
                <h5 className="e-onboard-form-title mt-2">
                    Account opening agreement
                </h5>
                <div className="row mt-3">
                    <div className="col-lg-12">
                        <div className="e-wrapper e-acct-agreement">
                            <div className="row">
                                <div className="col-lg-11">
                                    <p className='e-ac-open-agreement'>
                                        You need to sign an onboarding document to join our platform. 
                                        Click on the button to sign the document.
                                    </p>
                                </div>
                                {
                                    kycStatusLoader ?
                                        <div className='col-lg-12  mt-3'>
                                            <p className='e-btn-loader'><span>Loading</span></p>
                                        </div>
                                        :
                                        <div className="col-lg-12  mt-3">
                                            {
                                                KYCStatus?.status == "ESIGN_REQUIRED" ?
                                                    <>
                                                        {
                                                            loader == false ?
                                                                <PrimaryBtn
                                                                    name="E-sign document"
                                                                    className="e-sign-btn"
                                                                    image={Arrow}
                                                                    right={true}
                                                                    iconClass="ms-2"
                                                                    handleClick={() => {
                                                                        createEsign()
                                                                    }}
                                                                    disabled={KYCStatus?.status !== "ESIGN_REQUIRED" ? true : false}
                                                                />
                                                                :
                                                                <PrimaryBtn
                                                                    name="Generating document..."
                                                                    className="e-sign-btn"
                                                                    disabled={true}
                                                                />
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                    { 
                                                        KYCStatus?.status !='VERIFIED' ?
                                                        <p className='e-kyc-error-status' style={{ color: ' #d52828' }}>{KYCStatus?.message}</p>
                                                        :
                                                        null
                                                    }
                                                    </>
                                                    
                                            }
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ESign;