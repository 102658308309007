
// Package import 
import { useState, useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

// Style import 
import styles from '../../styles/components/editScoreConfirmation.module.scss';

// Asset import
import Confirmation from '../../assets/images/new-design/edit-score.svg';
import Flag from '../../assets/images/new-design/flagYellow.svg';

import Close from '../../assets/images/new-design/modalClose.svg';

// Component import 
import { PrimaryBtn, SecondaryBtn  } from '../cta/index';
import { Toast } from '../note';

import Config from '../../config/config';
import APIGet from '../../services/getApi';

const EditScoreConfirmation = (props) => {

    let navigator = useNavigate();
    const [ apiLoader, setApiLoader ] = useState(false);
    const [ toast, setToast] = useState(null);

    useEffect(() => {
        var myModal = document.getElementById('approved-edit')
        myModal.addEventListener('hidden.bs.modal', () => {
            props.modalClose();
        })
    },[])


    const denyEditScore = () => {
		setApiLoader(true);
		let url = Config.user + "/profile/reject-edit-riskscore";
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
                setApiLoader(false);
				$('#approved-edit').trigger('click');
                props.succuss();
			} else {
				setApiLoader(false);
				setToast(response.message);
			}
		})
		setTimeout(() => {
			setToast(null);
		}, 3000)
	}


    return(
        <div 
            className={`${styles.e_confirmation_modal} modal fade`} 
            id="approved-edit" 
            tabIndex="-1" 
            aria-labelledby="success" 
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                        <h5 className='mt-1'>Edit risk score</h5>
                        <img
                            src={Close}
                            className={`${styles.e_modal_close}`}
                            data-bs-dismiss="modal"
                        />
                    </div>
                    <div className="container p-4">
                    <div className="row ">
                        <div className='col-lg-12 mb-2'>
                            <h6 >Edit your risk score and portfolio</h6>
                        </div>
                        <div className='col-12'>
                            <div className="e-onboard-sub-info p-3 mb-4">
                                <div className="row">
                                    <div className="col-1">
                                        <img src={Confirmation} />
                                    </div>
                                    <div className="col-10 mt-1">
                                        <h5 className={`${styles.e_info_title} ps-2`}>
                                            Important notes regarding your risk score
                                        </h5>
                                    </div>
                                    <div className="col-1">
                                        <img src={Flag} />
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <p className={`${styles.e_info}`}>
                                            Editing your score has an impact on your portfolio. We
                                            will make the necessary changes by selling portions of
                                            your existing funds to buy new ones if you continue.
                                            Your total invested amount won't change, only your
                                            allocations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <SecondaryBtn 
                                name={ apiLoader ? 'Loading' : "Don't change my risk score"}
                                className={ apiLoader ? `${styles.e_s_btn} e-btn-loader w-100` : `${styles.e_s_btn} w-100`}
                                handleClick={() => { denyEditScore()}}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <PrimaryBtn 
                                name="Continue"
                                className="w-100"
                                handleClick={() => {
                                    $('#approved-edit').trigger('click');
                                    navigator('/portfolio')
                                }}
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {
                    toast && (
                        <Toast data={toast}/>
                    )
                }
            </div>
    )
}

export default EditScoreConfirmation;