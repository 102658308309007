/*
 *   File : addNominee.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add Nominee
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { Select } from '../input/index';
import Input from '../input/loginInput';
import { PrimaryBtn, SecondaryBtn } from '../cta/index';
import Uploader from '../fileUploader/upload';
import { CardIconStyleToolTip } from '../tooltip/index';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/* import services */
import devconfig from '../../config/config';
import COLOUR from '../../config/color';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import Close from '../../assets/images/new-design/modalClose.svg';
import Flag from '../../assets/images/new-design/flagYellow.svg';

/* import assests */
import Verify from '../../assets/images/new-design/successGif.gif';
import UploadIcon from '../../assets/images/onboard/upload.svg';
import { Toast } from '../note';
import { Modal } from 'bootstrap';
import Failed from '../../assets/images/new-design/failGif.gif';
import Tooltip from '../../assets/images/tooltip/tooltip.svg';

/* import assets */
export default function AddBank(props) {

    const [name, setName] = useState("");
    const [accountNumber, setAccountNumber] = useState(null);
    const [ifsc, setIfsc] = useState(null);
    const [accountType, setAccountType] = useState({ label: "Account Type", value: null });
    const [apiLoader, setApiLoader] = useState(false);
    const [error, setError] = useState(null);
    const [acOptions, setAccOptions] = useState([]);
    const [bankAdded, setBankAdded] = useState(false);
    const [fileName, setFileName] = useState("");
    const [uploading, setUploading] = useState(false);
    const [fileUrl, setFIleUrl] = useState("");
    const [loader, setLoader] = useState(false);
    const [popupBlockers, setPopupBlockers] = useState(false);
    const [mandateStatus, setMandateStatus] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        var myModalEl = document.getElementById('editBank')
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            clearFields()
            setPopupBlockers(false);
        })
    }, [])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 5000)
        }
    }, [error])

    // useEffect(() => {
    //     setName(props.data)
    // })


    const getData = () => {
        var tempRelation = [];
        let url = devconfig.user + '/app/get-generaldata';
        APIGet(url).then((response) => {
            if (response?.status_code === 200) {
                response.data.bank_account_types.map((item, key) => {
                    tempRelation.push({ value: item.id, label: item.display_name })
                })
                setAccOptions(tempRelation);

            }
            else {
                setError(response?.message)
            }
        })
    }

    useEffect(() => {
        if (name !== "" && name.length != 0) {
            clearFormValidationError('holderName');
        }
    }, [name]);

    useEffect(() => {
        if (accountNumber !== null) {
            clearFormValidationError('accountNo');
        }
    }, [accountNumber]);

    useEffect(() => {
        if (ifsc != null) {
            clearFormValidationError('ifsc');
        }
    }, [ifsc]);

    // useEffect(() => {
    //     if (!bankAdded) {
    //         var modal = new Modal(document.getElementById("paymentSucess"), {});
    //         modal.show()
    //     }
    // }, [bankAdded])
    /* validation to highlight fields */
    const showFormValidationError = (field) => {
        var ele = document.getElementById(field);
        ele.style.border = `solid 1.5px ${COLOUR.red}`
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* clearing validation fields */
    const clearFormValidationError = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.style.border = null;
        }
    }

    /* validation for select */
    const showFormValidationSelect = (field) => {
        const ele = document.getElementById(field);
        ele.className = "e-select-error"
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* clearing validation for select */
    const clearFormValidationSelect = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.className = "e-select-wraper";
        }
    }

    const validateFields = () => {
        if (accountNumber === null || accountNumber?.length < 2) {
            setError("Please enter account number");
            showFormValidationError("accountNo")
            return false
        } else if (name.length < 2) {
            setError("Minimum 2 characters required for name");
            showFormValidationError("holderName")
            return false;
        } else if (ifsc === null) {
            setError("Please enter valid IFSC");
            showFormValidationError("ifsc");
            return false;
        } else if (accountType.value === null) {
            setError("Please select account type");
            showFormValidationSelect("accountType");
            return false;
        }
        return true
    }

    const validateForm = () => {
        let status = validateFields()
        if (status) {
            addBankDetails()
        }
    }

    // adding bank details 
    const addBankDetails = async () => {
        setApiLoader(true);
        let url = devconfig.user + '/profile/add-bank';
        let data = JSON.stringify({
            account_number: accountNumber,
            account_holder: name,
            ifsc_code: ifsc,
            account_type: accountType.value,
            proof: null
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                var new_window = window.open(response.data.mandate_url);
                if (new_window !== null) {
                    var timer = setInterval(function () {
                        if (new_window.closed) {
                            clearInterval(timer);
                            checkMandate();
                        }
                    }, 300);
                }
                else {
                    setPopupBlockers(true);
                    setApiLoader(false)
                }
            } else {
                setApiLoader(false);
                setError(response.message)
            }
        })
    }

    const checkMandate = () => {
        let url = devconfig.user + '/bank/mandate-status';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setBankAdded(true);
                setMandateStatus(response.data.status);
            } else {
                setBankAdded(true);
            }
        })
    }


    const clearFields = () => {
        props.Close()
        setName("");
        setAccountNumber(null);
        setAccountType({ label: "Select account type", value: null });
        setIfsc(null);
    }

    return (
        <>
            <div
                className={`${styles.e_edit_modal}  modal fade`}
                id="editBank"
                tabIndex="-1"
                aria-labelledby="success"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    {
                        !bankAdded ?
                            <div className={`${styles.e_modal_content} modal-content`}>
                                <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                                    <h5 className='mt-1'>Change Bank Details</h5>
                                    <img
                                        src={Close}
                                        className={`${styles.e_modal_close}`}
                                        data-bs-dismiss="modal"
                                    />
                                </div>
                                <div className="container p-4">
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <label>New Account Number</label>
                                            <Input
                                                id="accountNo"
                                                value={parseInt(accountNumber)}
                                                valueChange={(value) => {
                                                    setAccountNumber(value)
                                                }}
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-lg-12 pt-1 mt-3">
                                            <label>Account Holder Name</label>
                                            <Input
                                                id="holderName"
                                                value={name}
                                                valueChange={(value) => {
                                                    setName(value)
                                                }}
                                                type="text"
                                            />
                                        </div>
                                        <div className="col-lg-6  col-md-6 col-12 pt-1 mt-3">
                                            <label>IFCS Code</label>
                                            <Input
                                                id="ifsc"
                                                label="IFSC code"
                                                value={ifsc}
                                                isCapital={true}
                                                valueChange={(value) => {
                                                    setIfsc(value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 pt-1 mt-3">
                                            <label>Account Type</label>
                                            <Select
                                                id="accountType"
                                                options={acOptions}
                                                placeholder="Select"
                                                value={accountType}
                                                className="e-dashboard-card-sort"
                                                selectchange={(temp) => {
                                                    setAccountType(temp);
                                                    clearFormValidationSelect("accountType")
                                                }}
                                            />
                                        </div>
                                        {/* <div className="col-lg-12 mt-3">
                                            <label className="mb-1">Upload a cancelled check or three months of bank statements (please merge the statements into a single file)<span className='star px-1'> * </span></label>
                                            <div id="bankStatement" className="e-uploader mt-2"
                                                onClick={() => {
                                                    $('#e-upload').trigger('click');
                                                    clearFormValidationError("bankStatement");
                                                }}>
                                                {
                                                    uploading ?
                                                        <span className="e-upload-span e-btn-loader">
                                                            <img src={UploadIcon} />
                                                            <span>Uploading</span>
                                                        </span>
                                                        :
                                                        <span className="e-upload-span">
                                                            <img src={UploadIcon} />
                                                            Choose a file<span className="e-note ms-2">(supported formats PDF, PNG, JPEG and max-size 5mb)</span>
                                                        </span>

                                                }
                                                <Uploader
                                                    tempKey="files"
                                                    className="hidden"
                                                    id="e-upload"
                                                    uploadSucess={(temp) => {
                                                        console.log(temp);
                                                        var str = temp.split("/"); setFileName(str[str.length - 1]);
                                                        setFIleUrl(temp);
                                                        setUploading(false);
                                                    }}
                                                    uploadStatus={(temp) => setUploading(temp)}
                                                />
                                            </div>
                                        </div>
                                        {
                                            fileName ?
                                                <div className='col-lg-12 border mt-3  px-3 py-2 e-boarding-file-preview'>
                                                    <h6>Uploading complete</h6>
                                                    <p>{fileName}</p>
                                                </div>
                                                :
                                                null
                                        } */}
                                        <div className='col-lg-10 pt-1 mt-3'>
                                            <p className='mb-0' style={{ fontSize: '12px' }}>We will verify your account by sending it Rs. 1. This is known as penny drop verification.</p>
                                        </div>
                                        <div className='col-lg-12 mt-3'>
                                            <div className="e-onboard-sub-info px-3 py-2">
                                                <div className="row py-3">

                                                    <div className='col-11'>
                                                        <p className={`${styles.e_info}`}>
                                                            All existing SIPs will be cancelled and replaced by new ones with the updated mandate on successful verification.
                                                        </p>
                                                    </div>
                                                    <div className="col-1">
                                                        <img src={Flag} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 mt-2 pb-4">
                                        {
                                            popupBlockers ?
                                                <div className='col-lg-12 my-3'>
                                                    <p style={{ color: "red" }}>Disable popup blocker to continue with Payment Gateway</p>
                                                </div>
                                                :
                                                null
                                        }
                                        <div className='col-lg-6 col-md-6 col-sm-12 mb-lg-0 mb-3'>
                                            <SecondaryBtn
                                                name="Cancel"
                                                className={`w-100`}
                                                cancel="modal"
                                            />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                                            <PrimaryBtn
                                                name={apiLoader ? "Verifying " : "Verify and update"}
                                                className={apiLoader
                                                    ? `w-100 e-btn-loader mb-lg-0 mb-3`
                                                    :
                                                    `w-100 mb-lg-0 mb-3`
                                                }
                                                handleClick={() => { validateForm() }}
                                            />
                                        </div>
                                        {/* :
                                       <PrimaryBtn name="Save Details" className={`${styles.e_disabled}`} />
                                   } */}
                                        {/* */}
                                    </div>
                                </div>
                            </div>
                            // </div>
                            // </div>
                            :
                            <div className={`${styles.e_modal_content} modal-content`}>
                                <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                                    <h5 className='mt-1'>
                                        {
                                            mandateStatus === 'SUCCESS' ?
                                                'Verification Successfull'
                                                :
                                                'Verification Failed'
                                        }
                                    </h5>
                                    <img
                                        src={Close}
                                        className={`${styles.e_modal_close}`}
                                        data-bs-dismiss="modal"
                                        onClick={() => props.Added()}
                                    />
                                </div>
                                <div className="container p-4">
                                    <div className='row'>
                                        <div className='col-lg-12 text-center'>
                                            <img src={mandateStatus === 'SUCCESS' ? Verify : Failed} alt="" style={{ width: '120px', marginTop: '40px' }} />
                                            <p className='ps-3 pt-2'>
                                                {
                                                    mandateStatus === 'SUCCESS' ?
                                                        'You have successfully completed the penny drop verification. Your account has been verified.'
                                                        :
                                                        'Failed to create mandate'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        // null
                    }
                </div>
            </div>
            {
                error != null ?
                    <Toast data={error}
                        type="fail"
                    />
                    :
                    null
            }
            {/* {
                !bankAdded ?
                    <SuccessPayment mandate={true} heading="E-mandate successfull" status={bankAdded} close={() => { props.Added() }} />
                    :
                    null
            } */}
        </>
    )
}
