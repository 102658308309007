import { useState, useEffect } from 'react';

import styles from '../../styles/components/welcomeModal.module.scss';

// Asset import 
import Close from '../../assets/images/new-design/modalClose.svg';
import Team from '../../assets/images/new-design/mintdTeam.png';
import Icon from '../../assets/images/new-design/whtsapp.svg';
import CalendlyICon from '../../assets/images/new-design/calendly.svg';
import Tour from '../../assets/images/new-design/productTour.svg';
import Map from '../../assets/images/new-design/map-pin.svg';

import { WatsappBtn, CalendlyBtn } from '../../components/cta';
import devconfig from '../../config/config';

const WelcomeModal = (props) => {
    const calendlyUrl = devconfig.calendarUrl
    useEffect(() => {
        var myModalEl = document.getElementById('welcome')
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            sessionStorage.setItem('welcome_modal', true);
            props.modalClose();
        })
    }, [])



    return (
        <div className={`${styles.e_welcome_modal} modal fade`} id="welcome" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered ${styles.e_dialog}`}>
                <div className={`modal-content ${styles.e_content} p-5`}>
                    <div className={`${styles.e_title} text-center mt-3 e-modal-title`}>
                        <span>
                            {`Hey ${props.name}, Welcome to Mintd!`}
                        </span>
                        <img src={Close}
                            data-bs-dismiss="modal"
                            className='e-btn-close'
                        />
                    </div>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <p className={`${styles.e_subtitle}`}>Want to talk to a human and help get invested?</p>
                        </div>
                    </div>
                    <div className='col-12 my-3 text-center'>
                        <img src={Team} className={`${styles.e_team}`} />
                    </div>
                    <div className='col-10 offset-1 mt-2 text-center'>
                        <h6>Mintd team is always ready to help you :)</h6>
                        <p className={`${styles.e_text} px-5`}>
                            You can book a call with one of our team member or chat with us on WhatsApp
                        </p>
                    </div>
                    <div className='col-10 offset-1 mb-3 mt-2 text-center'>
                        <a
                            href={devconfig.whatsappUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                            onClick={() => {
                                sessionStorage.setItem('welcome_modal', true);
                            }}
                        >
                            <WatsappBtn left={true} image={Icon} className="me-lg-3 me-1"
                            />
                        </a>
                        <a
                            href={calendlyUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                            onClick={() => {
                                sessionStorage.setItem('welcome_modal', true);
                            }}
                        >
                            <CalendlyBtn left={true} image={CalendlyICon} />
                        </a>
                        {
                            !props.removeTour && (
                                <>
                                    <span className={`${styles.e_separator} my-3`}>OR</span>
                                    <button
                                        className={`${styles.e_product_tour} `}
                                        onClick={() => {
                                            sessionStorage.setItem('welcome_modal', true);
                                            window.location.href = "/tour?welcome=" + true
                                        }}
                                    >
                                        <img src={Map} className="me-2" />
                                        Product Tour
                                    </button>
                                </>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeModal;