
//Package import
import { useState, useEffect } from 'react';
import moment from 'moment';
//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput';

import Arrow from '../../assets/images/PrimaryArrow.svg';
import Trash from '../../assets/images/onboard/trash-can.png';

// service 
import APIPost from '../../services/postApi';

//config
import Config from '../../config/config';
import { Toast, SuccessToast } from '../../components/note';
import Nodata from '../../components/note/nodata-placeholder';

const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]


const PropertyList = (props) => {
    const [loader, setLoader] = useState(false);
    const [toast, setToast] = useState(null);
    const [error, setError] = useState(null);
    const [removing, setRemoving] = useState(false);
    const [propertyList, setPropertyList] = useState([]);

    useEffect(() => {
        console.log('property list', propertyList);
        if (props.data) {
            setPropertyList(props.data);
        }
    }, [props.data])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 5000)
        }
    }, [toast])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 5000)
        }
    }, [error])

    const _updateProperty = (data) => {
        setLoader(true);
        let url = Config.portfolio + '/assets/realestate/update';
        let payload = JSON.stringify({
            real_estate_id: data.id,
            type_id: data.property_type_id,
            purchase_date: data.purchase_date,
            buy_price: data.buy_price,
            last_evaluation_date: data.last_evaluation_date,
            current_value: data.current_value,
            size_unit: data.size_unit,
            property_size: data.property_size
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setToast("Successfully updated");
                props.success();
            }
            else {
                setLoader(false);
                setError(response.message);
            }
        })
    }

    const _removeProperty = (id, index) => {
        setRemoving(true)
        let url = Config.portfolio + '/assets/realestate/delete';
        let payload = JSON.stringify({
            request_id: id
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setRemoving(false);
                setToast("Successfully removed");
                let array = propertyList;
                array.splice(index, 1)
                setPropertyList([...array])
            }
            else {
                setRemoving(false);
                setError(response.message)
            }
        })
    }

    return (
        <div className={`row mt-4 ${styles.e_stocks}`}>
            <div className='col-12'>
                <div className='row'>
                    <div className='col-12'>
                        <label>
                            Property type
                        </label>
                        <Select
                            value={{ value: propertyList.property_type_id, label: propertyList.property_type_name }}
                            isSearchable={true}
                            options={props.propertyOptions}
                            selectchange={(e) => {
                                let temp = propertyList;
                                temp.property_type_id = e.value;
                                temp.property_type_name = e.label;
                                setPropertyList({...temp});
                            }}
                        />
                    </div>
                    <div className='col-6 mt-4 pt-2'>
                        <label>
                            Purchase date
                        </label>
                        <SingleDate
                            placeholder="DD-MM-YYYY"
                            value={propertyList.purchase_date}
                            removeToday={true}
                            converted={true}
                            disabledDate={(day => {
                                if (day.isAfter(moment())) {
                                    return true
                                }
                            })}
                            onChange={(e) => {
                                var tempDate = moment(e, 'YYYY-MM-DD')
                                let temp = propertyList;
                                temp.purchase_date = tempDate.format('DD-MM-YYYY');
                                setPropertyList({...temp});
                            }}
                        />
                    </div>
                    <div className='col-6 mt-4 pt-2'>
                        <label>
                            Buy price
                        </label>
                        <div className='position-relative'>
                            <Input
                                value={propertyList.buy_price}
                                label="Buy price"
                                className={`${styles.e_price_input}`}
                                type="number"
                                valueChange={(e) => {
                                    let temp = propertyList;
                                    temp.buy_price = e;
                                    setPropertyList({...temp});
                                }}
                            />
                            <span className={`${styles.e_rupee}`}>₹</span>
                        </div>
                    </div>
                    <div className='col-6 mt-4 '>
                        <label>
                            Last evaluation day
                        </label>
                        <SingleDate
                            placeholder="DD-MM-YYYY"
                            removeToday={true}
                            converted={true}
                            value={propertyList.last_evaluation_date}
                            disabledDate={(day => {
                                if (day.isAfter(moment())) {
                                    return true
                                }
                            })}
                            onChange={(e) => {
                                var tempDate = moment(e, 'YYYY-MM-DD')
                                let temp = propertyList;
                                temp.last_evaluation_date = tempDate.format('DD-MM-YYYY');
                                setPropertyList({...temp});
                            }}
                        />
                    </div>
                    <div className='col-6 mt-4 '>
                        <label>
                            Current value
                        </label>
                        <div className='position-relative'>
                            <Input
                                value={propertyList.current_value}
                                type="number"
                                className={`${styles.e_price_input}`}
                                valueChange={(e) => {
                                    let temp = propertyList;
                                    temp.current_value = e;
                                    setPropertyList({...temp});
                                }}
                            />
                            <span className={`${styles.e_rupee}`}>₹</span>
                        </div>
                    </div>
                    <div className='col-6 mt-4 '>
                        <label>
                            Size unit
                        </label>
                        <Input
                            value={propertyList.size_unit}
                            placeholder="Sq.feet"
                            type="number"
                            valueChange={(e) => {
                                let temp = propertyList;
                                temp.size_unit = e;
                                setPropertyList({...temp});
                            }}
                        />
                    </div>
                    <div className='col-6 mt-4 '>
                        <label>
                            Property size
                        </label>
                        <Input
                            value={propertyList.property_size}
                            type="number"
                            valueChange={(e) => {
                                let temp = propertyList;
                                temp.property_size = e;
                                setPropertyList({...temp});
                            }}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                        <SecondaryBtn
                            name="Cancel"
                            className="w-100"
                            cancel="modal"
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                        {
                            propertyList.property_type_id &&
                            propertyList.purchase_date &&
                            propertyList.buy_price &&
                            propertyList.last_evaluation_date &&
                            propertyList.current_value &&
                            propertyList.size_unit &&
                            propertyList.property_size ?
                                <PrimaryBtn
                                    name={loader ? 'Updating' : 'Update'}
                                    className={loader ? 'e-btn-loader w-100' : 'w-100'}
                                    handleClick={() => _updateProperty(propertyList)}
                                />
                                :
                                <PrimaryBtn
                                    name='Update'
                                    className="w-100"
                                    disabled={true}
                                />
                        }

                    </div>
                </div>
            </div>
            {
                toast && (
                    <SuccessToast data={toast} />
                )
            }

            {
                error && (
                    <Toast data={error} />
                )
            }
        </div>
    )
}

export default PropertyList;