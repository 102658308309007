
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../../styles/components/videoPlayer.module.scss';
import Muted from '../../assets/images/muted.svg';
import UnMuted from '../../assets/images/unmuted.svg';
import PlayBtn from '../../assets/images/onboard/video-play.png';
import PauseBtn from '../../assets/images/pauseBtn.svg';
import Logo from '../../assets/images/menu/logo-white.svg';
import LogoMobile from '../../assets/images/menu/logo.svg';

const VideoPlayer = (props) => {
    const videoRef = useRef(null);
    const [volume, setVolume] = useState(false);
    const [position, setPosition] = useState(0);
    const [videoFocused, setVideoFocused] = useState(false);
    const [updatingSeek, setUpdatingSeek] = useState(false);
    const navigator = useNavigate();

    useEffect(() => {
        updateSeeker() // function call
    }, [])

    // function update video progress bar thumb 
    const updateSeeker = (current) => {
        var video = document.getElementById('e-vid');
        let timer = setInterval(() => {
            setPosition(video.currentTime)
        }, 1000)
    }

    //  seeker change
    const seekVideo = (e) => {
        setUpdatingSeek(true);
        var video = document.getElementById('e-vid');
        video.currentTime = e.target.value;
        setPosition(e.target.value);
    }

    // () for focus out video 
    const _removeVideoFocus = () => {
        let ele = document.getElementById('e-pause');
        let video = document.getElementById('e-vid');
        // video.classList.add(`${style.e_disabled}`);
        if (video.muted === false) {
            video.muted = true;
        }
        setVideoFocused(false);
    }

    const _setVideoFocus = () => {
        let video = document.getElementById('e-vid');
        // video.classList.remove(`${style.e_disabled}`);
        if (volume === true) {
            video.muted = false;
        }
        setVideoFocused(true);
    }

    return (
        <>
            {
                // props.home ?

                //     <div className={`${style.e_player_wrapper} h-100`}>
                //         <p>
                //             {props.message}
                //         </p>
                //         <iframe
                //             // onClick={() => setVideoFocused(true)}
                //             id="e-vid"
                //             sandbox
                //             height="100%" width="100%"
                //             allow='autoplay'
                //             src={`https://www.youtube.com/embed/${props.url}`}>
                //         </iframe>
                //     </div>
                //     :

                    <div

                        className={videoFocused ? `${style.e_player_wrapper} h-100` : `${style.e_player_wrapper} ${style.e_video_overlay} h-100`}
                    >
                        <img
                            src={window.innerWidth > 992 ? Logo : LogoMobile}
                            style={{ cursor: 'pointer' }}
                            className={style.e_app_logo}
                            onClick={() => navigator('/')}
                        />
                        {/* Mute button */}
                        {
                            videoFocused && (
                                <>
                                    {
                                        volume ?
                                            <button className={`${style.e_sound_btn}`}
                                                onClick={() => {
                                                    setVolume(false)
                                                    var video = document.getElementById('e-vid');
                                                    video.muted = !video.muted;
                                                }}
                                            >
                                                <img src={UnMuted} />
                                            </button>
                                            :
                                            <button className={`${style.e_sound_btn}`}
                                                onClick={() => {
                                                    setVolume(true);
                                                    var video = document.getElementById('e-vid');
                                                    video.muted = !video.muted;
                                                }}
                                            >
                                                <img src={Muted} />
                                            </button>
                                    }
                                </>

                            )
                        }

                        {/* play button */}
                        {
                            videoFocused === true ?
                                <button className={`${style.e_pause_btn}`} id="e-pause"
                                    onClick={() => {
                                        _removeVideoFocus();
                                        setVideoFocused(false);
                                    }}
                                >
                                    <img src={PauseBtn} />
                                </button>
                                :
                                <button className={`${style.e_play_btn}`}
                                    onClick={() => {
                                        _setVideoFocus();
                                        setVideoFocused(true)
                                    }}
                                >
                                    <img src={PlayBtn} />
                                </button>
                        }

                        {/* video seeker */}
                        {
                            videoFocused && (
                                <input
                                    value={position}
                                    type="range"
                                    id="seek-bar"
                                    min="0"
                                    max="47"
                                    className={`${style.e_seek_bar}`}
                                    onChange={(e) => seekVideo(e)}
                                />
                            )
                        }

                        {
                            !videoFocused && (
                                <p>
                                    {
                                        props.message ?
                                            props.message 
                                        :
                                            'A message from our founder'
                                    }
                                </p>
                            )
                        }
                        <video
                            className={`${style.e_player} `}
                            id="e-vid"
                            // controls 
                            ref={videoRef}
                            autoPlay
                            muted
                            loop
                        >
                            <source src={
                                props.url ?
                                props.url
                                    :
                                    "https://mintd.s3.ap-south-1.amazonaws.com/video/A+message+from+our+founder.mp4"
                            } type="video/mp4" />
                        </video>

                    </div>
            }
        </>
    )
}

export default VideoPlayer;