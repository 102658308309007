import style from '../../styles/components/security-modal.module.scss';

import Title from '../../assets/images/new-design/SecurityModalTitle.svg';
import ModalClose from '../../assets/images/new-design/modalClose.svg';
import AMFI from '../../assets/images/new-design/AMFI.svg';
import AWS from '../../assets/images/new-design/aws.svg';
import Privacy from '../../assets/images/new-design/privacy.svg';
import Database from '../../assets/images/new-design/database.svg';
import Key from '../../assets/images/new-design/key.svg';
import Text from '../../assets/images/new-design/file-text.svg';
import Statement from '../../assets/images/new-design/edit.svg';
import Ending from '../../assets/images/new-design/ending.svg';

const MintdSecurity = () => {
    return (
        <div className={`${style.e_modal} modal fade`} id="mintd-security" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered ${style.e_modal_dialog}`}>
                <div className={`modal-content  ${style.e_content}`}>
                    <img src={ModalClose} className={style.e_close} data-bs-dismiss="modal" />
                    <div className='row mt-4'>
                        <div className='col-12 text-center'>
                            <img src={Title} />
                        </div>
                    </div>
                    <div className='row px-4 mt-lg-5 mt-md-4 mt-xs-4 mt-4 mb-4'>
                        <div className='col-1'>
                            <img src={AMFI} className={'e-security-modal-img mt-md-3 mt-xs-2 mt-2'} />
                        </div>
                        <div className='col-11'>
                            <p className="mt-lg-4 mt-sm-3  ms-2">
                                We are authorised <b>Mutual Fund Distributor</b>
                            </p>
                        </div>
                        <div className='col-1 mt-lg-4 mt-3 '>
                            <img src={AWS} className={`e-security-modal-img mt-lg-2`} />
                        </div>
                        <div className='col-11 mt-3 mt-lg-4 '>
                            <p className='ms-2' style={{ marginBlockEnd: 'auto' }}>
                                We are hosted on <b>Amazon’s AWS</b> secured private network.
                            </p>
                        </div>
                        <div className='col-12 text-center'>
                            <img src={Ending} />
                        </div>
                        <div className='col-1 mt-lg-4 mt-sm-3 mt-3'>
                            <img src={Privacy} className={'e-security-modal-img'} />
                        </div>
                        <div className='col-11 mt-lg-4 mt-sm-3 mt-3'>
                            <p className='ms-2'>
                                Stringent <a href="https://www.mintd.in/privacy-policy" target="_blank">privacy policy</a>. Mintd never discloses your data to third parties.
                            </p>
                        </div>
                        <div className='col-1 mt-3'>
                            <img src={Database} className={'e-security-modal-img'} />
                        </div>
                        <div className='col-11 mt-3'>
                            <p className='ms-2'>
                                Stored data is protected by encryption at rest and sensitive data by application level encryption.
                            </p>
                        </div>
                        <div className='col-1 mt-3'>
                            <img src={Key} className={'e-security-modal-img'} />
                        </div>
                        <div className='col-11 mt-3'>
                            <p className='ms-2 mt-lg-2'>
                                Mintd supports OTP based authentication.
                            </p>
                        </div>
                        <div className='col-1 mt-3'>
                            <img src={Text} className={'e-security-modal-img'} />
                        </div>
                        <div className='col-11 mt-3'>
                            <p className='ms-2 mt-lg-2'>
                                Your wealth advisor is under NDA.
                            </p>
                        </div>
                        <div className='col-1 mt-3'>
                            <img src={Statement} className={'e-security-modal-img'} />
                        </div>
                        <div className='col-11 mt-3'>
                            <p className='ms-2 mt-lg-2'>
                                Our data security is audited regularly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MintdSecurity;