import React from 'react';

import { PrimaryBtn, PrimaryIconBtn, PrimaryLink, SecondaryBtn } from '../../components/cta/index';
import { Toast } from '../note';

import StopIcon from '../../assets/images/new-design/video-stop.svg';
import PlayBtn from '../../assets/images/onboard/video-play.png';
import Record from '../../assets/images/new-design/video.svg';

/*import Services */
import s3Upload from './awsHelper';
// import assets
import RecordBtn from '../../assets/images/onboard/play-button.png';
import StopBtn from '../../assets/images/onboard/record-button.png';


export default class WebcamOpen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			video: null,
			// stream: null,
			recorder: null,
			recording: false,
			hours: "00",
			minits: "00",
			second: 10,
			recordComplete: false,
			retake: false,
			count: null,
			mime: null,
			showPreview : false,
			previewPlaying: false,
			uploading: false,
		}

	}

	componentDidMount() {
		this.getBrowser();
		this.initializeMedia();
	}

	// find out app is open in which browser 
	getBrowser = () => {
		console.log(navigator.userAgent);
	}


	componentWillUnmount() {
		this.turnOffCam();
	}

	turnOffCam() {
		if (this.player) {
			this.player.srcObject.getVideoTracks().forEach((track) => {
				track.stop();
			});
		}

	}

	initializeMedia = async () => {
		this.setState({ imageDataURL: null });

		if (!("mediaDevices" in navigator)) {
			navigator.mediaDevices = {};
		}

		if (!("getUserMedia" in navigator.mediaDevices)) {
			navigator.mediaDevices.getUserMedia = function (constraints) {
				var getUserMedia =
					navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

				if (!getUserMedia) {
					return Promise.reject(new Error("getUserMedia Not Implemented"));
				}

				return new Promise((resolve, reject) => {
					getUserMedia.call(navigator, constraints, resolve, reject);
				});
			};
		}

		//Get the details of video inputs of the device
		const videoInputs = await this.getListOfVideoInputs();
		await console.log(videoInputs)
		this.setState({ capture: true })
		

		//The device has a camera
		if (videoInputs.length) {
			navigator.mediaDevices
				.getUserMedia({
					video: {
						// deviceId: {
						//   exact: videoInputs[this.cameraNumber].deviceId,
						// },
					},
				})
				.then((stream) => {
					this.player.srcObject = stream;

					this.setState({ player: stream });
					console.log(stream)

					this.setState({ player: this.player.srcObject });

					if (this.state.retake === true) {
						this.startRecording();
					}
					

				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			this.setState({ toast: "The device does not have a camera" });
		}

		setTimeout(() => {
			this.setState({ toast: null })
		}, 6000)


	};


	//   capturePicture = () => {
	//     var canvas = document.createElement("canvas");
	//     canvas.width = this.player.videoWidth;
	//     canvas.height = this.player.videoHeight;
	//     var contex = canvas.getContext("2d");
	//     contex.drawImage(this.player, 0, 0, canvas.width, canvas.height);
	//     this.player.srcObject.getVideoTracks().forEach((track) => {
	//       track.stop();
	//     });

	//     console.log(canvas.toDataURL());
	//     this.setState({ imageDataURL: canvas.toDataURL() });
	// >>>>>>> origin/develop
	//   };

	getListOfVideoInputs = async () => {
		// Get the details of audio and video output of the device
		const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
		console.log(enumerateDevices)
		//Filter video outputs (for devices with multiple cameras)
		return enumerateDevices.filter((device) => device.kind === "videoinput");
	}
	startRecording = () => {

		let type;
		// if(navigator.userAgent.indexOf("Safari") > 0){
		// 	type = 'video/mp4';
		// 	console.log(navigator.userAgent.indexOf("Safari") )
		// 	console.log('mac');
		// }else{
		// 	type = 'video/webm';
		// 	console.log('not mac');
		// }
		let count;
		console.log(this.state.player)
		var recorder;
		var stream = this.state.player;
		recorder = new MediaRecorder(stream, {
			mimeType: 'video/webm'
		});
		recorder.start();
		setTimeout(() => {
			this.stopRecording();
		}, 11000)
		count = setInterval(this.countdown, 1000);
		if (this.state.recordComplete) {
			this.setState({ second: 10 })
		}
		this.setState({ recorder: recorder, recording: true, count: count });
		console.log(recorder)
	}


	stopRecording() {
		if (this.state.second === 0) {
			clearInterval(this.state.count);
			console.log(this.state.recorder)
			this.state.recorder.ondataavailable = (e) => {
				this.setState({ video: e.data, videoUrl: URL.createObjectURL(e.data) })
			};

			this.state.recorder.stop();
			this.state.player.getVideoTracks().forEach((track) => {
				track.stop();
			});
			this.setState({ recordComplete: true, recording: false, second: 10 });
			this.props.completed(true);
			this.setState({showPreview: true, previewPlaying: false})
		} else {
			this.setState({ toast: "Recording will stop automatically after 10 secs" })
			setTimeout(() => {
				this.setState({ toast: null })
			}, 5000)
		}
	}


	countdown = () => {
		this.setState({ second: (this.state.second - 1) })
	}
	async uploadVideo(e) {
		let video = document.getElementById('e-preview-video');
		video.muted = true;
		video.pause();
		this.setState({ uploading: true})
		var response = await s3Upload("video", 'kyc/video/video.format', this.state.video, this.state.video.type);
		// console.log(response);

		if (response.status) {
			await this.setState({ file: response.cdn_url });
			// this.props.uploadSucess(response.cdn_url);
			this.props.submit(response.cdn_url)
			// this.setState({ uploading: false});
			
		}
		else {
			await this.setState({ apiLoading: false });
		}

	}


	render() {
		return (
			<>
				<div className="row ">
					{/* <div className='col-lg-6 offset-lg-6'>
						{
							this.state.capture ?
								null
								:
								<PrimaryBtn name="Start verification" className="e-verification-btn e-full-width" handleClick={this.initializeMedia} />
						}
					</div> */}
					<div className="col-lg-12 pe-lg-5 pe-0 col-md-12 col-sm-12 " >
						{
							this.state.capture && !this.state.recordComplete ?
								<div className='e-video-recorder-wrapper position-relative'>
									<video className='e-video-cam' ref={(refrence) => { this.player = refrence; }} autoPlay style={{ width: "100%" }}>  </video>
								</div>
								:
								null
						}
						{
							this.state.showPreview ?
								<div 
									className={
										this.state.previewPlaying === true ? 
											'e-video-recorder-wrapper  position-relative'
										:
											'e-video-recorder-wrapper e-preview-player position-relative'
									}
								>
									<video 
										className='e-video-cam' 
										id="e-preview-video"  
										style={{ width: "100%" }} 
										src={this.state.videoUrl} 
										type="video/webm"
										muted 
									> </video>
									{
										this.state.previewPlaying === false  && (
											<button className='e-preview-play'
												onClick={() => {
													let video = document.getElementById('e-preview-video');
													video.muted = false;
													video.play();
													this.setState({ previewPlaying: true})
												}}
											>
												<img src={PlayBtn}/>	
											</button>
										)
									}
									
								</div>
								:
								null
						}
						{/* {
							this.state.capture && !this.state.recording && !this.state.recordComplete ?
								<PrimaryBtn name={"Record"} className="e-verification-btn e-record-btn" handleClick={this.startRecording} />
								:
								null
						} */}
						{
							<>
								{
									this.state.recordComplete=== false && (
										<>
										{
											this.state.recording ?
											<div className='col-12 text-center mt-3'>
												<PrimaryBtn 
													name={this.state.second === 10 ? `${this.state.minits} : ${this.state.second}` : `${this.state.minits} : ${"0" + this.state.second}`}
													image={StopIcon}
													left={true}
													iconClass="me-2"
													className="e-verification-btn e-stop-record  " id="video-stop-btn" handleClick={() => { this.stopRecording() }} 
												/>
											</div>
												:
											<div className='col-12 text-center mt-3'>
												<PrimaryBtn
													name="Record"
													image={Record}
													left={true}
													className="e-record-again" 
													iconClass="me-2"
													handleClick={()=>{
														this.startRecording();
													}}
												/>
											</div>
										}
										</>
									)
								}
								
							</>
						}
						
						{
							this.props.showPreview ?
								<SecondaryBtn name="Retake" className="  mt-3" handleClick={() => { this.setState({ retake: true, capture: true, recordComplete: false, recording: false }); this.props.completed(false); this.initializeMedia() }} />
								:
								null
						}
						{
							this.state.recordComplete ?
								<div className='col-12 mt-3'>
									<PrimaryBtn 
										className={  this.state.uploading === true ?  "me-3 e-video-upload e-btn-loader" : 'me-3 e-video-upload' }
										name={this.state.uploading  === true? "Uploading" : "Upload video" }
										handleClick={() => this.uploadVideo()} 
										disable={this.state.uploading}
									/>
									<PrimaryBtn 
										name="Record again" 
										image={Record}
										left={true}
										className="e-record-again" 
										iconClass="me-2"
										disable={this.state.uploading}
										handleClick={() => { 
											this.setState({ retake: true, capture: true, recordComplete: false, recording: false, showPreview: false }); 
											this.props.completed(false); this.initializeMedia() }} 
										/>

								</div>
								:
								null
						}
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6 col-md-6">
						{
							this.state.imageDataURL ?
								<PrimaryBtn name={this.state.second === 10 ? `${this.state.minits} : ${this.state.second}` : `${this.state.minits} : ${"0" + this.state.second}`} className="e-verification-btn e-full-width  " handleClick={() => { this.stopRecording() }} />
								:
								null
						}
					</div>
					<div className="col-lg-6 col-md-6 text-end">
						{
							this.state.capture && this.state.imageDataURL ?
								<PrimaryIconBtn name="Re-take" className="e-capture-submit mt-4 pt-2" handleClick={this.initializeMedia} />
								:
								null
						}
					</div>
				</div>
				{
					this.state.toast ?
						<Toast data={this.state.toast} type="fail" />
						:
						null
				}
			</>
		)
	}
}
