import React, { createContext, useState } from 'react';

export const UserDataContext = createContext();

export const UserDataProvider = props =>{
    const [userData, setUserData] = useState(null);
    const [ onboardFlag, setOnboardFlag] = useState(true);
    return(
        <UserDataContext.Provider value={[userData, setUserData, onboardFlag, setOnboardFlag]}>
            {props.children}
        </UserDataContext.Provider>
    )
}