/*
 *   File : nominee.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Nominee
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 05-01-2022
 */

/* import packages */
import React, { useState, useEffect } from 'react';

/* import components */
import ProgressBar from '../../components/loader/progressLoader';

/* import assets */
import { PrimaryBtn, PrimaryIconBtn, SecondaryBtn } from '../../components/cta';
import { AddNominee, EditNominee, DeleteConfirm } from '../../components/modal';
import { Modal } from 'bootstrap';
// import 
// import services
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import { Loader } from '../../components/loader';

import Icon from '../../assets/images/new-design/plus-circle-blue.svg';

const NomenineeDeatils = () => {
  const [editable, setEditable] = useState();
  const [nominees, setNominees] = useState([]);
  //eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [to_trash, setTo_trash] = useState(null);

  useEffect(() => {
    getNominees();
  }, [])

  // get nominee list
  const getNominees = () => {
    setLoader(true)
    let url = devconfig.user + '/profile/get-nominee';
    APIGet(url).then((response) => {
      if (response.status_code === 200) {
        setNominees(response.data);
        setLoader(false);
      }
      else {
        setLoader(false);
        setNominees(null);
      }
    })
  }

  const openModal = (data) => {
    setEditable(data);
    console.log('editable-->', editable)
    setTimeout(() => {
      const modal = new Modal(document.getElementById("editNominee"), {})
      modal.show()
    }, 500)

  }
  useEffect(() => {

    if (editable && editable.length) {
      console.log('tem-->', editable)
    }
  }, [editable])

  useEffect(() => {
    if (to_trash !== null) {
      const modal = new Modal(document.getElementById("deleteConfirm"), {})
      modal.show()
    }
  }, [to_trash])
  return (
    <>
      {
        loader ? (
          <ProgressBar />
        )
          : (
            <div className="e-profile-detail pb-5 mb-5">
              <div className='col-lg-8 col-md-10 col-sm-12'>
                <div className='row '>

                  <div className='col-12'>
                    <h5 className='e-title'>
                      Nominee details
                    </h5>
                  </div>

                  {
                    nominees && nominees.length > 0 ?
                      nominees.map((item, key) => {
                        return (
                          <div className={key === 0 ? 'row mt-3' : 'row mt-4'}>

                            <div className='col-12 '>
                              <h5 className='e-title' style={{ fontSize: '16px' }}>
                                Nominee {key + 1}
                              </h5>
                            </div>

                            <div className='col-12 '>
                              <label>Nominee name</label>
                              <div className='e-credential-wrapper'>
                                {
                                  item.nominee_name
                                }
                              </div>
                            </div>
                            <div className='col-12 mt-4'>
                              <label>Relation</label>
                              <div className='e-credential-wrapper e-letter-style'>
                                {
                                  item.relation
                                }
                              </div>
                            </div>
                            <div className='col-12 mt-4'>
                              <label>Allocation</label>
                              <div className='e-credential-wrapper'>
                                {
                                  item.allocation
                                }
                              </div>
                            </div>

                            <div className='col-12 mt-4'>
                              <label>Date of birth</label>
                              <div className='e-credential-wrapper'>
                                {
                                  item.nominee_dob
                                }
                              </div>
                            </div>
                            <div className='col-12'>
                              <SecondaryBtn
                                name="Edit"
                                className="e-nominee-edit mt-3"
                                toggle="modal"
                                target="#editNominee"
                                handleClick={() => setEditable(item)}
                              />
                              <PrimaryBtn
                                name="Delete"
                                className="e-nominee-delete mt-3 ms-2"
                                handleClick={() => setTo_trash(item)}
                              />
                            </div>
                          </div>
                        )
                      })
                      :
                      <div className='col-lg-12 col-12 mt-3'>
                        <PrimaryBtn name="Add nominee" className="px-5" toggle="modal" target="#addNominee" />
                      </div>
                  }

                </div>
                <div className='row'>
                  {
                    nominees && nominees.length <= 2 ?
                      <div className='col-lg-12 col-12 '>
                        <PrimaryIconBtn
                          left={true}
                          image={Icon}
                          iconClass="me-2"
                          name="Add more nominee"
                          className="mt-3 e-add-more"
                          toggle="modal"
                          target="#addNominee"
                        />
                      </div>
                      :
                      null
                  }
                </div>
              </div>
            </div>
          )
      }
      <AddNominee success={() => { getNominees(); }} />
      <EditNominee data={editable} reload={() => getNominees()} />
      <DeleteConfirm data={to_trash} success={() => { setTo_trash(null); getNominees(); }} />
    </>
  );
}


export default NomenineeDeatils;