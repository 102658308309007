import { useState, useEffect } from 'react';
import $ from 'jquery';

import Close from '../../assets/images/new-design/modalClose.svg';
import Arrow from '../../assets/images/new-design/btn-arrow.svg';

import styles from '../../styles/components/allocationModal.module.scss';

import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { PieChart } from '../chart';
import { Loader } from '../loader';
import { PrimaryBtn } from '../cta';

const Allocation = (props) => {

    const [graphData, setGraphData] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        console.log('selected', props.selectedPortfolio);
        if (props.selectedPortfolio) {
            _getAllocation(props.selectedPortfolio.risk_id)
        }
    }, [props.selectedPortfolio])

    useEffect(() => {
        var myModal = document.getElementById('allocation')
        myModal.addEventListener('hidden.bs.modal', () => {
            props.modalClose()
        });
    }, []);


    const _getAllocation = (id) => {

        setLoader(true)
        let userAllocation = [];
        let actualAllocation = [];
        let url = devconfig.user + '/portfolio/get'
        let data = JSON.stringify({
            risk_score_id: id
        });
        console.log('API data :: ', data)
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setGraphData(response.data);
                setLoader(false)

            }
            else {
                setLoader(false)
                // setError(response.message);
            }
        })
    }

    const _callBack = () => {
        props.callBack(props.selectedPortfolio);
        $('#allocation').trigger('click');
    }
    return (
        <div
            className={`${styles.e_allocation_modal} modal fade`}
            id="allocation"
            tabIndex="-1"
            aria-labelledby="invest"
            aria-hidden="true"
        >
            <div className={`modal-dialog modal-dialog-centered ${styles.e_dialog}`}>
                <div className={`${styles.e_modal_content} modal-content`} style={{ maxWidth: '540px' }}>
                    <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                        <h5 className=''>Asset allocations</h5>
                        <img
                            src={Close}
                            className={`${styles.e_modal_close}`}
                            data-bs-dismiss="modal"
                        />
                    </div>

                    <div className='container px-5 py-3 e-onboard-container'>
                        {
                            loader ? (
                                <Loader removeTag={true} height="200px" />
                            ) : (
                                <div className='row'>
                                    <div className='col-lg-5 col-md-5 col-sm-12 gx-0'>
                                        {
                                            graphData && graphData.graph_data ?
                                                <PieChart
                                                    data={graphData.graph_data.allocation}
                                                    colors={graphData.graph_data.colors}
                                                    labels={graphData.graph_data.fund_class}
                                                    width="260"
                                                    height="240"
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-12 pe-0 pt-3  '>
                                        <div className={`${styles.e_graph_legends} ps-4`}>
                                            {
                                                graphData && graphData.graph_data.allocation.map((item, key) => {
                                                    return (
                                                        <div className={`${styles.e_chart_label} e-chart-label mt-2`} key={key} >
                                                            <p className="d-inline e-chart-label-text">
                                                                <div className="rounded-circle me-2 pt-1"
                                                                    style={{ backgroundColor: `${graphData.graph_data.colors[key]}`, width: '16px', height: '16px', display: 'inline-block', position: 'relative', top: '3px' }}
                                                                >
                                                                </div>
                                                                {graphData.graph_data.fund_class[key]}:
                                                            </p>
                                                            <span className='pt-2 ps-2 d-inline'>{item}%</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                        <label>
                                            Minimum Investment Amount
                                        </label>
                                        <span className={`${styles.e_amount} `}><span className='e-rupee-symbol d-inline'>₹</span>{props?.selectedPortfolio.min.toLocaleString('en-IN')}</span>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                        <label>
                                            Risk Score
                                        </label>
                                        <span className={`${styles.e_amount}`}>{props?.selectedPortfolio.score}</span>
                                    </div>
                                    <div className='col-12 mt-4'>
                                        <PrimaryBtn
                                            name={props.buttonType === 0 ? "Invest Now" : "Do KYC and Invest"}
                                            image={Arrow}
                                            iconClass="ms-2"
                                            right={true}
                                            className={`${styles.e_submit}`}
                                            handleClick={() => _callBack()}
                                        />
                                    </div>
                                    <div className='col-12 text-center'>
                                        <p className={`${styles.e_desc} mt-3`}>
                                            You can change your Risk Score anytime
                                        </p>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Allocation;