/*
 *   File : skipTourConfirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Skip Tour  Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 22-07-2022
 */

/*import packages*/
import React, { useEffect, useContext } from 'react';
import $ from 'jquery'

/*import styles*/
import styles from '../../styles/components/welcomeModal.module.scss';

/*import context*/
import { UserDataContext } from '../../context/userData';

/*import assets */
import Skip from '../../assets/images/tour/skip.svg';
import Close from '../../assets/images/new-design/modalClose.svg';

/* import components */
import { PrimaryBtn, PrimaryLink, SecondaryBtn } from '../cta';
import { Modal } from 'bootstrap';

/*import services */
import GetURLVar from '../../services/getUrl';
import Config from '../../config/config';
import APIGet from '../../services/getApi';
import LeadSquared from '../../services/leadSquredServices';

export default function SkipTour(props) {

  const [userData, setUserData] = useContext(UserDataContext);

  const _updateTourStatus = () => {
    let url = Config.user + '/profile/product-tour';
    APIGet(url).then((response) => {
      if (response.status_code === 200) {
        $("#skip").trigger("click")
        // if (props.active === true) {
        //   window.location.href = '/overview';
        // } else {
        //   window.location.href = '/onboard';
        // }
        window.location.href = '/home';
      }
    })
  }


  const _segmentTourTracker = () => {
    window.analytics.track('Product tour', {
      user_id: userData?.user_id,
      producttour_status: 'skipped',
      step: props.step
    });
  }

  const _updateActivity = async (step) => {
    let trackerData = [
      {
        SchemaName: 'user_id',
        Value: userData?.user_id
      },
      {
        SchemaName: 'producttour_status',
        Value: 'skipped'
      },
      {
        SchemaName: 'step',
        Value: props.step
      }
    ]
    let activityId = await LeadSquared._retrieveActivity(202);
    if (activityId) {
      LeadSquared._updateTracker('Product tour', trackerData, 202, activityId);
    }
  }

  return (
    <div className={`${styles.e_welcome_modal} modal fade`} id="skip" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="successLabel" aria-hidden="true" data-bs-backdrop="static" >
      <div className={`modal-dialog modal-dialog-centered ${styles.e_dialog}`}>
        <div className={`modal-content ${styles.e_content} p-5`}>
          <div className={`${styles.e_title} text-center mt-3`}>
            {`Do you want to skip tour?`}
            <img src={Close}
              data-bs-dismiss="modal"
              className='text-end'
            />
          </div>
          <div className="container px-3">
            <div className='row'>
              <div className='col-lg-12 d-flex '>
                {/* <img src={Skip} alt="" /> */}
                <p className={`${styles.e_text} ps-3 pt-3 text-start`}>The tour helps you to understand how to get the most out of Mintd.</p>
              </div>
            </div>
            <div className="row pt-lg-3 pt-md-3 pt-sm-3 pb-2">
              {/* cancel or close button */}

              <div className="col-lg-6 col-6">
                <SecondaryBtn name="Skip the tour"
                  className={` e-skip-tour-btn mt-4 mt-lg-0 mt-md-0 mt-sm-0`}

                  // handleClick={() => {
                  //  $("#skip").trigger("click")
                  //    window.location.href = "/onboard"
                  // }} 
                  handleClick={() => {
                    _segmentTourTracker();
                    _updateTourStatus();
                  }}

                />
              </div>
              <div className="col-lg-6 col-6">
                <PrimaryBtn
                  name="Continue the tour"
                  className={`e-finish-tour-btn e-take-tour`}
                  cancel="modal" handleClick={() => {
                    props.continue()
                  }} />


              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

  )
}



