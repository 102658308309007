
// Package import
import { useState, useEffect } from 'react';

/* Style import*/
import styles from '../../styles/components/rebalanceModal.module.scss';

import PrimaryArrow from '../../assets/images/assets/primary_arrow.png';

// Service import 
import  Config from '../../config/config';
import APIGet from '../../services/getApi';

const RebalanceCard = (props) => {

    const [ detail, setDetail] = useState();

    useEffect(()=>{
        getRebalanceDetaill();
    },[])

    const getRebalanceDetaill = () =>{
        let url = Config.user+'/mf/portfolio-changes';
        APIGet(url).then((response)=>{
            if(response.status_code === 200 ){
                setDetail(response.data);
            }else{
            }
        })
    }
    
   

    return (
        <div className={` ${styles.e_rebalance_card}  p-3`}>
                <div className='row'>
                    <div className='col-10'>
                        <h6 className={`${styles.e_title} `}>Funds name</h6>
                    </div>
                    <div className='col-2'>
                        <h6 className={`${styles.e_title} `}>Quantity</h6>
                    </div>
                </div>
                <div>
                    {
                        detail && detail.withdraw_mfs.length != 0 &&  (
                            <div className='row'>
                                    <div className='col-12'>
                                        <h6 className={`${styles.e_title} mt-2`}>Funds to sell</h6>
                                   </div>
                                   
                                   {
                                        detail.withdraw_mfs.map((data, key) => {
                                            return (
                                                <>
                                                <div className='col-12'>
                                                    <h6 className={`${styles.e_title} mt-2`}>{data.asset_class}</h6>
                                                </div>
                                                <div className='col-10'>
                                                    <h6 className={`${styles.e_fund_name}  `}>{data.scheme_name}</h6>
                                                </div>
                                                <div className='col-2'>
                                                    <h6 className={styles.e_weight}>{data.old_allocation}%</h6>
                                                </div>
                                                <div className='col-10'>
                                                    <h6 className={`${styles.e_fund_name}`}>{data.scheme_name}</h6>
                                                </div>
                                                <div className='col-2'>
                                                    <h6 className={styles.e_weight}>{data.new_allocation}%</h6>
                                                </div>
                                                </>
                                            )
                                        })
                                    }
                            </div>
                            
                        )
                    }
                    {
                        detail && detail.purchase_mfs.length != 0 && (
                            <div className='row'>
                            <div className='col-lg-12'>
                                <h6 className={`${styles.e_title} mt-2`}>Funds to buy</h6>
                            </div>
                            {
                                detail.purchase_mfs.map((data, key) => {
                                    return (
                                        <>
                                        <div className='col-12'>
                                                <h6 className={`${styles.e_title} mt-2`}>{data.asset_class}</h6>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className={`${styles.e_fund_name}  `}>{data.scheme_name}</h6>
                                        </div>
                                        <div className='col-2'>
                                            <h6 className={styles.e_weight}>{data.old_allocation}%</h6>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className={`${styles.e_fund_name} `}>{data.scheme_name}</h6>
                                        </div>
                                        <div className='col-2'>
                                            <h6 className={styles.e_weight}>{data.new_allocation}%</h6>
                                        </div>
                                        </>
                                    )
                                })
                            }
                            </div>
                        )
                    }
                        {
                            detail && detail.stable_mfs.length != 0 && (
                                <div className='row'>
                                <div className='col-lg-12'>
                                    <h6 className={`${styles.e_title} mt-3`}>No changes to</h6>
                                </div>
                                {
                                    detail.stable_mfs.map((data, key) => {
                                        return (
                                            <>
                                            <div className='col-12'>
                                                    <h6 className={`${styles.e_title} mt-2`}>{data.asset_class}</h6>
                                            </div>
                                            <div className='col-10'>
                                                <h6 className={`${styles.e_fund_name} `}>{data.scheme_name}</h6>
                                            </div>
                                            <div className='col-2'>
                                                <h6 className={styles.e_weight}>{data.old_allocation}%</h6>
                                            </div>
                                            <div className='col-10'>
                                                <h6 className={`${styles.e_fund_name}`}>{data.scheme_name}</h6>
                                            </div>
                                            <div className='col-2'>
                                                <h6 className={styles.e_weight}>{data.new_allocation}%</h6>
                                            </div>
                                            </>
                                        )
                                    })
                                }
                                </div>
                            )
                        }
                    </div>
            </div>

         
    )
}

export default RebalanceCard