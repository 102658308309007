/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import moment from 'moment';

/*import componets*/
import { Input, Select, SingleDate, RadioButton } from '../input/index';
import { SecondaryBtn, PrimaryBtn, PrimaryLink } from '../cta/index';


/*import styles*/
import styles from '../../styles/components/edit.module.scss';

// import Service
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';


/* import assets */
import Delete from '../../assets/images/modal/delete.svg'

export default function ProfileEdit(props) {

  const [apiLoader, setAPILoader] = useState(false);
  const [error, setError] = useState(null);
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [maritalOptions, setMaritalOptions] = useState();
  const [genderOptions, setGenderOptions] = useState();
  const [marital, setMarital] = useState(null);
  const [selected, setSelected] = useState(null);
  const [country, setCountry] = useState("yes");
  const [dob, setDOB] = useState(null);

  useEffect(() => {
    getGeneralData();
  }, [])

  useEffect(() => {
    let modal = document.getElementById('profile-edit');
    modal.addEventListener('hidden.bs.modal', () => {
      props.modalClose();
    })
  }, [])

  useEffect(() => {
    setFname(props.data.first_name);
    setLname(props.data.last_name);
    let date = props.data.dob;
    let reversed = date.split('/').reverse().join('/');
    console.log('reversed', reversed);
    let formattedDate = reversed.replaceAll("/", "-");
    setDOB(formattedDate);
    setSelected({ value: props.data.gender_id, label: props.data.gender });
    setMarital(props.data.marital_status_id);
    setCountry(props.data.is_indian_citizen === true ? "yes" : "no")
    console.log('props.data', props.data)
  }, [props.data])

  const getGeneralData = () => {
    var tempGender = [];
    let url = devconfig.user + '/app/get-generaldata';
    APIGet(url).then((response) => {
      if (response?.status_code === 200) {
        response.data.genders.map((item, key) => {
          tempGender.push({ value: item.id, label: item.display_name })
        })
        setGenderOptions(tempGender);
        setMaritalOptions(response.data.marital_statuses);

      } else {
        setError(response?.message)
      }
    });
  }


  const updateProfile = () => {
    setAPILoader(true);
    let url = devconfig.user + '/profile/edit';
    let data = JSON.stringify({
      first_name: fname,
      last_name: lname,
      dob: dob,
      gender_id: selected.value,
      marital_status_id: marital,
      occupation_id: props.data.occupation_id,
      wealth_source_id: props.data.wealth_source_id,
      is_indian_citizen: country === "yes" ? true : false,
    })
    APIPost(url, data).then((response) => {
      if (response.status_code === 200) {
        props.success(response.data);
        setAPILoader(false);
        $('#profile-edit').trigger('click');
      }
      else {
        setAPILoader(false);
        setError(response.message);
      }
    })
  }

  return (
    <div className={`${styles.e_edit_modal} modal fade `} id="profile-edit" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6> Personal details</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="container px-3">
            <div className="row mt-3">
              <div className="col-lg-12">
                <h6 className={`${styles.e_profile_edit_sub_ittle} mb-3`}>What is your name ?</h6>
              </div>
              <div className='col-lg-6'>
                <Input label="First name" value={fname} valueChange={(value) => { setFname(value); setError(null) }} type="text" />
              </div>
              <div className='col-lg-6'>
                <Input label="Last name" value={lname} valueChange={(value) => { setLname(value); setError(null) }} type="text" />
              </div>
              <div className='col-lg-6 mt-4'>
                <h6 className={`${styles.e_profile_edit_sub_ittle} mb-3`}>What is your date of birth</h6>
                <SingleDate
                  id="dob"
                  value={dob}
                  converted={true}
                  placeholder="dd-mm-yyyy"
                  disabledDate={(day => {
                    if (day.isAfter(moment().subtract(18, "years"))) {
                      return true
                    }
                  })}
                  isDecadeBack={true}
                  onChange={(dateString) => {
                    setDOB(dateString);
                  }} />
              </div>
              <div className='col-lg-6 mt-4'>
                <h6 className={`${styles.e_profile_edit_sub_ittle} mb-3`}>What is your gender</h6>
                <Select id="gender"
                  options={genderOptions}
                  value={selected}
                  isSearchable={false}
                  placeholder="Gender"
                  className="e-select-sort"
                  style="border:solid 10px #f0f0f0;"
                  selectchange={(temp) => {
                    setSelected(temp);
                  }} />
              </div>
              <div className='col-lg-12 mt-4'>
                <h6 className={`${styles.e_profile_edit_sub_ittle} mb-1`}>Marital status</h6>
                <div className="e-radio-buttons">
                  {
                    maritalOptions && maritalOptions.map((item, key) => {
                      return (
                        <RadioButton
                          id={item.id}
                          label={item.display_name}
                          wrapperClass="e-edit-profile-radio"
                          checked={item.id == marital}
                          onClick={(e) => {
                            setMarital(item.id);
                          }}
                          name={item.type}
                        />
                      )

                    })
                  }
                </div>
              </div>
              <div className='col-lg-12 mt-4'>
                <h6 className={`${styles.e_profile_edit_sub_ittle} mb-1`}>Is India your country of citizenship?</h6>
                <div className="e-radio-buttons">
                  <RadioButton id={1}
                    label="Yes"
                    name="citizen"
                    wrapperClass="e-edit-profile-radio"
                    checked={country == "yes" ? true : false}
                    onClick={(e) => {
                      setCountry("yes")
                    }}
                  />
                  <RadioButton id={2}
                    label="No"
                    name="citizen"
                    wrapperClass="e-edit-profile-radio"
                    checked={country === "no" ? true : false}
                    onClick={(e) => {
                      setCountry("no")
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row pt-4 mt-2 pb-2">
              <span style={{ color: 'red' }}>{error}</span>
              <div className="col-lg-12 d-flex justify-content-end">
                <PrimaryLink name="Cancel" className="me-3" cancel="modal" />
                {
                  fname &&
                    lname &&
                    country &&
                    marital &&
                    selected &&
                    country &&
                    dob ?
                    <SecondaryBtn
                      name={apiLoader ? "Saving" : " Save details"}
                      className={apiLoader ? `${styles.e_cancel} e-cancel e-btn-loader` : `${styles.e_cancel} e-cancel`}
                      handleClick={() => updateProfile()} />
                    :
                    <SecondaryBtn name="Save details" disabled={true} className={`${styles.e_cancel} e-cancel`} />
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
