import styles from '../../styles/components/tooltip.module.scss';

const CustomToolTip = (props) => {
    return (
        <div className={`${styles.e_custom_tooltip}`}>
            {
                props.symbol == true && (
                    <span className='e-rupee-symbol position-relative'
                        style={{ fontSize: '14px', fontWeight: '500', top: props.top ? props.top : '-8px' }}
                    >₹</span>
                )
            }
            {props.data}
        </div>
    )
}

export default CustomToolTip;