
//Package import 
import { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import $ from 'jquery';

//Asset import 
import Tradebook from '../../assets/images/tradebook.svg';
import UploadIcon from '../../assets/images/uploadIcon.svg';
import FileIcon from '../../assets/images/fileIcon.svg';
import Zerodha from '../../assets/images/Zerodha.svg';
import Upstocks from '../../assets/images/Upstocks.svg';
import Arrow from '../../assets/images/PrimaryArrow.svg';
import Close from '../../assets/images/modal/close.svg';
import Trash from '../../assets/images/onboard/trash-can.png';

//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput';
import Uploader from '../../components/fileUploader/upload';
import Toast from '../../components/note/successToast';
import ErrorToast from '../../components/note/toast';

import SpinnerMinus from '../../assets/images/new-design/spinner-minus.svg';
import SpinnerPlus from '../../assets/images/new-design/spinner-plus.svg';

// import Config
import Config from '../../config/config';

//service import 
import APIPost from '../../services/postApi';
import Nodata from '../../components/note/nodata-placeholder';

import { UserDataContext } from '../../context/userData';

const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]


const StockDetail = (props) => {
    const [loader, setLoader] = useState(false);
    const [toast, setToast] = useState(null);
    const [error, setError] = useState(null);
    const [stockList, setStockList] = useState(null);
    const [removing, setRemoving] = useState(false);
    const [openSearchPanel, setOpenSearchPanel] = useState(null);
    const [searchDetail, setSearchDetail] = useState([]);
    const [userData, setUserData] = useContext(UserDataContext);

    const wrapperRef = useRef(null);

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 5000)
        }
    }, [toast])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null)
            }, 5000)
        }
    }, [error])

    useEffect(() => {
        if (props.data) setStockList(props.data)
    }, [props.data])

    const _updateStock = (data) => {
        setLoader(true);
        let url = Config.portfolio + '/assets/stocks/update';
        let payload = JSON.stringify({
            stock_id: data.id,
            ticker: data.ticker,
            ltp: data.ltp,
            purchase_date: data.purchase_date,
            quantity: data.quantity,
            stock_type: 11
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                setToast("Stock updated!");
                props.success();
                // _eventTracker();
            }
            else {
                setLoader(false)
                setError(response.message);
            }
        })
    }

    const _removeStock = (id, key) => {
        setRemoving(true);
        let url = Config.portfolio + '/assets/stocks/delete';
        let payload = JSON.stringify({
            request_id: id
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code == 200) {
                setRemoving(false);
                setToast('Stock removed');
                let array = stockList;
                array.splice(key, 1);
                setStockList([...array]);
            }
            else {
                setRemoving(false);
                setError(response.message);
            }
        })
    }

    const _eventTracker = () => {
        window.analytics.track('Portfolio tracker configured', {
            user_id: userData?.user_id,
            stocks: 'true',
            MF: 'false',
            Gold: 'false',
            RealEstate: 'false',
            Bond: 'false',
            crypto: 'false'
        });
    }

    const _stockSearch = (text) => {
        let url = Config.portfolio + '/assets/general/stock-search';
        let payload = JSON.stringify({
            search_text: text
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                if (openSearchPanel !== null) {
                    setSearchDetail([...response.data]);
                } else {
                    console.log('inside else');
                    setSearchDetail(response.data);
                }
            }
        })
    }

    const hideDropDown = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpenSearchPanel(null)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', hideDropDown);
        return () => {
            document.removeEventListener('mouseup', hideDropDown);
        };
    }, []);


    return (
        <div className={`row mt-4 ${styles.e_stocks} e_stocks`}>
            <div className='col-12 '>
                <h5 className={`${styles.e_form_name}`}>
                    /Editing
                </h5>
            </div>
            {
                stockList && (
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <label>
                                Ticker
                            </label>
                            <Input
                                value={stockList.ticker}
                                placeholder="Search stock"
                                needFocusEvent={true}
                                className={`${styles.e_search}`}
                                focusIn={() => {
                                    let temp = stockList;
                                    temp.ticker = '';
                                    setStockList({...temp});
                                    setOpenSearchPanel(true);
                                }}
                                valueChange={(e) => {
                                    let temp = stockList;
                                    temp.ticker = e;
                                    setStockList({...temp});
                                    if (e) { _stockSearch(e) }
                                }}
                            />
                            {
                                openSearchPanel === true && searchDetail.length ? (
                                    <div className='e-mf-search-panel' ref={wrapperRef} >
                                        {
                                            searchDetail && searchDetail.map((stock, i) => {
                                                return (
                                                    <div className='row border-bottom py-2' key={i}>
                                                        <div className={i === 0 ? 'col-9' : 'col-9 mt-2'}>
                                                            {stock.symbol}
                                                        </div>
                                                        <div className='col-3'>
                                                            <SecondaryBtn
                                                                name="Add"
                                                                className="e-mf-add-btn"
                                                                handleClick={() => {
                                                                    let temp = stockList;
                                                                    temp.ticker = stock.symbol;
                                                                    setStockList({...temp});
                                                                    setOpenSearchPanel(false);
                                                                    setSearchDetail([]);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                                    : null
                            }
                        </div>
                        <div className='col-6 mt-4 pt-2'>
                            <label>
                                Purchase date
                            </label>
                            <SingleDate
                                value={stockList.purchase_date}
                                placeholder="DD-MM-YYYYY"
                                removeToday={true}
                                converted={true}
                                disabledDate={(day => {
                                    if (day.isAfter(moment())) {
                                        return true
                                    }
                                })}
                                onChange={(e) => {
                                    var tempDate = moment(e, 'YYYY-MM-DD')
                                    let temp = stockList;
                                    temp.purchase_date = tempDate.format('DD-MM-YYYY');
                                    setStockList({...temp});
                                }}
                            />
                        </div>
                        <div className='col-6 mt-4 pt-2'>
                            <label>
                                Quantity
                            </label>
                            <div className='w-50 position-relative'>
                                <Input
                                    value={stockList.quantity}
                                    className={` ${styles.e_spinner_input}`}
                                    valueChange={(e) => {
                                        let temp = stockList;
                                        temp.quantity = e;
                                        setStockList({...temp});
                                    }}
                                />
                                <img src={SpinnerMinus} 
                                    className={`${styles.e_spinner_minus}`}
                                    onClick={()=>{
                                        let temp = stockList;
                                        temp.quantity = temp.quantity-1;
                                        setStockList({...temp});
                                    }}
                                />
                                <img 
                                    src={SpinnerPlus} 
                                    className={`${styles.e_spinner_plus}`}
                                    onClick={()=>{
                                        let temp = stockList;
                                        temp.quantity = temp.quantity+1;
                                        setStockList({...temp});
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-6 mt-4'>
                            <label>
                                Current LTP
                            </label>
                            <Input
                                label="Current LTP"
                                value={stockList.ltp}
                                valueChange={(e) => {
                                    let temp = stockList;
                                    temp.ltp = e;
                                    setStockList({...temp});
                                }}
                            />
                        </div>
                        <div className='col-6 mt-4 '>
                            <label>
                                Net amount
                            </label>
                            <Input
                                className="e-portfolio-disable-input"
                                value={stockList.ltp * stockList.quantity}
                                valueChange={(e) => { }}
                            />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                        <SecondaryBtn
                            name="Cancel"
                            className="w-100"
                            cancel="modal"
                        />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                {
                    stockList.ticker &&
                        stockList.purchase_date &&
                        stockList.quantity &&
                        stockList.ltp ?
                        <PrimaryBtn
                            name="Update"
                            className={loader ? 'e-btn-loader w-100' : 'w-100'}
                            handleClick={() => _updateStock(stockList)}
                        />
                        :
                        <PrimaryBtn
                            name="Update"
                            className="w-100"
                            disabled={true}
                        />
                }


            </div>
                    </div>
                )
            }
           



            {
                toast && (
                    <Toast data={toast} />
                )
            }

            {
                error && (
                    <ErrorToast data={error} />
                )
            }
        </div>
    )
}

export default StockDetail;