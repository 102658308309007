/*
 *   File : addNominee.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add Nominee
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import moment from 'moment';

/*import componets*/
import { Select, SingleDate } from '../input/index';
import Input from '../input/loginInput';

import { PrimaryBtn, SecondaryBtn } from '../cta/index';
import { Toast } from '../note';
import Close from '../../assets/images/new-design/modalClose.svg';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/* import services */
import devconfig from '../../config/config';
import COLOUR from '../../config/color';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';


/* import assets */
export default function AddNominee(props) {

	const [name, setName] = useState("");
	const [allocation, setAllocation] = useState(null);
	const [date, setDate] = useState(null);
	const [relation, setRelation] = useState();
	const [apiLoader, setApiLoader] = useState(false);
	const [error, setError] = useState(null);
	const [relationOtions, setRelationOptions] = useState([]);
	const [minor, setMinor] = useState(false);
	const [guardianRelation, setGuardianRelation] = useState(null);
	const [gName, setGname] = useState(null);
	const [guardianOptions, setGuardianOptions] = useState([]);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null)
			}, 5000)
		}
	}, [error])

	useEffect(() => {
		if (name !== "" && name.length != 0) {
			clearFormValidationError('name');
		}
	}, [name]);

	useEffect(() => {
		if (allocation !== null && allocation.length != 0) {
			clearFormValidationError('allocation');
		}
	}, [allocation]);

	useEffect(() => {
		if (date != null) {
			clearFormValidationError('add-nominee-dob');
		}
	}, [date]);

	const getData = () => {
		var tempRelation = [];
		let url = devconfig.user + '/app/get-generaldata';
		APIGet(url).then((response) => {
			if (response?.status_code === 200) {
				response.data.kyc_relationships.map((item, key) => {
					tempRelation.push({ value: item.id, label: item.display_name })
				})
				setRelationOptions(tempRelation);
				const filtered = tempRelation.filter((item) => item.label !== 'Son' && item.label !== 'Daughter');
				setGuardianOptions(filtered);
			}
			else {
				setError(response?.message)
			}
		})
	}

	/* validation to highlight fields */
	const showFormValidationError = (field) => {
		var ele = document.getElementById(field);
		ele.style.border = `solid 1.5px ${COLOUR.red}`
		ele.scrollIntoView({
			behavior: "smooth",
			block: "end",
			inline: "nearest"
		});
	}

	/* clearing validation fields */
	const clearFormValidationError = (field) => {
		var ele = document.getElementById(field);
		if (ele) {
			ele.style.border = null;
		}
	}

	/* validation for select */
	const showFormValidationSelect = (field) => {
		const ele = document.getElementById(field);
		ele.className = "e-select-error"
		ele.scrollIntoView({
			behavior: "smooth",
			block: "end",
			inline: "nearest"
		});
	}

	/* clearing validation for select */
	const clearFormValidationSelect = (field) => {
		var ele = document.getElementById(field);
		if (ele) {
			ele.className = "e-select-wraper";
		}
	}

	const validateFields = () => {
		if (name.length < 2) {
			setError("Minimum 2 characters required for nominee name");
			showFormValidationError("name")
			return false;
		} else if (allocation === null) {
			setError("Allocation must be 100%");
			showFormValidationError("allocation")
			return false
		} else if (date === null) {
			setError("Please enter date of birth of nominee");
			showFormValidationError("add-nominee-dob");
			return false;
		} else if (relation.value === null) {
			setError("Please select relation");
			showFormValidationSelect("relation");
			return false;
		}
		return true
	}

	const validateForm = () => {
		let status = validateFields()
		if (status) {
			AddNominee()
		}
	}
	const AddNominee = (status) => {
		// console.log(idx)
		let temp = [];
		setApiLoader(true);
		let url = `${devconfig.user}/profile/add-nominee`;
		let data = JSON.stringify({
			nominee_name: name,
			allocation: allocation,
			nominee_dob: date,
			relation_id: relation.value,
			guardian_name: gName,
			guardian_relation_id: guardianRelation?.value
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setApiLoader(false);
				$('#addNominee').trigger('click');
				clearFields();
				props.success();
			} else {
				setApiLoader(false);
				setError(response.message)
			}
		})
	}

	const clearFields = () => {
		setName("");
		setAllocation("");
		setRelation({ label: "Relation", value: null });
		setDate(null);
	}

	// checking nominee is minor or not
	const _calculateAge = (date) => {
		const birthday = new Date(date);
		var ageDifMs = Date.now() - birthday.getTime();
		var ageDate = new Date(ageDifMs);
		if (Math.abs(ageDate.getUTCFullYear() - 1970) < 18) setMinor(true);
		else setMinor(false);
	}

	const _validForm = () => {
		let valid = false;
		if (name && allocation && relation && date) {
			const birthday = new Date(date);
			var ageDifMs = Date.now() - birthday.getTime();
			var ageDate = new Date(ageDifMs);
			var age = Math.abs(ageDate.getUTCFullYear() - 1970);
			if (age < 18) {
				if (gName && guardianRelation) {
					valid = true
				}
				else {
					valid = false
				}
			}
			else {
				valid = true
			}
		}
		else {
			valid = false
		}
		return valid;
	}

	return (
		<div className={`${styles.e_edit_modal} modal fade`} id="addNominee" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
						<h5 className='mt-1'>Add Nominee</h5>
						<img
							src={Close}
							className={`${styles.e_modal_close}`}
							data-bs-dismiss="modal"
						/>
					</div>
					<div className="container p-4">
						<div className="row">
							<div className="col-lg-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
								<label className='mb-1' >Nominee Name</label>
								<Input
									id="name"
									value={name}
									valueChange={(value) => setName(value)}
									type="text"
								/>
							</div>
							<div className="col-lg-6">
								<label className='mb-1'>Allocation</label>
								<Input
									id="allocation"
									value={allocation}
									valueChange={(value) => setAllocation(value)}
									type="number"
								/>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-lg-6 mb-2 pb-2">
								<label className='mb-1'>Date of Birth</label>
								<SingleDate
									placeholder="dd-mm-yyyy"
									id="add-nominee-dob"
									value={date}
									onChange={(dateString) => {
										setDate(dateString);
										_calculateAge(dateString);
									}}
									removeToday={true}
									disabledDate={(day => {
										if (day.isAfter(moment())) {
											return true
										}
									})}
								/>
							</div>
							<div className="col-lg-6 mb-2 pb-2">
								<label className='mb-1'>Relation</label>
								<Select
									id="relation"
									value={relation}
									options={relationOtions}
									placeholder="Select"
									className="e-select-sort"
									style="border:solid 10px #f0f0f0;"
									selectchange={(temp) => {
										setRelation(temp);
										clearFormValidationSelect("relation")
									}}
								/>
							</div>
						</div>
						{
							minor === true && (
								<div className='row mt-3'>
									<div className="col-lg-6">
										<label className='mb-1'>Guardian Name</label>
										<Input
											id="g_name"
											value={gName}
											valueChange={(value) => setGname(value)}
											type="text"
										/>
									</div>
									<div className='col-lg-6'>
										<label className='mb-1'>Guardian relation</label>
										<Select
											id="relation"
											value={guardianRelation}
											options={guardianOptions}
											placeholder="Select"
											className="e-select-sort"
											style="border:solid 10px #f0f0f0;"
											selectchange={(temp) => {
												setGuardianRelation(temp);
											}}
										/>
									</div>
								</div>
							)
						}
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-lg-6 col-md-6 col-sm-12">
								<SecondaryBtn
									name="Cancel"
									modal="cancel"
									className="w-100"
								/>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-4">
								{
									_validForm() ? (
										<PrimaryBtn
											name={apiLoader ? "Saving" : "Save Details"}
											className={apiLoader
												? `
												w-100 e-btn-submit`
												:
												`w-100`
											}
											handleClick={() => validateForm()}
										/>
									) : (
										<PrimaryBtn
											name={apiLoader ? "Saving" : "Save Details"}
											className={apiLoader
												? `
												w-100 e-btn-submit`
												:
												`w-100`
											}
											disabled={true}
										/>
									)
								}

							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error ?
					<Toast data={error} type="fail" />
					:
					null
			}
		</div>
	)
}
