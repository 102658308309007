// import packages
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
// import style
import style from '../../styles/components/dateSelector.module.scss';

// import assets
import Close from '../../assets/images/input/close.png';

import Calender from '../../assets/images/new-design/calendar-date.svg';


const DateSelector = (props) => {
    const [openDatePanel, setOpenDatePanel] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const datePickerRef = useRef(null);

    //set default selected date as start of the month date if current date > 28;
    useEffect(() => {
        if(props.date) {
            setSelectedDate(props.date.split(",")[0])
            props.selectedDate(parseInt(props.date.split(",")[0]))
        }
    }, [props.date])



    useEffect(() => {
        if (props.defaultDate) setSelectedDate(props.defaultDate);
    }, [props.defaultDate])

    //  close date picker when click out side the date picker 
    const hideDropdown = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setOpenDatePanel(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mouseup', hideDropdown);
        return () => {
            document.removeEventListener('mouseup', hideDropdown);
        };
    }, []);


    // render dates
    const renderDates = () => {
        var temp = [];


        let sip_date =   props.date?.split(",");
        for (let index = 1; index <= 28; index++) {
            let is_valid_sip = sip_date?.includes(index.toString())
            temp.push(
                <span className={parseInt(selectedDate) === (index) ? `${style.e_selected_date} ${style.e_date} ` :
                    // index === Number(props.date?.split(",")[props.date?.split(",").length - 1]) 
                    (is_valid_sip == false) ? ` text-center  ${style.e_disabled_date}` : `text-center ${style.e_date}`}
                    key={index}
                    onClick={() => {
                        setSelectedDate(index);
                        props.selectedDate(index);
                        setOpenDatePanel(false)
                    }}>{index}</span>
            )
        }
        return (
            temp
        )

    }

    //  render 'st' , 'nd' , 'rd', 'th' based on selected date
    const renderTitle = () => {
        if( !selectedDate ) return <h5>Choose a date</h5>
        if (selectedDate > 3 && selectedDate < 21) return <h5>{`${selectedDate}th of every month`}</h5>;  //return date+th if selected date bw 3 to 21
        switch (selectedDate % 10) { // get reminder of selected date 
            case 1: return <h5>{`${selectedDate}st of every month`}</h5>; // return date+st;
            case 2: return <h5>{`${selectedDate}nd of every month`}</h5>; // return date+nd;
            case 3: return <h5>{`${selectedDate}rd of every month`}</h5>; // return date+rd
            default: return <h5>{`${selectedDate}th of every month`}</h5>;// return date+th
        }
    }
    return (
        <div className={`${style.e_custome_date_picker} position-relative`} ref={datePickerRef}>
            {/* <label onClick={()=>setOpenDatePanel(true)}>SIP date</label> */}
            <div className='position-relative'>
                <input 
                    className={props.disableSelect ? `${style.e_disable_picker} ${style.e_date_input}` : `${style.e_date_input}`} 
                    type="text" 
                    value={selectedDate} 
                    readOnly 
                    onFocus={() => setOpenDatePanel(true)} 
                />
                <img 
                    src={Calender}
                    className={`${style.e_icon}`}
                />
            </div>
            {
                openDatePanel ?
                    <div className={`${style.e_date_panal_dropdown} pt-4`} >
                        <div className={`${style.e_date_picker_header} text-center`}>
                            {renderTitle()}
                            <img src={Close} className={`${style.e_close_btn}`} onClick={() => setOpenDatePanel(false)} />
                        </div>
                        <div className={`${style.e_date_group}`}>
                            {renderDates()}
                            {/* <button onClick={() => setOpenDatePanel(false)}>Confirm</button> */}
                        </div>

                    </div>
                    :
                    null
            }
        </div>
    )
}

export default DateSelector;