/*
 *   File : config.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Config file for the template
 *   Version : 1.0.0
 *   Created : 10-11-2021
 */

/*----Meta -----*/
const Metadata = { title: 'Mintd-User' }

/*----- Footer ----- */
const Footer = {
	footerCopyRight: `©  ${new Date().getFullYear()}  Mintd Ltd.`,
	poweredby: 'Evoque Innovative Lab',
}


const url = process.env.REACT_APP_API_URL

const Kite_API_Key = process.env.REACT_APP_KITE_API_KEY
const Kite_login_url = `${process.env.REACT_APP_KITE_LOGIN_URL}?v=3&api_key=${Kite_API_Key}`
const calendar_url = process.env.REACT_APP_CALENDAR_URL
const whatsapp_url = process.env.REACT_APP_WHATSAPP_URL


var devconfig = {
	footer: Footer,
	user: url + 'customer',
	KiteLoginUrl: Kite_login_url,
	portfolio: url + "portfolio",
	calendarUrl: calendar_url,
	whatsappUrl: whatsapp_url
}


export default devconfig;
