
//Package import 
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import $ from 'jquery';

//Style import 
import styles from '../../styles/components/assetList.module.scss';

// Component import
import { Input, Select } from '../input';
import { PrimaryBtn, SecondaryBtn, PrimaryLink } from '../cta';
import { Toast } from '../note';
import Close from '../../assets/images/new-design/modalClose.svg';
import Arrow from '../../assets/images/new-design/arrow.svg';

//Asset import
import Lens from '../../assets/images/lens.svg';

//Container import
import StockDetail from '../../containers/portfolioTracker/stocksDetail';
import MutualFundsDetail from '../../containers/portfolioTracker/mutualFundsDetail';
import RealEstateDetail from '../../containers/portfolioTracker/realEstate';
import FixedDepositDetail from '../../containers/portfolioTracker/fixedDepositDetail';
import GoldDetail from '../../containers/portfolioTracker/gold.Detail';

// config import 
import Config from '../../config/config';

//service import
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';
import GetURLVar from '../../services/getUrl';
import { Modal } from 'bootstrap';
import MutualFundListError from '../../containers/portfolioTracker/mfErrorList';
import devconfig from '../../config/config';



const AssetList = (props) => {

    const [menuOpen, setMenuOpen] = useState(true);
    const [selectedAsset, setSelectedAsset] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [assetTypes, setAssetsTypes] = useState([]);
    const [fixedType, setFixedType] = useState([]);
    const [mutualFunds, setMutualFunds] = useState([]);
    const [organization, setOrganization] = useState([]);
    const [property, setProperty] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [toast, setToast] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [errorData, setErrorData] = useState([])
    const [error, setShowerror] = useState(false)
    const location = useParams();


    useEffect(() => {
        if (props.selected) {
            setSelectedAsset(props.selected);
        }
    }, [props.selected])

    useEffect(() => {
        _getGeneralData()
    }, [])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 5000)
        }
    }, [toast])

    const _getGeneralData = async () => {
        let url = Config.portfolio + '/assets/general/list';
        //checking url params if url param zerodha redirection;
        let selected = await GetURLVar().then((query) => { return query.asset_type });
        if (selected) selected = selected.replace("%20", " ");
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                let assets = [];
                response.data.asset_type.map((item, key) => {
                    if (selected == item.label)
                        setSelectedAsset({ value: item.id, label: item.label });
                    if (item.label != 'Equity') {
                        return (
                            assets.push({ value: item.id, label: item.label })
                        )
                    }

                })
                console.log('assets', assets)
                setAssetsTypes(assets);
                let fixed = response.data.fixed_deposit_type.map((item, key) => {
                    return (
                        { value: item.id, label: item.label }
                    )
                })
                setFixedType(fixed)
                // let mutual = response.data.mutual_fund_type.map((item, key) => {
                //     return(
                //         {value: item.id, label: item.label}
                //     )
                // })
                // setMutualFunds(mutual)
                let organization = response.data.organization_type.map((item, key) => {
                    return (
                        { value: item.id, label: item.label }
                    )
                })
                setOrganization(organization);
                let properties = response.data.property_type.map((item, key) => {
                    return (
                        { value: item.id, label: item.label }
                    )
                })
                setProperty(properties)
                let stock = response.data.stock_type.map((item, key) => {
                    return (
                        { value: item.id, label: item.label }
                    )
                })
                setStocks(stock);
            }
        })
    }

    useEffect(() => {
        var myModalEl = document.getElementById('asset-list')
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            props.modalClose();
        })
    }, [])

    const _fetchHoldings = (txnResponse) => {
        let payload = JSON.stringify(txnResponse)
        APIPost(devconfig.user + "/smallcase/get-holdings", payload).then((response) => {
            setShowPreview(true);
            setTableData(response.data);
        }).catch((err) => {
            console.warn("Error fetching holdings: ", err)
        })
    }

    const _fetchMfHoldings = (txnResponse) => {
        let payload = JSON.stringify({
            data: txnResponse
        })
        APIPost(devconfig.user + "/smallcase/get-mf-holdings", payload).then((response) => {
            setShowPreview(true);
            setTableData(response.data);
        }).catch((err) => {
            console.warn("Error fetching holdings: ", err)
        })
    }

    // call API and initialize for holdings transaction
    const _initiateHoldingsTransaction = (gatewayInstance, is_mf) => {
        APIGet(devconfig.user + "/smallcase/create-transaction-token").then((response) => {
            if (response.success) {
                console.log("response.data.transactionId - ", response.data.transactionId)
                gatewayInstance.triggerTransaction({
                    // ! Use valid transactionId here
                    transactionId: response.data.transactionId
                }).then(txnResponse => {
                    /*
                        * Gateway flow ended after order placement
                        * Response structure & example - https://developers.gateway.smallcase.com/docs/transaction-response
                        */
                    console.log('received response:', txnResponse);
                    _fetchHoldings(txnResponse,)

                }).catch(err => {
                    /*
                        * Gateway flow ended before order placement
                        * Possible errors - https://developers.gateway.smallcase.com/docs/transaction-errors
                        */
                    console.log(err)
                    console.log('received error:', err.message);
                });
            }
        })
    }

    // call API and initialize for mf transaction
    const _initiateMfHoldingsTransaction = (gatewayInstance) => {
        APIGet(devconfig.user + "/smallcase/create-mf-transaction-token").then((response) => {
            if (response.success) {
                console.log("response.data.transactionId - ", response.data.transactionId)
                gatewayInstance.triggerMfTransaction({
                    // ! Use valid transactionId here
                    transactionId: response.data.transactionId
                }).then(txnResponse => {
                    /*
                        * Gateway flow ended after order placement
                        * Response structure & example - https://developers.gateway.smallcase.com/docs/transaction-response
                        */
                    console.log('received response:', txnResponse);
                    _fetchMfHoldings(txnResponse,)
                }).catch(err => {
                    /*
                        * Gateway flow ended before order placement
                        * Possible errors - https://developers.gateway.smallcase.com/docs/transaction-errors
                        */
                    console.log(err)
                    console.log('received error:', err.message);
                });
            }
        })
    }

    // call API and get token for initialization
    const _generateSmallCaseToken = (is_mf) => {
        APIGet(devconfig.user + "/smallcase/get-auth-token").then((response) => {
            console.log(response);
            if (response.success) {
                const gatewayInstance = new window.scDK({
                    gateway: "mintd",
                    smallcaseAuthToken: response.data.auth_token,
                    config: {
                        amo: true
                    }
                });
                if (is_mf) {
                    _initiateMfHoldingsTransaction(gatewayInstance, is_mf)
                } else {
                    // to get the stocks
                    _initiateHoldingsTransaction(gatewayInstance, is_mf);
                }
                // to get the mutual funds
                // _initiateMfHoldingsTransaction(gatewayInstance);
            }
        })
    }


    const RenderAssetDetail = () => {
        let body;
        switch (selectedAsset?.value) {
            case 1: return (
                <MutualFundsDetail
                    mutualFundOptions={mutualFunds}
                    success={(file, error) => {
                        if (file) {
                            setShowPreview(true);
                            setTableData(file);
                            setErrorData(error)
                            // setShowPreview(true)
                        } else {
                            $('#asset-list').trigger('click');
                            props.success(selectedAsset);
                        }

                    }}
                    error={(e) => setToast(e)}
                    getSmallCaseData={() => {
                        _generateSmallCaseToken(true)

                    }}
                />
            );
                break;
            case 2: return (
                <StockDetail
                    stockType={stocks}
                    success={(file) => {
                        if (file) {
                            setShowPreview(true)
                            setTableData(file);
                        } else {
                            $('#asset-list').trigger('click');
                            props.success(selectedAsset);
                        }

                    }}
                    getSmallCaseData={() => {
                        _generateSmallCaseToken(false)

                    }}
                    error={(e) => setToast(e)}
                />
            );
                break;
            case 3: return (
                <RealEstateDetail
                    propertyOptions={property}
                    success={() => {
                        $('#asset-list').trigger('click');
                        props.success(selectedAsset);
                    }}
                    error={(e) => setToast(e)}
                />
            );
                break;
            case 4: return (
                <GoldDetail
                    success={() => {
                        $('#asset-list').trigger('click');
                        props.success(selectedAsset);
                    }}
                    error={(e) => setToast(e)}
                />
            );
                break;
            case 5: return (
                <FixedDepositDetail
                    organization={organization}
                    fixedOptions={fixedType}
                    success={() => {
                        $('#asset-list').trigger('click');
                        props.success(selectedAsset);
                    }}
                    error={(e) => setToast(e)}
                />
            );
                break;
            case 6: return (<div></div>);
                break;
            case 20: return (<div className={`${styles.e_coming_soon} text-center mt-4 py-4`}>Coming soon</div>)
                break;
            case 21: return (<div className={`${styles.e_coming_soon} text-center mt-4 py-4`}>Coming soon</div>)
                break;
            default: return (<div></div>)
        }
    }

    const MfPreviewData = () => {
        console.log('preview', tableData.tables)
        return (
            <div className='e-asset-modal-preview'>
                <div className='col-12 e-table-wrapper gx-0 mt-3'>
                    <table width="max-content" className='e-csv-table mb-3'>
                        <thead>
                            <tr>
                                <th>Folio</th>
                                <th>Scheme code</th>
                                <th>Name</th>
                                <th>Unit balance</th>
                                <th>Purchase nav</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                tableData.length &&
                                tableData.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td key={i}>{item.folio}</td>
                                            <td key={i}>{item.scheme_code}</td>
                                            <td key={i} style={{ whiteSpace: 'pre' }}>{item.mf_name}</td>
                                            <td key={i}>{item.unit_balance}</td>
                                            <td key={i}>{item.nav_value}</td>
                                        </tr>
                                    );
                                })

                            }
                        </tbody>
                    </table>
                </div>
                <div className='row'>
                    <div className='col-12 mt-4'>
                        <PrimaryLink
                            name="Add more"
                            className={`mt-2 ${styles.e_link_btn}`}
                            handleClick={() => { setShowPreview(false) }}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                        <SecondaryBtn
                            name="Cancel"
                            className="w-100"
                            cancel="modal"
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                        <PrimaryBtn
                            name="Confirm"
                            className="w-100"
                            handleClick={() => {
                                if (errorData.length === 0) {
                                    $('#asset-list').trigger('click');
                                    props.success(selectedAsset);
                                    props.smallCase(true)
                                } else {
                                    setShowerror(true)
                                    setShowPreview(false)
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const StockPreviewData = () => {
        console.log('preview', tableData.tables)
        return (
            <div className='e-asset-modal-preview'>
                <div className='col-12 e-table-wrapper gx-0 mt-3'>
                    <table width="100%" style={{ overflow: 'auto' }} className='e-csv-table mb-3'>
                        <thead>
                            <tr>
                                <th>Ticker</th>
                                <th>Quantity</th>
                                <th>Avg. cost</th>
                                <th>Amount invested</th>

                            </tr>
                        </thead>
                        <tbody className='position-relative'>
                            {
                                tableData.length &&
                                tableData.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td key={i}>{item.ticker}</td>
                                            <td key={i}>{item.quantity}</td>
                                            <td key={i}>{item.purchase_ltp}</td>
                                            <td key={i}>{item.net_amount}</td>

                                        </tr>
                                    );
                                })

                            }
                        </tbody>
                    </table>
                </div>
                <div className='row'>
                    <div className='col-12 mt-4'>
                        <PrimaryLink
                            name="Add more"
                            className={`mt-3 pt-2 ${styles.e_link_btn}`}
                            handleClick={() => { setShowPreview(false) }}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                        <SecondaryBtn
                            name="Cancel"
                            className="w-100"
                            cancel="modal"
                            handleClick={() => setShowPreview(false)}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                        <PrimaryBtn
                            name="Confirm"
                            className="w-100"
                            handleClick={() => {
                                setShowPreview(false);
                                $('#asset-list').trigger('click');
                                props.success(selectedAsset);
                                props.smallCase(true)
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div
                className={`${styles.e_asset_modal} modal fade`}
                id="asset-list"
                tabIndex="-1"
                aria-labelledby="successLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered "
                    style={{ minWidth: showPreview && window.innerWidth > 757 && 'fit-content' }}
                >
                    <div
                        className={`${styles.e_content} modal-content position-relative`}
                        style={{ minWidth: showPreview && window.innerWidth > 757 && '800px' }}
                    >
                        <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                            <h5 className='mt-1'>{error ? "Update the uncharted funds" : "Add Assets"}</h5>

                            <img
                                src={Close}
                                className={`${styles.e_modal_close}`}
                                data-bs-dismiss="modal"
                            />
                        </div>

                        <div className='modal-body'>
                            <div className="container p-3">
                                <div className='row gx-0'>
                                    <div className='col-12 gx-0 position-relative'>
                                        {
                                            Object.keys(selectedAsset).length ? (
                                                <div className='col-12 d-flex align-items-center'>
                                                    <div className={`${styles.e_back_btn}`} onClick={() => { setSelectedAsset({}) }} >
                                                        <img
                                                            src={Arrow}
                                                        />
                                                        Back
                                                    </div>
                                                    <div
                                                        className={`${styles.e_active} ${styles.e_tab} `}
                                                        style={{ width: 'max-content', left: '0', right: '0', margin: 'auto' }}
                                                        onClick={() => { setSelectedAsset({}); setShowPreview(false) }}
                                                    >
                                                        {selectedAsset.label}
                                                    </div>
                                                </div>

                                            ) : (
                                                <div className='d-flex flex-wrap justify-content-md-between justify-content-sm-start justify-content-start'>
                                                    {
                                                        assetTypes && assetTypes.length > 0 && assetTypes.map((item, key) => {
                                                            return (
                                                                <div
                                                                    className={
                                                                        item.value === selectedAsset.value ?
                                                                            `${styles.e_tab} ${styles.e_active} mb-3 `
                                                                            :
                                                                            `${styles.e_tab}  mb-3 `
                                                                    }
                                                                    onClick={() => {
                                                                        setSelectedAsset(item);
                                                                        setShowPreview(false);
                                                                        sessionStorage.setItem('asset_type', item.label);
                                                                    }}

                                                                >
                                                                    {item.value === 1 || item.value === 2 || item.value === 5 ?
                                                                        `${item.label}s`
                                                                        :
                                                                        item.label
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            error ?
                                                <p className='pt-4'>Certain funds cannot be charted automatically because of fund name mismatches. Please search for the fund connected and update it. </p>
                                                :
                                                null
                                        }
                                        {/* <div className={`${styles.e_input_wrapper} e-search-select-wrapper`}>
                                        <Select
                                            isSearchable={true}
                                            options={assetTypes}
                                            value={selectedAsset}
                                            // menuIsOpen={true}
                                            placeholder="Search or select the assets type"
                                            selectchange={(e)=>{
                                                setSelectedAsset(e);
                                                setShowPreview(false);
                                                sessionStorage.setItem('asset_type', e.label);
                                            }}
                                        />
                                    </div>     */}
                                    </div>
                                    {
                                        showPreview === true ? (
                                            <div className='col-12'>
                                                {
                                                    selectedAsset.value === 1 ? (
                                                        <MfPreviewData />
                                                    )
                                                        :
                                                        selectedAsset.value === 2 && (
                                                            <StockPreviewData />
                                                        )
                                                }

                                            </div>
                                        ) :
                                            error ?
                                                <MutualFundListError data={errorData} success={() => { $('#asset-list').trigger('click'); }} />
                                                :

                                                (
                                                    <div className='col-12'>
                                                        <RenderAssetDetail />
                                                    </div>
                                                )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    toast && (
                        <Toast data={toast} />
                    )
                }
            </div>
        </>
    )
}

export default AssetList