/*
 *   File : checkBox.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */
/*import package*/
import React from 'react';

/*import styles*/
import styles from '../../styles/components/checkBox.module.scss';

const CheckBox = (props) => {
  return (
    <label className={styles.e_checkbox_container + " " + props.className}>
      <input type="checkbox" name={props?.name ? props?.name : "editList"} id={props.id} {...props} required={props.required} onChange={props.onChange} checked={props.check}/>{props.checkboxText}
      <span className={styles.checkmark}></span>
    </label>
  );
}
export default CheckBox;

