
//Package import
import { useState, useEffect } from 'react';
import moment from 'moment';
//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput';

import { Toast, SuccessToast } from '../../components/note';

import Trash from '../../assets/images/onboard/trash-can.png';

import APIPost from '../../services/postApi';
import Config from '../../config/config';

import Nodata from '../../components/note/nodata-placeholder';


const frequencyOption = [
    { value: 12, label: 'Monthly' },
    { value: 4, label: 'Quarterly' },
    { value: 2, label: 'Half-yearly' },
    { value: 1, label: 'Yearly' }
]

const DepositList = (props) => {
    const [loader, setLoader] = useState(null);
    const [toast, setToast] = useState(null);
    const [error, setError] = useState(null);
    const [removing, setRemoving] = useState(false);

    const [depositList, setDepositList] = useState(null);

    useEffect(() => {
        if (props.data) {
            setDepositList(props.data);
        }
    }, [props.data])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 5000)
        }
    }, [toast])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 5000)
        }
    }, [error])

    const _updateDeposit = (data) => {
        let url = Config.portfolio + '/assets/fd/update';
        let payload = JSON.stringify({
            fd_id: data.id,
            organization_type_id: data.organization_type_id,
            organization_name: data.organization_name,
            fd_type: data.fd_type,
            invested_amount: data.invested_amount,
            rate_of_interest: data.rate_of_interest,
            start_date: data.start_date,
            interest_compounding_frequency: data.interest_compounding_frequency,
            tenure: data.tenure
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setToast("Updated successfully")
                props.success()
            }
            else {
                setLoader(false);
                setError(response.message);
            }
        })
    }

    const _removeDeposit = (id, index) => {
        setRemoving(true);
        let url = Config.portfolio + '/assets/fd/delete';
        let payload = JSON.stringify({
            request_id: id
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                let array = depositList;
                array.splice(index, 1);
                setDepositList([...array]);
                setToast("Removed successfully");
                setRemoving(false)
            }
            else {
                setError(response.data);
                setRemoving(false)
            }
        })
    }
    return (
        <div className={`row mt-4 ${styles.e_stocks}`}>
            {
                depositList && (
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-12'>
                                <label>
                                    Organization type
                                </label>
                                <Select
                                    isSearchable={true}
                                    value={{ value: depositList.organization_type_id, label: depositList.organization_type_name }}
                                    options={props.organization}
                                    selectchange={(e) => {
                                        let temp = depositList;
                                        temp.organization_type_id = e.value;
                                        temp.organization_type_name = e.label
                                        setDepositList({...temp});
                                    }}
                                />
                            </div>
                            <div className='col-6 mt-4 pt-2'>
                                <label>
                                    Organization name
                                </label>
                                <Input
                                    value={depositList.organization_name}
                                    valueChange={(e) => {
                                        let temp = depositList;
                                        temp.organization_name = e
                                        setDepositList({...temp});
                                    }}
                                />
                            </div>
                            <div className='col-6 mt-4 pt-2'>
                                <label>
                                    FD Type
                                </label>
                                <Select
                                    value={{ value: depositList.fd_type, label: depositList.fd_type_name }}
                                    placeholder="Select"
                                    options={props.fixedOptions}
                                    selectchange={(e) => {
                                        let temp = depositList;
                                        temp.fd_type = e.value;
                                        temp.fd_type_name = e.label;
                                        setDepositList({...temp});
                                    }}
                                />
                            </div>
                            <div className='col-6 mt-4 '>
                                <label>
                                    Investment amount
                                </label>
                                <Input
                                    value={depositList.invested_amount}
                                    placeholder="0&#8377;"
                                    type="number"
                                    valueChange={(e) => {
                                        let temp = depositList;
                                        temp.invested_amount = e
                                        setDepositList({...temp});
                                    }}
                                />
                            </div>
                            <div className='col-6 mt-4 '>
                                <label>
                                    Rate of interest
                                </label>
                                <Input
                                    value={depositList.rate_of_interest}
                                    placeholder="e.g 6.75%"
                                    type="number"
                                    valueChange={(e) => {
                                        let temp = depositList;
                                        temp.rate_of_interest = e
                                        setDepositList({...temp});
                                    }}
                                />
                            </div>
                            <div className='col-6 mt-4 pt-1'>
                                <label>
                                    Start date
                                </label>
                                <SingleDate
                                    value={depositList.start_date}
                                    placeholder="DD-MM-YYYY"
                                    removeToday={true}
                                    converted={true}
                                    disabledDate={(day => {
                                        if (day.isAfter(moment())) {
                                            return true
                                        }
                                    })}
                                    onChange={(e) => {
                                        var tempDate = moment(e, 'YYYY-MM-DD')
                                        let temp = depositList;
                                        temp.start_date = tempDate.format('DD-MM-YYYY')
                                        setDepositList({...temp});
                                    }}
                                />
                            </div>
                            <div className='col-6 mt-3 '>
                                <label>
                                    Interest compounding frequency
                                </label>
                                <Select
                                    value={
                                        {
                                            value: depositList.interest_compounding_frequency,
                                            label: depositList.interest_compounding_frequency == 12 ?
                                                "Monthly" :
                                                depositList.interest_compounding_frequency == 4 ?
                                                    "Quarterly" :
                                                    depositList.interest_compounding_frequency == 2 ?
                                                        "Half-Yearly"
                                                        : "Yearly"
                                        }
                                    }
                                    placeholder="Select"
                                    options={frequencyOption}
                                    selectchange={(e) => {
                                        let temp = depositList;
                                        temp.interest_compounding_frequency = e.value;
                                        setDepositList({...temp});
                                    }}
                                />
                            </div>
                            <div className='col-6 mt-4 '>
                                <label>
                                    Tenure (In years)
                                </label>
                                <Input
                                    value={depositList.tenure}
                                    placeholder="e.g 2yrs"
                                    type="number"
                                    valueChange={(e) => {
                                        let temp = depositList;
                                        temp.tenure = e
                                        setDepositList({...temp});
                                    }}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12  mt-4'>
                                <SecondaryBtn
                                    name="Cancel"
                                    className="w-100"
                                    cancel="modal"
                                />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12  mt-4'>
                                {
                                    depositList.organization_type_id &&
                                    depositList.organization_name &&
                                    depositList.fd_type &&
                                    depositList.invested_amount &&
                                    depositList.rate_of_interest &&
                                    depositList.start_date &&
                                    depositList.interest_compounding_frequency &&
                                    depositList.tenure ?
                                        <PrimaryBtn
                                            name={ loader ? "Updating" : "Update"}
                                            className={loader ? 'e-btn-loader w-100' : 'w-100'}
                                            handleClick={() => _updateDeposit(depositList)}
                                        />
                                        :
                                        <PrimaryBtn
                                            name="Update"
                                            className="w-100"
                                            disabled={true}
                                        />
                                }

                            </div>
                        </div>
                    </div>
                )
            }


            {
                toast && (
                    <SuccessToast data={toast} />
                )
            }

            {
                error && (
                    <Toast data={error} />
                )
            }
        </div>
    )
}

export default DepositList;