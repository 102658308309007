/*
 *   File : watsappBtn.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Watsapp button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 16-06-2022
 */

/*import style*/
import styles from '../../styles/components/watsappBtn.module.scss';

const WatsappBtn = (props) => {
	return (
		<button
			id={props.id}
			className={props.disabled ? `e-input-wrap e-whatsapp-btn ${styles.e_watsapp} ${props.className}` : `e-input-wrap e-whatsapp-btn ${styles.e_watsapp} ${props.className}`}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
			type="button"
		>
      	{
				props.left ?
					<img src={props.image} className={`${styles.e_icon}`}/>
				:
					null
			}
			<span>Contact Us</span>
		

		</button>

	);
}


export default WatsappBtn;
