import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

//Asset import
import Info from '../../assets/images/new-design/info.svg';
import Arrow from '../../assets/images/new-design/arrow-up-right-black.svg';

// Component import
import { PieChart } from '../../components/chart/index';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta';
import { SkeletonLoader } from '../../components/loader';
import Nodata from '../../components/note/nodata-placeholder';
import { InvestNowModal, RedeemModal, SuccessPayment } from '../../components/modal';

import CustomToolTip from '../../components/tooltip/customeTooltip';
//Service import
// import services
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import { IconStyleToolTip } from '../../components/tooltip';
import SelectBox from '../../components/input/selectBox';



const Asset = (props) => {

    const [assets, setAssets] = useState(null);
    const [loader, setLoader] = useState(true);
    const [chartWidth, setChartWidth] = useState('350');
    const [chartHeight, setChartHeight] = useState('250');
    const [popupIndex, setPopupIndex] = useState(null);
    const navigator = useNavigate();
    const [currentToolTip, setCurrentToolTip] = useState(false);
    const [targetToolTip, setTargetToolTip] = useState(false);
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
    const [successPopup, setSuccessPopUp] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [haveMandate, setHaveMandate] = useState(null);
    const [assetDetail, setAssetDetail] = useState();
    const [successContent, setSuccessContent] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState(null);
    const [weightedTooltip, setWeightedTooltip] = useState(false);
    const [returns, setReturns] = useState({})
    const [openTooltip, setOpenTooltip] = useState(false);
    const [openAmountTooltip, setOpenAmountTooltip] = useState(false);
    const [filterOptions, setFilterOptions] = useState([])
    // const [filterOptions,set]

    useEffect(() => {
        getHoldings()
    }, [])

    useEffect(() => {
        if (window.innerWidth >= 1350) {
            setChartWidth('330');
            setChartHeight('200')
        }
        if (window.innerWidth <= 1350 && window.innerWidth > 1290) {
            setChartWidth('280');
            setChartHeight('180')
            console.log('med', window.innerWidth)
        }
        if (window.innerWidth < 1290 && window.innerWidth > 990) {
            setChartWidth('270');
            setChartHeight('160')
        }
        if (window.innerWidth < 990) {
            setChartWidth('330');
            setChartHeight('200')
        }
    }, [])

    const getHoldings = () => {
        setLoader(true);
        let url = devconfig.user + '/user/get-holding';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setAssets(response.data);
                setLoader(false);
                let temp;
                let data = response.data.invested_data.allocation
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    temp = element.performance;
                }

                let tempOptions = [];
                // getting options for filter
                Object.keys(temp).forEach((key) => {

                    tempOptions.push({
                        label: Object.keys(temp[key])[0],
                        value: Object.keys(temp[key])[0]
                    })
                    setReturns(tempOptions[0])
                    setFilterOptions(tempOptions)
                });


            } else {
                setLoader(false);
            }
        })
    }

    useEffect(() => {
        if (openModal) {
            const modal = new Modal(document.getElementById("invest"), {})
            modal.show()
        }
    }, [openModal])

    useEffect(() => {
        if (openWithdrawModal === true) {
            const modal = new Modal(document.getElementById("redeem"), {})
            modal.show();
        }
    }, [openWithdrawModal])

    useEffect(() => {
        if (successPopup) {
            const modal = new Modal(document.getElementById("paymentSucess"), {})
            modal.show()
        }
    }, [successPopup])

    // find time weighted returns of corresponding asset 
    const filterReturn = (temp) => {

        let performance;
        if (assets?.invested_data.allocation) {
            let data = assets.invested_data.allocation
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if (element.portfolio_id === temp) {
                    performance = element.performance;
                }
            }
        }
        let data;
        // map performance objects from equities and match with selected filter value
        let c_returns = Object.keys(performance).forEach((key) => {
            if (Object.keys(performance[key])[0] === returns.label) {
                data = Object.values(performance[key])[0]
            } // set object value to data if object value match with filter value
        });
        if (data && data < 0) return <span style={{ color: 'red' }}>{data}%</span>; // return negative values with color red
        else if (data && data > 0) return <span style={{ color: '#0FD99C' }}>+{data}%</span>; //return positive values with color green
        else return <span style={{ color: '#ccc' }}>---</span>;  // return "---" if nothing found with color gray;
    }

    const getPortfolioDetail = () => {
        let url = devconfig.user + '/mf/portfolio';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setAssetDetail(response.data);

            }
        })
        getMandate()
    }

    const getMandate = () => {
        let url = devconfig.user + '/mf/get-mandate';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setHaveMandate(response.data.bank_mandate);
                setOpenModal(true)
            }
        })
    }

    const _successCallback = () => {
        props.markedAsRead();
        setSuccessPopUp(true);

    }

    useEffect(() => {
        if (props.userDetail) _analyticsTrackUserDetails();
    }, [props.userDetail])

    const _analyticsTrackUserDetails = () => {
        window.analytics.identify(props.userDetail?.user_id, {
            path: window.location.pathname
        });
    }

    const _getCurrentValue = (val) => {
        if (val >= 10000000) {
            val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(2) + ' Lac';
        } else if (val >= 10000 || val >= 1000) {
            val = (val / 1000).toFixed(2) + ' K';
        }

        return val
    }


    return (
        <div className="e-asset-container px-lg-5  px-3  mb-5">
            <div className="row">

                {/* Heading */}
                <div className="col-12  mt-lg-3 mt-0 pt-0">
                    <h5 className="e-dashboard-greeting mt-3">
                        Your Assets
                    </h5>
                    <p className="e-dashboard-sub-title">
                        Summary of your investment
                    </p>
                </div>
                <>
                    {
                        loader ?
                            <SkeletonLoader />
                            : !loader && !assets ?
                                <Nodata />
                                :
                                <div className='row pe-0 '>
                                    {/* Allocation card */}
                                    <div className='col-12 mt-3'>
                                        <div className="e-card e-asset-graph-card position-relative p-4">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className='col-12 d-flex'>
                                                        <h5>
                                                            Target allocation
                                                            <img src={Info} className="ms-2"
                                                                onMouseEnter={() => setTargetToolTip(true)}
                                                                onMouseLeave={() => setTargetToolTip(false)}
                                                            />
                                                            {
                                                                targetToolTip && (
                                                                    <IconStyleToolTip
                                                                        content="This reflects the percentage of your investments that 
                                                                        <b>should be allocated</b> to each asset class based on your risk score or customised portfolio."
                                                                    />
                                                                )
                                                            }
                                                        </h5>

                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-lg-5 e-asset-graph-chart pe-0 ps-2'>
                                                            <PieChart
                                                                data={assets && assets.portfolio_data && assets.portfolio_data.graph_data.allocation}
                                                                colors={assets && assets.portfolio_data && assets.portfolio_data.graph_data.colors}
                                                                labels={assets && assets.portfolio_data && assets.portfolio_data.graph_data.fund_class}
                                                                width={chartWidth}
                                                                height={chartHeight}
                                                            />
                                                        </div>
                                                        <div className='col-lg-7 pe-2'>
                                                            <div className=" e-piechart-label ms-2">
                                                                {
                                                                    assets && assets.portfolio_data.graph_data && assets.portfolio_data.graph_data.allocation.map((item, key) => {
                                                                        return (
                                                                            < div className='d-block mb-2 position-relative'>
                                                                                <span className="mb-0 e-number">
                                                                                    <div
                                                                                        className="rounded-circle me-2"
                                                                                        style={{
                                                                                            backgroundColor: assets && assets.portfolio_data && assets.portfolio_data.graph_data.colors[key],
                                                                                            width: '18px', height: '18px', display: 'inline-block', verticalAlign: 'middle'
                                                                                        }}
                                                                                    >
                                                                                    </div>
                                                                                    <span
                                                                                        id={`e-target-${key + 1}`}
                                                                                        className='e-assets-chart-label me-1'
                                                                                        data-bs-toggle="tooltip "
                                                                                        data-bs-placement="top"
                                                                                        title={assets.portfolio_data.graph_data.fund_class[key]}
                                                                                        key={key}
                                                                                    >
                                                                                        {assets.portfolio_data.graph_data.fund_class[key]} :
                                                                                    </span>
                                                                                    {`${assets.portfolio_data.graph_data.allocation[key]}%`}
                                                                                </span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>


                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='e-vertical-rule'></div>
                                                {/* Current allocation */}

                                                <div className="col-lg-6 col-md-6 col-sm-12 mt-3 mt-lg-0  mt-md-0">
                                                    <div className='col-12 d-flex'>
                                                        <h5>
                                                            Current allocation
                                                            <img src={Info} className="ms-2"
                                                                onMouseEnter={() => setCurrentToolTip(true)}
                                                                onMouseLeave={() => setCurrentToolTip(false)}
                                                            />
                                                            {
                                                                currentToolTip && (
                                                                    <IconStyleToolTip
                                                                        content="This reflects the percentage of your investments that <b>
                                                                        is currently allocated</b> to each asset class based on the market value of your
                                                                        holdings."
                                                                    />
                                                                )
                                                            }
                                                        </h5>

                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-lg-5 e-asset-graph-chart pe-0 ps-2'>
                                                            <PieChart
                                                                data={assets && assets.invested_data && assets.invested_data.graph_data.allocation}
                                                                colors={assets && assets.invested_data && assets.invested_data.graph_data.colors}
                                                                labels={assets && assets.invested_data && assets.invested_data.graph_data.fund_class}
                                                                width={chartWidth}
                                                                height={chartHeight}
                                                            />
                                                        </div>

                                                        <div className=' col-lg-7 gx-0'>
                                                            <div className=" e-piechart-label ms-2"  >
                                                                {
                                                                    assets && assets.invested_data && assets.invested_data.graph_data.allocation && assets && assets.invested_data && assets.invested_data.graph_data.allocation.map((item, key) => {
                                                                        return (
                                                                            <div className='d-block mb-2'>
                                                                                <span className="mb-0  e-number" >
                                                                                    <div
                                                                                        className="rounded-circle me-2"
                                                                                        style={{
                                                                                            backgroundColor: assets && assets.invested_data && assets.invested_data.graph_data.colors[key],
                                                                                            width: '18px', height: '18px',
                                                                                            display: 'inline-block',
                                                                                            verticalAlign: 'middle'
                                                                                        }}
                                                                                    >
                                                                                    </div>
                                                                                    <span
                                                                                        id={`e-current-${key + 1}`}
                                                                                        className='e-assets-chart-label'
                                                                                        data-bs-toggle="tooltip "
                                                                                        data-bs-placement="top"
                                                                                        title={assets.invested_data.graph_data.fund_class[key]}
                                                                                        key={key}
                                                                                    >
                                                                                        {assets.invested_data.graph_data.fund_class[key]}
                                                                                    </span> :
                                                                                    {` ${assets.invested_data.graph_data.allocation[key]}%`}
                                                                                </span>

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* Allocation detail */}
                                    <div className='col-lg-9 col-12  mt-4'>
                                        <div className='e-card p-4'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='e-asset-table-title p-3'>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <h6 className='pt-1'>
                                                                    Asset class
                                                                </h6>
                                                            </div>
                                                            <div className='col-4 ps-lg-3 ps-md-3 ps-0'>
                                                                <h6 className='pt-1'>
                                                                    Current weight
                                                                </h6>
                                                            </div>
                                                            <div className='col-4 d-flex e-asset-detail-wrapper '>
                                                                <h6 className='position-relative pt-1'>
                                                                    Returns
                                                                    <img
                                                                        src={Info}
                                                                        className="ms-2"
                                                                        onMouseEnter={() => {
                                                                            setWeightedTooltip(true);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setWeightedTooltip(false);
                                                                        }}
                                                                    />
                                                                    {
                                                                        weightedTooltip && (
                                                                            <IconStyleToolTip
                                                                                content="Time weighted returns is the compound growth rate of your investment portfolio."
                                                                            />
                                                                        )
                                                                    }

                                                                </h6>
                                                                <div className='e-asset-select ms-3'>
                                                                    <SelectBox
                                                                        value={returns}
                                                                        isSearchable={false}
                                                                        options={filterOptions}
                                                                        selectchange={(temp) => {
                                                                            setReturns(temp)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row px-3'>
                                                        {
                                                            Object.keys(assets.portfolio_by_class).map(function (key, i) {
                                                                return (
                                                                    <>
                                                                        <div className='e-table-subtitle  text-center py-2 mt-3'>
                                                                            <h6>
                                                                                {key}
                                                                            </h6>
                                                                        </div>
                                                                        {
                                                                            assets.portfolio_by_class[key].map((item, index) => {
                                                                                // let performance = assets.invested_data.
                                                                                return (
                                                                                    <div className='col-12 mt-3 position-relative' key={index}
                                                                                        onMouseEnter={() => {
                                                                                            let ele = document.getElementById(`pop-up-${i}-${index}`);
                                                                                            console.log('element', ele);
                                                                                            ele.classList.add('e-asset-popup-open')
                                                                                        }}
                                                                                        onMouseLeave={() => {
                                                                                            let ele = document.getElementById(`pop-up-${i}-${index}`);
                                                                                            console.log('element', ele);
                                                                                            ele.classList.remove('e-asset-popup-open')
                                                                                        }}
                                                                                    >
                                                                                        <div className="row e-table-body p-3" >
                                                                                            <div className="col-lg-4 col-4 mt-1 pe-0 ps-1">
                                                                                                <p>{item.display_name}</p>
                                                                                            </div>
                                                                                            <div className="col-lg-4 col-4 e-scan-loader mt-1 pe-0">
                                                                                                <span className="e-value pe-3">{item.allocation}%</span>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-3  mt-1 pe-0 e-filter-return">
                                                                                                <span>{filterReturn(item.portfolio_id)}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='e-allocation-detail p-4'
                                                                                            id={`pop-up-${i}-${index}`}
                                                                                            onMouseEnter={() => {
                                                                                                let ele = document.getElementById(`pop-up-${i}-${index}`);
                                                                                                console.log('element', ele);
                                                                                                ele.classList.add('e-asset-popup-open')
                                                                                            }}

                                                                                        >
                                                                                            <div className='row'>
                                                                                                <div className='col-12'>
                                                                                                    <h6 className='e-fund-name'>
                                                                                                        {item.scheme_name}
                                                                                                    </h6>
                                                                                                    <p className='e-display-name'>{item.display_name}</p>
                                                                                                </div>
                                                                                                <div className='col-5 mt-1'>
                                                                                                    <label>
                                                                                                        NAV
                                                                                                    </label>
                                                                                                    <p className='e-card-value'>
                                                                                                        <span className='e-rupee-symbol'>₹ </span>
                                                                                                        {
                                                                                                            item.nav ?
                                                                                                                item.nav.toFixed(2)
                                                                                                                :
                                                                                                                0
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='col-6 mt-1'>
                                                                                                    <label>
                                                                                                        Current value
                                                                                                    </label>
                                                                                                    <p className='e-card-value'>
                                                                                                        <span className='e-rupee-symbol'>₹ </span>
                                                                                                        {
                                                                                                            item.current_value ?
                                                                                                                Number((item.current_value).toFixed(2)).toLocaleString('en-IN')
                                                                                                                :
                                                                                                                0
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>

                                                                                                <div className='col-5 mt-1'>
                                                                                                    <label>
                                                                                                        Target weight
                                                                                                    </label>
                                                                                                    <p className='e-card-value'>
                                                                                                        {item.target_weight}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='col-6 mt-1'>
                                                                                                    <label>
                                                                                                        Current weight
                                                                                                    </label>
                                                                                                    <p className='e-card-value'>
                                                                                                        {item.current_weight}
                                                                                                    </p>
                                                                                                </div>

                                                                                                <div className='col-5 mt-1'>
                                                                                                    <label>
                                                                                                        Units
                                                                                                    </label>
                                                                                                    <p className='e-card-value'>
                                                                                                        {item.units.toFixed(2)}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='col-6 mt-4'>
                                                                                                    <label>

                                                                                                    </label>
                                                                                                    <a
                                                                                                        className=''
                                                                                                        href={'/scrip-detail?id=' + item.portfolio_id}
                                                                                                    // onClick={() => {
                                                                                                    //     navigator()
                                                                                                    // }}
                                                                                                    >
                                                                                                        Know more
                                                                                                        <img src={Arrow} className="ms-2" />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </>
                                                                )
                                                            })

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Portfolio summary */}
                                    <div className='col-lg-3 col-md-6 col-sm-12  col-12 mt-4'>
                                        <div className="e-card e-asset-portfolio-card p-4">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <h6 className='e-card-title'>
                                                        Portfolio Summary
                                                    </h6>
                                                </div>
                                                <div className='col-12 mt-3'>
                                                    <label>
                                                        Current Value
                                                    </label>
                                                    <div
                                                        className='e-current-value position-relative'
                                                        onMouseEnter={() => {
                                                            setOpenTooltip(true)
                                                        }}
                                                        onMouseLeave={() => {
                                                            // $(`#e-portfolio-value`).removeClass('e-hover-popup');
                                                            setOpenTooltip(false)
                                                        }}
                                                    >
                                                        <span className='e-dashboard-rupee'>₹ </span>
                                                        {
                                                            assets.portfolio_data.investment_summary.current_value ?
                                                                _getCurrentValue(assets.portfolio_data.investment_summary.current_value)
                                                                :
                                                                null
                                                        }
                                                        {
                                                            openTooltip && (
                                                                <CustomToolTip
                                                                    symbol={true}
                                                                    data={assets.portfolio_data.investment_summary.current_value.toLocaleString('en-IN')}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-12 mt-3'>
                                                    <label>
                                                        Amount Invested
                                                    </label>
                                                    <div
                                                        className='e-value position-relative'
                                                        onMouseEnter={() => {
                                                            setOpenAmountTooltip(true)
                                                        }}
                                                        onMouseLeave={() => {
                                                            // $(`#e-portfolio-value`).removeClass('e-hover-popup');
                                                            setOpenAmountTooltip(false)
                                                        }}
                                                    >
                                                        <span className='e-dashboard-rupee-sub'>₹ </span>
                                                        {
                                                            assets.portfolio_data.investment_summary.invested_value ?
                                                                _getCurrentValue(assets.portfolio_data.investment_summary.invested_value)
                                                                :
                                                                null
                                                        }
                                                        {
                                                            openAmountTooltip && (
                                                                <CustomToolTip
                                                                    symbol={true}
                                                                    top="-3px"
                                                                    data={assets.portfolio_data.investment_summary.invested_value.toLocaleString('en-IN')}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-12 mt-3'>
                                                    <PrimaryBtn
                                                        name="Invest more"
                                                        className="e-disabled"
                                                        handleClick={() => {
                                                            getPortfolioDetail()
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-12 mt-3'>
                                                    <SecondaryBtn
                                                        name="Withdraw"
                                                        handleClick={() => {
                                                            setOpenWithdrawModal(true)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    }
                    {
                        openModal ?
                            <InvestNowModal
                                haveMandate={haveMandate}
                                location={props.type === 1 ? "overview" : "asset"}
                                details={{}}
                                assetsDetail={assetDetail}
                                callBack={(data, amount) => {
                                    console.log('data', data);
                                    console.log('stst', amount);
                                    if (data === 'SUCCESS') {
                                        setSuccessContent(
                                            `We have received your payment of ₹${amount}. 
                                    The orders have been placed with each asset manager and you should 
                                    receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.`
                                        );
                                        setPaymentStatus(true);
                                    }
                                    else {
                                        setSuccessContent(
                                            `Investment failed please try again `
                                        );
                                        setPaymentStatus(false);
                                    }

                                    _successCallback();
                                    // getHoldings();
                                }}
                                unmountModal={() => setOpenModal(false)}
                            />
                            :
                            null
                    }

                    {
                        openWithdrawModal && (
                            <RedeemModal
                                modalClose={() => { setOpenWithdrawModal(false) }}
                                success={(data) => {
                                    setWithdrawAmount(data);
                                    // getHoldings();
                                    _successCallback();
                                }}
                                heading={(data) => props.heading(data)}
                            />
                        )
                    }
                    {
                        successPopup && (
                            <SuccessPayment
                                editScore={false}
                                heading={
                                    successContent ?
                                        paymentStatus === true ?
                                            'Investment Successful'
                                            :
                                            'Investment Failed'
                                        :
                                        "Withdrawal Successful"
                                }//type
                                subTitle={!successContent && `You have successfully withdrawn ₹${withdrawAmount}`}
                                content={
                                    successContent ?
                                        successContent
                                        :
                                        `We are processing your withdrawal of ₹${withdrawAmount}. The orders have
                            been placed with each asset manager and you should
                            receive your mutual fund allocations in 3-4 days. They will
                            show on your dashboard then.`
                                }
                                status={successContent ? paymentStatus : true}//status
                                from="portfolio"
                                close={() => {
                                    // if (!status) {
                                    // 	window.location.href = "/orders"
                                    // }
                                }}
                            />
                        )
                    }
                </>
            </div>
        </div >
    )
}

export default Asset