// import package
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import asset
import Inprogress from '../../assets/images/onboard/in-progress.svg';
import Success from '../../assets/images/modal/success.svg';
import Sample from '../../assets/images/new-design/samplePose.png';
import Record from '../../assets/images/new-design/video.svg';


// import component 
import { WebcamOpen } from '../../components/input';
import { PrimaryBtn, PrimaryIconBtn } from '../../components/cta';
import { SuccessModal, SuccessPayment } from '../../components/modal/index';
import { Modal } from 'bootstrap';
import { Toast } from '../../components/note';

// import service
import Config from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';

const Verification = (props) => {
    const [recorded, setRecorded] = useState(false);
    const [needPreview, setNeedPreview] = useState(false);
    const [video, setVideo] = useState(null);
    const [verified, setVerified] = useState(false);
    const [videoLoader, setVideoLoader] = useState(false);
    const [eKyc, setKyc] = useState(false);
    const [error, setError] = useState(null);
    const [startRecord, setStartRecord] = useState(false)

    let navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000)
    }, [error])

    const submitEkyc = (temp) => {
        setVideoLoader(true);
        let url = Config.user + '/auth/update-ekyc';
        let data = JSON.stringify({
            video: temp
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                checkKYCStatus();
                setVideoLoader(false);
            } else {
                setKyc(false);
                setVideoLoader(false)
                setError(response.message)
            }
        })
    }

    const checkKYCStatus = () => {
        let url = Config.user + '/auth/get-kyc';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                if (response.data.status === 'REJECTED') {
                    const modal = new Modal(document.getElementById("paymentSucess"), {})
                    modal.show();
                } else {
                    setKyc(true);
                    const modal = new Modal(document.getElementById("success"), {})
                    modal.show();
                }
            }
        })
    }

    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-12 e-mobile-confirm-wrap mb-5 pb-5">
            <div className="e-process-wrap e-verification positio-relative">
                <h5 className="e-onboard-form-title mt-2">
                    Video Verification
                </h5>
                {/* Form */}
                <div className="row pt-3 pe-2">
                    <div className="col-lg-12">
                        {
                            verified === false ?
                                <div className="e-wraper">
                                    {
                                        !startRecord && (
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <p className='e-video-instruction'>
                                                        Write the below code on a piece of
                                                        paper and hold it in front of the
                                                        camera
                                                    </p>
                                                    <div className="e-video-otp text-center">
                                                        {props.otp}
                                                    </div>
                                                    <p className='e-video-instruction mt-3'>
                                                        Ensure that your face and the code are clearly visible
                                                    </p>
                                                    <button className='e-video-btn mt-lg-3 mt-md-3 mt-sm-0 mt-0 '
                                                        onClick={() => {
                                                            setStartRecord(true);
                                                        }}
                                                    >
                                                        <img
                                                            src={Record}
                                                            className="me-2"
                                                        />
                                                        Record
                                                    </button>
                                                </div>
                                                <div className='col-lg-4 col-md-4 col-sm-12 mt-lg-0  mt-4'>
                                                    <img
                                                        src={Sample}
                                                        className="e-sample-video-pose"
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className='row'>
                                        <div className='col-lg-6'>

                                        </div>
                                        {/* <div className='col-lg-6 text-end'>
                                            {
                                                recorded ?
                                                    <PrimaryIconBtn name="Preview recorded video"
                                                        className="mt-3 pe-0"
                                                        handleClick={() => setNeedPreview(true)} />
                                                    :
                                                    null
                                            }
                                        </div> */}
                                    </div>
                                    {
                                        startRecord && (
                                            <WebcamOpen
                                                submit={(temp) => { submitEkyc(temp); setVideo(temp) }}
                                                showPreview={needPreview}
                                                completed={(temp) => {
                                                    setRecorded(temp);
                                                    if (temp === false) {
                                                        setNeedPreview(false)
                                                    }
                                                }}
                                            />
                                        )
                                    }

                                </div>
                                :
                                <div className="e-wraper">
                                    <div className='row'>
                                        <div className="col-lg-6 col-6 e-verification-wraper text-center">
                                            {props.otp}
                                        </div>
                                        <div className='col-lg-9 col-9 d-flex e-progress-text pt-4'>
                                            <img src={Inprogress} alt="" />
                                            <p className='ps-1'>In progress</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-lg-5 offset-lg-7 col-md-6 col-7 text-end">
                                            <PrimaryBtn
                                                name="Continue to next step "
                                                className={
                                                    videoLoader ?
                                                        "e-verification-btn e-full-width e-btn-loader"
                                                        : "e-verification-btn e-full-width"
                                                }
                                                handleClick={() => submitEkyc()}
                                            />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <SuccessPayment status={true} heading="Your KYC verification failed" close={() => navigate('/onboard')} />
            <SuccessModal data="Verified successfully"
                img={Success}
                type={eKyc}
                loader={(data) => {
                    props.success();
                }} />
            {
                error ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}


export default Verification; 