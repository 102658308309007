/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Overview
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-12-2021
 */

/*import packages*/
import { useState, useEffect, React } from 'react';
import { Link, useNavigate } from "react-router-dom";

/*import components*/
import { HeaderInner, Footer } from '../../components/menu/index';
import { PieChart } from '../../components/chart/index';
import { PrimaryIconBtn } from '../../components/cta/index';
import { IconStyleToolTip } from '../../components/tooltip/index';
import SelectBox from '../../components/input/selectBox';
import Nodata from '../../components/note/nodata-placeholder';

/*import services*/
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import GetURLVar from '../../services/getUrl';
import RedirectUser from '../../services/redirectUser';

/*import assets*/
import TooltipIcon from '../../assets/images/tooltip/icon.svg';
import Tooltip from '../../assets/images/tooltip/icon-blue.svg';
import Arrow from '../../assets/images/scrip/arrow.svg';
import BackArrow from '../../assets/images/new-design/arrow-back.svg';
import { Loader, SkeletonLoader } from '../../components/loader';
import { RedeemModal, SuccessPayment } from '../../components/modal';
import { Modal } from 'bootstrap';


const ScriptDetail = (props) => {
	const [isShown, setIsShown] = useState(false);
	const [showTootltip, setShowTooltip] = useState(false);
	const [click, setClick] = useState(0);
	//eslint-disable-next-line
	const [id, setId] = useState();
	const [loader, setLoader] = useState(true);
	const [detail, setDetail] = useState();
	const [returns, setReturns] = useState({});
	const [openWithdrawModal, setOpenModalWithdrawModal] = useState(false);
	const [status, setStatus] = useState(true);
	const [type, setType] = useState(null)
	const [chartWidth, setChartWidth] = useState('200')
	const [chartHeight, setChartHeight] = useState('200');
	const [totalValue, setTotalValue] = useState(null);
	const [filterOptions, setFilterOptions] = useState([]);

	let colorIndex = -1;

	let navigator = useNavigate();
	var count = -1;
	useEffect(() => {
		if (window.innerWidth >= 1350) {
			setChartWidth('330');
			setChartHeight('200')
		}
		if (window.innerWidth <= 1350 && window.innerWidth > 1290) {
			setChartWidth('280');
			setChartHeight('180')
			console.log('med', window.innerWidth)
		}
		if (window.innerWidth < 1290 && window.innerWidth > 990) {
			setChartWidth('270');
			setChartHeight('160')
		}
		if (window.innerWidth < 990) {
			setChartWidth('330');
			setChartHeight('200')
		}
	}, [])

	// for dev purpose, update when resizing screen 
	useEffect(() => {
		window.addEventListener('resize', (event) => {
			if (window.innerWidth >= 1350) {
				setChartWidth('330');
				setChartHeight('200')
			}
			if (window.innerWidth <= 1350 && window.innerWidth > 1290) {
				setChartWidth('280');
				setChartHeight('180')
				console.log('med', window.innerWidth)
			}
			if (window.innerWidth < 1290 && window.innerWidth > 990) {
				setChartWidth('270');
				setChartHeight('160')
			}
			if (window.innerWidth < 990) {
				setChartWidth('330');
				setChartHeight('200')
			}
		});

		return () => {
			window.removeEventListener('resize', (event) => { return null });
		};
	}, [])

	useEffect(async () => {
		let data = await RedirectUser();
		GetURLVar().then((query) => {
			if (query && query.id) {
				setId(query.id);
				if (!data.redirect) {
					getDetail(query.id);
				}
				else {
					navigator(data.link);
				}

			}
		})
	}, [])

	useEffect(() => {
		if (props.userDetail) {
			window.analytics.identify(props.userDetail.user_id, {
				path: window.location.pathname,
			});
		}
	}, [props.userDetail])

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [])

	useEffect(() => {
		if (openWithdrawModal === true) {
			const modal = new Modal(document.getElementById("redeem"), {})
			modal.show();
		}
	}, [openWithdrawModal])

	// get detail of asset
	const getDetail = (temp) => {
		setLoader(true)
		let url = devconfig.user + '/portfolio/asset-detail';
		let data = JSON.stringify({
			"portfolio_id": parseInt(temp),
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setTotalValue(response.data.graph_data.allocation.reduce((a, b) => a + b, 0));
				let options = response.data.mf_trailing?.map((item, key) => {
					return (
						{ value: item[Object.keys(item)[0]], label: Object.keys(item)[0] }
					)
				})
				if (response.data.mf_trailing) {
					setReturns({ value: response.data.mf_trailing[0][Object.keys(response.data.mf_trailing[0])[0]], label: Object.keys(response.data.mf_trailing[0])[0] });
				}
				setFilterOptions(options);
				setDetail(response.data);
				setLoader(false);

			}
			else {
				setDetail(null);
				setLoader(false);
			}
		})
	}


	const openModal = (data) => {
		setOpenModalWithdrawModal(false);
		if (data === "SUCCESS") {
			setStatus(false)
			const modal = new Modal(document.getElementById("paymentSucess"), {})
			modal.show()
			setType("Payment successful")
		} else {
			setStatus(true)
			const modal = new Modal(document.getElementById("paymentSucess"), {})
			modal.show()
			setType("Payment failed")
		}

	}

	//pie chart color
	const backgroundColor = [
		"#1329E6",
		"#644DEC",
		"#8C65EC",
		"#9A87F1",
		"#AF93F6",
		"#BDACF6",
		"#DED0FB",
		"#E3D6FF",
		"#EDE8FA"
	]

	const getWidth = (item) => {
		let width;
		let base;
		let length = detail?.graph_data.allocation.length;
		if (length < 10) base = 50;
		else if (length > 10 && length < 20) base = 48;
		else if (length > 20) base = 100;
		else base = 50;
		width = base * item / 100;
		return `${item}%`
	}
	return (

		<>

			<div className="px-lg-5 px-4 e-asset-detail-wrapper">
				{
					loader ?
						<SkeletonLoader />
						:
						!loader && !detail ?
							<div style={{ height: '100vh' }} >
								<Nodata note="No detail found" className="mt-5" />
							</div>
							:
							<>
								{/* <HeaderInner logo={InnerLogo} profileData={(data) => null} openWithdrawModal={() => { setOpenModalWithdrawModal(true) }}  /> */}
								<div className="row e-asset-details mt-lg-2 mt-md-2 mt-sm-0 mt-0">
									<div className="col-lg-12">
										<Link to="/assets">
											<span className="e-back" >
												<img src={BackArrow} className="e-back-arrow me-3" alt="" />
												Back
											</span>
										</Link>
										<h4 className='e-fund-name mt-2'>{detail.mf_name}</h4>
										<p className='e-amc-name'>{detail.amc_name}</p>
									</div>
								</div>

								<div className="row mt-3">

									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-12">
											<div className="e-fund-wrapper">
												<h5>About this fund</h5>
												<p className="">{detail.about}</p>
												<PrimaryIconBtn name="Know more" right={true} image={Arrow} className="pt-3" handleClick={() => window.open(detail.website, "_blank")} />
											</div>
										</div>
										<div className='col-lg-6 col-md-6 col-sm-12'>
											<div className="e-fund-wrapper">
												<h5>Asset details</h5>
											</div>
											<div className='row'>
												<div className='col-4'>
													<label className='e-label'>
														NAV
													</label>
													<div className='e-value'>
														<span className='e-rupee-symbol position-relative'
															style={{ fontWeight: '400', fontSize: '16px', top: '-1px' }}>₹ </span>
														{detail.asset_details.nav.toLocaleString("en-IN")}
													</div>
												</div>
												<div className='col-4'>
													<label className='e-label'>
														Current value
													</label>
													<div className='e-value'>
														{
															detail.asset_details.current_value ?
																<>
																	<span className='e-rupee-symbol position-relative'
																		style={{ fontWeight: '400', fontSize: '16px', top: '-1px' }}>₹
																	</span>
																	{detail.asset_details.current_value.toLocaleString('en-IN')}
																</>

																:
																'-'
														}
													</div>
												</div>
												<div className='col-4'>
													<label className='e-label'>
														Returns
													</label>
													<div className='e-value'>
														{
															returns.value.toFixed(2)
														}%
													</div>
												</div>
												<div className='col-4 mt-3 position-relative'>
													<label className='e-label'>
														Target weight
														<img src={TooltipIcon} className="e-tooltip-hover ms-2" alt="" onMouseEnter={() => setShowTooltip(true)}
															onMouseLeave={() => setShowTooltip(false)} />
															{
															showTootltip ?
																<IconStyleToolTip icon={Tooltip} content="This reflects the percentage of your investments that <b>should be
																allocated</b> to each this fund based on your risk score or customised portfolio." />
																:
																null
														}
													</label>
													<div className='e-value'>
														{detail.asset_details.target_weight}
													</div>
												</div>

												<div className='col-4 mt-3 position-relative'>
													<label className='e-label'>
														Current weight
														<img src={TooltipIcon} className="e-tooltip-hover ms-2" alt="" onMouseEnter={() => setIsShown(true)}
															onMouseLeave={() => setIsShown(false)} />
														{
															isShown ?
																<IconStyleToolTip icon={Tooltip} content="This reflects the percentage of your investments that <b>is currently
																allocated</b> to this fund class based on the market value of your holdings." />
																:
																null
														}
													</label>
													<div className='e-value'>
														{detail.asset_details.current_weight}
													</div>
												</div>

												<div className='col-4 mt-3'>
													<div className='e-asset-detail-select'>
														<SelectBox
															value={returns}
															isSearchable={false}
															options={filterOptions}
															selectchange={(temp) => {
																setReturns(temp)
															}}
														/>
													</div>
												</div>

												<div className='col-4 mt-3'>
													<label className='e-label'>
														Units
													</label>
													<div className='e-value'>
														{detail.asset_details.units.toFixed(2)}
													</div>
												</div>
											</div>
										</div>

										<div className="col-lg-12 col-md-12 mt-4">
											<div className="e-pie-container position-relative row">
												<div className="e-fund-wrapper mb-1">
													<h5>Sector allocation</h5>
												</div>
												<div className='col-lg-8'>
													{
														detail?.graph_data && (
															<div className='e-chart-wrapper'>
																{
																	detail.graph_data.allocation.map((item, key) => {
																		if (count >= 8) count = 0;
																		else count = count + 1;
																		return (
																			<div className='d-inline-block e-chart-bar'
																				style={{
																					backgroundColor: backgroundColor[count],
																					width: getWidth(item),
																					height: '100%',
																					borderRadius:
																						key === 0 ? '8px 0px 0px 8px'
																							:
																							key === detail.graph_data.allocation.length - 1 && getWidth(item) == '0%' ?
																								key === detail.graph_data.allocation.length - 2 ?
																									'0px 8px 8px 0px'
																									:
																									'0px 8px 8px 0px'
																								:
																								null
																				}}
																			>
																			</div>
																		)
																	})
																}
															</div>
														)
													}
													<div className='row mt-4'>
														{
															detail.graph_data && detail.graph_data.allocation.map((item, key) => {
																if (colorIndex >= 8) colorIndex = 0;
																else colorIndex = colorIndex + 1;
																return (
																	<div className='col-lg-6 col-md-6 col-12 mb-3'>
																		<div className='d-flex'>
																			<div className='e-indicator mt-1'
																				style={{ backgroundColor: backgroundColor[colorIndex] }}
																			>
																			</div>
																			<div className='e-legend-label'>
																				<div className='e-name'>{detail.graph_data.sector[key]}</div>
																				<div className='e-percentage'> : {item}%</div>
																			</div>
																		</div>


																	</div>
																)
															})
														}
													</div>
												</div>
											</div>
										</div>



									</div>
									{/* <div className="col-lg-8 mt-5">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="e-fund-wrapper">
                            <div className="row">
                              <div className="col-lg-6 col-12">
                                <h5>Returns</h5>
                              </div>
                              <div className="col-lg-6 col-md-12 col-12 text-end gx-0 e-mobile-scroll">
                                <div className="" style={{ minWidth: "345px" }}>
                                  <div className="e-span-wrap">
                                    {
                                      detail.mf_trailing && detail.mf_trailing.map((item, key) => {
                                        return (
                                          <span className={click === key ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => { setClick(key); setReturns(Object.values(item)) }}>
                                            {Object.keys(item)}
                                          </span>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <h5 className="e-returns">{returns}%</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 mt-4">
                          <div className="e-fund-wrapper">
                            <div className="row">
                              <div className="col-lg-12 mb-4">
                                <h5> Asset details</h5>
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                                <h6>NAV</h6>
                                <p className="e-currnt-value pt-2">{detail.asset_details.nav_text}</p>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                                <h6>Current value</h6>
                                <p className="e-currnt-value pt-2">{detail.asset_details.current_value_text}</p>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                                <h6>Target weight</h6>
                                <p className="e-currnt-value pt-2">{detail.asset_details.target_weight}</p>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-3 position-relative">
                                <h6>Current weight<img src={TooltipIcon} className="e-tooltip-hover ms-2" alt="" onMouseEnter={() => setIsShown(true)}
                                  onMouseLeave={() => setIsShown(false)} /></h6>
                                {
                                  isShown ?
                                    <IconStyleToolTip className="e-tooltip" icon={Tooltip} content="The Securities and Exchange Board of India (SEBI), which regulates mutual funds and other securities, requires us to collect this information as part of a mandatory Know Your Client (KYC) process. " />
                                    :
                                    null
                                }
                                <p className="e-currnt-value pt-2">{detail.asset_details.current_weight}</p>
                              </div>
                              <div className="col-lg-6">
                                <h6>Units</h6>
                                <p className="e-currnt-value pt-2 mb-0">{detail.asset_details.units}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div> */}

								</div>
							</>
				}
			</div>
			{/* <Footer logo={FooterLogo} playstore={Playstore} appstore={Appstore} /> */}
			<SuccessPayment from="/scrip-detail" heading={type} status={status} close={() => { }} />
			{
				openWithdrawModal ?
					<RedeemModal
						modalClose={() => { setOpenModalWithdrawModal(false) }}
						success={(data) => {
							openModal(data);
						}}
						heading={(data) => { }}
					/>
					:
					null
			}
		</>
	)
}

export default ScriptDetail;

