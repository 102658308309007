
/*import package*/
import React, { Component } from 'react';
import Chart from "react-apexcharts";

class LineChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [{
				name: '',
				type: 'area',
				data: this.props.data.data
			}],
			options: {
				chart: {
					type: 'area',
					type: 'line',
				},
				stroke: {
					width: [1],
				},
				xaxis: {
					categories: this.renderLabel(this.props.data.categories),
					labels: {
						formatter: (idx) => { return idx != " " ? idx : '' }, // remove data point in x-axis if " ";
						style: {
							colors: ['#6D6D6D'],
							fontSize: '10px',
							fontFamily: 'Montserrat',
							fontWeight: 300,
							cssClass: 'apexcharts-xaxis-label',
						},
					},
				},
				yaxis: {
					labels: {
						formatter: (idx) => { return Math.abs(idx) > 0 ? props.currency + Math.sign(idx) * ((Math.abs(idx) / 1000).toFixed(1)) + 'k' : Math.sign(idx) * Math.abs(idx) },
						style: {
							colors: ['#6D6D6D'],
							fontSize: '10px',
							fontFamily: 'Montserrat',
							fontWeight: 300,
							cssClass: 'apexcharts-yaxis-label',
						},
					},
				},
				grid: {
					show: true,
					borderColor: '#6d6d6d59',
					strokeDashArray: 2,
					position: 'back',
					// column: {
					//   colors: '#6D6D6D',
					//   opacity: 0.7,
					// },  
				},
				fill: {
					type: 'gradient',
					gradient: {
						// type: "diagonal1",
						// shadeIntensity: 0,
						// opacityFrom: 0.1,
						// opacityTo: 0.5,
						// stops: [0, 90, 100]
						shade: "light",
						type: "vertical",
						shadeIntensity: 0,
						opacityFrom: 0.1,
						opacityTo: 0.5,
						stops: [0, 0, 60, 200]
					},
				},
				colors: this.props.colors,
				markers: {
					size: 0
				},
				tooltip: {
					enabled: true,
					intersect: false,
					inverseOrder: false,
					followCursor: false,
					x:{
						formatter: (x) => { return this.renderTooltip(x) },
						// formatter: function (x) {
						// 	let data = this.props.data.categories[x]
						// 	return data;
						// }
					},
					y: {
						formatter: function (y) {
							if (typeof y !== "undefined") {
								return '₹ ' + y.toFixed(0);
							}
							return y;
						}
					}
				}
			},
		};
	}

	renderTooltip = (x) =>{
		return this.props.data.categories[x-1] // return x-axis label based on tooltip index 
	}

	// return x-axis label based on count
	renderLabel = (temp) =>{
		let labelArray =[];
		console.log(temp.length)
		if(temp.length <= 30) {
			return temp // return every day
		}
		for (let index = 0; index < temp.length; index++) {
			// return every week
			if( temp.length <= 90 ) {
				if( index % 7 === 0 ) {
					labelArray.push(temp[index]);			
				}else{
					labelArray.push(" ");
				}
			}
			//  return every month
			else if ( temp.length > 90 ) {
				if ( index === 0) {
					labelArray.push(temp[index]);	
				}
				else if( temp[index].split(' ')[0] == '01' ) {
					labelArray.push(temp[index]);			
				}else{
					labelArray.push(" ");
				}
			}
			
		}
			return labelArray

	}
	render() {
		return (
			<Chart options={this.state.options}
				series={this.state.series}
				type="line"
				height={490}
				width="100%"
			/>
		);
	}
}
export default LineChart;