import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderOuterFullWidth } from '../../components/menu';


/*import assets*/
import Logo from '../../assets/images/menu/logo.svg';
const NotFound = () => (
    <>
        <HeaderOuterFullWidth logo={Logo} headerType={1} onboarding={false} />
        <div className="container-fluid e-auth-container e-onboard-process e-onboarding-step-4 mt-5 pt-5 ps-5">
            <h1>404 - Not Found!</h1>
            <Link to="/">Go Home</Link>
        </div>
    </>
);

export default NotFound;