/*
 *   File : headerOuterFull.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Login Header
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import packages*/
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

/*import components */
import { PrimaryIconBtn, SecondaryBtn, PrimaryLink } from '../cta/index';

import PlayBtn from '../../assets/images/onboard/video-play.png';
import Power from '../../assets/images/power-off.png';

/*import styles*/
import styles from '../../styles/components/headerOuterFull.module.scss';

const HeaderOuterFullWidth = (props) => {
  return (
    <>
      {
        props.headerType === 1 ?
          <div className={props.type === "confirm" ? `${styles.e_headerOuterFullConfirm} e-header-outer-full-confirm` : `${styles.e_headerOuterFull} e-header-outer-full`}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <Link to={props.onboarding ? props.edit_score ? '/change-score' : `/onboard` : '/login'}>
                  <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                </Link>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                <span 
                  class="e-logout-icon mt-2" 
                  data-bs-toggle="tooltip" 
                  data-bs-placement="top" 
                  title="Logout"
                  onClick={()=> {
                    localStorage.clear(); window.location.href = "/login"
                  }}
                >
                  <img src={Power} width="25"/>
                  <span className='pt-1 e-label'>LOGOUT</span>
                </span>  
              </div>
            </div>
          </div>
          : props.headerType === 2 ?
            <div className={` ${styles.e_headerOuterBtn} e-header-outer-btn`}>
              <div className="row">

                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <Link to='/login'>
                    <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end pe-0">
                  <Link to='/signup'>
                    <SecondaryBtn name="Create account" className={`${styles.e_create_acct_btn}`} />
                  </Link>
                </div>
              </div>
            </div>
            : props.headerType === 3 ?
              <div className={` ${styles.e_headerOuterLogin} e-header-outer-login`}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <Link to='/login'>
                      <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
                    <Link to="/">
                      <PrimaryIconBtn name="Login" className={`${styles.e_login_btn}`} />
                    </Link>
                  </div>
                </div>
              </div>
              : props.headerType === 4 ?
                <div className={` ${styles.e_headerOuterLogin} e-header-outer-login`}>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                      <Link to='/onboard'>
                        <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                      </Link>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-6 mt-lg-0 mt-md-0 mt-3 col-12 text-lg-end text-md-end text-sm-end d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-between">
                      <div onClick={() => props.openModal()}>
                        <button className={`${styles.e_video_play}`} ><img src={PlayBtn} /></button>
                        <PrimaryLink name="Why we created Mintd" className={`${styles.e_video_btn} ps-2`} />
                        
                      </div>
                        <span 
                          style={{cursor: 'pointer'}}
                          class="ms-lg-4 ms-md-4 ms-sm-2 ms-2 mt-0 mt-lg-1 mt-md-1 mt-sm-1 " 
                          data-bs-toggle="tooltip" 
                          data-bs-placement="top" 
                          title="Logout"
                          onClick={()=> {
                            localStorage.clear(); window.location.href = "/login"
                          }}
                        >
                          <img src={Power} style={{paddingTop: '2px'}} width="25"/>
                        </span>  
                    </div>
                  </div>
                </div>

                : props.headerType === 5 ?
                  <div className={` ${styles.e_headerauthLogin} e-header-outer-login`}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <Link to='/login'>
                          <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
                        <Link to="/">
                          <PrimaryIconBtn name="Login" className={`${styles.e_login_btn}`} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  : props.headerType === 6 ?
                    <div className={` ${styles.e_headerOuterLogin} e-header-outer-login`}>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <Link to='/login'>
                            <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                          </Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-lg-end text-md-end text-sm-end">
                          <div onClick={() => props.openModal()}>
                            <PrimaryLink name="Why we created Mintd" className={`${styles.e_video_btn}`} />
                            <button className={`${styles.e_video_play}`} ><img src={PlayBtn} /></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className={`${styles.e_headerOuterFullLogin} e-header-outer-full-login`}>
                      <div className="row">

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <Link to="/login">
                            <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                          </Link>
                        </div>
                      </div>
                    </div>
      }
    </>
  )
}

export default HeaderOuterFullWidth;
{/* <div className="container-fluid px-0">
      <div className={` ${styles.e_headerOuterFull}`}>
        <div className="row">
          {
            props.headerType === 1 ?
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                <h6>dajds</h6>
              </div>
              : props.headerType === 2 ?
                <>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end pe-0">
                    <SecondaryBtn name="Create account" className={`${styles.e_create_acct_btn}`} handleClick={() => window.location.href = "/signup"} />
                  </div>
                </>
                :
                <>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end pe-0">
                    <PrimaryIconBtn name="Login" className={`${styles.e_login_btn}`} handleClick={() => window.location.href = "/"} />
                  </div>
                </>
          }

        </div>
      </div>
      

    </div>
 */}
