/* import package*/
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

/*import components*/
import { HeaderOuterFullWidth } from '../../components/menu/index';
import ProgressBar from '../../components/input/progress.js';
import { InvestNowModal } from '../../components/modal';
import { Modal } from 'bootstrap';
import LeadSquared from '../../services/leadSquredServices';

//import containers
import PersonalDetails from './personalDetails';
import AddressDetail from './addressData';
import TaxDetails from './taxDetails';
import AddBankDetails from './bankDetails';
import AddNomineeDetails from './addNomineeDetails';
import Verification from './verification';

/*import assets*/
import Logo from '../../assets/images/menu/logo.svg';
import BackArrow from '../../assets/images/onboard/left-arrow.svg';
import InfoFlag from '../../assets/images/new-design/infoFlag.svg';


//import service
import Config from '../../config/config';
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';
import ESign from './esign';

const KYCandDetails = (props) => {

    const [progress, setProgress] = useState(0);
    const [genderOptions, setGenderOptions] = useState(null);
    const [occupationOption, setOccupationOption] = useState(null);
    const [sourceOptions, setSourceOptions] = useState(null);
    const [maritalOptions, setMaritalOptions] = useState(null);
    const [proofOptions, setProofOptions] = useState(null);
    const [stateOption, setStateOption] = useState(null);
    const [countryOptions, setCountryOptions] = useState(null);
    const [userProgress, setUserProgress] = useState(null);
    const [step, setStep] = useState(1);
    const [KYCStatus, setKYCStatus] = useState(true);
    const [acOptions, setAcOptions] = useState(null);
    const [relationOptions, setRelationOptions] = useState(null);
    const [otp, setOtp] = useState(null);
    const [mountInvestModal, setMountInvestModal] = useState(false);
    const [assetDetail, setAssetDetail] = useState(null);
    const [haveMandate, setHaveMandate] = useState(null);
    const [totalSteps, setTotalSteps] = useState(null);
    let navigator = useNavigate();

    useEffect(() => {
        _getGeneralData();
        _getStates();
        _searchCountry();
    }, [])

    useEffect(() => {
        if (props.signupData) {
            _getOnboardProgress(props.signupData)
        }
    }, [props.signupData])

    useEffect(() => {
        if (props.signupData) {
            window.analytics.identify(props.signupData.user_id, {
                path: window.location.pathname,
            });
        }
    }, props.signupData)

    // API -Ger general data
    const _getGeneralData = () => {
        var tempGender = [];
        var tempOccupation = [];
        var tempSource = [];
        var tempProof = [];
        var tempAccount = [];
        var tempRelation = [];
        let url = Config.user + '/app/get-generaldata';
        APIGet(url).then((response) => {
            if (response?.status_code === 200) {
                response.data.genders.map((item, key) => {
                    tempGender.push({ value: item.id, label: item.display_name })
                })
                response.data.occupations.map((item, key) => {
                    tempOccupation.push({ value: item.id, label: item.display_name })
                })
                response.data.source_of_wealths.map((item, key) => {
                    tempSource.push({ value: item.id, label: item.display_name })
                })
                response.data.document_types.map((item, key) => {
                    tempProof.push({ value: item.id, label: item.display_name })
                })
                response.data.bank_account_types.map((item, key) => {
                    tempAccount.push({ value: item.id, label: item.display_name })
                })
                response.data.kyc_relationships.map((item, key) => {
                    tempRelation.push({ value: item.id, label: item.display_name })
                })

                setAcOptions(tempAccount);
                setRelationOptions(tempRelation);
                setGenderOptions(tempGender);
                setOccupationOption(tempOccupation);
                setSourceOptions(tempSource);
                setMaritalOptions(response.data.marital_statuses);
                setProofOptions(tempProof)

            } else {
            }
        });
    }

    // API - Get State data
    const _getStates = () => {
        var tempStates = [];
        let url = Config.user + "/app/get-states";
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                response.data.map((item, key) => {
                    tempStates.push({ value: item.id, label: item.name })
                })
            }
            setStateOption(tempStates);
        })
    }

    // get country options
    const _searchCountry = () => {
        var temp = [];
        let url = Config.user + '/auth/search-country';
        let data = JSON.stringify({
            query: ''
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                response.data.map((item, key) => {
                    temp.push({ value: item.id, label: item.name })
                })
                setCountryOptions(temp)
            } else {
            }
        })
    }

    // get onboard progress
    const _getOnboardProgress = (progrssData) => {
        // let url = Config.user + '/auth/signup-progress';
        // APIGet(url).then((response) => {
        //     if (response.status_code === 200) {
        setKYCStatus(progrssData.is_kyc_complaint);
        setOtp(progrssData.otp);
        if (progrssData.is_kyc_complaint === true) {
            setTotalSteps(5);
            setProgress(20)
        } else {
            setTotalSteps(7);
            setProgress(14.28);
        }
        _setCurrentProgress(progrssData.signup_status, progrssData.is_kyc_complaint);
        setUserProgress(progrssData);
        //     }
        // });
    }

    // render  user signup page and progress based on status 
    const _setCurrentProgress = (status, kyc_status) => {
        if (kyc_status === true) {
            switch (status) {
                case 'PERSONAL_DATA_ADDED': setStep(2); setProgress(40);
                    break;
                case 'ADDRESS_DATA_ADDED': setStep(3); setProgress(60);
                    break;
                case 'INCOME_DATA_ADDED': setStep(4); setProgress(80);
                    break;
                case 'BANKACCOUNT_ADDED': setStep(5); setProgress(100);
                    break;
                case 'NOMINEE_ADDED': setStep(5); setProgress(100);
                    break;
                case 'AMC_KYC_COMPLETED': setStep(5); setProgress(100);
                    break;
            }
        } else {
            switch (status) {
                case 'PERSONAL_DATA_ADDED': setStep(2); setProgress(14.28 * 2);
                    break;
                case 'ADDRESS_DATA_ADDED': setStep(3); setProgress(14.28 * 3);
                    break;
                case 'INCOME_DATA_ADDED': setStep(4); setProgress(14.28 * 4);
                    break;
                case 'BANKACCOUNT_ADDED': setStep(5); setProgress(14.28 * 5);
                    break;
                case 'NOMINEE_ADDED': setStep(6); setProgress(14.28 * 6);
                    break;
                case 'AMC_KYC_INPROGRESS': setStep(7); setProgress(14.28 * 7);
                    break;
                case 'AMC_KYC_REJECTED': setStep(1); setProgress(14.28);
                    break;
                case 'AMC_KYC_COMPLETED': setStep(7); setProgress(14.28 * 7);
                    break;
                case 'ESIGN_STARTED': setStep(7); setProgress(14.28 * 7);
                    break;
                case 'ESIGN_COMPLETED': setProgress(14.28 * 7); navigator('/portfolio');
                    break;
            }
        }

    }

    // back button navigation
    const _backNavigation = () => {
        if (KYCStatus === true) {
            if (step === 1 || (step === 5 && userProgress.signup_status === 'NOMINEE_ADDED')) {
                navigator('/onboard');
            }
            else {
                setStep(step - 1);
                setProgress(progress - 20);
            }
        }
        else {
            if (step === 1 || step === 7 || (step === 5 && userProgress.signup_status === 'NOMINEE_ADDED')) {
                navigator('/onboard');
            }
            else {
                setStep(step - 1);
                setProgress(progress - 14.28);
            }
        }
    }

    // update user progress with api response after saving step 
    const updateUserData = (temp, data) => {
        let tempContext = userProgress;
        tempContext[temp] = data;
        setUserProgress({ ...tempContext })
    }

    // analytics tracker for user kyc status 
    const _trackUserProgress = (step) => {
        window.analytics.track('KYC', {
            user_id: userProgress.user_id,
            kyc_status: KYCStatus,
            kyc_step: step
        });
    }

    const _success = () => {
        props.success()
    }

    return (
        <div className="e-onboard-step-section e-onboard-form">
            <div className="e-step-progress" style={{ width: `${progress}%` }}></div>
            {/* Title section */}
            <div className="row px-lg-5 px-4 mt-lg-3 mt-md-3 mt-0  pt-lg-4 pb-3">
                <div className="col-lg-10 col-md-10 col-sm-12 col-12 order-lg-1 gx-lg-4 gx-0 ps-lg-4 ps-0 order-md-1 order-sm-2 order-2">
                    <h4 className="e-step-title" id='e-section'>
                        Personal Details
                    </h4>
                    <div className='col-lg-10 col-md-12 col-sm-12 pe-lg-4 pe-0 '>
                        <div className='e-onboard-info-card p-3'>
                            <div className='row'>
                                <div className='col-11'>
                                    <h5>
                                        Why are you asking for my personal information?
                                    </h5>
                                    <p>
                                        It’s mandatory requirement from SEBI.
                                        All investors must provide their PAN details and do KYC to open an investment account
                                    </p>
                                </div>
                                <div className='col-1'>
                                    <img src={InfoFlag} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 col-12 order-lg-2 order-md-2 order-sm-1 order-1 gx-lg-5 gx-0 text-lg-end text-md-end">
                    <span className="e-step-indicator">STEP 3/4</span>
                </div>

                {/* Content */}

                <div className='col-12 order-3 ps-lg-4 ps-0 pt-lg-2 pt-md-2 pt-0 gx-lg-5 gx-0'>
                    {
                        step === 1 && (
                            <PersonalDetails
                                genderOptions={genderOptions}
                                countryOptions={countryOptions}
                                progressData={userProgress}
                                totalSteps={totalSteps}
                                KYCStatus={(status) => { // setting kyc status getting after verify pan number to state
                                    let data = userProgress;
                                    data.is_kyc_complaint = status;
                                    setUserProgress(data);
                                    setKYCStatus(status);
                                }}
                                updateUserData={(key, data) => {
                                    updateUserData(key, data);
                                }}
                                success={(status) => {
                                    const ele = document.getElementById('e-section');

                                    ele.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end",
                                        inline: "nearest"
                                    });
                                    let data = userProgress;
                                    data.signup_status = status;
                                    setUserProgress({ ...data });
                                    _trackUserProgress('Pan');
                                    if (KYCStatus === true) {
                                        setTotalSteps(5);
                                        setProgress(40);
                                        setStep(2);
                                    } else {
                                        setProgress(14.28 * 2);
                                        setStep(2);
                                    }

                                    LeadSquared._activityTracker('KYC Drop-off mail', 210);
                                }}
                            />
                        )
                    }

                    {/* Address details */}

                    {
                        step === 2 && (
                            <AddressDetail
                                progressData={userProgress}
                                stateOption={stateOption}
                                proofOptions={proofOptions}
                                totalSteps={totalSteps}
                                updateUserData={(key, data) => {
                                    updateUserData(key, data);
                                }}
                                success={(status) => {
                                    const ele = document.getElementById('e-section');

                                    ele.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end",
                                        inline: "nearest"
                                    });
                                    let data = userProgress;
                                    data.signup_status = status;
                                    setUserProgress({ ...data });
                                    _trackUserProgress('Address details');
                                    if (KYCStatus === true) {
                                        setProgress(60);
                                        setStep(3);
                                    } else {
                                        setProgress(14.28 * 3);
                                        setStep(3);
                                    }
                                    LeadSquared._activityTracker('KYC Drop-off mail', 210);
                                }}
                            />
                        )
                    }

                    {/* Tax details */}

                    {
                        step === 3 && (
                            <TaxDetails
                                progressData={userProgress}
                                maritalOptions={maritalOptions}
                                occupationOption={occupationOption}
                                sourceOption={sourceOptions}
                                totalSteps={totalSteps}
                                updateUserData={(key, data) => {
                                    updateUserData(key, data);
                                }}
                                success={(status) => {
                                    const ele = document.getElementById('e-section');

                                    ele.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end",
                                        inline: "nearest"
                                    });
                                    let data = userProgress;
                                    data.signup_status = status;
                                    setUserProgress({ ...data });
                                    setUserProgress(data);
                                    _trackUserProgress('Tax details');
                                    if (KYCStatus === true) {
                                        setProgress(80);
                                        setStep(4);
                                    } else {
                                        setProgress(14.28 * 4);
                                        setStep(4);
                                    }
                                    LeadSquared._activityTracker('KYC Drop-off mail', 210);
                                }}
                            />
                        )
                    }

                    {/* Bank Details */}

                    {
                        step === 4 && (
                            <AddBankDetails
                                progressData={userProgress}
                                acOptions={acOptions}
                                totalSteps={totalSteps}
                                updateUserData={(key, data) => {
                                    updateUserData(key, data);
                                }}
                                success={(status) => {
                                    const ele = document.getElementById('e-section');

                                    ele.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end",
                                        inline: "nearest"
                                    });
                                    let data = userProgress;
                                    data.signup_status = status;
                                    setUserProgress({ ...data });
                                    _trackUserProgress('Bank');
                                    if (KYCStatus === true) {
                                        setProgress(100);
                                        setStep(5);
                                    }
                                    else {
                                        setProgress(14.28 * 5);
                                        setStep(5);
                                    }
                                    LeadSquared._activityTracker('KYC Drop-off mail', 210);
                                }}
                            />
                        )
                    }

                    {/* Nominee details */}

                    {
                        step === 5 && (
                            <AddNomineeDetails
                                progressData={userProgress}
                                acOptions={acOptions}
                                relationOptions={relationOptions}
                                totalSteps={totalSteps}
                                updateUserData={(key, data) => {
                                    updateUserData(key, data);
                                }}
                                success={(otp, status) => {
                                    const ele = document.getElementById('e-section');

                                    ele.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end",
                                        inline: "nearest"
                                    });
                                    let data = userProgress;
                                    data.signup_status = status;
                                    setUserProgress({ ...data });
                                    _trackUserProgress('Nominee');
                                    if (userProgress.is_kyc_complaint === true) {
                                        _success();
                                    } else {
                                        setProgress(14.28 * 6);
                                        setOtp(otp);
                                        setStep(6);
                                    }
                                    LeadSquared._activityTracker('KYC Drop-off mail', 210);

                                }}
                            />
                        )
                    }

                    {/* In person verification */}

                    {step === 6 && (
                        <Verification
                            totalSteps={totalSteps}
                            otp={otp}
                            success={(status) => {
                                const ele = document.getElementById('e-section');

                                ele.scrollIntoView({
                                    behavior: "smooth",
                                    block: "end",
                                    inline: "nearest"
                                });
                                let data = userProgress;
                                data.signup_status = status;
                                setUserProgress({ ...data });
                                _trackUserProgress('Verification');
                                setProgress(100);
                                setStep(7);
                                LeadSquared._activityTracker('KYC Drop-off mail', 210);
                            }}
                        />
                    )}

                    {/* Esign */}

                    {step === 7 && (
                        <ESign
                            totalSteps={totalSteps}
                            success={(status) => {
                                const ele = document.getElementById('e-section');

                                ele.scrollIntoView({
                                    behavior: "smooth",
                                    block: "end",
                                    inline: "nearest"
                                });
                                let data = userProgress;
                                _trackUserProgress('Esign');
                                data.signup_status = status;
                                setUserProgress({ ...data });
                                _success();
                                LeadSquared._activityTracker('KYC Drop-off mail', 210);
                            }}
                        />
                    )}
                </div>

                {/*  Ends here */}
            </div>
            {
                mountInvestModal ?
                    <InvestNowModal
                        haveMandate={haveMandate}
                        location="portfolio"
                        success={() => { }}
                        reload={() => { }}
                        unmountModal={() => { setMountInvestModal(false) }}
                        updateContext={(data) => { window.location.href = "/overview" }}
                        assetsDetail={assetDetail}
                        editScore={false}
                    />
                    :
                    null
            }
        </div>
    )
}

export default KYCandDetails;