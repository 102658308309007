// import packages
import { useEffect, useState } from 'react';

// import component
import { RadioButton, Select } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta';
import { Toast } from '../../components/note';
import Input from '../../components/input/loginInput';

// import assets
import Arrow from '../../assets/images/onboard/submit.svg';
import LoaderImage from '../../assets/images/onboard/onboardLoader.gif';

// import service
import Config from '../../config/config';
import APIPost from "../../services/postApi";
import COLOUR from '../../config/color';

const TaxDetails = (props) => {
    const [taxCountry, setTaxCountry] = useState(false);
    const [marital, setMarital] = useState(null);
    const [occupation, setOccupation] = useState(null);
    const [source, setSource] = useState(null);
    const [income, setIncome] = useState(null);
    const [apiLoader, setApiLoader] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (props.progressData) {
            let tax_data = props.progressData.tax_data;
            if (tax_data) {
                setTaxCountry(tax_data.tax_payer_other_country);
                setMarital(tax_data.marital_status_id);
                setIncome(tax_data.annual_income);
                if (tax_data.occupation_id && tax_data.occupation_name) {
                    setOccupation({ value: tax_data.occupation_id, label: tax_data.occupation_name })
                }
                if (tax_data.wealth_source_id && tax_data.wealth_source_name) {
                    setSource({ value: tax_data?.wealth_source_id, label: tax_data.wealth_source_name });
                }
            }
        }
    }, [props.progressData])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 3000)
        }
    }, [error])
    /* clearing validation for radio */
    const clearFormValidationRadio = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            // ele.className = "radioButton_e_radiobutton__MQ5Ie";
        }
    }

    /* validation for radio */
    const showFormValidationErrorRadio = (field) => {
        var ele = document.getElementById(field);
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* validation for select */
    const showFormValidationSelect = (field) => {
        const ele = document.getElementById(field);
        ele.className = "e-select-error"
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* clearing validation for select */
    const clearFormValidationSelect = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.className = "e-select-wraper";
        }
    }

    /* validation to highlight fields */
    const showFormValidationError = (field) => {
        var ele = document.getElementById(field);
        ele.style.border = `solid 1.5px ${COLOUR.red}`
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* clearing validation fields */
    const clearFormValidationError = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.style.border = null;
        }
    }

    /* form validation */
    const _validateForm = () => {
        if (taxCountry === null || taxCountry === undefined) {
            setError("Please select you resident status");
            showFormValidationErrorRadio(1);
            showFormValidationErrorRadio(2);
            return false;
        }
        else if (marital === null || marital === undefined) {
            setError("Select marital status");
            for (let i = 0; i < props.maritalOptions.length; i++) {
                showFormValidationErrorRadio(props.maritalOptions[i].id);
            }
            return false;
        }
        else if (occupation?.value === null) {
            setError("Select occupation");
            showFormValidationSelect("occupation")
            return false;
        }
        else if (source?.value === null) {
            setError("Select source of wealth");
            const ele = document.getElementById("source");
            ele.className = "e-select-error"
            return false;
        }
        else if (income === "" || income === undefined) {
            setError("Add income");
            showFormValidationError("income")
            return false;
        }
        return true;
    }

    // sumbit tax details
    const _submitTaxDetails = () => {
        let status = _validateForm();
        if (status) {
            setApiLoader(true);
            let url = Config.user + '/auth/add-income';
            let data = JSON.stringify({
                "is_foreigner": taxCountry,
                "marital_status_id": marital,
                "occupation_id": occupation ? occupation.value : null,
                "wealth_source_id": source ? source?.value : null,
                "annual_income": parseInt(income)
            })
            APIPost(url, data).then((response) => {
                if (response.status_code === 200) {
                    props.updateUserData('tax_data', response.data.income_data);
                    props.success(response.data.signup_status);
                    setApiLoader(false);
                }
                else {
                    setApiLoader(false);
                    setError(response.message)
                }
            })
        }

    }
    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-12  e-mobile-confirm-wrap pb-5 mb-5">
            <div className="e-process-wrap position-relative">
                <h5 className="e-onboard-form-title">
                    Tax and source of wealth
                </h5>
                {/* Form */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="e-wraper">
                            <div className="row">
                                <div className="col-lg-12 mt-3">
                                    <label className="" style={{ fontSize: '13px' }}>
                                        Are you a tax-paying resident in any other country?
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <div className="e-radio-buttons">
                                        <RadioButton
                                            id={1}
                                            label="Yes"
                                            wrapperClass=" me-5"
                                            checked={taxCountry}
                                            onClick={(e) => {
                                                setTaxCountry(true);
                                                clearFormValidationRadio(1);
                                                clearFormValidationRadio(2);
                                            }}
                                            name="tax"
                                        />
                                        <RadioButton
                                            id={2}
                                            label="No"
                                            className="me-3"
                                            checked={taxCountry === false}
                                            onClick={(e) => {
                                                setTaxCountry(false);
                                                clearFormValidationRadio(2);
                                                clearFormValidationRadio(1);
                                            }}
                                            name="tax"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 pt-2">
                                    <label className="">
                                        Marital Status
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <div className="e-radio-buttons">
                                        {
                                            props.maritalOptions && props.maritalOptions.map((item, key) => {
                                                return (
                                                    <RadioButton
                                                        id={item.id}
                                                        label={item.display_name}
                                                        wrapperClass="e-tax-martital-status"
                                                        checked={item.id == marital}
                                                        onClick={(e) => {
                                                            setMarital(item.id);
                                                            for (let i = 0; i < props.maritalOptions.length; i++) {
                                                                clearFormValidationRadio(props.maritalOptions[i].id);
                                                            }

                                                        }}
                                                        name={item.type}
                                                    />
                                                )

                                            })
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6 pt-3">
                                    <label className="mb-1">
                                        Occupation
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Select
                                        id="occupation"
                                        options={props.occupationOption}
                                        value={occupation}
                                        placeholder="Select"
                                        className="e-select-sort"
                                        selectchange={(temp) => {
                                            setOccupation(temp);
                                            clearFormValidationSelect("occupation");
                                        }}
                                    />
                                </div>
                                <div className="col-lg-6 pt-3">
                                    <label className="mb-1">
                                        Source of Wealth
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Select
                                        id="source"
                                        options={props.sourceOption}
                                        value={source}
                                        placeholder="Select"
                                        className="e-select-sort"
                                        selectchange={(temp) => {
                                            setSource(temp);
                                            clearFormValidationSelect("source");
                                        }}
                                    />
                                </div>
                                <div className="col-lg-6 pt-lg-4 pt-md-4 pt-sm-3 pt-3 mt-lg-2 mt-md-2 mt-sm-0 mt-0 position-relative">
                                    <label className="mb-1">
                                        Annual income (Rs. 10L  = 1000000)
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Input
                                        id="income"
                                        label="Annual income"
                                        value={income !== null ? parseInt(income) : ''}
                                        valueChange={(value) => {
                                            setIncome(value)
                                        }}
                                        className="e-tax-input"
                                        type="number"
                                        required={true}
                                    />
                                    <span className='e-ruppe'>₹</span>
                                </div>
                                <div className="col-lg-12 mb-3 mt-lg-5 mt-md-5 mt-sm-4 mt-4">
                                    {
                                        window.innerWidth > 575 ? (
                                            <>
                                                {
                                                    marital && occupation && source && income ?
                                                        (
                                                            <PrimaryBtn
                                                                name="Next"
                                                                image={Arrow}
                                                                right={!apiLoader && true}
                                                                iconClass='ms-2'
                                                                className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                                handleClick={() => {
                                                                    _submitTaxDetails()
                                                                }}
                                                            />
                                                        ) :
                                                        (
                                                            <PrimaryBtn
                                                                name="Next"
                                                                image={Arrow}
                                                                right={!apiLoader && true}
                                                                disabled={true}
                                                                iconClass='ms-2'
                                                                className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                            />
                                                        )
                                                }
                                            </>

                                        ) : (
                                            <>
                                                {
                                                    marital && occupation && source && income ? (
                                                        <PrimaryBtn
                                                            name="Next"
                                                            image={Arrow}
                                                            right={!apiLoader && true}
                                                            iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                            className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                            handleClick={() => {
                                                                _submitTaxDetails()
                                                            }}
                                                        />
                                                    ) : (
                                                        <PrimaryBtn
                                                            name="Next"
                                                            image={Arrow}
                                                            right={!apiLoader && true}
                                                            iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                            className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                            disabled={true}
                                                        />
                                                    )
                                                }
                                            </>

                                        )
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

export default TaxDetails;