/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { TextArea } from '../input/index';
import { SecondaryBtn, PrimaryBtn } from '../cta/index';


/*import styles*/
import styles from '../../styles/components/edit.module.scss';

import Close from '../../assets/images/new-design/modalClose.svg';

/* import assets */
import Delete from '../../assets/images/modal/delete.svg'

// import services
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { Toast } from '../note';

export default function DeleteScore(props) {

    const [name, setName] = useState();
    const [id, setId] = useState();
    const [loader, setLoader] = useState(false);
    const [toast, setToast] = useState(false)

    useEffect(()=>{
        if(props.data ){
            setName(props.data.nominee_name);
            setId(props.data.nominee_id);
        }
    },[props.data])

    const deleteNominee = () =>{
        setLoader(true);
        console.log('id', id)
        let url = devconfig.user+'/profile/delete-nominee';
        let data = JSON.stringify({
            "nominee_id" : id
        })
        APIPost(url, data).then((response)=>{
            if(response.status_code === 200 ){
                setLoader(false);
                $('#deleteConfirm').trigger('click');
                props.success();
            }
            else{
                setLoader(false);
                setToast(response.message);
            }
        })
        setTimeout(()=>{
            setToast(null);
        }, 4000)
    }

  return (
    <div className={`${styles.e_edit_modal} modal fade`} id="deleteConfirm" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className={`modal-content ${styles.e_modal_content}`}>
          <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
            <h5 className='mt-1'>Nominee Delete</h5>
            <img
              src={Close}
              className={`${styles.e_modal_close}`}
              data-bs-dismiss="modal"
            />
          </div>
          <div className="container px-4">
            <div className="row pt-3 ">
              {/* <div className={`${styles.e_icon} col-lg-1 col-2 pe-0 gx-0 `}>
                <img src={Delete} alt="" />
              </div> */}
              <div className="col-lg-12 col-12 ps-0 gx-0 ps-3 mt-2">
                <span className={`${styles.e_delete_text} `}>Do you really want to delete '<b>{name}</b>' from your nominee list?</span>
              </div>
            </div>
            <div className="row pt-4 mt-2 pb-2">
              <div className="col-lg-6 col-md-6 col-sm-12">
                  <SecondaryBtn name="Cancel" className={`w-100`} cancel="modal" />
                </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                  <PrimaryBtn name={loader ? "Deleting " : "Yes, delete" }className={ loader ? `w-100 e-btn-loader` : `w-100`} handleClick={()=> deleteNominee()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {
          toast ?
            <Toast data={toast} type="fail"/>
        :
            null
      }
    </div>
  )
}
