
// Package import
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

// Components import 
import { PrimaryBtn, PrimaryLink, SecondaryBtn } from '../../components/cta';
import { PieChart } from '../../components/chart';
import { Loader } from '../../components/loader';
import { AssetList, SuccessPayment, UpdateAssets } from '../../components/modal';
import CustomToolTip from '../../components/tooltip/customeTooltip';
import ProgressBar from '../../components/loader/progressLoader';

// Assets import 
import Stocks from '../../assets/images/new-design/I-stock.svg';
import Funds from '../../assets/images/new-design/mf.svg';
import Gold from '../../assets/images/new-design/Gold.svg';
import RealEstate from '../../assets/images/new-design/RealEstate.svg';
import Deposit from '../../assets/images/new-design/Fd.svg';
import Dollar from '../../assets/images/new-design/us-stocks.svg';
import Gain from '../../assets/images/new-design/gain.svg';
import PlusIcon from '../../assets/images/new-design/plus-circle.svg';
import PlusIconBlack from '../../assets/images/new-design/plus-circle-black.svg';
import Crypto from '../../assets/images/new-design/Crypto.svg';
import Pen from '../../assets/images/new-design/pen.svg';
import Refresh from '../../assets/images/new-design/refresh.svg';
import List from '../../assets/images/new-design/list.svg'
import Grid from '../../assets/images/new-design/grid.svg'
import ListActive from '../../assets/images/new-design/list-active.svg'
import GridActive from '../../assets/images/new-design/grid-active.svg'
import Calendar from '../../assets/images/new-design/calendar.svg'
import Image from '../../assets/images/new-design/image.svg'


// import cofig
import Config from '../../config/config';
import APIGet from '../../services/getApi';
import GetURLVar from '../../services/getUrl';
import _getCurrentValue from '../../services/getCurrentValue';

import { UserDataContext } from '../../context/userData';
import { IconStyleToolTip } from '../../components/tooltip';
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import Nodata from '../../components/note/nodata-placeholder';

const images = {
    "mutual fund": Funds,
    "stock": Stocks,
    "real estate": RealEstate,
    "gold": Gold,
    "fixed deposit": Deposit,
}

const fundNames = [
    'Indian stocks',
    'mutual funds',
    'fixed deposit',
    'gold',
    'real estate',
    'US stocks'
]

const colors = [
    "#1329E6",
    "#6A79FF",
    "#2F3DB9",
    "#644DEC",
    "#AF93F6",
    "#C6D9FF",
    "#72BBFF",
    "#127CC9"
]

const PortfolioTracker = (props) => {
    const [apiLoader, setApiLoader] = useState(true);
    const [openAssetList, setOpenAssetList] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [dashboardData, setDashboardData] = useState(null);
    const [investedAsset, setInvestedAsset] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeUser, setActiveUser] = useState(true);
    const [userData, setUserData] = useContext(UserDataContext);
    const [chartWidth, setChartWidth] = useState('330');
    const [chartHeight, setChartHeight] = useState('250');
    const [selectedAssetType, setSelectedAssetType] = useState(null);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [openAmountTooltip, setOpenAmountTooltip] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [asset, setAsset] = useState(null);
    const [syncing, setSyncing] = useState(false);
    const [toast, setToast] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [grid, setGrid] = useState(false);
    const [assetAdded, setAssetAdded] = useState(false)
    const [assetsList, setAssetsList] = useState([]);
    const [assetLength, setAssetLength] = useState([]);
    const [newsData, setNewsData] = useState([]);
    const [downloadLoader, setDownloadLoader] = useState(false)

    const navigator = useNavigate();

    useEffect(() => {
        setActiveUser(props.active)
    }, [props.active])

    useEffect(() => {
        if (props.profile) {
            setProfileData(props.profile)
        }
    }, [props.profile])

    useEffect(() => {
        GetURLVar().then((query) => {
            if (query.asset_type !== "null" && query.asset_type) {
                setOpenAssetList(true);
            }
        })
    }, [])

    useEffect(() => {
        _getDashBoard();
    }, [])

    const _identifierTrack = (data) => {
        window.analytics.identify(userData?.user_id, {
            email: data?.email,
            name: `${data?.first_name} ${data?.last_name}`,
            path: window.location.pathname
        });
    }

    const _getLastUpdateValue = () => {
        const todaysDate = new Date()
        const fomatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }
        const formattedDate = todaysDate.toLocaleDateString('en-us', fomatOptions)
        return formattedDate
    }

    useEffect(() => {
        if (profileData) {
            _identifierTrack(profileData);
        }
    }, [profileData])

    const _eventTracker = () => {
        window.analytics.track('Portfolio tracker configured', {
            user_id: userData?.user_id,
            stocks: false,
            MF: false,
            Gold: false,
            RealEstate: false,
            FD: false,
            Bond: false,
            crypto: false
        });
    }

    const _updatePortfolioStatus = () => {
        window.analytics.identify(userData?.user_id, {
            'Portfolio tracker': true,
        });
    }

    // listener for toggle asset list modal
    useEffect(() => {
        if (openAssetList) {
            const modal = new Modal(document.getElementById("asset-list"), {})
            modal.show()
        }
    }, [openAssetList])

    useEffect(() => {
        if (updateModal) {
            const modal = new Modal(document.getElementById("update-asset"), {})
            modal.show()
        }
    }, [updateModal])

    useEffect(() => {
        if (successModal) {
            const modal = new Modal(document.getElementById("paymentSucess"), {})
            modal.show()
        }
    }, [successModal])

    const _getDashBoard = () => {
        setApiLoader(true);
        let url = Config.portfolio + '/assets/assets/dashboard';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setDashboardData(response.data);
                setApiLoader(false);
                _getAllAsstes();
                _getNewsList();
            }
            else {
                setDashboardData(null);
                setApiLoader(false);
            }
        })
    }

    const _getAllAsstes = () => {
        let url = Config.portfolio + '/assets/assets/list';
        let data = JSON.stringify({
            asset_type_id: null
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setAssetsList(response.data)
                let tempAssetLength = []
                response.data.map((item) => {
                    return (
                        tempAssetLength.push(item.data.length)
                    )
                })
                setAssetLength(tempAssetLength)
            }
            else {
                setAssetsList([]);
            }
        })
    }

    const _getNewsList = () => {
        let url = Config.portfolio + '/assets/stocks/news';
        let data = JSON.stringify({
            page_num: 1
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setNewsData(response.data)

            }
            else {
                setNewsData([]);
            }
        })
    }

    useEffect(() => {
        if (window.innerWidth >= 1350) {
            setChartWidth('250');
            setChartHeight('250')
        }
        if (window.innerWidth <= 1350 && window.innerWidth > 1290) {
            setChartWidth('230');
            setChartHeight('230')
            console.log('med', window.innerWidth)
        }
        if (window.innerWidth < 1290 && window.innerWidth > 990) {
            setChartWidth('190');
            setChartHeight('190')
        }
        if (window.innerWidth < 990 && window.innerWidth > 756) {
            setChartWidth('190');
            setChartHeight('190')
        }
        if (window.innerWidth < 756) {
            setChartHeight('250');
            setChartWidth('250')
        }
    }, [])

    const _importData = () => {
        setSyncing(true);
        let url = Config.portfolio + '/assets/stocks/import-from-api';
        let payload = JSON.stringify({
            access_token: sessionStorage.getItem('kite_access_token'),
            api: 'ZERODHA'
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setSyncing(false);
                _getDashBoard()
            }
            else {
                setSyncing(false);
                setToast(response.message);
            }
        })
    }

    const _downloadAsstes = () => {
        setDownloadLoader(true)
        const url = Config.portfolio + "/assets/assets/download";

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false)
            } else {
                setDownloadLoader(false)
            }
        })
    }

    return (
        <>
            {
                apiLoader ? (
                    // <Loader height="250px" />
                    <ProgressBar loader={apiLoader} />
                )

                    :

                    (
                        <>

                            <div className="e-portfolio-container px-lg-5 px-3 pb-5 mb-5">
                                <div className='row '>

                                    {/* Heading */}
                                    <div className="col-12  mt-lg-3 mt-md-3 mt-sm-0 mt-0  pt-0">
                                        <h5 className="e-dashboard-greeting mt-3" >
                                            Portfolio Tracker
                                        </h5>
                                        <p className="e-dashboard-sub-title">
                                            A bird’s eye view of all your assets
                                        </p>
                                    </div>

                                    <div className='col-lg-5 col-md-6 col-sm-12 mt-lg-4 mt-md-4 mt-sm-0 mt-0'>
                                        <div className='e-card e-portfolio-stat-card p-4' >
                                            <div className='row  pt-2 pb-2 ps-2'>
                                                <div className='col-12'>
                                                    <h5 className='e-card-title'>
                                                        Portfolio stats
                                                    </h5>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 mt-2 pe-0'>
                                                    <label>Net worth</label>
                                                    {
                                                        dashboardData.allocations.asset_types.length ?
                                                            <p
                                                                className='e-amount position-relative'
                                                                onMouseEnter={() => {
                                                                    setOpenTooltip(true)
                                                                }}
                                                                onMouseLeave={() => {
                                                                    // $(`#e-portfolio-value`).removeClass('e-hover-popup');
                                                                    setOpenTooltip(false)
                                                                }}
                                                            >
                                                                <span
                                                                    className='e-dashboard-rupee position-relative'
                                                                    style={{
                                                                        fontSize: '18px',
                                                                        top: '-6px',
                                                                        fontFamily: 'Roboto'
                                                                    }}
                                                                >₹ </span>
                                                                {_getCurrentValue(dashboardData.statistics.net_worth)}
                                                                {
                                                                    openTooltip && (
                                                                        <CustomToolTip top="0px" symbol={true} data={Number((dashboardData.statistics.net_worth).toFixed(2)).toLocaleString('en-IN')} />
                                                                    )
                                                                }
                                                            </p>

                                                            :
                                                            <hr className='e-no-data' style={{ width: '60px' }} />
                                                    }

                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                    <label>Amount invested</label>
                                                    {
                                                        dashboardData.allocations.asset_types.length ?
                                                            <p className='e-assets position-relative'
                                                                onMouseEnter={() => {
                                                                    setOpenAmountTooltip(true)
                                                                }}
                                                                onMouseLeave={() => {
                                                                    // $(`#e-portfolio-value`).removeClass('e-hover-popup');
                                                                    setOpenAmountTooltip(false)
                                                                }}
                                                            >
                                                                <span
                                                                    className='e-rupee-portfolio position-relative'

                                                                >₹ </span>
                                                                {_getCurrentValue(dashboardData.statistics.holdings)}
                                                                {
                                                                    openAmountTooltip && (
                                                                        <CustomToolTip top="0px" symbol={true} data={Number((dashboardData.statistics.holdings).toFixed(2)).toLocaleString('en-IN')} />
                                                                    )
                                                                }
                                                            </p>
                                                            :
                                                            <hr className='e-no-data' />
                                                    }

                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                    <label>Returns</label>
                                                    {
                                                        dashboardData.allocations.asset_types.length ?
                                                            <p className='e-assets'>
                                                                <span
                                                                    className='e-rupee-portfolio position-relative'

                                                                >₹ </span>
                                                                {
                                                                    _getCurrentValue(dashboardData.statistics.returns)
                                                                }</p>
                                                            :
                                                            <hr className='e-no-data' />
                                                    }

                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                                                    <label>Return %</label>
                                                    {
                                                        dashboardData.allocations.asset_types.length ?
                                                            <p className='e-assets'>

                                                                {dashboardData.statistics.returns_percentage}
                                                                <span className='e-gain-percent'>
                                                                    {
                                                                        dashboardData.statistics.returns_percentage < 0 ?
                                                                            <div className='e-lose-arrow'></div>
                                                                            :
                                                                            parseFloat(dashboardData.statistics.returns_percentage) > 0 ?
                                                                                <img src={Gain} className="ms-2" />
                                                                                :
                                                                                null

                                                                    }
                                                                </span>
                                                            </p>
                                                            :
                                                            <hr className='e-no-data' />
                                                    }

                                                </div>
                                                {
                                                    dashboardData && dashboardData.kite_user ?
                                                        <div className='col-lg-12 position-relative e-refresh'>
                                                            <p className='mb-0 e-red-color'>Last updated on {_getLastUpdateValue()}
                                                                <img
                                                                    src={Refresh}
                                                                    style={{ cursor: "pointer" }}
                                                                    className={syncing ? "e-rotate e-refresh-btn ms-3" : "e-refresh-btn ps-3"}
                                                                    onClick={(e) => {
                                                                        if (sessionStorage.getItem("kite_access_token")) {
                                                                            _importData()
                                                                        } else {
                                                                            window.location.href = Config.KiteLoginUrl;
                                                                        }
                                                                    }}
                                                                    onMouseEnter={() => {
                                                                        setRefresh(true)
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        setRefresh(false)
                                                                    }}
                                                                />
                                                            </p>
                                                            {
                                                                refresh ?
                                                                    <IconStyleToolTip
                                                                        className="e-refresh-tooltip"
                                                                        content="Click to refresh your assets"
                                                                    />
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div className='col-12  mt-3'>
                                                    <PrimaryBtn
                                                        name={
                                                            dashboardData.allocations.asset_types.length ?
                                                                "Add more assets"
                                                                :
                                                                "Add Assets"
                                                        }
                                                        // className="me-3 e-card-btn"
                                                        // name="Add more "
                                                        className="me-4 e-add-more"
                                                        left={true}
                                                        image={PlusIcon}
                                                        iconClass="me-2"
                                                        handleClick={() => {
                                                            _eventTracker();
                                                            _updatePortfolioStatus();
                                                            setOpenAssetList(true);
                                                        }} />
                                                    {
                                                        dashboardData.allocations.asset_types.length ? (
                                                            <SecondaryBtn
                                                                name="Edit"
                                                                className="e-edit-btn"
                                                                left={true}
                                                                image={Pen}
                                                                iconClass="me-2"
                                                                handleClick={() => setUpdateModal(true)}
                                                            />
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    {
                                                        dashboardData.allocations.asset_types.length === 0 && (
                                                            <span className='e-empty-text mt-2'>
                                                                Add your assets to start tracking with Mintd
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-7 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-3 mb-3'>
                                        <div className='e-card e-graph-card position-relative mt-lg-4 mt-md-4 mt-sm-2 mt-2 p-4'
                                        >
                                            {
                                                dashboardData.allocations.data.length > 0 ? (
                                                    <div className='row'>
                                                        <div className='col-12 mt-2'>
                                                            <h5 className='e-title'>
                                                                Your portfolio allocation
                                                            </h5>
                                                        </div>
                                                        <div className='col-lg-5 col-md-5 col-sm-12 text-center'>
                                                            {
                                                                dashboardData.allocations && (
                                                                    <PieChart
                                                                        data={dashboardData.allocations.data}
                                                                        colors={dashboardData.allocations.colors}
                                                                        labels={dashboardData.allocations.asset_types}
                                                                        width="250"
                                                                        height="250"
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                        <div className='col-lg-7 col-md-7 col-sm-12 mt-3 e-portfolio-legend'>
                                                            {
                                                                dashboardData && dashboardData.allocations &&
                                                                dashboardData.allocations.data.map((item, key) => {
                                                                    return (
                                                                        <div
                                                                            className='e-graph-legends '
                                                                            key={key}
                                                                            onClick={() => {
                                                                                let id = dashboardData.assets.filter((el) => {
                                                                                    if (el.asset_type_name == dashboardData.allocations.asset_types[key]) {
                                                                                        return el.asset_type_id
                                                                                    }
                                                                                })
                                                                                console.log('id', id);
                                                                                navigator('/allocation?id=' + id[0].asset_type_id);
                                                                            }}
                                                                        >
                                                                            <div className='e-graph-indicator me-1' style={{ background: dashboardData.allocations.colors[key] }}></div>
                                                                            <span className='e-legend-value mt-1'>{`${dashboardData.allocations.asset_types[key]} : ${item}%`}</span>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                ) :
                                                    (
                                                        <div className='row'>
                                                            <div className='col-12 mt-2'>
                                                                <h5 className='e-title'>
                                                                    Your portfolio allocation
                                                                </h5>
                                                            </div>
                                                            <div className='col-lg-5 col-md-5 col-sm-12 text-center'>
                                                                <PieChart
                                                                    data={[10, 10, 40, 25, 15]}
                                                                    colors={colors}
                                                                    labels={[]}
                                                                    width={250}
                                                                    height={250}
                                                                    removeTooltip={true}
                                                                />
                                                            </div>
                                                            <div className='col-lg-7 col-md-12 col-sm-12 mt-3 e-portfolio-legend'>
                                                                {
                                                                    fundNames.map((item, key) => {
                                                                        return (
                                                                            <div
                                                                                className='e-graph-legends '
                                                                                key={key}
                                                                                style={{ marginBottom: '6px' }}
                                                                            >
                                                                                <div className='e-graph-indicator me-1' style={{ background: colors[key] }}></div>
                                                                                <span className='e-legend-value mt-1'>{`${item} : `} </span>
                                                                                <hr
                                                                                    className='e-no-data'
                                                                                    style={{ display: 'inline-block', verticalAlign: 'middle', width: '15px' }}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                        </div>

                                    </div>

                                    <div className='row pe-lg-3 pe-md-3 pe-0 mt-3'>
                                        <div className='col-lg-6 col-4 e-potfolio-container '>
                                            <h5 className='e-card-title'>
                                                {
                                                    !grid ?
                                                        `${dashboardData.assets.length} assets`
                                                        :
                                                        assetLength.length > 0 ?
                                                            assetLength.reduce((intialValue, finalValue) => intialValue + finalValue, 0) > 1 ?
                                                                `${assetLength.reduce((intialValue, finalValue) => intialValue + finalValue, 0)} total assets`
                                                                :
                                                                `${assetLength.reduce((intialValue, finalValue) => intialValue + finalValue, 0)} total asset`
                                                            :
                                                            null
                                                }
                                            </h5>
                                        </div>
                                        <div className='col-lg-6 col-8 text-end'>
                                            {
                                                dashboardData.assets.length && (
                                                    <>
                                                        {
                                                            !grid ?
                                                                null
                                                                :
                                                                <PrimaryLink
                                                                    name="Download"
                                                                    fontSize="17px"
                                                                    className={downloadLoader ? "e-btn-loader pe-2" : "pe-2"}
                                                                    handleClick={() => {
                                                                        _downloadAsstes()
                                                                    }} />
                                                        }
                                                        <span>
                                                            View options
                                                            <img
                                                                src={!grid ? GridActive : Grid}
                                                                alt=""
                                                                className={grid ? 'e-active px-3' : 'e-inactive px-3'}
                                                                onClick={() => {
                                                                    setGrid(false)
                                                                }} />
                                                            <img
                                                                src={grid ? List : ListActive}
                                                                className={grid ? 'e-active' : 'e-inactive'}
                                                                alt=""
                                                                onClick={() => {
                                                                    setGrid(true)
                                                                }} />
                                                        </span>
                                                    </>
                                                )}
                                        </div>

                                    </div>
                                    {
                                        !grid ?
                                            <>

                                                {
                                                    dashboardData.assets.length &&
                                                    dashboardData.assets.map((item, key) => {
                                                        if (item.asset_type_id === 20 || item.asset_type_id === 21) {
                                                            return
                                                        }
                                                        else {
                                                            return (
                                                                <div className='col-lg-3 col-md-3 col-sm-12 mt-4 e-asset-card-wrapper position-relative e-refresh' key={key}  >
                                                                    <div className='e-asset-card e-card p-4'
                                                                        onClick={() => {
                                                                            navigator('/allocation?id=' + item.asset_type_id)
                                                                        }}
                                                                    >
                                                                        {/* {
                                                                (item.asset_type_id === 1 || item.asset_type_id === 2) && dashboardData && dashboardData.kite_user && (
                                                                    <img
                                                                        src={Refresh}
                                                                        className="e-refresh-btn"
                                                                        onClick={(e) => {
                                                                            window.location.href = Config.KiteLoginUrl;
                                                                        }}
                                                                        onMouseEnter={() => {
                                                                            setAsset(item.asset_type_id)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setAsset(null)
                                                                        }}
                                                                    />
                                                                )
                                                            } */}
                                                                        <div className=''>
                                                                            <img src={images[item.asset_type_name]} />
                                                                        </div>
                                                                        <div className='col-12 mt-3'>
                                                                            <span className='e-capatalize' >
                                                                                {
                                                                                    item.asset_type_id === 1 || item.asset_type_id === 2 || item.asset_type_id === 5 ?
                                                                                        `${item.asset_type_name}s`
                                                                                        :
                                                                                        item.asset_type_name
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className='d-flex mt-2 '>
                                                                            {
                                                                                item.total_value != 0 ? (
                                                                                    <>
                                                                                        <h6>
                                                                                            <span
                                                                                                className='position-relative e-rupee-portfolio'

                                                                                            >₹ </span>
                                                                                            {_getCurrentValue(item.total_value)}
                                                                                        </h6>
                                                                                        <img
                                                                                            src={Gain}
                                                                                            className="e-asset-card-arrow mt-2 ms-1"
                                                                                        />
                                                                                    </>
                                                                                ) : (
                                                                                    <img
                                                                                        src={PlusIconBlack}
                                                                                        className="mt-1"
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            setSelectedAssetType({ value: item.asset_type_id, label: item.asset_type_name });
                                                                                            setOpenAssetList(true);
                                                                                            _eventTracker();
                                                                                        }}
                                                                                    />
                                                                                )

                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    {/* {
                                                            asset === item.asset_type_id ?
                                                                <IconStyleToolTip
                                                                    className="e-assets-tooltip"
                                                                    content={`<div>Click to refresh your assets <p className='e-red-color'>Last updated on ${_getLastUpdateValue()}</p></div>`}
                                                                />
                                                                :
                                                                null
                                                        } */}
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                                <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-4 e-asset-card-wrapper'>
                                                    <div className='e-asset-card e-no-pointer e-card p-4'

                                                    >
                                                        <div className='d-flex'>
                                                            <img src={Dollar} />
                                                        </div>
                                                        <div className='col-12 mt-3'>
                                                            <span className="e-capatalize">
                                                                US Stocks
                                                            </span>
                                                        </div>
                                                        <div className='col-12 mt-2'>
                                                            <img
                                                                src={PlusIconBlack}
                                                                className="mt-1"
                                                            />
                                                        </div>
                                                        <div className='e-coming-soon '>
                                                            Coming soon
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-2 col-md-3 col-sm-6 col-12 mt-4 e-asset-card-wrapper' >
                                                    <div className='e-asset-card e-no-pointer e-card p-4'>
                                                        <div className='d-flex'>
                                                            <img src={Crypto} />
                                                        </div>
                                                        <div className='col-12 mt-3'>
                                                            <span className='e-capatalize' >
                                                                Crypto
                                                            </span>
                                                        </div>
                                                        <div className='col-12 mt-2'>
                                                            <img
                                                                src={PlusIconBlack}
                                                                className="mt-1"
                                                            />
                                                        </div>
                                                        <div className='e-coming-soon '>
                                                            Coming soon
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            assetsList.length > 0 ?
                                                <div className='col-lg-12 col-md-12 col-12 e-assets-table-card p-4 mt-4'>
                                                    <div className="e-table-wrapper">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-4 pt-1 px-0">
                                                                <h6 className='ms-4 '>Asset Name</h6>
                                                            </div>
                                                            <div className="col-lg-3 col-3 pt-1">
                                                                <h6 >
                                                                    Invested
                                                                </h6>
                                                            </div>

                                                            <div className="col-lg-3 col-3 pt-1 pe-0 ">
                                                                <h6>Current value</h6>
                                                            </div>
                                                            <div className="col-lg-2 col-2 pt-1 pe-0 ">
                                                                <h6>P&L</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="e-table-content p-0">
                                                        {
                                                            assetsList.map(function (assetItem) {
                                                                return (
                                                                    <>
                                                                        <div className='e-table-subtitle  text-center py-2 mt-3'>
                                                                            <h6 className='e-capatalize' style={{ cursor: "pointer" }} onClick={() => {
                                                                                navigator('/allocation?id=' + assetItem.asset_type_id)
                                                                            }}>
                                                                                {assetItem.asset_type}
                                                                            </h6>
                                                                        </div>
                                                                        {
                                                                            assetItem.data.map((item, index) => {
                                                                                // let performance = assets.invested_data.
                                                                                return (
                                                                                    <div className="row e-table-row p-3 mt-3" >
                                                                                        <div className="col-lg-4 col-4 mt-1 pe-0 ps-1 d-flex align-items-center pe-3">
                                                                                            <p>{assetItem.asset_type_id === 1 ? item.mf_name : assetItem.asset_type_id === 2 ? item.ticker : assetItem.asset_type_id === 3 ? item.property_type_name : assetItem.asset_type_id === 4 ? `${item.gold_in_grams} Grams` : item.organization_name}</p>
                                                                                        </div>
                                                                                        <div className="col-lg-3 col-3 e-scan-loader mt-1 pe-0 d-flex align-items-center">
                                                                                            <p className="e-value pe-3 position-relative">
                                                                                                <span
                                                                                                    className=' e-rupee-portfolio'

                                                                                                >₹ </span>
                                                                                                {_getCurrentValue(item.invested_value)}</p>
                                                                                        </div>
                                                                                        <div className="col-lg-3 col-3 e-scan-loader mt-1 pe-0 d-flex align-items-center">
                                                                                            <p className="e-value pe-3 position-relative">
                                                                                                <span
                                                                                                    className='e-rupee-portfolio'

                                                                                                >₹ </span>
                                                                                                {_getCurrentValue(item.current_value)}</p>
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-2  mt-1 pe-0 e-filter-return d-flex align-items-center">
                                                                                            <p style={item.return_percentage > 0 ? { color: "#0fd99c" } : item.return_percentage < 0 ? { color: "#ff0000" } : null}>{(item.return_percentage) > 0 ? `+${item.return_percentage}` : item.return_percentage}%</p>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </>

                                                                )

                                                            })

                                                        }
                                                        <div className='e-table-subtitle-coming  text-center py-2 mt-3'>
                                                            <h6>
                                                                {`US Stocks (coming soon)`}
                                                            </h6>
                                                        </div>
                                                        <div className='e-table-subtitle-coming  text-center py-2 mt-3'>
                                                            <h6>
                                                                {`Crypto (coming soon)`}
                                                            </h6>
                                                        </div>


                                                    </div>
                                                </div>
                                                :
                                                <Nodata note="There data found" />
                                    }
                                    <div className='row mt-md-5 mt-lg -5 mt-3 pt-md-4 pt-0'>
                                        <h5 className='e-card-title mb-4'>
                                            News feed
                                        </h5>
                                        {
                                            newsData.map((item, key) => {
                                                return (
                                                    <div className='col-lg-6 col-md-6 col-12' key={key} onClick={() => {
                                                        window.open(item.url)
                                                    }}>
                                                        <div className='e-news-card-wrapper p-3'>
                                                            <h6>{item.title}</h6>
                                                            <p>{item.content}</p>
                                                            <div className='d-flex mt-auto e-bottom'>

                                                                <span className='me-3 e-date pt-2' style={{ fontWeight: "600", fontSize: "14px" }}>{item.source}</span>

                                                                <span className='e-date pt-2'><img src={Calendar} alt="" className='pe-2' />{item.news_date}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
            }
            {
                openAssetList && (
                    <AssetList
                        modalClose={() => {
                            setOpenAssetList(false);
                            // setSuccessModal(true)
                        }}
                        smallCase={(status) => {
                            setAssetAdded(status)
                        }}
                        selected={selectedAssetType}
                        success={(asset) => { setInvestedAsset(asset); setSuccessModal(true) }}
                    />
                )
            }
            {
                successModal && (
                    <SuccessPayment
                        editScore={false}
                        assetAdded={assetAdded}
                        heading={"Add assets"}//type
                        status={true}//status
                        subTitle={`Your ${investedAsset.label} had been added to Portfolio Tracker`}
                        content={`You have added your ${investedAsset.label} investment. We will track your investment
                    from now on.`}
                        from="portfolio-tracker"
                        close={() => {
                            setSuccessModal(false);
                            _getDashBoard();
                            // if (!status) {
                            // 	window.location.href = "/orders"
                            // }
                        }}
                    />
                )

            }
            {
                updateModal && (
                    <UpdateAssets
                        type={1}
                        modalClose={() => {
                            setUpdateModal(false);
                            // setSuccessModal(true)
                        }}
                        success={(asset) => { setInvestedAsset(asset); setSuccessModal(true) }}
                    />
                )
            }
        </>
    )
}

export default PortfolioTracker; 