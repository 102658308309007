/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';

/*import componets*/
import { PhoneInput, TextArea } from '../input/index';
import { SecondaryBtn, PrimaryBtn } from '../cta/index';


/*import styles*/
import styles from '../../styles/components/methodology.module.scss';


/* import assets */
import PickingAsset from '../../assets/images/modal/picking-asset.svg';
import Subclass from '../../assets/images/modal/sub-class.svg';
import SubclassTwo from '../../assets/images/modal/sub-class2.svg';
import Fund from '../../assets/images/modal/fund.svg';
import Score from '../../assets/images/modal/score.svg';
import Close from '../../assets/images/modal/close.svg';

export default function MethodologyModal(props) {

  useEffect(() => {

  }, [])


  function MethodOne(props) {
    return (
      <>
        <div className='row'>
          <div className='col-lg-1 col-md-1 col-sm-1 col-2'>
            <img src={props.img} alt="Mintd" className='' />
          </div>
          <div className='col-lg-11 col-md-11 col-sm-11 col-10'>
            <h4 className='py-2'>{props.title}</h4>
            {
              props.type == 1 ?
                <p>{props.content}</p>
                : props.type == 2 ?
                  <>
                    <p className='mb-0'>Even within the two major asset classes - equities and debt - there are several sub-classes that offer different risk/reward possibilities. Here they are for each:</p>
                    <ul>
                      <li>Equities (from least to most risky): US equities, Indian large cap equities, Indian mid cap equities, and Indian small cap equities</li>
                      <li>Debt (from least to most risky): liquid funds, arbitrage funds, government bond funds, and corporate bond funds</li>
                    </ul>

                  </>
                  : props.type == 3 ?
                    <>
                      <p className='mb-0'>For each asset class, there are broadly two types of funds: passive funds, which just track that asset’s market and active funds, which try to beat the asset’s market. A significant amount of research has been published that shows active funds not only underperform the market, but those that outperform in one period are unlikely to outperform in subsequent periods. Hence, we prefer to stick to passive funds and instead focus on other criteria for selection:</p>
                      <ol>
                        <li><span>Cost</span>: All things equal, we choose funds with the lowest expense ratios (annual fees)</li>
                        <li><span>Tracking error</span>: Even passive funds don’t always exactly track the markets they’re supposed to replicate. This difference is called a tracking error. We try to choose funds with the lowest tracking error.</li>
                        <li><span>Liquidity</span>: Liquidity means how easy it is to buy or sell something. We want to make sure that the funds we pick have sufficient liquidity so that we can buy or sell large amounts for our customers at any time.</li>
                        <li><span>AUM</span>: AUM stands for Assets Under Management. A large AUM is a good indicator that several investors trust the fund and that it has been around for a long time.</li>
                      </ol>
                    </>
                    : props.type == 4 ?
                      <>
                        <p>Here’s where the Nobel Prize-winning science comes in. In 1952 Harry Markowitz came up with a mathematical framework called <a href="https://en.wikipedia.org/wiki/Modern_portfolio_theory" target="_blank">Modern Portfolio Theory</a>, for which he later won the Nobel Prize. The TLDR: It tells you what percentage of your portfolio to allocate to each asset class to maximize your return for each given level of risk. We have used this theory as the foundation for our portfolio construction.</p>
                      </>
                      : props.type == 5 ?
                        <>
                          <p>The output of the Modern Portfolio Theory framework is a range of risk scores - expressed in terms of portfolio volatility - with accompanying portfolios that maximizes your potential return for each score. We’ve converted that range into a simplified 0 to 10 scale, so we can assign you the ideal portfolio based on where our assessment of your risk tolerance falls on that scale. </p>
                          <p>If you want to read about our <a href="https://www.mintd.in/methodology" target="_blank">methodology</a> in more detail, head over to our website.</p>
                        </>
                        :
                        null
            }
          </div>
        </div>
      </>
    )
  }


  return (
    <div className={`${styles.e_methodology_modal} modal fade`} id="methodology" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
        <div className={`${styles.e_modal_content} modal-content`}>
          <div className={`${styles.e_modal_header} modal-header`}>
            <div className='row' style={{ width: "100%" }}>
              <div className='offset-lg-3 offset-md-3 offset-sm-3 offset-3 col-lg-6 col-md-6 col-sm-6 col-6'>
                <h6 className='mb-0'>Methodology</h6>
              </div>
              <div className='col-lg-3 col-3 text-end'>
                <img src={Close} data-bs-dismiss="modal" aria-label="Close" className={`${styles.e_close}`} />

              </div>
            </div>
            {/* <h6 className='mb-0'>Methodology</h6>
            <img src={Close} data-bs-dismiss="modal" aria-label="Close" className={`${styles.e_close}`} /> */}
          </div>

          <div className={`${styles.e_container} container mt-4 pt-2`}>
            <div className="row">
              <div className="col-lg-12">
                <MethodOne type="1" img={PickingAsset} title="Picking the asset classes" content="The four major investable asset classes are equities, debt (or bonds), gold, and real estate. Within these, Indian investors’ portfolios have historically been overexposed to real estate and gold. In fact, the two asset classes combined make up more than 65% of all Indian household wealth. Given our inherent bias towards real estate and gold, we have left them out of our asset allocation and focused on equities and bonds (debt) only." />

              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <MethodOne type="2" img={Subclass} title="Choosing sub-class" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <MethodOne type="3" img={SubclassTwo} title="Choosing sub-class" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <MethodOne type="4" img={Fund} title="Deciding how much to invest in each fund" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-12">
                <MethodOne type="5" img={Score} title="Tying it all in to your risk score" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
