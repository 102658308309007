/*
 *   File : selectBox.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 29-10-2021
 */

/*import package*/
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

/*import style*/
import styles from '../../styles/components/selectBox.module.scss';

const SelectBox = (props) => {

	const valueChange = (e, value, key) => {
		props.selectchange(e, value, key);
	}

	return (
		<div id={props.id}
			className={props.value?.value !== null ? "e-select-wraper" : "e-select-null-wraper"}>
			{
				props.image ?
					<img src={props.image} />
					:
					null
			}
			<Select id={props.id}
				isSearchable={props.isSearchable}
				isClearable={props.isClearable}
				className={`${styles.e_select_box}` + " " + props.className}
				onChange={(e, value) => { valueChange(e, value) }}
				isDisabled={props.isDisabled}
				options={props.options}
				value={props.value}
				name={props.name}
				isMulti={props.selectType}
				placeholder={props.placeholder ? props.placeholder : 'Select'}
				menuIsOpen={props.menuIsOpen}
				// defaultMenuIsOpen="true"
				defaultValue={props.defaultValue}

			/>
		</div>
	);
}

export default SelectBox;
