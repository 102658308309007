/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { TextArea } from '../input/index';
import { SecondaryBtn, PrimaryBtn, PrimaryLink } from '../cta/index';

import Close from '../../assets/images/new-design/modalClose.svg';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';


/* import assets */
import Delete from '../../assets/images/modal/delete.svg'

// import services
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { Toast } from '../note';

export default function CancelSIPModal(props) {

  const [id, setId] = useState();
  const [loader, setLoader] = useState(false);
  const [toast, setToast] = useState(false);
  const [success, setSuccess] = useState(false);


  useEffect(() => {
    if (props.id) {
      setId(props.id);
    }
  }, [props.id])

  useEffect(() => {
    let modal = document.getElementById('cancelConfirm');
    modal.addEventListener('hidden.bs.modal', () => {
      props.modalClose(success);
    })
  }, [])

  const cancelSIP = () => {
    setLoader(true);
    let url = devconfig.user + '/sip/cancel'
    let data = JSON.stringify({
      sip_id: id
    })
    APIPost(url, data).then((response) => {
      if (response.status_code === 200) {
        // $('#cancelConfirm').trigger('click');
        setLoader(false);
        // props.success();
        setSuccess(true);
      } else {
        setLoader(false);
        setToast(response.message);
      }
    });
    setTimeout(() => {
      setToast(null);
    }, 5000)
  }

  const _success = () => {
    props.success()
  }
  return (
    <div className={`${styles.e_edit_modal} modal fade`} id="cancelConfirm" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        {
          success === false ? (
            <div className={`${styles.e_modal_content} modal-content`}>
              <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                <h5 className='mt-1'>Cancel SIP</h5>
                <img
                  src={Close}
                  className={`${styles.e_modal_close}`}
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="container p-4">
                <div className="row ">
                  <div className='col-12 text-center'>
                    <h5 className={`${styles.e_subtitle}`}>Do you wish to stop your SIP?</h5>
                    <p className={`${styles.e_note}`}>
                      Please note : Cancellations will only apply for future instalment.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <PrimaryBtn
                      name="Keep SIP"
                      className="w-100"
                      cancel="modal"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <SecondaryBtn
                      name={loader ? "Canceling " : "Cancel SIP"}
                      className={loader ? 'e-btn-loader w-100' : 'w-100'}
                      // className={ loader ? `${styles.e_modal_submit} e-modal-submit e-send-btn e-btn-loader` : `${styles.e_modal_submit} e-modal-submit e-send-btn`} /
                      handleClick={() => cancelSIP()}
                    />
                  </div>
                </div>
              </div>
            </div>
          )  : (
            <div className={`${styles.e_modal_content} modal-content`}>
              <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                <h5 className='mt-1'>SIP Cancelled</h5>
                <img
                  src={Close}
                  className={`${styles.e_modal_close}`}
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="container p-4">
                <div className="row ">
                  <div className='col-12 text-center'>
                    <p className={`${styles.e_note}`}>
                      We have cancelled your SIP, cancellations will only apply for future instalment.
                    </p>
                  </div>
                  <div className="col-12 mt-3">
                    <PrimaryBtn
                      name="Start another SIP"
                      className="w-100"
                      
                      handleClick={()=> _success()}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }

      </div>
      {
        toast ?
          <Toast data={toast} type="fail" />
          :
          null
      }
    </div>

  )
}
