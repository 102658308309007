/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { TextArea } from '../input/index';
import { SecondaryBtn, PrimaryBtn } from '../cta/index';


/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/* import assets */
import Delete from '../../assets/images/modal/delete.svg'

/*Service import*/
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { message } from 'antd';

export default function DeleteScore(props) {
	const [loader, setLoader] = useState(false);
	const [reason, setReason] = useState();
	const [error, setError] = useState(null);

	useEffect(() => {
		var myModalEl = document.getElementById('deleteScore')
		myModalEl.addEventListener('hidden.bs.modal', function (event) {
			setReason('')
		})
	}, [])
	const requestForDelete = () => {
		setLoader(true);
		let url = devconfig.user + '/profile/delete-risk-score';
		let data = JSON.stringify({
			reason: reason,
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				// message.success(response.message);
				setLoader(false);
				$('#deleteScore').trigger('click');
			}
			else {
				setError(response.message);
				setLoader(false);
			}
		})
	}

	return (
		<div className={`${styles.e_edit_modal} modal fade`} id="deleteScore" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h6>Confirmation</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="container px-4">
						<div className="row pt-3 px-lg-3 px-md-3">
							<div className={`${styles.e_icon} d-lg-block d-md-block d-none col-lg-1 col-2 pe-0 gx-0 `}>
								<img src={Delete} alt="" />
							</div>
							<div className="col-lg-11 col-10 ps-0 gx-0 ps-3">
								<span className={`${styles.e_delete_text} `}>Do you really want to delete your risk score? It is recommended to keep our recommended score for better risk management. Also specify the reason you want to delete the score</span>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-lg-12">
								<TextArea placeholder="Specify your reason" value={reason} valueChange={(value) =>{ setError(null); setReason(value)}} type="text" />
							</div>
							{
								error ?
									<p style={{color: 'red'}}>{error}</p>
								:
									null
							}
						</div>
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									{
										reason ?
											<PrimaryBtn name={loader ? "Deleting" : "Yes, delete"} handleClick={() => requestForDelete()} className={loader ? `${styles.e_modal_submit} e-modal-submit e-send-btn e-btn-loader` : `${styles.e_modal_submit} e-modal-submit e-send-btn `} />
											:
											<PrimaryBtn name="Yes, delete" disabled={true} className={`${styles.e_modal_submit} e-modal-submit e-send-btn`} />
									}

									<SecondaryBtn name="Cancel" className={`${styles.e_cancel} e-cancel ms-2 ms-lg-0 ms-md-0`} cancel="modal" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
