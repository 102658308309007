import React from 'react';
import $ from 'jquery'
import { useEffect, useState } from 'react';

/*import style*/
import styles from '../../styles/components/progressBar.module.scss';

const ProgressBar = (props) => {
    const [isScanComplete, setScanComplete] = useState(false);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (props.loader) {

            runScan()

        } else {
            setDataToInitial()
        }
    }, [props.loader])

    const handleScanComplete = () => {
        setWidth(100);

        setTimeout(() => {
            if (props.complete) {
                setScanComplete(true);
            } else {
                $("#re-progress").removeClass("e-progress-loader")
                setWidth(0)
                setTimeout(() => {
                    $("#re-progress").addClass("e-progress-loader")
                    runScan()
                }, 1000)
            }

        }, 1000);
    }


    const runScan = () => {
        setWidth(30);
        setTimeout(() => {
            setWidth(60);
        }, 400);

        setTimeout(() => {
            handleScanComplete()
        }, 1000);

    }

    const setDataToInitial = () => {
        setWidth(0);
        setScanComplete(false);
    }


    return (
        <div id="e-progress" className={isScanComplete ? "e-progress-loader w-100 d-flex flex-column align-items-center e-hide-scan-loader" : "e-progress-loader w-100 d-flex flex-column align-items-center"} style={{ margin: props.margin }}>
            <div className='e-btn-loader mb-3'>
                <span>
                    Loading
                </span>
            </div>
            <div className={`${styles.e_wrapper} d-flex`}>
                <div className={`${styles.e_progress_bar}`} style={{ width: `${width}%` }}></div>
            </div>
        </div>
    )
}

export default ProgressBar;