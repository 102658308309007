import $ from 'jquery';

export default async function APIPost(url, data, access_token) {
  let token = localStorage.getItem('access_token') || access_token
  return await $.ajax({
    type: "POST",
    url: url,
    timeout: 120000,
    dataType: 'json',
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    },
    data: data,
    complete: function (XHR, TextStatus) {
      if (TextStatus == 'timeout') {
        console.log("Timeout");
      }
    }
  })
    .then(function (response) { return response })
    .catch(function (response) {
      console.log(response.responseJSON);
      if (response.responseJSON.status_code === 401) {
        localStorage.clear();
        sessionStorage.clear();
        window.location = "/";
      }
      else {
        return response.responseJSON
      }

    })
}
