/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { TextArea } from '../input/index';
import { SecondaryBtn, PrimaryBtn, PrimaryLink } from '../cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';


/* import assets */
import Delete from '../../assets/images/modal/delete.svg'

// import service
import Config from '../../config/config';
import APIGet from '../../services/getApi';

export default function OpenSIPConfirmation(props) {

    const [ reject,  setReject] = useState(false);
    const [ toast, setToast ] = useState(null);

    useEffect(()=> {
        let modal = document.getElementById('openSIP');
        modal.addEventListener('hidden.bs.modal', () => {
            props.modalClose();
        })
    },[])

    const rejectRebalance = () => {
        setReject(true);
        let url = Config.user + '/investment/reject-rebalance';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setReject(false)
                $('#openSIP').trigger('click');
                window.location.href="/overview";
            }
            else {
                setReject(false);
                setToast(response.message);
            }
        })
        setTimeout(() => {
            setToast(null);
        }, 5000)
    }

  return (
    <div className={`${styles.e_edit_modal} modal fade`} id="openSIP" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6>Confirmation</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="container px-3">
            <div className="row ">
              <div className="col-12  mt-3">
                <span className={`${styles.e_delete_text} `}>
                  You have a pending order in progress. If you continue, 
                  your new allocations might not match your target allocations. 
                  Would you still like to continue?
                  {/* You have an active order in progress. Do you want to continue. */}
                  </span>
              </div>
            </div>
            <div className="row pt-4 mt-2 pb-2">
              <div className="col-lg-12">
                <div className="d-flex justify-content-end">
                    <PrimaryLink 
                        name={ reject ? 'Processing' : "Come back later" }
                        className={ reject ? "e-btn-loader me-3" : 'me-3' }
                        handleClick={( )=> rejectRebalance()}
                    />
                    <PrimaryBtn 
                        name= "Continue anyway" 
                        cancel="modal"
                        handleClick={()=> props.continue()}
                        // className={ loader ? `${styles.e_modal_submit} e-modal-submit e-send-btn e-btn-loader` : `${styles.e_modal_submit} e-modal-submit e-send-btn`} /
                        />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
          toast ?
            <Toast data={toast} type="fail"/>
        :
            null
      }
    </div>
  )
}
