/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Orders
 *   Integrations : 25-01-2022
 *   Version : 1.0.0
 *   Created : 04-01-2022
 */

/*import packages*/
import { useState, useEffect, React, useContext } from 'react';
import ReactJoyride, { ACTIONS } from 'react-joyride';
import { Modal } from 'bootstrap';

/*import components*/
import { Footer } from '../../components/menu/index';
import { PrimaryBtn } from '../../components/cta';
import { SkipTour } from '../../components/modal';
import { UserDataContext } from '../../context/userData';

/*import assets*/
import FooterLogo from '../../assets/images/menu/footerLogo.svg';
import Playstore from '../../assets/images/menu/playstore.svg';
import Appstore from '../../assets/images/menu/appstore.svg';
import Arrows from '../../assets/images/tour/arrow.svg';
import Previous from '../../assets/images/tour/prev.svg';
import MintdLogo from '../../assets/images/menu/logo.svg';
import SideBarMenu from '../../assets/images//new-design/menu.png';
import Arrow from '../../assets/images/dataformatting/arrow.svg'
import Users from '../../assets/images/sidebar/users.svg';
import Active from '../../assets/images/new-design/profile-tab-arrow.svg';
import User from '../../assets/images/new-design/user-navigation.svg';
import Message from '../../assets/images/new-design/message-profile.svg';

/*import services */
import GetURLVar from '../../services/getUrl';
import Config from '../../config/config';
import APIGet from '../../services/getApi';
import LeadSquared from '../../services/leadSquredServices';
import Sidebar from '../../components/menu/sidebar';
import Details from '../profile/details';

const ProfileTour = (props) => {
  const [tabIndex, setTabindex] = useState(1);
  // let percentValue = 100 / 7;
  const [run, setRun] = useState(false);
  //eslint-disable-next-line
  const [userData, setUserData, onboardFlag, setOnboardFlag] = useContext(UserDataContext);
  const [openSideBar, setOpenSideBar] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, []);

  const getQueries = async () => {
    let queries = await GetURLVar();
    if (queries.tabIndex) {
      setTabindex(parseInt(queries.tabIndex))
    }
  }

  useEffect(() => {
    getQueries();
  }, [tabIndex]);

  useEffect(async () => {
    setRun(true)
  }, [])

  const handleClickStart = () => {
    // e.preventDefault();
    setRun(true)
  };

  // analytics tracker for product tour calls after complete tour
  const _segmentTourTracker = () => {
    window.analytics.track('Product tour', {
      user_id: userData?.user_id,
      producttour_status: 'viewed',
      step: 7
    });
  }

  const _updateTourStatus = () => {
    let url = Config.user + '/profile/product-tour';
    APIGet(url).then((response) => {
      if (response.status_code === 200) {
        if (props.active === true) {
          window.location.href = '/overview';
        } else {
          window.location.href = '/onboard';
        }

      }
    })
  }

  /* joyride steps */

  const steps = [

    {
      content: (
        <div className='e-tour'>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      target: '#e-sec-0',

    },
    {
      content: (
        <div className='e-tour'>
          <div className='d-flex'>
            <span className='e-completed-item'>7</span>
            <h6 className='ps-3 text-start pt-0 pe-3'>Edit your portfolio anytime</h6>
          </div>
          <p className='text-start ms-4 ps-3'>Want to change your risk level? Put in a request with our team so we can change your current portfolio to your desired one.</p>
          <PrimaryBtn name="Finish tour and continue"
            className="e-finish-tour-btn"
            cancel="modal"
            handleClick={() => {
              _segmentTourTracker();
              LeadSquared._activityTracker('Product Tour Drop-off mail', 208);
              _updateTourStatus();
            }}
          />


        </div>
      ),
      placement: 'left',
      styles: {
        options: {
          borderRadius: 16,
          width: 400,

        }
      },
      locale: {
        skip: <p className=''>7 to 7</p>,
        next: <span className="" >
          <img src={Arrows} className="e-form-submit" />
        </span>,
        back: <img src={Previous} alt="" className='d-none' />

      },
      disableBeacon: true,
      disableOverlayClose: true,
      target: '#e-sec-7',

    },


  ]

  const handleJoyrideCallback = data => {
    const { action, index, type, size } = data;

    if (action === ACTIONS.CLOSE) {
      var modal = new Modal(document.getElementById("skip"), {});
      modal.show()
      return setRun(false)

    }
  }

  const profileData = {
    "first_name": "John",
    "last_name": "Doe",
    "dob": "06/11/1996",
    "dob_text": "06 Nov 1996 ",
    "risk_sore": "10",
    "pan": "ABCDE3456T",
    "country_code": "+91",
    "mobile": "9876543219",
    "email": "johndoe@gmail.com",
    "is_indian_citizen": true,
    "is_product_viewed": false,
    "wealth_source_id": "34",
    "wealth_source": "Salary",
    "marital_status_id": "31",
    "marital_status": "Single",
    "gender_id": "29",
    "gender": "Female",
    "occupation_id": "10",
    "occupation": "Professional",
    "rebalance_available": null,
    "active_editscore_request": false,
    "active_deletescore_request": false,
    "created": "07 Nov 2022",
    "last_invested": "07 Nov 2022",
    "sip_dates": [

    ],
    "invested_amount": 3400.0,
    "invested_amount_text": "₹3,400.00",
    "profile_picture": null
  }



  return (

    <>
      {/* <HeaderTour logo={InnerLogo} /> */}
      <div className="container-fluid e-profile-container px-4">
        <div className="row pt-4 ps-3">
          <div className='col-lg-2'>
            <div className='e-side-bar-lg' >
              <Sidebar tour={props.tour} activeUser={true} count={1} />
            </div>
          </div>
          <div className='col-lg-10'>
            <div className={

              'e-inner-small-screen-title d-flex justify-content-between px-2 pb-4'
            }>
              <img src={MintdLogo} className="mt-4" />
              <button
                className='e-menu-btn mt-4'
                onClick={() => {
                  setOpenSideBar(true);
                }}
              >
                <img src={SideBarMenu} />
              </button>
            </div>
            <div
              className={
                openSideBar === true ?
                  'e-small-screen-menu e-menu-over-lay'
                  :
                  'e-small-screen-menu'
              }
              style={{ display: openSideBar && 'block', right: openSideBar && '0' }}
            >
              <Sidebar tour={props.tour} activeUser={true} count={1} id={window.innerWidth <= 992 ? "e-sec-3" : null} closeMenu={() => setOpenSideBar(false)} />
            </div>
            <div className='row pe-0'>
              <div className="col-lg-3 col-md-3 col-sm-12 e-onboard-progress-section py-4 ps-4">
                <div className='row'>
                  <div className='col-12 gx-0 mt-3'>
                    <div className="e-breadcrumb-wrapper mt-4">
                      <span className="e-previous">Account Settings</span>
                      <span className="e-divider mx-2"><img src={Arrow} /></span>
                      <span className="e-current">
                        {
                          tabIndex === 1 ?
                            'Personal Details'
                            : tabIndex === 2 ?
                              'Bank Details'
                              : 'Nominee Details'
                        }
                      </span>
                    </div>
                  </div>
                </div>
                <div className={window.innerWidth > 575 ? 'row mt-4 e-profile-tab-wrapper' : 'mt-4 e-profile-tab-wrapper'}>
                  <div className={window.innerWidth > 575 ? 'col-lg-12 col-md-12 col-sm-4 col-6 gx-0' : ''}>
                    <div className={tabIndex === 1 ? 'e-active-profile-tab mt-1' : 'e-profile-tab mt-1'} onClick={() => setTabindex(1)}>
                      <div className='d-flex'>
                        <img src={User} />
                        <span className='mx-2 '>Personal Details</span>
                        {
                          tabIndex === 1 && (
                            <img src={Active} className='' />
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className={window.innerWidth > 575 ? 'col-lg-12 col-md-12 col-sm-4 col-6 gx-0' : ''}>
                    <div className={tabIndex === 2 ? 'e-active-profile-tab mt-1' : 'e-profile-tab mt-1'} onClick={() => setTabindex(2)}>
                      <div className='d-flex'>
                        <img src={Message} />
                        <span className='mx-2'>Bank Details</span>
                        {
                          tabIndex === 2 && (
                            <img src={Active} />
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className={window.innerWidth > 575 ? 'col-lg-12 col-md-12 col-sm-4 col-6 gx-0' : ''}>
                    <div className={tabIndex === 3 ? 'e-active-profile-tab mt-1' : 'e-profile-tab mt-1'} onClick={() => setTabindex(3)}>
                      <div className='d-flex'>
                        <img src={Users} />
                        <span className='mx-2'>Nominee Details</span>
                        {
                          tabIndex === 3 && (
                            <img src={Active} />
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-9 col-md-9 col-sm-12  mt-5 ps-lg-5 ps-md-5 ps-2 e-onboard-step-section pb-5 mb-5" id="e-sec-7">
                <h5 className="e-dashboard-greeting mt-3">
                  My Profile
                </h5>
                <p className="e-dashboard-sub-title" style={{ marginBlockEnd: '-11px' }}>
                  Some subtext for user's profile goes here
                </p>
                <div className='col-12'>
                  {
                    tabIndex === 1 && (
                      <Details profile={profileData} />
                    )
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer logo={FooterLogo} playstore={Playstore} appstore={Appstore} /> */}
      <ReactJoyride
        continuous
        showSkipButton
        scrollTo
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        styles={{
          options: {
            zIndex: 1000,
            color: "#1329e6",
            arrowColor: 'transparent',
            height: 300,
          },
          buttonNext: {

            background: "#1329e6",
            // borderRadius: "50%",
          },
        }}
      />
      <SkipTour active={props.active} continue={() => handleClickStart()} />

    </>



  )
}

export default ProfileTour;

