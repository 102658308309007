/*
 *   File : s3UploadHelper.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : s3UploadHelper.js
 *   Integrations : 
 *   Version : v1.0
 */

// import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';

export default async function UploadImageFileAWS(fileName, path, file, fileType) {
    let config = {
        bucketName: 'mintd',
        dirName: path,
        region: 'ap-south-1',
        accessKeyId: 'AKIAVZWU5DK3BBETKF76',
        secretAccessKey: '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',
        // s3Url:  'https://ninjatruckawsbucket.s3.amazonaws.com',
    };

    const ReactS3Client = new S3(config);

    return ReactS3Client
        .uploadFile(file, fileName)
        .then(data => {
            return { "status": true, "cdn_url": data.location }
        })
        .catch(err => {
            console.error(err);
            return { "status": false, "cdn_url": err }
        })
}
