import { useState } from "react";

import Holdings from '../../assets/images/new-design/holdings.svg'
import Transactions from '../../assets/images/new-design/transactions.svg';
import Capital from '../../assets/images/new-design/captial.svg';
import Info from '../../assets/images/new-design/info.svg';

import DateRangePicker from '../../components/input/yearRangePicker';

import devconfig from "../../config/config";
import APIPost from "../../services/postApi";
import APIGet from "../../services/getApi";
import { PrimaryBtn } from "../../components/cta";
import { IconStyleToolTip } from "../../components/tooltip";
import { Toast } from "../../components/note";
import { useEffect } from "react";

const Reports = (props) => {

    const [id, setId] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [loader, setLoader] = useState(false);
    const [type, setType] = useState(null);
    const [capitalInfo, setCapitalInfo] = useState(false);
    const [gainersInfo, setGainersInfo] = useState(false);
    const [transactionsInfo, setTransactionsInfo] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 5000)
        }
    }, [error])

    useEffect(() => {
        if (props.userDetail) _analyticsTrackUserDetails();
    }, [props.userDetail])

    const _analyticsTrackUserDetails = () => {
        window.analytics.identify(props.userDetail?.user_id, {
            path: window.location.pathname
        });
    }

    const downloadGainReport = (type) => {
        setLoader(true);
        let url = devconfig.user + '/profile/capitalgain-report';
        let data = JSON.stringify({
            from_date: startDate,
            to_date: endDate,
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (!response.data.url) {
                    setError('No report available for download');
                } else {
                    window.open(response.data.url);
                }
                setLoader(false)
                setStartDate(null);
                setEndDate(null);
            }
            else {
                setStartDate(null);
                setEndDate(null);
                setError(response.message);
                setLoader(false)
            }
        })
    }

    const downloadTransactions = (type) => {
        setLoader(true);
        let url = devconfig.user + '/profile/transaction-report';
        let data = JSON.stringify({
            from_date: startDate,
            to_date: endDate,
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (!response.data.url) {
                    setError('No report available for download');
                } else {
                    window.open(response.data.url);
                }
                setStartDate(null);
                setEndDate(null);
                setLoader(false)
            }
            else {
                setStartDate(null);
                setEndDate(null);
                setError(response.message);
                setLoader(false)
            }
        })
    }

    const getHoldings = () => {
        setLoader(true);
        let url = devconfig.user + '/profile/holdings-report';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url)
                setLoader(false);
                setError('No report available for download');
            }
            else {
                setLoader(false)
                setError(response.message);
            }
        })
    }

    return (
        <div className='e-reports px-lg-5 px-3 pb-5 mb-5'>
            <div className="row">
                <div className="col-12  mt-lg-3 mt-md-3 mt-sm-2 mt-2 pt-0">
                    <h5 className="e-dashboard-greeting mt-3">
                        Reports
                    </h5>
                    <p className="e-dashboard-sub-title">
                        Download and view your reports below
                    </p>
                </div>
                <div className="row pe-lg-3 pe-0">
                    <div className="col-lg-5  col-md-6 col-sm-12 mt-4 ">
                        <div className="e-card e-report-card p-4 ">
                            <div className="row ">
                                <div className="col-12">
                                    <img src={Capital} />
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="d-flex">
                                        <h5 className="position-relative">
                                            Capital gains
                                            {/* <img 
                                                src={Info} 
                                                className="ms-2 position-absolute"
                                                style={{top: '2px'}}
                                                onMouseEnter={()=> setCapitalInfo(true)}
                                                onMouseLeave={()=> setCapitalInfo(false)}
                                            />
                                            {
                                                capitalInfo && (
                                                    <IconStyleToolTip className="e-tooltip"
                                                        content="Download your capital gains report below"
                                                    />
                                                )
                                            } */}
                                        </h5>

                                    </div>
                                </div>
                                <div className="col-12">
                                    <p>
                                        Download your capital gains report below
                                    </p>
                                </div>
                                <div className="col-12 ">
                                    <label style={{ fontSize: '13px' }}>Choose period</label>
                                    <DateRangePicker
                                        onDatesChange={(tempStart, tempEnd) => {
                                            setStartDate(tempStart);
                                            setEndDate(tempEnd)
                                        }}
                                        allDate={true}
                                    />
                                </div>
                                <div className="col-12 mt-4">
                                    <PrimaryBtn
                                        name={loader && type === 1 ? 'Downloading' : 'Download'}
                                        className={loader && type === 1 && 'e-btn-loader'}
                                        handleClick={() => {
                                            setType(1);
                                            downloadGainReport()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-6 col-sm-12 mt-4 ">
                        <div className="e-card e-report-card p-4 ">
                            <div className="row ">
                                <div className="col-12">
                                    <img src={Transactions} />
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="d-flex">
                                        <h5 className="position-relative">
                                            Transactions
                                            {/* <img src={Info}
                                                className="ms-2 position-absolute"
                                                style={{ top: '2px' }}
                                                onMouseEnter={() => setTransactionsInfo(true)}
                                                onMouseLeave={() => setTransactionsInfo(false)}
                                            />
                                            {
                                                transactionsInfo && (
                                                    <IconStyleToolTip className="e-tooltip"
                                                        content="Download your transaction statement below"
                                                    />
                                                )
                                            } */}
                                        </h5>

                                    </div>
                                </div>
                                <div className="col-12">
                                    <p>
                                        Download your transaction statement below
                                    </p>
                                </div>
                                <div className="col-12">
                                    <label style={{ fontSize: '13px' }}>Choose period</label>
                                    <DateRangePicker
                                        onDatesChange={(tempStart, tempEnd) => {
                                            setStartDate(tempStart);
                                            setEndDate(tempEnd)
                                        }}
                                        allDate={true}
                                    />
                                </div>
                                <div className="col-12 mt-4">
                                    <PrimaryBtn
                                        name={loader && type === 2 ? 'Downloading' : 'Download'}
                                        className={loader && type === 2 && 'e-btn-loader'}
                                        handleClick={() => {
                                            setType(2);
                                            downloadTransactions()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-6 col-sm-12 mt-4 ">
                        <div className="e-card e-report-card p-4 ">
                            <div className="row ">
                                <div className="col-12">
                                    <img src={Holdings} />
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="d-flex">
                                        <h5 className="position-relative">
                                            Holding
                                            {/* <img
                                                src={Info}
                                                className="ms-2 position-absolute"
                                                style={{ top: '2px' }}
                                                onMouseEnter={() => setGainersInfo(true)}
                                                onMouseLeave={() => setGainersInfo(false)}
                                            />
                                            {
                                                gainersInfo && (
                                                    <IconStyleToolTip className="e-tooltip"
                                                        content="Download your holdings report below"
                                                    />
                                                )
                                            } */}
                                        </h5>

                                    </div>
                                </div>
                                <div className="col-12">
                                    <p>
                                        Download your holdings report below
                                    </p>
                                </div>

                                <div className="col-12 mt-4">
                                    <PrimaryBtn
                                        name={loader && type === 3 ? 'Downloading' : 'Download'}
                                        className={loader && type === 3 && 'e-btn-loader'}
                                        handleClick={() => {
                                            setType(3);
                                            getHoldings()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports;