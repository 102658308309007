
// import packages
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/*import components*/
import { HeaderInner, Footer } from '../../components/menu/index';
import { Loader } from '../../components/loader';
import Nodata from '../../components/note/nodata-placeholder';
import { Tab } from '../../components/dataFormatting/index';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta';
import { SuccessPayment, EditScoreConfirmation } from '../../components/modal';
import { SuccessToast } from '../../components/note';
import { Modal } from 'bootstrap';
/*import assets*/
import SIP from '../../assets/images/new-design/sip.svg';
import Lumpsum from '../../assets/images/new-design/lumpsum.svg';
import Withdrawals from '../../assets/images/new-design/withdraw.svg';
import Edit from '../../assets/images/new-design/edit-notification-square.svg';
import Down from '../../assets/images/new-design/arrow-down.svg';



// import Service
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';

const notification = [
    {
        title: 'SIP amount paid',
        content: 'We have received your payment of ₹1,500.00. The orders have been placed with each asset manager and you should receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.'
    },
    {
        title: 'SIP amount pending',
        content: 'We have received your payment of ₹1,500.00. The orders have been placed with each asset manager and you should receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.'
    },
    {
        title: 'SIP amount pending',
        content: 'We have received your payment of ₹1,500.00. The orders have been placed with each asset manager and you should receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.'
    },
    {
        title: 'SIP amount pending',
        content: 'We have received your payment of ₹1,500.00. The orders have been placed with each asset manager and you should receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.'
    },
    {
        title: 'SIP amount pending',
        content: 'We have received your payment of ₹1,500.00. The orders have been placed with each asset manager and you should receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.'
    },
    {
        title: 'SIP amount pending',
        content: 'We have received your payment of ₹1,500.00. The orders have been placed with each asset manager and you should receive your mutual fund allocations in 3-4 days. They will show on your dashboard then.'
    },
]

const NotificationList = (props) => {
    const [loader, setLoader] = useState(true);
    const [notificationData, setNotificationData] = useState(null);
    const [unreadCount, setUnreadCount] = useState(0);
    const [openWithdrawModal, setOpenModalWithdrawModal] = useState(false);
    const [status, setStatus] = useState(true);
    const [type, setType] = useState(null)
    let navigator = useNavigate();
    const [notificationDetail, setNotificaitonDetail] = useState(null);
    const [currentID, setCurrentID] = useState(null);
    const [openEditScoreModal, setOpenEditScoreModal] = useState(false);
    const [toast, setToast] = useState(null);

    useEffect(() => {
        getUnreadNotificationCount();
        getNotifications()
    }, [])

    useEffect(() => {
        if (props.count) {
            setUnreadCount(props.count);
        }
    }, [props.count])

    useEffect(() => {
        if (props.userDetail) {
            window.analytics.identify(props.userDetail.user_id, {
                path: window.location.pathname,
            });
        }
    }, props.userDetail)

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null)
            }, 5000)
        }
    }, [toast])

    useEffect(() => {
        if (openWithdrawModal === true) {
            const modal = new Modal(document.getElementById("redeem"), {})
            modal.show();
        }
    }, [openWithdrawModal])

    const getUnreadNotificationCount = () => {
        let url = devconfig.user + '/notifications/count';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setUnreadCount(response.data.notifications)
            }
        })
    }

    useEffect(() => {
        if (openEditScoreModal) {
            const modal = new Modal(document.getElementById("approved-edit"), {})
            modal.show()
        }
    }, [openEditScoreModal])

    const markAsRead = (id, key) => {
        let url = devconfig.user + '/notifications/read';
        let data = JSON.stringify({
            notification_id: id
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                props.markedAsRead();
                let arry = notificationData;
                arry[key].is_read = true;
                setNotificationData([...arry]);
            }
        })
    }

    const getNotifications = () => {
        let url = devconfig.user + '/notifications/get-notifications';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setNotificationData(response.data);
                setLoader(false);
            }
            else {
                setNotificationData(null);
                setLoader(false);
            }
        })
    }

    const openModal = (data) => {
        setOpenModalWithdrawModal(false);
        if (data === "SUCCESS") {
            setStatus(false)
            const modal = new Modal(document.getElementById("paymentSucess"), {})
            modal.show()
            setType("Payment successful")
        } else {
            setStatus(true)
            const modal = new Modal(document.getElementById("paymentSucess"), {})
            modal.show()
            setType("Payment failed")
        }
    }

    const getDetails = (order_id, id) => {
        setCurrentID(id);
        let url = devconfig.user + '/notifications/order/detail';
        let data = JSON.stringify({
            order_id: order_id
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setNotificaitonDetail(response.data);
            }
        })
    }
    const _successCancelEdit = () => {
        markAsRead(notificationData.data_id);
        props.markedAsRead();
        getNotifications();
        setToast('Rejected successfully')
    }
    return (
        <>

            <div className='e-notifications px-lg-5 px-3  pb-5 mb-5'>
                <div className='row'>
                    <div className="col-8 mt-lg-3 mt-md-3 mt-sm-0 mt-0 pt-0">
                        <h5 className="e-dashboard-greeting mt-3">
                            Notifications
                        </h5>
                        <p className="e-dashboard-sub-title">
                            {
                                unreadCount > 0 ?
                                    `You got ${unreadCount} new notifications`
                                    :
                                    'You got no new notification'
                            }
                        </p>
                    </div>
                    {
                        notificationData && notificationData.map((item, key) => {
                            return (
                                <div>
                                    <div className='col-lg-6  col-md-8 col-sm-12 mt-4' key={key}>
                                        <div
                                            className='e-card e-notification-card p-4'
                                        >
                                            <div className='row'>
                                                <div className='col-1'>
                                                    <img
                                                        src={
                                                            item.type === 1 || item.type === 4 ?
                                                                Edit
                                                                : item.type === 8 ?
                                                                    SIP
                                                                    : item.type === 9 ?
                                                                        Withdrawals
                                                                        : item.type === 7 ?
                                                                            Lumpsum
                                                                            :
                                                                            Edit
                                                        }
                                                        className="me-3 e-notification-icon"
                                                    />
                                                </div>
                                                <div className='col-11'>

                                                    <div className='ps-4'>
                                                        <h5>
                                                            {item.title}
                                                        </h5>
                                                        <p>
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    item.type === 1 ? (
                                                        <div className='col-12 text-end mt-3'>
                                                            {
                                                                item.is_read === false && (
                                                                    <SecondaryBtn
                                                                        name="Do later"
                                                                        className="e-notification-btn me-3"
                                                                        handleClick={() => {
                                                                            markAsRead(item.notification_id, key);
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            <PrimaryBtn
                                                                name="Edit"
                                                                className="e-primary"
                                                                handleClick={() => setOpenEditScoreModal(true)}
                                                            />
                                                        </div>
                                                    ) : item.type === 4 || item.type === 6 || item.type === 9 || item.type === 10 ? (
                                                        <div className='col-12 text-end mt-3'>
                                                            {
                                                                item.is_read === false && (
                                                                    <SecondaryBtn
                                                                        name="Dismiss"
                                                                        className="e-notification-btn me-3"
                                                                        handleClick={() => {
                                                                            markAsRead(item.notification_id, key);
                                                                        }}
                                                                    />
                                                                )
                                                            }

                                                        </div>
                                                    ) : item.type === 5 || item.type === 7 || item.type === 8 ? (
                                                        <div className='col-12 text-end mt-3'>
                                                            {
                                                                item.is_read === false && (
                                                                    <SecondaryBtn
                                                                        name="Dismiss"
                                                                        className="e-notification-btn me-3"
                                                                        handleClick={() => {
                                                                            markAsRead(item.notification_id, key);
                                                                        }}
                                                                    />
                                                                )
                                                            }

                                                            <PrimaryBtn
                                                                name="See details"
                                                                right={true}
                                                                image={Down}
                                                                iconClass="ms-2"
                                                                className="e-primary"
                                                                handleClick={() => {
                                                                    if (currentID === item.notification_id) {
                                                                        setNotificaitonDetail(null);
                                                                        setCurrentID(null)
                                                                    } else {
                                                                        getDetails(item.data_id, item.notification_id)
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                        : null
                                                }
                                                {
                                                    currentID === item.notification_id && notificationDetail && (
                                                        <div className='col-12'>
                                                            <div className='e-notification-accordion p-4 mt-4'
                                                                style={{ height: currentID === item.notification_id && notificationDetail && '327px' }}
                                                            >
                                                                <div className='row'>
                                                                    <div className='col-lg-1 col-md-2 col-sm-2 col-2'>
                                                                        <div className='e-notification-progress-wrapper'>
                                                                            <div className='e-inprogress-dashed'>
                                                                                <div className='e-progress-indicator' style={{ height: notificationDetail.units_allocated_on !== null ? '100%' : '50%' }}></div>
                                                                                <div className='e-step-count e-one'>
                                                                                    1
                                                                                </div>
                                                                                <div className='e-step-count e-two'>
                                                                                    2
                                                                                </div>
                                                                                {
                                                                                    notificationDetail.units_allocated_on !== null ? (
                                                                                        <div className='e-step-count e-three'>
                                                                                            3
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className=' e-step-count e-three e-step-progress'>
                                                                                            3
                                                                                        </div>
                                                                                    )
                                                                                }


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-11 col-md-10 col-sm-10 col-10'>
                                                                        <div className='e-progress-label-wrapper'>
                                                                            <div className='e-progress-label'>
                                                                                <p>Fund received</p>
                                                                                <span className='e-success-badge'>COMPLETED</span>
                                                                                <h5>{notificationDetail.order_date}</h5>
                                                                            </div>
                                                                            <div className='e-progress-label'>
                                                                                <p>Order placed</p>
                                                                                <span className='e-success-badge'>COMPLETED</span>
                                                                                <h5>{notificationDetail.order_date}</h5>
                                                                            </div>
                                                                            <div className='e-progress-label'>
                                                                                <p>MFs allocation</p>
                                                                                <span
                                                                                    className={
                                                                                        notificationDetail.units_allocated_on === null ? 'e-inprogress-badge' : 'e-success-badge'
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        notificationDetail.units_allocated_on === null ?
                                                                                            'IN PROGRESS'
                                                                                            :
                                                                                            'COMPLETED'
                                                                                    }
                                                                                </span>
                                                                                <h5>{notificationDetail.units_allocated_on} </h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>


            </div>
            {
                openEditScoreModal && (
                    <EditScoreConfirmation
                        modalClose={() => setOpenEditScoreModal(false)}
                        succuss={() => _successCancelEdit()}
                    />
                )

            }
            {
                toast && (
                    <SuccessToast data={toast} />
                )
            }
        </>
    )
}

export default NotificationList;