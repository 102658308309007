
// import packages

// import style
import style from '../../styles/components/nodata.module.scss';

// import assets
import Illustration from '../../assets/images/new-design/no-data.svg';
import PlusIcon from '../../assets/images/new-design/plus-circle.svg';

//import components
import { PrimaryBtn } from '../cta';
import devconfig from '../../config/config';
const NoAssets = (props) => {
    return (
        <div className={`${style.e_nodata_asset_wrapper} col-lg-12 text-center mt-5 pt-3`}>
            <img src={Illustration} />
            <h5>No data found</h5>

            <p className='mb-0'>
                <span>Add your assets</span> and get started now
            </p>
            <PrimaryBtn
                name="Add assets"
                className={style.e_add_assets}
                left={true}
                image={PlusIcon}
                iconClass="me-2"
                handleClick={() => {
                    props.addAsset()
                }} />
            <p className={style.e_link}>
                Not sure how to start? <a href={devconfig.calendarUrl} target="_blank">Book a call </a> with us or chat on <a href={devconfig.whatsappUrl} target="_blank">whatsapp</a>
            </p>
        </div>
    )
}

export default NoAssets;