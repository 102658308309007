// import package
import { useEffect, useState } from 'react';
import moment from 'moment';

// import component
import { Select, SingleDate } from '../../components/input';
import { PrimaryIconBtn, PrimaryBtn } from '../../components/cta';
import { Toast } from '../../components/note';
import { IconStyleToolTip, CardIconStyleToolTip } from '../../components/tooltip/index';
import Input from '../../components/input/loginInput'
// import assest
import TooltipIcon from '../../assets/images/new-design/info.svg';
import Tooltip from '../../assets/images/tooltip/icon-blue.svg';
import LoaderImage from '../../assets/images/onboard/onboardLoader.gif';
import Trash from '../../assets/images/onboard/trash-can.png';
import Arrow from '../../assets/images/onboard/submit.svg';
import Flag from '../../assets/images/new-design/flagYellow.svg';

// import service
import COLOUR from '../../config/color';
import Config from '../../config/config';
import APIPost from '../../services/postApi';

const AddNomineeDetails = (props) => {
    const [isShownNominee, setIsShownNominee] = useState(false);
    const [error, setError] = useState(null);
    const [apiLoader, setApiLoader] = useState(false);
    const [KYCVerified, setKYCVerified] = useState(true);
    const [guardianRelationOptions, setGuardianRelationOptions] = useState([]);
    // const [ gPan, setGPan ] = useState(false);
    // const [ panNumber, setPanNumber ] = useState(null);
    // const [ gName, setGname] = useState(null);

    let [idx, setIdx] = useState([
        {
            nominee_id: null,
            nominee_name: "",
            allocation: null,
            nominee_dob: null,
            relation: null,
            nominee_pan: '',
            guardian_name: '',
            guardian_pan: ''
        }
    ]);

    useEffect(() => {
        if (props.relationOptions) {
            const filtered = props.relationOptions.filter((item) => item.label !== 'Son' && item.label !== 'Daughter');
            setGuardianRelationOptions(filtered)
        }

    }, [props.relationOptions])


    useEffect(() => {
        if (props.progressData.nominee_data) {
            //  nominee data
            var temp = [];
            if (props.progressData.nominee_data.length) {
                props.progressData.nominee_data.map((item, key) => {

                    return (
                        item.skip_nominee === false ?
                            temp.push(
                                {
                                    nominee_name: item.name,
                                    allocation: item.allocation,
                                    nominee_dob: item.dob,
                                    relation: { value: item.relation_id, label: item.relation_name },
                                    guardian_name: item.guardian_name ? item.guardian_name : null,
                                    guardian_relation: item.guardian_relation ? { value: item.guardian_relation_id, label: item.guardian_relation_name } : null
                                }
                            )
                            :
                            temp.push(
                                {
                                    nominee_name: '',
                                    allocation: '',
                                    nominee_dob: null,
                                    relation: null,
                                    guardian_name: null,
                                    guardian_relation: null
                                }
                            )
                    )

                })

            }
            else {
                temp.push(
                    {
                        nominee_name: '',
                        allocation: '',
                        nominee_dob: null,
                        relation: null,
                        guardian_name: null,
                        guardian_relation: null
                    }
                )
            }
            setIdx(temp)
        }
    }, [props.progressData])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 3000)
        }
    }, [error])

    // adding nominee dob to array
    const handleChange = (val, e, i) => {
        console.log(val, e, i)
        const { name, value } = e.target
        const list = [...idx];
        list[i][name] = value;
        setIdx(list);
    }

    // adding nominee percentage to array
    const handleChangeNumber = (val, e, i) => {
        const { name, value } = e.target
        const list = [...idx];
        if (value) {
            list[i][name] = Number(value);
        }
        else {
            list[i][name] = value;
        }
        setIdx(list);
    }

    // adding nominee relation to array
    const SelectChange = (value, e, i) => {
        console.log(value, e, i)
        const list = idx;
        // const list = [...idx];
        list[i][e.name] = value.value;
        list[i]['selectedRelation'] = value;
        setIdx([...list]);
    }

    // adding fields for more nominee details
    const addField = () => {
        setIdx([...idx,
        {
            nominee_name: "",
            allocation: null,
            nominee_dob: null,
            relation: null,
            guardian_name: '',
            guardian_relation: null,
        }])
    }

    // validating nominee allocation percentage, sum off all allocation value needs to be 100  
    const validateAllocation = () => {
        if (idx[0].allocation === null) {
            AddNominee({ is_nominee: false });
            return false;
        }
        else {
            for (let index = 0; index < idx.length; index++) {
                const element = idx[index];
                if (element.nominee_name === "") {
                    setError("Name");
                    showFormValidationError("name" + index)
                    return false
                } else if (element.nominee_dob === null) {
                    setError("dob");
                    showFormValidationError("nominee_dob" + index)
                    return false
                }
                else if (element.relation === null) {
                    setError("relation");
                    showFormValidationOther("relation" + index)
                    return false
                }
            }
            return true
        }
    }
    /* validation to highlight fields */
    const showFormValidationError = (field) => {
        var ele = document.getElementById(field);
        ele.style.border = `solid 1.5px ${COLOUR.red}`
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* clearing validation fields */
    const clearFormValidationError = (field) => {
        console.log(field)
        var ele = document.getElementById(field);
        if (ele) {
            ele.style.border = null;
        }
    }

    /* validation for select */
    const showFormValidationOther = (field) => {
        const ele = document.getElementById(field);
        ele.className = "e-select-error"

        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* clearing validation for select */
    const clearFormValidationOther = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.className = "e-select-wraper";
            console.log(ele)
        }
    }

    const validateNomineeForm = () => {
        let status = validateAllocation()
        if (status) {
            let alloc = idx.reduce((a, b) => a = a + b.allocation, 0)
            if (alloc === 100) {
                AddNominee({ is_nominee: true });
            } else {
                setError("Allocation must be 100%")
            }
        }
    }

    const AddNominee = (status) => {
        console.log(idx)
        let temp = [];
        idx.map((item, key) => {
            return (
                temp.push({
                    nominee_id: key,
                    nominee_name: item.nominee_name,
                    allocation: item.allocation,
                    relation_id: item.relation?.value,
                    nominee_dob: item.nominee_dob,
                    guardian_name: item.guardian_name,
                    guardian_relation_id: item.guardian_relation?.value
                    // guardian_name: item.guardian_name,
                    // guardian_pan: item.guardian_pan,
                    // nominee_pan: item.nominee_pan
                })
            )
        })
        console.log(temp)
        setApiLoader(true);
        let url = `${Config.user}/auth/add-nominee`;
        let data = JSON.stringify({
            nominees: status?.is_nominee === false ? [] : temp,
            is_nominee_available: status?.is_nominee === false ? status.is_nominee : true,
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setApiLoader(false);
                props.updateUserData('nominee_data', response.data.nominee_data)
                props.success(response.data.otp, response.data.signup_status);
            } else {
                setApiLoader(false);
                setError(response.message)
            }
        })
    }

    const MinorCheck = (dob, key) => {
        // console.log('index', key);
        const birthday = new Date(dob);
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs);
        var age = Math.abs(ageDate.getUTCFullYear() - 1970);
        if (age < 18) {
            return (
                <>
                    <div className="col-lg-6 mt-lg-3 mt-md-3 mt-sm-3 mt-3">
                        <label className="mb-1">
                            Guardian name
                            <span className='star px-1'> * </span>
                        </label>
                        <Input
                            id="g_name"
                            label=" Guardian Name"
                            value={idx[key].guardian_name}
                            valueChange={(value) => {
                                let tmp = idx;
                                tmp[key].guardian_name = value;
                                setIdx([...tmp])
                            }}
                            type="text"
                        />
                    </div>
                    <div className="col-lg-6 mt-lg-3 mt-md-3 mt-sm-0 mt-0">
                        <label className="mb-1">
                            Guardian Relation
                            <span className='star px-1'> * </span>
                        </label>
                        <Select
                            name="guardian relation"
                            // defaultValue={{ value: idx[key].guardian_name, label :  }}
                            id={"relation" + key}
                            value={idx[key].guardian_relation}
                            options={guardianRelationOptions}
                            placeholder="Guardian Relation"
                            className="e-dashboard-card-sort"
                            selectchange={(val) => {
                                let tmp = idx;
                                tmp[key].guardian_relation = val;
                                setIdx([...tmp])
                            }}
                        />
                    </div>
                </>
            )
        }
        else {
            return null
        }
    }

    const _validForm = () => {
        let valid = false;
        for (let index = 0; index < idx.length; index++) {
            const element = idx[index];
            if (element.allocation && element.nominee_dob && element.nominee_name && element.relation) {
                const birthday = new Date(element.nominee_dob);
                var ageDifMs = Date.now() - birthday.getTime();
                var ageDate = new Date(ageDifMs);
                var age = Math.abs(ageDate.getUTCFullYear() - 1970);
                if (age < 18) {
                    if (!element.guardian_name || !element.guardian_relation) {
                        valid = false
                    } else {
                        valid = true
                    }
                } else {
                    valid = true
                }
            } else {
                valid = false;
            }
        }
        return valid;
    }



    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-12  e-mobile-confirm-wrap pb-5 mb-5">
            <div className="e-process-wrap position-relative">
                <h5 className="e-onboard-form-title mt-1">
                    Nominee Details
                </h5>

                <div className="row mt-2">
                    <div className="col-lg-11">
                        <div className="e-onboard-sub-info p-3">
                            <div className="row">
                                <div className="col-11">
                                    <h5>
                                        We recommend that you add a nominee to your account.
                                        A nominee is someone who can receive the proceeds of your account in the event of your unexpected death.
                                        The nominee can be anyone you deem to be your first relative - your parents and spouse.
                                        This is a standard precautionary measure
                                    </h5>
                                </div>
                                <div className="col-1">
                                    <img src={Flag} />
                                </div>
                            </div>
                        </div>
                        {/* <CardIconStyleToolTip 
                            bgColor="#EDF3FF" 
                            icon={Tooltip} 
                            content="We recommend that you add a nominee to your account. 
                            A nominee is someone who can receive the proceeds of your account in the event of your unexpected death.
                            The nominee can be anyone you deem to be your first relative - your parents and spouse. 
                            This is a standard precautionary measure" 
                        /> */}
                    </div>
                </div>
                {/* form */}

                <div className="row mt-3">
                    {/* <div className="col-lg-12"> */}
                    <div className="e-wrapper">
                        {/* <div className="row"> */}
                        {
                            idx?.map((item, key) => (
                                <div className='mb-3 row'>
                                    <div className='col-lg-6'>

                                    </div>
                                    <div className='col-lg-6 text-center'>
                                        {
                                            key > 0 ?
                                                <div
                                                    className='e-nominee-remove'
                                                    onClick={() => {
                                                        var temp = idx; temp.splice(key, 1);
                                                        setIdx([...temp])
                                                    }}
                                                >
                                                    <img src={Trash} />
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="col-lg-6 mt-2" key={item} >
                                        <label className="mb-1">
                                            Nominee Name
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            id={`name${key}`}
                                            name="nominee_name"
                                            label="Nominee name"
                                            defaultValue={item.name}
                                            value={item.nominee_name}
                                            valueChange={(value, e) => {
                                                handleChange(value, e, key)
                                            }}
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-lg-6 mt-lg-2 mt-md-2 mt-lg-3 mt-md-3 mt-sm-3 mt-3">
                                        <label className="mb-1">
                                            Allocation %
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            name="allocation"
                                            label="Allocation %"
                                            id={`allocation${key}`}
                                            value={item.allocation}
                                            valueChange={(value, e) => {
                                                handleChangeNumber(value, e, key)
                                            }}
                                            type="number"
                                            notDecimal={true}
                                        />
                                    </div>
                                    <div className="col-lg-6 mt-lg-3 mt-md-3 mt-sm-3 mt-3">
                                        <label className="mb-1">
                                            Nominee DOB
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <SingleDate
                                            name={"nominee_dob" + key}
                                            id={"nominee_dob" + key}
                                            value={item.nominee_dob}
                                            removeToday={true}
                                            disabledDate={(day => {
                                                if (day.isAfter(moment())) {
                                                    return true
                                                }
                                            })}
                                            placeholder="DOB (dd-mm-yyyy)"
                                            onChange={(dateString) =>
                                                handleChange(dateString,
                                                    {
                                                        "target":
                                                        {
                                                            "name": "nominee_dob",
                                                            "value": dateString
                                                        }
                                                    },
                                                    key
                                                )}
                                        />
                                    </div>
                                    <div className="col-lg-6 mt-lg-3 mt-md-3 mt-sm-3 mt-3">
                                        <label className="mb-1">
                                            Nominee Relation
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Select
                                            name="relation"
                                            defaultValue={item.relation}
                                            id={"relation" + key}
                                            value={item.relation}
                                            options={props.relationOptions}
                                            placeholder="Relation"
                                            className="e-dashboard-card-sort"
                                            selectchange={(temp, e) => {
                                                SelectChange(temp, e, key);
                                                let tmp = idx;
                                                tmp[key].relation = temp;
                                                setIdx([...tmp])
                                                clearFormValidationOther("relation" + key)
                                            }}
                                        />
                                    </div>
                                    {MinorCheck(item.nominee_dob, key)}
                                </div>
                            ))
                        }
                        {
                            idx.length < 3 ?
                                <div className='col-lg-12 pt-lg-4 pt-md-4 pt-sm-2 pt-0 mt-md-1 mt-lg-1 mb-2'>
                                    <PrimaryIconBtn name="+ Add new" handleClick={() => addField()} />
                                </div>
                                :
                                null
                        }

                        <div className="row mt-lg-3 mt-md-3 mt-sm-3 mt-3">
                            <div className="col-lg-6 col-md-6 col-sm-12 position-relative">
                                <PrimaryIconBtn
                                    name="Skip this step"
                                    className="e-skip-btn pt-lg-3 pt-md-3 pt-sm-1 pt-1"
                                    handleClick={() => { AddNominee({ is_nominee: false }) }}
                                />
                                <img
                                    src={TooltipIcon}
                                    className="e-tooltip-hover"
                                    onMouseEnter={() => setIsShownNominee(true)}
                                    onMouseLeave={() => setIsShownNominee(false)}
                                />
                                <div className='position-relative'>
                                    {
                                        isShownNominee === true && (
                                            <IconStyleToolTip
                                                className="e-tooltip e-skip-tooltip"
                                                icon={Tooltip}
                                                content="If you don't want to add a nominee you can skip this step, but we strongly suggest adding one."
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 text-end mb-3 mt-3">
                                {
                                    idx.length > 0 ?
                                        <>
                                            {
                                                window.innerWidth > 575 ? (
                                                    <>
                                                        {
                                                            _validForm() ?
                                                                (
                                                                    <PrimaryBtn
                                                                        name="Next"
                                                                        image={Arrow}
                                                                        right={!apiLoader && true}
                                                                        iconClass='ms-2'
                                                                        className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                                        handleClick={() => {
                                                                            validateNomineeForm()
                                                                        }}
                                                                    />
                                                                )
                                                                : (
                                                                    <PrimaryBtn
                                                                        name="Next"
                                                                        image={Arrow}
                                                                        right={!apiLoader && true}
                                                                        iconClass='ms-2'
                                                                        className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                                        disabled={true}
                                                                    />
                                                                )
                                                        }
                                                    </>

                                                ) : (
                                                    <>
                                                        {
                                                            _validForm() ? (
                                                                <PrimaryBtn
                                                                    name="Next"
                                                                    image={Arrow}
                                                                    right={!apiLoader && true}
                                                                    iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                                    className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                                    handleClick={() => {
                                                                        validateNomineeForm()
                                                                    }}
                                                                />
                                                            ) : (
                                                                <PrimaryBtn
                                                                    name="Next"
                                                                    image={Arrow}
                                                                    right={!apiLoader && true}
                                                                    iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                                    className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                                    disabled={true}
                                                                />
                                                            )

                                                        }
                                                    </>

                                                )
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                window.innerWidth > 575 ? (
                                                    <PrimaryBtn
                                                        name="Next"
                                                        image={Arrow}
                                                        right={!apiLoader && true}
                                                        iconClass='ms-2'
                                                        className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                        disabled={true}
                                                    />
                                                ) : (
                                                    <PrimaryBtn
                                                        name="Next"
                                                        image={Arrow}
                                                        iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                        className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                        disabled={true}
                                                    />
                                                )
                                            }


                                        </>
                                }

                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    {/* </div> */}
                </div>
            </div>
            {
                error ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

export default AddNomineeDetails;