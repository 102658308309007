
//Package import
import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput'
import { Toast } from '../../components/note';
import Trash from '../../assets/images/onboard/trash-can.png';

// service import 
import APIPost from '../../services/postApi';
import Config from '../../config/config';

import { UserDataContext } from '../../context/userData';

const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]


const GoldDetail = (props) => {

    const [loader, setLoader] = useState(null);
    const [date, setDate] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [toast, setToast] = useState(null);
    const [goldList, setGoldList] = useState([
        {
            gold_purchase_date: null,
            gold_in_grams: null,
        }
    ]);

    const [userData, setUserData] = useContext(UserDataContext);

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 3000)
        }
    }, [toast])

    const _saveGoldDetail = () => {
        setLoader(true);
        let url = Config.portfolio + '/assets/gold/add';
        let payload = JSON.stringify({
            data: goldList
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                _eventTracker();
                setLoader(false);
                props.success();
            }
            else {
                setLoader(false);
                setToast(response.message);
            }
        })
    }

    const _formValidation = () => {
        let valid = true;
        goldList.forEach(function (v, i) {
            if (
                Object.keys(v).some(function (k) {
                    return (v[k] == null || v[k] == '');
                })
            ) {
                console.log('null value present', i);
                valid = false;
            }
            else {
                console.log('data right', i);
                valid = true;
            }
        });
        return valid;
    }

    const _eventTracker = () => {
        window.analytics.track('Portfolio tracker configured', {
            user_id: userData?.user_id,
            stocks: false,
            MF: false,
            Gold: true,
            RealEstate: false,
            FD: false,
            Bond: false,
            crypto: false
        });
    }

    return (
        <div className={`row mt-4 ${styles.e_stocks} e_stocks`}>
            <div className='col-12'>
                {
                    goldList.map((item, key) => {
                        return (
                            <div key={key} className='col-12 mt-3 position-relative'>
                                <div className={key === 0 ? 'row ' : 'row mt-4'}>
                                    <div className='col-8'>
                                        <h6 className={styles.e_asset_number}>Gold</h6>
                                    </div>
                                    <div className='col-4 text-end'>
                                        {
                                            key !== 0 && (
                                                <img
                                                    src={Trash}
                                                    className={`${styles.e_form_trash}`}
                                                    onClick={() => {
                                                        let array = goldList;
                                                        array.splice(key, 1);
                                                        setGoldList([...array]);
                                                    }}
                                                />
                                            )
                                        }
                                    </div>
                                </div>

                                <div className={key === 0 ? 'row ' : 'row '}>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <label>
                                            Gold purchase date
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <SingleDate
                                            placeholder="DD-MM-YYYY"
                                            removeToday={true}
                                            disabledDate={(day => {
                                                if (day.isAfter(moment())) {
                                                    return true
                                                }
                                            })}
                                            onChange={(e) => {
                                                var tempDate = moment(e, 'YYYY-MM-DD')
                                                let array = goldList;
                                                array[key].gold_purchase_date = tempDate.format('DD-MM-YYYY');
                                                console.log('e', e)
                                                setGoldList([...array]);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mt-lg-0 m-md-0 mt-3'>
                                        <label>
                                            Grams
                                            <span className='star px-1'> * </span>
                                        </label>
                                        <Input
                                            placeholder="e.g 5.55%"
                                            type="number"
                                            value={item.gold_in_grams}
                                            valueChange={(e) => {
                                                let array = goldList;
                                                array[key].gold_in_grams = e;
                                                setGoldList([...array]);
                                            }}
                                        />
                                    </div>


                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className='col-12 mt-4'>
                <PrimaryLink
                    name="Add more"
                    className={` ${styles.e_link_btn}`}
                    handleClick={() => {
                        let array = goldList;
                        array.push(
                            {
                                gold_purchase_date: null,
                                gold_in_grams: null,
                            }
                        )
                        setGoldList([...array]);
                    }}
                />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                <SecondaryBtn
                    name="Cancel"
                    className="w-100"
                    cancel="modal"
                />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                {
                    _formValidation() ? (
                        <PrimaryBtn
                            name="Add"
                            className={loader ? 'e-btn-loader w-100' : 'w-100'}
                            handleClick={() => { _saveGoldDetail() }}
                        />
                    ) : (
                        <PrimaryBtn
                            name="Add"
                            disabled={true}
                            className={'w-100'}
                            handleClick={() => { _saveGoldDetail() }}
                        />
                    )

                }

            </div>
            {
                toast && (
                    <Toast data={toast} />
                )
            }
        </div>
    )
}

export default GoldDetail;