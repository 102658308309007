/*
 *   File : yearRangePicker.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : singleDatepicker.js
 *   Integrations : 
 *   Version : v1.0
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { DatePicker } from 'antd';

const RangePickerAnt =(props)=> {

	const { RangePicker } = DatePicker;
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  useEffect(()=>{
  	if(props.start){
      var tempDate = moment(props.start, 'DD-MM-YYYY');
      setStart(tempDate);
    }
    else{
      setStart(null);
    }
    if(props.end){
      var tempDate = moment(props.end, 'DD-MM-YYYY');
      setEnd(tempDate);
    }
    else{
      setEnd(null);
    }
  },[props.start, props.end])

  const handleChange = (date, dateString)=> {
  console.log(date);
    if(date){
      var tempStart = moment(date[0], 'DD-MM-YYYY');
      var tempEnd = moment(date[1], 'DD-MM-YYYY');
      setStart(tempStart);
      setEnd(tempEnd);
      props.onDatesChange(tempStart.format('YYYY-MM-DD'), tempEnd.format('YYYY-MM-DD'));
    }
    else{
      setStart(null);
      setEnd(null);
      props.onDatesChange(null, null);
    }
}

  return (
    <div className="e-ant-daterange-picker">
      <RangePicker onChange={handleChange} 
        format='DD-MM-YYYY'
        value={[start, end]} 
        // defaultPickerValue={ props.isDecadeBack ? moment().subtract(14, "years") : null} 
        placeholder={["DD-MM-YYYY", "DD-MM-YYYY"]}
        allowClear={true}
        disabledDate={ (day=> {if(day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true} )} 
      />

      </div>
  );
}

export default RangePickerAnt;
