/*
 *   File : allocationInput.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

/*import package*/
import { useState, useEffect } from 'react';

/*import style*/
import styles from '../../styles/components/allocationInput.module.scss';
import { Toast } from '../note';

const AllocationInput = (props) => {

  const [value, setValue] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    // if (props.defaultValue) {
    //   setValue(props.defaultValue);
    // }
  }, [])

  const valueChange = (e) => {
    // if (e.target.value <= 100) {
      if(e.target.value == " ") {
        setValue(0);
        props.valueChange(0);
      }else{
      setValue(e.target.value);
      props.valueChange(e.target.value, e);
      console.log(e.target.value);
      }
    // } else {
    //   setValue(null);
    //   props.valueChange(null);
    // }
  }

  useEffect(()=>{
    if(value > 100) {
      setError(true);
    }
    else{
      setError(false);
    }
  },[value])

  var invalidChars = [
    "-",
    "+",
    "e",
  ];

  const keyValidation = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }
  return (
    <div className={ `${styles.e_input_wrap}`}>
      <input id={props.id}
        value={props.value}
        type={props.type}
        className= { error ? `${styles.e_error_input} e-allocation-input` : `${styles.e_allocation_input} e-allocation-input`}
        onChange={(e) => { valueChange(e) }}
        placeholder={props.placeholder}
        onInput={(e) => { e.target.value = Math.abs(e.target.value) }}
        required={props.required}
        onFocus={() => setValue(props.value)}
        // onBlur={(e) => props.onBlur(e.target.value)}
        onKeyDown={(e) => keyValidation(e)}
        prefix={props.prefix}
        defaultValue={props.defaultValue}
        onWheel={() => document.activeElement.blur()}

      />
      {
        props.suffix &&
        <span className={styles.e_suffix}>{props.suffix}</span>
      }

      {/* {
        error ?
          // <p className={styles.e_error_msg+" mb-0"}>{error}</p>
          <Toast data={error} />
          :
          null
      } */}
    </div>
  );
}
export default AllocationInput;

