/*
 *   File : color.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Config file for the template
 *   Version : 1.0.0
 *   Created : 10-11-2021
 */

const red = "#de2020"

var COLOUR = {
	red : red
}


export default COLOUR;
