/*
 *   File : textarea.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Textarea
 *   Integrations :
 *   Version : 1.0
 *   Created : 06-12-2021
 */

/*import packages*/
import {useState, useEffect} from 'react';

/*import styles*/
import styles from '../../styles/components/textarea.module.scss';

const TextArea = (props) => {

  const[value,setValue]=useState();
  const[error,setError]=useState();


  useEffect(()=>{
    if(props.defaultValue){
      setValue(props.defaultValue);
    }
  },[])

  const valueChange =(e) => {
    setValue(e.target.value);
    setError(null);
    if(e.target.value == ''){
      props.valueChange(null);
    }
    else{
      props.valueChange(e.target.value);
    }
  }

  const validate = () => {
    if(props.mandatory){
      if(value && value.trim() === ""){
        setError({error: "Shoudn't allow only white space"});
        props.fail(false)
      }
    }
  }

  const keyPress = (e) =>{
    if(e.keyCode === 13 && props.searchBox){
      props.onSearch();
    }
  }

  return (
    <div className={`${styles.e_textarea_wrap} position-relative`}>
      <textarea
        id={props.id}
        value={props.value}
        // type={}
        className={error ?  `${styles.e_input_error} ${styles.e_textarea }` +" "+props.className : `${styles.e_textarea }` +" "+props.className}
        onChange={(e)=>{ valueChange(e)}}
        placeholder={props.placeholder}
        required={props.required}
        rows={props.rows}
        cols={props.cols}
        onBlur={()=>{validate();}}
        onKeyDown={e=>{keyPress(e)}}
      />
      { props.label? <label htmlFor={props.id} className="label">{props.label}</label>: null }
      {
        error ?
        <p className={styles.e_error_msg+" mb-0"}>{error}</p>
        :
        null
      }
    </div>
  );
}
export default TextArea;


