/*
 *   File : editScore.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Edit risk score popup
 *   Integrations : antd
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { message } from 'antd';

/*import componets*/
import { TextArea } from '../input/index';
import { SecondaryBtn, PrimaryBtn } from '../cta/index';

import Close from '../../assets/images/new-design/modalClose.svg';

/*Service import*/
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

export default function EditScore(props) {

	const [reason, setReason] = useState();
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState(null);
	const [successScreen, setSuccessScreen] = useState(false);

	useEffect(() => {
		var myModalEl = document.getElementById('editScore')
		myModalEl.addEventListener('hidden.bs.modal', function (event) {
			setReason('')
		})
	}, [])
	// API - Submit edit score request
	const editRequest = () => {
		setLoader(true);
		let url = devconfig.user + '/profile/edit-risk-score';
		let data = JSON.stringify({
			"reason": reason
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				setSuccessScreen(true);
				// message.success('Request for edit score submitted successfully')
				// $('#editScore').trigger('click');
			}
			else {
				setError(response.message);
				setLoader(false);
			}
		})
	}

	return (
		<div className={`${styles.e_edit_modal} modal fade`}
			id="editScore"
			tabIndex="-1"
			aria-labelledby="success"
			aria-hidden="true">

			<div className="modal-dialog modal-dialog-centered">

				{
					successScreen === true ? (
						<div className={`${styles.e_modal_content} modal-content`}>
							<div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
								<h5 className='mt-1'>Edit Request Sent</h5>
								<img
									src={Close}
									className={`${styles.e_modal_close}`}
									data-bs-dismiss="modal"
								/>
							</div>
							<div className="container p-4">
								<div className='row'>
									<div className='col-12 text-center'>
										<h6 className={`${styles.e_success_title} `}>
											We have received your Risk Score edit request
										</h6>
										<p className={`${styles.e_success_text}`}>
											We will inform you when you can edit your risk score
										</p>
									</div>
									<div className='col-12 mt-3'>
										<PrimaryBtn
											name="Okay"
											className="w-100"
											cancel="modal"
											handleClick={()=> setSuccessScreen(false)}
										/>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className={`${styles.e_modal_content} modal-content`}>
							<div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
								<h5 className='mt-1'>Edit Request</h5>
								<img
									src={Close}
									className={`${styles.e_modal_close}`}
									data-bs-dismiss="modal"
								/>
							</div>

							<div className="container p-4">
								{/* popup message */}
								<div className='row'>
									<div className='col-11'>
										<p className={`${styles.e_delete_text} `}>
											Do you really want to edit your risk score? You need admin approval to edit <br />your score. Also specify the reason you want to edit the score</p>
									</div>
								</div>


								<div className="row ">
									<div className="col-lg-12">
										<label className='mb-1'>Reason</label>
										<TextArea placeholder="Specify your reason"
											type="text"
											value={reason}
											valueChange={(value) => { setError(null); setReason(value) }} />
									</div>
									{
										error ?
											<div className='col-gl12'>
												<p style={{ color: 'red' }}>{error}</p>
											</div>
											:
											null
									}
								</div>

								<div className="row pt-lg-4 pt-md-4 mt-lg-2 mt-md-2 pb-2">
									<div className='col-lg-6 col-md-6 col-sm-12'>
										<SecondaryBtn
											name="Cancel"
											cancel="modal"
											className="w-100"
										/>
									</div>
									<div className='col-lg-6 col-md-6 col-sm-12 mt-4 mt-lg-0 t-md-0'>
										{
											reason ?
												<PrimaryBtn name={loader ? "Requesting" : "Send Request"}
													handleClick={() => editRequest()}
													className={loader ? `w-100 e-btn-loader` : `w-100`} />
												:
												<PrimaryBtn name="Send Request"
													disabled={true}
													className={`w-100`} />
										}
									</div>

								</div>
							</div>
						</div>
					)
				}

			</div>
		</div>
	)
}
