/*
 *   File : invest.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Invest Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*import packages*/
import React, { useEffect, useState, } from 'react';
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';
/*import styles*/
import styles from '../../styles/components/success.module.scss';
import style from '../../styles/components/loader.module.scss';

import ProgressBar from '../loader/progressLoader';

/*import assets */
import Success from '../../assets/images/modal/success.svg';
import LoaderImg from '../../assets/images/loader/mintd-loader.gif';
import { PrimaryBtn } from '../cta';
import { Modal } from 'bootstrap';

export default function SuccessModal(props) {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);
  const [minute, setMinute] = useState(1);
  const [second, setSecond] = useState(60);

  // useEffect(() => {
  //   setLoader(false)
  //   setTimeout(() => {
  //     if (!props.type) {
  //       // setLoader(true);
  //     }
  //   }, 2000)
  // }, [props.type])


  useEffect(() => {
    if (props.type) {
      setNewSecond();
    }
  }, [second, props.type]);

  // useEffect(() => {
  //   if (second === 0) {
  //     setTimeout(() => {
  //       props.loader(false);
  //       $("#success").trigger("click")
  //     }, 2000)
  //   }
  // }, [second])

  const setNewSecond = () => {
    // let Timer;
    if (minute === 0 && second === 0) {
      setLoader(true);
      setTimeout(() => {
        props.loader(false);
        $("#success").hide()
        $(".fade").removeClass("modal-backdrop")
      }, 1000)
    } else {
      setTimeout(() => {
        if (second > 0) {
          setSecond(second - 1)
        }
        else {
          setSecond(60);
          setMinute(0);
        }
      }, 1000)
    }

  }




  return (
    <div className={`${styles.e_success_modal} modal fade`} id="success" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="successLabel" aria-hidden="true" >
      <div className="modal-dialog modal-dialog-centered ">
        <div className={`${styles.e_content} modal-content position-relative`} style={{ height: window.innerWidth > 575 ? '400px' : '325px' }}>
          <div className='modal-body' >
            <div className="container px-3">

              {
                loader === false ?
                  <div className={`${style.e_loader} ${props.className} col-lg-12  text-center`} >
                    <div className={style.e_img_wrapper}>
                      <ProgressBar complete={loader} loader={Number(second) < 60 ? true : false} margin="25% 0 0% 2%" />
                      <p>{second < 10 ? `Video verification will complete in 0${minute} : 0${second}` : `Video verification will complete in 0${minute} : ${second}`}</p>
                      {/* <p>{`Please wait for 00:0${minute}: ${second}`} </p> */}
                    </div>
                  </div>
                  :
                  <>
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <img src={props.img} className={`${styles.e_success_icon}`} style={{ width: '80px', height: '80px' }} />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-12 text-center">
                        <p>{props.data}</p>
                        {/* <p></p> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >hi</button> */}
                        {/* <PrimaryBtn name="Proceed to e-sign KYC form" handleClick={() => {props.loader(false); $("#success").trigger("click")}}/> */}
                      </div>
                    </div>
                  </>
              }
            </div>
          </div>
        </div>


      </div>
    </div>

  )
}



