// import package
import { useEffect, useState } from 'react';
import $ from 'jquery';

// import assets
import TooltipIcon from '../../assets/images/tooltip/icon.svg';
import Tooltip from '../../assets/images/tooltip/icon-blue.svg';
import Arrow from '../../assets/images/onboard/submit.svg';
import UploadIcon from '../../assets/images/new-design/upload.svg';
import LoaderImage from '../../assets/images/onboard/onboardLoader.gif';

// import component
import { IconStyleToolTip, CardIconStyleToolTip } from '../../components/tooltip/index';
import { PrimaryBtn } from '../../components/cta';
import { Select } from '../../components/input/index';
import Uploader from '../../components/fileUploader/upload';
import { Toast } from "../../components/note";
import Input from '../../components/input/loginInput';

// import service
import COLOUR from '../../config/color';
import Config from '../../config/config';
import APIPost from '../../services/postApi';

const AddBankDetails = (props) => {
    const [toggleBankInfoPopup, setToggleBankInfoPopup] = useState(false);
    const [acctNumber, setAccountNumber] = useState(null);
    const [name, setName] = useState(null);
    const [ifsc, setIfsc] = useState(null);
    const [selected, setSelected] = useState(null);
    const [KYCVerified, setKYCVerified] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileUrl, setFIleUrl] = useState(null);
    const [apiLoader, setApiLoader] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (props.progressData) {
            setKYCVerified(props.progressData.is_kyc_complaint);
            let bank_detail_data = props.progressData.bank_detail_data;
            if (bank_detail_data) {

                setAccountNumber(bank_detail_data.account_number);
                setName(bank_detail_data.account_holder_name);
                if (bank_detail_data.ifsc_code) {
                    setIfsc(bank_detail_data.ifsc_code.toUpperCase());
                }
                setSelected({ value: bank_detail_data.account_type_id, label: bank_detail_data.account_type_name });
                if (bank_detail_data.bank_proof) {
                    var str = bank_detail_data.bank_proof.split("/"); setFileName(str[str.length - 1]);
                    setFIleUrl(bank_detail_data.bank_proof);
                }
            }
        }
    }, [props.progressData])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 3000)
        }
    }, [error])

    /* validation to highlight fields */
    const showFormValidationError = (field) => {
        var ele = document.getElementById(field);
        ele.style.border = `solid 1.5px ${COLOUR.red}`
        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    /* clearing validation fields */
    const clearFormValidationError = (field) => {
        console.log(field)
        var ele = document.getElementById(field);
        if (ele) {
            ele.style.border = null;
        }
    }

    /* validation for select */
    const showFormValidationOther = (field) => {
        const ele = document.getElementById(field);
        ele.className = "e-select-error"

        ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }


    /* clearing validation for select */
    const clearFormValidationOther = (field) => {
        var ele = document.getElementById(field);
        if (ele) {
            ele.className = "e-select-wraper";
            console.log(ele)
        }
    }

    const _validateForm = () => {
        if (acctNumber.length < 9) {
            setError("Enter valid Account number");
            showFormValidationError("accountNo");
            return false;
        } else if (name.length < 2) {
            setError("Account holder name cannot be blank ");
            showFormValidationError("holderName");
            return false;
        } else if (ifsc.length < 11) {
            setError("IFSC code must be 11 digits containing Alpha numeric characters");
            showFormValidationError("ifsc");
            return false;
        } else if (selected.value === null) {
            setError("Select an account type");
            showFormValidationOther("accountType");
            return false;
        } else if (fileUrl === "" && !KYCVerified) {
            setError("Please upload three months bank statement");
            showFormValidationError("bankStatement");
            return false;
        }
        return true;
    }

    // submitBank details
    const _submitBankData = () => {
        let status = _validateForm();
        if (status) {
            setApiLoader(true);
            let url = Config.user + '/auth/add-bankdetails';
            let data = JSON.stringify({
                account_number: acctNumber,
                account_holder: name,
                ifsc_code: ifsc,
                account_type: selected.value,
                proof: fileUrl
            })
            APIPost(url, data).then((response) => {
                if (response.status_code === 200) {
                    props.updateUserData('bank_detail_data', response.data.bank_account_data);
                    props.success(response.data.signup_status);
                    setApiLoader(false);
                } else {
                    setApiLoader(false);
                    setError(response.message)
                }
            })
        }
    }

    const _validForm = () => {
        let valid = false
        if (KYCVerified === false) {
            if (acctNumber && name && ifsc && selected && fileName) {
                valid = true
            } else {
                valid = false
            }
        } else {
            if (acctNumber && name && ifsc && selected) {
                valid = true
            } else {
                valid = false
            }
        }
        return valid
    }
    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-12 e-mobile-confirm-wrap pb-5 mb-5">
            <div className="e-process-wrap position-relative">
                <h5 className="e-onboard-form-title mt-1">
                    Bank Details
                </h5>
                {/* form */}
                <div className="row mt-md-4 mt-lg-4 mt-sm-3 mt-3">
                    <div className="col-lg-12">
                        <div className="e-wrapper">
                            <div className="row">
                                <div className="col-lg-6">
                                    <label className="mb-1">
                                        Account Number
                                        <span className='star px-1'> * </span>
                                        {/* <img 
                                            src={TooltipIcon} 
                                            className="e-tooltip-hover ms-0" 
                                            onMouseEnter={() => setToggleBankInfoPopup(true)}
                                            onMouseLeave={() => setToggleBankInfoPopup(false)} 
                                        /> */}
                                    </label>
                                    {
                                        toggleBankInfoPopup ?
                                            <IconStyleToolTip
                                                className="e-tooltip-account"
                                                icon={Tooltip}
                                                content="We neither store your details nor have access to them on the platform. 
                                                    These details are solely so that you can transact directly with the Asset Management 
                                                    Company (AMC) that sells you the mutual funds. Funds go directly from your account to theirs, 
                                                    and the mutual funds are always in your name only. Our platform is completely secure "
                                            />
                                            :
                                            null
                                    }
                                    <Input
                                        id="accountNo"
                                        label="Account number"
                                        value={parseInt(acctNumber)}
                                        valueChange={(value) => {
                                            setAccountNumber(value)
                                        }}
                                        type="number"
                                    />
                                </div>
                                <div className="col-lg-6 mt-lg-0 mt-md-0 mt-3">
                                    <label className='mb-1'>
                                        A/C Holder Name
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Input
                                        id="holderName"
                                        label="Account holder name"
                                        value={name}
                                        valueChange={(value) => {
                                            setName(value)
                                        }}
                                        type="text"
                                    />
                                </div>
                                <div className="col-lg-6 col-12 mt-3">
                                    <label className='mb-1'>
                                        IFSC Code
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Input
                                        id="ifsc"
                                        label="IFSC code"
                                        value={ifsc}
                                        isCapital={true}
                                        valueChange={(value) => {
                                            setIfsc(value);
                                        }}
                                    />
                                </div>
                                <div className="col-lg-6 col-12 mt-3">
                                    <label className='mb-1'>
                                        A/C Type
                                        <span className='star px-1'> * </span>
                                    </label>
                                    <Select
                                        id="accountType"
                                        options={props.acOptions}
                                        placeholder="Select"
                                        value={selected}
                                        className="e-dashboard-card-sort"
                                        selectchange={(temp) => {
                                            setSelected(temp);
                                            clearFormValidationOther("accountType")
                                        }}
                                    />
                                </div>
                                {
                                    KYCVerified === false ?
                                        <>
                                            <div className='col-12 mt-4'>
                                                <label className="" style={{ fontSize: '12px' }}>
                                                    Upload a cancelled check or three months of bank statements
                                                    (please merge the statements into a single file)
                                                    <span className='star px-1'> * </span>
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">

                                                <div
                                                    id="bankStatement"
                                                    className="e-uploader text-center mt-2"
                                                    onClick={() => {
                                                        $('#e-upload').trigger('click');
                                                        clearFormValidationError("bankStatement");
                                                    }}
                                                >
                                                    <span className="e-upload-span text-center" >
                                                        <h6>Choose file</h6>
                                                        <button>
                                                            <img src={UploadIcon}
                                                                className="me-2"
                                                            />
                                                            {
                                                                uploading ?
                                                                    'Uploading'
                                                                    :
                                                                    'Click to upload'
                                                            }

                                                        </button>
                                                        <span className="e-note mt-2 px-4">(supported formats PDF, PNG, JPEG and max-size 5mb)</span>
                                                    </span>
                                                    <Uploader
                                                        tempKey="files"
                                                        className="hidden"
                                                        id="e-upload"
                                                        uploadSucess={(temp) => {
                                                            var str = temp.split("/"); setFileName(str[str.length - 1]);
                                                            setFIleUrl(temp);
                                                            setUploading(false);
                                                        }}
                                                        uploadStatus={(temp) => setUploading(temp)}
                                                    />
                                                </div>
                                                {fileName && (
                                                    <div className='col-lg-12  mt-3 '>
                                                        <h6 className='e-file-preview-title'>Uploaded successfully</h6>
                                                        <div className='e-boarding-file-preview'>
                                                            <p className='p-2'>{fileName}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </>
                                        :
                                        null
                                }
                                <div className='col-lg-10 e-penny-note'>
                                    <p className='e-bank-note'>
                                        <span>Note: </span>
                                        We will verify your account by sending you Rs. 1.
                                        This is known as a penny drop verification.
                                    </p>
                                </div>
                                <div className="col-lg-12  mb-3 mt-3">
                                    {
                                        window.innerWidth > 575 ?
                                            (
                                                <>
                                                    {
                                                        _validForm() ?
                                                            (
                                                                <PrimaryBtn
                                                                    name="Next"
                                                                    image={Arrow}
                                                                    right={!apiLoader && true}
                                                                    iconClass='ms-2'
                                                                    className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                                    handleClick={() => {
                                                                        _submitBankData()
                                                                    }}
                                                                />
                                                            ) : (
                                                                <PrimaryBtn
                                                                    name="Next"
                                                                    image={Arrow}
                                                                    right={!apiLoader && true}
                                                                    iconClass='ms-2'
                                                                    className={apiLoader ? "e-submit-btn e-btn-loader " : 'e-submit-btn'}
                                                                    disabled={true}
                                                                />
                                                            )
                                                    }
                                                </>

                                            ) : (
                                                <>
                                                    {
                                                        _validForm() ? (
                                                            <PrimaryBtn
                                                                name="Next"
                                                                image={Arrow}
                                                                right={!apiLoader && true}
                                                                iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                                className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                                handleClick={() => {
                                                                    _submitBankData()
                                                                }}
                                                            />
                                                        ) : (
                                                            <PrimaryBtn
                                                                name="Next"
                                                                image={Arrow}
                                                                right={!apiLoader && true}
                                                                iconClass={apiLoader ? " e-hide-arrow ms-2" : 'ms-2'}
                                                                className={apiLoader ? "e-onboard-next e-btn-loader " : 'e-onboard-next'}
                                                                disabled={true}
                                                            />
                                                        )
                                                    }
                                                </>

                                            )
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}


export default AddBankDetails;