/*
 *   File : headerOuterFull.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Login Header
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom'

/*import components */
import { HeaderDropdownMenu, NotificationMenu } from '../menu/index';

/*import styles*/
import styles from '../../styles/components/headerInner.module.scss';

/*import assets */
import Notification from '../../assets/images/menu/notification.svg';
import ActiveBell from '../../assets/images/bell-active.svg';

/*import services */
import APIGet from '../../services/getApi';
import devconfig from '../../config/config';
const HeaderInner = (props) => {
  const [headerDropdown, setHeaderDropdown] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [error, setError] = useState(null);
  const [location, setLocation] = useState();
  const current = useLocation();
  const wrapperRef = useRef(null);
  const [openNotification, setOpenNotification] = useState(false);
  const notificationRef = useRef(null);
  const [notificationData, setNotificationData] = useState();
  let navigator = useNavigate();
  
  const closeMenu = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setHeaderDropdown(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mouseup', closeMenu);
    return () => {
      document.removeEventListener('mouseup', closeMenu);
    };
  }, []);
  const hideNotification = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setOpenNotification(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mouseup', hideNotification);
    return () => {
      document.removeEventListener('mouseup', hideNotification);
    };
  }, []);

  useEffect(() => {
    if (props.reload) getProfile();
  }, [props.reload])

  useEffect(() => {
    setLocation(current)
  }, [current]);

  useEffect(() => {
    getProfile();
  }, []);

  // notification list



  /*------------get profile details api ---*/
  const getProfile = () => {
    let url = devconfig.user + '/profile/get';

    APIGet(url).then((response) => {
      if (response.status_code === 200) {
        setProfileData(response.data)
        props?.profileData(response.data)
      }
      else {
        setError(response.message)
      }
    })
  }
  const _openModal =() => {
    props.openWithdrawModal();
  }

  return (
    <div className={`${styles.e_headerInner} e-header-inner`}>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-8 col-3 d-flex">
          <Link to="/overview">
            <img src={props.logo} alt="Mintd Logo" className={`${styles.e_logo}`} />
          </Link>
          <div className='e-laptop-view '>
            <Link to="/overview" className={current.pathname == "/overview" || current.pathname == "/" ? `${styles.e_inner_header_link_active} ` : `${styles.e_inner_header_link} `}>
              Overview
            </Link>

            <Link to="/assets" className={current.pathname == "/assets" || current.pathname == "/scrip-detail" ? `${styles.e_inner_header_link_active} ` : `${styles.e_inner_header_link}`}>
              Assets
            </Link>

            <span className='position-relative'>
              <Link to="/portfolio-tracker" className={current.pathname == "/portfolio-tracker" || current.pathname == "/allocation" ? `${styles.e_inner_header_link_active} ` : `${styles.e_inner_header_link}`}>
                Portfolio Tracker
              </Link>
              <span className='position-absolute e-new-badge'>
                New
              </span>
            </span>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-9 text-end">
          <span className={`${styles.e_notification_wrap} me-4`} onClick={() => navigator('/notifications')}><img src={current.pathname == '/notifications' ? ActiveBell : Notification} alt="Notification" /></span>
          {/* {
            openNotification ?
              <div ref={notificationRef} className={`position-absolute`}>
                <NotificationMenu data={notificationData} />
              </div>
              :
              null
          } */}
          <Link to="/profile">
            <span className={`${styles.e_name_wrap}`}>{Object.keys(profileData).length > 0 ? profileData?.first_name?.charAt(0) : '-'}</span>
          </Link>
          <span className={`${styles.e_profile_name}`} onClick={() => setHeaderDropdown(!headerDropdown)}>{Object.keys(profileData).length > 0 ? profileData?.first_name + " " + profileData?.last_name?.charAt(0) : '--'}</span>

        </div>
        {
          headerDropdown ?
            <div ref={wrapperRef}>
              <HeaderDropdownMenu
                openWithdrawModal={()=> _openModal()}
                 profileData={profileData} 
              />
            </div>
            :
            null
        }

      </div>
    </div>

  )
}

export default HeaderInner;

