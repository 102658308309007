/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { TextArea } from '../input/index';
import PhoneInput from '../../components/input/loginPhone';
import Input from '../../components/input/loginInput';
import { Toast } from '../note';
import { SecondaryBtn, PrimaryBtn, PrimaryLink } from '../cta/index';


/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/* import assets */
import Delete from '../../assets/images/modal/delete.svg';
import Close from '../../assets/images/new-design/modalClose.svg';

// import Service
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';

export default function Phone(props) {

	const [phone, setPhone] = useState("");
	const [countryCode, setCountryCode] = useState("");
	const [otpVerified, setOTPVerified] = useState(false);
	const [otp, setOTP] = useState(null);
	const [resend, setResend] = useState(null);
	const [apiLoader, setAPILoader] = useState(false);
	const [error, setError] = useState(null);
	const [phoneStatusClass, setPhoneStatusClass] = useState(null);
	const [phoneResponseStatus, setPhoneResponseStatus] = useState(null);
	const [number, setNumber] = useState(null);
	const [OTPValidationSuccess, setOTPValidationSuccess] = useState(false);
	const [OTPValidationFail, setOTPValidationFail] = useState(false);
	const [otpResponseStatus, setOTPResponseStatus] = useState(null);
	const [timer, setTimer] = useState(null);
	const [resendLoader, setResendLoader] = useState(false);
	//   useEffect(() => {
	//     setPhone(props.phone.mobile);
	//     setCountryCode(props.phone.country_code)
	//   }, [props.phone])

	useEffect(()=>{
		setTimeout(()=>{
			setError(null);
		},5000)
	},[error])

	const getOTP = (type = null) => {
		setAPILoader(true)
		let url = devconfig.user + "/profile/update-phone";
		let data = JSON.stringify({
			phone_number: phone,
			type: type
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setOTPVerified(true);
				setAPILoader(false);
				setTimer(20);
			} else {
				setAPILoader(false);
				setError(response.message);
			}
		})
	}

	useEffect(() => {
		setNewCount();
	}, [timer])
	
	  const setNewCount = () => {
		setTimeout(() => {
		  if (timer > 0) {
			setTimer(timer - 1)
		  }
		}, 1000)
	  }


	const verifyOTP = () => {
		setAPILoader(true)
		let url = devconfig.user + '/profile/verify-phone';
		let data = JSON.stringify({
			phone_number: phone,
			otp: otp
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				props.success(phone);
				setAPILoader(false);
				$('#phone').trigger('click');
			}
			else {
				setAPILoader(false);
				setError(response.message);
			}
		})
	}

	useEffect(() => {
		let modal = document.getElementById('phone');
		modal.addEventListener('hidden.bs.modal', () => {
			props.modalClose();
			setOTPVerified(false);
		})
	}, [])

	return (
		<div className={`${styles.e_edit_modal} modal fade`} id="phone" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
						<h5 className='mt-1'>Change Phone Number</h5>
						<img
							src={Close}
							className={`${styles.e_modal_close}`}
							data-bs-dismiss="modal"
						/>
					</div>
					<div className="container p-4">
						<div className="row " >
							<div className='col-lg-12'>
								<p className={styles.e_description}>Enter your new phone number</p>
							</div>
							<div className="col-lg-8">
								<label className='mb-1'>New Phone Number</label>
								<PhoneInput
									value={phone}
									valueChange={(value) => { setPhone(value); setError(null) }}
									type="text"
									readOnly={otpVerified}
									errorClass={phoneStatusClass}
									message={
										phoneResponseStatus ?
											phoneResponseStatus?.message
											:
											'You will receive an OTP on this number'
									}
									messageType={phoneResponseStatus && phoneResponseStatus?.type}
									country={(val) => { }}
									number={(val) => setNumber(val)}
									callBack={(value) => {
										if (number && number.length !== 10) {
											console.log('inside', value)
											setPhoneStatusClass('e-phone-fail');
											setPhoneResponseStatus({ type: 'fail', message: 'Invalid Number' })
										} else if (number && number.length == 10) {
											setPhoneResponseStatus(null)
										}
									}}
								/>
							</div>
							<>
								<div className="col-5 e-otp-wrapper mt-3"
									style={{height: otpVerified === false ? '0px' : '120px'}}
								>
									<label className='mb-1'>Mobile OTP</label>
									<Input 
										value={otp} 
										valueChange={(value) => { setOTP(value) }} 
										type="number" 
										width="40%"
										success={OTPValidationSuccess}
										message={otpResponseStatus?.message}
										messageType={otpResponseStatus?.type}
										needFocusEvent={true}
										focusIn={()=> {
											setOTPResponseStatus(null);
											setOTPValidationFail(false)
										}}
									/>
									<div className='d-flex justify-content-between e-timer-wrapper mt-1'>
										<>
											<span className='e-otp-timer'>00 : {timer < 10 ? "0" + timer : timer}</span>
											<PrimaryLink
												className={ resendLoader ? "e-btn-loader e-otp-resend" : 'e-otp-resend'}
												name={ resendLoader ? "Sending" : "Resend OTP"}
												disabled={timer > 0 ? true : false}
												type="button"
												handleClick={(e)=>{
													getOTP('RESEND');
												}}
											/>
										</>
									</div>
								</div>
							</>
						</div>
						{
							otpVerified === false ? (
								<div className={`row pb-2 pt-3 ${styles.e_otp_wrapper}`}>
									<div className="col-lg-6 col-md-6 col-sm-12 ">
										<SecondaryBtn
											name="Cancel"
											cancel="modal"
											className="w-100"
										/>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12  mt-lg-0 mt-md-0 mt-4">
										<PrimaryBtn
											name={apiLoader ? "Loading" : "Next"}
											className={apiLoader ? 'e-btn-loader w-100' : 'w-100'}
											disabled={ number && 	 number.length === 10 ? false : true}
											handleClick={() => getOTP()}
										/>
									</div>
								</div>
							) :
								(
									<div className="row pb-2 pt-3">
										<div className="col-lg-6 col-md-6 col-sm-12 ">
											<SecondaryBtn
												name="Cancel"
												cancel="modal"
												className="w-100"
											/>
										</div>
										<div className='col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-4'>
											<PrimaryBtn
												name={apiLoader ? "Updating" : "Update"}
												className={apiLoader ? 'e-btn-loader w-100' : 'w-100'}
												disabled={number.length === 10 && otp  ? false : true}
												handleClick={() => verifyOTP()}
											/>
										</div>
								</div>
								)
						}

					</div>
				</div>
			</div>
			{
				error && (
					<Toast data={error}/>
				)
			}
		</div>
  )
}
