import { useEffect, useState } from 'react';
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

import Arrow from '../../assets/images/new-design/arrow.svg';
import Info from '../../assets/images/new-design/info.svg';

// Component import
import { PrimaryIconBtn } from "../../components/cta";
import { Loader } from '../../components/loader';
import { Radiometrics } from '../../components/input';
import { Toast } from '../../components/note';
import LoaderModal from '../../components/modal/loaderModal';
import { ScoreExampleModal } from '../../components/modal';

// Service import 
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import Config from '../../config/config';

const Questionnaire = (props) => {
    const [questionData, setQuestionData] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [current, setCurrent] = useState(0);
    const [apiLoader, setApiLoader] = useState(true);
    const [error, setError] = useState(null);
    const [base, setBase] = useState(0);
    const [renderLoaderModal, setRenderLoaderModal] = useState(false);
    const [toolTip, setTooltip] = useState(false);

    const navigator = useNavigate();


    useEffect(() => {
        getQuestions()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setError(null)
        }, 5000)
    }, [error])

    const getQuestions = () => {
        let url = Config.user + '/quiz/get-questions';
        APIGet(url).then(async (response) => {
            if (response?.status_code === 200) {
                setQuestionData(response.data);
                setPercentage(100 / response.data.length);
                setBase(100 / response.data.length)
                setApiLoader(false);
            }
            else {
                setApiLoader(false);
                // setError(response?.message)
            }
        })
        setTimeout(() => {
            //   setError(null)
        }, 5000)
    }

    const _saveAnswer = (qus, ans) => {
        let url = Config.user + '/quiz/save-answer';
        let data = JSON.stringify({
            question_id: qus,
            answer_id: ans
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                console.log('current+!', current + 1);
                if (current <= questionData.length) {
                    setCurrent(current + 1);
                    setPercentage(base * (current === 0 ? 2 : current + 1));
                }

            }
            else {
                setError(response.message)
            }
        })
    }

    const skipAnalysis = (e) => {
        e.preventDefault();
        let url = Config.user + '/quiz/skip-riskanalysis';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setRenderLoaderModal(true)
                // setCurrent(allQuestions.length); setPercent(basePercentage * allQuestions.length);
            } else {
                setError(response.message);
            }
        })
        setTimeout(() => {
            setError(null);
        }, 5000)
    }

    useEffect(() => {
        if (renderLoaderModal) {
            const modal = new Modal(document.getElementById("loader-modal"), {})
            modal.show();
        }
    }, [renderLoaderModal])

    const _success = () => {
        props.success()
    }

    return (
        <>
            {
                apiLoader ? (
                    <Loader removeTag={true} height="200px" />
                )
                    : (
                        <div className="e-onboard-step-section ">

                            {/* Progress bar */}
                            <div className="e-step-progress" style={{ width: `${percentage}%` }}></div>

                            {/* Title section */}
                            <div className="row px-lg-5 px-3 pt-lg-3 mt-lg-3 mt-0 ms-lg-2 ms-0 pt-0 pb-3">
                                <div className="col-lg-10 col-md-10 col-sm-12 col-12 order-lg-1 order-md-1 order-sm-2 order-2 gx-lg-3 gx-0">
                                    <h4 className="e-step-title">
                                        {
                                            current !== questionData.length - 1 ?
                                                'Personalized risk analysis questionnaire'
                                                :
                                                'Personal Score'
                                        }

                                    </h4>
                                    <p className="e-desc">
                                        {
                                            current !== questionData.length - 1 ?
                                                'The questionnaire below is used to determine your risk tolerance score on a scale of 0.5 to 10. We use that score to assign you the perfect portfolio suited to your specific needs'
                                                :
                                                'Now on a scale of 0-10, describe'
                                        }

                                    </p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-12 order-lg-2 order-md-2 order-sm-1 order-1 text-lg-end text-md-end gx-lg-5 gx-0">
                                    <span className="e-step-indicator">STEP 1/4</span>
                                </div>
                            </div>

                            {/* Quiz section */}
                            <div className="row px-lg-5 px-2 pb-5 pb-lg-0  ms-lg-2 ms-0">
                                <div className="col-12">
                                    <h5 className="e-quiz-title pb-3">
                                        /{questionData[current].topic_name}
                                    </h5>

                                    {
                                        questionData.length && (
                                            <div className="e-quiz">
                                                <label className='pe-5'>{questionData[current].question}</label>
                                                {
                                                    questionData[current].question_type === 2 && (
                                                        <>{
                                                            questionData[current].answers?.map((item, key) => {
                                                                return (
                                                                    <div className={
                                                                        questionData[current].answer === item.answer_id ?
                                                                            "e-quiz-radio-wrapper e-current-answer mt-3"
                                                                            :
                                                                            "e-quiz-radio-wrapper mt-3"
                                                                    }
                                                                        onClick={() => {
                                                                            $('#e-input-' + key + current).trigger('click');
                                                                            _saveAnswer(questionData[current].question_id, item.answer_id)
                                                                            let temp = questionData;
                                                                            temp[current].answer = item.answer_id;
                                                                            setQuestionData([...temp])
                                                                        }}
                                                                    >
                                                                        <div className="e-option">{item.answer}</div>
                                                                        <input
                                                                            id={"e-input-" + key + current}
                                                                            // onChange={() => { _success() }}
                                                                            type="radio"
                                                                            name="loan"
                                                                            className="e-quiz-radio"
                                                                            checked={questionData[current].answer === item.answer_id ? true : false}
                                                                        // disabled={props.disabled}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        </>
                                                    )
                                                }
                                                {
                                                    questionData[current].question_type === 4 && (

                                                        <Radiometrics
                                                            value={questionData[current].answers}
                                                            selected={questionData[current].answer}
                                                            // userData={userData.quiz_data[current - 1]}
                                                            // data={data.topic_id}
                                                            question={questionData[current].question_id}
                                                            checked={(temp) => { }}
                                                            success={() =>
                                                                setRenderLoaderModal(true)
                                                            }
                                                        />
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        current !== questionData.length - 1 && (
                                            <>
                                                <button className="e-onboard-back-btn mt-4" onClick={() => {
                                                    if (current !== 0) {
                                                        setCurrent(current - 1)
                                                    } else {
                                                        navigator('/home')
                                                    }

                                                }}>
                                                    <img src={Arrow} className="me-2" />
                                                    Back
                                                </button>
                                                <div className="col-12 mt-4">
                                                    <div className='d-flex position-relative' style={{ width: 'max-content' }}>
                                                        <PrimaryIconBtn
                                                            name="Skip risk analysis"
                                                            handleClick={(e) => skipAnalysis(e)}
                                                        />
                                                        <img
                                                            src={Info}
                                                            onMouseEnter={() => {
                                                                setTooltip(true);
                                                            }}
                                                            onMouseLeave={() => {
                                                                setTooltip(false);
                                                            }}
                                                        />
                                                        {
                                                            toolTip && (
                                                                <div className="e-tool-tip p-3" style={{ top: '18px' }}>
                                                                    If you already have a good sense of your risk tolerance, skip the quiz and head to portfolio section.
                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }


                                </div>
                            </div>
                        </div>
                    )
            }
            {
                renderLoaderModal && (
                    <LoaderModal
                        modalClose={() => {
                            setRenderLoaderModal(false);
                            _success();
                        }}
                    />
                )
            }
            {
                error && (
                    <Toast data={error} />
                )
            }
            <ScoreExampleModal />
        </>

    )
}

export default Questionnaire