
//Package import 
import { useState, useEffect } from 'react';
import moment from 'moment';
import $ from 'jquery';

//Asset import 
import Tradebook from '../../assets/images/tradebook.svg';
import UploadIcon from '../../assets/images/uploadIcon.svg';
import FileIcon from '../../assets/images/fileIcon.svg';
import Zerodha from '../../assets/images/Zerodha.svg';
import Upstocks from '../../assets/images/Upstocks.svg';
import Arrow from '../../assets/images/PrimaryArrow.svg';
import Close from '../../assets/images/modal/close.svg';
import Trash from '../../assets/images/onboard/trash-can.png';
import SpinnerMinus from '../../assets/images/new-design/spinner-minus.svg';
import SpinnerPlus from '../../assets/images/new-design/spinner-plus.svg';

//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput';

import Uploader from '../../components/fileUploader/upload';
import { Toast, SuccessToast } from '../../components/note';

//service import 
import APIPost from '../../services/postApi';
import Config from '../../config/config';
import Nodata from '../../components/note/nodata-placeholder';

const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]



const MutualFundList = (props) => {
    const [loader, setLoader] = useState(false);
    const [searchDetail, setSearchDetail] = useState([]);
    const [openSearchPanel, setOpenSearchPanel] = useState(false);
    const [selectedFund, setSelectedFund] = useState(null);
    const [toast, setToast] = useState(null)
    const [mfList, setMfList] = useState([]);
    const [error, setError] = useState(false)
    const [mfName, setMfName] = useState(null);
    const [removing, setRemoving] = useState(false);

    useEffect(() => {
        if (props.data) {
            setMfList(props.data)
        }
    }, [props.data])

    useEffect(() => {
        if (mfName) {
            _mfSearch(mfName)
        }
    }, [mfName])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null)
            }, 3000)
        }
    }, [toast])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])


    const _updateMfList = (item) => {
        console.log('data', mfList);
        let url = Config.portfolio + '/assets/mf/update';
        let payload = JSON.stringify({
            mf_id: item.id,
            scheme_code: item.scheme_code,
            isin: item.isin,
            type_id: 7,
            mutual_fund_name: item.mf_name,
            purchase_date: item.purchase_date,
            quantity: item.quantity,
            nav: item.current_nav
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setToast('Successfully updated');
                props.success();
            }
            else {
                // setManual(false);
                setLoader(false);
                setError(response.message);
            }
        })
    }

    const _removeMf = (id, index) => {
        setRemoving(true);
        let url = Config.portfolio + '/assets/mf/delete';
        let payload = JSON.stringify({
            request_id: id
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setToast("Successfully removed");
                setRemoving(false);
                let array = mfList;
                array.splice(index, 1);
                setMfList([...array])
            }
            else {
                setError(response.message);
                setRemoving(false);
            }
        })
    }

    const _mfSearch = (text) => {

        let url = Config.portfolio + '/assets/general/mf-search';
        let payload = JSON.stringify({
            search_text: text
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setSearchDetail(response.data);

            }
            else {
                setSearchDetail([])
            }
        })
    }

    const _kiteLogin = () => {
        window.location.href = Config.KiteLoginUrl;
    }

    return (
        <div className={`row mt-4 ${styles.e_stocks} e_stocks`}>
            <div className='col-12 '>
                <h5 className={`${styles.e_form_name}`}>
                    /Editing
                </h5>
            </div>
            {
                mfList && (
                    <div className='col-12 mt-3'>
                        <div className='col-12'>
                            <label>
                                Mutual Fund Name
                            </label>
                            <Input
                                value={mfList.mf_name}
                                placeholder="Search stock"
                                needFocusEvent={true}
                                className={`${styles.e_search}`}
                                focusIn={() => {
                                    let temp = mfList;
                                    temp.mf_name = '';
                                    setMfList({ ...temp });
                                    setOpenSearchPanel(true);
                                }}
                                valueChange={(e) => {
                                    setMfName(e);
                                    let temp = mfList;
                                    temp.mf_name = e;
                                    setMfList({ ...temp });
                                }}
                            />
                            {
                                openSearchPanel === true && searchDetail.length ? (
                                    <div className='e-mf-search-panel' >
                                        {
                                            searchDetail && searchDetail.map((mf, i) => {
                                                return (
                                                    <div className='row border-bottom py-2' key={i}>
                                                        <div className={i === 0 ? 'col-9' : 'col-9 mt-2'}>
                                                            {mf.scheme_name}
                                                        </div>
                                                        <div className='col-3'>
                                                            <SecondaryBtn
                                                                name="Add"
                                                                className="e-mf-add-btn"
                                                                handleClick={() => {
                                                                    setSelectedFund(mf);
                                                                    // let array = mfList;
                                                                    let temp = mfList;
                                                                    temp.mf_name = mf.scheme_name;
                                                                    temp.scheme_code = mf.scheme_code;
                                                                    temp.isin = mf.isin_dividend_payout_or_growth;
                                                                    setMfList({ ...temp });
                                                                    setOpenSearchPanel(false);
                                                                    setSearchDetail([])
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                                    : null
                            }
                        </div>
                        <div className='col-6 mt-4 pt-2'>
                            <label>
                                Purchase date
                            </label>
                            <SingleDate
                                placeholder="DD-MM-YYYY"
                                removeToday={true}
                                converted={true}
                                value={mfList.purchase_date}
                                disabledDate={(day => {
                                    if (day.isAfter(moment())) {
                                        return true
                                    }
                                })}
                                onChange={(e) => {
                                    let temp = mfList;
                                    var tempDate = moment(e, 'YYYY-MM-DD');
                                    temp.purchase_date = tempDate.format('DD-MM-YYYY');
                                    setMfList({ ...temp });

                                }}
                            />
                        </div>
                        <div className='col-6 mt-4 pt-2'>
                            <label>
                                Quantity
                            </label>
                            <div className='w-50 position-relative'>
                                <Input
                                    value={mfList.quantity === 0 ? '0' : mfList.quantity}
                                    className={` ${styles.e_spinner_input}`}
                                    type="number"
                                    valueChange={(e) => {
                                        let temp = mfList;
                                        let value = e === 0 ? '0' : e
                                        temp.quantity = value;
                                        setMfList({ ...temp });
                                    }}
                                />
                                <img src={SpinnerMinus}
                                    className={`${styles.e_spinner_minus}`}
                                    onClick={() => {
                                        let temp = mfList;
                                        temp.quantity = temp.quantity - 1;
                                        setMfList({ ...temp });
                                    }}
                                />
                                <img
                                    src={SpinnerPlus}
                                    className={`${styles.e_spinner_plus}`}
                                    onClick={() => {
                                        let temp = mfList;
                                        temp.quantity = temp.quantity + 1;
                                        setMfList({ ...temp });
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-6 mt-4 '>
                            <label>
                                NAV
                            </label>
                            <Input
                                label="NAV"
                                value={mfList.current_nav}
                                type="number"
                                valueChange={(e) => {
                                    let temp = mfList;
                                    temp.current_nav = e;
                                    setMfList({ ...temp });
                                }}
                            />
                        </div>
                        <div className='col-6 mt-4 '>
                            <label>
                                Net amount
                            </label>
                            <Input
                                label="Net amount"
                                value={mfList.quantity * mfList.current_nav === 0 ? '0' : mfList.quantity * mfList.current_nav}
                                type="number"
                                className="e-portfolio-disable-input"
                                valueChange={(e) => {
                                }}
                            />
                        </div>
                    </div>
                )
            }
            <div className='col-lg-6 col-md-6 col-12  mt-4'>
                <SecondaryBtn
                    name="Cancel"
                    className="w-100"
                    cancel="modal"
                />
            </div>
            <div className='col-lg-6 col-md-6 col-12  mt-4'>
                {
                    // item.mf_type_id &&
                    mfList.mf_name &&
                        mfList.purchase_date &&
                        (mfList.quantity == 0 || mfList.quantity) &&
                        mfList.current_nav ?
                        <PrimaryBtn
                            name="Update"
                            className={loader ? 'e-btn-loader w-100' : 'w-100'}
                            handleClick={() =>
                                _updateMfList(mfList)
                            }
                        />
                        :
                        <PrimaryBtn
                            name="Update"
                            className={'w-100'}
                            disabled={true}
                        />
                }


            </div>


            {
                toast && (
                    <SuccessToast data={toast} />
                )
            }

            {
                error && (
                    <Toast data={error} />
                )
            }
        </div>
    )
}

export default MutualFundList;