/*
 *   File : switch.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

/*import package*/
import { useState, useEffect } from 'react';

/*import style*/
import styles from '../../styles/components/switch.module.scss';

const ToggleButton = (props) => {

  return (
    <>
      <div>
        <label className="switch d-inline-flex" >
          <input type="checkbox" checked={props.check} className={props.className} disabled={props.disabled} onChange={props.onChange} {...props} />
          <span className="slider round">

          </span>
          <span className='ps-5 '>
            {
              props.check === true ?
                <span class="on">On</span>
                :
                <span class="off">Off</span>
            }
          </span>
        </label>
      </div>
    </>
  );
}

export default ToggleButton;

