import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Accordion from 'react-bootstrap/Accordion';
import { Modal } from "bootstrap";

//import asset
import Risk from '../../assets/images/new-design/risk-score.svg';
import KYC from '../../assets/images/new-design/kyc.svg';
import Portfolio from '../../assets/images/new-design/portfolio.svg';
import Invest from '../../assets/images/new-design/invest.svg';
import Info from '../../assets/images/new-design/info.svg';
import BtnArrow from '../../assets/images/new-design/btn-arrow.svg';
import ArrowUp from '../../assets/images/new-design/arrow-up-right.svg';
import Icon from '../../assets/images/new-design/whtsapp.svg';
import CalendlyICon from '../../assets/images/new-design/calendly.svg';
import Completed from '../../assets/images/new-design/check.svg'
// import component
import { PrimaryBtn, PrimaryIconBtn, PrimaryLink } from "../../components/cta";
import VideoPlayer from '../../components/input/videoPlayer';
import { WatsappBtn, CalendlyBtn } from '../../components/cta';
import WelcomeModal from '../../components/modal/welcomeModal';
import devconfig from "../../config/config";


let calendlyUrl = devconfig.calendarUrl

const onboardCard = [
    {
        title: 'Risk Assessment',
        desc: 'Analyze your risk tolerance and see your customized investment portfolio.',
        icon: Risk,
        tooltip: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters'
    },
    {
        title: 'View your portfolio',
        desc: 'View your portfolio text goes here I am just a dummy paragraph.',
        icon: Portfolio,
        tooltip: 'View the assets in your portfolio curated specially for you.'
    },
    {
        title: 'Personal details and KYC',
        desc: 'Complete your KYC documentation and put your wealth creation on autopilot.',
        icon: KYC,
        tooltip: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters'
    },
    {
        title: 'Invest',
        desc: 'Invest and put your wealth creation on autopilot with Mintd',
        icon: Invest,
        tooltip: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters'
    }
]

const faq = [
    {
        title: 'How do I know I can trust you with my wealth?',
        answer: "First, we use institutional grade security to ensure your data is always protected. Second, we never hold your money. It goes directly from your account to the mutual funds account. And third, our advisors have decades of experience managing billions of dollars."
    },
    {
        title: 'What if I want to pull my money out?',
        answer: "It’s your money. You can withdraw it any time. We make it easy."
    },
    {
        title: 'How long does it take to open an account?',
        answer: "The entire process from onboarding through investment takes less than 5 minutes. Just keep your documents handy."
    },
    {
        title: 'Why should I invest with Mintd?',
        answer: "There are thousands of mutual funds yet 90% of managers don’t beat the market on a consistent basis. Our managers take away the headache of choosing for you by curating diversified portfolios tailored to your needs that provide superior risk-adjusted returns."
    },
    {
        title: 'Is there a lock in period for my investment?',
        answer: "No. We invest in liquid securities so you can withdraw your funds with no penalty ay any time. The cash usually takes about three days to settle in your account once you redeem your mutual funds."
    },
    {
        title: 'What happens if Mintd shutdown?',
        answer: `We never hold your money or investments. The funds are always in your name with each asset manager, we merely facilitate the transaction. So IF anything were to happen to us you could always:

        a.  Go to CAMS portal online and withdraw the amount
        
        b. Go to the AMC website and login using your PAN and withdraw the amount
        
        c. Call your AMC customer care who will send you a link to withdraw the amount
        
        Either ways we will facilitate this process for each of our customers.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.`
    },
]

const SIGNUP_STATUS = [
    {
        "status": "PERSONAL_DATA_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "ADDRESS_DATA_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "INCOME_DATA_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "AMC_KYC_REJECTED",
        "step": 3,
        "completed": false
    }, {
        "status": "BANKACCOUNT_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "NOMINEE_ADDED",
        "step": 3,
        "completed": false
    }, {
        "status": "AMC_KYC_INPROGRESS",
        "step": 3,
        "completed": false
    }, {
        "status": "ESIGN_STARTED",
        "step": 3,
        "completed": false
    }, {
        "status": "ESIGN_COMPLETED",
        "step": 4,
        "completed": true
    }, {
        "status": "AMC_KYC_COMPLETED",
        "step": 4,
        "completed": true
    },
    {
        "status": "AMC_KYC_VERIFIED",
        "step": 3,
        "completed": true
    }, {
        "status": "READY_TO_INVEST",
        "step": 4,
        "completed": false
    }, {
        "status": "QUESTIONNAIRE_STARTED",
        "step": 1,
        "completed": false
    }, {
        "status": "QUESTIONNAIRE_COMPLETED",
        "step": 2,
        "completed": true
    }, {
        "status": "RISK_SCORE_CALCULATED",
        "step": 2,
        "completed": false
    }, {
        "status": "RISKSCORE_SKIPPED",
        "step": 2,
        "completed": false
    }, {
        "status": "QUESTIONNAIRE_RESUBMIT_COMPLETED",
        "step": 1,
        "completed": false
    }, {
        "status": "QUESTIONNAIRE_RESUBMIT_STARTED",
        "step": 1,
        "completed": false
    },
    {
        "status": 'PORTFOLIO_CONFIGURED',
        "step": 3,
        "completed": false
    },
    {
        "status": "PASSWORD_CONFIGURED",
        "step": 1,
        "completed": false
    },
    {
        "status": "AUTH_CONFIGURED",
        "step": 1,
        "completed": false
    },
    {
        "status": "OTP_REQUESTED",
        "step": 1,
        "completed": false
    },
    {
        "status": "INVESTED",
        "step": 4,
        "completed": true
    }

]

const DashBoard = (props) => {
    const [currentStep, setCurrentStep] = useState({});
    const navigator = useNavigate();
    const [userData, setUserData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [openToolTip, setOpenToolTip] = useState(null);

    useEffect(() => {
        if (props.userDetail) {
            setUserData(props.userDetail)
            let status_index = SIGNUP_STATUS.findIndex((item) => item.status === props.userDetail.signup_status);
            setCurrentStep(SIGNUP_STATUS[status_index]);
            setLoader(false);
        }
    }, [props.userDetail])

    useEffect(() => {
        if (!sessionStorage.getItem('welcome_modal')) {
            var modal = new Modal(document.getElementById("welcome"), {})
            modal.show()
        }
    }, [])

    const _generateGreeting = () => {

        var currentHour = moment().format("HH");

        if (currentHour >= 3 && currentHour < 12) {
            return "Good Morning!";
        } else if (currentHour >= 12 && currentHour < 15) {
            return "Good Afternoon!";
        } else if (currentHour >= 15 && currentHour < 20) {
            return "Good Evening!";
        } else if (currentHour >= 20 && currentHour < 3) {
            return "Good Night!";
        } else {
            return "Good Evening!"
        }
    }

    const EditBtn = (props) => {
        let status_index = SIGNUP_STATUS.findIndex((item) => item.status === userData.signup_status);
        if (props.step == 3 && status_index < 5) {
            return (
                <PrimaryLink
                    className="mt-3"
                    name="Edit"
                    handleClick={() => navigator('/onboard')}
                />
            )

        }
        return null;
    }

    const _analyticsTrackUserDetails = () => {
        if (sessionStorage.getItem('avail_utm')) {
            window.analytics.identify(props.userDetail?.user_id, {
                path: window.location.pathname,
                name: `${props.profile?.first_name} ${props.profile?.last_name}`,
                first_touch_utm: sessionStorage.getItem('avail_utm')
            });
        } else {
            window.analytics.identify(props.userDetail?.user_id, {
                path: window.location.pathname,
                name: `${props.profile?.first_name} ${props.profile?.last_name}`,
                first_touch_utm: 'None'
            });
        }

    }

    useEffect(() => {
        if (props.userDetail && props.profile) _analyticsTrackUserDetails();
    }, [props.userDetail, props.profile])

    return (
        <div className="e-main-content e-dashboard ps-lg-5 ps-2 pb-5 mb-5" style={{ backgroundColor: '#F9F9F9' }}>
            <div className="row">

                {/* Heading */}
                <div className="col-12  mt-lg-3 mt-sm-3 mt-3 pt- 5">
                    <h5 className="e-dashboard-greeting mt-3">
                        {`Hey ${props?.profile?.first_name}, ${_generateGreeting()}`}
                    </h5>
                    <p className="e-dashboard-sub-title">
                        Please complete these steps to start investing
                    </p>
                </div>

                {/* Cards */}
                <div className="col-12 mt-lg-3 mt-xs-0 mt-0">
                    <div className="d-flex e-dashboard-onboard-card-wrapper py-3">
                        {
                            userData && onboardCard.map((item, key) => {
                                return (
                                    <div className="e-dashboard-card p-4">
                                        <div className="row p-1">
                                            <div className="col-6">
                                                <img src={item.icon} />
                                            </div>
                                            <div className="col-6 text-end">
                                                <span
                                                    className="e-step"
                                                    style={{ color: key + 1 === currentStep.step && "#5A69EE" }}
                                                >
                                                    STEP {`${key + 1}/4`}
                                                </span>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <h6>
                                                    <span className="position-relative">
                                                        {item.title}
                                                        <img
                                                            src={Info}
                                                            className="ms-2"
                                                            onMouseEnter={() => {
                                                                setOpenToolTip(key)
                                                            }}
                                                            onMouseLeave={() => {
                                                                setOpenToolTip(null);
                                                            }}
                                                        />
                                                        {
                                                            openToolTip === key && (
                                                                <div className="e-tool-tip p-3">
                                                                    {item.tooltip}
                                                                </div>
                                                            )
                                                        }
                                                    </span>

                                                </h6>
                                                <p>
                                                    {item.desc}
                                                </p>
                                            </div>
                                            <div className="col-12 justify-content-between d-flex">
                                                <EditBtn step={key + 1} />
                                                {
                                                    currentStep.step === key + 1 && (



                                                        <PrimaryBtn
                                                            className="e-dashboard-onboard-card-btn mt-3"
                                                            name={
                                                                currentStep.step === 1 && currentStep.completed === false ?
                                                                    "Let's Start"
                                                                    :
                                                                    'Continue'
                                                            }
                                                            image={BtnArrow}
                                                            right={true}
                                                            iconClass="ms-2"
                                                            handleClick={() => navigator('/onboard')}
                                                        />

                                                    )
                                                }
                                                {console.log("completed", currentStep, key)}
                                                {
                                                    key + 1 < currentStep.step && (
                                                        <div className="e-completed">
                                                            <img src={Completed} alt="" className="me-2" />Completed</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col-12 my-lg-3 my-md-3 my-sm-0 my-0">
                    <span className="e-option">OR</span>
                    <PrimaryIconBtn
                        name="Track your investments"
                        right={true}
                        image={ArrowUp}
                        className="e-btn-link"
                        handleClick={() => {
                            navigator('/portfolio-tracker')
                        }}
                    />
                </div>

                {/* Video section */}
                <div className="col-12">
                    <div className="row pe-2 pe-lg-0 pe-md-0">
                        <div className="col-12">
                            <h6 className="e-section-title mt-2 mb-3">
                                Welcome to Mintd
                            </h6>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="e-video-card">
                                {/* <VideoPlayer
                                                    url="https://mintd.s3.ap-south-1.amazonaws.com/video/methodology.mp4"
                                                    message="How we build your portfolio"
                                                    home={true}
                                                /> */}
                                <iframe
                                    id="e-vid"
                                    sandbox
                                    height="100%" width="100%"
                                    allow='autoplay'
                                    allowFullScreen
                                    src={"https://www.youtube.com/embed/ZHC1K1jZ8a8"}>
                                </iframe>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mt-4  mt-lg-0 mt-md-0">
                            <div className="e-video-card">
                                {/* <VideoPlayer
                                                    url="https://mintd.s3.ap-south-1.amazonaws.com/video/message.mp4"
                                                    message="A message from our founder"
                                                    home={true}
                                                /> */}
                                <iframe
                                    id="e-vid"
                                    sandbox
                                    target="_blank"
                                    height="100%" width="100%"
                                    allow='autoplay'
                                    allowFullScreen
                                    src={"https://www.youtube.com/embed/qT7Hs3N_bnc"}>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>

                {/* FAQ */}
                <div className="col-12 mt-4">
                    <div className="row pe-2 pe-lg-0 pe-md-0">
                        <h6 className="e-section-title mt-2 mb-3 ">
                            Frequently asked questions
                        </h6>


                        <Accordion defaultActiveKey="0" flush className="e-home-faq">
                            <div className="e-home-faq-wrapper e-col pe-lg-4 pe-md-4 pe-0">
                                {
                                    faq.map((item, key) => {
                                        if (key < 3) {
                                            return (
                                                <Accordion.Item eventKey={key} className="mt-4">
                                                    <Accordion.Header>{item.title}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {item.answer}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className="e-home-faq-wrapper e-col pe-lg-4 pe-md-4 pe-0">
                                {
                                    faq.map((item, key) => {
                                        if (key > 2) {
                                            return (
                                                // <div className="">
                                                <Accordion.Item eventKey={key} className="mt-4">
                                                    <Accordion.Header>{item.title}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {
                                                            key !== 5 ?
                                                                item.answer
                                                                :
                                                                <div>
                                                                    <p>
                                                                        We never hold your money or investments. The funds are always in your name with each asset manager, we merely facilitate the transaction. So IF anything were to happen to us you could always:
                                                                    </p>
                                                                    <p>
                                                                        a. Go to CAMS portal online and withdraw the amount
                                                                    </p>
                                                                    <p>
                                                                        b. Go to the AMC website and login using your PAN and withdraw the amount
                                                                    </p>
                                                                    <p>
                                                                        c. Call your AMC customer care who will send you a link to withdraw the amount
                                                                    </p>
                                                                    <p>
                                                                        Either ways we will facilitate this process for each of our customers.
                                                                    </p>
                                                                </div>
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                // </div>
                                            )
                                        }
                                    })
                                }
                            </div>

                        </Accordion>
                    </div>
                </div>

                {/* Contact */}
                <div className='col-12 mt-5 pt-3 mb-5 text-center'>
                    <h6 className='e-help-title'>Still not sure?</h6>
                    <a href={devconfig.whatsappUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                        <WatsappBtn left={true} image={Icon} className="me-lg-3 me-md-3 me-sm-3 me-1" />
                    </a>
                    <a href={calendlyUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                        <CalendlyBtn left={true} image={CalendlyICon} />
                    </a>
                </div>

                <div className="col-12 pb-5 pe-4 ">
                    <PrimaryBtn
                        className="e-dashboard-main-btn w-100 mt-3"
                        name="I'm Ready. Let's get started"
                        image={BtnArrow}
                        iconClass="ms-2"
                        right={true}
                        handleClick={() => { navigator('/onboard') }}
                    />
                </div>
            </div>
            <WelcomeModal modalClose={() => { }} name={props?.profile?.first_name} />
        </div>
    )
}

export default DashBoard;