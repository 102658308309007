
//Package import
import { useState, useEffect } from 'react';
import moment from 'moment';
//Style import 
import styles from '../../styles/components/assetModalBody.module.scss';

//Component import
import { PrimaryBtn, PrimaryLink, SecondaryBtn, PrimaryIconBtn } from '../../components/cta';
import { Select, SingleDate } from '../../components/input';
import Input from '../../components/input/loginInput'
import { Toast, SuccessToast } from '../../components/note';
import Trash from '../../assets/images/onboard/trash-can.png';

// service import 
import APIPost from '../../services/postApi';
import Config from '../../config/config';
import Nodata from '../../components/note/nodata-placeholder';

const Assets = [
    { value: 1, label: 'Stocks' },
    { value: 2, label: 'Mutual funds' },
    { value: 3, label: 'Fixed deposit' },
    { value: 4, label: 'Equity' },
    { value: 5, label: 'Real Estate' },
    { value: 6, label: 'Bonds' },
]


const GoldList = (props) => {

    const [loader, setLoader] = useState(null);
    const [date, setDate] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [toast, setToast] = useState(null);
    const [goldList, setGoldList] = useState([]);
    const [error, setError] = useState(null);
    const [removing, setRemoving] = useState(false);

    useEffect(() => {
        if (props.data) {
            setGoldList(props.data)
        }
    }, [props.data])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 5000)
        }
    }, [toast])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 5000)
        }
    }, [error])

    const _updateGold = (data) => {
        setLoader(true);
        let url = Config.portfolio + '/assets/gold/update';
        let payload = JSON.stringify({
            gold_id: data.id,
            gold_purchase_date: data.purchase_date,
            gold_in_grams: data.gold_in_grams
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setToast('Updated successfully')
                props.success();
            }
            else {
                setLoader(false);
                setError(response.message);
            }
        })
    }

    const _removeGold = (id, index) => {
        setRemoving(true)
        let url = Config.portfolio + '/assets/gold/delete';
        let payload = JSON.stringify({
            request_id: id
        })
        APIPost(url, payload).then((response) => {
            if (response.status_code === 200) {
                let array = goldList;
                array.splice(index, 1);
                setGoldList([...array]);
                setRemoving(false);
                setToast("Successfully removed")
            }
            else {
                setRemoving(false);
                setError(response.message)
            }
        })
    }
    return (
        <div className={`row mt-4 ${styles.e_stocks}`}>
            {
                goldList && (
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-6'>
                                <label>
                                    Gold purchase date
                                </label>
                                <SingleDate
                                    placeholder="DD-MM-YYYY"
                                    removeToday={true}
                                    converted={true}
                                    value={goldList.purchase_date}
                                    disabledDate={(day => {
                                        if (day.isAfter(moment())) {
                                            return true
                                        }
                                    })}
                                    onChange={(e) => {
                                        var tempDate = moment(e, 'YYYY-MM-DD')
                                        let temp = goldList;
                                        temp.purchase_date = tempDate.format('DD-MM-YYYY');
                                        setGoldList({...temp});
                                    }}
                                />
                            </div>
                            <div className='col-6 '>
                                <label>
                                    Grams
                                </label>
                                <Input
                                    placeholder="e.g 5.55%"
                                    type="number"
                                    value={goldList.gold_in_grams}
                                    valueChange={(e) => {
                                        let temp = goldList;
                                        temp.gold_in_grams = e;
                                        setGoldList({...temp});
                                    }}
                                />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                                <SecondaryBtn
                                    name="Cancel"
                                    className="w-100"
                                    cancel="modal"
                                />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 mt-4'>
                                {
                                    goldList.purchase_date &&
                                    goldList.gold_in_grams ?
                                        <PrimaryBtn
                                            name={ loader ? 'Updating' : "Update"}
                                            className={loader ? 'e-btn-loader w-100' : 'w-100'}
                                            handleClick={() => { _updateGold(goldList) }}
                                        />
                                        :
                                        <PrimaryBtn
                                            name="Update"
                                            className="w-100"
                                            disabled={true}
                                        />
                                }


                            </div>
                        </div>

                    </div>
                )
            }


            {
                toast && (
                    <SuccessToast data={toast} />
                )
            }

            {
                error && (
                    <Toast data={error} />
                )
            }
        </div>
    )
}

export default GoldList;