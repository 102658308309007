/*
 *   File : Filter.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Filter Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 04-01-2022
*/
/*import components */
import { useEffect, useState } from 'react';
import { PrimaryLink, PrimaryBtn } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/filter.module.scss';
import { CheckBox } from '../input';
import close from '../../assets/images/new-design/modal-close.svg';

const Filter = (props) => {
  const [filter, setFilter] = useState(false);
  const [orderType, setOrderType] = useState([]);
  const [statusType, setStatusType] = useState([])
  const [ filterData, setFilterData] = useState(null);
  useEffect(() => {
    let position = window.innerWidth
    if(position <= "996px"){
      setFilter(true);
    }
  },[window.innerWidth])
  
  const order = [
    {
      id: 1,
      name: "Lumpsum"
    },
    {
      id: 2,
      name: "SIP"
    },
    {
      id: 3,
      name: "Withdrawals"
    },
  ]

  const status = [
    {
      id: 1,
      name: "Success"
    },
    {
      id: 2,
      name: "Failed"
    },
    {
      id: 3,
      name: "Processing"
    },
  ]

  useEffect(()=>{
    if ( props.typeFilter && props.typeFilter.length > 0) {
      setOrderType([...props.typeFilter]);
      console.log('type filter', props.typeFilter);
    }
    
    // 

  },[props.typeFilter])

  useEffect(()=>{
    if ( props.statusFilter) {
      setStatusType([...props.statusFilter]);
      console.log('type filter', props.statusFilter);
    }
  },[props.statusFilterType])

  useEffect(()=>{
    if ( props.data ) {
      setFilterData(props.data)
    }
  },[props.data])

  // setting order filter
  const setOrderFilter = (id, index) =>{
    if( id === null){ // checking for all filter types
      let tmp = [];
      if( orderType.length !== props.data.order_type.length ){ // check all the check box in the order type ;
        for (let index = 0; index < props.data.order_type.length; index++) {
          const element = props.data.order_type[index];
          tmp.push(element.id);
        }
      }
      else{ // uncheck all the checkbox
        tmp = [];
      }
      setOrderType([...tmp])
    }else{
    let temp = orderType;
    let ItemIndex  =  temp.indexOf(id);
     if( ItemIndex < 0){
      temp.push(id)
    }
    else{
      temp.splice(ItemIndex , 1)
    }
    var filtered = temp.filter(function (el) { // uncheck 'ALL' checkbox if any other checkbox unchecked after all checkboxes were selected
      return el != null;
    });
    setOrderType([...filtered]); 
  }
}

  // setting status filter
  const setStatusFilter = (id, index) =>{
    if( id === null){ // checking for all filter types
      let tmp = [];
      if( statusType.length !== props.data.status.length ){ // check all the check box in the order status ;
        for (let index = 0; index < props.data.status.length; index++) {
          const element = props.data.status[index];
          tmp.push(element.id);
        }
      }
      else{ // uncheck all the checkbox
        tmp = [];
      }
      setStatusType([...tmp])
    }else{
      let temp = statusType;
      let itemIndex = temp.indexOf(id);
      if( itemIndex < 0){
        temp.push(id)
      }else{
        temp.splice(itemIndex , 1)
      }
      var filtered = temp.filter(function (el) { // uncheck 'ALL' checkbox if any other checkbox unchecked after all checkboxes were selected
        return el != null;
      });
      
      setStatusType([...filtered])
    }
  }
  return (
    <>
      <div className={`${styles.e_filter} e-order-filter`}>
        <div className="row">
          <div className="col-lg-12 col-12 mb-3 d-flex justify-content-between" style={{ cursor: "pointer" }} >
            <h6 onClick={() => setFilter(!filter)}>Filters</h6>
              <img src={close}
                onClick={() => {
                  // setOrderType([]); 
                  // setStatusType([]); 
                  props.close()
              }}
              />
          </div>
          {
            filter === false ?
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-xs-6 col-sm-12'>
                  <div className="col-lg-12 col-md-12 col-sm-12 mb-0 text-start">
                    <p className={`${styles.e_order_text}`}>Order type</p>
                  </div>
                  <div className='row'>
                  {
                    filterData && filterData.order_type.map((item, index) => (
                      <div 
                        className={
                          index === 0 ?  "col-lg-3 col-md-3 col-sm-12 d-flex mb-3 e-order-checkbox" :
                          index === 1 ? "col-lg-5 col-md-5 col-sm-12 d-flex mb-3 e-order-checkbox" :
                          "col-lg-4 col-md-4 col-sm-12 d-flex mb-3 e-order-checkbox"
                        }
                      >
                        <CheckBox check={ orderType.includes(item.id) ? true : false} type="checkbox" className={`${styles.e_input_check}`} onChange={() => { setOrderFilter(item.id, index) }} /><span onClick={() => { setOrderFilter(item.id, index) }}>{item.name}</span>
                      </div>
                    ))
                  }
                  </div>
                </div>
                <div className='col-lg-12 col-md-12 col-xs-6 col-sm-12'>
                  <div className="col-lg-12 col-md-12 col-sm-12 mb-0 text-start">
                    <p className={`${styles.e_order_text} mt-3`}>Payment Status</p>
                  </div>
                  <div className='row'>
                  {
                    filterData && filterData.status.map((item, key) => (
                      <div 
                        className={ 
                          key === filterData.status.length -1 ? 
                          "col-lg-8 col-md-8 col-sm-12 d-flex mb-3 e-order-checkbox"
                          :
                          "col-lg-4 col-md-4 col-sm-12 d-flex mb-3 e-order-checkbox"
                        }
                      >
                        <CheckBox type="checkbox" check={statusType.includes(item.id) ? true : false}  className={`${styles.e_input_check}`} onChange={() => { setStatusFilter(item.id, key)}} /><span onClick={() => { setStatusFilter(item.id, key)}}>{item.name}</span>
                      </div>
                    ))
                  }
                  </div>
                </div>
              </div>
              :
              null
          }

          <div className='col-lg-12 text-start mt-3'>
            {
              orderType.length || statusType.length ?
                <PrimaryBtn name="Apply" className={`${styles.e_filter_submit}`} handleClick={()=> props.submit(orderType, statusType)}/>
              :
              <PrimaryBtn name="Apply"  disabled={true} className={`${styles.e_filter_submit}`}/>
            }
              
            </div>
        </div>
      </div>


    </>
  )
}

export default Filter;
