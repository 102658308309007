/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
/*import componets*/
import Input from '../../components/input/loginInput';
import { SecondaryBtn, PrimaryBtn, PrimaryLink } from '../cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

// import Service
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';

/* import assets */
import Delete from '../../assets/images/modal/delete.svg'
import Close from '../../assets/images/new-design/modalClose.svg';

export default function Email(props) {

	const [email, setEmail] = useState(null);
	const [otp, setOTP] = useState(null);
	const [otpVerified, setOTPVerified] = useState(false);
	const [apiLoader, setAPILoader] = useState(false);
	const [error, setError] = useState(null);
	const [timer, setTimer] = useState(null);
	const [resendLoader, setResendLoader] = useState(false);
	const [OTPValidationSuccess, setOTPValidationSuccess] = useState(false);
	const [OTPValidationFail, setOTPValidationFail] = useState(false);
	const [otpResponseStatus, setOTPResponseStatus] = useState(null);
	const [emailResponseStatus, setEmailResponseStatus] = useState(null);
	const [validEmail, setValidEmail] = useState(false);
	
	//   useEffect(() => {
	//     setEmail(props?.email)
	//   }, [props?.email])

	useEffect(() => {
		setNewCount();
	}, [timer])
	
	  const setNewCount = () => {
		setTimeout(() => {
		  if (timer > 0) {
			setTimer(timer - 1)
		  }
		}, 1000)
	  }

	const getOTP = (type = null) => {
		setAPILoader(true)
		let url = devconfig.user + "/profile/update-email";
		let data = JSON.stringify({
			email: email,
			type: type
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setOTPVerified(true);
				setAPILoader(false);
				setTimer(20);
			} else {
				setAPILoader(false);
				setError(response.message);
			}
		})
	}

	const verifyOTP = () => {
		setAPILoader(true)
		let url = devconfig.user + '/profile/verify-email';
		let data = JSON.stringify({
			email: email,
			otp: otp
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				props.success(email);
				setAPILoader(false);
				$('#email').trigger('click');
			}
			else {
				setAPILoader(false);
				setError(response.message);
			}
		})
	}

	useEffect(() => {
		let modal = document.getElementById('email');
		modal.addEventListener('hidden.bs.modal', () => {
			props.modalClose();
			setOTPVerified(false);
		})
	}, [])


	return (
		<div className={`${styles.e_edit_modal} modal fade `} id="email" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
						<h5 className='mt-1'>Change Email Address</h5>
						<img
							src={Close}
							className={`${styles.e_modal_close}`}
							data-bs-dismiss="modal"
						/>
					</div>
					<div className="container p-4 ">
						<form
							onSubmit={(e) => e.preventDefault()}
						>
							<div className="row">
								<div className="col-lg-8">
									<label className='mb-1'>New Email</label>
									<Input
										value={email}
										valueChange={(value) => { setEmail(value); setError(null) }}
										type="text"
										readOnly={otpVerified}
										focusOut={(value)=>{
											if ( value ) {
												if ( !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value)) {
													setEmailResponseStatus({type: 'fail', message: 'Invalid email'});
													setValidEmail(false);
												}else{
													setValidEmail(true);
												}
											}
										}}
										messageType={emailResponseStatus?.type}
										success={emailResponseStatus && emailResponseStatus.type == 'success' ? true : false}
										fail={emailResponseStatus && emailResponseStatus.type != 'success' ? true : false}
										message={ 
											emailResponseStatus && emailResponseStatus.message ?
												emailResponseStatus.message 
											:	
												'You will receive an OTP on this email'
										}
										needBlurEvent={true}
										needFocusEvent={true}
										focusIn={()=>{
											setEmailResponseStatus(null)
										}}
									/>

									<div className="col-5 e-otp-wrapper mt-3"
										style={{ height: otpVerified === false ? '0px' : '120px' }}
									>
										<label className='mb-1'>Email OTP</label>
										<Input
											value={otp}
											valueChange={(value) => { setOTP(value) }}
											type="number"
											width="40%"
											success={OTPValidationSuccess}
											message={otpResponseStatus?.message}
											messageType={otpResponseStatus?.type}
											needFocusEvent={true}
											focusIn={() => {
												setOTPResponseStatus(null);
												setOTPValidationFail(false)
											}}
										/>
										<div className='d-flex justify-content-between e-timer-wrapper mt-1'>
											<>
												<span className='e-otp-timer'>00 : {timer < 10 ? "0" + timer : timer}</span>
												<PrimaryLink
													className={resendLoader ? "e-btn-loader e-otp-resend" : 'e-otp-resend'}
													name={resendLoader ? "Sending" : "Resend OTP"}
													disabled={timer > 0 ? true : false}
													type="button"
													handleClick={(e) => {
														getOTP('RESEND');
													}}
												/>
											</>
										</div>
									</div>
								</div>
							</div>

							{
								otpVerified === false ? (
									<div className={`row pt-3 pb-2 ${styles.e_otp_wrapper}`}>
										<div className="col-lg-6 col-md-6 col-sm-12 ">
											<SecondaryBtn
												name="Cancel"
												cancel="modal"
												className="w-100"
												type="button"
												handleClick={(e)=>{e.preventDefault()}}
											/>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-4">
											<PrimaryBtn
												name={apiLoader ? "Loading" : "Next"}
												className={apiLoader ? 'e-btn-loader w-100' : 'w-100'}
												disabled={ validEmail === true ? false : true}
												handleClick={() =>{ if (validEmail  === true){ getOTP() } else { return}}}
											/>
										</div>
									</div>
								) :
									(
										<div className="row pt-3 pb-2">
											<div className="col-lg-6 col-md-6 col-sm-12 ">
												<SecondaryBtn
													name="Cancel"
													cancel="modal"
													type="button"
													className="w-100"
													handleClick={(e)=>{e.preventDefault()}}
												/>
											</div>
											<div className='col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-4'>
												<PrimaryBtn
													name={apiLoader ? "Updating" : "Update"}
													className={apiLoader ? 'e-btn-loader w-100' : 'w-100'}
													disabled={validEmail && otp && otp.length === 3 ? false : true}
													handleClick={() =>{ if (validEmail  === true){  verifyOTP() } else{ return }}}
												/>
											</div>
									</div>
									)
							}
						</form>
					</div>
				</div>
			</div>
			{
				error && (
					<Toast data={error}/>
				)
			}
		</div>
	)
}
