
import { useState, useEffect } from "react";
import $ from 'jquery';

import styles from '../../styles/components/deleteAssetConfirm.module.scss';

import { SecondaryBtn, PrimaryBtn } from '../cta/index';
import { Toast } from "../note";

import Close from '../../assets/images/new-design/modalClose.svg';

import Config from '../../config/config';
import APIPost from "../../services/postApi";

const AssetDeleteConfirmation = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 5000)
        }
    }, [error])

    useEffect(() => {
        var myModalEl = document.getElementById('remove-asset')
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            props.modalClose();
        })
    }, [])

    const _removeAsset = () => {
        let api;
        switch (props.selected.value) {
            case 1: api = '/assets/mf/delete';
                break;
            case 2: api = '/assets/stocks/delete';
                break;
            case 3: api = '/assets/realestate/delete';
                break;
            case 4: api = '/assets/gold/delete';
                break;
            case 5: api = '/assets/fd/delete';
                break;
            default: return;
        }
        setLoading(true);
        let url = Config.portfolio + api;
        let data = JSON.stringify({
            request_id: props.id
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoading(false);
                $('#remove-asset').trigger('click');
                props.success(props.selected);
            } else {
                setError(response.message);
            }
        })
    }

    const deleteAllAssets = () => {
        let url = Config.portfolio + "/assets/assets/delete";
        let data = JSON.stringify({
            asset_type_id: props.selected.value
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoading(false);
                $('#remove-asset').trigger('click');
                props.success(props.selected);
            } else {
                setError(response.message);
            }
        })
    }
    return (
        <div className={`${styles.e_modal} modal fade`} id="remove-asset" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                        <h5 className='mt-1'>{props.delete ?
                            `Delete all ${props.selected.value === 1 || props.selected.value === 2 || props.selected.value === 5 ? `${props.selected.label}s` : props.selected.label}`
                            :
                            "Remove Asset"
                        }</h5>
                        <img
                            src={Close}
                            className={`${styles.e_modal_close}`}
                            data-bs-dismiss="modal"
                        />
                    </div>
                    <div className="container p-4">
                        <div className="row ">
                            <div className='col-12 text-center'>
                                <h5 className={`${styles.e_subtitle}`}>{props.delete ?
                                    `Are you sure you want to delete all ${props.selected.value === 1 || props.selected.value === 2 || props.selected.value === 5 ? `${props.selected.label}s` : props.selected.label} from your portfolio?`
                                    : "Delete Confirmation"}</h5>
                                <p className={`${styles.e_note}`}>
                                    {props.delete ?
                                        "Please note this process can’t be undone"
                                        :
                                        `Do you want remove this ${props.selected.label}`}
                                </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                <SecondaryBtn
                                    name="Cancel"
                                    className="w-100"
                                    cancel="modal"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                <PrimaryBtn
                                    name="Delete"
                                    className="w-100"
                                    handleClick={() => {
                                        if (props.delete) {
                                            deleteAllAssets()
                                        } else {
                                            _removeAsset()
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error && (
                    <Toast data={error} />
                )
            }
        </div>
    )
}

export default AssetDeleteConfirmation;