/*
 *   File : addNominee.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add Nominee
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
/*import componets*/
import {  Select, SingleDate } from '../input/index';
import Input from '../input/loginInput';
import { SecondaryBtn, PrimaryBtn } from '../cta/index';

import Close from '../../assets/images/new-design/modalClose.svg';


/*import styles*/
import styles from '../../styles/components/edit.module.scss';
import moment from 'moment';
// import { DatePicker } from 'antd';
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
// import SingleDate from '../input/singleDate';
import { Toast } from '../note';
/* import assets */
export default function EditNominee(props) {
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [allocation, setAllocation] = useState(null);
    const [date, setDate] = useState(null);
    const [relation, setRelation] = useState({ label: "Relation", value: null });
    const [relationOptions, setRelationOptions] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [ minor, setMinor ] = useState(false);
	const [ guardianName, setGuardianName] = useState(null);
    const [ guardianRelation, setGuardianRelation] = useState(null);
    const [ guardianOptions, setGuardianOptions] = useState([]);

    const opt = [
        { value: 1, label: "Father" },
        { value: 2, label: "Mother" },
    ]

    useEffect(() => {
        getDropdown();
    }, [])

    useEffect(() => {
        console.log('props.data--<', props.data)
        setName(props?.data?.nominee_name)
        setAllocation(props?.data?.allocation)
        setDate(props?.data?.nominee_dob);
        if (props.data && props.data.relation_id) {
            setRelation({ value: props.data.relation_id, label: props.data.relation })
        } else {
            setRelation({ value: null, label: "Relation" })
        }
        setId(props?.data?.nominee_id);
        if ( props.data && props.data.guardian_relation_id) {
            setGuardianRelation( { value: props.data.guardian_relation_id, label:props.data.guardian_relation })
            setGuardianName(props.data.guardian_name);
        }
        _calculateAge(props?.data?.nominee_dob)
    }, [props.data])
    useEffect(() => {

        var myModal = document.getElementById('editNominee')
        myModal.addEventListener('hidden.bs.modal', () => {
            setAllocation(null);
            setName(null);
            setDate(null);
            setRelation(null);
            setGuardianName(null);
            setGuardianRelation(null)
        })
    }, [])

    const getDropdown = () => {
        var tempRelation = [];
        let url = devconfig.user + '/app/get-generaldata';
        APIGet(url).then((response) => {
            if (response?.status_code === 200) {
                response.data.kyc_relationships.map((item, key) => {
                    tempRelation.push({ value: item.id, label: item.display_name })
                })
                setRelationOptions(tempRelation);
                const filtered = tempRelation.filter((item) => item.label !== 'Son' && item.label !== 'Daughter');
				setGuardianOptions(filtered);
            }
        })
    }

    // const handleChange = (date, dateString) => {
    //   //  console.log(dateString)
    //   let d = moment(dateString).format('DD/MM/YYYY')
    //   setDate(d)
    // }

    const updateNominee = () => {
        setLoader(true);
        console.log('data', date);
        let url = devconfig.user + '/profile/update-nominee';
        let data = JSON.stringify({
            "nominee_id": id,
            "nominee_name": name,
            "allocation": allocation,
            "nominee_dob": date,
            "relation_id": relation.value,
            "guardian_name": guardianName,
            "guardian_relation_id": guardianRelation?.value
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                props.reload()
                $('#editNominee').trigger('click');
                setLoader(false);
            }
            else {
                setLoader(false);
                setError(response.message);
            }
        })
    }
    	
    // checking nominee is minor or not
	const _calculateAge = (date) => {
		const birthday = new Date(date);
		var ageDifMs = Date.now() - birthday.getTime();
		var ageDate = new Date(ageDifMs); 
		if (Math.abs(ageDate.getUTCFullYear() - 1970) < 18 ) setMinor(true);
		else setMinor(false);
	}


    return (


        <div className={`${styles.e_edit_modal} modal fade`} id="editNominee" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
						<h5 className='mt-1'>Edit Nominee</h5>
						<img
							src={Close}
							className={`${styles.e_modal_close}`}
							data-bs-dismiss="modal"
						/>
					</div>
                    <div className="container p-4">
                        <div className="row mt-1">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className='mb-1'>Nominee name</label>
                                <Input
                                    value={name}
                                    valueChange={(value) => setName(value)}
                                    type="text"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                            <label className='mb-1'>Allocation</label>
                                <Input
                                    label="Allocation"
                                    value={allocation}
                                    valueChange={(value) => setAllocation(value)}
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="row mt-3">

                            <div className="col-lg-6 col-md-6 col-sm-12">
                            <label className='mb-1'>Date of Birth</label>
                                <SingleDate
                                    value={date}
                                    id="edit-nominee"
                                    placeholder="dd-mm-yyyy"
                                    onChange={(dateString) => {
                                        setDate(dateString);
                                        _calculateAge(dateString)
                                    }}
                                    converted={true}
                                    removeToday ={true}
									disabledDate={(day => {
                                        if (day.isAfter(moment())) {
                                          return true
                                        }
                                      })}
                                />
                            </div>


                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className='mb-1'>Relation</label>
                                {
                                    props.data && props?.data?.relation ?
                                        <Select
                                            options={relationOptions}
                                            value={relation}
                                            placeholder="Relation"
                                            className="e-select-sort"
                                            selectchange={(temp) => setRelation(temp)}
                                        />
                                        :
                                        null
                                }
                            </div>
                        </div>
                        {
							minor === true &&(
                                <div className='row mt-4 pt-1'>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label className='mb-1'>Guardian Name</label>
                                        <Input
                                            id="g_name"
                                            value={guardianName}
                                            valueChange={(value) => setGuardianName(value)}
                                            type="text"
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <label className='mb-1'>Guardian Relation</label>
                                        <Select
											id="relation"
											value={guardianRelation}
											options={guardianOptions}
											placeholder="Guardian relation"
											className="e-select-sort"
											style="border:solid 10px #f0f0f0;"
											selectchange={(temp) => {
												setGuardianRelation(temp);
											}}
										/>
                                    </div>
                                </div> 
                            )
                        }
                        <div className="row pt-4 mt-2 pb-2">
                        <div className="col-lg-6 col-md-6 col-sm-12">
								<SecondaryBtn
									name="Cancel"
									modal="cancel"
									className="w-100"
								/>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-4">
								{
									name && allocation && relation && date  ? (
										<PrimaryBtn
											name={loader ? "Updating" : "Update"}
											className={loader
												? `
												w-100 e-btn-submit`
												:
												`w-100`
											}
											handleClick={(e) => updateNominee(e)}
										/>
									) : (
										<PrimaryBtn
											name={loader ? "Updating" : "Updating"}
											className={loader
												? `
												w-100 e-btn-submit`
												:
												`w-100`
											}
											disabled={true}
										/>
									)
								}
									
							</div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error ?
                    <Toast data={error} type="fail" />
                    :
                    null
            }
        </div>

    )
}
