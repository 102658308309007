/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Orders
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 04-01-2022
 */

/*import packages*/
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
/*import components*/
import { HeaderInner, Footer } from '../../components/menu/index';
import { Filter } from '../../components/card/index';
import { Accordion } from '../../components/dataFormatting';
import { Loader, SkeletonLoader } from '../../components/loader';
import RedirectUser from '../../services/redirectUser';
import { RedeemModal, SuccessPayment } from '../../components/modal';
import { Modal } from 'bootstrap';
import { CheckBox } from '../../components/input';
/*import assets*/
import InnerLogo from '../../assets/images/menu/inner-logo.svg';
import FooterLogo from '../../assets/images/menu/footerLogo.svg';
import Playstore from '../../assets/images/menu/playstore.svg';
import Appstore from '../../assets/images/menu/appstore.svg';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import GetURLVar from '../../services/getUrl';
import Nodata from '../../components/note/nodata-placeholder';
import APIGet from '../../services/getApi';
import { PrimaryLink } from '../../components/cta';
import OrderFilter from '../../components/modal/ordreFIlter';

const Orders = (props) => {
    const [orderType, setOrderType] = useState(null);
    const [statusType, setStatusType] = useState(null);
    const [loader, setLoader] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [error, setError] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [overlayLoader, setOverlayLoader] = useState(true);
    const [filterData, setFilterData] = useState();
    const [openWithdrawModal, setOpenModalWithdrawModal] = useState(false);
    const [status, setStatus] = useState(true);
    const [type, setType] = useState(null);
    let navigator = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const filterRef = useRef(null);
    const [typeFilter, setTypeFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);


    useEffect(() => {
        window.scrollTo({ top: 0, behaviour: "smooth" })
        getFilters();
    }, [])

    useEffect(() => {
        if (openWithdrawModal === true) {
            const modal = new Modal(document.getElementById("redeem"), {})
            modal.show();
        }
    }, [openWithdrawModal])

    useEffect(() => {
        if (props.userDetail) {
            window.analytics.identify(props.userDetail.user_id, {
                path: window.location.pathname,
            });
        }
    }, props.userDetail)

    useEffect(async () => {
        let data = await RedirectUser();
        GetURLVar().then((query) => {
            if (query && query.order_id) {

                if (!data.redirect) {
                    setOrderId(query.order_id);
                    getOrderList([], []);
                    setOverlayLoader(false);
                }
                else {
                    navigator(data.link);
                }
            }
            else {
                if (!data.redirect) {
                    getOrderList([], []);
                    //getProcessingOrders();
                    setOverlayLoader(false);
                }
                else {
                    navigator(data.link);
                }
            }
        })
    }, [])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 5000)
        }
    }, [error]);


    const getFilters = () => {
        let url = devconfig.user + '/profile/order-filter';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setFilterData(response.data);
            }
        })
    }


    const getProcessingOrders = () => {
        let url = devconfig.user + '/profile/processing-orders';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {

            }
        })
    }

    /*-----sip details api----*/
    const getOrderList = (type, status) => {
        setTypeFilter(type);
        setStatusFilter(status);
        console.log('temp', type);
        setLoader(true);
        let url = devconfig.user + '/profile/order-list'
        let data = JSON.stringify({
            order_type: type,
            status_type: status
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.status === true) {
                    setLoader(false);
                    setOrderList(response.data)
                } else {
                    setLoader(false);
                    setOrderList([]);
                    setError(response.message);
                }
            } else {
                setOrderList([]);
                setLoader(false);
                setError(response.message);
            }
        });
    }

    const openModal = (data) => {
        setOpenModalWithdrawModal(false);
        if (data === "SUCCESS") {
            setStatus(false)
            const modal = new Modal(document.getElementById("paymentSucess"), {})
            modal.show()
            setType("Payment successful")
        } else {
            setStatus(true)
            const modal = new Modal(document.getElementById("paymentSucess"), {})
            modal.show()
            setType("Payment failed")
        }

    }

    const closeMenu = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpenFilter(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);


    return (
        <>
            {
                overlayLoader ?
                    <SkeletonLoader />
                    :
                    <>
                        <div
                            className={
                                openFilter === true ?
                                    "e-overview-container e-overlay-order e-order-page px-lg-5 px-3 pb-5 mb-5"
                                    :
                                    "e-overview-container  e-order-page px-lg-5 px-3 pb-5 mb-5"
                            }
                        >
                            <div className="row pe-lg-5 pe-0">
                                {/* Heading */}
                                <div className="col-lg-8 col-md-8 col-sm-12   mt-lg-3 mt-md-3 mt-sm-0 mt-0 pt-0">
                                    <h5 className="e-dashboard-greeting mt-3">
                                        Your Orders
                                    </h5>
                                    <p className="e-dashboard-sub-title">
                                        Orders for your Mintd, sorted chronologically
                                    </p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-end mt-lg-5 mt-md-5 mt-0 pt-lg-3 pt-md-3 pt-0 position-relative">
                                    <PrimaryLink
                                        name="Filters"
                                        className="e-filter-btn-mobile mt-lg-4 mt-md-4 mt-0"
                                        handleClick={() => setOpenFilter(true)}
                                    />
                                    <div className='e-filter' style={{ display: openFilter === true ? 'block' : 'none' }} ref={filterRef}>
                                        <Filter
                                            submit={(tempType, tempStatus) => {
                                                getOrderList(tempType, tempStatus);
                                                setOpenFilter(false)
                                            }}
                                            close={() => setOpenFilter(false)}
                                            data={filterData}
                                        />
                                    </div>


                                </div>
                            </div>
                            <div className="row pe-lg-5 pe-0">
                                <div className="col-lg-12 e-small-device">
                                    {
                                        loader ?
                                            <div className='mt-5 pt-5'>
                                                <Loader />
                                            </div>
                                            :
                                            !loader && orderList && orderList.length > 0 ?
                                                <Accordion
                                                    data={orderList}
                                                    OrderId={orderId}
                                                />
                                                :
                                                <Nodata note="There is no order found" />
                                    }
                                </div>
                            </div>
                        </div>
                        <SuccessPayment from="/orders" heading={type} status={status} close={() => { }} />
                        {
                            openWithdrawModal ?
                                <RedeemModal
                                    modalClose={() => { setOpenModalWithdrawModal(false) }}
                                    success={(data) => {
                                        openModal(data)
                                        getOrderList([], []);
                                    }}
                                    heading={(data) => { }}
                                />
                                :
                                null
                        }
                        {

                        }
                    </>
            }
            {
                openFilter && (
                    <OrderFilter
                        data={filterData}
                        typeFilter={typeFilter}
                        statusFilter={statusFilter}
                        modalClose={() => setOpenFilter(false)}
                        filterSubmit={(tempType, tempStatus) => {
                            getOrderList(tempType, tempStatus);

                        }}
                    />
                )
            }


        </>
    )
}

export default Orders;

