/*
 *   File : skipTourConfirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Skip Tour  Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 22-07-2022
 */

/*import packages*/
import React, { useState } from 'react';
import $ from 'jquery'

/*import styles*/
import styles from '../../styles/components/check.module.scss';

/*import context*/


/*import assets */

import Close from '../../assets/images/new-design/modalClose.svg';
import RightArrow from '../../assets/images/new-design/arrow-right-white.svg'

/* import components */
import { PrimaryBtn } from '../cta';
import { CheckBox } from '../input';


/*import services */


export default function KycCheckList(props) {
    const [check, setCheck] = useState([])
    const [checkState, setCheckState] = useState(false)

    /* ------ array list of documents ----*/

    const kycDocumentList = {
        soft: [
            {
                name: 'PAN Card',
                id: 1
            },
            {
                name: `Address proof (Driver's License or Passport or Voters's Id)`,
                id: 2
            },
            {
                name: 'Signature on a blank paper',
                id: 3
            },
            {
                name: 'Cancelled cheque',
                id: 4
            },
            {
                name: 'Passport size photo',
                id: 5
            },
        ],
        other: [
            {
                name: 'Aadhar number',
                id: 6
            },
            {
                name: 'Bank account number',
                id: 7
            },
            {
                name: 'IFSC code',
                id: 8
            },
        ]
    }

    const kycListLength = Object.values(kycDocumentList).map((item) => item.length).reduce((a, b) => a + b, 0)

    return (
        <div className={`${styles.e_check_modal} modal fade`} id="check-list" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="successLabel" aria-hidden="true" data-bs-backdrop="static" >
            <div className={`modal-dialog modal-dialog-centered`}>
                <div className={`modal-content ${styles.e_content}`}>
                    <div className={`${styles.e_modal_header} text-center pt-3`}>
                        {`KYC Required`}
                        <img src={Close}
                            data-bs-dismiss="modal"
                            className='text-end'
                        />
                    </div>
                    <div className="container px-4 mx-2">
                        <div className='row'>
                            <div className='col-lg-12 pt-4'>
                                {/* <img src={Skip} alt="" /> */}
                                <h6 className={styles.e_sub_heading}>Hey {props.name}, you need to do KYC</h6>
                                <p className={`${styles.e_text}`}>Please make sure you have :-</p>
                            </div>
                            <div className='col-lg-12'>
                                <h6 className={styles.e_sub_heading_title}>Soft copy of</h6>
                                {
                                    kycDocumentList.soft.map((item) => {
                                        return (
                                            <CheckBox
                                                checkboxText={item.name}
                                                className="mt-2"
                                                check={check.indexOf(item.id) < 0 ? false : true}
                                                onChange={() => {
                                                    let tempCheckListArr = [...check]
                                                    let idx = tempCheckListArr.findIndex((checkItem) => checkItem === item.id)
                                                    if (idx < 0) {
                                                        tempCheckListArr.push(item.id)
                                                    } else {
                                                        tempCheckListArr.splice(idx, 1)
                                                    }
                                                    if (tempCheckListArr.length === kycListLength) {
                                                        setCheckState(true)
                                                    } else {
                                                        setCheckState(false)
                                                    }
                                                    setCheck(tempCheckListArr)

                                                }} />
                                        )
                                    })
                                }
                            </div>

                            <div className='col-lg-12 mt-2'>
                                <h6 className={styles.e_sub_heading_title}>Other details required</h6>
                                {
                                    kycDocumentList.other.map((item) => {
                                        return (
                                            <CheckBox
                                                checkboxText={item.name}
                                                check={check.indexOf(item.id) < 0 ? false : true}
                                                className="mt-2"
                                                onChange={() => {
                                                    let tempCheckListArr = [...check]
                                                    let idx = tempCheckListArr.findIndex((checkItem) => checkItem === item.id)
                                                    if (idx < 0) {
                                                        tempCheckListArr.push(item.id)
                                                    } else {
                                                        tempCheckListArr.splice(idx, 1)
                                                    }
                                                    setCheck(tempCheckListArr)
                                                    if (tempCheckListArr.length === kycListLength) {
                                                        setCheckState(true)
                                                    } else {
                                                        setCheckState(false)
                                                    }
                                                }} />
                                        )
                                    })
                                }
                            </div>
                            <div className='col-lg-12 d-flex justify-content-center mt-3 e-check-handy'>
                                <CheckBox
                                    checkboxText="I have everything handy"
                                    check={checkState}
                                    onChange={(e) => {
                                        setCheckState(e.target.checked)
                                        let temp = []
                                        if (e.target.checked) {
                                            Object.values(kycDocumentList).map((item) => {
                                                return (
                                                    item.map((checkItem) => {
                                                        temp.push(checkItem.id)
                                                    })
                                                )
                                            })
                                            setCheck(temp)
                                        } else {
                                            setCheck([])
                                        }

                                    }} />
                            </div>
                        </div>

                        <div className="row pt-lg-4 pt-md-4 pt-sm-4">
                            <div className="col-lg-12 col-12 pb-4">
                                <PrimaryBtn
                                    name="Let’s do KYC"
                                    className={kycListLength === Number(check.length) ? `e-finish-tour-btn e-take-tour` : `e-finish-tour-btn e-take-tour e-disabled`}
                                    image={RightArrow}
                                    right={true}
                                    cancel="modal"
                                    iconClass="ps-3"
                                    handleClick={() => {
                                        $("#check-list").trigger("click")
                                    }} />

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    )
}



