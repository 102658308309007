/*
 *   File : toast.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : toast
 *   Integrations :
 *   Version : v1.0
 *  Created : 16-12-2021
 */

import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Error from '../../assets/images/note/error.svg'
/*import style*/
import styles from '../../styles/components/toast.module.scss';


function Toast(props) {
  toast.error(props.data, {
    toastId: '1',
    icon: ({ theme, type }) => <img src={Error} />
  })
  return (
    <>
      {/* <div className={`${styles.e_toast} e-toast`}>
        <div className={props.type === "succcess" ? `${styles.e_success} row` : `${styles.e_fail} row`}>
          <div className='col-lg-11 col-10 pt-1 ps-4'>
            {props.data}
          </div>
          <div className='col-lg-1 col-2 px-0'>
            <img src={Close} alt="" />
          </div>
        </div>
      </div> */}
      <ToastContainer position="bottom-center" autoClose={5000} />
      {/* <ToastContainer>
        {props.data}
      </ToastContainer> */}
    </>
  );
}
export default Toast;
